import { Component, Inject, NgZone, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { ToolbarService } from '../../../services/toolbar.service';
import { MessagingService } from '../../../components/messaging/messaging.service';
import { UserService } from '../../../services/user.service';
import { EscapeDialogHelper } from '../../../helpers/escape-dialog.helper';
import { ExifHelper } from '../../../helpers/exifHelper.helper';
import { first, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-upload-files',
  templateUrl: './upload-files.component.html',
  styleUrls: ['./upload-files.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UploadFilesComponent implements OnInit, OnDestroy {
  maxSize = 10; // Max size in MB
  wrongFormatMessage = '';
  maxSizeExceedMessage = '';
  private readonly imageQuality = 0.85;
  private unsubscribe = new Subject();

  uploadedFiles = 0;
  totalFiles = 0;
  progress = 0;
  private allowDocumentType = ['pdf', 'xlsx', 'txt', 'docx', 'png', 'jpeg', 'jpg'];
  private allowImageType = ['png', 'jpeg', 'jpg'];

  constructor(
    public dialogRef: MatDialogRef<UploadFilesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UserService,
    public toolbarService: ToolbarService,
    private message: MessagingService,
    private escapeDialogRef: EscapeDialogHelper,
    private exifHelper: ExifHelper,
    private ngZone: NgZone
  ) {}

  ngOnInit(): void {
    if (this.data.isQuote || this.data.isInvoice) {
      this.totalFiles = 1;
      this.validateQuote();
    } else {
      this.totalFiles = this.data.files.length;
      this.validateFiles();
    }

    this.escapeDialogRef.escapeDialog(this.dialogRef);
  }

  validateFiles() {
    const filesWrongFormat = [];
    const filesExceedMaxSize = [];

    for (let i = 0; i < this.totalFiles; i++) {
      const filename = this.data.files[i].name;
      const fileSize = this.data.files[i].size / 1024 / 1024; // In MB
      const extension = filename.split('.').pop().toLowerCase();

      if (
        (!this.data.isPhotos && !this.allowDocumentType.includes(extension)) ||
        (this.data.isPhotos && !this.allowImageType.includes(extension))
      ) {
        filesWrongFormat.push(filename);
      } else if (fileSize > this.maxSize) {
        filesExceedMaxSize.push(filename);
      }
    }

    if (filesWrongFormat.length > 0 || filesExceedMaxSize.length > 0) {
      if (filesWrongFormat.length > 0) {
        this.wrongFormatMessage =
          filesWrongFormat.join(', ') +
          (filesWrongFormat.length > 1 ? ' are not valid files. ' : ' is not a valid file. ') +
          `${
            this.data.isPhotos
              ? 'The image format must be jpg, jpeg or png'
              : 'The document format must be png, jpeg, jpg, pdf, xlsx, txt or docx'
          }.`;
      }
      if (filesExceedMaxSize.length > 0) {
        this.maxSizeExceedMessage =
          filesExceedMaxSize.join(', ') +
          (filesExceedMaxSize.length > 1 ? ' are ' : ' is ') +
          'over 10mb and could not be uploaded.';
      }
    } else {
      this.uploadFiles();
    }
  }

  uploadFiles() {
    if (this.data.files.length > this.uploadedFiles) {
      const formData: FormData = new FormData();
      const uploadedFile = this.data.files[this.uploadedFiles];
      const fileName = uploadedFile.name;
      formData.append('filename', `${fileName}`);
      if (this.data.isPhotos) {
        let maxWidth = 1024;
        const img = new Image();
        img.src = URL.createObjectURL(uploadedFile);
        img.onload = () => {
          // Same values as V6
          if (img.naturalHeight > img.naturalWidth) {
            maxWidth = 768;
          }
          if (img.naturalWidth > maxWidth) {
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            canvas.width = maxWidth;
            canvas.height = (maxWidth * img.naturalHeight) / img.naturalWidth;
            context.drawImage(img, 0, 0, canvas.width, canvas.height);
            if (uploadedFile.type === 'image/png') {
              canvas.toBlob(
                (blob) => {
                  formData.append('image', blob);
                  this.uploadJobFiles(formData, this.data.isPhotos);
                },
                'image/png',
                this.imageQuality
              );
            } else {
              // only jpeg must copy EXIF data
              this.exifHelper
                .adjustImageExifAfterScaleDown(
                  uploadedFile,
                  canvas.toDataURL(uploadedFile.type, this.imageQuality),
                  canvas.width,
                  canvas.height
                )
                .pipe(first(), takeUntil(this.unsubscribe))
                .subscribe({
                  next: (data) => {
                    formData.append('image', data);
                    this.uploadJobFiles(formData, this.data.isPhotos);
                  },
                  error: () => {
                    this.dialogRef.close();
                    this.message.error('Could not upload photos.');
                  }
                });
            }
          } else {
            formData.append('image', this.data.files[this.uploadedFiles]);
            this.uploadJobFiles(formData, this.data.isPhotos);
          }
        };
      } else {
        formData.append('document', uploadedFile, `${fileName}`);
        this.uploadJobFiles(formData, this.data.isPhotos);
      }
    } else {
      this.dialogRef.close('Success');
    }
  }

  uploadJobFiles(formData: FormData, isPhotoToUpload: boolean) {
    this.uploadedFiles++;
    this.userService
      .uploadJobFile(this.data.jobId, formData, isPhotoToUpload ? 'uploadJobPhoto' : 'uploadJobDocument')
      .subscribe({
        next: () => {
          this.uploadFiles();
          this.ngZone.run(() => {
            this.progress = (this.uploadedFiles / this.totalFiles) * 100;
            if (this.data.files.length === this.uploadedFiles) {
              this.dialogRef.close('Success');
            }
          });
        },
        error: (error) => {
          if (error.error === 'VIRUS_FOUND') {
            // ignore
            this.uploadFiles();
          } else {
            this.message.error(`Could not upload ${isPhotoToUpload ? 'photos' : 'documents'}.`);
            this.stopUpload();
          }
        }
      });
  }

  validateQuote() {
    const filename = this.data.files.length ? this.data.files[0].name : this.data.files.name;
    const imageSize = (this.data.files.length ? this.data.files[0].size : this.data.files.size) / 1024 / 1024; // In MB
    const extension = filename.split('.').pop().toLowerCase();
    if (extension !== 'xml') {
      this.wrongFormatMessage = filename + ' is not a valid file. The quote format must be xml.';
    } else if (imageSize > this.maxSize) {
      this.maxSizeExceedMessage = filename + ' is over 10mb and could not be uploaded.';
    } else {
      this.uploadQuoteOrInvoice();
    }
  }

  uploadQuoteOrInvoice() {
    const formData: FormData = new FormData();
    formData.append('quote', this.data.files.length ? this.data.files[0] : this.data.files);
    this.uploadedFiles = 1;
    this.progress = 100;
    if (this.data.isQuote) {
      this.userService.importQuote(this.data.jobId, formData, this.data.importVehicleInfo).subscribe(
        () => {
          this.dialogRef.close('Success');
        },
        (error) => {
          if (error.error === 'VIRUS_FOUND') {
            this.stopUpload();
          } else if (error.error === 'DUPLICATE_QUOTE_NUMBER') {
            this.wrongFormatMessage = 'A quote with this quote number is already associated with the job.';
          } else if (error.error === 'INVALID_REQUEST') {
            this.message.error('Could not upload quote: cannot parse XML');
            this.stopUpload();
          } else {
            this.message.error('Could not upload quote.');
            this.stopUpload();
          }
        }
      );
    } else {
      this.userService.importInvoice(this.data.jobId, this.data.invoiceNumber, formData).subscribe(
        () => {
          this.dialogRef.close('Success');
        },
        (error) => {
          if (error.error === 'VIRUS_FOUND') {
            this.stopUpload();
          } else if (error.error === 'DUPLICATE_INVOICE_NUMBER') {
            this.wrongFormatMessage = 'An invoice with this invoice number is already associated with the job.';
          } else if (error.error === 'NUMBER_OF_QUOTES_IN_CLAIM_NOT_MATCH_NUMBER_OF_QUOTES_DOCUMENT_IMPORTED') {
            this.wrongFormatMessage =
              'The number of quote(s) in the claim does not match the number of quote(s) in the file being imported.';
          } else if (error.error === 'QUOTE_NUMBER_NOT_FOUND') {
            this.wrongFormatMessage = 'A quote in the file being imported does not match any quote in the claim.';
          } else {
            this.message.error('Could not upload invoice.');
            this.stopUpload();
          }
        }
      );
    }
  }

  stopUpload() {
    this.data.files = [];
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
