import {
  Component,
  Input,
  EventEmitter,
  Output,
  ViewChild,
  ElementRef,
  AfterContentChecked,
  inject,
  DestroyRef,
  OnInit,
  AfterViewInit
} from '@angular/core';
import { UserService } from '../../../services/user.service';
import { Job } from '../../../model/job.model';
import { ToolbarService } from '../../../services/toolbar.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { UpdateRepairTimelineComponent } from '../update-repair-timeline/update-repair-timeline.component';
import { User, UserRepairerSite } from '../../../model/user.model';
import { MessagingService } from '../../../components/messaging/messaging.service';
import { first, filter } from 'rxjs/operators';
import { ConfirmBoxComponent } from '../../../components/confirm-box/confirm-box.component';
import { ModalConfigsHelper } from '../../../helpers/modal-configs.helper';
import { mediumModalConfig } from '../../../consts/modal-config.const';
import { CurrentUserService } from '../../../services/currentUser.service';
import { JobService } from '../../../services/job.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-repairer-tile',
  templateUrl: './repairer-tile.component.html',
  styleUrls: ['./repairer-tile.component.scss']
})
export class RepairerTileComponent implements OnInit, AfterViewInit, AfterContentChecked {
  @Input() currentUser: User;
  @Input() job: Job;
  @Input() photoLoaded: object;
  @Input() repairerSite: UserRepairerSite;
  @Input() hasRedBudge = false;
  @Input() bucket: string;
  @Output() getJobs: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('vehicleDescription', { static: false }) vehicleDescription: ElementRef;
  @ViewChild('vehicleDescriptionText', { static: false }) vehicleDescriptionText: ElementRef;
  setAnimation = false;
  activeJobId: number;

  private matDialog = inject(MatDialog);
  private destroyRef = inject(DestroyRef);
  private elRef = inject(ElementRef);
  private currentUserService = inject(CurrentUserService);
  private jobService = inject(JobService);
  private message = inject(MessagingService);
  private toolbarService = inject(ToolbarService);
  private userService = inject(UserService);
  private modalConfigsHelper = inject(ModalConfigsHelper);

  ngOnInit() {
    this.jobService.activeJobId$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((activeJobId) => {
      this.activeJobId = activeJobId;
    });
  }

  ngAfterViewInit() {
    if (this.job.jobId === this.activeJobId) {
      setTimeout(() => {
        this.elRef.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 200);
    }
  }

  ngAfterContentChecked() {
    this.setAnimation =
      this.vehicleDescriptionText?.nativeElement?.offsetWidth > this.vehicleDescription?.nativeElement?.offsetWidth;
  }

  saveActiveJob(jobId: number) {
    this.jobService.activeJobId = jobId;
    localStorage.setItem('previousUrl', 'jobViewer');
  }

  archiveJob(id: number) {
    const data = {
      title: 'Archive',
      alertMessage: 'Are you sure you want to archive this claim?',
      confirmButton: 'ARCHIVE',
      hideCannotBeUndone: true,
      biggerButtons: true
    };
    const dialogRef = this.matDialog.open(
      ConfirmBoxComponent,
      this.modalConfigsHelper.buildMediumModalConfig(data, mediumModalConfig)
    );
    dialogRef
      .afterClosed()
      .pipe(filter((value) => value === 'ARCHIVE'))
      .subscribe(() => {
        this.toolbarService.setCloudState('SAVING');
        this.userService
          .hideFromDashboard(id, true)
          .pipe(first())
          .subscribe({
            next: () => {
              this.message.confirm('Job archived');
              this.toolbarService.setCloudState('RESTING');
              this.getJobs.emit();
            },
            error: () => {
              this.message.error('Could not archived the job.');
              this.toolbarService.setCloudState('RESTING');
            }
          });
      });
  }

  toggleJobReadStatus(isUnread: boolean, id: number): void {
    this.userService.toggleJobReadStatus(isUnread, id).subscribe(() => this.getJobs.emit());
  }

  openRepairTimelineModal(job: Job) {
    const data = {
      job,
      userTimezone: this.currentUserService?.timeZone,
      repairTimelineDates: {
        authorityDate: job?.authorityDate,
        repairBookedDate: job?.repairBookedDate,
        vehicleDateIn: job?.vehicleDateIn,
        repairStartedDate: job?.repairStartedDate,
        repairCompletedDate: job?.repairCompletedDate,
        vehicleDateOut: job?.vehicleDateOut
      }
    };
    const dialogRef = this.matDialog.open(
      UpdateRepairTimelineComponent,
      this.modalConfigsHelper.buildExtraWideModalConfig(data, 98)
    );
    dialogRef
      .afterClosed()
      .pipe(filter((result) => result === 'Success'))
      .subscribe(() => this.getJobs.emit());
  }
}
