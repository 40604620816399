import { NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, effect, inject, ViewChild } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import { MatLegacyTableDataSource as MatTableDataSource, MatLegacyTableModule } from '@angular/material/legacy-table';
import {MatSort, MatSortModule} from '@angular/material/sort';
import { InfosGuidanceComponent } from '../../../components/infos-guidance/infos-guidance.component';
import { LoadingSpinnerComponent } from '../../../components/loading-spinner/loading-spinner.component';
import { RepairerSiteHistory } from '../../../model/user.model';
import { DatePipe } from '../../../pipes/date.pipe';
import { TimePipe } from '../../../pipes/time.pipe';
import { CurrentUserService } from '../../../services/currentUser.service';
import { LocationAdminStore } from '../location.admin.store.component';

@Component({
  selector: 'history-of-company',
  standalone: true,
  templateUrl: './history-of-company.component.html',
  imports: [
    NgTemplateOutlet,
    NgClass,
    NgIf,
    DatePipe,
    MatSortModule,
    MatDividerModule,
    MatLegacyTableModule,
    MatButtonModule,
    TimePipe,
    InfosGuidanceComponent,
    LoadingSpinnerComponent
  ]
})
export class HistoryOfCompanyComponent {
  @ViewChild(MatSort) set sort(matSort: MatSort) {
    this.dataSource.sort = matSort;
  }

  columnHeaders = ['transactionDateTime', 'event', 'details'];
  dataSource: MatTableDataSource<RepairerSiteHistory> = new MatTableDataSource();
  activeColumn = 'transactionDateTime';
  locationAdminStore = inject(LocationAdminStore);
  currentUserService = inject(CurrentUserService);

  constructor() {
    effect(() => {
      if (this.locationAdminStore.repairerSiteHistories().length) {
        this.dataSource.data = this.locationAdminStore.repairerSiteHistories();
        this.dataSource.sort = this.sort;
      }
    });
  }
}
