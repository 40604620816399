<form *ngIf="googleMapsService.googleMapsLoaded | async" [formGroup]="form" class="grid grid-cols-2 gap-x-20">
  <mat-form-field appearance="outline" color="accent" class="col-span-2">
    <mat-label>Address</mat-label>
    <input
      formControlName="addressLine1"
      matInput
      [appAddressAutocomplete]="form.value"
      (chosenAddress)="chosenAddress($event)" />
    <mat-icon *ngIf="isGeolocalized && form.enabled" class="geolocalized-address" matSuffix>pin_drop</mat-icon>
    <mat-error *ngIf="addressLine1.errors?.required"> Address is <b>required</b> </mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline" color="accent" class="col-span-2">
    <mat-label>Address 2</mat-label>
    <input formControlName="addressLine2" matInput />
  </mat-form-field>
  <mat-form-field appearance="outline" color="accent" class="col-span-2">
    <mat-label>Suburb / Town</mat-label>
    <input formControlName="suburb" matInput />
  </mat-form-field>
  <mat-form-field *ngIf="country?.value === 'New Zealand'" appearance="outline" color="accent">
    <mat-label>City</mat-label>
    <input formControlName="city" matInput />
  </mat-form-field>
  <mat-form-field *ngIf="country?.value === 'Australia'" appearance="outline" color="accent" class="mat-auto-width">
    <mat-label>State</mat-label>
    <mat-select formControlName="state">
      <mat-option *ngFor="let state of stateOptions" [value]="state">{{ state }}</mat-option>
    </mat-select>
    <mat-error *ngIf="state?.errors?.required"> State is <b>required</b> </mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline" color="accent" class="mat-auto-width">
    <mat-label>Postcode</mat-label>
    <input appNumbersOnly formControlName="postCode" matInput maxlength="4" />
    <mat-error *ngIf="postCode.errors?.required"> Post code is <b>required</b> </mat-error>
  </mat-form-field>
</form>
