import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'daysSince'
})
export class DaysSincePipe implements PipeTransform {
  transform(epoch: string, timezone?: string): number {
    const dateEntered = timezone ? moment(epoch).tz(timezone) : moment(epoch);
    const today = moment(Date.now());
    return today.diff(dateEntered, 'days');
  }
}
