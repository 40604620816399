<div class="submit-docs-box">
  <div class="icon-holder">
    <mat-icon>send</mat-icon>
  </div>
  <div class="title-1 title">Send To {{ assessorName }}</div>
  <div *ngIf="!submitting && !errorMessage" class="info-text subtitle-1">
    {{ sendingDocumentsMessage }}
  </div>

  <ng-container *ngIf="submitting">
    <mat-progress-bar class="progress-bar" mode="buffer"></mat-progress-bar>
    <div class="transfer-text body-2">
      <div>Sending...</div>
      <div>This should only take a few seconds</div>
    </div>
  </ng-container>

  <div *ngIf="errorMessage" class="error-message-box">
    <div class="warn-box">
      <mat-icon matPrefix>report_problem</mat-icon>
      <div>There was a problem sending your documents to the insurer. Please try again or contact Stelvio Support.</div>
    </div>
  </div>

  <mat-divider *ngIf="!submitting"></mat-divider>

  <mat-dialog-actions *ngIf="!submitting && !errorMessage">
    <button mat-raised-button mat-dialog-close color="accent">CANCEL</button>
    <button (click)="submitJob()" color="primary" mat-raised-button>
      <mat-icon>send</mat-icon>
      SEND
    </button>
  </mat-dialog-actions>
  <button *ngIf="errorMessage" class="ok-button" mat-button mat-dialog-close>OK</button>
</div>
