import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { User } from '../model/user.model';
import { PermissionHelper } from '../helpers/permission.helper';
import { CurrentUserService } from '../services/currentUser.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardRepadmin {
  currentUser: User;

  constructor(
    private currentUserService: CurrentUserService,
    private router: Router,
    private permissionHelper: PermissionHelper
  ) {}

  canActivate(): Observable<boolean> | boolean {
    return this.checkUser();
  }

  checkUser() {
    const currentUser = this.currentUserService.currentUserValue;
    if (currentUser) {
      return this.permissionHelper.checkPermission(currentUser, 'REPADMIN', this.router);
    }
    return false;
  }
}
