import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CheckExistenceOfFileHelper {
  constructor() {}
  urlExists(url: string) {
    let http = new XMLHttpRequest();
    http.open('HEAD', url, false);
    http.send();
    return http.status != 404;
  }
}
