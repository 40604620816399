<div class="header-container top-container">
  <form [formGroup]="generateReportForm" (ngSubmit)="generateReport()">
    <div class="search-inputs" [class.clear-button]="selectedInsurer">
      <mat-form-field appearance="outline" class="range-field primary-icon" color="accent">
        <mat-label>Time Range</mat-label>
        <mat-date-range-input [rangePicker]="rangePicker">
          <input matStartDate formControlName="startDate" placeholder="Start date" required />
          <input matEndDate formControlName="endDate" placeholder="End date" (dateChange)="dateChange()" required />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="rangePicker"></mat-datepicker-toggle>
        <mat-date-range-picker
          #rangePicker
          [calendarHeaderComponent]="DatepickerRangeComponent"></mat-date-range-picker>
        <mat-hint>DD/MM/YYYY - DD/MM/YYYY</mat-hint>
        <mat-error
          *ngIf="
            (startDate.hasError('required') || endDate.hasError('required')) &&
            !startDate.hasError('matDatepickerParse') &&
            !endDate.hasError('matDatepickerParse')
          ">
          <span *ngIf="startDate.hasError('required')">Start date is <strong>required</strong>. </span>
          <span *ngIf="endDate.hasError('required')"> End date is <strong>required.</strong></span>
        </mat-error>
        <mat-error *ngIf="startDate.hasError('matStartDateInvalid') || endDate.hasError('matEndDateInvalid')">
          <div>Start date must be on or before end date</div>
        </mat-error>
        <mat-error *ngIf="startDate.hasError('matDatepickerParse') || endDate.hasError('matDatepickerParse')">
          The date format is <strong>DD/MM/YYYY - DD/MM/YYYY</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field
        appearance="outline"
        class="status-field"
        color="accent"
        [class.clear-show-button]="selectedInsurer">
        <mat-label>Status</mat-label>
        <mat-select formControlName="status" [(value)]="selectedType">
          <mat-option *ngFor="let type of reportTypes" [value]="type.value">{{ type.name }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" class="insurer-field" [class.clear-show-button]="selectedInsurer">
        <mat-label>Insurer</mat-label>
        <mat-select [formControl]="insurer" multiple>
          <mat-option (onSelectionChange)="change($event)" *ngFor="let insurer of insurers" [value]="insurer.id">{{
            insurer.name
          }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-icon class="clear" *ngIf="selectedInsurer" (click)="clearAllSelection($event)" matSuffix
        >backspace_24px
      </mat-icon>
    </div>
    <div class="report-button">
      <ng-container *ngIf="buttonPrimary; else buttonAccent">
        <button
          color="accent"
          (click)="generateReport()"
          mat-button
          class="generate-report-button subtitle-1"
          type="submit"
          [disabled]="startDate.invalid || endDate.invalid">
          APPLY
        </button>
      </ng-container>
      <ng-template #buttonAccent>
        <ng-container>
          <button
            color="primary"
            (click)="buttonPrimary = true; generateReport()"
            mat-raised-button
            class="generate-report-button subtitle-1"
            type="submit"
            [disabled]="startDate.invalid || endDate.invalid">
            APPLY
          </button>
        </ng-container>
      </ng-template>
    </div>
  </form>
</div>

<div class="report-section">
  <div class="header">
    <div class="init-header" *ngIf="generateReportForm.invalid">
      <div class="subtitle-2">
        <mat-icon>info_outline_24px</mat-icon>
        Define a date range to generate a report of jobs, their status and totals quoted, invoiced and authorised
      </div>
    </div>
    <div class="no-result" *ngIf="reportSummary?.reports?.length === 0">
      <mat-card class="subtitle-2">
        <mat-icon>warning_24px</mat-icon>
        No results could be found. Please review your criteria.
      </mat-card>
    </div>
    <ng-container *ngTemplateOutlet="loadingSpinner"></ng-container>
  </div>
  <div [class.hidden]="doNotShowReports">
    <div class="report">
      <h6 class="title">{{ reportSummary?.rows }} {{ reportSummary?.rows === 1 ? 'Claim' : 'Claims' }}</h6>
      <div class="amounts">
        <div class="totals">
          <div class="quoted">
            <span class="totals-title body-2">Total Quoted</span>
            <div class="total subtitle-2">$ {{ reportSummary?.totalQuotedAmount | number: '1.2-2' }}</div>
          </div>
          <div class="authorised">
            <span class="totals-title body-2">Total Authorised</span>
            <div class="total subtitle-2">$ {{ reportSummary?.totalAuthorizedAmount | number: '1.2-2' }}</div>
          </div>
          <div class="approved">
            <span class="totals-title body-2">Total Approved</span>
            <div class="total subtitle-2">$ {{ reportSummary?.totalApprovedAmount | number: '1.2-2' }}</div>
          </div>
        </div>
      </div>
      <div class="excel" [class.spinner-right]="loadingXlsx">
        <mat-icon *ngIf="!loadingXlsx; else loadingExport" inline="true" (click)="exportAsXLSX()"
          >file_download_24px
        </mat-icon>
        <ng-template #loadingExport>
          <mat-spinner class="loading-export" color="primary" mode="indeterminate" [diameter]="21"></mat-spinner>
        </ng-template>
      </div>
    </div>
  </div>
  <div class="content-report" [class.hidden]="doNotShowReports">
    <mat-divider></mat-divider>
    <div class="report-table" #tableContent>
      <div
        [ngClass]="{
          'large-screen': 1025 | breakObserver: 'min' | async,
          'small-screen': 1024 | breakObserver: 'max' | async
        }">
        <table
          mat-table
          [dataSource]="dataSource"
          (matSortChange)="sortChange($event)"
          matSort
          matSortDirection="asc"
          matSortActive="lastUpdateDate"
          matSortDisableClear>
          <ng-container matColumnDef="lastUpdateDate">
            <th
              class="body-2"
              mat-header-cell
              *matHeaderCellDef
              [class.active]="activeColumn === 'lastUpdateDate'"
              mat-sort-header>
              Last Received
            </th>
            <td mat-cell class="subtitle-2" *matCellDef="let element">
              {{ element.lastUpdateDate ? (element.lastUpdateDate | dateepoch: timezone) : '' }}
            </td>
          </ng-container>
          <ng-container matColumnDef="regoNumber">
            <th
              class="body-2"
              mat-header-cell
              *matHeaderCellDef
              [class.active]="activeColumn === 'regoNumber'"
              mat-sort-header>
              Registration
            </th>
            <td mat-cell class="subtitle-2" *matCellDef="let element">
              <span [ngClass]="{ regoNumber: element.regoNumber }">{{ element.regoNumber }}</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="ownerName">
            <th
              class="body-2"
              mat-header-cell
              *matHeaderCellDef
              [class.active]="activeColumn === 'ownerName'"
              mat-sort-header>
              Owner
            </th>
            <td mat-cell class="subtitle-2" *matCellDef="let element">{{ element.ownerName }}</td>
          </ng-container>
          <ng-container matColumnDef="claimNumber">
            <th
              class="body-2"
              mat-header-cell
              *matHeaderCellDef
              [class.active]="activeColumn === 'claimNumber'"
              mat-sort-header>
              Claim Number
            </th>
            <td mat-cell class="subtitle-2" *matCellDef="let element">{{ element.claimNumber }}</td>
          </ng-container>
          <ng-container matColumnDef="lastAssessmentAction">
            <th
              class="body-2"
              mat-header-cell
              *matHeaderCellDef
              [class.active]="activeColumn === 'lastAssessmentAction'"
              mat-sort-header>
              Last Assessment Action
            </th>
            <td mat-cell class="subtitle-2" *matCellDef="let element">{{ element.lastAssessmentAction }}</td>
          </ng-container>
          <ng-container matColumnDef="quoteOriginalNumber">
            <th
              class="body-2"
              mat-header-cell
              *matHeaderCellDef
              [class.active]="activeColumn === 'quoteOriginalNumber'"
              mat-sort-header>
              Quote Number
            </th>
            <td mat-cell class="subtitle-2" *matCellDef="let element">{{ element.quoteOriginalNumber }}</td>
          </ng-container>
          <ng-container matColumnDef="insurerName">
            <th
              class="body-2"
              mat-header-cell
              *matHeaderCellDef
              [class.active]="activeColumn === 'insurerName'"
              mat-sort-header>
              Insurer Name
            </th>
            <td mat-cell class="subtitle-2" *matCellDef="let element">{{ element.insurerName }}</td>
          </ng-container>
          <ng-container matColumnDef="assessorName">
            <th
              class="body-2"
              mat-header-cell
              *matHeaderCellDef
              [class.active]="activeColumn === 'assessorName'"
              mat-sort-header>
              Assessor
            </th>
            <td mat-cell class="subtitle-2" *matCellDef="let element">{{ element.assessorName }}</td>
          </ng-container>
          <ng-container matColumnDef="vehicleDescription">
            <th
              class="body-2"
              mat-header-cell
              *matHeaderCellDef
              [class.active]="activeColumn === 'vehicleDescription'"
              mat-sort-header>
              Vehicle Description
            </th>
            <td mat-cell class="subtitle-2" *matCellDef="let element">{{ element.vehicleDescription }}</td>
          </ng-container>
          <ng-container matColumnDef="quoteDate">
            <th
              class="body-2"
              mat-header-cell
              *matHeaderCellDef
              [class.active]="activeColumn === 'quoteDate'"
              mat-sort-header>
              Quote Submitted
            </th>
            <td mat-cell class="subtitle-2" *matCellDef="let element">
              {{ element.quoteDate ? (element.quoteDate | dateepoch: timezone) : '' }}
            </td>
          </ng-container>
          <ng-container matColumnDef="authorizedDate">
            <th
              class="body-2"
              mat-header-cell
              *matHeaderCellDef
              [class.active]="activeColumn === 'authorizedDate'"
              mat-sort-header>
              Repairs Authorised
            </th>
            <td mat-cell class="subtitle-2" *matCellDef="let element">
              {{ element.authorizedDate ? (element.authorizedDate | dateepoch: timezone) : '' }}
            </td>
          </ng-container>
          <ng-container matColumnDef="approvedDate">
            <th
              class="body-2"
              mat-header-cell
              *matHeaderCellDef
              [class.active]="activeColumn === 'approvedDate'"
              mat-sort-header>
              Invoice Approved
            </th>
            <td mat-cell class="subtitle-2" *matCellDef="let element">
              {{ element.approvedDate ? (element.approvedDate | dateepoch: timezone) : '' }}
            </td>
          </ng-container>
          <ng-container matColumnDef="quotedAmount">
            <th
              class="body-2"
              mat-header-cell
              *matHeaderCellDef
              [class.active]="activeColumn === 'quotedAmount'"
              mat-sort-header>
              Total Quoted
            </th>
            <td mat-cell class="subtitle-2" *matCellDef="let element">
              {{ element.quotedAmount !== 0 ? '$ ' + (element.quotedAmount | number: '1.2-2') : '' }}
            </td>
          </ng-container>
          <ng-container matColumnDef="authorizedAmount">
            <th
              class="body-2"
              mat-header-cell
              *matHeaderCellDef
              [class.active]="activeColumn === 'authorizedAmount'"
              mat-sort-header>
              Total Authorised
            </th>
            <td mat-cell class="subtitle-2" *matCellDef="let element">
              {{ element.authorizedAmount !== 0 ? '$ ' + (element.authorizedAmount | number: '1.2-2') : '' }}
            </td>
          </ng-container>
          <ng-container matColumnDef="approvedAmount">
            <th
              class="body-2"
              mat-header-cell
              *matHeaderCellDef
              [class.active]="activeColumn === 'approvedAmount'"
              mat-sort-header>
              Total Approved
            </th>
            <td mat-cell class="subtitle-2" *matCellDef="let element">
              {{ element.approvedAmount !== 0 ? '$ ' + (element.approvedAmount | number: '1.2-2') : '' }}
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="reportColumns; sticky: true"></tr>
          <tr
            mat-row
            [id]="row.jobId"
            *matRowDef="let row; columns: reportColumns"
            (click)="redirectToJob(row.jobId)"
            [class.highlight-active-job]="row.jobId === this.activeJobId"></tr>
        </table>
      </div>
    </div>
  </div>
</div>

<ng-template #loadingSpinner>
  <div class="loading-result" *ngIf="loading">
    <mat-spinner color="primary" mode="indeterminate" [diameter]="21"></mat-spinner>
    <div class="spinner-text subtitle-2">Loading...</div>
  </div>
</ng-template>
