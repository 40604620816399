import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { get } from 'idb-keyval';
import { ParseXMLService } from '../../services/parseXML.service';
import { ConfigureImportDirectoriesComponent } from '../configure-import-directories/configure-import-directories.component';
import { wideModalConfig } from '../../consts/modal-config.const';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { AddInvoiceComponent } from '../../dashboard/job-viewer/add-invoice/add-invoice.component';
import { AddQuoteComponent } from '../../dashboard/job-viewer/add-quote/add-quote.component';

@Component({
  selector: 'app-import-file-from-disk',
  templateUrl: './import-file-from-disk.component.html',
  styleUrls: ['./import-file-from-disk.component.scss']
})
export class ImportFileFromDiskComponent implements OnChanges {
  @Input() rego: string;
  @Input() type: string;
  @Input() fileDeleted: boolean;
  @Input() xmlFiles: any;
  @Output() setFile: EventEmitter<File> = new EventEmitter();
  @Output() setImages: EventEmitter<any> = new EventEmitter();
  fileDirectoryHandler: FileSystemDirectoryHandle;
  imagesDirectoryHandler: FileSystemDirectoryHandle;
  cancelAccessAuthorisation = false;
  permissionOfFileWasGiven = false;
  permissionOfImagesWasGiven = false;
  noFile = false;
  file = null;

  constructor(
    private parseXMLService: ParseXMLService,
    public dialogRef: MatDialogRef<AddInvoiceComponent | AddQuoteComponent>,
    private configureImport: MatDialog
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.xmlFiles) {
      this.importFolderCheck();
    }
  }

  async importFolderCheck() {
    this.fileDirectoryHandler = await get('file');
    this.imagesDirectoryHandler = await get('image');
    if (this.fileDirectoryHandler && this.rego && !this.fileDeleted && !this.xmlFiles) {
      this.permissionOfFileWasGiven = await this.verifyPermission(this.fileDirectoryHandler, 'file');
      await this.givePermissionToFileAccess();
      if (!this.permissionOfFileWasGiven) {
        this.setFile.emit(null);
      }
    } else if (this.xmlFiles?.length) {
      await this.getPermissionOfImages();
      this.parseXmlFile(this.xmlFiles[0]);
    }
  }

  async verifyPermission(fileHandle: any, type: string) {
    // Check if permission was already granted. If so, return true.
    if ((await fileHandle?.queryPermission({ mode: 'read', id: type })) === 'granted') {
      return true;
    }
    // The user didn't grant permission, so return false.
    return false;
  }

  async givePermissionToFileAccess() {
    const permission = await (this.fileDirectoryHandler as any).requestPermission({ mode: 'read' });
    this.cancelAccessAuthorisation = permission === 'prompt';
    this.permissionOfFileWasGiven = permission === 'granted';
    if (this.imagesDirectoryHandler) {
      if (this.rego && !this.fileDeleted && this.permissionOfFileWasGiven) {
        await this.getPermissionOfImages();
        await this.searchFileAndImageAccordingToRego(this.fileDirectoryHandler);
      }
    } else {
      await this.searchFileAndImageAccordingToRego(this.fileDirectoryHandler);
    }
  }

  async getPermissionOfImages() {
    const imagePermission = await this.verifyPermission(this.imagesDirectoryHandler, 'image');
    this.permissionOfImagesWasGiven = imagePermission;
    if (!imagePermission) {
      const permission = await (this.imagesDirectoryHandler as any)?.requestPermission({ mode: 'read' });
      this.permissionOfImagesWasGiven = permission === 'granted';
    }
  }

  configureImportDirectories() {
    this.dialogRef.close();
    this.configureImport.open(ConfigureImportDirectoriesComponent, wideModalConfig);
  }

  async searchFileAndImageAccordingToRego(handler: any) {
    const files = [];
    for await (const entry of handler.values()) {
      if (entry.kind === 'file') {
        files.push(entry.name.toLowerCase());
        entry.getFile().then((file) => {
          const regoCaseInsensitive = this.rego.toLowerCase();
          const fileNameWithoutExt = file.name.split('.')[0].toLowerCase();
          if (regoCaseInsensitive && file.type === 'text/xml' && fileNameWithoutExt === regoCaseInsensitive) {
            this.setFile.emit(file);
            this.file = file;
            this.parseXmlFile(file);
          }
        });
      }
    }
    this.noFile = !files.some((x) => x.split('.')[0].toLowerCase() === this.rego.toLowerCase());
    if (this.noFile) {
      this.setFile.emit(null);
    }
  }

  findCommonPrefix(strings: string[], foundCommon: string[] = []): string[] {
    if (strings.length === 0) {
      return [];
    }
    let commonString = strings[0];
    const prefix = commonString.split('\\').filter(Boolean)[0];
    for (let i = 1; i < strings.length; i++) {
      if (strings[i].includes(prefix)) {
        while (strings[i].indexOf(commonString) === -1) {
          commonString = commonString.slice(0, commonString.lastIndexOf('\\'));
          if (commonString === '') {
            break;
          }
        }
      }
    }

    if (commonString.length) {
      foundCommon.push(commonString);
    }

    const excludeItemsWithCommonString: string[] = strings.filter(item => foundCommon.filter(found => item.startsWith(found)).length === 0);

    if (excludeItemsWithCommonString.length) {
      return this.findCommonPrefix(excludeItemsWithCommonString, foundCommon);
    }

    return foundCommon;
  }

  parseXmlFile(file: any) {
    if (this.permissionOfImagesWasGiven && this.imagesDirectoryHandler) {
      this.parseXMLService.parseXML(file).subscribe({
        next: async (attachments: any) => {
          const images = [];
          if (!!attachments.length) {
            const commonPrefix: string[] = this.findCommonPrefix(attachments);
            attachments = attachments.map((item: string) => {
              const prefix = commonPrefix.find(pre => item.includes(pre));
              return item.replace(prefix + '\\', '');
            });

            for (const attachment of attachments) {
              let directory: FileSystemDirectoryHandle;
              const chunks = attachment.split('\\');
              const fileName: string = chunks.pop();

              if (chunks.length > 0) {
                for (let i = 0; i < chunks.length; i++) {
                  try {
                    directory = await (directory || this.imagesDirectoryHandler).getDirectoryHandle(chunks[i]);
                  } catch (e) {}
                }
              }

              try {
                const fileHandler = await (directory || this.imagesDirectoryHandler).getFileHandle(fileName);
                images.push(await fileHandler.getFile());
              } catch (e) {}
            }
          }
          this.setImages.emit(images);
        },
        error: () => {
          this.setFile.emit(null);
          this.setImages.emit([]);
        }
      });
    } else {
      this.setImages.emit([]);
    }
  }
}
