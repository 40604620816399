import { Pipe, PipeTransform } from '@angular/core';
import { fromUnixTime, format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

@Pipe({
  standalone: true,
  name: 'time',
  pure: true
})
export class TimePipe implements PipeTransform {
  transform(epoch: number, timezone: string) {
    const userTimezone = timezone ? timezone : Intl.DateTimeFormat().resolvedOptions().timeZone;

    return format(utcToZonedTime(fromUnixTime(epoch), userTimezone), 'HH:mm');
  }
}
