import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BucketDetails } from '../../../model/dashoard.model';

@Component({
  selector: 'app-tab-headers',
  templateUrl: './tab-headers.component.html',
  styleUrls: ['./tab-headers.component.scss']
})
export class TabHeadersComponent {
  @Input() bucketDetails: BucketDetails[];
  @Input() dates: Dates;
  @Input() bucketSelected: string;
  @Input() unreadJobFilter = {
    hasUnreadJobs: false,
    isUnreadFilterActivated: false
  };
  @Output() scrollBucket: EventEmitter<string> = new EventEmitter();
  @Output() filterUnreadJobs: EventEmitter<boolean> = new EventEmitter();
  isSmallScreen = false;

  setUnreadJobsTooltipMessage(): string {
    if (!this.unreadJobFilter.hasUnreadJobs) {
      return 'No unread jobs to display';
    }
    return this.unreadJobFilter.isUnreadFilterActivated ? 'View all jobs' : 'View unread jobs';
  }

  onClickUnreadFilterButton(): void {
    this.unreadJobFilter.isUnreadFilterActivated = !this.unreadJobFilter.isUnreadFilterActivated;
    this.filterUnreadJobs.emit(this.unreadJobFilter.isUnreadFilterActivated);
  }
}

export interface Dates {
  oldestReceivedOn: number;
  newestReceivedOn: number;
  timezone: string;
}
