import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { ToolbarService } from '../../../services/toolbar.service';
import { UserService } from '../../../services/user.service';
import { PhotoReference } from '../../../model/job.model';
import { EscapeDialogHelper } from '../../../helpers/escape-dialog.helper';

@Component({
  selector: 'app-submit-document',
  templateUrl: './submit-document.component.html',
  styleUrls: ['./submit-document.component.scss']
})
export class SubmitDocumentComponent implements OnInit {
  submitting = false;
  errorMessage = false;
  assessorName = this.data.assessor;
  unSubmittedPhotos = this.data.unSubmittedPhotos;
  unSubmittedQuote = this.data.unSubmittedQuote;
  unSubmittedInvoice = this.data.unSubmittedInvoice;
  unSubmittedDiary = this.data.unSubmittedDiary;
  sendingDocumentsMessage = '';
  noQuoteResponse = this.data.job.noQuoteResponse;
  noQuoteResponseAssessmentAction = this.data.job.noQuoteResponseAssessmentAction;
  booking = this.data.job.booking;
  selfAuthorise = this.data.job.enableSelfAuthorityCurtain;

  constructor(
    public dialogRef: MatDialogRef<SubmitDocumentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UserService,
    public toolbarService: ToolbarService,
    private escapeDialogRef: EscapeDialogHelper
  ) {}

  ngOnInit(): void {
    this.sendingDocumentsMessage = this.createSendingMessage();
    this.escapeDialogRef.escapeDialog(this.dialogRef);
  }

  createPhotoMessage(photos: PhotoReference[]) {
    const numPhotos = photos.length;
    let message = '';

    if (numPhotos === 1) {
      message = '1 photo';
    } else {
      message = numPhotos + ' photos';
    }
    return message;
  }

  createQuoteMessage(quote) {
    return 'Quote # ' + quote.document.quoteNumber;
  }

  createInvoiceMessage(invoice) {
    return 'Invoice # ' + invoice.document.invoiceNumber;
  }

  createSendingMessage() {
    let message = '';
    if (
      this?.unSubmittedInvoice &&
      this?.unSubmittedQuote &&
      this.unSubmittedPhotos.length > 0 &&
      this?.unSubmittedDiary
    ) {
      message =
        this.createInvoiceMessage(this.unSubmittedInvoice) + ', ' + this.createQuoteMessage(this.unSubmittedQuote);
    } else if (this?.unSubmittedQuote && this.unSubmittedPhotos.length > 0 && this?.unSubmittedDiary) {
      message = this.createQuoteMessage(this.unSubmittedQuote);
    } else if (this?.unSubmittedInvoice && this.unSubmittedPhotos.length > 0 && this?.unSubmittedDiary) {
      message = this.createInvoiceMessage(this.unSubmittedInvoice);
    } else if (this?.unSubmittedInvoice && this?.unSubmittedQuote) {
      message =
        this.createInvoiceMessage(this.unSubmittedInvoice) +
        ', ' +
        this.createQuoteMessage(this.unSubmittedQuote) +
        ', ';
    } else if (this?.unSubmittedQuote) {
      message = this.createQuoteMessage(this.unSubmittedQuote);
    } else if (this?.unSubmittedInvoice) {
      message = this.createInvoiceMessage(this.unSubmittedInvoice);
    }

    if (this.unSubmittedPhotos.length > 0) {
      if (message === '') {
        message = this.createPhotoMessage(this.unSubmittedPhotos);
      } else {
        if (!this.unSubmittedDiary && !this.noQuoteResponse) {
          message += ' and ' + this.createPhotoMessage(this.unSubmittedPhotos);
        } else {
          message += ', ' + this.createPhotoMessage(this.unSubmittedPhotos);
        }
      }
    }

    if (this?.unSubmittedDiary) {
      if (this.unSubmittedInvoice === 0 && this.unSubmittedQuote === 0 && this.unSubmittedPhotos.length === 0) {
        message += 'A diary';
      } else {
        this.noQuoteResponseAssessmentAction ? (message += ', a diary') : (message += ' and a diary');
      }
    }

    if (this.selfAuthorise) {
      if (message === '') {
        message += 'A Self-Authorise notification';
      } else {
        message += ' and a Self-Authorise notification';
      }
    }

    if (this.noQuoteResponse) {
      if (this.noQuoteResponseAssessmentAction) {
        if (message === '') {
          message += this.noQuoteResponseAssessmentAction;
        } else {
          message += ' and a ' + this.noQuoteResponseAssessmentAction;
        }
      }
    }

    if (this.booking) {
      if (message === '') {
        message += 'Booking update';
      } else {
        message += ' and a booking update';
      }
    }

    return message + ' will be sent to ' + this.assessorName + '.';
  }

  submitJob() {
    this.submitting = true;

    this.toolbarService.setCloudState('SAVING');
    this.userService.submitJob(this.data.jobId).subscribe(
      () => this.toolbarService.setCloudState('RESTING'),
      () => {
        this.submitting = false;
        this.errorMessage = true;
        this.toolbarService.setCloudState('RESTING');
      },
      () => {
        this.submitting = false;
        this.dialogRef.close('Success');
      }
    );
  }
}
