<div class="booking-card">
  <mat-card class="curtains">
    <div class="booking-header secondary-icon">
      <h5>Booking on-site inspection</h5>
      <div class="booking-kebab-panel">
        <button mat-icon-button (click)="confirmDeleteBooking()">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
    <mat-card-content>
      <form [formGroup]="bookingForm">
        <div class="card-body">
          <mat-form-field appearance="outline">
            <mat-label>Booking Options</mat-label>
            <mat-select
              formControlName="bookingOption"
              (selectionChange)="updateJob('bookingOption')"
              required
              [disabled]="!job.inspectionDate">
              <mat-option value="PROPOSE_ON_SITE_INSPECTION" *ngIf="!onSite">Propose on-site inspection </mat-option>
              <mat-option value="PROPOSE_NEW_DATE" *ngIf="onSite">Propose new date</mat-option>
              <mat-option
                value="CANCELLED_DUE_TO_NO_SHOW"
                *ngIf="onSite && job.inspectionDate && !hasQuote && !inspectionDateInPast"
                >Cancelled due to no show
              </mat-option>
            </mat-select>
            <mat-error>Booking option is <strong>required</strong></mat-error>
          </mat-form-field>
          <div class="cancel-booking-message subtitle-2" *ngIf="bookingOption.value === 'CANCELLED_DUE_TO_NO_SHOW'">
            The insurance company will follow-up on this claim.
          </div>
          <mat-form-field
            appearance="outline"
            *ngIf="bookingOption.value !== 'CANCELLED_DUE_TO_NO_SHOW'"
            class="medium-field primary-icon"
            color="accent">
            <mat-label>Proposed Date</mat-label>
            <input
              (keypress)="dateInputHelper.restrictDateInput($event)"
              [matDatepicker]="inspectionDatepicker"
              [min]="minDate"
              [matDatepickerFilter]="availableDatesFilter"
              formControlName="proposedDate"
              matInput />
            <mat-datepicker-toggle [for]="inspectionDatepicker" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #inspectionDatepicker></mat-datepicker>
            <mat-hint>Date format DD/MM/YYYY</mat-hint>
            <mat-error *ngIf="proposedDate.hasError('required') && !proposedDate.hasError('matDatepickerParse')">
              Proposed date is <strong>required</strong>
            </mat-error>
            <mat-error
              *ngIf="
                proposedDate.hasError('matDatepickerFilter') &&
                !proposedDate.hasError('matDatepickerParse') &&
                !proposedDate.hasError('required')
              ">
              Proposed date is not available
            </mat-error>
            <mat-error
              *ngIf="
                proposedDate.hasError('matDatepickerMin') &&
                !proposedDate.hasError('matDatepickerFilter') &&
                !proposedDate.hasError('matDatepickerParse') &&
                !proposedDate.hasError('required')
              ">
              Proposed date is in the past
            </mat-error>
            <mat-error *ngIf="proposedDate.hasError('matDatepickerParse')">
              The date format is <strong>DD/MM/YYYY</strong>
            </mat-error>
          </mat-form-field>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
