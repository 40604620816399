import { Component, inject, signal, WritableSignal } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DynamicDialogService } from './dynamic-dialog-service';

@Component({ template: '' })
export abstract class AbstractDynamicDialogComponent<T = unknown> {
  disabled: WritableSignal<boolean> = signal(false);
  thirdButtonDisable: WritableSignal<boolean> = signal(false);
  data: T = inject(MAT_DIALOG_DATA);
  form: UntypedFormGroup;
  dynamicDialogService = inject(DynamicDialogService);
  abstract saveOrExecuteAction(): void;
  extraAction?(): void;
}
