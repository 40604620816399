<h3>Repairers</h3>

<section class="my-20 mx-15">
  <ng-container *ngFor="let site of currentUserService.currentUserValue.repairerSites">
    <mat-card
      class="p-10 flex flex-col justify-between hover:cursor-pointer"
      [ngClass]="{ 'pointer-events-none': site?.status !== 'ACTIVE' }"
      (click)="accessRepairSite(site)">
      <div class="flex flex-col items-stretch gap-10">
        <p class="subtitle-1 m-0">{{ site?.companyName }}</p>
        <div class="flex items-center body-2 text-dove-gray">
          <mat-icon color="accent" class="custom-mat-icon mat-icon-xs mr-5">account_circle</mat-icon>
          <span>{{ site?.mainContact.firstName }} {{ site?.mainContact.lastName }}</span>
        </div>
        <div class="flex items-center body-2 text-dove-gray">
          <mat-icon color="accent" class="custom-mat-icon mat-icon-xs mr-5">call</mat-icon>
          <span>{{ site?.phoneNumber }}</span>
        </div>
        <div class="flex body-2 text-dove-gray">
          <mat-icon color="accent" class="custom-mat-icon mat-icon-xs mr-5">home</mat-icon>
          <span>{{ site?.addressString }}</span>
        </div>
      </div>

      <div class="flex justify-end mt-10">
        <div
          class="rounded-full body-1 px-30 py-10 text-white"
          [ngClass]="{
            'bg-salem': site?.status === 'ACTIVE',
            'bg-secondary-200': site?.status === 'PENDING',
            'bg-primary-500': site?.status === 'ARCHIVED'
          }">
          {{ site?.status }}
        </div>
      </div>
    </mat-card>
  </ng-container>
</section>
