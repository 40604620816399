export const quoteCurtainPartColumnHeadersLargeScreen = [
  'partDescription',
  'partNumber',
  'partSource',
  'assessorNote',
  'quantity',
  'estimateAmount',
  'estimateMarkup',
  'assessedAmount',
  'assessedMarkup'
];

export const quoteCurtainPartColumnHeadersSmallScreen = [
  'partDescriptionNumber',
  'partSource',
  'assessorNote',
  'quantity',
  'estimateAmount',
  'estimateMarkup',
  'assessedAmount',
  'assessedMarkup'
];

export const invoiceCurtainPartColumnHeadersLargeScreen = [
  'partDescription',
  'partNumber',
  'partSource',
  'assessorNote',
  'assessedAmount',
  'invoicedAmount',
  'approvedAmount'
];

export const invoiceCurtainPartColumnHeadersSmallScreen = [
  'partDescriptionNumber',
  'partSource',
  'assessorNote',
  'assessedAmount',
  'invoicedAmount',
  'approvedAmount'
];
