import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { User } from '../../model/user.model';
import { CurrentUserService } from '../../services/currentUser.service';

@Component({
  selector: 'app-view-eula',
  templateUrl: './view-eula.component.html',
  styleUrls: ['./view-eula.component.scss']
})
export class ViewEulaComponent implements OnInit {
  currentUser: User;
  timezone: string;
  eulaContent: SafeHtml = '';

  constructor(
    private currentUserService: CurrentUserService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.currentUser = this.currentUserService.currentUserValue;
    this.timezone = this.currentUserService?.timeZone;
    fetch('assets/eula/EULA.html')
      .then((res) => res.text())
      .then((data) => {
        this.eulaContent = this.sanitizer.bypassSecurityTrustHtml(data);
      });
  }
}
