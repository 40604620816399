export const ICONS = [
  { iconName: 'sharing_paused', iconPath: 'assets/images/sharing_paused.svg' },
  { iconName: 'pdf_icon', iconPath: 'assets/images/pdf_icon.svg' },
  { iconName: 'txt_icon', iconPath: 'assets/images/txt_icon.svg' },
  { iconName: 'docx_icon', iconPath: 'assets/images/docx_icon.svg' },
  { iconName: 'xlsx_icon', iconPath: 'assets/images/xlsx_icon.svg' },
  { iconName: 'jpeg_icon', iconPath: 'assets/images/jpeg_icon.svg' },
  { iconName: 'jpg_icon', iconPath: 'assets/images/jpg_icon.svg' },
  { iconName: 'png_icon', iconPath: 'assets/images/png_icon.svg' },
  { iconName: 'next_icon', iconPath: 'assets/images/next_icon.svg' },
  { iconName: 'back_icon', iconPath: 'assets/images/back_icon.svg' },
  { iconName: 'migration_progress_icon', iconPath: 'assets/images/migration_progress_icon.svg' },
  { iconName: 'car_placeholder_icon', iconPath: 'assets/images/car-placeholder.svg' },
  { iconName: 'folder_error', iconPath: 'assets/images/folder_error.svg' },
  { iconName: 'folder_plus', iconPath: 'assets/images/folder_plus.svg' },
  { iconName: 'folder_edit', iconPath: 'assets/images/folder_edit.svg' },
  { iconName: 'total_loss_icon', iconPath: 'assets/images/total_loss_icon.svg' },
  { iconName: 'description', iconPath: 'assets/images/description_white.svg' },
  { iconName: 'allianz', iconPath: 'assets/images/allianz.svg' },
  { iconName: 'unread_job', iconPath: 'assets/images/unread_job.svg' },
  { iconName: 'chat_black_notification', iconPath: 'assets/images/chat_black_notification.svg' },
  { iconName: 'estimage_pay', iconPath: 'assets/images/estimage_pay_logo.svg' }
];
