import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AcceptEulaComponent } from './accept-eula/accept-eula.component';
import { AuthGuard } from './auth/auth.guard';
import { AuthGuardDashboard } from './auth/auth.guard.dashboard';
import { AuthGuardMladmin } from './auth/auth.guard.mladmin';
import { AuthGuardRepadmin } from './auth/auth.guard.repadmin';
import { AuthGuardSysadmin } from './auth/auth.guard.sysadmin';
import { JobViewerComponent } from './dashboard/job-viewer/job-viewer.component';
import { JobsReportsComponent } from './dashboard/jobsReports/jobs-reports.component';
import { LocationAdminComponent } from './dashboard/location-admin/location-admin.component';
import { MladminDashboardComponent } from './dashboard/mladmin-dashboard/mladmin-dashboard.component';
import { RepairerDashboardComponent } from './dashboard/repairer-dashboard/repairer-dashboard.component';
import { ShellComponent } from './dashboard/shell.component';
import { SysadminDashboardComponent } from './dashboard/sysadmin-dashboard/sysadmin-dashboard.component';
import { LoginComponent } from './login/login.component';
import { MonitorComponent } from './monitor/monitor.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SetPasswordComponent } from './set-password/set-password.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: '',
    component: ShellComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'monitor',
        component: MonitorComponent,
        canActivate: [AuthGuardSysadmin]
      },
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: 'sysadmin',
        component: SysadminDashboardComponent,
        canActivate: [AuthGuardSysadmin]
      },
      {
        path: 'mladmin',
        component: MladminDashboardComponent,
        canActivate: [AuthGuardMladmin]
      },
      {
        path: 'dashboard',
        component: RepairerDashboardComponent,
        canActivate: [AuthGuardDashboard]
      },
      {
        path: 'locationAdmin',
        component: LocationAdminComponent,
        canActivate: [AuthGuardRepadmin]
      },
      {
        path: 'jobViewer/:id',
        component: JobViewerComponent
      },
      {
        path: 'jobViewer',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: 'claimList',
        component: JobsReportsComponent
      }
    ]
  },
  {
    path: 'setPassword/:name/:nonce/:expiry',
    component: SetPasswordComponent
  },
  {
    path: 'resetPassword',
    component: ResetPasswordComponent
  },
  {
    path: 'acceptEula',
    component: AcceptEulaComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { bindToComponentInputs: true })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
