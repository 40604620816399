import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import Autocomplete = google.maps.places.Autocomplete;
import MapsEventListener = google.maps.MapsEventListener;
import { AddressHelper } from '../helpers/address.helper';
import { noop } from 'rxjs';
import { Address, CountryEnum } from '../model/address.model';

@Directive({
  selector: '[appAddressAutocomplete]'
})
export class AddressAutocompleteDirective implements OnInit, OnDestroy {
  @Input('appAddressAutocomplete') address: Address;
  @Output() chosenAddress: EventEmitter<Address> = new EventEmitter<Address>();
  private autocomplete: Autocomplete;
  private autocompleteListener: MapsEventListener;

  constructor(
    private el: ElementRef,
    private addressHelper: AddressHelper
  ) {}

  ngOnInit() {
    const input: HTMLElement = this.el.nativeElement;

    if (input instanceof HTMLInputElement) {
      const options = {
        componentRestrictions: { country: CountryEnum[this.address.country] ?? 'AU' },
        strictBounds: false
      };
      this.autocomplete = new google.maps.places.Autocomplete(input, options);

      this.autocompleteListener = this.autocomplete.addListener('place_changed', () => {
        const place = this.autocomplete.getPlace();

        if (place.geometry?.location) {
          const address: Address = this.addressHelper.getAddressFromGooglePlaceResult(
            place.address_components,
            this.address
          );
          address.latitude = place.geometry.location.lat();
          address.longitude = place.geometry.location.lng();

          this.chosenAddress.emit(address);
        }
      });
    } else {
      console.error(
        '%caddressAutocomplete%c directive should be added only to Input Elements',
        'background: #222; color: #bada55',
        'background: transparent; color: red'
      );
    }
  }

  ngOnDestroy() {
    this.autocompleteListener ? google.maps.event.removeListener(this.autocompleteListener) : noop();
    this.autocomplete ? google.maps.event.clearInstanceListeners(this.autocomplete) : noop();
    document.querySelector('.pac-container')?.remove();
  }
}
