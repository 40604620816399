import { MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { CommonDialogConfig } from "../model/dialogData.model";

const commonConfigs : CommonDialogConfig = {
  panelClass: null,
  backdropClass: null,
  autoFocus: false,
  disableClose: true,
  restoreFocus: true,
  data: null
};

export const extraWideModalConfig: MatDialogConfig = {
  width: '1280px',
  ...commonConfigs
};

export const wideModalConfig: MatDialogConfig = {
  width: '750px',
  ...commonConfigs
};

export const mediumModalConfig: MatDialogConfig = {
  width: '360px',
  ...commonConfigs
};

export const smallModalConfig: MatDialogConfig = {
  width: '275px',
  ...commonConfigs
};
