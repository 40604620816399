import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../../auth/auth.service';
import { MessagingService } from '../../../components/messaging/messaging.service';
import { EscapeDialogHelper } from '../../../helpers/escape-dialog.helper';

@Component({
  selector: 'app-password-confirmation',
  templateUrl: './password-confirmation.component.html',
  styleUrls: ['./password-confirmation.component.scss']
})
export class PasswordConfirmationComponent implements OnInit {
  passwordForm: UntypedFormGroup;
  pending = false;

  constructor(
    public dialogRef: MatDialogRef<PasswordConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder,
    private auth: AuthService,
    private message: MessagingService,
    private escapeDialogRef: EscapeDialogHelper
  ) {}

  get password() {
    return this.passwordForm.get('password');
  }

  ngOnInit(): void {
    this.passwordForm = this.fb.group({
      password: ['', [Validators.required]]
    });

    this.escapeDialogRef.escapeDialog(this.dialogRef);
  }

  confirm() {
    const username: string = this.data.username;
    const password: string = this.password.value.trim();

    if (username && password) {
      this.pending = true;

      this.auth.login({ username, password }).subscribe(
        () => this.dialogRef.close({ result: 'Confirmed', value: password }),
        (error) => {
          if (error.status === 403) {
            // Auth Provider rejected the credentials
            this.password.setErrors({ notValid: true });
          } else {
            // There was am unexpected problem
            this.message.error('There was a problem checking password. Please try again.');
          }
          this.pending = false;
        }
      );
    } else {
      this.message.error('There was a problem checking password. Please try again.');
    }
  }
}
