import { Directive, HostListener, Input } from '@angular/core';
import { NAVIGATION_KEYS } from '../consts/keys.const';

@Directive({
  selector: '[appNumbersOnly]'
})
export class NumbersOnlyDirective {
  constructor() {}

  _enabled = true;

  get enabled() {
    return this._enabled;
  }

  @Input('numbersOnly')
  set enabled(v: string | boolean) {
    if (v !== null && v !== '') {
      this._enabled = v as boolean;
    }
  }

  @HostListener('keydown', ['$event']) onInputChange(event) {
    if (this.enabled) {
      if (this.isAcceptedKey(event)) {
        return;
      }
      if (event.key === ' ' || isNaN(Number(event.key))) {
        event.preventDefault();
      }
    }
  }

  @HostListener('drop', ['$event'])
  @HostListener('paste', ['$event'])
  onDropPaste(event) {
    if (this.enabled) {
      const value =
        event instanceof DragEvent ? (event.target as HTMLInputElement).value : event.clipboardData.getData('text');
      if (/\d/.test(value)) {
        return;
      } else {
        event.preventDefault();
      }
    }
  }

  private isAcceptedKey(event: KeyboardEvent): boolean {
    return (
      NAVIGATION_KEYS.indexOf(event.key) > -1 ||
      (event.key === 'a' && event.ctrlKey === true) ||
      (event.key === 'c' && event.ctrlKey === true) ||
      (event.key === 'v' && event.ctrlKey === true) ||
      (event.key === 'x' && event.ctrlKey === true) ||
      /\d/.test(event.key)
    );
  }
}
