export const sortingDataAccessor = (item, property) => {
  if (property.includes('.')) {
    if (property === 'mainContact.firstName') {
      return (item.mainContact.firstName + item.mainContact.lastName).toLowerCase();
    }
    return property
      .split('.')
      .reduce((object, key) => object[key], item)
      .toLowerCase();
  }
  return item[property] ? item[property].toString().toLowerCase() : '';
};
