<mat-card class="curtains">
  <div class="potential-breach-of-code-header secondary-icon">
    <h5>Potential Breach of Code</h5>
    <div class="customer-panel">
      <button mat-icon-button (click)="confirmDeletePotentialBreachOfCode()">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </div>
  <mat-card-content>
    <form [formGroup]="potentialBreachOfCodeForm" class="gicop">
      <div class="field-rows first-row">
        <mat-form-field class="inputField" appearance="outline" color="accent">
          <mat-label>Feedback raised by</mat-label>
          <input matInput autocomplete="off" formControlName="raisedBy" maxlength="128" required />
          <mat-error *ngIf="raisedBy.invalid">Feedback raised is <strong>required</strong></mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Date of breach</mat-label>
          <input
            (keypress)="dateInputHelper.restrictDateInput($event)"
            [matDatepicker]="dateOfIncidentDatePicker"
            required
            formControlName="dateOfIncident"
            matInput />
          <mat-datepicker-toggle [for]="dateOfIncidentDatePicker" matSuffix></mat-datepicker-toggle>
          <mat-datepicker #dateOfIncidentDatePicker></mat-datepicker>
          <mat-hint *ngIf="dateOfIncident.enabled">Date format DD/MM/YYYY</mat-hint>
          <mat-error *ngIf="dateOfIncident.hasError('matDatepickerParse')">
            The date format is <strong>DD/MM/YYYY</strong>
          </mat-error>
          <mat-error *ngIf="dateOfIncident.hasError('required') && !dateOfIncident.hasError('matDatepickerParse')">
            Date of breach is <strong>required</strong>
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Date discovered</mat-label>
          <input
            (keypress)="dateInputHelper.restrictDateInput($event)"
            [matDatepicker]="dateDiscoveredDatePicker"
            required
            formControlName="dateDiscovered"
            matInput />
          <mat-datepicker-toggle [for]="dateDiscoveredDatePicker" matSuffix></mat-datepicker-toggle>
          <mat-datepicker #dateDiscoveredDatePicker></mat-datepicker>
          <mat-hint *ngIf="dateDiscovered.enabled">Date format DD/MM/YYYY</mat-hint>
          <mat-error *ngIf="dateDiscovered.hasError('matDatepickerParse')">
            The date format is <strong>DD/MM/YYYY</strong>
          </mat-error>
          <mat-error *ngIf="dateDiscovered.hasError('required') && !dateDiscovered.hasError('matDatepickerParse')">
            Date discovered is <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="field-rows second-row">
        <mat-form-field appearance="outline">
          <mat-label>Part of the Code</mat-label>
          <mat-select formControlName="partOfCode" required>
            <mat-option *ngFor="let part of partOfCodeEnum | keyvalue" [value]="part.key">
              <span>{{ part.value }}</span>
            </mat-option>
          </mat-select>
          <mat-error *ngIf="partOfCode.invalid">Part of the code is <strong>required</strong></mat-error>
        </mat-form-field>
        <mat-form-field class="inputField" appearance="outline" color="accent">
          <mat-label>Paragraph</mat-label>
          <input matInput autocomplete="off" formControlName="codeOfPracticeParagraph" maxlength="128" required />
          <mat-error *ngIf="codeOfPracticeParagraph.invalid">Part of the code is <strong>required</strong></mat-error>
        </mat-form-field>
      </div>
      <div>
        <div class="body-2 code-practice">
          Based on
          <a
            href="https://insurancecode.org.au/app/uploads/2021/10/GI-Code-2020-5-October-2021-Version.pdf"
            rel="noopener"
            target="_blank"
            >General Insurance code of practice 2020</a
          >
        </div>
        <mat-form-field class="textarea-fields" appearance="outline" color="accent">
          <mat-label>Description of potential breach</mat-label>
          <textarea
            matInput
            autocomplete="off"
            formControlName="description"
            maxlength="65535"
            cdkTextareaAutosize="true"
            cdkAutosizeMinRows="1"
            required></textarea>
          <mat-error *ngIf="description.invalid"
            >Description of potential breach is <strong>required</strong></mat-error
          >
        </mat-form-field>
      </div>
    </form>
  </mat-card-content>
</mat-card>
