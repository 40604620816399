import { Component, effect, EventEmitter, inject, Input, Output, ViewChild } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { User } from '../../../model/user.model';
import { LocationAdminStore } from '../location.admin.store.component';

@Component({
  selector: 'user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent {
  @Input() repairSiteStatus: string;
  @Input() currentUser: User;
  @Output() addEditUser: EventEmitter<any> = new EventEmitter<any>();
  @Output() enableDisableUser: EventEmitter<{ user: User; disable: boolean }> = new EventEmitter<any>();
  @Output() confirmDeleteInvitedUser: EventEmitter<string> = new EventEmitter<any>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  usersDataSource: MatTableDataSource<User>;
  usersColumnHeaders = ['permission', 'username', 'firstName', 'lastName', 'phone', 'email', 'isActivated', 'kebab'];
  activeColumn = 'permission';

  locationAdminStore = inject(LocationAdminStore);

  constructor() {
    effect(() => {
      if (this.locationAdminStore.usersOfRepairerSite().length) {
        this.usersDataSource = new MatTableDataSource(this.locationAdminStore.usersOfRepairerSite());
        this.usersDataSource.sortingDataAccessor = this.sortingDataAccessor;
        this.usersDataSource.sort = this.sort;
      }
    });
  }
  sortingDataAccessor(item, property) {
    if (property === 'permission') {
      return item.privileges.length > 0 ? 'Administrator' : 'Repairer';
    } else if (property === 'isActivated') {
      return item.isActivated ? (item.status === 'ENABLED' ? 'Active' : item.status) : 'Invited';
    }
    return item[property];
  }

  onSortEvent(event) {
    this.activeColumn = event.active;
  }
}
