import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, interval, NEVER, Subject } from 'rxjs';
import { CloudState } from '../dashboard/cloudState.model';
import { HttpClient } from '@angular/common/http';
import { switchMap, timeout } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';

@Injectable({ providedIn: 'root' })
export class ToolbarService {
  public isOnline = true;
  private toolbarDataSource = new BehaviorSubject<any>({});
  toolbarDataReceived$ = this.toolbarDataSource.asObservable();
  pausePingInterval: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private editing = new Subject<CloudState>();
  cloudState$ = this.editing.asObservable();

  private http = inject(HttpClient);
  private auth = inject(AuthService);

  constructor() {
    // Offline poller
    this.pausePingInterval.pipe(switchMap((paused) => (paused ? NEVER : interval(30000)))).subscribe(() => {
      if (!navigator.onLine) {
        return (this.isOnline = false);
      } else {
        // You can test a time-out with this tool. The format is /time-to-respond-in-ms/http-code
        // this.http.head('http://timeout-as-a-service.herokuapp.com/8000/200')
        if (document.hasFocus()) {
          this.http
            .head('/ping')
            .pipe(timeout(3000))
            .subscribe(
              () => (this.isOnline = true),
              () => (this.isOnline = false)
            );
        }
      }
    });

    this.setCloudState('RESTING');
  }

  toolbarData(data: any) {
    this.toolbarDataSource.next(data);
  }

  setCloudState = (state: CloudState) => {
    this.editing.next(state);

    if (state === 'SAVING') {
      this.auth.refresh().subscribe();
    }
  };
}
