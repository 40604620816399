<div class="dialog-header" mat-dialog-title>
  <h6>{{ data?.user ? 'Edit User' : 'Add New User' }}</h6>
  <button aria-label="Close" mat-dialog-close mat-icon-button>
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-divider></mat-divider>
<form (ngSubmit)="saveUpdateUser()" [formGroup]="addUserForm">
  <div class="add-user-container" mat-dialog-content>
    <div [class.padding-bottom-12]="!username.valid && !username.hasError('required')" class="fields-box">
      <mat-form-field appearance="outline" color="accent">
        <mat-label>Username</mat-label>
        <input formControlName="username" matInput required />
        <mat-error *ngIf="username.hasError('required')"> Username is <strong>required</strong> </mat-error>
        <mat-error *ngIf="!username.valid && !username.hasError('required')">
          This username exists already in the system
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" color="accent">
        <mat-label>First Name</mat-label>
        <input formControlName="firstName" matInput required />
        <mat-error *ngIf="firstName.hasError('required')"> First Name is <strong>required</strong> </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" color="accent">
        <mat-label>Last Name</mat-label>
        <input formControlName="lastName" matInput required />
        <mat-error *ngIf="lastName.hasError('required')"> Last Name is <strong>required</strong> </mat-error>
      </mat-form-field>
    </div>

    <div class="fields-box">
      <mat-form-field appearance="outline" color="accent">
        <mat-label>Email</mat-label>
        <input formControlName="email" matInput required />
        <mat-error *ngIf="email.hasError('required')"> Email is <strong>required</strong> </mat-error>
        <mat-error *ngIf="email.hasError('pattern') && !email.hasError('required')" class="mat-error">
          Please enter a valid email address
        </mat-error>
        <mat-error *ngIf="email.hasError('duplicate') && !email.hasError('required')" class="mat-error">
          This email exists already in the system
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" color="accent">
        <mat-label>Phone Number</mat-label>
        <input formControlName="phone" matInput />
      </mat-form-field>
    </div>

    <div *ngIf="!data?.user?.privileges.includes('MLADMIN')" class="mt-15">
      <div class="permission small-field">
        <mat-form-field appearance="outline" color="accent">
          <mat-label>Permission</mat-label>
          <mat-select formControlName="permission">
            <mat-option value="User">Repairer</mat-option>
            <mat-option value="REPADMIN">Administrator</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="primary-contact-box" *ngIf="data?.user?.isActivated && data?.user?.status === 'ENABLED'">
        <mat-checkbox color="primary" [checked]="primaryContact.value" formControlName="primaryContact"
          >Primary Contact of this location
        </mat-checkbox>
        <div class="contact-info">
          This person can be contacted by Stelvio to deal with issues related to your location
        </div>
      </div>

      <div class="email-info" *ngIf="!data?.user.isActivated">
        <mat-icon>error_outline</mat-icon>
        <span>An email will be sent to this email address to complete account information</span>
      </div>
    </div>
  </div>

  <mat-dialog-actions>
    <button mat-raised-button mat-dialog-close color="accent" type="button">CANCEL</button>
    <button [disabled]="addUserForm.invalid || pending" class="buttons" color="primary" mat-raised-button type="submit">
      {{ !data || !data?.user.isActivated ? 'INVITE' : 'SAVE' }}
    </button>
  </mat-dialog-actions>
</form>
