<div class="car">
  <ng-container *ngIf="photoInfo?.photoIdentityId">
    <img
      [hidden]="!photoLoaded[jobInfo?.jobId]"
      [ngClass]="{ 'car-img-custom-size': isInRepairerTile, 'car-img-small-size': jobInfo?.rego, 'car-img': true }"
      appRetrySrc="/jobAttachments/{{ jobInfo?.repairerSiteId }}/{{ jobInfo?.jobId }}/thumb_{{
        photoInfo?.photoIdentityId
      }}.{{ photoInfo?.photoIdentityExtension }}"
      (error)="photoLoaded[jobInfo?.jobId] = false"
      (load)="photoLoaded[jobInfo?.jobId] = true" />
  </ng-container>
  <mat-icon
    *ngIf="!photoInfo?.photoIdentityId || !photoLoaded[jobInfo?.jobId]"
    [ngClass]="{
      'car-placeholder-small-size': jobInfo?.rego && !isInRepairerTile,
      'car-placeholder': true,
      'car-placeholder-custom-size': isInRepairerTile
    }"
    svgIcon="car_placeholder_icon"></mat-icon>
  <div
    *ngIf="jobInfo?.rego"
    class="body-2 rego-span"
    [ngClass]="{
      'car-rego-top-spaced': photoInfo?.photoIdentityId && !isInRepairerTile,
      'car-rego-top-spaced-13': photoInfo?.photoIdentityId && isInRepairerTile,
      'car-rego-padding-added': isInRepairerTile
    }">
    {{ jobInfo?.rego }}
  </div>
</div>
