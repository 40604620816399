<mat-calendar-header></mat-calendar-header>
<div class="top-tile body-2">Exact Date</div>
<mat-card class="range-box">
  <div class="body-2">relative</div>
  <div class="chips-row">
    <button
      [class.selected]="range === selectedDateRange"
      class="subtitle-2"
      *ngFor="let range of rangePresets"
      mat-button
      (click)="selectRange(range)">
      {{ range }}
    </button>
  </div>
</mat-card>
