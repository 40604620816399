import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TimelineBox } from '../../../../model/TimelineBox.model';

@Component({
  selector: 'app-repair-timeline-box',
  templateUrl: './repair-timeline-box.component.html',
  styleUrls: ['./repair-timeline-box.component.scss']
})
export class RepairTimelineBoxComponent {
  @Input() timelineBox: TimelineBox;
  @Input() currentTimezone: string;
  @Input() editDisabled: boolean;
  @Output() editRepairTimeline = new EventEmitter<void>();
  constructor() {}
}
