import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RepairerSiteHistory, RepairerSiteUpdate, SimpleUserUpdate, User, UserCreate } from '../model/user.model';
import { catchError, finalize, map } from 'rxjs/operators';
import { BankingDetails } from '../model/banking-details.model';
import { MessagingService } from '../components/messaging/messaging.service';
import { ToolbarService } from './toolbar.service';

@Injectable({
  providedIn: 'root'
})
export class RepairerAdminService {
  private http = inject(HttpClient);
  private messageService = inject(MessagingService);
  private toolbarService = inject(ToolbarService);

  private static handleError(error: any) {
    return Promise.reject(error.error || error.details || error);
  }

  getUsersOfRepairerSite() {
    return this.http.get<User[]>('ui/admin/usersOfRepairerSite').pipe(catchError(RepairerAdminService.handleError));
  }

  updateSimpleUser(user: SimpleUserUpdate, username: string) {
    return this.http.put(`ui/admin/updateRepUser/${username}`, user).pipe(catchError(RepairerAdminService.handleError));
  }

  deleteUser(username: string) {
    return this.http.post(`ui/admin/deleteUser/${username}`, null).pipe(catchError(RepairerAdminService.handleError));
  }

  createUser(user: UserCreate) {
    user.email = user.email.toLowerCase();

    return this.http.post('ui/admin/user', user).pipe(catchError(RepairerAdminService.handleError));
  }

  disableUser(username: string) {
    this.toolbarService.setCloudState('SAVING');
    return this.http.post(`ui/admin/disableRepUser/${username}`, null).pipe(
      map((result) => {
        this.messageService.confirm(`User disabled.`);
        return result;
      }),
      catchError((error) => {
        this.messageService.error(`Could not disable user.`);
        return RepairerAdminService.handleError(error);
      }),
      finalize(() => {
        this.toolbarService.setCloudState('RESTING');
      })
    );
  }

  enableUser(username: string) {
    return this.http
      .post(`ui/admin/enableRepUser/${username}`, null)
      .pipe(catchError(RepairerAdminService.handleError));
  }

  acceptEula(username: string) {
    return this.http
      .post(`ui/admin/users/${username}/acceptEULA`, null)
      .pipe(catchError(RepairerAdminService.handleError));
  }

  updateRepairerSiteWithAddress(repairerId: number, repairerSiteUpdate: RepairerSiteUpdate) {
    return this.http
      .put(`ui/admin/updateCore/repairerSites/${repairerId}`, repairerSiteUpdate)
      .pipe(catchError(RepairerAdminService.handleError));
  }

  publishRepairerSite() {
    return this.http.post('ui/admin/publishRepairerSite', null).pipe(catchError(RepairerAdminService.handleError));
  }

  updateBankingDetails(bankingDetails: BankingDetails) {
    return this.http
      .post('ui/admin/update/bankingDetails', bankingDetails)
      .pipe(catchError(RepairerAdminService.handleError));
  }

  getRepairerSiteHistory(repairerSiteId: number) {
    return this.http.get<RepairerSiteHistory[]>(`ui/admin/repairerSite/${repairerSiteId}/history`);
  }

  applyRepairerSiteToEstimagePay(email: string) {
    return this.http
      .post<{ onboardingUrl: string }>('ui/admin/repairerSite/estimagePay/onboarding', { email })
      .pipe(catchError(RepairerAdminService.handleError));
  }
}
