<div class="dialog-header" mat-dialog-title>
  <h6>Job History</h6>
  <button aria-label="Close" mat-dialog-close mat-icon-button>
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-divider></mat-divider>
<div mat-dialog-content>
  <mat-form-field appearance="outline">
    <mat-label>Type to Highlight</mat-label>
    <input [(ngModel)]="stringHighlight" matInput />
  </mat-form-field>
  <div *ngIf="jobHistory" class="history-table">
    <table [dataSource]="jobHistory" mat-table>
      <ng-container matColumnDef="timeStamp">
        <th *matHeaderCellDef class="time-stamp-header body-2" mat-header-cell>
          Timestamp
          <mat-icon inline="true">arrow_downward</mat-icon>
        </th>
        <td *matCellDef="let item" mat-cell class="body-1">
          <div class="time-stamp">
            {{ item.transactionDateTime | dateHistoryEpoch: timezone }} {{ item.transactionDateTime | time: timezone }}
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="action">
        <th *matHeaderCellDef class="action-header body-2" mat-header-cell>Action</th>
        <td *matCellDef="let item" mat-cell class="body-1">
          <mat-icon *ngIf="!(item?.description === ' ')" inline="true">{{
            item.inbound ? 'move_to_inbox' : 'send'
          }}</mat-icon>
          <span [innerHTML]="item.description | highlight: stringHighlight : 'highlight-string'"></span>
        </td>
      </ng-container>
      <tr *matHeaderRowDef="jobHistoryColumns" mat-header-row></tr>
      <tr *matRowDef="let row; columns: jobHistoryColumns" mat-row></tr>
    </table>
  </div>
</div>
<mat-dialog-actions align="end">
  <button color="primary" mat-dialog-close mat-raised-button>DONE</button>
</mat-dialog-actions>
