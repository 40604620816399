import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RepairProgressBuckets } from '../../../model/job.model';
import { UserRepairerSite } from '../../../model/user.model';

@Component({
  selector: 'app-repair-progress',
  templateUrl: './repair-progress.component.html',
  styleUrls: ['./repair-progress.component.scss']
})
export class RepairProgressComponent {
  @Input() buckets: RepairProgressBuckets;
  @Input() repairerSite: UserRepairerSite;
  @Output() updateBuckets: EventEmitter<boolean> = new EventEmitter();
  constructor() {}
}
