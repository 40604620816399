import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolbarService } from '../../services/toolbar.service';
import { Refreshable } from '../../model/refreshable.model';
import { MlAdminInfoComponent } from './mladmin-info/mladmin-info.component';
import { MultiLocalAdminService } from '../../services/ml-admin.service';
import { CurrentUserService } from '../../services/currentUser.service';
import { MlAdminRepairerSitesComponent } from './mladmin-repairer-sites/mladmin-repairer-sites.component';
import { SysAdminService } from '../../services/sys-admin.service';

@Component({
  selector: 'app-mladmin-dashboard',
  standalone: true,
  imports: [CommonModule, MlAdminInfoComponent, MlAdminRepairerSitesComponent],
  templateUrl: './mladmin-dashboard.component.html'
})
export class MladminDashboardComponent implements Refreshable, OnInit {
  loading: boolean;

  currentUserService = inject(CurrentUserService);
  private mlAdminService = inject(MultiLocalAdminService);
  private sysAdminService = inject(SysAdminService);
  private toolbarService = inject(ToolbarService);

  ngOnInit() {
    this.toolbarService.toolbarData({ pageName: 'Repairer Administration', backLink: '' });
    this.sysAdminService.repairerSiteAccessed()
      ? this.sysAdminService.revokeAccessRepairerSite().subscribe(() => this.refresh())
      : this.getCurrentMlAdminUser();
  }

  private getCurrentMlAdminUser() {
    this.mlAdminService.getMlAdmin(this.currentUserService.currentUserValue.username).subscribe();
  }

  refresh() {
    this.getCurrentMlAdminUser();
  }
}
