import { Component, OnInit, inject } from '@angular/core';
import { ToolbarService } from '../../services/toolbar.service';
import { UserService } from '../../services/user.service';
import { User, UserRepairerSite } from '../../model/user.model';
import { MessagingService } from '../../components/messaging/messaging.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Bucket, BucketDetails, Buckets, Dashboard } from '../../model/dashoard.model';
import { MatLegacyTabChangeEvent as MatTabChangeEvent } from '@angular/material/legacy-tabs';
import { JobHistory } from '../../model/jobHistory.model';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig
} from '@angular/material/legacy-dialog';
import { PickupJobComponent } from '../pickup-job/pickup-job.component';
import { InsurerPickUp } from '../../model/insurer.model';
import { GuidanceBoxComponent } from '../../components/guidance-box/guidance-box.component';
import { CurrentUserService } from '../../services/currentUser.service';
import { Job, RepairProgressBuckets } from '../../model/job.model';
import { DOCUMENT } from '@angular/common';
import { CleanActiveJobHelper } from '../../helpers/clean-active-job.helper';
import { INVOICING, QUOTING_BUCKET_DETAILS, WORK_AUTHORISED } from '../../consts/headers.const';
import { INVOICING_BUCKETS, QUOTING_BUCKETS, WORK_AUTHORISED_BUCKETS } from '../../consts/buckets.const';
import { mediumModalConfig, wideModalConfig } from '../../consts/modal-config.const';
import { ConfigureImportDirectoriesComponent } from '../../components/configure-import-directories/configure-import-directories.component';
import { BucketDetailsFilterFunction, BucketFilterFunction } from '../../types/bucketFilterFunction.type';

@Component({
  selector: 'app-repairer-dashboard',
  templateUrl: './repairer-dashboard.component.html',
  styleUrls: ['./repairer-dashboard.component.scss']
})
export class RepairerDashboardComponent implements OnInit {
  currentUser: User;
  timezone: string;
  repairerSite: UserRepairerSite;
  jobs: Dashboard;
  jobHistory: JobHistory[] = [];
  historyCount = 25;
  jobHistoryColumns = ['timeStamp', 'rego', 'owner', 'action'];
  canLoadMore = false;
  repairPickUpAvailable = false;
  insurersPickup: InsurerPickUp[];

  tabs = ['QUOTES', 'WORK_IN_PROGRESS', 'INVOICES', 'REPAIR_PROGRESS'];

  wipTabCounter = 0;
  quotesTabCounter = 0;
  invoicesTabCounter = 0;
  rpTabCounter = 0;
  oldestReceivedOn = null;
  newestReceivedOn = null;

  selectedTabIndex = 0; // by default the first tab Quotes is selected

  loading = false;
  loadingRepairerSite = false;
  loadingJobs = false;
  photoLoaded = {};
  bucketSelected = '';
  repairProgressJobs: RepairProgressBuckets;
  isSmallScreen = false;
  quotingBucketDetails = [];
  quotingBuckets = [];
  workAuthorisedBucketDetails = [];
  workAuthorisedBuckets = [];
  invoicingBucketDetails = [];
  invoicingBuckets = [];
  unreadJobFilter = {
    hasUnreadJobs: false,
    isUnreadFilterActivated: false
  };

  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private doc: Document = inject(DOCUMENT);
  private configureImport = inject(MatDialog);
  private matDialog = inject(MatDialog);
  private currentUserService = inject(CurrentUserService);
  private message = inject(MessagingService);
  private toolbarService = inject(ToolbarService);
  private userService = inject(UserService);
  private cleanActiveJobHelper = inject(CleanActiveJobHelper);

  ngOnInit() {
    this.currentUser = this.currentUserService.currentUserValue;
    this.timezone = this.currentUserService?.timeZone;
    this.loading = this.loadingRepairerSite = true;
    this.toolbarService.setCloudState('FETCHING');
    this.selectedTabIndex = parseInt(this.route.snapshot.queryParamMap.get('tab'), 10);
    if (isNaN(this.selectedTabIndex) || this.selectedTabIndex < 0) {
      this.selectedTabIndex = 0;
    }

    this.route.queryParamMap.subscribe((val) => {
      this.selectedTabIndex = parseInt(val.get('tab'), 10) ? parseInt(val.get('tab'), 10) : 0;
    });

    this.userService.getRepairerSiteForUser().subscribe({
      next: (repairerSite) => {
        this.repairerSite = repairerSite;
        this.currentUserService.timeZone = repairerSite.timezone;
      },
      error: () => {
        this.message.error('Could not retrieve the repairer site.');
        this.loadingRepairerSite = false;
        this.endOfLoading();
      },
      complete: () => {
        this.toolbarService.toolbarData({ pageName: 'Dashboard', backLink: '' });
        this.loadingRepairerSite = false;
        this.endOfLoading();
        this.getListOfInsurersForPickup();
      }
    });

    this.refresh();
  }

  getJobs(event?: string) {
    const tab = this.tabs[this.selectedTabIndex];
    if (!tab) {
      this.router.navigate(['/dashboard']);
      return;
    }
    if (event !== 'updateBuckets') {
      this.loading = this.loadingJobs = true;
    }
    this.toolbarService.setCloudState('FETCHING');
    this.userService.getJobs(tab).subscribe({
      next: (jobs) => {
        this.jobs = jobs;
        this.unreadJobFilter.hasUnreadJobs = this.checkIfUnreadJobs(this.jobs?.buckets);
        this.updateBucketsByUnreadStatus(this.unreadJobFilter.isUnreadFilterActivated);

        const { requestForQuoteSortOrder, pendingAssessmentSortOrder } = jobs ?? {};
        this.userService.dashboardSortOrder.set({ requestForQuoteSortOrder, pendingAssessmentSortOrder });
      },
      error: () => {
        this.message.error('Could not retrieve your jobs.');
        this.loadingJobs = false;
        this.endOfLoading();
      },
      complete: () => {
        this.getDates();
        this.loadingJobs = false;
        this.endOfLoading();
      }
    });
  }

  private checkIfUnreadJobs(buckets: Buckets): boolean {
    return Object.values(buckets).some((jobList: Job[]) => jobList.some((job) => job.unread));
  }

  updateBucketsByUnreadStatus(hasUnreadJobs: boolean) {
    this.unreadJobFilter.isUnreadFilterActivated = hasUnreadJobs;
    const tab = this.tabs[this.selectedTabIndex];
    switch (tab) {
      case 'QUOTES':
        this.quotingBuckets = this.getFilteredBuckets(hasUnreadJobs, QUOTING_BUCKETS);
        this.quotingBucketDetails = this.getUpdatedBucketDetails(this.quotingBuckets, QUOTING_BUCKET_DETAILS);
        break;
      case 'WORK_IN_PROGRESS':
        this.workAuthorisedBuckets = this.getFilteredBuckets(hasUnreadJobs, WORK_AUTHORISED_BUCKETS);
        this.workAuthorisedBucketDetails = this.getUpdatedBucketDetails(this.workAuthorisedBuckets, WORK_AUTHORISED);
        break;
      case 'INVOICES':
        this.invoicingBuckets = this.getFilteredBuckets(hasUnreadJobs, INVOICING_BUCKETS);
        this.invoicingBucketDetails = this.getUpdatedBucketDetails(this.invoicingBuckets, INVOICING);
        break;
    }
  }

  private getFilteredBuckets(hasUnreadJobs: boolean, filterFunction: BucketFilterFunction): Bucket[] {
    return hasUnreadJobs ? this.filterBucketsByUnreadJobs(filterFunction(this.jobs)) : filterFunction(this.jobs);
  }

  private filterBucketsByUnreadJobs(buckets: Bucket[]): Bucket[] {
    return buckets.map((bucket) => ({
      ...bucket,
      jobBuckets: bucket.jobBuckets.filter((job: Job) => job.unread === true)
    }));
  }

  private getUpdatedBucketDetails(bucketsList: Bucket[], filterFunction: BucketDetailsFilterFunction): BucketDetails[] {
    const bucketDetailsList = filterFunction(this.jobs?.buckets);
    return bucketDetailsList.map((bucketDetails) => {
      const concernedBucket = bucketsList.find((bucket) => bucket.id === bucketDetails.bucketName);
      const bucketLength = concernedBucket?.jobBuckets.length ?? 0;

      return {
        ...bucketDetails,
        bucketLength
      };
    });
  }

  updateBuckets(event: boolean) {
    if (event) {
      this.getJobs('updateBuckets');
    }
  }

  configureImportDirectories() {
    this.configureImport.open(ConfigureImportDirectoriesComponent, wideModalConfig);
  }

  private endOfLoading() {
    if (!this.loadingJobs && !this.loadingRepairerSite) {
      this.loading = false;
      this.toolbarService.setCloudState('RESTING');
    }
  }

  private getDates() {
    let combinedDates = [];

    this.wipTabCounter = this.jobs?.wipTabCounter;
    this.quotesTabCounter = this.jobs?.quotesTabCounter;
    this.invoicesTabCounter = this.jobs?.invoicesTabCounter;
    this.rpTabCounter = this.jobs?.rpTabCounter;

    if (this.selectedTabIndex === 0) {
      const datesRequestForQuote = this.jobs?.buckets.REQUEST_FOR_QUOTE.map((dates) => dates.lastReceivedDateTime);
      const datesMoreInformationRequired = this.jobs?.buckets.MORE_INFO_REQUIRED.map(
        (dates) => dates.lastReceivedDateTime
      );
      const datesPendingAssessment = this.jobs?.buckets.PENDING_ASSESSMENT.map((dates) => dates.lastReceivedDateTime);
      combinedDates = [...datesRequestForQuote, ...datesMoreInformationRequired, ...datesPendingAssessment];
    } else if (this.selectedTabIndex === 1) {
      const datesAuthorised = this.jobs?.buckets.AUTHORIZED.map((dates) => dates.lastReceivedDateTime);
      const datesMoreInfoSupplement = this.jobs?.buckets.MORE_INFO_SUPPLEMENT.map(
        (dates) => dates.lastReceivedDateTime
      );
      const datesPendingSupplementAssessment = this.jobs?.buckets.PENDING_SUPPLEMENT_ASSESSMENT.map(
        (dates) => dates.lastReceivedDateTime
      );
      combinedDates = [...datesAuthorised, ...datesMoreInfoSupplement, ...datesPendingSupplementAssessment];
    } else if (this.selectedTabIndex === 2) {
      const datesPendingInvoiceApproval =
        this.jobs?.buckets.PENDING_INVOICE_APPROVAL.map((dates) => dates.lastReceivedDateTime) || [];
      const datesInvoiceRejected = this.jobs?.buckets.INVOICE_REJECTED.map((dates) => dates.lastReceivedDateTime) || [];
      combinedDates = [...datesPendingInvoiceApproval, ...datesInvoiceRejected];
    }

    this.oldestReceivedOn = combinedDates.length ? Math.min(...combinedDates) : null;
    this.newestReceivedOn = combinedDates.length ? Math.max(...combinedDates) : null;
  }

  private jobHistoryList() {
    this.loading = true;
    this.toolbarService.setCloudState('FETCHING');
    this.userService.getJobHistory(this.historyCount).subscribe({
      next: (resp) => {
        this.jobHistory = resp.body.jobHistory;
        this.wipTabCounter = resp.body.wipTabCounter;
        this.quotesTabCounter = resp.body.quotesTabCounter;
        this.invoicesTabCounter = resp.body.invoicesTabCounter;
        this.rpTabCounter = resp.body.rpTabCounter;
        this.canLoadMore = resp.status === 206;
      },
      error: () => {
        this.message.error('Could not retrieve your jobs history.');
        this.loading = false;
        this.toolbarService.setCloudState('RESTING');
      },
      complete: () => {
        this.loading = false;
        this.toolbarService.setCloudState('RESTING');
      }
    });
  }

  loadMoreItems() {
    this.historyCount += 25;
    this.jobHistoryList();
  }

  tabSelected(tabChangeEvent: MatTabChangeEvent): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        tab: tabChangeEvent.index
      }
    });
    this.cleanActiveJobHelper.clearLocalStorageFromActiveJob();
    this.selectedTabIndex = tabChangeEvent.index;
    this.unreadJobFilter.isUnreadFilterActivated = false;
    this.bucketSelected = '';
    this.refresh();
  }

  redirectToJob(id: number) {
    this.router.navigate([`/jobViewer/${id}`]).catch();
  }

  // The refresh button in the dashboard (in shell.component.ts) calls this function in order to refresh the jobs.
  refresh() {
    this.selectedTabIndex === 4 ? this.jobHistoryList() : this.getJobs();
  }

  private getListOfInsurersForPickup() {
    this.userService.getInsurersForPickUp().subscribe({
      next: (insurers) => (this.insurersPickup = insurers),
      error: () => {
        this.message.error(`Could not retrieve the list of insurers.`);
      },
      complete: () => {
        this.insurersPickup?.sort((x, y) => x.insurerName.localeCompare(y.insurerName));
        this.repairPickUpAvailable = this.insurersPickup?.length > 0;
      }
    });
  }

  pickupJob() {
    const pickUpDialogConfig: MatDialogConfig = wideModalConfig;
    pickUpDialogConfig.data = { insurers: this.insurersPickup, timeZone: this.timezone };
    const dialogRef = this.matDialog.open(PickupJobComponent, pickUpDialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (!result || result === '') {
        return;
      }
      let title = 'No match found';
      let alertMessage =
        'We were unable to find any pending assessment based on the information you provided.\r\n\r\n' +
        "Someone in our office will review your request and send the 'Request for quote' via EstImage " +
        'or contact you directly.';

      if (result.status === 'SUCCESS') {
        title = 'Your request was received';
        if (result.result === 'Match') {
          if (result.inspectionDate) {
            // tslint:disable-next-line:max-line-length
            alertMessage =
              'We are pleased to confirm that an assessment for the vehicle has been booked' +
              (result.inspectionDate !== 'Invalid date' ? ' for ' + result.inspectionDate + '.' : '.');
          } else {
            alertMessage =
              'We are pleased to confirm that an assessment for the vehicle has been booked.\r\n\r\n' +
              'If you are located in a regional area you will be contacted by the assessor within 24hrs to confirm the booking date.';
          }
          alertMessage += "\r\n\r\nYou will receive the 'Request for quote' in EstImage within a few minutes.";
        } else if (result.result === 'PreAssigned') {
          alertMessage =
            "EstImage server has already sent you a 'Request for quote'" +
            (result.inspectionDate ? ' with this inspection date: ' + result.inspectionDate : '') +
            '.';
        }
      }
      const guidanceBoxDialogConfig: MatDialogConfig = mediumModalConfig;
      guidanceBoxDialogConfig.panelClass = 'medium-small-modal';
      guidanceBoxDialogConfig.data = { title, alertMessage, confirmButton: 'OK' };
      this.matDialog.open(GuidanceBoxComponent, guidanceBoxDialogConfig);
    });
  }

  scrollBucket(event: string) {
    this.doc.getElementById(event).scrollIntoView({ behavior: 'smooth', block: 'start' });
    this.bucketSelected = event;
  }
}
