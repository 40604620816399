import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ConfirmBoxComponent } from '../../../components/confirm-box/confirm-box.component';
import { UserService } from '../../../services/user.service';
import { ToolbarService } from '../../../services/toolbar.service';
import { MessagingService } from '../../../components/messaging/messaging.service';
import { DateInputHelper } from '../../../helpers/date-input.helper';
import { ModalConfigsHelper } from '../../../helpers/modal-configs.helper';
import { smallModalConfig } from '../../../consts/modal-config.const';

@Component({
  selector: 'app-self-authorise',
  templateUrl: './self-authorise.component.html',
  styleUrls: ['./self-authorise.component.scss']
})
export class SelfAuthoriseComponent implements OnInit {
  @Input() jobId: number;
  @Output() refreshJobViewer: EventEmitter<any> = new EventEmitter();
  @Output() openPdfReport: EventEmitter<null> = new EventEmitter<null>();

  constructor(
    private confirmBox: MatDialog,
    private userService: UserService,
    public toolbarService: ToolbarService,
    private message: MessagingService,
    public dateInputHelper: DateInputHelper,
    private modalConfigsHelper: ModalConfigsHelper
  ) {}

  ngOnInit(): void {}

  confirmDeleteSelfAuthorise() {
    const data = {
      title: 'Delete Self-Authorise',
      alertMessage: 'This self-authorise will be deleted.',
      confirmButton: 'DELETE'
    };
    const dialogRef = this.confirmBox.open(
      ConfirmBoxComponent,
      this.modalConfigsHelper.buildMediumModalConfig(data, smallModalConfig)
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'DELETE') {
        this.deleteSelfAuthorise();
      }
    });
  }

  deleteSelfAuthorise() {
    this.userService.updateJob('false', this.jobId, 'selfAuthorize').subscribe(
      () => this.toolbarService.setCloudState('RESTING'),
      () => {
        this.message.error('Could not delete self-authorise.');
        this.toolbarService.setCloudState('RESTING');
      },
      () => {
        this.message.confirm('Self-Authorise deleted');
        this.refreshJobViewer.emit();
      }
    );
  }
}
