<ng-container [ngSwitch]="data?.status">
  <ng-container
    *ngSwitchCase="'ACTIVE'"
    [ngTemplateOutlet]="box"
    [ngTemplateOutletContext]="{ status: 'active', tooltip: 'ACTIVE' }">
  </ng-container>

  <ng-container
    *ngSwitchCase="'PENDING'"
    [ngTemplateOutlet]="box"
    [ngTemplateOutletContext]="{
      status: 'pending',
      tooltip: data?.publishFailed
        ? 'PUBLICATION FAILED\nPLEASE VERIFY LEGACY CUSTOMER ID'
        : 'PENDING ' +
          (data?.mainContact?.isActivated ? 'PUBLICATION' : 'REGISTRATION') +
          '\n SENT ' +
          (data?.invitationDate | dateepoch: currentUserTimezone) +
          ' - ' +
          (data.invitationDate | time: currentUserTimezone)
    }">
  </ng-container>

  <ng-container
    *ngSwitchCase="'ARCHIVED'"
    [ngTemplateOutlet]="box"
    [ngTemplateOutletContext]="{
      status: 'archived',
      tooltip:
        'ARCHIVED \n ' +
        (data?.archivedDate | dateepoch: currentUserTimezone) +
        ' - ' +
        (data?.archivedDate | time: currentUserTimezone)
    }">
  </ng-container>

  <ng-container *ngSwitchCase="'ENABLED'">
    <ng-container
      *ngIf="data?.isActivated"
      [ngTemplateOutlet]="box"
      [ngTemplateOutletContext]="{ status: 'active', tooltip: 'ENABLED' }">
    </ng-container>

    <ng-container
      *ngIf="!data?.isActivated"
      [ngTemplateOutlet]="box"
      [ngTemplateOutletContext]="{ status: 'pending', tooltip: 'INVITED' }">
    </ng-container>
  </ng-container>

  <ng-container
    *ngSwitchCase="'DISABLED'"
    [ngTemplateOutlet]="box"
    [ngTemplateOutletContext]="{ status: 'pending', tooltip: 'DISABLED' }">
  </ng-container>

  <ng-container
    *ngSwitchCase="'SUSPENDED'"
    [ngTemplateOutlet]="box"
    [ngTemplateOutletContext]="{ status: 'archived', tooltip: 'SUSPENDED' }">
  </ng-container>
</ng-container>

<ng-template #box let-status="status" let-tooltip="tooltip">
  <div
    class="status-box"
    [ngClass]="'status-box-' + status"
    [matTooltipClass]="'status-box-' + status"
    [matTooltip]="tooltip"></div>
</ng-template>
