import { Component, Inject, OnInit } from '@angular/core';
import { del, get, set } from 'idb-keyval';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { DialogData } from '../confirm-box/confirm-box.component';

@Component({
  selector: 'app-configure-import-directories',
  templateUrl: './configure-import-directories.component.html',
  styleUrls: ['./configure-import-directories.component.scss']
})
export class ConfigureImportDirectoriesComponent implements OnInit {
  fileDirectoryHandler: FileSystemDirectoryHandle = null;
  imagesDirectoryHandler: FileSystemDirectoryHandle = null;
  types = [];
  constructor(
    public dialogRef: MatDialogRef<ConfigureImportDirectoriesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit(): void {
    this.importFolderFromIndexDB();
  }

  setDirectoryHandler(type: string) {
    try {
      (window as any).showDirectoryPicker({ mode: 'read', id: type }).then((directoryHandle) => {
        if (type === 'image') {
          this.imagesDirectoryHandler = directoryHandle;
        } else {
          this.fileDirectoryHandler = directoryHandle;
        }
      });
    } catch (error) {
      // To avoid console error
    }
  }

  deleteDirectoryHandler(type: string) {
    !this.types.includes(type) && this.types.push(type);
    if (type === 'image') {
      this.imagesDirectoryHandler = null;
    } else {
      this.fileDirectoryHandler = null;
    }
  }

  async importFolderFromIndexDB() {
    this.fileDirectoryHandler = await get('file');
    this.imagesDirectoryHandler = await get('image');
  }

  saveHandlers() {
    if (this.fileDirectoryHandler) {
      del('file');
      set('file', this.fileDirectoryHandler);
    }
    if (this.imagesDirectoryHandler) {
      del('image');
      set('image', this.imagesDirectoryHandler);
    }
    this.dialogRef.beforeClosed().subscribe((result) => {
      if (!this.fileDirectoryHandler || !this.imagesDirectoryHandler) {
        this.types.map((t) => del(t));
      }
    });
    this.dialogRef.close();
  }
}
