import { Component, inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { AbstractDynamicDialogComponent } from '../../../components/dynamic-dialog/abstract-dynamic-dialog.component';
import { getErrorMessage } from '../../../consts/form-field-errors';
import { CompanyInfo } from '../../../model/company-information.model';
import { abnValidator } from '../../../validators/abn.validator';
import { RepairerSitesStore } from '../repairer-sites/repairer-sites-store.component';

@Component({
  selector: 'app-change-company-information',
  templateUrl: './change-company-information.component.html'
})
export class ChangeCompanyInformationComponent
  extends AbstractDynamicDialogComponent<{ companyInfo: CompanyInfo }>
  implements OnInit
{
  protected readonly getErrorMessage = getErrorMessage;

  constructor() {
    super();
  }

  repairerSitesStore = inject(RepairerSitesStore);
  #dialogRef = inject(MatDialogRef<ChangeCompanyInformationComponent>);
  #fb = inject(UntypedFormBuilder);

  get companyName() {
    return this.form.controls.companyName;
  }

  get abn() {
    return this.form.controls.abn;
  }

  get accountName() {
    return this.form.controls.accountName;
  }

  get accountNumber() {
    return this.form.controls.accountNumber;
  }

  get bsb() {
    return this.form.controls.bsb;
  }

  ngOnInit() {
    this.initForm();
    this.dynamicDialogService.dialogRefSignal.set(this.#dialogRef);
  }

  private initForm(): void {
    const {
      companyName = null,
      abn = null,
      accountName = null,
      accountNumber = null,
      bsb = null,
      country
    } = this.repairerSitesStore.selectedRepairerSite() || {};

    this.form = this.#fb.group({
      companyName: [companyName, [Validators.required]],
      abn: [abn, [Validators.required]],
      accountName,
      accountNumber,
      bsb
    });

    if (country === 'Australia') {
      this.abn.addValidators(abnValidator);
      this.abn.updateValueAndValidity({ onlySelf: true });
    }
  }

  saveOrExecuteAction() {
    if (this.form.valid) {
      this.disabled.set(true);
      this.repairerSitesStore.updateCompanyInformation({
        id: this.repairerSitesStore.selectedRepairerSite().id,
        ...this.form.getRawValue()
      });
    }
  }

  adjustFormatOfAbn() {
    if (this.abn.valid && this.repairerSitesStore.selectedRepairerSite()?.country === 'Australia') {
      this.abn.patchValue(this.abn.value.replace(/\B(?=(\d{3})+(?!\d))/g, ' '));
    }
  }
}
