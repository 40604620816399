<div class="dialog-header" [class.pb-25]="!isPublished" mat-dialog-title>
  <h6>{{ !isPublished ? 'Complete your Company Information' : 'Edit Company Information' }}</h6>
  <button *ngIf="isPublished" aria-label="Close" mat-dialog-close mat-icon-button>
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-divider></mat-divider>
<form (ngSubmit)="isPublished ? updateRepairerSites() : publishRepairerSite()" [formGroup]="editAddressForm">
  <section mat-dialog-content class="add-edit-address mt-10">
    <article class="grid grid-cols-3 gap-20">
      <mat-form-field
        appearance="outline"
        color="accent"
        class="col-span-2"
        *ngIf="!companyName.disabled; else disabledCompany">
        <mat-label>Company name</mat-label>
        <input formControlName="companyName" matInput required />
        <mat-error *ngIf="!companyName.valid"> Business name is <b>required</b> </mat-error>
      </mat-form-field>

      <ng-template #disabledCompany>
        <div class="col-span-2">
          <ng-container
            *ngTemplateOutlet="
              readOnlyText;
              context: {
                label: 'Company name',
                value: data.repairer?.companyName
              }
            "></ng-container>
        </div>
      </ng-template>

      <mat-form-field
        *ngIf="
          data.repairer.status === 'ACTIVE' && currentUserService.currentUserValue.privileges.includes('SYSADMIN');
          else repairerGroupDisabled
        "
        appearance="outline"
        color="accent">
        <mat-label>Repairer Group</mat-label>
        <mat-select formControlName="repairerGroupId">
          <mat-option *ngFor="let group of data?.repairersGroup" [value]="group.id">
            {{ group.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <ng-template #repairerGroupDisabled>
        <ng-container
          *ngTemplateOutlet="
            readOnlyText;
            context: {
              label: 'Repairer Group',
              value: data?.repairer?.repairerGroupName
            }
          "></ng-container>
      </ng-template>
    </article>

    <article class="grid grid-cols-3 gap-x-20">
      <ng-container
        *ngTemplateOutlet="
          readOnlyText;
          context: {
            label: 'Country',
            value: data.repairer.country
          }
        "></ng-container>

      <ng-container
        *ngTemplateOutlet="
          readOnlyText;
          context: {
            label: data.repairer.country === 'Australia' ? 'ABN' : 'NZBN',
            value: data.repairer.abn
          }
        "></ng-container>

      <mat-form-field appearance="outline" color="accent" *ngIf="!licenseNumber.disabled; else disabledLicenceNumber">
        <mat-label>License Number</mat-label>
        <input formControlName="licenseNumber" matInput />
      </mat-form-field>
      <ng-template #disabledLicenceNumber>
        <ng-container
          *ngTemplateOutlet="
            readOnlyText;
            context: {
              label: 'License Number',
              value: data.repairer?.licenseNumber
            }
          "></ng-container>
      </ng-template>

      <ng-container
        *ngTemplateOutlet="
          readOnlyText;
          context: {
            label: 'Legacy Customer ID',
            value: data.repairer?.legacyCustomerId
          }
        "></ng-container>

      <mat-form-field
        *ngIf="
          currentUserService.currentUserValue.privileges.includes('SYSADMIN') && !data.repairer?.quotingSystems.length;
          else quotingSystemDisabled
        "
        appearance="outline"
        color="accent">
        <mat-label>Quoting System</mat-label>
        <mat-select formControlName="quotingSystem">
          <mat-option *ngFor="let item of quotingSystemList" [value]="item">
            {{ item }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <ng-template #quotingSystemDisabled>
        <ng-container
          *ngTemplateOutlet="
            readOnlyText;
            context: {
              label: 'Quoting System',
              value: data.repairer?.quotingSystemsString
            }
          "></ng-container>
      </ng-template>
    </article>

    <article class="grid grid-cols-2 gap-20">
      <mat-form-field appearance="outline" color="accent">
        <mat-label>Phone Number</mat-label>
        <input formControlName="phoneNumber" matInput required />
        <mat-error *ngIf="phoneNumber.invalid"> Phone number is <b>required</b> </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" color="accent">
        <mat-label>Email</mat-label>
        <input formControlName="email" matInput required />
        <mat-error *ngIf="email.invalid && email.hasError('required')"> Email is <strong>required</strong> </mat-error>
        <mat-error *ngIf="email.invalid && email.hasError('pattern')"> Please enter a valid email address </mat-error>
      </mat-form-field>
    </article>

    <article class="grid grid-cols-2 gap-20">
      <div>
        <div class="title-1 pb-10 text-dove-gray">Physical Address</div>
        <address-block formControlName="physicalAddress" />
      </div>
      <div>
        <div class="grid grid-cols-2 gap-20">
          <div class="title-1 pb-10 text-dove-gray">Billing Address</div>
          <mat-checkbox color="primary" formControlName="sameAsPhysical" [checked]="sameAsPhysical.value">
            Same as Physical
          </mat-checkbox>
        </div>
        <address-block formControlName="billingAddress" />
      </div>
    </article>
  </section>
  <mat-dialog-actions>
    <button *ngIf="isPublished" mat-raised-button mat-dialog-close color="accent">CANCEL</button>
    <div *ngIf="!isPublished" class="basis-2/4 text-[13px]">
      Once your information is complete, publishing your location will make you available to all EstImage insurers.
    </div>
    <button
      [disabled]="editAddressForm.invalid || pending"
      color="primary"
      mat-raised-button
      type="submit"
      [innerText]="isPublished ? 'SAVE' : 'PUBLISH'"></button>
  </mat-dialog-actions>
</form>

<ng-template #readOnlyText let-label="label" let-value="value">
  <div *ngIf="value" class="ml-[13px] min-h-[43px] mb-15">
    <span class="body-2 block">{{ label }}</span>
    <span class="subtitle-2">{{ value }}</span>
  </div>
</ng-template>
