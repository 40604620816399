import { Component, effect, inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AbstractDynamicDialogComponent } from '../../../components/dynamic-dialog/abstract-dynamic-dialog.component';
import { getErrorMessage } from '../../../consts/form-field-errors';
import { EMAIL_PATTERN_STRING } from '../../../consts/patterns.const';
import { COUNTRIES, STATES } from '../../../model/states.model';
import { abnValidator } from '../../../validators/abn.validator';
import { RepairerSitesStore } from '../repairer-sites/repairer-sites-store.component';
import { SetDashboardDayComponent } from '../set-dashboard-day/set-dashboard-day.component';

@Component({
  selector: 'app-add-financing-company',
  templateUrl: './add-financing-company.component.html'
})
export class AddFinancingCompanyComponent extends AbstractDynamicDialogComponent<any> implements OnInit, OnDestroy {
  protected readonly states = STATES;
  protected readonly countries = COUNTRIES;
  protected readonly getErrorMessage = getErrorMessage;
  private unsubscribe = new Subject();

  #dialogRef = inject(MatDialogRef<SetDashboardDayComponent>);
  #fb = inject(UntypedFormBuilder);
  repairerSitesStore = inject(RepairerSitesStore);

  constructor() {
    super();

    effect(() => {
      this.repairerSitesStore.abnUpdateErrors() && this.abn.setErrors(this.repairerSitesStore.abnUpdateErrors());
    });
  }

  get email() {
    return this.form?.controls?.email;
  }

  get abn() {
    return this.form?.controls?.abn;
  }

  get companyName() {
    return this.form?.controls?.companyName;
  }

  get country() {
    return this.form?.controls?.country;
  }

  ngOnInit(): void {
    this.dynamicDialogService.dialogRefSignal.set(this.#dialogRef);
    const {
      companyName = null,
      abn = null,
      addressLine1 = null,
      addressLine2 = null,
      email = null,
      phone = null,
      suburb = null,
      country = null,
      state = null,
      city = null,
      postCode = null
    } = this.data?.financingCompany || {};
    this.form = this.#fb.group({
      companyName: [companyName, [Validators.required]],
      abn: [
        abn,
        this.data?.financingCompany?.country === 'Australia'
          ? [Validators.required, abnValidator]
          : [Validators.required]
      ],
      addressLine1,
      addressLine2,
      email: [email, [Validators.required, Validators.pattern(EMAIL_PATTERN_STRING)]],
      phone,
      suburb,
      country: [country, Validators.required],
      state,
      city,
      postCode
    });

    this.country.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe({
      next: (country: string) => {
        this.abn.setValidators(country === 'Australia' ? [Validators.required, abnValidator] : Validators.required);
        if (country === 'Australia') {
          this.adjustFormatOfAbn();
        }
        this.abn.updateValueAndValidity();
      }
    });
  }

  adjustFormatOfAbn() {
    this.abn.updateValueAndValidity();
    if (this.abn.valid && this.country.value === this.countries[0].stateName) {
      this.abn.patchValue(this.abn.value.replace(/\B(?=(\d{3})+(?!\d))/g, ' '));
    }
  }

  clearStateAndCity(country: string) {
    country === 'Australia' ? this.form.controls.state.setValue('') : this.form.controls.city.setValue('');
  }

  saveOrExecuteAction(): void {
    if (this.form.valid) {
      this.disabled.set(true);
      this.repairerSitesStore.addOrUpdateFinancingCompany({
        id: this.data?.financingCompany?.id,
        financingCompany: this.form.getRawValue()
      });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
