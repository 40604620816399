<div *ngIf="document && quote">
  <mat-expansion-panel
    class="curtains"
    [expanded]="isSentToInsurerTray && !canBeReplacedOrMerged && !previousQuote"
    (closed)="quoteCurtainOpened = false"
    (opened)="quoteCurtainOpened = true"
    #panel>
    <mat-expansion-panel-header [collapsedHeight]="'70px'" [expandedHeight]="'70px'">
      <mat-panel-title class="quote-title">
        <div>
          <div *ngIf="document.date" class="received-date">
            {{ document.date | dateepoch: timezone }} {{ document.date | time: timezone }}
            <span *ngIf="quote.original && quotingSystemName">from {{ quotingSystemName }}</span>
          </div>
          <div class="quote-number">
            <h5>
              <span class="subtitle-1">{{
                canBeReplacedOrMerged ? 'New quote' : quote.original ? 'Original' : 'Supplement'
              }}</span>
              <span class="subtitle-1" *ngIf="quote.supplementNumber > 0">{{ quote.supplementNumber }}</span>
              {{ quote.quoteNumber }}
            </h5>
            <div *ngIf="!quote.assessed && document.submitted" class="pending-box" appHighlightStatus="PENDING">
              PENDING
            </div>
            <div
              (click)="$event.stopPropagation(); tooltipAssessedDate.toggle()"
              *ngIf="quote.assessed"
              class="assessed-box"
              appHighlightStatus="ASSESSED">
              ASSESSED
              <div
                #tooltipAssessedDate="matTooltip"
                matTooltip="{{ quote.assessedDateTime | dateepoch: timezone }} {{
                  quote.assessedDateTime | time: timezone
                }}"
                matTooltipClass="timestamp-tooltip"></div>
            </div>
          </div>
        </div>
        <div>
          <div class="body-2">Quoted Parts</div>
          <div class="subtitle-2">$ {{ quote.estPartsTotal | number: '1.2-2' }}</div>
        </div>
        <div>
          <div class="body-2">Quoted Labour</div>
          <div class="subtitle-2">$ {{ quote.estLabourTotal | number: '1.2-2' }}</div>
        </div>
        <div>
          <div class="body-2">Quoted Sublet</div>
          <div class="subtitle-2">$ {{ quote.estSubletTotal | number: '1.2-2' }}</div>
        </div>
        <div>
          <div class="body-2">Total Quoted</div>
          <div class="subtitle-2">$ {{ quote.estTotal | number: '1.2-2' }}</div>
        </div>
      </mat-panel-title>
      <mat-panel-description class="kebab-panel">
        <div *ngIf="!document?.submitted" class="secondary-icon">
          <button (click)="$event.stopPropagation(); confirmDeleteQuote()" mat-icon-button>
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <mat-divider></mat-divider>
    <form [formGroup]="quoteForm">
      <div class="quote-fields-box">
        <div class="quote-fields-grid">
          <mat-form-field appearance="outline" class="large" color="accent">
            <mat-label>Estimator</mat-label>
            <input formControlName="estimator" matInput />
          </mat-form-field>

          <mat-form-field
            [class.read-only]="quoteDate.disabled"
            appearance="outline"
            class="small primary-icon"
            color="accent">
            <mat-label>Quote Date</mat-label>
            <input
              (keypress)="dateInputHelper.restrictDateInput($event)"
              [matDatepicker]="quoteDatepicker"
              formControlName="quoteDate"
              matInput />
            <mat-datepicker-toggle [for]="quoteDatepicker" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #quoteDatepicker></mat-datepicker>
            <mat-hint *ngIf="quoteDate.enabled">Date format DD/MM/YYYY</mat-hint>
            <mat-error *ngIf="!quoteDate.valid"> The date format is <strong>DD/MM/YYYY</strong> </mat-error>
          </mat-form-field>

          <mat-form-field
            [class.read-only]="estimatedStartDate.disabled"
            appearance="outline"
            class="small primary-icon"
            color="accent">
            <mat-label>Estimated Start Date</mat-label>
            <input
              (keypress)="dateInputHelper.restrictDateInput($event)"
              [matDatepicker]="startDatepicker"
              formControlName="estimatedStartDate"
              matInput />
            <mat-datepicker-toggle [for]="startDatepicker" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #startDatepicker></mat-datepicker>
            <mat-hint *ngIf="estimatedStartDate.enabled">Date format DD/MM/YYYY</mat-hint>
            <mat-error *ngIf="!estimatedStartDate.valid"> The date format is <strong>DD/MM/YYYY</strong> </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="small" color="accent">
            <mat-label>Estimated Duration</mat-label>
            <input formControlName="estimatedDuration" matInput type="number" />
            <span *ngIf="displayDays" matSuffix>Days</span>
            <mat-error *ngIf="estimatedDuration.errors"> Only numbers are accepted </mat-error>
          </mat-form-field>
        </div>
        <div *ngIf="quote?.repairerInstructions" class="repairer-instructions">
          <div class="field-label body-2">Repairer Instructions</div>
          <p class="subtitle-2">{{ quote.repairerInstructions }}</p>
        </div>
      </div>

      <div class="overview-filter">
        <h4 [class.red]="overviewCheckbox.checked">Overview</h4>
        <mat-checkbox color="primary" #overviewCheckbox [class.hidden]="!quote.assessed" class="checkbox-margin">
          Show only assessor adjustments and notes
        </mat-checkbox>
      </div>

      <div class="overview-grid">
        <div>
          <div class="body-2">Labour</div>
          <div class="overview-amounts body-1">
            $
            <div class="section-amount">
              {{ (quote.assessed ? quote.assessedLabourTotal : quote.estLabourTotal) | number: '1.2-2' }}
            </div>
            <ng-container
              *ngIf="quote.assessed"
              [ngTemplateOutletContext]="{
                estimatedAmount: quote.estLabourTotal,
                assessedAmount: quote.assessedLabourTotal
              }"
              [ngTemplateOutlet]="assessedTotals"></ng-container>
          </div>
        </div>
        <div>
          <div class="body-2">Parts</div>
          <div class="overview-amounts body-1">
            $
            <div class="section-amount">
              {{ (quote.assessed ? quote.assessedPartsTotal : quote.estPartsTotal) | number: '1.2-2' }}
            </div>
            <ng-container
              *ngIf="quote.assessed"
              [ngTemplateOutletContext]="{
                estimatedAmount: quote.estPartsTotal,
                assessedAmount: quote.assessedPartsTotal
              }"
              [ngTemplateOutlet]="assessedTotals"></ng-container>
          </div>
        </div>
        <div>
          <div class="body-2">Sublet</div>
          <div class="overview-amounts body-1">
            $
            <div class="section-amount">
              {{ (quote.assessed ? quote.assessedSubletTotal : quote.estSubletTotal) | number: '1.2-2' }}
            </div>
            <ng-container
              *ngIf="quote.assessed"
              [ngTemplateOutletContext]="{
                estimatedAmount: quote.estSubletTotal,
                assessedAmount: quote.assessedSubletTotal
              }"
              [ngTemplateOutlet]="assessedTotals"></ng-container>
          </div>
        </div>
        <div>
          <div class="body-2">Subtotal</div>
          <div class="overview-amounts body-1">
            $
            <div class="subtotal-amount">
              {{ (quote.assessed ? quote.assessedSubTotal : quote.estSubTotal) | number: '1.2-2' }}
            </div>
          </div>
        </div>
        <div>
          <div class="body-2">GST</div>
          <div class="overview-amounts body-1">
            $
            <span class="subtotal-amount">
              {{ (quote.assessed ? quote.assessedGSTTotal : quote.estGSTTotal) | number: '1.2-2' }}
            </span>
          </div>
        </div>
        <div>
          <div class="body-2">Total</div>
          <div class="overview-amounts body-1">
            $
            <span class="total-amount">
              {{ (quote.assessed ? quote.assessedTotal : quote.estTotal) | number: '1.2-2' }}
            </span>
            <ng-container
              *ngIf="quote.assessed"
              [ngTemplateOutletContext]="{ estimatedAmount: quote.estTotal, assessedAmount: quote.assessedTotal }"
              [ngTemplateOutlet]="assessedTotals"></ng-container>
          </div>
        </div>
      </div>
      <!-- LABOUR SECTION -->
      <h4 *ngIf="quote.labourSections" class="labour-title">Labour</h4>
      <div
        formArrayName="labourSections"
        *ngFor="let labourSection of labourSections.controls; let i = index; trackBy: trackByFn"
        class="labour-sections">
        <div [formGroupName]="i">
          <div class="section-header">
            <div class="group">
              <div class="title-1">{{ labourSection.value.labourType }}</div>
              <div class="paint-group subtitle-2">{{ labourSection.value.groupName }}</div>
            </div>
            <div class="rates">
              <div class="body-2">Est. rate</div>
              <div class="paint-group subtitle-2">
                <div *ngIf="!quote.manual || quote.submitted">$ {{ labourSection.value.estimateRate }}</div>
                <mat-form-field
                  appearance="outline"
                  *ngIf="quote.manual && !quote.submitted"
                  class="quote-column-field"
                  color="accent">
                  <input
                    formControlName="estimateRate"
                    matInput
                    (focusout)="setEstimatedRate(i, estimateRate.value)"
                    #estimateRate />
                  <div class="prefix body-1" matPrefix>$</div>
                  <mat-error *ngIf="labourSection.get('estimateRate').errors?.pattern">Invalid format</mat-error>
                </mat-form-field>
              </div>
              <ng-container *ngIf="labourSection.value.assessedRate">
                <div class="body-2">Ass. rate</div>
                <div class="paint-group subtitle-2">
                  <div
                    [ngClass]="{ emphasis: labourSection.value.assessedRate !== labourSection.value.estimateRate }"
                    class="assess-indicator">
                    $ {{ labourSection.value.assessedRate | number: '1.2-2' }}
                    <div
                      [ngClass]="{
                        ASSESSED_UNDER: +labourSection.value.assessedRate !== +labourSection.value.estimateRate
                      }"
                      class="indicator margin-left">
                      <mat-icon *ngIf="+labourSection.value.assessedRate < +labourSection.value.estimateRate"
                        >keyboard_arrow_down
                      </mat-icon>
                      <mat-icon *ngIf="+labourSection.value.assessedRate > +labourSection.value.estimateRate"
                        >keyboard_arrow_up</mat-icon
                      >
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
          <table
            formArrayName="items"
            [dataSource]="labourSection.value.items | filter: 'OVERVIEW' : overviewCheckbox.checked"
            class="table-style"
            mat-table
            [trackBy]="trackByFn">
            <ng-container matColumnDef="description">
              <th *matHeaderCellDef class="body-2" mat-header-cell>Description</th>
              <td *matCellDef="let item; let index = index" class="body-1" mat-cell [formGroupName]="index">
                <div class="description-cell">
                  <ng-container
                    [ngTemplateOutletContext]="{ item: item, added: item.addedInV6 }"
                    [ngTemplateOutlet]="assessedStatusIndicator"></ng-container>
                  <div *ngIf="!quote.manual || quote.submitted">
                    {{ item.description }}
                  </div>
                  <mat-form-field
                    appearance="outline"
                    *ngIf="quote.manual && !quote.submitted"
                    class="quote-column-field"
                    color="accent">
                    <input
                      formControlName="description"
                      type="text"
                      id="{{ 'description-' + i + index }}"
                      (focusout)="addOrEditLineLaboursItem(i, index)"
                      matInput
                      #labourDescriptionsRef />
                    <mat-error *ngIf="labourItem(i).controls[index].get('description').errors?.required"
                      >Description is <strong>required</strong></mat-error
                    >
                  </mat-form-field>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="note">
              <th *matHeaderCellDef class="body-2" mat-header-cell>Assessor Note</th>
              <td *matCellDef="let item; let index = index" class="emphasis body-1" mat-cell [formGroupName]="index">
                <div *ngIf="item.assessorNote" class="note-container">
                  <mat-icon class="note-icon">add_comment</mat-icon>
                  <div>{{ item.assessorNote }}</div>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="total">
              <td *matFooterCellDef mat-footer-cell class="body-1 text-end" colspan="2">
                <div>Total</div>
              </td>
            </ng-container>
            <ng-container matColumnDef="estimateHours">
              <th *matHeaderCellDef class="body-2" mat-header-cell>Est. Hours</th>
              <td *matCellDef="let item; let index = index" class="body-1" mat-cell [formGroupName]="index">
                <div *ngIf="!quote.manual || quote.submitted">
                  {{ item.estimateHours }}
                </div>
                <mat-form-field
                  appearance="outline"
                  *ngIf="quote.manual && !quote.submitted"
                  class="quote-column-field"
                  color="accent">
                  <input
                    formControlName="estimateHours"
                    id="{{ 'estimateHours-' + i + index }}"
                    (focusout)="
                      updateEstimateHours(i, index, estimateHoursValue.value); addOrEditLineLaboursItem(i, index)
                    "
                    matInput
                    #estimateHoursValue />
                  <mat-error *ngIf="labourItem(i).controls[index].get('estimateHours').errors?.pattern"
                    >Invalid format</mat-error
                  >
                </mat-form-field>
              </td>
              <td *matFooterCellDef mat-footer-cell class="body-1">
                <div>
                  {{ labourSection.value.totalEstimateHours | number: '1.2-2' }}
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="assessedHours">
              <th *matHeaderCellDef class="body-2" mat-header-cell>Ass. Hours</th>
              <td *matCellDef="let item; let index = index" class="body-1" mat-cell [formGroupName]="index">
                <ng-container *ngIf="item.assessedHours || item.assessedHours === 0">
                  <div
                    [ngClass]="{
                      emphasis: item.assessedStatus !== 'ASSESSED_EQUAL',
                      red: item.assessedStatus === 'ASSESSED_REJECTED'
                    }"
                    class="assess-indicator">
                    {{ item.assessedHours | number: '1.2-2' }}
                    <ng-container
                      [ngTemplateOutletContext]="{ item: item, small: true, added: item.addedInV6 }"
                      [ngTemplateOutlet]="assessedStatusIndicator"></ng-container>
                  </div>
                </ng-container>
              </td>
              <td *matFooterCellDef mat-footer-cell class="body-1">
                <div *ngIf="quote.assessed">
                  {{ labourSection.value.totalAssessedHours | number: '1.2-2' }}
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="estimateAmount">
              <th *matHeaderCellDef class="body-2" mat-header-cell>Est. Amount</th>
              <td *matCellDef="let item; let index = index" class="body-1" mat-cell [formGroupName]="index">
                <div class="amount-cell" *ngIf="!quote.manual || quote.submitted">
                  <div class="sign-cell">$</div>
                  <div>{{ item.estimateAmount }}</div>
                </div>
                <mat-form-field
                  appearance="outline"
                  *ngIf="quote.manual && !quote.submitted"
                  class="quote-column-field"
                  color="accent">
                  <input
                    formControlName="estimateAmount"
                    id="{{ 'estimateAmount-' + i + index }}"
                    (keydown.tab)="
                      updateEstimateAmount(i, index, estimateAmountValue.value); addOrEditLineLaboursItem(i, index)
                    "
                    (focusout)="
                      updateEstimateAmount(i, index, estimateAmountValue.value); addOrEditLineLaboursItem(i, index)
                    "
                    matInput
                    #estimateAmountValue />
                  <div class="prefix body-1" matPrefix *ngIf="item.estimateAmount">$</div>
                  <mat-error *ngIf="labourItem(i).controls[index].get('estimateAmount').errors?.pattern"
                    >Invalid format</mat-error
                  >
                  <mat-error *ngIf="labourItem(i).controls[index].get('estimateAmount').errors?.required"
                    >Amount is <strong>required</strong></mat-error
                  >
                </mat-form-field>
              </td>
              <td *matFooterCellDef mat-footer-cell class="body-1">
                <div class="amount-cell">
                  <div class="sign-cell-total">$</div>
                  {{ labourSection.value.totalEstimateAmount | number: '1.2-2' }}
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="assessedAmount">
              <th *matHeaderCellDef class="body-2" mat-header-cell>Ass. Amount</th>
              <td *matCellDef="let item; let index = index" class="body-1" mat-cell [formGroupName]="index">
                <div *ngIf="item.assessedAmount || item.assessedAmount === 0" class="amount-cell">
                  <div class="sign-cell">$</div>
                  <div
                    [ngClass]="{
                      emphasis: item.assessedStatus !== 'ASSESSED_EQUAL',
                      red: item.assessedStatus === 'ASSESSED_REJECTED'
                    }"
                    class="assess-indicator">
                    {{ item.assessedAmount | number: '1.2-2' }}
                    <ng-container
                      [ngTemplateOutletContext]="{ item: item, small: true, added: item.addedInV6 }"
                      [ngTemplateOutlet]="assessedStatusIndicator"></ng-container>
                  </div>
                </div>
              </td>
              <td *matFooterCellDef mat-footer-cell class="body-1">
                <div class="amount-cell" *ngIf="quote.assessed">
                  <div class="sign-cell-total">$</div>
                  {{ labourSection.value.totalAssessedAmount | number: '1.2-2' }}
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="delete">
              <th *matHeaderCellDef class="body-2" mat-header-cell></th>
              <td *matCellDef="let item; let index = index" class="body-1" mat-cell [formGroupName]="index">
                <div
                  *ngIf="
                    quote.manual &&
                    !quote.submitted &&
                    labourItem(i).controls.length > 1 &&
                    labourItem(i).controls.length !== index + 1
                  "
                  class="amount-cell delete">
                  <mat-icon (click)="deleteItem(item.id, index, 'labourItem', i)">delete</mat-icon>
                </div>
              </td>
            </ng-container>
            <tr *matHeaderRowDef="labourColumnHeaders" mat-header-row></tr>
            <tr
              *matRowDef="let item; let even = even; columns: labourColumnHeaders; let j = index"
              mat-row
              [ngClass]="{ 'gray-row': even && !item.mergedFromReUpload, 'merged-item': item.mergedFromReUpload }"
              [class.row-manual-quote]="quote.manual"></tr>
            <tr *matFooterRowDef="labourColumnFooters" mat-footer-row [hidden]="!showTotals"></tr>
          </table>
        </div>
        <div
          *ngIf="
            overviewCheckbox.checked &&
            (labourSection.value.items | filter: 'OVERVIEW' : overviewCheckbox.checked).length === 0
          "
          class="no-adjusted-line subtitle-2">
          <mat-icon>info_outlined</mat-icon>
          <span class="banner-text"
            >No adjusted lines. <a (click)="overviewCheckbox.checked = false">Clear your filter</a> to see all
            lines</span
          >
        </div>
      </div>
      <!-- PARTS SECTION -->
      <div *ngIf="quote.parts?.length || quote.manual" class="part-section" formArrayName="partItems">
        <h4 class="part-section-header">Parts</h4>
        <table
          [dataSource]="partItems.value | filter: 'OVERVIEW' : overviewCheckbox.checked"
          class="table-style"
          mat-table
          [trackBy]="trackByForSubletsAndParts"
          #partTable>
          <ng-container *ngIf="!isLargeScreen">
            <ng-container matColumnDef="partDescriptionNumber">
              <th *matHeaderCellDef class="body-2" mat-header-cell>
                <div>Description / Part Number</div>
              </th>
              <td *matCellDef="let item; let index = index" class="body-1" mat-cell [formGroupName]="index">
                <div class="description-cell">
                  <ng-container
                    [ngTemplateOutletContext]="{ item: item }"
                    [ngTemplateOutlet]="assessedStatusIndicator"></ng-container>
                  <div *ngIf="!quote.manual || quote.submitted">
                    <div>{{ item.description }}</div>
                    <div>{{ item.partNumber }}</div>
                  </div>
                  <mat-form-field
                    appearance="outline"
                    *ngIf="quote.manual && !quote.submitted"
                    class="quote-column-field"
                    color="accent">
                    <input
                      formControlName="description"
                      id="{{ 'descriptionPartsSmall-' + index }}"
                      type="text"
                      (focusout)="addOrEditLineParts(index)"
                      matInput />
                    <mat-error *ngIf="partItems.controls[index].get('description').errors?.required"
                      >Description is <strong>required</strong></mat-error
                    >
                  </mat-form-field>
                  <mat-form-field
                    appearance="outline"
                    *ngIf="quote.manual && !quote.submitted"
                    class="quote-column-field"
                    color="accent">
                    <input
                      formControlName="partNumber"
                      id="{{ 'partNumberPartsSmall-' + index }}"
                      (focusout)="addOrEditLineParts(index)"
                      type="text"
                      matInput />
                  </mat-form-field>
                </div>
              </td>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="isLargeScreen">
            <ng-container matColumnDef="partDescription">
              <th *matHeaderCellDef class="body-2" mat-header-cell>Description</th>
              <td *matCellDef="let item; let index = index" class="body-1" mat-cell [formGroupName]="index">
                <div class="description-cell">
                  <ng-container
                    [ngTemplateOutletContext]="{ item: item, added: item.addedInV6 }"
                    [ngTemplateOutlet]="assessedStatusIndicator"></ng-container>
                  <div *ngIf="!quote.manual || quote.submitted">
                    {{ item.description }}
                  </div>
                  <mat-form-field
                    appearance="outline"
                    *ngIf="quote.manual && !quote.submitted"
                    class="quote-column-field"
                    color="accent">
                    <input
                      formControlName="description"
                      id="{{ 'descriptionPartsLarge-' + index }}"
                      (focusout)="addOrEditLineParts(index)"
                      type="text"
                      matInput />
                    <mat-error *ngIf="partItems.controls[index].get('description').errors?.required"
                      >Description is <strong>required</strong></mat-error
                    >
                  </mat-form-field>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="partNumber">
              <th *matHeaderCellDef class="body-2" mat-header-cell>Part Number</th>
              <td *matCellDef="let item; let index = index" class="body-1" mat-cell [formGroupName]="index">
                <div *ngIf="!quote.manual || quote.submitted">{{ item.partNumber }}</div>
                <mat-form-field
                  appearance="outline"
                  *ngIf="quote.manual && !quote.submitted"
                  class="quote-column-field"
                  color="accent">
                  <input
                    formControlName="partNumber"
                    id="{{ 'partNumberPartsLarge-' + index }}"
                    (focusout)="addOrEditLineParts(index)"
                    type="text"
                    matInput />
                </mat-form-field>
              </td>
            </ng-container>
          </ng-container>
          <ng-container matColumnDef="partSource">
            <th *matHeaderCellDef class="body-2" mat-header-cell>Part Source</th>
            <td *matCellDef="let item; let index = index" class="body-1" mat-cell [formGroupName]="index">
              <div *ngIf="!quote.manual || quote.submitted">{{ item.partSource }}</div>
              <mat-form-field
                appearance="outline"
                *ngIf="quote.manual && !quote.submitted"
                class="quote-column-field"
                color="accent">
                <mat-select formControlName="partSource" id="{{ 'partSource-' + index }}">
                  <mat-select-trigger>
                    {{ partItems.value[index].partSource }}
                  </mat-select-trigger>
                  <mat-option
                    *ngFor="let part of partSourceOptions"
                    [value]="part.code"
                    (focusout)="addOrEditLineParts(index)">
                    {{ part.code }} - {{ part.description }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </td>
          </ng-container>
          <ng-container matColumnDef="assessorNote">
            <th *matHeaderCellDef class="body-2" mat-header-cell>Assessor Note</th>
            <td *matCellDef="let item; let index = index" class="emphasis body-1" mat-cell [formGroupName]="index">
              <div *ngIf="item.assessorNote" class="note-container">
                <mat-icon class="note-icon">add_comment</mat-icon>
                <div>{{ item.assessorNote }}</div>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="quantity">
            <th *matHeaderCellDef class="body-2" mat-header-cell>Qty.</th>
            <td *matCellDef="let item; let index = index" class="quantity body-1" mat-cell [formGroupName]="index">
              <div *ngIf="!quote.manual || quote.submitted">{{ item.quantity }}</div>
              <mat-form-field
                appearance="outline"
                *ngIf="quote.manual && !quote.submitted"
                class="quote-column-field"
                color="accent">
                <input
                  formControlName="quantity"
                  id="{{ 'quantity-' + index }}"
                  (focusout)="addOrEditLineParts(index)"
                  onkeypress="return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)"
                  matInput />
                <mat-error
                  class="mat-error-no-custom-width"
                  *ngIf="partItems.controls[index]?.get('quantity')?.errors?.min"
                  >Must be at least 1</mat-error
                >
              </mat-form-field>
            </td>
          </ng-container>
          <ng-container matColumnDef="total">
            <td *matFooterCellDef mat-footer-cell class="body-1 text-end" [attr.colspan]="partColumnHeaders.length - 4">
              <div class="font-normal my-20">Subtotal</div>
              <div class="font-normal">Markup</div>
              <div class="my-20">Total</div>
            </td>
          </ng-container>
          <ng-container matColumnDef="estimateAmount">
            <th *matHeaderCellDef class="body-2" mat-header-cell>Est. Amount</th>
            <td *matCellDef="let item; let index = index" class="body-1" mat-cell [formGroupName]="index">
              <div *ngIf="!quote.manual || quote.submitted" class="amount-cell">
                <div class="sign-cell">$</div>
                <div>{{ item.estimateAmount }}</div>
              </div>
              <mat-form-field
                appearance="outline"
                *ngIf="quote.manual && !quote.submitted"
                class="quote-column-field"
                color="accent">
                <input
                  formControlName="estimateAmount"
                  id="{{ 'estimateAmountParts-' + index }}"
                  (focusout)="addOrEditLineParts(index)"
                  matInput />
                <div *ngIf="item.estimateAmount" class="prefix body-1" matPrefix>$</div>
                <mat-error *ngIf="partItems.controls[index].get('estimateAmount').errors?.pattern"
                  >Invalid format</mat-error
                >
                <mat-error *ngIf="partItems.controls[index].get('estimateAmount').errors?.required"
                  >Amount is <strong>required</strong></mat-error
                >
              </mat-form-field>
            </td>
            <td *matFooterCellDef mat-footer-cell class="body-1">
              <div class="amount-cell font-normal my-20">
                <div class="sign-cell-total">$</div>
                {{ quote.estPartsSubTotal | number: '1.2-2' }}
              </div>
              <div class="amount-cell font-normal">
                <div class="sign-cell-total">$</div>
                {{ quote.estPartsMarkupTotal | number: '1.2-2' }}
              </div>
              <div class="amount-cell my-20">
                <div class="sign-cell-total">$</div>
                {{ quote.estPartsTotal | number: '1.2-2' }}
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="estimateMarkup">
            <th *matHeaderCellDef class="body-2" mat-header-cell>Est. Markup</th>
            <td *matCellDef="let item; let index = index" class="body-1" mat-cell [formGroupName]="index">
              <div *ngIf="!quote.manual || quote.submitted">{{ item.estimateMarkup | number: '1.2-2' }} %</div>
              <mat-form-field
                appearance="outline"
                *ngIf="quote.manual && !quote.submitted"
                class="quote-column-field"
                color="accent">
                <input
                  formControlName="estimateMarkup"
                  id="{{ 'estimateMarkup-' + index }}"
                  (focusout)="addOrEditLineParts(index)"
                  onkeypress="return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)"
                  matInput />
                <div *ngIf="item.estimateMarkup" class="prefix body-1" matSuffix>%</div>
                <mat-error>Enter number</mat-error>
              </mat-form-field>
            </td>
            <td *matFooterCellDef mat-footer-cell class="body-1"></td>
          </ng-container>
          <ng-container matColumnDef="assessedAmount">
            <th *matHeaderCellDef class="body-2" mat-header-cell>
              <ng-container> Ass. Amount </ng-container>
            </th>
            <td *matCellDef="let item" class="body-1" mat-cell>
              <ng-container *ngIf="item.assessedAmount || item.assessedAmount === 0">
                <div class="amount-cell">
                  <div class="sign-cell">$</div>
                  <div
                    [ngClass]="{
                      emphasis: item.assessedStatus !== 'ASSESSED_EQUAL',
                      red: item.assessedStatus === 'ASSESSED_REJECTED'
                    }"
                    class="assess-indicator">
                    {{ item.assessedAmount | number: '1.2-2' }}
                    <ng-container
                      [ngTemplateOutletContext]="{ item: item, small: true, added: item.addedInV6 }"
                      [ngTemplateOutlet]="assessedStatusIndicator"></ng-container>
                  </div>
                </div>
              </ng-container>
            </td>
            <td *matFooterCellDef mat-footer-cell class="body-1">
              <div class="amount-cell font-normal my-20" *ngIf="quote.assessed">
                <div class="sign-cell-total">$</div>
                {{ quote.assessedPartsSubTotal | number: '1.2-2' }}
              </div>
              <div class="amount-cell font-normal" *ngIf="quote.assessed">
                <div class="sign-cell-total">$</div>
                {{ quote.assessedPartsMarkupTotal | number: '1.2-2' }}
              </div>
              <div class="amount-cell my-20" *ngIf="quote.assessed">
                <div class="sign-cell-total">$</div>
                {{ quote.assessedPartsTotal | number: '1.2-2' }}
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="assessedMarkup">
            <th *matHeaderCellDef class="body-2" mat-header-cell>
              <ng-container> Ass. Markup </ng-container>
            </th>
            <td *matCellDef="let item" class="body-1" mat-cell>
              <ng-container *ngIf="item.assessedMarkupPercent || item.assessedMarkupPercent === 0">
                {{ item.assessedMarkupPercent | number: '1.2-2' }} %
              </ng-container>
            </td>
            <td *matFooterCellDef mat-footer-cell class="body-1"></td>
          </ng-container>
          <ng-container matColumnDef="delete">
            <th *matHeaderCellDef class="body-2" mat-header-cell></th>
            <td *matCellDef="let item; let index = index" class="body-1" mat-cell [formGroupName]="index">
              <div
                *ngIf="
                  quote.manual &&
                  !quote.submitted &&
                  partItems.controls.length > 1 &&
                  partItems.controls.length !== index + 1
                "
                class="amount-cell delete">
                <mat-icon (click)="deleteItem(item.id, index, 'partItem', null)">delete</mat-icon>
              </div>
            </td>
          </ng-container>
          <tr *matHeaderRowDef="partColumnHeaders" mat-header-row></tr>
          <tr
            *matRowDef="let item; let even = even; columns: partColumnHeaders"
            mat-row
            [ngClass]="{ 'gray-row': even && !item.mergedFromReUpload, 'merged-item': item.mergedFromReUpload }"
            class="parts"></tr>
          <tr *matFooterRowDef="partColumnFooters" mat-footer-row [hidden]="!showTotals"></tr>
        </table>
        <div
          *ngIf="overviewCheckbox.checked && (quote.parts | filter: 'OVERVIEW' : overviewCheckbox.checked).length === 0"
          class="no-adjusted-line subtitle-2">
          <mat-icon>info_outlined</mat-icon>
          <span class="banner-text"
            >No adjusted lines. <a (click)="overviewCheckbox.checked = false">Clear your filter</a> to see all
            lines</span
          >
        </div>
      </div>
      <!-- SUBLETS SECTION -->
      <div *ngIf="quote.sublets?.length || quote.manual" class="sublet-section" formGroupName="subletItems">
        <h4 class="part-section-header">Sublets</h4>
        <table
          [dataSource]="subletItems.value | filter: 'OVERVIEW' : overviewCheckbox.checked"
          class="table-style"
          mat-table
          [trackBy]="trackByForSubletsAndParts"
          #subletTable>
          <ng-container matColumnDef="description">
            <th *matHeaderCellDef class="body-2" mat-header-cell>Description</th>
            <td *matCellDef="let item; let index = index" class="body-1" mat-cell [formGroupName]="index">
              <div class="description-cell">
                <ng-container
                  [ngTemplateOutletContext]="{ item: item, added: item.addedInV6 }"
                  [ngTemplateOutlet]="assessedStatusIndicator"></ng-container>
                <div *ngIf="!quote.manual || quote.submitted">
                  {{ item.description }}
                </div>
                <mat-form-field
                  appearance="outline"
                  *ngIf="quote.manual && !quote.submitted"
                  class="quote-column-field"
                  color="accent">
                  <input
                    formControlName="description"
                    id="{{ 'descriptionSublet-' + index }}"
                    (focusout)="addOrEditLineSublets(index)"
                    type="text"
                    matInput
                    #subletDescriptionsRef />
                  <mat-error *ngIf="subletItems.controls[index].get('description').errors?.required"
                    >Description is <strong>required</strong></mat-error
                  >
                </mat-form-field>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="note">
            <th *matHeaderCellDef class="body-2" mat-header-cell>Assessor Note</th>
            <td *matCellDef="let item; let index = index" class="emphasis body-1" mat-cell [formGroupName]="index">
              <div *ngIf="item.assessorNote" class="note-container">
                <mat-icon class="note-icon">add_comment</mat-icon>
                <div>{{ item.assessorNote }}</div>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="total">
            <td *matFooterCellDef mat-footer-cell class="body-1 text-end" colspan="2">
              <div>Total</div>
            </td>
          </ng-container>
          <ng-container matColumnDef="estimateAmount">
            <th *matHeaderCellDef class="body-2" mat-header-cell>Est. Amount</th>
            <td *matCellDef="let item; let index = index" class="body-1" mat-cell [formGroupName]="index">
              <div *ngIf="!quote.manual || quote.submitted" class="amount-cell">
                <div class="sign-cell">$</div>
                <div>{{ item.estimateAmount }}</div>
              </div>
              <mat-form-field
                appearance="outline"
                *ngIf="quote.manual && !quote.submitted"
                class="quote-column-field"
                color="accent">
                <input
                  formControlName="estimateAmount"
                  id="{{ 'estimateAmountSublet-' + index }}"
                  (focusout)="addOrEditLineSublets(index)"
                  matInput />
                <div *ngIf="item.estimateAmount" class="prefix body-1" matPrefix>$</div>
                <mat-error *ngIf="subletItems.controls[index].get('estimateAmount').errors?.pattern"
                  >Invalid format</mat-error
                >
                <mat-error *ngIf="subletItems.controls[index].get('estimateAmount').errors?.required"
                  >Amount is <strong>required</strong></mat-error
                >
              </mat-form-field>
            </td>
            <td *matFooterCellDef mat-footer-cell class="body-1">
              <div class="amount-cell">
                <div class="sign-cell-total">$</div>
                {{ quote.estSubletTotal | number: '1.2-2' }}
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="empty">
            <th *matHeaderCellDef class="body-2" mat-header-cell></th>
            <td *matCellDef="let item" class="body-1" mat-cell></td>
            <td *matFooterCellDef mat-footer-cell class="body-1"></td>
          </ng-container>
          <ng-container matColumnDef="assessedAmount">
            <th *matHeaderCellDef class="body-2" mat-header-cell>Ass. Amount</th>
            <td *matCellDef="let item" class="body-1" mat-cell>
              <div *ngIf="item.assessedAmount || item.assessedAmount === 0" class="amount-cell">
                <div class="sign-cell">$</div>
                <div
                  [ngClass]="{
                    emphasis: item.assessedStatus !== 'ASSESSED_EQUAL',
                    red: item.assessedStatus === 'ASSESSED_REJECTED'
                  }"
                  class="assess-indicator">
                  {{ item.assessedAmount | number: '1.2-2' }}
                  <ng-container
                    [ngTemplateOutletContext]="{ item: item, small: true, added: item.addedInV6 }"
                    [ngTemplateOutlet]="assessedStatusIndicator"></ng-container>
                </div>
              </div>
            </td>
            <td *matFooterCellDef mat-footer-cell class="body-1">
              <div class="amount-cell" *ngIf="quote.assessed">
                <div class="sign-cell-total">$</div>
                {{ quote.assessedSubletTotal | number: '1.2-2' }}
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="delete">
            <th *matHeaderCellDef class="body-2" mat-header-cell></th>
            <td *matCellDef="let item; let index = index" class="body-1" mat-cell [formGroupName]="index">
              <div
                *ngIf="
                  quote.manual &&
                  !quote.submitted &&
                  subletItems.controls.length > 1 &&
                  subletItems.controls.length !== index + 1
                "
                class="amount-cell delete">
                <mat-icon (click)="deleteItem(item.id, index, 'subletItem', null)">delete</mat-icon>
              </div>
            </td>
          </ng-container>
          <tr *matHeaderRowDef="subletsColumnHeaders" mat-header-row></tr>
          <tr
            *matRowDef="let item; let even = even; columns: subletsColumnHeaders"
            mat-row
            [ngClass]="{ 'gray-row': even && !item.mergedFromReUpload, 'merged-item': item.mergedFromReUpload }"
            [class.row-manual-quote]="quote.manual"></tr>
          <tr *matFooterRowDef="subletsColumnFooters" mat-footer-row [hidden]="!showTotals"></tr>
        </table>
        <div
          *ngIf="
            overviewCheckbox.checked && (quote.sublets | filter: 'OVERVIEW' : overviewCheckbox.checked).length === 0
          "
          class="no-adjusted-line subtitle-2">
          <mat-icon>info_outlined</mat-icon>
          <span class="banner-text"
            >No adjusted lines. <a (click)="overviewCheckbox.checked = false">Clear your filter</a> to see all
            lines</span
          >
        </div>
      </div>
    </form>
  </mat-expansion-panel>
</div>

<ng-template #assessedStatusIndicator let-added="added" let-item="item" let-small="small">
  <div [class.added]="item.addedInV6" [class.small]="small" [ngClass]="item.assessedStatus" class="indicator">
    <ng-container [ngSwitch]="item.assessedStatus">
      <ng-container *ngIf="added">
        <mat-icon>add</mat-icon>
      </ng-container>
      <ng-container *ngIf="!added">
        <ng-container *ngIf="!small">
          <mat-icon *ngSwitchCase="'ASSESSED_EQUAL'">check</mat-icon>
        </ng-container>
        <mat-icon *ngSwitchCase="'ASSESSED_REJECTED'">clear</mat-icon>
        <mat-icon *ngSwitchCase="'ASSESSED_UNDER'">keyboard_arrow_down</mat-icon>
        <mat-icon *ngSwitchCase="'ASSESSED_OVER'">keyboard_arrow_up</mat-icon>
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #assessedTotals let-assessedAmount="assessedAmount" let-estimatedAmount="estimatedAmount">
  <div *ngIf="quote.assessed" class="assess-indicator no-margin">
    <div
      [ngClass]="{
        ASSESSED_UNDER: assessedAmount !== estimatedAmount,
        ASSESSED_EQUAL: assessedAmount === estimatedAmount
      }"
      class="indicator small margin-left-10">
      <mat-icon *ngIf="assessedAmount < estimatedAmount">keyboard_arrow_down</mat-icon>
      <mat-icon *ngIf="assessedAmount > estimatedAmount">keyboard_arrow_up</mat-icon>
      <mat-icon *ngIf="assessedAmount === estimatedAmount">check</mat-icon>
    </div>
  </div>
  <span *ngIf="assessedAmount !== estimatedAmount" class="overview-diff body-2"
    >$
    <span *ngIf="assessedAmount < estimatedAmount"> - </span>
    <span *ngIf="assessedAmount > estimatedAmount"> + </span>
    {{ Math.abs(assessedAmount - estimatedAmount) | number: '1.2-2' }}
  </span>
</ng-template>
