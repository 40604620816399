import { Pipe, PipeTransform } from '@angular/core';
import { LabourItem, PartItem, SubletItem } from '../model/document.model';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(values: any, type: string, ...filters: any[]): any {
    switch (type) {
      case 'OVERVIEW':
        if (filters[0]) {
          return values.filter(
            (item: LabourItem | PartItem | SubletItem) =>
              (item.assessedStatus !== 'NOT_ASSESSED' && item.assessedStatus !== 'ASSESSED_EQUAL') || item.assessorNote
          );
        }
        break;
      case 'NOTIFICATION':
        if (filters.length === 0) {
          switch (values) {
            case 'REPAIRS_AUTHORISED':
              return (values = 'Repairs Authorised');
              break;
            case 'REQUEST_FOR_QUOTE':
              return (values = 'Request for Quote');
              break;
            case 'REPAIRS_CANCELLED':
              return (values = 'Repairs Cancelled');
              break;
            case 'REGO_UPDATE':
              return (values = 'Rego Updated');
              break;
            case 'EXCESS_UPDATE':
              return (values = 'Excess Updated');
              break;
            default:
              return values;
          }
          return values;
        }
        break;
      case 'EVENT':
        if (filters.length === 0) {
          return values.filter((ev) => ev.checked);
        }
        break;
      case 'REPAIR_TIMELINE':
        return this.getTranslationBy(values);
      default:
        return values;
    }
    return values;
  }
  getTranslationBy(status: string): string {
    switch (status) {
      case 'REPAIR_BOOKED':
        return 'REPAIR BOOKED';
      case 'AUTHORISED':
        return 'AUTHORISED';
      case 'VEHICLE_IN':
        return 'VEHICLE IN';
      case 'REPAIR_STARTED':
        return 'REPAIR STARTED';
      case 'REPAIR_COMPLETED':
        return 'REPAIR COMPLETED';
      case 'VEHICLE_OUT':
        return 'VEHICLE OUT';
      default:
        return '';
    }
  }
}
