import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import xml2js from 'xml2js';
import { MessagingService } from '../components/messaging/messaging.service';

@Injectable({
  providedIn: 'root'
})
export class ParseXMLService {
  private message = inject(MessagingService);
  constructor() {}

  parseXML(file: any) {
    return new Observable((observer) => {
      let k: string | number,
        arr = [],
        parser = new xml2js.Parser({ trim: true, explicitArray: true });
      const reader = new FileReader();
      if (file) {
        reader.readAsText(file);
      }
      reader.onload = (xmlContent) => {
        let xml = xmlContent.target.result;
        parser.parseString(xml, (err, result) => {
          if (err) {
            this.message.error('This file cannot be imported: the XML format is invalid');
            observer.error();
            observer.complete();
          } else {
            let obj = result.EstImageClientQuote;
            let objOfAttachments = obj.Attachments?.[0]?.ImageAttachment;
            if (objOfAttachments) {
              for (k in objOfAttachments) {
                arr.push(objOfAttachments[k].FilePath[0]);
              }
            }
            observer.next(arr);
            observer.complete();
          }
        });
      };
    });
  }

  checkXmlValidity(file: any): Promise<boolean> {
    return new Promise((resolve) => {
      if (!file) {
        resolve(false);
      }

      const parser = new xml2js.Parser({ trim: true, explicitArray: true });
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = (xmlContent) => {
        parser.parseString(xmlContent.target.result, (err, result) => {
          if (err) {
            this.message.error('This file cannot be imported: the XML format is invalid');
            resolve(false);
          }
          resolve(true);
        });
      };
    });
  }
}
