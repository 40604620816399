<app-info-message></app-info-message>
<div class="background--confirm">
  <section class="form-container">
    <div *ngIf="errorMessage != ''" class="warn-box-authentication subtitle-1">
      <mat-icon matPrefix>error</mat-icon>
      {{ errorMessage }}
    </div>
    <mat-toolbar>
      <mat-toolbar-row>
        <img src="../../assets/images/logo.svg" />
        <h5 class="toolbar-title">Online</h5>
      </mat-toolbar-row>
    </mat-toolbar>
    <div *ngIf="!linkIsExpiredOrInvalid && !pendingCheck" class="content-confirm">
      <div class="welcome-text subtitle-1">
        <div>Welcome, {{ name }}</div>
        <div>Please set your new password</div>
      </div>
      <form (ngSubmit)="setPassword()" [formGroup]="setPasswordForm">
        <mat-form-field appearance="outline" color="accent" class="input-field-with-icon username">
          <mat-label>Username</mat-label>
          <mat-icon matPrefix color="accent">person</mat-icon>
          <mat-divider [vertical]="true"></mat-divider>
          <input autocomplete="username" formControlName="username" matInput required />
          <mat-error *ngIf="username.hasError('required')"> Username is <strong>required</strong> </mat-error>
          <mat-error *ngIf="!username.valid && !username.hasError('required')" class="mat-error">
            Please enter a valid username
          </mat-error>
        </mat-form-field>

        <div class="gray-title">
          <div>Your password must include:</div>
          <div>
            <mat-icon [class.green]="!password.hasError('minlength')">{{
              !password.hasError('minlength') ? 'check_circle' : 'radio_button_unchecked'
            }}</mat-icon>
            at least {{ minCharacters }} characters
          </div>
        </div>

        <mat-form-field appearance="outline" color="accent" class="input-field-with-icon password">
          <mat-label>Password</mat-label>
          <mat-icon matPrefix color="accent">lock</mat-icon>
          <mat-divider [vertical]="true"></mat-divider>
          <input
            (ngModelChange)="errorMessage = ''"
            [type]="hidePassword ? 'password' : 'text'"
            autocomplete="new-password"
            formControlName="password"
            matInput />
          <button (click)="hidePassword = !hidePassword" mat-icon-button matSuffix type="button">
            <mat-icon>{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
          </button>
          <mat-error *ngIf="password.hasError('minlength')">
            Must be at least {{ minCharacters }} characters long.
          </mat-error>
          <mat-error *ngIf="password.hasError('containUsername')"> Password cannot contain your username </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" color="accent" class="input-field-with-icon">
          <mat-label>Confirm password &nbsp;</mat-label>
          <mat-icon matPrefix color="accent">lock</mat-icon>
          <mat-divider [vertical]="true"></mat-divider>
          <input
            [type]="hideConfirmPassword ? 'password' : 'text'"
            autocomplete="new-password"
            formControlName="confirmPassword"
            matInput />
          <button (click)="hideConfirmPassword = !hideConfirmPassword" mat-icon-button matSuffix type="button">
            <mat-icon>{{ hideConfirmPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
          </button>
          <mat-error *ngIf="confirmPassword.hasError('passwordMismatch')"> Your passwords do not match </mat-error>
        </mat-form-field>
        <button
          [disabled]="setPasswordForm.invalid || submitPending"
          class="buttons body-1"
          color="primary"
          mat-raised-button
          type="submit">
          SET PASSWORD
        </button>
      </form>
    </div>

    <div *ngIf="linkIsExpiredOrInvalid && !pendingCheck" class="warn-container">
      <div class="warn-box">
        <mat-icon matPrefix>report_problem</mat-icon>
        <div>This link has expired or has been invalidated.</div>
        <div class="margin-top-20">
          To Receive a new link, please contact your Location's administrator or contact Stelvio Support at
        </div>
        <div>(02) 9455 1055</div>
      </div>
    </div>
  </section>
</div>
<app-static-footer></app-static-footer>
