<app-info-message></app-info-message>
<div class="background--confirm">
  <section class="form-container" *ngIf="!reset">
    <mat-toolbar>
      <mat-toolbar-row>
        <img src="../../assets/images/logo.svg" />
        <h5 class="toolbar-title">Online</h5>
      </mat-toolbar-row>
    </mat-toolbar>
    <div class="content-login">
      <div class="welcome-text">Please enter the username you used to register</div>
      <form [formGroup]="resetForm" (ngSubmit)="resetPassword()" (keydown.enter)="resetPassword()">
        <mat-form-field appearance="outline" color="accent" class="input-field-with-icon">
          <mat-label>Username</mat-label>
          <mat-icon matPrefix color="accent">person</mat-icon>
          <mat-divider [vertical]="true"></mat-divider>
          <input autocomplete="username" formControlName="username" matInput required />
          <mat-error *ngIf="username.hasError('required')"> Username is <strong>required</strong> </mat-error>
        </mat-form-field>
        <button [disabled]="resetForm.invalid" color="primary" mat-raised-button type="submit" class="buttons body-1">
          REQUEST RESET LINK
        </button>
        <button type="button" mat-button class="buttons body-1" (click)="backToLogin()">BACK TO LOGIN</button>
      </form>
    </div>
  </section>
  <section class="form-container" *ngIf="reset">
    <mat-toolbar color="accent">
      <mat-toolbar-row>
        <img src="../../assets/images/logo.svg" />
        <h2 class="toolbar-title">online</h2>
      </mat-toolbar-row>
    </mat-toolbar>
    <div class="content-login">
      <div class="welcome-text" [class.reset]="reset">
        <mat-icon>info_outline_24px</mat-icon>
        We’ve sent you an email with a link you can click to set a new password.
      </div>
    </div>
  </section>
</div>
<app-static-footer></app-static-footer>
