import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Make, Model, Variant } from '../model/glass-guide.model';

@Injectable({
  providedIn: 'root'
})
export class GlassGuideService {
  constructor(private http: HttpClient) {}

  private static handleError(error: any) {
    return Promise.reject(error.error || error.details || error);
  }

  getMakes() {
    return this.http.get<Make[]>('ui/glassGuideService/vehicles/makes').pipe(catchError(GlassGuideService.handleError));
  }

  getMakeModels(makeId: number) {
    return this.http
      .get<Model[]>(`ui/glassGuideService/vehicles/makes/${makeId}/models`)
      .pipe(catchError(GlassGuideService.handleError));
  }

  getMakeYears(makeId: number) {
    return this.http
      .get<number[]>(`ui/glassGuideService/vehicles/makes/${makeId}/years`)
      .pipe(catchError(GlassGuideService.handleError));
  }

  getModelYears(makeId: number, modelId: number) {
    return this.http
      .get<number[]>(`ui/glassGuideService/vehicles/makes/${makeId}/models/${modelId}/years`)
      .pipe(catchError(GlassGuideService.handleError));
  }

  getYearModels(makeId: number, year: number) {
    return this.http
      .get<Model[]>(`ui/glassGuideService/vehicles/makes/${makeId}/years/${year}/models`)
      .pipe(catchError(GlassGuideService.handleError));
  }

  getVariants(makeId: number, modelId: number, year: number) {
    return this.http
      .get<Variant[]>(`ui/glassGuideService/vehicles/makes/${makeId}/models/${modelId}/years/${year}/variants`)
      .pipe(catchError(GlassGuideService.handleError));
  }

  getLastUpdateDate() {
    return this.http.get<string>('ui/glassGuideService/lastUpdateDate').pipe(catchError(GlassGuideService.handleError));
  }
}
