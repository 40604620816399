<div class="notification-section">
  <h4 class="title">Customer Contact</h4>
  <table
    #customerContactSort="matSort"
    mat-table
    matSort
    [dataSource]="dataSource"
    class="table-style"
    matSortActive="date"
    matSortDirection="desc"
    matSortDisableClear
    (matSortChange)="activeColumn = $event.active">
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef [class.active]="activeColumn === 'date'" mat-sort-header>
        Date of Contact
      </th>
      <td mat-cell class="body-1" *matCellDef="let row">
        <div>{{ row.date | date: 'dd/MM/yyyy' }} {{ row.time }}</div>
        <div>
          {{ row.contactTo?.displayName }}
          <span class="contact-type">{{
            row.contactTo?.type === 'OWNER' ? '(' + (row.contactTo?.type | titlecase) + ')' : ''
          }}</span>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="isContactSuccessful">
      <th mat-header-cell *matHeaderCellDef [class.active]="activeColumn === 'isContactSuccessful'" mat-sort-header>
        Contact
      </th>
      <td mat-cell class="body-1" *matCellDef="let row">
        <mat-icon *ngIf="row.isContactSuccessful" class="success-color">done</mat-icon>
        <mat-icon *ngIf="!row.isContactSuccessful" color="warn">clear</mat-icon>
      </td>
    </ng-container>
    <ng-container matColumnDef="contactMethodType">
      <th mat-header-cell *matHeaderCellDef [class.active]="activeColumn === 'contactMethodType'" mat-sort-header>
        Method
      </th>
      <td mat-cell class="body-1" *matCellDef="let row">{{ row.contactMethodType | humanize | titlecase }}</td>
    </ng-container>
    <ng-container matColumnDef="isIssueDiscussed">
      <th mat-header-cell *matHeaderCellDef [class.active]="activeColumn === 'isIssueDiscussed'" mat-sort-header>
        Issue discussed
      </th>
      <td mat-cell class="body-1" *matCellDef="let row">
        <span>{{ row.isIssueDiscussed ? 'Yes - ' : 'No' }}</span>
        <span *ngIf="row.isIssueDiscussed">
          {{ row.issueResolutionType === 'RESOLVED' ? '  Resolved' : ' Unresolved' }}</span
        >
      </td>
    </ng-container>
    <ng-container matColumnDef="additionalDetails">
      <th mat-header-cell *matHeaderCellDef [class.active]="activeColumn === 'additionalDetails'" mat-sort-header>
        Details
      </th>
      <td mat-cell class="body-1" *matCellDef="let row">
        <ng-container *ngIf="row.issueDiscussionDetails">
          <div class="details-title">Issue Details</div>
          <div>{{ row.issueDiscussionDetails }}</div>
        </ng-container>
        <div class="details-title">Additional Details</div>
        <div>{{ row.additionalDetails }}</div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columnHeader"></tr>
    <tr mat-row *matRowDef="let row; columns: columnHeader"></tr>
  </table>
</div>
