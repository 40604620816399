<div class="dialog-header" mat-dialog-title>
  <h6>{{ data?.bsb ? 'Edit' : 'Add' }} Banking Details</h6>
  <button aria-label="Close" mat-dialog-close mat-icon-button>
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-divider></mat-divider>
<form (ngSubmit)="confirmPassword()" [formGroup]="editBankingInfoForm">
  <div class="edit-banking-container" mat-dialog-content>
    <div class="fields-box">
      <mat-form-field appearance="outline" color="accent">
        <mat-label>BSB</mat-label>
        <input formControlName="bsb" matInput required maxlength="20" />
        <mat-error> BSB is <strong>required</strong> </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" color="accent">
        <mat-label>Account Number</mat-label>
        <input formControlName="accountNumber" matInput required maxlength="20" />
        <mat-error> Account Number is <strong>required</strong> </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" color="accent">
        <mat-label>Account Name</mat-label>
        <input formControlName="accountName" matInput required maxlength="64" />
        <mat-error> Account Name is <strong>required</strong> </mat-error>
      </mat-form-field>
    </div>
  </div>
  <mat-dialog-actions>
    <button mat-raised-button mat-dialog-close color="accent">CANCEL</button>
    <button
      [disabled]="editBankingInfoForm.invalid || editBankingInfoForm.pristine || pending"
      class="buttons"
      color="primary"
      mat-raised-button
      type="submit">
      SAVE
    </button>
  </mat-dialog-actions>
</form>
