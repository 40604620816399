<mat-card [routerLink]="['/jobViewer', job.jobId]" (click)="saveActiveJob(job.jobId)">
  <mat-card-content>
    <div
      class="job-card"
      [ngClass]="{ 'unread-job bg-alice-blue': job?.unread, 'active-job': job.jobId === activeJobId }">
      <div class="left-block">
        <div class="timestamp body-2">
          <mat-icon class="ellipse" *ngIf="hasRedBudge" inline="true" color="primary">lens </mat-icon>
          <ng-container *ngIf="job?.unsubmittedDocument || job?.unreadDiary">
            <mat-icon color="primary" class="mat-icon-comment" *ngIf="job?.unreadDiary" inline="true"
              >comment_24px</mat-icon
            >
            <mat-icon color="primary" inline="true" *ngIf="job?.unsubmittedDocument">send</mat-icon>
          </ng-container>
        </div>
        <div
          *ngIf="job.inspectionDate && (bucket === 'REQUEST_FOR_QUOTE' || bucket === 'PENDING_ASSESSMENT')"
          class="body-2 display-ellipsis">
          Inspection scheduled: {{ job.inspectionDate | date: 'd/M/yy' }}
        </div>
        <div class="claim-nb display-ellipsis subtitle-1 flex items-center" [ngClass]="{ 'font-bold': job?.unread }">
          <mat-icon
            *ngIf="job?.unread"
            class="custom-mat-icon mat-icon-xs material-icons-outlined bg-pacific-blue text-white rounded p-[3px] mr-5">
            mark_email_unread_outline
          </mat-icon>
          {{ job.claimNumber }}
        </div>
        <div class="body-2 display-ellipsis">
          {{ job.insco }}
        </div>
        <div *ngIf="job?.ownerCompanyName || job?.ownerPersonName" class="owner">
          <mat-icon>account_circle</mat-icon>
          <span class="body-2">{{
            job.ownerIsACompany ? job?.ownerCompanyName || '' : job?.ownerPersonName || ''
          }}</span>
        </div>
        <div
          class="display-ellipsis body-2"
          [class.scrolling-text]="job?.vehicleDescription && setAnimation"
          #vehicleDescription>
          <span class="full-vehicle" #vehicleDescriptionText>{{ job.vehicleDescription }}</span>
        </div>
      </div>
      <app-car-placeholder-or-photo
        [isInRepairerTile]="true"
        [jobInfo]="{ jobId: job?.jobId, repairerSiteId: repairerSite?.id, rego: job?.rego }"
        [photoInfo]="{
          photoIdentityId: job?.photoIdentityUI?.id,
          photoIdentityExtension: job?.photoIdentityUI?.extension
        }">
      </app-car-placeholder-or-photo>
      <div class="kebab-block">
        <button mat-icon-button [matMenuTriggerFor]="kebabMenu" (click)="$event.stopPropagation()">
          <mat-icon>more_vert_32px</mat-icon>
        </button>
      </div>
    </div>
    <div *ngIf="job.totalLoss" class="body-3 total-loss-indicator">
      <mat-icon svgIcon="total_loss_icon"></mat-icon>
      TOTAL LOSS
    </div>
    <div
      *ngIf="job?.authorityDate !== '' && ['AUTHORIZED', 'PRE_AUTHORIZED'].includes(bucket)"
      class="status body-2"
      (click)="openRepairTimelineModal(job); $event.stopPropagation()">
      <div>BOOK REPAIRS</div>
      <button mat-flat-button class="status-menu" type="button" color="primary" mat-icon-button>
        <mat-icon>exit_to_app</mat-icon>
      </button>
    </div>
  </mat-card-content>
</mat-card>

<mat-menu #kebabMenu="matMenu" [xPosition]="'before'" class="job-progress">
  <ng-template matMenuContent>
    <button (click)="archiveJob(job?.jobId)" mat-menu-item>Archive job</button>
    <button (click)="toggleJobReadStatus(job?.unread, job?.jobId)" mat-menu-item>
      Mark as {{ job?.unread ? 'read' : 'unread' }}
    </button>
  </ng-template>
</mat-menu>
