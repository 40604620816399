<form [formGroup]="dashboardDaysForm" (ngSubmit)="saveOrExecuteAction()">
  <mat-form-field appearance="outline" color="accent">
    <mat-label>Days</mat-label>
    <input formControlName="days" matInput type="number" min="1" required />
    <mat-error *ngIf="days.hasError('required')"> Days is <strong>required.</strong> </mat-error>
    <mat-error *ngIf="days.hasError('min')"> Days should be more than zero. </mat-error>
  </mat-form-field>
  <div class="guidance-message-container empty-state">
    <mat-icon>error_outline</mat-icon>
    <span
      >Jobs after this number of days will be hidden from your dashboard. They will remain available in the
      system</span
    >
  </div>
</form>
