<div class="notification-section">
  <div class="breach-code-title">
    <h4 class="title">Potential Breach of Code</h4>
    <div class="info subtitle-2">
      <mat-icon>info_outlined</mat-icon>
      <span
        >Based on the
        <a
          href="https://insurancecode.org.au/app/uploads/2021/10/GI-Code-2020-5-October-2021-Version.pdf"
          rel="noopener"
          target="_blank"
          >General Insurance code of practice 2020</a
        >
      </span>
    </div>
  </div>

  <table
    #potentialBreachOfCodeSort="matSort"
    mat-table
    matSort
    [dataSource]="dataSource"
    class="table-style"
    matSortActive="dateOfIncident"
    matSortDirection="desc"
    matSortDisableClear
    (matSortChange)="activeColumn = $event.active">
    <ng-container matColumnDef="dateOfIncident">
      <th mat-header-cell *matHeaderCellDef [class.active]="activeColumn === 'dateOfIncident'" mat-sort-header>
        Date of Breach
      </th>
      <td mat-cell class="body-1" *matCellDef="let row">{{ row.dateOfIncident | date: 'dd/MM/yyyy' }}</td>
    </ng-container>
    <ng-container matColumnDef="dateDiscovered">
      <th mat-header-cell *matHeaderCellDef [class.active]="activeColumn === 'dateDiscovered'" mat-sort-header>
        Date discovered
      </th>
      <td mat-cell class="body-1" *matCellDef="let row">{{ row.dateDiscovered | date: 'dd/MM/yyyy' }}</td>
    </ng-container>
    <ng-container matColumnDef="raisedBy">
      <th mat-header-cell *matHeaderCellDef [class.active]="activeColumn === 'raisedBy'" mat-sort-header>Raised By</th>
      <td mat-cell class="body-1" *matCellDef="let row">{{ row.raisedBy }}</td>
    </ng-container>
    <ng-container matColumnDef="partOfCode">
      <th mat-header-cell *matHeaderCellDef [class.active]="activeColumn === 'partOfCode'" mat-sort-header>
        Code of practice
      </th>
      <td mat-cell class="body-1" *matCellDef="let row">
        <div>{{ partOfCodeEnum[row.partOfCode] }}</div>
        <div>{{ row.description }}</div>
      </td>
    </ng-container>
    <ng-container matColumnDef="codeOfPracticeParagraph">
      <th mat-header-cell *matHeaderCellDef [class.active]="activeColumn === 'details'" mat-sort-header>Paragraph</th>
      <td mat-cell class="body-1" *matCellDef="let row">{{ row.codeOfPracticeParagraph }}</td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columnHeader"></tr>
    <tr mat-row *matRowDef="let row; columns: columnHeader"></tr>
  </table>
</div>
