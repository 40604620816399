import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'loading-spinner',
  standalone: true,
  imports: [MatProgressSpinnerModule, NgIf],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div *ngIf="loading" class="flex items-center justify-center mt-10">
      <mat-spinner [diameter]="spinnerHeight" color="warn" />
      <div class="text-primary-500 text-[14px] ml-10">Loading...</div>
    </div>
  `
})
export class LoadingSpinnerComponent {
  @Input({ required: true }) loading: boolean;
  @Input() spinnerHeight = 40;
}
