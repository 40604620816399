import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import * as moment from 'moment';
import { TimelineBox } from '../../../model/TimelineBox.model';
import { Job } from '../../../model/job.model';
import { User } from '../../../model/user.model';

@Component({
  selector: 'app-repair-timeline-curtain',
  templateUrl: './repair-timeline-curtain.component.html',
  styleUrls: ['./repair-timeline-curtain.component.scss']
})
export class RepairTimelineCurtainComponent implements OnChanges {
  @Input() job: Job;
  @Input() currentTimezone: string;
  @Output() editRepairTimeline = new EventEmitter();
  timelineBoxes: TimelineBox[];
  currentUser: User;
  isEditionDisabled: boolean;
  constructor() {}

  ngOnChanges(): void {
    this.timelineBoxes = [
      {
        status: 'Repairs Booked',
        date: this.job?.repairBookedDate,
        isDateSetInPast:
          !!this.job?.repairBookedDate &&
          moment.tz(this.job?.repairBookedDate, this.currentTimezone).isBefore(moment().tz(this.currentTimezone))
      },
      {
        status: 'Vehicle In',
        date: this.job?.vehicleDateIn,
        isDateSetInPast:
          !!this.job?.vehicleDateIn &&
          moment.tz(this.job?.vehicleDateIn, this.currentTimezone).isBefore(moment().tz(this.currentTimezone))
      },
      {
        status: 'Repairs Started',
        date: this.job?.repairStartedDate,
        isDateSetInPast:
          !!this.job?.repairStartedDate &&
          moment.tz(this.job?.repairStartedDate, this.currentTimezone).isBefore(moment().tz(this.currentTimezone))
      },
      {
        status: 'Repairs Completed',
        date: this.job?.repairCompletedDate,
        isDateSetInPast:
          !!this.job?.repairCompletedDate &&
          moment.tz(this.job?.repairCompletedDate, this.currentTimezone).isBefore(moment().tz(this.currentTimezone))
      },
      {
        status: 'Vehicle Out',
        date: this.job?.vehicleDateOut,
        isDateSetInPast:
          !!this.job?.vehicleDateOut &&
          moment.tz(this.job?.vehicleDateOut, this.currentTimezone).isBefore(moment().tz(this.currentTimezone))
      }
    ];
    this.isEditionDisabled =
      this.job?.cancelled ||
      (!this.job?.documents?.some((doc) => doc.type === 'INVOICE' && doc.submitted) &&
        !(
          (!this.job?.selfAuthorized &&
            this.job?.documents?.some((doc) => doc.type === 'QUOTE' && doc.submitted) &&
            !!this.job?.authority) ||
          (this.job.selfAuthorized && !!this.job.authorityDate)
        ));
  }
}
