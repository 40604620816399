import { Component, DestroyRef, inject, Inject, OnInit, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { DocumentItem, Quote } from '../../../model/document.model';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UserService } from '../../../services/user.service';
import { AddInvoice } from '../../../model/add-invoice.model';
import { ToolbarService } from '../../../services/toolbar.service';
import { MessagingService } from '../../../components/messaging/messaging.service';
import { EscapeDialogHelper } from '../../../helpers/escape-dialog.helper';
import { UploadFilesComponent } from '../upload-files/upload-files.component';
import { mediumModalConfig } from '../../../consts/modal-config.const';
import { ModalConfigsHelper } from '../../../helpers/modal-configs.helper';
import { CurrentUserService } from '../../../services/currentUser.service';
import { ParseXMLService } from '../../../services/parseXML.service';
import { DecimalPipe } from '@angular/common';
import { fadeSlideUpDown } from '../../../animations/fade-slide-up-down.animation';
import { fadeInOut } from '../../../animations/fade-in-out.animation';

@Component({
  selector: 'app-add-invoice',
  templateUrl: './add-invoice.component.html',
  providers: [DecimalPipe],
  animations: [fadeSlideUpDown, fadeInOut],
  styles: [
    `
      .quote-list-grid {
        display: grid;
        grid-template-columns: auto 90px;
        height: 65px;
        padding: 0 10px;
        align-items: center;
      }
      .total {
        display: inline-flex;
        align-items: center;
        justify-content: flex-end;
        padding: 10px;
        width: 45%;
      }
    `
  ]
})
export class AddInvoiceComponent implements OnInit {
  quotes: DocumentItem[];
  invoiceForm: UntypedFormGroup;
  pending = signal(false);
  pendingImages = signal(false);
  assessedTotalInvoice = 0;
  assessedSubTotalInvoice = 0;
  assessedGSTTotalInvoice = 0;
  fileName: string;
  lastModified: number;
  invoiceFiles: any;
  importInvoiceFiles: any;
  imageFiles: any;
  timezone: string;
  uploadImages = true;
  fileDeleted = false;
  selectedIndex = 0;
  imagesPluralMapping = { '=1': '1 image', other: '# images' };
  decimalPipe = inject(DecimalPipe);
  destroyRef = inject(DestroyRef);
  get invoiceNumber() {
    return this.invoiceForm?.controls.invoiceNumber;
  }
  get ownerContribution() {
    return this.invoiceForm?.controls.ownerContribution;
  }
  invoiceTotal: WritableSignal<number> = signal(0);

  constructor(
    public dialogRef: MatDialogRef<AddInvoiceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder,
    private userService: UserService,
    private currentUserService: CurrentUserService,
    public toolbarService: ToolbarService,
    private message: MessagingService,
    private uploadFiles: MatDialog,
    private escapeDialogRef: EscapeDialogHelper,
    private modalConfigsHelper: ModalConfigsHelper,
    private parseXMLService: ParseXMLService
  ) {}

  ngOnInit(): void {
    this.quotes = this.data.authorizedQuotes;
    this.quotes.sort((a, b) => (a.document as Quote).assessedDateTime - (b.document as Quote).assessedDateTime);
    this.assessedTotalInvoice = this.quotes.reduce((acc, item) => acc + (item.document as Quote).assessedTotal, 0);
    this.assessedSubTotalInvoice = this.quotes.reduce(
      (acc, item) => acc + (item.document as Quote).assessedSubTotal,
      0
    );
    this.assessedGSTTotalInvoice = this.quotes.reduce(
      (acc, item) => acc + (item.document as Quote).assessedGSTTotal,
      0
    );
    this.invoiceForm = this.fb.group({
      invoiceNumber: ['', Validators.required],
      ownerContribution: this.data.ownerContribution || ''
    });
    this.ownerContribution.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.calculateInvoiceTotal());
    this.escapeDialogRef.escapeDialog(this.dialogRef);
    this.timezone = this.currentUserService?.timeZone;
    this.selectedIndex = this.data?.selectedIndex;
    this.calculateInvoiceTotal();
  }

  async importInvoice(invoiceInput: any) {
    if (invoiceInput.files.length) {
      this.pending.set(true);
      const xmlValid = await this.parseXMLService.checkXmlValidity(invoiceInput.files[0]);
      if (xmlValid) {
        this.pendingImages.set(true);
        this.fileDeleted = false;
        this.fileName = invoiceInput.files[0].name;
        this.lastModified = invoiceInput.files[0].lastModified;
        this.invoiceFiles = invoiceInput.files;
        this.importInvoiceFiles = invoiceInput.files;
      } else {
        invoiceInput.value = '';
      }
      this.pending.set(false);
    }
  }

  calculateInvoiceTotal() {
    const doCalculations =
      !this.data.lastInvoice || (this.data.lastInvoice?.isFirstInvoice && this.data.lastInvoice?.status === 'REJECTED');
    const total = doCalculations
      ? this.assessedTotalInvoice - (this.data.excess ?? 0) - (this.ownerContribution.value ?? 0)
      : this.assessedTotalInvoice;

    this.invoiceTotal.set(total);
  }

  setFileFromHandler(file: any) {
    this.pendingImages.set(true);
    this.fileName = file?.name;
    this.lastModified = file?.lastModified;
    this.invoiceFiles = file;
  }

  setImages(images: any[]) {
    this.imageFiles = images;
    this.pendingImages.set(false);
  }

  deleteFile(invoiceInput: any) {
    this.fileName = '';
    this.lastModified = undefined;
    this.invoiceFiles = undefined;
    delete this.importInvoiceFiles;
    invoiceInput.value = '';
    this.fileDeleted = true;
    this.imageFiles = null;
  }

  addInvoice(importFromQuote: boolean) {
    if (importFromQuote) {
      this.pending.set(true);
      this.toolbarService.setCloudState('SAVING');
      const invoiceToAdd: AddInvoice = {};
      invoiceToAdd.jobId = this.data.jobId;
      invoiceToAdd.invoiceNumber = this.invoiceNumber.value;
      invoiceToAdd.invoiceDate = '';
      this.invoiceForm.disable();
      this.userService.addInvoice(invoiceToAdd, this.data.jobId).subscribe(
        () => {
          if (
            !this.data.lastInvoice ||
            (this.data.lastInvoice.isFirstInvoice && this.data.lastInvoice.status === 'REJECTED')
          ) {
            this.userService
              .updateJob(
                (this.ownerContribution.value ?? '').toString().replace(/,/g, ''),
                this.data.jobId,
                'ownerContribution'
              )
              .subscribe(
                () => {
                  this.toolbarService.setCloudState('RESTING');
                  this.pending.set(false);
                  this.dialogRef.close('Success');
                },
                () => {
                  this.invoiceForm.enable();
                  this.message.error('Could not update Owner Contribution.');
                  this.toolbarService.setCloudState('RESTING');
                }
              );
          } else {
            this.toolbarService.setCloudState('RESTING');
            this.pending.set(false);
            this.dialogRef.close('Success');
          }
        },
        (error) => {
          this.invoiceForm.enable();
          if (error.error === 'DUPLICATE_INVOICE_NUMBER') {
            this.invoiceNumber.setErrors({ duplicateInvoice: true });
          } else {
            this.message.error('Could not add invoice.');
          }
          this.toolbarService.setCloudState('RESTING');
          this.pending.set(false);
        }
      );
    } else {
      const data = {
        jobId: this.data.jobId,
        files: this.invoiceFiles,
        invoiceNumber: this.invoiceNumber.value,
        isInvoice: true
      };
      const dialogRef = this.uploadFiles.open(
        UploadFilesComponent,
        this.modalConfigsHelper.buildMediumModalConfig(data, mediumModalConfig)
      );
      dialogRef.afterClosed().subscribe((result) => {
        if (result === 'Success') {
          this.message.confirm('Invoice Uploaded');
          this.uploadImages && this.imageFiles?.length ? this.addImages() : this.dialogRef.close('Success');
        }
      });
    }
  }

  addImages() {
    const data = { jobId: this.data.jobId, files: this.imageFiles, isPhotos: true };
    const dialogRef = this.uploadFiles.open(
      UploadFilesComponent,
      this.modalConfigsHelper.buildMediumModalConfig(data, mediumModalConfig)
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'Success') {
        this.message.confirm(`Photos Uploaded`);
      }
      this.dialogRef.close('Success');
    });
  }

  formatOwnerContribution() {
    this.ownerContribution.setValue(
      this.ownerContribution.value ? this.decimalPipe.transform(this.ownerContribution.value, '1.2-2') : '',
      { emitEvent: false }
    );
    this.ownerContribution.setErrors(null);
  }
}
