<div class="add-customer-contact">
  <mat-card class="curtains">
    <div class="customer-contact-header secondary-icon">
      <h5>Customer Contact</h5>
      <div class="customer-panel">
        <button mat-icon-button (click)="confirmDeleteCustomerContact()">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
    <mat-card-content>
      <form [formGroup]="customerContactedForm" class="gicop">
        <div class="three-fields">
          <mat-form-field appearance="outline">
            <mat-label>From</mat-label>
            <mat-select formControlName="contactFrom" required>
              <mat-option *ngFor="let from of contactsFrom" [value]="from.type">
                {{ from.displayName }}<span>{{ from.type === 'OWNER' ? ' (Owner)' : '' }}</span>
              </mat-option>
            </mat-select>
            <mat-error *ngIf="contactFrom.invalid">From is <strong>required</strong></mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>To</mat-label>
            <mat-select formControlName="contactTo" required>
              <mat-option *ngFor="let to of contactsTo" [value]="to.type">
                {{ to.displayName }}<span>{{ to.type === 'OWNER' ? ' (Owner)' : '' }}</span>
              </mat-option>
            </mat-select>
            <mat-error *ngIf="contactTo.invalid">To is <strong>required</strong></mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Contact method</mat-label>
            <mat-select formControlName="contactMethodType" required>
              <mat-option
                *ngFor="let contactMethod of customerContactMethodEnum | keyvalue"
                [value]="contactMethod.key">
                <span>{{ contactMethod.value }}</span>
              </mat-option>
            </mat-select>
            <mat-error *ngIf="contactMethodType.invalid">Contact method is <strong>required</strong></mat-error>
          </mat-form-field>
        </div>
        <div class="five-fields">
          <mat-form-field appearance="outline" color="accent">
            <mat-label>Date</mat-label>
            <input
              [matDatepicker]="customerContactDatepicker"
              [max]="currentDate"
              formControlName="date"
              (dateChange)="validateTime()"
              matInput
              required />
            <mat-datepicker-toggle [for]="customerContactDatepicker" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #customerContactDatepicker></mat-datepicker>
            <mat-hint>DD/MM/YYYY</mat-hint>
            <mat-error *ngIf="date.errors?.matDatepickerParse && !date.errors?.matDatepickerMax">
              Format is <strong>DD/MM/YYYY</strong>
            </mat-error>
            <mat-error
              *ngIf="date.errors?.required && !date.errors?.matDatepickerParse && !date.errors?.matDatepickerMax">
              Date is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="date.errors?.inFuture"> Date cannot be in the future </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" color="accent" class="time-picker">
            <mat-label>Time</mat-label>
            <input type="text" formControlName="time" (input)="validateTime()" matInput required />
            <mat-hint>HH:mm</mat-hint>
            <mat-error *ngIf="time.errors?.required && !time.errors?.invalid && !time.errors?.inFuture">
              The time is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="time.errors?.invalid && !time.errors?.inFuture">
              The time format is <strong>HH:mm</strong>
            </mat-error>
            <mat-error *ngIf="time.errors?.inFuture"> Time cannot be in the future </mat-error>
          </mat-form-field>

          <div class="contact-successful">
            <label id="radio-group-contact-successful" class="body-2">Contact successful</label>
            <mat-radio-group
              aria-labelledby="radio-group-contact-successful"
              color="accent"
              formControlName="isContactSuccessful"
              class="radio-group">
              <mat-radio-button class="body-1" [value]="true">Yes</mat-radio-button>
              <mat-radio-button class="body-1 no" [value]="false">No</mat-radio-button>
            </mat-radio-group>
          </div>

          <ng-container *ngIf="isContactSuccessful.value">
            <div class="issue-discussed">
              <label id="radio-group-issue discussed" class="body-2">Was an issue discussed</label>
              <mat-radio-group
                aria-labelledby="radio-group-issue discussed"
                color="accent"
                formControlName="isIssueDiscussed"
                class="radio-group">
                <mat-radio-button class="body-1" [value]="true">Yes</mat-radio-button>
                <mat-radio-button class="body-1 no" [value]="false">No</mat-radio-button>
              </mat-radio-group>
            </div>
          </ng-container>

          <ng-container *ngIf="isIssueDiscussed.value">
            <mat-form-field appearance="outline" color="accent" *ngIf="isIssueDiscussed.value">
              <mat-label>Issue resolution</mat-label>
              <mat-select formControlName="issueResolutionType" [required]="isIssueDiscussed.value">
                <mat-option *ngFor="let resolution of issueResolutionEnum | keyvalue" [value]="resolution.key">
                  {{ resolution.value }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="issueResolutionType.invalid">Issue resolution is <strong>required</strong></mat-error>
            </mat-form-field>
          </ng-container>
        </div>

        <ng-container *ngIf="isIssueDiscussed.value">
          <mat-form-field appearance="outline" color="accent" class="textarea-fields">
            <mat-label>Issue discussed</mat-label>
            <textarea
              matInput
              autocomplete="off"
              formControlName="issueDiscussionDetails"
              maxlength="65535"
              cdkTextareaAutosize="true"
              cdkAutosizeMinRows="1"
              required></textarea>
            <mat-error *ngIf="issueDiscussionDetails.invalid">Issue details is <strong>required</strong></mat-error>
          </mat-form-field>
        </ng-container>

        <mat-form-field appearance="outline" color="accent" class="textarea-fields">
          <mat-label>Additional details</mat-label>
          <textarea
            matInput
            autocomplete="off"
            formControlName="additionalDetails"
            maxlength="65535"
            cdkTextareaAutosize="true"
            cdkAutosizeMinRows="1"
            required></textarea>
          <mat-error *ngIf="additionalDetails.invalid">Additional details is <strong>required</strong></mat-error>
        </mat-form-field>
      </form>
    </mat-card-content>
  </mat-card>
</div>
