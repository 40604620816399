import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Pipes
import { HumanizePipe } from './pipes/humanize.pipe';

// Components
import { EstimagePayGuidanceComponent } from './dashboard/job-viewer/invoice-curtain/estimage-pay-guidance/estimage-pay-guidance.component';
import { FinanceCompanyGuidanceComponent } from './dashboard/job-viewer/invoice-curtain/finance-company-guidance/finance-company-guidance.component';
import { InfosContentComponent } from './components/infos-content/infos-content.component';

// To use when we need to import new standalone components, pipes... but still need to use them on old pages
@NgModule({
  imports: [
    CommonModule,
    HumanizePipe,
    EstimagePayGuidanceComponent,
    FinanceCompanyGuidanceComponent,
    InfosContentComponent
  ]
})
export class SharedModule {}
