<div class="background--login">
  <section class="eula-container">
    <mat-toolbar color="accent">
      <mat-toolbar-row>
        <img src="../../assets/images/logo.svg" />
        <h2 class="toolbar-title">online</h2>
      </mat-toolbar-row>
    </mat-toolbar>
    <div class="content-login">
      <div class="eula-text subtitle-1">
        <div>Welcome, {{ currentUser.firstName }} {{ currentUser.lastName }}.</div>
        <div>
          Kindly read the following EstImage End User License Agreement. To accept, please click the Accept button to
          continue
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="eula subtitle-2" [innerHTML]="eulaContent"></div>
      <mat-divider></mat-divider>
      <div class="eula-text subtitle-1">
        If you agree to the terms and conditions of the EstImage End User License Agreement, click Accept, otherwise,
        click Decline.
      </div>
      <mat-divider></mat-divider>
      <div class="buttons-container">
        <button (click)="decline()" mat-raised-button color="accent" class="buttons">DECLINE</button>
        <button (click)="agree()" color="primary" mat-raised-button type="submit" class="buttons">ACCEPT</button>
      </div>
    </div>
  </section>
</div>
