import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { PartOfCodeEnum, PotentialBreachOfCode } from '../../../../model/gicop-notifications';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import * as moment from 'moment';

@Component({
  selector: 'app-potential-breach-code-table',
  templateUrl: './potential-breach-code-table.component.html',
  styleUrls: ['./potential-breach-code-table.component.scss']
})
export class PotentialBreachCodeTableComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() codeOfPracticeBreaches: PotentialBreachOfCode[];
  @Input() timezone: string;
  @ViewChild('potentialBreachOfCodeSort', { static: true }) potentialBreachOfCodeSort: MatSort;
  readonly partOfCodeEnum = PartOfCodeEnum;
  columnHeader = ['dateOfIncident', 'dateDiscovered', 'raisedBy', 'partOfCode', 'codeOfPracticeParagraph'];
  dataSource: MatTableDataSource<PotentialBreachOfCode>;
  activeColumn = 'dateOfIncident';

  constructor() {}

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<any>(this.codeOfPracticeBreaches);
    this.dataSource.sortingDataAccessor = this.customSortingAccessor.bind(this);
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.potentialBreachOfCodeSort;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.codeOfPracticeBreaches && !changes.codeOfPracticeBreaches.firstChange) {
      this.dataSource = new MatTableDataSource<any>(this.codeOfPracticeBreaches);
      this.dataSource.sortingDataAccessor = this.customSortingAccessor.bind(this);
      this.dataSource.sort = this.potentialBreachOfCodeSort;
    }
  }

  customSortingAccessor(item: any, property: string) {
    if (property === 'codeOfPracticeParagraph' || property === 'raisedBy') {
      return item[property].toLowerCase();
    } else if (property === 'dateOfIncident' || property === 'dateDiscovered') {
      return moment(item[property]).tz(this.timezone).format('DD/MM/yyyy');
    }
    return item[property];
  }
}
