import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { CustomerVulnerability } from '../../../../model/gicop-notifications';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import * as moment from 'moment';

@Component({
  selector: 'app-customer-vulnerability-table',
  templateUrl: './customer-vulnerability-table.component.html',
  styleUrls: ['./customer-vulnerability-table.component.scss']
})
export class CustomerVulnerabilityTableComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() customerVulnerabilities: CustomerVulnerability[];
  @Input() timezone: string;
  @ViewChild('customerVulnerabilitySort', { static: true }) customerVulnerabilitySort: MatSort;
  columnHeader = ['createdTime', 'customerVulnerability', 'contactPerson', 'phoneNumber', 'email'];
  dataSource: MatTableDataSource<CustomerVulnerability>;
  activeColumn = 'createdTime';

  constructor() {}

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<any>(this.customerVulnerabilities);
    this.dataSource.sortingDataAccessor = this.customSortingAccessor.bind(this);
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.customerVulnerabilitySort;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.customerVulnerabilities && !changes.customerVulnerabilities.firstChange) {
      this.dataSource = new MatTableDataSource<any>(this.customerVulnerabilities);
      this.dataSource.sortingDataAccessor = this.customSortingAccessor.bind(this);
      this.dataSource.sort = this.customerVulnerabilitySort;
    }
  }

  customSortingAccessor(item: any, property: string) {
    if (property === 'createdTime') {
      return moment(item[property]).tz(this.timezone).format('DD/MM/yyyy');
    }
    return item[property].toLowerCase();
  }
}
