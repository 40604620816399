import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PartOfCodeEnum, PotentialBreachOfCode } from '../../../../model/gicop-notifications';
import { DateInputHelper } from '../../../../helpers/date-input.helper';
import { ConfirmBoxComponent } from '../../../../components/confirm-box/confirm-box.component';
import { UserService } from '../../../../services/user.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ToolbarService } from '../../../../services/toolbar.service';
import { MessagingService } from '../../../../components/messaging/messaging.service';
import * as moment from 'moment';
import { EIO_DATE_BE_FORMAT } from '../../../../consts/localization.const';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { smallModalConfig } from '../../../../consts/modal-config.const';
import { ModalConfigsHelper } from '../../../../helpers/modal-configs.helper';

@Component({
  selector: 'app-add-potential-breach-code',
  templateUrl: './add-potential-breach-code.component.html',
  styleUrls: ['./add-potential-breach-code.component.scss']
})
export class AddPotentialBreachCodeComponent implements OnInit, AfterViewInit {
  @Input() potentialBreachOfCode: PotentialBreachOfCode;
  @Output() refreshJobViewer: EventEmitter<any> = new EventEmitter();
  @Output() disableSendButton: EventEmitter<any> = new EventEmitter();
  readonly partOfCodeEnum = PartOfCodeEnum;
  potentialBreachOfCodeForm: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    public dateInputHelper: DateInputHelper,
    private userService: UserService,
    private confirmBox: MatDialog,
    public toolbarService: ToolbarService,
    private message: MessagingService,
    private elRef: ElementRef,
    private modalConfigsHelper: ModalConfigsHelper
  ) {}

  get raisedBy() {
    return this.potentialBreachOfCodeForm.get('raisedBy');
  }
  get dateOfIncident() {
    return this.potentialBreachOfCodeForm.get('dateOfIncident');
  }
  get dateDiscovered() {
    return this.potentialBreachOfCodeForm.get('dateDiscovered');
  }
  get partOfCode() {
    return this.potentialBreachOfCodeForm.get('partOfCode');
  }
  get codeOfPracticeParagraph() {
    return this.potentialBreachOfCodeForm.get('codeOfPracticeParagraph');
  }
  get description() {
    return this.potentialBreachOfCodeForm.get('description');
  }

  ngOnInit(): void {
    this.potentialBreachOfCodeForm = this.fb.group({
      id: this.potentialBreachOfCode.id,
      raisedBy: [this.potentialBreachOfCode.raisedBy || '', [Validators.required]],
      dateOfIncident: [this.potentialBreachOfCode.dateOfIncident || '', [Validators.required]],
      dateDiscovered: [this.potentialBreachOfCode.dateDiscovered || '', [Validators.required]],
      partOfCode: [this.potentialBreachOfCode.partOfCode || '', [Validators.required]],
      codeOfPracticeParagraph: [this.potentialBreachOfCode.codeOfPracticeParagraph || '', [Validators.required]],
      description: [this.potentialBreachOfCode.description || '', [Validators.required]]
    });
    this.updatePotentialBreachOfCodeForm();
  }

  updatePotentialBreachOfCodeForm() {
    this.raisedBy.valueChanges.pipe(debounceTime(750), distinctUntilChanged()).subscribe(() => {
      this.disableSendButton.emit();
      this.updatePotentialBreachOfCode();
    });

    this.dateOfIncident.valueChanges.pipe(debounceTime(750), distinctUntilChanged()).subscribe({
      next: (value) => {
        this.potentialBreachOfCode.dateOfIncident = value;
        this.dateOfIncident.invalid ? this.dateOfIncident.markAsTouched() : this.updatePotentialBreachOfCode();
        this.disableSendButton.emit(this.dateOfIncident.invalid);
      }
    });

    this.dateDiscovered.valueChanges.pipe(debounceTime(750), distinctUntilChanged()).subscribe({
      next: (value) => {
        this.potentialBreachOfCode.dateDiscovered = value;
        this.dateDiscovered.invalid ? this.dateDiscovered.markAsTouched() : this.updatePotentialBreachOfCode();
        this.disableSendButton.emit(this.dateDiscovered.invalid);
      }
    });

    this.partOfCode.valueChanges.subscribe(() => {
      this.disableSendButton.emit();
      this.updatePotentialBreachOfCode();
    });

    this.codeOfPracticeParagraph.valueChanges.pipe(debounceTime(750), distinctUntilChanged()).subscribe(() => {
      this.disableSendButton.emit();
      this.updatePotentialBreachOfCode();
    });

    this.description.valueChanges.pipe(debounceTime(750), distinctUntilChanged()).subscribe(() => {
      this.disableSendButton.emit();
      this.updatePotentialBreachOfCode();
    });
  }

  confirmDeletePotentialBreachOfCode() {
    const data = {
      title: 'Delete Potential Breach of Code',
      alertMessage: 'This potential breach of code will be deleted.',
      confirmButton: 'DELETE'
    };
    const dialogRef = this.confirmBox.open(
      ConfirmBoxComponent,
      this.modalConfigsHelper.buildMediumModalConfig(data, smallModalConfig)
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'DELETE') {
        this.deletePotentialBreachOfCode();
      }
    });
  }

  deletePotentialBreachOfCode() {
    this.toolbarService.setCloudState('SAVING');
    this.userService
      .deleteNotification(this.potentialBreachOfCode.jobId, 'codeOfPracticeBreaches', this.potentialBreachOfCode.id)
      .subscribe(
        () => this.toolbarService.setCloudState('RESTING'),
        () => {
          this.message.error('Potential breach of code could not be deleted.');
          this.toolbarService.setCloudState('RESTING');
        },
        () => {
          this.message.confirm('Potential Breach of Code Deleted.');
          this.refreshJobViewer.emit();
        }
      );
  }

  updatePotentialBreachOfCode() {
    this.toolbarService.setCloudState('SAVING');
    const potentialBreachOfCode: PotentialBreachOfCode = this.potentialBreachOfCodeForm.getRawValue();

    potentialBreachOfCode.dateOfIncident = this.dateOfIncident.value
      ? moment(this.dateOfIncident.value).format(EIO_DATE_BE_FORMAT)
      : null;
    potentialBreachOfCode.dateDiscovered = this.dateDiscovered.value
      ? moment(this.dateDiscovered.value).format(EIO_DATE_BE_FORMAT)
      : null;

    this.userService
      .updateNotification(this.potentialBreachOfCode.jobId, 'codeOfPracticeBreaches', potentialBreachOfCode)
      .subscribe(
        () => this.toolbarService.setCloudState('RESTING'),
        () => {
          this.message.error('Potential breach of code has not been saved');
          this.toolbarService.setCloudState('RESTING');
        },
        () => {
          this.refreshJobViewer.emit();
        }
      );
  }

  ngAfterViewInit() {
    this.elRef.nativeElement.scrollIntoView({
      behavior: 'smooth'
    });
  }
}
