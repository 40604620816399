import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { JobHistory } from '../../../model/jobHistory.model';
import { JobService } from '../../../services/job.service';

@Component({
  selector: 'app-job-history-tab',
  templateUrl: './job-history-tab.component.html',
  styleUrls: ['./job-history-tab.component.scss']
})
export class JobHistoryTabComponent {
  @Input() jobHistory: JobHistory[] = [];
  @Input() timezone: string;
  @Input() canLoadMore: boolean;
  @Output() loadMoreItems: EventEmitter<void> = new EventEmitter();
  jobHistoryColumns = ['timeStamp', 'rego', 'owner', 'action'];

  private router = inject(Router);
  private jobService = inject(JobService);

  redirectToJob(id: number) {
    this.jobService.activeJobId = id;
    this.router.navigate([`/jobViewer/${id}`]).catch();
  }
}
