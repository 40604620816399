import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-confirm-share-screen',
  templateUrl: './confirm-share-screen.component.html',
  styleUrls: ['./confirm-share-screen.component.scss']
})
export class ConfirmShareScreenComponent implements OnInit {
  sendingInProgress = false;
  constructor(
    public dialogRef: MatDialogRef<ConfirmShareScreenComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {}

  acceptSharingScreen() {
    this.sendingInProgress = true;

    setTimeout(() => {
      this.sendingInProgress = false;
      this.dialogRef.close('ACCEPT');
    }, 1000);
  }

  cancelSharingScreen() {
    this.dialogRef.close('CANCEL');
  }
}
