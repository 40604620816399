import {trigger} from '@angular/animations';
import {NgForOf, NgIf} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  EventEmitter,
  Input,
  Output,
  signal,
  Signal,
  WritableSignal
} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {ITEM_ANIMATION, LIST_ANIMATION} from '../../../../consts/animations.const';
import {CobrowsingUser} from '../../../../model/user.model';
import {InitialsPipe} from '../../../../pipes/initials.pipe';

@Component({
  selector: 'cobrowsing-user',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [InitialsPipe, MatIconModule, NgForOf, NgIf],
  animations: [trigger('listAnimation', LIST_ANIMATION), trigger('itemAnimation', ITEM_ANIMATION)],
  template: `
    <div class="flex justify-center items-center px-10 py-20">
      <mat-icon class="cursor-pointer" *ngIf="cobrowsingUserList().length > 4" (click)="previous()">
        arrow_back_ios
      </mat-icon>
      <div class="flex items-center" [@listAnimation]="currentIndex()">
        <ng-container *ngFor="let cobrowsingUser of displayedCobrowsingUsers(); let i = index">
          <div class="w-[150px] flex flex-col justify-center items-center px-10" [@itemAnimation]="currentIndex()">
            <div
              (click)="sendRequestShare.emit(cobrowsingUser.username)"
              class="rounded-full bg-pacific-blue w-100 h-100 flex items-center justify-center text-white
              text-[42px] cursor-pointer border-[6px] border-pacific-blue border-solid hover:border-mercury hover:shadow-lg">
              {{ cobrowsingUser.fullName | initials }}
            </div>
            <div class="body-2">
              <div>{{ cobrowsingUser.fullName }}</div>
              <div>{{ cobrowsingUser.email }}</div>
            </div>
          </div>
        </ng-container>
      </div>
      <mat-icon class="cursor-pointer" *ngIf="cobrowsingUserList().length > 4" (click)="next()">
        arrow_forward_ios
      </mat-icon>
    </div>
  `
})
export class CobrowsingUserComponent {
  cobrowsingUserList: WritableSignal<CobrowsingUser[]> = signal([]);

  @Input() set cobrowsingUsers(value: CobrowsingUser[]) {
    this.cobrowsingUserList.set(value);
  }

  @Output() sendRequestShare: EventEmitter<string> = new EventEmitter();
  currentIndex: WritableSignal<number> = signal(0);
  displayedCobrowsingUsers: Signal<CobrowsingUser[]> = computed(() =>
    this.cobrowsingUserList().slice(this.currentIndex(), this.currentIndex() + 4)
  );

  previous() {
    this.currentIndex() - 1 >= 0 && this.currentIndex.set(this.currentIndex() - 1);
  }

  next() {
    this.currentIndex() + 4 < this.cobrowsingUserList().length && this.currentIndex.set(this.currentIndex() + 1);
  }
}
