import { AbstractControl, ValidationErrors } from '@angular/forms';

export function abnValidator(control: AbstractControl): ValidationErrors | null {
  const abn: string | null = control.value ? control.value.replace(/\s+/g, '') : null;

  if (!abn || isNaN(Number(abn)) || abn.length !== 11 || calculateSum(abn) % 89 !== 0) {
    return { invalidABN: true };
  }

  return null;
}

function calculateSum(abn: string): number {
  const weights: number[] = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];
  return abn.split('').reduce((total: number, digit: any, index: number) => {
    !index && digit--;
    return total + digit * weights[index];
  }, 0);
}
