<div class="reply-card">
  <mat-card class="curtains">
    <div class="reply-header secondary-icon">
      <h5>Return to Assessor</h5>
      <div class="reply-kebab-panel">
        <button mat-icon-button (click)="confirmDeleteReplyCard()">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
    <mat-card-content>
      <form [formGroup]="replyForm">
        <div class="card-body">
          <mat-form-field appearance="outline">
            <mat-label>Assessment Action</mat-label>
            <mat-select
              (selectionChange)="updateJob('noQuoteResponseAssessmentAction')"
              formControlName="noQuoteResponseAssessmentAction"
              required>
              <mat-option *ngIf="!hasQuotes && !assessorAllocated" value="On Site Assessment required"
                >On Site Assessment required</mat-option
              >
              <mat-option *ngIf="displayRFA" value="Request for Assessment">Request for Assessment</mat-option>
              <mat-option *ngIf="displaySupplementRFA" value="Request for Supplement Assessment"
                >Request for Supplement Assessment</mat-option
              >
              <mat-option value="Returned Un-Actioned">Returned Un-Actioned</mat-option>
              <mat-option value="Total Loss">Total Loss</mat-option>
            </mat-select>
            <mat-error>Assessment Action is <strong>required</strong></mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" class="diary-note">
            <mat-label>Note</mat-label>
            <textarea
              matInput
              formControlName="noQuoteResponseNote"
              #note
              cdkTextareaAutosize="true"
              cdkAutosizeMinRows="1"></textarea>
          </mat-form-field>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
