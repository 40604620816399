export const QUOTING_SYSTEMS = [
  '1Q1',
  'Action Smart',
  'Auto-Quote',
  'Capital Smart',
  'Car Quote',
  'Crashzone',
  'EQ',
  'FlexiQuote',
  'Foxquote',
  'iBodyshop',
  'Naevette',
  "Panel N' Paint",
  'PartsCheck',
  'PPG Access',
  'QCQ',
  'Quotimation',
  'Repair.',
  'Smash Quote',
  'UNIQ',
  'VQS'
];
