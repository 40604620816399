import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UpdatePhotoFromBlobHelper {
  b64toBlob(dataURI: string, type: string): Blob {
    const byteString = atob(dataURI.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type });
  }
}
