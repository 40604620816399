import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { MessagingService } from '../../../components/messaging/messaging.service';
import {mediumModalConfig} from '../../../consts/modal-config.const';
import { EscapeDialogHelper } from '../../../helpers/escape-dialog.helper';
import { ModalConfigsHelper } from '../../../helpers/modal-configs.helper';
import {RepairerAdminService} from '../../../services/repairer-admin.service';
import {ToolbarService} from '../../../services/toolbar.service';
import {PasswordConfirmationComponent} from '../password-confirmation/password-confirmation.component';

@Component({
  selector: 'app-edit-banking-details',
  templateUrl: './edit-banking-details.component.html',
  styleUrls: ['./edit-banking-details.component.scss']
})
export class EditBankingDetailsComponent implements OnInit {
  editBankingInfoForm: UntypedFormGroup;
  pending = false;

  constructor(
    public dialogRef: MatDialogRef<EditBankingDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder,
    private message: MessagingService,
    public toolbarService: ToolbarService,
    private confirmPasswordBox: MatDialog,
    private escapeDialogRef: EscapeDialogHelper,
    private repAdminService: RepairerAdminService,
    private modalConfigsHelper: ModalConfigsHelper
  ) {}

  get bsb() {
    return this.editBankingInfoForm.get('bsb');
  }

  get accountName() {
    return this.editBankingInfoForm.get('accountName');
  }

  get accountNumber() {
    return this.editBankingInfoForm.get('accountNumber');
  }

  ngOnInit(): void {
    this.editBankingInfoForm = this.fb.group({
      bsb: [this.data.bsb ? this.data.bsb : '', [Validators.required]],
      accountName: [this.data.accountName ? this.data.accountName : '', [Validators.required]],
      accountNumber: [this.data.accountNumber ? this.data.accountNumber : '', [Validators.required]]
    });

    this.bsb.valueChanges.subscribe(() => {
      if (this.bsb.invalid) {
        this.bsb.markAsTouched();
      }
    });

    this.accountName.valueChanges.subscribe(() => {
      if (this.accountName.invalid) {
        this.accountName.markAsTouched();
      }
    });

    this.accountNumber.valueChanges.subscribe(() => {
      if (this.accountNumber.invalid) {
        this.accountNumber.markAsTouched();
      }
    });

    this.escapeDialogRef.escapeDialog(this.dialogRef);
  }

  confirmPassword() {
    this.bsb.setValue(this.bsb.value.trim());
    this.accountName.setValue(this.accountName.value.trim());
    this.accountNumber.setValue(this.accountNumber.value.trim());
    if (this.bsb.valid && this.accountName.valid && this.accountNumber.valid) {
      this.pending = true;
      const data = {username: this.data.username, biggerButtons: true};
      const dialogRef = this.confirmPasswordBox.open(
        PasswordConfirmationComponent,
        this.modalConfigsHelper.buildMediumModalConfig(data, mediumModalConfig)
      );
      dialogRef.afterClosed().subscribe((response) => {
        if (response.result === 'Confirmed') {
          this.saveBankingInfo();
        } else {
          this.pending = false;
        }
      });
    }
  }

  saveBankingInfo() {
    const bankingDetails = {
      bsb: this.bsb.value,
      accountName: this.accountName.value,
      accountNumber: this.accountNumber.value
    };

    this.toolbarService.setCloudState('SAVING');

    this.repAdminService.updateBankingDetails(bankingDetails).subscribe(
      () => this.dialogRef.close('Success'),
      () => {
        this.message.error('Could not edit banking details.');
        this.toolbarService.setCloudState('RESTING');
        this.pending = false;
      },
      () => {
        this.toolbarService.setCloudState('RESTING');
        this.pending = false;
      }
    );
  }
}
