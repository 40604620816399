import { booleanAttribute, Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'infos-content',
  standalone: true,
  imports: [NgClass, MatTooltipModule],
  template: `
    <div class="flex flex-col">
      <span class="text-boulder text-[12px]">{{ field }}</span>
      <span
        #el
        class="bold text-[14px]"
        [ngClass]="overflowTooltip ? 'truncate' : 'break-words'"
        [matTooltip]="value"
        [matTooltipDisabled]="!overflowTooltip || el.scrollWidth <= el.clientWidth">
        {{ value }}
      </span>
    </div>
  `
})
export class InfosContentComponent {
  @Input({ transform: booleanAttribute }) overflowTooltip = false;
  @Input() field: string;
  @Input() value: string | number | boolean;
}
