<div class="dialog-header" mat-dialog-title>
  <h6>{{ data?.quotingSystem?.clientId ? 'Edit' : 'Add' }} Quoting System</h6>
  <button mat-icon-button aria-label="Close" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-divider></mat-divider>
<div class="title-1">Company Information</div>
<div mat-dialog-content class="add-quoting-system-container">
  <form [formGroup]="quotingSystemForm">
    <div class="fields-line">
      <mat-form-field appearance="outline" color="accent">
        <mat-label>Company</mat-label>
        <input matInput formControlName="companyName" required />
        <mat-error *ngIf="quotingSystemForm.controls['companyName'].hasError('required')">
          Company Name is <strong>required</strong>
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" color="accent">
        <mat-label>Contact</mat-label>
        <input matInput formControlName="contactPhoneNumber" />
      </mat-form-field>
    </div>
  </form>
</div>

<mat-dialog-actions>
  <button mat-raised-button mat-dialog-close color="accent">CANCEL</button>
  <button
    [disabled]="quotingSystemForm.invalid || quotingSystemForm.pristine || pending"
    (click)="data?.quotingSystem?.clientId ? editQuotingSystem() : addQuotingSystem()"
    mat-raised-button
    color="primary">
    SAVE
  </button>
</mat-dialog-actions>
