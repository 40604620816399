import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import {MatLegacyCardModule as MatCardModule} from '@angular/material/legacy-card';

@Component({
  selector: 'infos-card',
  standalone: true,
  imports: [MatIconModule, NgTemplateOutlet, MatCardModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <mat-card>
      <mat-card-header class="flex justify-between items-center">
        <mat-card-title class="subtitle-1 bold -ml-15">{{ title }}</mat-card-title>
        <ng-content select="[card-header-content]"/>
      </mat-card-header>
      <mat-card-content>
        <ng-content select="[card-body-content]"/>
      </mat-card-content>
    </mat-card>
  `
})
export class InfosCardComponent {
  @Input() title: string;
}
