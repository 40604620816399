<button class="white-button" [matMenuTriggerFor]="actionsMenu" mat-button>
  <span class="action-menu-button">
    <mat-icon>task_rounded</mat-icon>
    <span class="subtitle-1">TASKS</span>
  </span>
</button>
<mat-menu #actionsMenu="matMenu" class="new-actions-kebab-menu">
  <div class="menu-grouping">
    <div
      class="menu-grouping-item"
      [matTooltipDisabled]="
        !(
          unSubmittedQuote ||
          noQuoteResponse ||
          !job?.canRepairerUploadEstimatesAndInvoices ||
          invoiceCreated ||
          invoicePendingReview ||
          jobStates?.isJobWithSelfAuthDateAndNoSelfAuthSent ||
          (job?.enableSelfAuthorityCurtain && job?.enableToSelfAuthorized)
        )
      "
      [matTooltip]="
        unSubmittedQuote
          ? 'You may only send one quote at a time'
          : job?.enableSelfAuthorityCurtain
          ? 'You must send the self-authorise before adding a quote'
          : noQuoteResponse
          ? 'You have already selected to return without a quote'
          : ''
      "
      [matTooltipPosition]="'right'">
      <button
        [matMenuTriggerFor]="quoteMenu"
        [disabled]="
          unSubmittedQuote ||
          noQuoteResponse ||
          !job?.canRepairerUploadEstimatesAndInvoices ||
          invoiceCreated ||
          invoicePendingReview ||
          jobStates?.isJobWithSelfAuthDateAndNoSelfAuthSent ||
          (job?.enableSelfAuthorityCurtain && job?.enableToSelfAuthorized)
        "
        class="white-button"
        mat-menu-item>
        <span>{{ supplementQuote ? ' Supplement' : '' }} Quote</span>
      </button>
      <mat-icon
        [class.disabled]="
          unSubmittedQuote ||
          noQuoteResponse ||
          !job?.canRepairerUploadEstimatesAndInvoices ||
          invoiceCreated ||
          invoicePendingReview ||
          jobStates?.isJobWithSelfAuthDateAndNoSelfAuthSent ||
          (job?.enableSelfAuthorityCurtain && job?.enableToSelfAuthorized)
        "
        >add</mat-icon
      >
    </div>
    <div
      class="menu-grouping-item"
      *ngIf="jobStates?.isJobWithAtLeastOneAuthorisedQuote && !jobStates?.hasOrginalQuoteNotSubmittedAndAuthorised"
      [matTooltip]="invoiceButtonMsg"
      [matTooltipDisabled]="!invoiceButtonMsg"
      [matTooltipPosition]="'right'">
      <button
        [matMenuTriggerFor]="invoiceMenu"
        *ngIf="jobStates?.isJobWithAtLeastOneAuthorisedQuote && !jobStates?.hasOrginalQuoteNotSubmittedAndAuthorised"
        [disabled]="
          !(
            jobStates?.isLatestQuoteAuthorised &&
            jobStates?.isLatestQuoteNotInvoiced &&
            jobStates?.isQuoteInSendToTrayEmpty &&
            displayAddInvoiceButton &&
            jobStates.isInvoiceInSendToTrayEmpty
          )
        "
        class="white-button"
        mat-menu-item>
        <span>Invoice</span>
      </button>
      <mat-icon
        [class.disabled]="
          !(
            jobStates?.isLatestQuoteAuthorised &&
            jobStates?.isLatestQuoteNotInvoiced &&
            jobStates?.isQuoteInSendToTrayEmpty &&
            displayAddInvoiceButton &&
            jobStates.isInvoiceInSendToTrayEmpty
          )
        "
        >add</mat-icon
      >
    </div>

    <div
      *ngIf="job?.enableToSelfAuthorized || job?.selfAuthorized"
      [matTooltipDisabled]="!(showSelfAuthorisationCurtain || !job?.enableToSelfAuthorized)"
      [matTooltip]="
        showSelfAuthorisationCurtain
          ? 'You may only send one self-authority at a time'
          : !job?.enableToSelfAuthorized
          ? 'Authority has already been issued'
          : ''
      "
      [matTooltipPosition]="'right'">
      <button
        (click)="taskButtonClicked.emit({ action: 'addSelfAuthoriseAction' })"
        [disabled]="showSelfAuthorisationCurtain || !job?.enableToSelfAuthorized"
        class="white-button"
        mat-menu-item>
        <span> Self Authorize </span>
      </button>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="menu-grouping">
    <div
      [matTooltipDisabled]="!unSubmittedQuote && !noQuoteResponse"
      [matTooltip]="
        unSubmittedQuote
          ? 'You have already selected to return with a quote'
          : noQuoteResponse
          ? 'You may only send one return to assessor at a time'
          : ''
      "
      [matTooltipPosition]="'right'">
      <button
        (click)="taskButtonClicked.emit({ action: 'addNoQuoteResponse', param: 'Total Loss' })"
        [disabled]="
          !(
            !unSubmittedQuote &&
            !noQuoteResponse &&
            !invoiceCreated &&
            (job?.status === 'MORE_INFO_REQUIRED' ||
              job?.status === 'MORE_INFO_SUPPLEMENT' ||
              job?.status === 'REQUEST_FOR_QUOTE' ||
              job?.status === 'PRE_AUTHORIZED' ||
              job?.status === 'AUTHORIZED' ||
              job?.status === 'INVOICE_APPROVED' ||
              job?.status === 'INVOICE_REJECTED')
          )
        "
        class="white-button"
        mat-menu-item>
        <span>Return As Total Loss</span>
      </button>
      <button
        (click)="taskButtonClicked.emit({ action: 'addNoQuoteResponse', param: 'Returned Un-Actioned' })"
        [disabled]="
          !(
            !unSubmittedQuote &&
            !noQuoteResponse &&
            !invoiceCreated &&
            (job?.status === 'MORE_INFO_REQUIRED' ||
              job?.status === 'MORE_INFO_SUPPLEMENT' ||
              job?.status === 'REQUEST_FOR_QUOTE' ||
              job?.status === 'PRE_AUTHORIZED' ||
              job?.status === 'AUTHORIZED' ||
              job?.status === 'INVOICE_APPROVED' ||
              job?.status === 'INVOICE_REJECTED')
          )
        "
        class="white-button"
        mat-menu-item>
        <span>Return Un-Actioned</span>
      </button>
      <button
        (click)="taskButtonClicked.emit({ action: 'addNoQuoteResponse' })"
        [disabled]="
          !(
            !unSubmittedQuote &&
            !noQuoteResponse &&
            !invoiceCreated &&
            (job?.status === 'MORE_INFO_REQUIRED' ||
              job?.status === 'MORE_INFO_SUPPLEMENT' ||
              job?.status === 'REQUEST_FOR_QUOTE' ||
              job?.status === 'PRE_AUTHORIZED' ||
              job?.status === 'AUTHORIZED' ||
              job?.status === 'INVOICE_APPROVED' ||
              job?.status === 'INVOICE_REJECTED')
          )
        "
        class="white-button"
        mat-menu-item>
        <span>Return To Assessor</span>
      </button>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="menu-grouping">
    <div
      [matTooltipDisabled]="!unSubmittedDiary"
      [matTooltip]="'You may only send one diary at a time'"
      [matTooltipPosition]="'right'">
      <button
        (click)="taskButtonClicked.emit({ action: 'addDiaryCard' })"
        [disabled]="unSubmittedDiary"
        class="white-button"
        mat-menu-item>
        <span>Diary</span>
      </button>
    </div>
    <div
      *ngIf="job?.enableBooking"
      [matTooltipDisabled]="!showBooking"
      [matTooltip]="'You may only send one inspection booking at a time'"
      [matTooltipPosition]="'right'">
      <button
        (click)="taskButtonClicked.emit({ action: 'addBooking' })"
        [disabled]="showBooking"
        class="white-button"
        mat-menu-item>
        <span>Inspection Booking</span>
      </button>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="menu-grouping">
    <div
      [matTooltipDisabled]="!job?.gicopUI?.unsubmittedGicopNotification"
      [matTooltip]="'You may only send one notification at a time'"
      [matTooltipPosition]="'right'">
      <button
        type="button"
        mat-menu-item
        *ngIf="ownerName"
        [disabled]="job?.gicopUI?.unsubmittedGicopNotification"
        (click)="taskButtonClicked.emit({ action: 'addNotification', param: 'customerContacts' })"
        class="custom-menu-item">
        Customer Contact
      </button>
      <button
        type="button"
        mat-menu-item
        [disabled]="job?.gicopUI?.unsubmittedGicopNotification"
        (click)="taskButtonClicked.emit({ action: 'addNotification', param: 'customerFeedback' })"
        class="custom-menu-item">
        Customer Feedback
      </button>
      <button
        type="button"
        mat-menu-item
        [disabled]="job?.gicopUI?.unsubmittedGicopNotification"
        (click)="taskButtonClicked.emit({ action: 'addNotification', param: 'codeOfPracticeBreaches' })"
        class="custom-menu-item">
        Potential Breach of Code
      </button>
      <button
        type="button"
        mat-menu-item
        [disabled]="job?.gicopUI?.unsubmittedGicopNotification"
        (click)="taskButtonClicked.emit({ action: 'addNotification', param: 'customerVulnerabilities' })"
        class="custom-menu-item">
        Vulnerable Customer
      </button>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="menu-grouping">
    <button (click)="imageInput.click()" class="white-button" mat-menu-item>Photos</button>
    <input
      #imageInput
      [value]="inputFileValue"
      (click)="imageInput.value = null"
      (change)="taskButtonClicked.emit({ action: 'addFiles', param: { imageInput, isPhotos: true } })"
      [hidden]="true"
      accept="image/jpeg, image/png"
      multiple="multiple"
      type="file" />
    <button (click)="documentInput.click()" class="white-button" *ngIf="documentFeatureEnabled" mat-menu-item>
      Documents
    </button>
    <input
      #documentInput
      [value]="inputFileValue"
      (click)="documentInput.value = null"
      (change)="taskButtonClicked.emit({ action: 'addFiles', param: { documentInput, isPhotos: false } })"
      [hidden]="true"
      accept=".pdf, .xlsx, .txt, .docx, .png, .jpeg, .jpg"
      multiple="multiple"
      type="file" />
  </div>
  <mat-divider></mat-divider>
  <div class="menu-grouping">
    <div
      [matTooltipDisabled]="!job?.unread"
      [matTooltip]="'This job is already marked as unread'"
      [matTooltipPosition]="'right'">
      <button
        mat-menu-item
        class="white-button"
        [disabled]="job?.unread"
        (click)="taskButtonClicked.emit({ action: 'markAsUnread' })">
        Mark as unread
      </button>
    </div>
  </div>
</mat-menu>

<mat-menu #quoteMenu="matMenu" backdropClass="notification-menu" class="new-actions-kebab-menu">
  <div class="menu-grouping">
    <button
      type="button"
      mat-menu-item
      class="custom-menu-item"
      (click)="taskButtonClicked.emit({ action: 'addQuote', param: 0 })">
      Import Quote
    </button>
    <button
      type="button"
      mat-menu-item
      class="custom-menu-item"
      (click)="taskButtonClicked.emit({ action: 'addQuote', param: 1 })">
      Manual Quote
    </button>
  </div>
</mat-menu>
<mat-menu #invoiceMenu="matMenu" backdropClass="notification-menu" class="new-actions-kebab-menu">
  <div class="menu-grouping">
    <button
      type="button"
      mat-menu-item
      class="custom-menu-item"
      (click)="taskButtonClicked.emit({ action: 'appendInvoice', param: 0 })">
      Import Invoice
    </button>
    <button
      type="button"
      mat-menu-item
      class="custom-menu-item"
      (click)="taskButtonClicked.emit({ action: 'appendInvoice', param: 1 })">
      Invoice From Quotes
    </button>
  </div>
</mat-menu>
