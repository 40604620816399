import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CloudState } from '../../dashboard/cloudState.model';
import { CleanActiveJobHelper } from '../../helpers/clean-active-job.helper';

@Component({
  selector: 'app-refresh',
  template: `
    <span class="refresh-container" *ngIf="!refreshStatus" (mouseover)="refreshStatus = true">
      <span *ngIf="offline" class="updating"><mat-icon>cloud_off</mat-icon>Cannot reach server</span>
      <span *ngIf="!offline" [ngSwitch]="cloudState">
        <span *ngSwitchCase="'FETCHING'" class="updating"
          ><mat-icon>cloud_download</mat-icon> Retrieving from cloud<span>.</span><span>.</span><span>.</span></span
        >
        <span *ngSwitchCase="'SAVING'" class="updating"
          ><mat-icon>cloud_upload</mat-icon> Uploading your work<span>.</span><span>.</span><span>.</span></span
        >
        <span *ngSwitchDefault class="cloud-done"><mat-icon>cloud_done</mat-icon> {{ updatingTimerInfo }}</span>
      </span>
    </span>
    <span
      class="update-display body-1"
      *ngIf="refreshStatus"
      (mouseout)="refreshStatus = false"
      [class.loading]="loading"
      (click)="refresh.emit(); cleanActiveJobHelper.clearLocalStorageFromActiveJob()">
      <mat-icon id="refresh-icon">refresh</mat-icon>
      {{ loading ? 'Updating...' : 'Refresh' }}
    </span>
  `,
  styleUrls: ['./refresh.component.scss']
})
export class RefreshComponent implements OnChanges {
  @Input() loading: boolean;
  @Input() offline: boolean;
  @Input() cloudState: CloudState;

  @Output() refresh: EventEmitter<ElementRef> = new EventEmitter();

  interval;
  updatingTimerInfo = '';
  refreshStatus = false;

  constructor(public cleanActiveJobHelper: CleanActiveJobHelper) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.cloudState && changes.cloudState.currentValue && changes.cloudState.currentValue === 'RESTING') {
      this.stopTimer();
      this.refreshTimer();
    }
  }

  refreshTimer() {
    let timeElapsed = 0;
    this.updatingTimerInfo = 'Updated just now';

    this.interval = setInterval(() => {
      timeElapsed++;
      if (timeElapsed > 55) {
        this.updatingTimerInfo = 'Updated over an hour ago';
        this.stopTimer();
      } else if (timeElapsed === 1) {
        this.updatingTimerInfo = 'Updated ' + timeElapsed + ' minute ago';
      } else if (timeElapsed % 5 === 0) {
        this.updatingTimerInfo = 'Updated ' + timeElapsed + ' minutes ago';
      }
    }, 60000);
  }

  stopTimer() {
    clearInterval(this.interval);
  }
}
