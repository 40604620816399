<div class="body-1 my-15">
  <div>Request payment of invoice <span class="bold">{{data.estImagePayRequest?.invoiceNumber}}</span> through EstImage Pay with the following details</div>
  <div class="py-10">
    <div class="body-2 pb-5">Amount requested</div>
    <div class="bold subtitle-1"><span class="mr-10">$</span>{{data.estImagePayRequest?.amountRequested | number: '1.2-2'}}</div>
  </div>
  <div class="mb-10">Disbursement account</div>
  <div [ngClass]="{'h-[200px] overflow-auto custom-thin-scrollbar': dataSource.data?.length > 3}">
    <table [dataSource]="dataSource"
           *ngIf="dataSource.data.length; else noDisbursementAccount"
           class="mt-0"
           matSort
           mat-table
           matSortActive="accountName"
           matSortDirection="asc"
           (matSortChange)="activeColumn = $event.active"
           matSortDisableClear>
      <ng-container matColumnDef="selected" [class.hidden]="!accountSelected()">
        <th class="body-2" *matHeaderCellDef mat-header-cell></th>
        <td class="body-1 w-40" *matCellDef="let element" mat-cell>
          <mat-icon *ngIf="element?.accountId === accountSelected()?.accountId" class="text-pacific-blue">check_circle</mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="accountName">
        <th class="body-2 pl-5" *matHeaderCellDef mat-header-cell>Account Name</th>
        <td class="body-1" *matCellDef="let element" mat-cell>
          {{element?.accountName}}
        </td>
      </ng-container>

      <ng-container matColumnDef="accountNumber">
        <th class="body-2 pl-5" *matHeaderCellDef mat-header-cell>Account Number</th>
        <td class="body-1" *matCellDef="let element" mat-cell>
          {{ element?.accountNumber }}
        </td>
      </ng-container>

      <ng-container matColumnDef="bsb">
        <th class="body-2 pl-5" *matHeaderCellDef mat-header-cell>BSB</th>
        <td class="body-1" *matCellDef="let element" mat-cell>
          {{ element?.bsb }}
        </td>
      </ng-container>

      <tr *matHeaderRowDef="columnHeaders" mat-header-row></tr>
      <tr
        [class.selected-row]="row?.accountId === accountSelected()?.accountId"
        *matRowDef="let row; columns: columnHeaders"
        (click)="accountSelected.set(row); acknowledge() && disabled.set(false)"
        mat-row></tr>
    </table>
  </div>
  <div class="bold mt-20 mb-10">Please acknowledge the following before requesting payment:</div>
  <mat-checkbox
  color="primary"
  [checked]="acknowledge()"
  (change)="acknowledge.set($event.checked); disabled.set(!$event.checked)">
    <div class="whitespace-pre-line break-words mt-10">All repairs have been completed in accordance with the Repair Authority issued by the Insurer and in accordance to acceptable industry standards. The Vehicle has or is ready to be released to the Insured vehicle owner.</div>
</mat-checkbox>
</div>

<ng-template #noDisbursementAccount>
  <section class="flex justify-center mb-20">
    <div class="block rounded border border-solid border-primary-500 py-10">
      <infos-guidance [guidanceTemplate]="noResult" warningGuidance />
      <ng-template #noResult>
        <div class="subtitle-2 text-primary-500">Failed to retrieve disbursement accounts. Please try again or contact Support.</div>
      </ng-template>
    </div>
  </section>
</ng-template>
