<div class="notification-section">
  <h4 class="title">Customer Feedback</h4>
  <table
    #customerFeedbackSort="matSort"
    mat-table
    matSort
    [dataSource]="dataSource"
    class="table-style"
    matSortActive="date"
    matSortDirection="desc"
    matSortDisableClear
    (matSortChange)="activeColumn = $event.active">
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef [class.active]="activeColumn === 'date'" mat-sort-header>
        Date of Feedback
      </th>
      <td mat-cell class="body-1" *matCellDef="let row">{{ row.date | date: 'dd/MM/yyyy' }}</td>
    </ng-container>
    <ng-container matColumnDef="feedbackType">
      <th mat-header-cell *matHeaderCellDef [class.active]="activeColumn === 'feedbackType'" mat-sort-header>
        Type of feedback
      </th>
      <td mat-cell class="body-1" *matCellDef="let row">{{ row.feedbackType | titlecase }}</td>
    </ng-container>
    <ng-container matColumnDef="raisedBy">
      <th mat-header-cell *matHeaderCellDef [class.active]="activeColumn === 'raisedBy'" mat-sort-header>Raised By</th>
      <td mat-cell class="body-1" *matCellDef="let row">{{ row.raisedBy }}</td>
    </ng-container>
    <ng-container matColumnDef="details">
      <th mat-header-cell *matHeaderCellDef [class.active]="activeColumn === 'details'" mat-sort-header>Details</th>
      <td mat-cell class="body-1" *matCellDef="let row">
        {{ row.details }}
        <div class="action-to-resolve">Action to Resolve</div>
        <div>{{ row.actionToResolve || 'None' }}</div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columnHeader"></tr>
    <tr mat-row *matRowDef="let row; columns: columnHeader"></tr>
  </table>
</div>
