<app-info-message></app-info-message>
<div class="background--login">
  <section class="form-container">
    <div *ngIf="unauthorized != ''" class="warn-box-authentication subtitle-1">
      <mat-icon matPrefix>error</mat-icon>
      {{ unauthorized }}
    </div>
    <mat-toolbar>
      <mat-toolbar-row>
        <img src="../../assets/images/logo.svg" />
        <h5 class="toolbar-title">Online</h5>
      </mat-toolbar-row>
    </mat-toolbar>
    <div class="content-login">
      <div class="welcome-text subtitle-1">Welcome to EstImage Online</div>
      <form [formGroup]="loginForm" (ngSubmit)="login()">
        <mat-form-field appearance="outline" color="accent" class="input-field-with-icon">
          <mat-label>Username</mat-label>
          <mat-icon matPrefix color="accent">person</mat-icon>
          <mat-divider [vertical]="true"></mat-divider>
          <input autocomplete="username" formControlName="username" matInput required />
          <mat-error *ngIf="loginForm.controls['username'].hasError('required')">
            Username is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="!loginForm.controls['username'].hasError('required')" class="mat-error">
            Please enter a valid username
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" color="accent" class="input-field-with-icon">
          <mat-label>Password</mat-label>
          <mat-icon matPrefix color="accent">lock</mat-icon>
          <mat-divider [vertical]="true"></mat-divider>
          <input
            autocomplete="current-password"
            formControlName="password"
            matInput
            required
            [type]="hidePassword ? 'password' : 'text'" />
          <button (click)="hidePassword = !hidePassword" mat-icon-button matSuffix type="button">
            <mat-icon>{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
          </button>
          <mat-error *ngIf="loginForm.controls['password'].hasError('required')">
            Password is <strong>required</strong>
          </mat-error>
        </mat-form-field>
        <button [disabled]="loginForm.invalid" color="primary" mat-raised-button type="submit" class="buttons body-1">
          LOGIN
        </button>
      </form>
      <button (click)="reset(); which = 'RESET'" mat-button class="buttons body-1">RESET PASSWORD</button>
    </div>
  </section>
</div>
<app-static-footer></app-static-footer>
