import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SysadminUpdate, UserCreate } from '../../../model/user.model';
import { MessagingService } from '../../../components/messaging/messaging.service';
import { ToolbarService } from '../../../services/toolbar.service';
import { EscapeDialogHelper } from '../../../helpers/escape-dialog.helper';
import { SysAdminService } from '../../../services/sys-admin.service';

@Component({
  selector: 'app-edit-sysadmin',
  templateUrl: './edit-sysadmin.component.html',
  styleUrls: ['./edit-sysadmin.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EditSysadminComponent implements OnInit {
  sysadminForm: UntypedFormGroup;
  pending = false;

  constructor(
    public dialogRef: MatDialogRef<EditSysadminComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder,
    public toolbarService: ToolbarService,
    private message: MessagingService,
    private escapeDialogRef: EscapeDialogHelper,
    private sysAdminService: SysAdminService
  ) {}

  get username() {
    return this.sysadminForm.get('username');
  }
  get email() {
    return this.sysadminForm.get('email');
  }
  get firstName() {
    return this.sysadminForm.get('firstName');
  }
  get lastName() {
    return this.sysadminForm.get('lastName');
  }

  ngOnInit() {
    this.sysadminForm = this.fb.group({
      username: [this.data?.username || '', Validators.required],
      firstName: [this.data?.firstName || '', Validators.required],
      lastName: [this.data?.lastName || '', Validators.required],
      email: [
        this.data?.emailAddress || '',
        [
          Validators.required,
          Validators.pattern(
            '^([^@\\s\\."\'\\(\\)\\[\\]\\{\\}\\\\/,:;]+\\.)*[^@\\s\\."\'\\(\\)\\[\\]\\{\\}\\\\/,:;]' +
              '+@[^@\\s\\."\'\\(\\)\\[\\]\\{\\}\\\\/,:;]+(\\.[^@\\s\\."\'\\(\\)\\[\\]\\{\\}\\\\/,:;]+)+$'
          )
        ]
      ]
    });

    if (this.data) {
      this.username.disable();
    }

    this.escapeDialogRef.escapeDialog(this.dialogRef);
  }

  saveUpdateSysadmin() {
    this.toolbarService.setCloudState('SAVING');
    this.pending = true;
    if (!this.data) {
      this.sysAdminService
        .createSysadmin({
          username: this.username.value.trim(),
          firstName: this.firstName.value.trim(),
          lastName: this.lastName.value.trim(),
          email: this.email.value.trim()
        } as UserCreate)
        .subscribe(
          () => this.dialogRef.close('created'),
          (error) => {
            if (error.error === 'DUPLICATE_USERNAME') {
              this.username.setErrors({ usernameDuplicate: true });
            } else {
              this.message.error('Could not create the administrator.');
            }
            this.toolbarService.setCloudState('RESTING');
            this.pending = false;
          },
          () => {
            this.toolbarService.setCloudState('RESTING');
            this.pending = false;
          }
        );
    } else {
      const userUpdate: SysadminUpdate = this.sysadminForm.getRawValue();
      this.sysAdminService.updateAndReinviteSysadmin(this.data?.username, userUpdate).subscribe(
        () => this.dialogRef.close('updated'),
        () => {
          this.message.error('Could not update administrator.');
          this.toolbarService.setCloudState('RESTING');
          this.pending = false;
        },
        () => {
          this.toolbarService.setCloudState('RESTING');
          this.pending = false;
        }
      );
    }
  }
}
