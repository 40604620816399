<div class="header-container">
  <h3>Quoting systems</h3>
  <div class="header-container-actions">
    <button (click)="addEditQuotingSystem()" mat-raised-button color="accent">ADD QUOTING SYSTEM</button>
    <mat-divider class="header-container-actions-divider" [vertical]="true"></mat-divider>
    <button mat-icon-button (click)="quotingSystemExpanded = !quotingSystemExpanded">
      <mat-icon>{{ quotingSystemExpanded ? 'expand_less' : 'expand_more' }}</mat-icon>
    </button>
  </div>
</div>
<mat-divider></mat-divider>
<div [class.hidden]="!quotingSystemExpanded" class="quoting-system-section">
  <table
    #quotingSystemSort="matSort"
    (matSortChange)="quotingSystemActiveColumn = $event.active"
    [dataSource]="quotingSystemListDataSource"
    mat-table
    matSort
    matSortActive="companyName"
    matSortDirection="asc"
    matSortDisableClear>
    <ng-container matColumnDef="companyName">
      <th
        *matHeaderCellDef
        [class.active]="quotingSystemActiveColumn === 'companyName'"
        mat-header-cell
        mat-sort-header>
        Company Name
      </th>
      <td *matCellDef="let qSystem" mat-cell>{{ qSystem.companyName }}</td>
    </ng-container>
    <ng-container matColumnDef="contactPhoneNumber">
      <th
        *matHeaderCellDef
        [class.active]="quotingSystemActiveColumn === 'contactPhoneNumber'"
        mat-header-cell
        mat-sort-header>
        Contact
      </th>
      <td *matCellDef="let qSystem" mat-cell>{{ qSystem.contactPhoneNumber }}</td>
    </ng-container>
    <ng-container matColumnDef="oAuth2Enabled">
      <th
        *matHeaderCellDef
        [class.active]="quotingSystemActiveColumn === 'oAuth2Enabled'"
        mat-header-cell
        mat-sort-header>
        OAuth2 Interface
      </th>
      <td *matCellDef="let qSystem" mat-cell>
        <span
          [class.green-color]="qSystem.oAuth2Enabled"
          [appHighlightStatus]="qSystem.oAuth2Enabled ? 'ENABLED' : 'DISABLED'"
          class="status"
          >{{ qSystem.oAuth2Enabled ? 'Enabled' : 'Disabled' }}</span
        >
      </td>
    </ng-container>
    <ng-container matColumnDef="kebab">
      <th *matHeaderCellDef [class.active]="quotingSystemActiveColumn === 'kebab'" mat-header-cell></th>
      <td *matCellDef="let qSystem" mat-cell>
        <mat-panel-description class="kebab-panel">
          <div class="secondary-icon">
            <button mat-icon-button [matMenuTriggerFor]="quotingSystemMenu" (click)="$event.stopPropagation()">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #quotingSystemMenu="matMenu" class="kebab-menu">
              <button
                mat-menu-item
                (click)="configureInterfaces.emit({ clientId: qSystem.clientId, isOauthInterface: true })">
                <span>Configure OAuth2 interface</span>
              </button>
              <button
                mat-menu-item
                (click)="confirmRevokeAllTokens.emit({ repairerSiteId: qSystem.clientId, isQuotingSystem: true })">
                <span>Revoke All Tokens</span>
              </button>
              <button mat-menu-item (click)="confirmDeleteQuotingSystem(qSystem.clientId)">
                <span>Delete Quoting System</span>
              </button>
            </mat-menu>
          </div>
        </mat-panel-description>
      </td>
    </ng-container>
    <tr *matHeaderRowDef="quotingSystemListColumnHeaders" mat-header-row></tr>
    <tr *matRowDef="let row; columns: quotingSystemListColumnHeaders" mat-row (click)="addEditQuotingSystem(row)"></tr>
  </table>
  <div *ngIf="!quotingSystemList?.length">
    <div class="no-results">No quoting systems configured</div>
  </div>
</div>
