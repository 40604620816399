import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RedirectHelper {
  manageRedirection(user, router) {
    if (user.privileges && user.privileges.includes('SYSADMIN')) {
      router.navigate(['sysadmin']).catch();
    } else if (user.privileges && user.privileges.includes('MLADMIN')) {
      router.navigate(['mladmin']).catch();
    } else if (user.privileges && user.privileges.includes('REPADMIN') && !user.eulaApproved) {
      router.navigate(['acceptEula']).catch();
    } else if (user.privileges && user.privileges.includes('REPADMIN') && !user.siteIsPublished) {
      router.navigate(['locationAdmin']).catch();
    } else {
      router.navigate(['dashboard']).catch();
    }
  }
}
