import { Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { FormArray, FormControl, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyOptionModule } from '@angular/material/legacy-core';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import { UserRepairerSite } from '../../../../model/user.model';
import { UserService } from '../../../../services/user.service';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacyProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MultiLocalAdminService } from '../../../../services/ml-admin.service';
import { MatDividerModule } from '@angular/material/divider';
import { InfosGuidanceComponent } from '../../../../components/infos-guidance/infos-guidance.component';

@Component({
  selector: 'app-mladmin-modal-repairer-sites',
  standalone: true,
  imports: [
    CommonModule,
    MatLegacyButtonModule,
    ReactiveFormsModule,
    MatLegacyCheckboxModule,
    MatLegacyOptionModule,
    MatLegacySelectModule,
    MatLegacyInputModule,
    MatLegacyProgressSpinnerModule,
    MatIconModule,
    MatDividerModule,
    InfosGuidanceComponent
  ],
  templateUrl: './mladmin-modal-repairer-sites.component.html'
})
export class MladminModalRepairerSitesComponent implements OnInit, OnDestroy {
  @Input() userRepairerSite: UserRepairerSite[] = [];
  @Output() repairerSiteSelected = new EventEmitter<number[]>();

  form: UntypedFormGroup;
  repairerGroupList: string[] = ['All'];
  selectAll = false;
  searchPerformed = false;

  private fb = inject(UntypedFormBuilder);
  mladminService = inject(MultiLocalAdminService);
  private userService = inject(UserService);

  get repairerSites() {
    return this.form.get('repairerSites') as FormArray;
  }
  get search() {
    return this.form.get('search');
  }
  get searchRepairerGroup() {
    return this.form.get('searchRepairerGroup');
  }

  selectedRepairerSites = signal<UserRepairerSite[]>([]);

  ngOnInit() {
    this.initializeForm();
    this.getRepairerGroup();
    this.emitRepairerSiteSelected();
  }

  ngOnDestroy(): void {
    this.mladminService.resetNotArchivedRepairerSitesList();
  }

  private initializeForm() {
    const initialUserRepairerSites = this.userRepairerSite ?? [];
    const repairerSites: number[] = initialUserRepairerSites.map((site: UserRepairerSite) => site.id);
    this.form = this.fb.group({
      repairerSites: this.fb.array(repairerSites),
      search: '',
      searchRepairerGroup: 'All'
    });
    this.selectedRepairerSites.set([...initialUserRepairerSites]);
  }

  private emitRepairerSiteSelected() {
    this.repairerSiteSelected.emit(this.repairerSites.value);
  }

  private getRepairerGroup() {
    this.userService.getRepairerGroup().subscribe((list) => {
      list.forEach((group) => {
        this.repairerGroupList.push(group.name);
      });
    });
  }

  private addSiteToSelectedList(siteId: number): void {
    if (!this.selectedRepairerSites().find(({ id }) => id === siteId)) {
      const newSite = this.mladminService.notArchivedRepairerSitesList().find(({ id }) => id === siteId);
      this.selectedRepairerSites.set(
        [...this.selectedRepairerSites(), newSite].sort((a, b) => a.companyName.localeCompare(b.companyName))
      );
    }
  }

  getRepairerSites() {
    const groupSearch = this.searchRepairerGroup.value !== 'All' ? this.searchRepairerGroup.value : '';
    this.mladminService.getNotArchivedRepairerSites(groupSearch, this.search.value).subscribe(() => {
      this.checkSelectAllCheckBox();
      !this.searchPerformed && (this.searchPerformed = true);
    });
  }

  onCheckRepairerSiteCheckbox(checked: boolean, siteId: number) {
    if (checked) {
      this.repairerSites.push(new FormControl(siteId));
      this.addSiteToSelectedList(siteId);
    } else {
      this.removeIdFromRepairerSiteForm(siteId);
    }
    this.emitRepairerSiteSelected();
    this.checkSelectAllCheckBox();
  }

  removeIdFromRepairerSiteForm(siteId: number) {
    const indexToRemove = this.repairerSites.value.findIndex((id) => id === siteId);
    indexToRemove !== -1 && this.repairerSites.removeAt(indexToRemove);
  }

  checkSelectAllCheckBox() {
    const idsVisible = this.mladminService.notArchivedRepairerSitesList().map((site) => site.id) ?? [];
    const missingElements = this.findMissingElements(this.repairerSites.value, idsVisible);
    this.selectAll = missingElements.length === 0 && this.mladminService.notArchivedRepairerSitesList().length !== 0;
  }

  selectAllVisibleRepairerSites(checked: boolean) {
    const idsVisible = this.mladminService.notArchivedRepairerSitesList().map((site) => site.id) ?? [];
    if (checked) {
      const missingElements = this.findMissingElements(this.repairerSites.value, idsVisible);
      if (missingElements.length) {
        missingElements.forEach((siteId) => {
          this.repairerSites.push(new FormControl(siteId));
          this.addSiteToSelectedList(siteId);
        });
      }
    } else {
      const matchingElements = this.findMatchingElements(this.repairerSites.value, idsVisible);
      matchingElements.forEach((element) => {
        this.removeIdFromRepairerSiteForm(element);
      });
    }
    this.emitRepairerSiteSelected();
  }

  private findMissingElements(arr1: number[], arr2: number[]): number[] {
    return arr2.filter((element) => !arr1.includes(element));
  }

  private findMatchingElements(arr1: number[], arr2: number[]) {
    return arr2.filter((element) => arr1.includes(element));
  }
}
