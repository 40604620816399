import { HttpClient, HttpParams } from '@angular/common/http';
import { computed, inject, Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, finalize, mergeMap, tap } from 'rxjs/operators';
import { MessagingService } from '../components/messaging/messaging.service';
import { FeaturesGatedEnum } from '../enums/featuresGated.enum';
import { RepairerSitesSortEnum } from '../enums/RepairerSitesSortEnum.enum';
import { RedirectHelper } from '../helpers/redirect.helper';
import { CompanyInfo } from '../model/company-information.model';
import { FinanceCompany, FinanceCompanyUpdate } from '../model/financing-company.model';
import { CoreQuotingSystem } from '../model/quoting-system/core-quoting-system.model';
import { QuotingSystemCreate, QuotingSystemUpdate } from '../model/quoting-system/quoting-system-create.model';
import { QuotingSystemOAuth2 } from '../model/quoting-system/quoting-system-oAuth.model';
import { QuotingSystem } from '../model/quoting-system/quoting-system.model';
import {
  RepairerSiteCreate,
  RepairerSiteView,
  SysadminUpdate,
  User,
  UserCreate,
  UserRepairerSite,
  UserRepairerSitesResponse
} from '../model/user.model';
import { CurrentUserService } from './currentUser.service';
import { ToolbarService } from './toolbar.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class SysAdminService {
  repairerSiteAccessed: WritableSignal<string> = signal('');
  repairerSitesTotalNumberOfMatchesFound: WritableSignal<number> = signal(0);
  repairerSitesList: WritableSignal<UserRepairerSite[]> = signal([]);
  loadingRepairerSitesList: WritableSignal<boolean> = signal(false);
  featuresGated: WritableSignal<FeaturesGatedEnum[]> = signal([]);
  estimagePayEnabled: Signal<boolean> = computed(() =>
    this.featuresGated().includes(FeaturesGatedEnum.FEATURE_ESTIMAGE_PAY_ENABLED)
  );
  private http = inject(HttpClient);
  private router = inject(Router);
  private redirectHelper = inject(RedirectHelper);
  private currentUserService = inject(CurrentUserService);
  private messageService = inject(MessagingService);
  private userService = inject(UserService);
  private toolbarService = inject(ToolbarService);

  private static handleError(error: any) {
    return Promise.reject(error.error || error.details || error);
  }

  getRepairerSites(
    status: string,
    searchString = null,
    page: number = 0,
    sortOrder: RepairerSitesSortEnum = null,
    sortDescending = false
  ) {
    this.loadingRepairerSitesList.set(true);
    const params: HttpParams = new HttpParams()
      .set('searchString', searchString)
      .set('sortOrder', sortOrder)
      .set('sortDescending', sortDescending)
      .set('status', status)
      .set('page', page);
    return this.http.get<UserRepairerSitesResponse>(`ui/admin/repairerSites`, { params }).pipe(
      tap((result) => {
        this.repairerSitesTotalNumberOfMatchesFound.set(result.numberOfMatches);
        this.repairerSitesList.set(result.repairerSites);
      }),
      catchError((error) => {
        this.messageService.error('Could not retrieve the list of repairer sites.');
        return SysAdminService.handleError(error);
      }),
      finalize(() => this.loadingRepairerSitesList.set(false))
    );
  }

  getRepairerSite(id: number) {
    return this.http.get<RepairerSiteView>(`ui/admin/repairerSite/${id}`).pipe(catchError(SysAdminService.handleError));
  }

  getSysadmins() {
    return this.http.get<User[]>('ui/admin/sysadmin').pipe(catchError(SysAdminService.handleError));
  }

  createSysadmin(sysadmin: UserCreate) {
    sysadmin.email = sysadmin.email.toLowerCase();

    return this.http.post('ui/admin/sysadmin', sysadmin).pipe(catchError(SysAdminService.handleError));
  }

  getQuotingSystems() {
    return this.http.get<CoreQuotingSystem[]>('ui/admin/quotingSystems').pipe(catchError(SysAdminService.handleError));
  }

  getQuotingSystem(clientId: string) {
    return this.http
      .get<QuotingSystem>(`ui/admin/quotingSystem/${clientId}`)
      .pipe(catchError(SysAdminService.handleError));
  }

  createQuotingSystem(quotingSystemCreate: QuotingSystemCreate) {
    return this.http.post('ui/admin/quotingSystem', quotingSystemCreate).pipe(catchError(SysAdminService.handleError));
  }

  deleteQuotingSystem(clientId: string) {
    return this.http.delete(`ui/admin/${clientId}`).pipe(catchError(SysAdminService.handleError));
  }

  grantAccessRepairerSite(repairerSiteId: number) {
    return this.http
      .post(`ui/admin/grantAccessRepairerSite/${repairerSiteId}`, null)
      .pipe(catchError(SysAdminService.handleError));
  }

  getRepairerSiteAccessFromLocalStorage() {
    localStorage.getItem('repairerSiteAccessed') !== this.repairerSiteAccessed() &&
      this.repairerSiteAccessed.set(localStorage.getItem('repairerSiteAccessed'));
  }

  revokeAccessRepairerSite() {
    return this.http.post('ui/admin/revokeAccessRepairerSite', null).pipe(
      mergeMap(() => this.userService.getCurrentUser()),
      tap((user) => {
        this.repairerSiteAccessed.set('');
        localStorage.removeItem('repairerSiteAccessed');
        return this.currentUserService.userLogin(user);
      }),
      catchError((error) => {
        this.messageService.error('Could not revoke access the the repairer site.');
        return SysAdminService.handleError(error);
      }),
      finalize(() => this.redirectHelper.manageRedirection(this.currentUserService.currentUserValue, this.router))
    );
  }

  updateAndReinviteRepairerSite(email: string, repairerSiteId: number) {
    return this.http
      .post(`ui/admin/updateAndReinviteRepairerSite/${repairerSiteId}?email=${email}`, null)
      .pipe(catchError(SysAdminService.handleError));
  }

  updateAndReinviteSysadmin(username: string, userUpdate: SysadminUpdate) {
    return this.http
      .post(`ui/admin/updateAndReinviteSysadmin/${username}`, userUpdate)
      .pipe(catchError(SysAdminService.handleError));
  }

  archiveRepairerSite(repairerSiteId: number) {
    return this.http
      .post(`ui/admin/archiveRepairerSite/${repairerSiteId}`, null)
      .pipe(catchError(SysAdminService.handleError));
  }

  deleteSysadminOrMlAdmin(username: string) {
    this.toolbarService.setCloudState('SAVING');
    return this.http.post(`ui/admin/deleteSysadminOrMlAdmin/${username}`, null).pipe(
      tap(() => {
        this.messageService.confirm(`Invited User Deleted`);
      }),
      catchError((error) => {
        this.messageService.error(`Invited User could not be deleted.`);
        return SysAdminService.handleError(error);
      }),
      finalize(() => {
        this.toolbarService.setCloudState('RESTING');
      })
    );
  }

  deleteRepairerSite(repairerSiteId: number) {
    return this.http
      .post(`ui/admin/deleteRepairerSite/${repairerSiteId}`, null)
      .pipe(catchError(SysAdminService.handleError));
  }

  setDashboardDays(repairerSiteId: number, dashboardDays: number) {
    return this.http
      .put(`ui/admin/repairerSites/${repairerSiteId}/dashboardDays/${dashboardDays}`, null)
      .pipe(catchError(SysAdminService.handleError));
  }

  disableUser(username: string) {
    this.toolbarService.setCloudState('SAVING');
    return this.http.post(`ui/admin/disableUser/${username}`, null).pipe(
      tap(() => {
        this.messageService.confirm(`User disabled.`);
      }),
      catchError((error) => {
        this.messageService.error(`Could not disable user.`);
        return SysAdminService.handleError(error);
      }),
      finalize(() => {
        this.toolbarService.setCloudState('RESTING');
      })
    );
  }

  enableUser(username: string) {
    this.toolbarService.setCloudState('SAVING');
    return this.http.post(`ui/admin/enableUser/${username}`, null).pipe(
      tap(() => {
        this.messageService.confirm(`User enabled.`);
      }),
      catchError((error) => {
        this.messageService.error(`Could not enable user.`);
        return SysAdminService.handleError(error);
      }),
      finalize(() => {
        this.toolbarService.setCloudState('RESTING');
      })
    );
  }

  createRepairerSite(repairerSite: RepairerSiteCreate) {
    repairerSite.repAdmin.email = repairerSite.repAdmin.email.toLowerCase();

    return this.http.post('ui/admin/repairerSite', repairerSite).pipe(catchError(SysAdminService.handleError));
  }

  updateQuotingSystem(id: number, quotingSystemUpdate: QuotingSystemUpdate) {
    return this.http
      .post(`ui/admin/update/quotingSystem/${id}`, quotingSystemUpdate)
      .pipe(catchError(SysAdminService.handleError));
  }

  updateQuotingSystemOauth2(quotingSystemOAuth2: QuotingSystemOAuth2) {
    return this.http
      .post('ui/admin/update/quotingSystemOAuth2', quotingSystemOAuth2)
      .pipe(catchError(SysAdminService.handleError));
  }

  revokeTokensOfQuotingSystem(repairerSiteId: number) {
    return this.http
      .post(`ui/admin/revokeTokensOfQuotingSystem/${repairerSiteId}`, null)
      .pipe(catchError(SysAdminService.handleError));
  }

  revokeTokensOfRepairerSite(repairerSiteId: number) {
    return this.http
      .post(`ui/admin/revokeTokensOfRepairerSite/${repairerSiteId}`, null)
      .pipe(catchError(SysAdminService.handleError));
  }

  addFinancingCompany(financingCompany: FinanceCompanyUpdate) {
    return this.http.post('ui/admin/factoryCompanies', financingCompany).pipe(catchError(SysAdminService.handleError));
  }

  updateFinancingCompany(id: number, financingCompanyData: FinanceCompanyUpdate) {
    return this.http
      .post(`ui/admin/update/factoryCompanies/${id}`, financingCompanyData)
      .pipe(catchError(SysAdminService.handleError));
  }

  deleteFinancingCompany(id: number) {
    return this.http.post(`ui/admin/factoryCompanies/delete/${id}`, null).pipe(catchError(SysAdminService.handleError));
  }

  getQuotingSystemJobCounts(clientId: string, repairerSiteId: number, fromDate: number) {
    // tslint:disable-next-line:max-line-length
    return this.http
      .get<number>(
        `ui/admin/quotingSystems/${clientId}/repairerSites/${repairerSiteId}/jobs/count?fromDate=${fromDate}`
      )
      .pipe(catchError(SysAdminService.handleError));
  }

  updateQuotingSystemJob(clientId: string, repairerSiteId: number, toClientId: string, fromDate: number) {
    // tslint:disable-next-line:max-line-length
    return this.http
      .put(
        `/ui/admin/quotingSystems/${clientId}/repairerSites/${repairerSiteId}/jobs?toClientId=${toClientId}&fromDate=${fromDate}`,
        null
      )
      .pipe(catchError(SysAdminService.handleError));
  }

  archiveSysadmin(username: string) {
    this.toolbarService.setCloudState('SAVING');
    return this.http.post(`ui/admin/archiveSysadminOrMlAdmin/${username}`, null).pipe(
      tap(() => {
        this.messageService.confirm(`User Archived`);
      }),
      catchError((error) => {
        this.messageService.error(`User could not be archived.`);
        return SysAdminService.handleError(error);
      }),
      finalize(() => {
        this.toolbarService.setCloudState('RESTING');
      })
    );
  }

  productionMode() {
    return this.http.get<boolean>('ui/admin/productionMode').pipe(catchError(SysAdminService.handleError));
  }

  getFinancingCompanies() {
    return this.http.get<FinanceCompany[]>('ui/admin/factoryCompanies').pipe(catchError(SysAdminService.handleError));
  }

  updateUnpublishedRepairerSites(repairerSiteId: number, updateRepairerSite: RepairerSiteCreate) {
    return this.http
      .put(`ui/admin/updateUnpublished/repairerSites/${repairerSiteId}`, updateRepairerSite)
      .pipe(catchError(SysAdminService.handleError));
  }

  updateCompanyInformation(companyInfo: Omit<CompanyInfo, 'id'>, id: number) {
    return this.http.put(`ui/admin/companyInformation/${id}`, companyInfo);
  }
  getFeaturesGated() {
    return this.http.get<FeaturesGatedEnum[]>('ui/admin/activeFeaturesGated').pipe(
      tap((listOfFeaturesGated) => this.featuresGated.set(listOfFeaturesGated)),
      catchError(SysAdminService.handleError)
    );
  }
}
