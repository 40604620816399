<form [formGroup]="form" (ngSubmit)="saveOrExecuteAction()" class="mt-25">
  <mat-form-field appearance="outline" color="accent">
    <mat-label>Company Name</mat-label>
    <input formControlName="companyName" matInput>
    <mat-error *ngIf="companyName.hasError('required')">
      Company Name is <strong>{{getErrorMessage(companyName.errors)}}</strong>
    </mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline" color="accent" class="grid grid-cols-2 gap-20">
    <mat-label>{{repairerSitesStore.selectedRepairerSite()?.country === 'Australia' ? 'ABN' : 'NZBN'}}</mat-label>
    <input formControlName="abn" matInput (focusout)="adjustFormatOfAbn()">
    <mat-error *ngIf="abn.hasError('required')">
      {{repairerSitesStore.selectedRepairerSite()?.country === 'Australia' ? 'ABN' : 'NZBN'}} is <strong>{{getErrorMessage(abn.errors)}}</strong>
    </mat-error>
    <mat-error *ngIf="abn.hasError('invalidABN') && !abn.hasError('required')">
      <strong>{{getErrorMessage(abn.errors)}}</strong>
    </mat-error>
  </mat-form-field>
  <div class="title-1 pt-5 text-dove-gray mb-15">Banking Details</div>
  <mat-form-field appearance="outline" color="accent">
    <mat-label>Account Name</mat-label>
    <input formControlName="accountName" matInput>
  </mat-form-field>
  <article class="grid grid-cols-2 gap-20">
    <mat-form-field appearance="outline" color="accent">
      <mat-label>Account Number</mat-label>
      <input formControlName="accountNumber" matInput>
    </mat-form-field>
    <mat-form-field appearance="outline" color="accent">
      <mat-label>BSB</mat-label>
      <input formControlName="bsb" matInput>
    </mat-form-field>
  </article>
</form>
