<div class="header-container top-container">
  <h3>Repairer Sites</h3>
  <div class="add-repairer-filter-container">
    <button (click)="addRepairerSite()" mat-raised-button color="accent">ADD REPAIRER</button>
    <mat-divider class="header-container-actions-divider" [vertical]="true"></mat-divider>
    <button mat-icon-button (click)="repairersExpanded = !repairersExpanded">
      <mat-icon>{{ repairersExpanded ? 'expand_less' : 'expand_more' }}</mat-icon>
    </button>
  </div>
</div>
<mat-divider></mat-divider>
<app-repairer-sites
  [class.hidden]="!repairersExpanded"
  [isToBeRefreshed]="refreshTab"
  [userTimezone]="timezone"
  [repairersGroup]="repairersGroup"></app-repairer-sites>

<app-multi-location-admin-section class="dashboard-section"></app-multi-location-admin-section>

<app-administrators-section
  class="dashboard-section"
  [sysadminList]="sysadminList"
  (updateSysadminList)="getSysadmins()">
</app-administrators-section>

<app-quoting-system-section
  class="dashboard-section"
  [quotingSystemList]="quotingSystemList"
  (updateQuotingSystemList)="getQSystems()"
  (confirmRevokeAllTokens)="confirmRevokeAllTokens($event)"
  (configureInterfaces)="configureInterfaces($event)">
</app-quoting-system-section>

<app-financing-companies-section
  class="dashboard-section"
  [financingCompaniesList]="financingCompanies"
  (updateFinancingCompanies)="getFinancingCompanies()">
</app-financing-companies-section>
