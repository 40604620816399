interface States {
  abbreviation: string;
  stateName: string;
}

interface Countries {
  abbreviation: string;
  stateName: string;
}

export const STATES: States[] = [
  { abbreviation: 'ACT', stateName: 'Australian Capital Territory' },
  { abbreviation: 'NSW', stateName: 'New South Wales' },
  { abbreviation: 'NT', stateName: 'Northern Territory' },
  { abbreviation: 'QLD', stateName: 'Queensland' },
  { abbreviation: 'SA', stateName: 'South Australia' },
  { abbreviation: 'TAS', stateName: 'Tasmania' },
  { abbreviation: 'VIC', stateName: 'Victoria' },
  { abbreviation: 'WA', stateName: 'Western Australia' }
];

export const COUNTRIES: Countries[] = [
  { abbreviation: 'AU', stateName: 'Australia' },
  { abbreviation: 'NZ', stateName: 'New Zealand' }
];
