import { Directive, ElementRef, HostListener, inject, Input, Optional } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[trimInput]',
  standalone: true
})
export class TrimInputDirective {
  @Input() trimInput: boolean = true;

  @Optional() ngControl = inject(NgControl);
  private el = inject(ElementRef);

  @HostListener('blur', ['$event.target.value'])
  onBlur(value: any) {
    if (!!value && this.trimInput) {
      this.ngControl?.control.setValue(value.trim(), { emitEvent: false });
      this.el.nativeElement.value = value.trim();
    }
  }
}
