import { inject, Injectable, signal, WritableSignal } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { MlAdminUser, UserCreate, UserRepairerSite, UserRepairerSitesResponse } from '../model/user.model';
import { catchError, finalize, map, switchMap, tap } from 'rxjs/operators';
import { MessagingService } from '../components/messaging/messaging.service';
import { of } from 'rxjs';
import { ToolbarService } from './toolbar.service';
import { CurrentUserService } from './currentUser.service';

@Injectable({
  providedIn: 'root'
})
export class MultiLocalAdminService {
  notArchivedRepairerSitesTotalNumberOfMatchesFound: WritableSignal<number> = signal(0);
  notArchivedRepairerSitesList: WritableSignal<UserRepairerSite[]> = signal([]);
  loadingNotArchivedRepairerSitesList: WritableSignal<boolean> = signal(false);

  mlAdminList: WritableSignal<MlAdminUser[]> = signal([]);
  loadingMlAdminList: WritableSignal<boolean> = signal(false);
  loadingMlAdminUser: WritableSignal<boolean> = signal(false);

  private http = inject(HttpClient);
  private currentUserService = inject(CurrentUserService);
  private messagingService = inject(MessagingService);
  private toolbarService = inject(ToolbarService);

  private static handleError(error: any) {
    return Promise.reject(error.error || error.details || error);
  }

  getMlAdmin(username: string) {
    this.toolbarService.setCloudState('SAVING');
    return this.http.get<MlAdminUser>(`ui/admin/mladmin/${username}`).pipe(
      tap((user) => {
        const currentUserStored = this.currentUserService.currentUserValue;
        if (currentUserStored.username === username) {
          this.currentUserService.userLogin({ ...currentUserStored, ...user, email: user.emailAddress });
        }
      }),
      catchError((error) => {
        this.messagingService.error("Couldn't retrieve multi-location admin user.");
        return MultiLocalAdminService.handleError(error);
      }),
      finalize(() => {
        this.toolbarService.setCloudState('RESTING');
      })
    );
  }

  getAllMlAdmins() {
    this.loadingMlAdminList.set(true);
    return this.http.get<MlAdminUser[]>('ui/admin/mladmin').pipe(
      map((list) => {
        const updatedList = list.map((user) => ({
          ...user,
          name: `${user.firstName} ${user.lastName}`
        }));
        this.mlAdminList.set(updatedList);
        return 'SUCCESS';
      }),
      catchError((error) => {
        this.messagingService.error("Couldn't retrieve multi-location admin users.");
        return MultiLocalAdminService.handleError(error);
      }),
      finalize(() => {
        this.loadingMlAdminList.set(false);
        this.loadingMlAdminUser.set(false);
        this.toolbarService.setCloudState('RESTING');
      })
    );
  }

  createMlAdmin(mladmin: UserCreate, repairerSiteIds: number[]) {
    this.loadingMlAdminUser.set(true);
    this.toolbarService.setCloudState('SAVING');
    mladmin.email = mladmin.email?.toLowerCase();
    return this.http.post<string>('ui/admin/mladmin', mladmin).pipe(
      switchMap(() => {
        if (repairerSiteIds.length > 0) {
          return this.associateRepairerSitesToMlAdmin(mladmin.username, repairerSiteIds, 'add');
        } else {
          this.messagingService.confirm('New Multi-Location Administrator Created');
          return this.getAllMlAdmins();
        }
      }),
      catchError((response: HttpErrorResponse) => {
        if (response.error.error === 'DUPLICATE_USERNAME') {
          return of('DUPLICATE_USERNAME');
        } else {
          this.messagingService.error('Could not create the Multi-Location Administrator.');
        }
        return MultiLocalAdminService.handleError(response);
      }),
      finalize(() => {
        this.loadingMlAdminUser.set(false);
      })
    );
  }

  updateMlAdmin(username: string, mladmin: UserCreate, repairerSiteIds: number[], updateRepairerSites = true) {
    this.loadingMlAdminUser.set(true);
    this.toolbarService.setCloudState('SAVING');
    mladmin.email = mladmin.email.toLowerCase();
    return this.http.put<string>(`ui/admin/mladmin/${username}`, mladmin).pipe(
      switchMap(() => {
        if (updateRepairerSites) {
          return this.associateRepairerSitesToMlAdmin(username, repairerSiteIds, 'edit');
        } else {
          this.messagingService.confirm('Multi-Location Administrator Updated');
          return updateRepairerSites ? this.getAllMlAdmins() : this.getMlAdmin(username);
        }
      }),
      catchError((error) => {
        this.messagingService.error('Could not update Multi-Location Administrator.');
        return MultiLocalAdminService.handleError(error);
      })
    );
  }

  associateRepairerSitesToMlAdmin(username: string, repairerIdList: number[], action: 'add' | 'edit') {
    return this.http.put<null>(`ui/admin/mladmin/${username}/associateRepairerSites`, repairerIdList).pipe(
      map(() => {
        this.messagingService.confirm(action === 'add' ? 'New User Created' : 'User Updated');
        return 'SUCCESS';
      }),
      catchError((error) => {
        this.messagingService.error('Could not associate repairer sites to user.');
        return MultiLocalAdminService.handleError(error);
      }),
      switchMap(() => {
        return this.getAllMlAdmins();
      })
    );
  }

  getNotArchivedRepairerSites(groupName: string, companyName: string) {
    this.loadingNotArchivedRepairerSitesList.set(true);
    const params: HttpParams = new HttpParams().set('groupName', groupName).set('companyName', companyName);
    return this.http.get<UserRepairerSitesResponse>(`ui/admin/repairerSites/notArchived`, { params }).pipe(
      tap((result) => {
        this.notArchivedRepairerSitesTotalNumberOfMatchesFound.set(result.numberOfMatches);
        this.notArchivedRepairerSitesList.set(result.repairerSites);
      }),
      catchError((error) => {
        this.messagingService.error('Could not retrieve the list of repairer sites.');
        return MultiLocalAdminService.handleError(error);
      }),
      finalize(() => this.loadingNotArchivedRepairerSitesList.set(false))
    );
  }

  resetNotArchivedRepairerSitesList(): void {
    this.notArchivedRepairerSitesTotalNumberOfMatchesFound.set(0);
    this.notArchivedRepairerSitesList.set([]);
  }
}
