<div class="title-1">Confirmation of change</div>
<form (ngSubmit)="confirm()" [formGroup]="passwordForm">
  <div class="password-confirm subtitle-2" mat-dialog-content>
    <div>For security measures, you need to enter your password to confirm the change.</div>
    <div class="fields-box">
      <mat-form-field appearance="outline" color="accent" class="input-field-with-icon">
        <mat-label>Password</mat-label>
        <mat-icon matPrefix color="accent">lock</mat-icon>
        <mat-divider [vertical]="true"></mat-divider>
        <input formControlName="password" matInput required type="password" />
        <mat-error *ngIf="password.hasError('required')"> Password is <strong>required</strong> </mat-error>
        <mat-error *ngIf="password.hasError('notValid')" class="mat-error">
          Your password could not be validated. Please review your information
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <mat-dialog-actions [ngClass]="{ biggerButtons: data?.biggerButtons }">
    <button mat-raised-button color="accent" mat-dialog-close>CANCEL</button>
    <button color="primary" mat-button mat-raised-button [disabled]="passwordForm.invalid || pending" type="submit">
      CONFIRM
    </button>
  </mat-dialog-actions>
</form>
