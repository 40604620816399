<div class="header-container">
  <h3>Finance Companies</h3>
  <div class="header-container-actions">
    <button (click)="addEditFinancingCompany()" mat-raised-button color="accent">ADD FINANCE COMPANY</button>
    <mat-divider class="header-container-actions-divider" [vertical]="true"></mat-divider>
    <button mat-icon-button (click)="financingCompaniesExpanded = !financingCompaniesExpanded">
      <mat-icon>{{ financingCompaniesExpanded ? 'expand_less' : 'expand_more' }}</mat-icon>
    </button>
  </div>
</div>
<mat-divider></mat-divider>
<div [class.hidden]="!financingCompaniesExpanded" class="factoring-section">
  <table
    #financingCompaniesSort="matSort"
    (matSortChange)="activeFinancingCompaniesColumn = $event.active"
    [dataSource]="financingCompaniesDataSource"
    class="table-style"
    mat-table
    matSort
    matSortActive="companyName"
    matSortDirection="asc"
    matSortDisableClear>
    <ng-container matColumnDef="companyName">
      <th
        *matHeaderCellDef
        [class.active]="activeFinancingCompaniesColumn === 'companyName'"
        mat-header-cell
        mat-sort-header>
        Finance Company
      </th>
      <td class="body-1" *matCellDef="let financingCompany" mat-cell>{{ financingCompany.companyName }}</td>
    </ng-container>
    <ng-container matColumnDef="abn">
      <th *matHeaderCellDef [class.active]="activeFinancingCompaniesColumn === 'abn'" mat-header-cell mat-sort-header>
        ABN
      </th>
      <td class="body-1" *matCellDef="let financingCompany" mat-cell>{{ financingCompany.abn }}</td>
    </ng-container>
    <ng-container matColumnDef="email">
      <th *matHeaderCellDef [class.active]="activeFinancingCompaniesColumn === 'email'" mat-header-cell mat-sort-header>
        Email
      </th>
      <td class="body-1" *matCellDef="let financingCompany" mat-cell>{{ financingCompany.email }}</td>
    </ng-container>
    <ng-container matColumnDef="phone">
      <th *matHeaderCellDef [class.active]="activeFinancingCompaniesColumn === 'phone'" mat-header-cell mat-sort-header>
        Contact
      </th>
      <td class="body-1" *matCellDef="let financingCompany" mat-cell>{{ financingCompany.phone }}</td>
    </ng-container>
    <ng-container matColumnDef="addressLine1">
      <th
        *matHeaderCellDef
        [class.active]="activeFinancingCompaniesColumn === 'addressLine1'"
        mat-header-cell
        mat-sort-header>
        Address
      </th>
      <td class="body-1" *matCellDef="let financingCompany" mat-cell>
        <div>
          {{ financingCompany.addressLine1 && financingCompany.addressLine1 + ',' }}
          {{ financingCompany.addressLine2 && financingCompany.addressLine2 + ',' }}
          {{ financingCompany.suburb && financingCompany.suburb + ',' }}
        </div>
        <div>
          {{
          financingCompany.country === 'Australia'
            ? financingCompany.state && financingCompany.state + ','
            : financingCompany.city && financingCompany.city + ','
          }}
          {{ financingCompany.postCode && financingCompany.postCode + ',' }} {{ financingCompany.country }}
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="deleteCompany">
      <th *matHeaderCellDef mat-header-cell></th>
      <td *matCellDef="let financingCompany" class="primary-icon delete body-1" mat-cell>
        <button (click)="$event.stopPropagation(); confirmDeleteFinancingCompany(financingCompany.id)" mat-icon-button>
          <mat-icon>delete</mat-icon>
        </button>
      </td>
    </ng-container>
    <tr *matHeaderRowDef="financingCompaniesColumnHeaders" mat-header-row></tr>
    <tr
      (click)="addEditFinancingCompany(row)"
      *matRowDef="let row; columns: financingCompaniesColumnHeaders"
      mat-row></tr>
  </table>
  <div *ngIf="!financingCompaniesList?.length">
    <div class="no-results">No finance companies</div>
  </div>
</div>
