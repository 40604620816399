import { Component, Input } from '@angular/core';
import { Gicop } from '../../../model/gicop-notifications';

@Component({
  selector: 'app-gicop-notifications-curtain',
  templateUrl: './gicop-notifications-curtain.component.html',
  styleUrls: ['./gicop-notifications-curtain.component.scss']
})
export class GicopNotificationsCurtainComponent {
  @Input() gicopNotifications: Gicop;
  @Input() timezone: string;
  constructor() {}
}
