<div class="dialog-header" mat-dialog-title>
  <h6>Configure Import Folders</h6>
  <button aria-label="Close" mat-dialog-close mat-icon-button>
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-divider></mat-divider>
<div mat-dialog-conten>
  <div class="guidance-access body-1">
    <div>
      EstImage can automatically search for quotes and invoices to import in a job, as well as for related images, from
      the folders selected below.
    </div>
    <div class="bold">Click "View Files" to give EstImage access.</div>
  </div>
  <div class="set-directories">
    <app-set-directory
      [data]="{ icon: 'folder_open', type: 'file', fileDirectory: fileDirectoryHandler?.name }"
      (setDirectory)="setDirectoryHandler($event)"
      (deleteDirectory)="deleteDirectoryHandler($event)"></app-set-directory>
    <app-set-directory
      [data]="{ icon: 'crop_original', type: 'image', imageDirectory: imagesDirectoryHandler?.name }"
      (setDirectory)="setDirectoryHandler($event)"
      (deleteDirectory)="deleteDirectoryHandler($event)"></app-set-directory>
  </div>
</div>
<mat-dialog-actions>
  <button mat-raised-button mat-dialog-close color="accent">CANCEL</button>
  <button mat-button mat-raised-button (click)="saveHandlers()" cdkFocusInitial color="primary">SAVE</button>
</mat-dialog-actions>
