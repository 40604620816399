<div class="notification-section">
  <h4 class="title">Vulnerable customer</h4>
  <table
    #customerVulnerabilitySort="matSort"
    mat-table
    matSort
    [dataSource]="dataSource"
    class="table-style"
    matSortActive="createdTime"
    matSortDirection="desc"
    matSortDisableClear
    (matSortChange)="activeColumn = $event.active">
    <ng-container matColumnDef="createdTime">
      <th mat-header-cell *matHeaderCellDef [class.active]="activeColumn === 'createdTime'" mat-sort-header>
        Date Added
      </th>
      <td mat-cell class="body-1" *matCellDef="let row">{{ row.createdTime | dateepoch: timezone }}</td>
    </ng-container>
    <ng-container matColumnDef="customerVulnerability">
      <th mat-header-cell *matHeaderCellDef [class.active]="activeColumn === 'customerVulnerability'" mat-sort-header>
        Customer Vulnerability
      </th>
      <td mat-cell class="body-1" *matCellDef="let row">
        <div>
          {{ row.customerVulnerability | humanize | titlecase }}
          {{ ['Other', 'Customer Speaks'].includes(row.customerVulnerability) ? ':' : '' }}
        </div>
        <div [ngSwitch]="row.customerVulnerability">
          <span *ngSwitchCase="'Other'">{{ '(' + row.details + ')' }}</span>
          <span *ngSwitchCase="'Customer Speaks'">{{ '(' + row.languagesSpoken.join(', ') + ')' }}</span>
          <span *ngSwitchDefault></span>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="contactPerson">
      <th mat-header-cell *matHeaderCellDef [class.active]="activeColumn === 'contactPerson'" mat-sort-header>
        Contact Person
      </th>
      <td mat-cell class="body-1" *matCellDef="let row">
        <div>{{ row.contactPerson }}</div>
        <div *ngIf="row.position">{{ row.position }}</div>
      </td>
    </ng-container>
    <ng-container matColumnDef="phoneNumber">
      <th mat-header-cell *matHeaderCellDef [class.active]="activeColumn === 'phoneNumber'" mat-sort-header>
        Phone Number
      </th>
      <td mat-cell class="body-1" *matCellDef="let row">{{ row.phoneNumber }}</td>
    </ng-container>
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef [class.active]="activeColumn === 'email'" mat-sort-header>Email</th>
      <td mat-cell class="body-1" *matCellDef="let row">{{ row.email }}</td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columnHeader"></tr>
    <tr mat-row *matRowDef="let row; columns: columnHeader"></tr>
  </table>
</div>
