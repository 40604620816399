import { inject, Injectable, signal } from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { ResponseEnumEnum } from '../../enums/responseEnum.enum';
import { ToolbarService } from '../../services/toolbar.service';
import { MessagingService } from '../messaging/messaging.service';
import { DynamicDialogComponent } from './dynamic-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DynamicDialogService {
  #message = inject(MessagingService);
  #toolbarService = inject(ToolbarService);
  #dialog = inject(MatDialog);
  dialogRefSignal = signal(null);
  openDialog(config: MatDialogConfig<any>): MatDialogRef<any> {
    return this.#dialog.open(DynamicDialogComponent, config);
  }

  closeDialog(responseType: ResponseEnumEnum.SUCCESS | ResponseEnumEnum.ERROR, message?: string) {
    if (message) {
      responseType === ResponseEnumEnum.SUCCESS ? this.#message.confirm(message) : this.#message.error(message);
    }
    this.dialogRefSignal().close(responseType);
    this.#toolbarService.setCloudState('RESTING');
  }
}
