<mat-card class="curtains">
  <div class="repair-timeline">
    <div>
      <div *ngIf="job?.repairTimelineDate" class="received-date">
        {{ job?.repairTimelineDate | dateepoch: currentTimezone }} {{ job?.repairTimelineDate | time: currentTimezone }}
      </div>
      <h5 class="repair-timeline-header">Repair Timeline</h5>
    </div>
    <div class="repair-timeline-body">
      <div *ngIf="job?.authorityDate" class="repair-timeline-body-part">
        <div class="repair-timeline-body-part-authorized">
          <div class="repair-timeline-body-part-authorized-title body-1">Authorised</div>
          <div class="repair-timeline-body-part-authorized-date body-2">
            {{ job?.authorityDate | momentTz: currentTimezone }}
          </div>
        </div>
      </div>
      <app-repair-timeline-box
        *ngFor="let timelineBox of timelineBoxes"
        [timelineBox]="timelineBox"
        [editDisabled]="isEditionDisabled"
        (editRepairTimeline)="editRepairTimeline.emit()"
        [currentTimezone]="currentTimezone"></app-repair-timeline-box>
    </div>
    <div *ngIf="!isEditionDisabled" class="repair-timeline-footer">
      <button (click)="editRepairTimeline.emit()" mat-icon-button>
        <mat-icon class="mat-icon-edit">edit</mat-icon>
      </button>
    </div>
  </div>
</mat-card>
