<div class="dialog-header" mat-dialog-title>
  <h6>Change Quoting System</h6>
  <button mat-icon-button aria-label="Close" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-divider></mat-divider>
<div mat-dialog-content>
  <form [formGroup]="moveQSForm">
    <div class="field-line">
      <mat-form-field appearance="outline" color="accent">
        <mat-label>From</mat-label>
        <mat-select formControlName="from">
          <mat-option *ngFor="let qs of quotingSystemsList" [value]="qs.clientId">{{ qs.companyName }}</mat-option>
        </mat-select>
        <mat-error *ngIf="from.hasError('required')"> From is <strong>required</strong> </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" color="accent">
        <mat-label>To</mat-label>
        <mat-select formControlName="to">
          <mat-option *ngFor="let qs of quotingSystemsList" [value]="qs.clientId">{{ qs.companyName }}</mat-option>
        </mat-select>
        <mat-error *ngIf="to.hasError('required')"> To is <strong>required</strong> </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="primary-icon date-field" color="accent">
        <mat-label>Initial Date</mat-label>
        <input
          (keypress)="dateInputHelper.restrictDateInput($event)"
          [matDatepicker]="initialDatepicker"
          formControlName="initialDate"
          matInput />
        <mat-datepicker-toggle [for]="initialDatepicker" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #initialDatepicker></mat-datepicker>
        <mat-hint>Date format DD/MM/YYYY</mat-hint>
        <mat-error *ngIf="initialDate.hasError('required') && !initialDate.hasError('matDatepickerParse')">
          <div>Initial Date is <strong>required</strong></div>
        </mat-error>
        <mat-error *ngIf="initialDate.hasError('matDatepickerParse')">
          The date format is <strong>DD/MM/YYYY</strong>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="info-line" *ngIf="from.valid && to.valid && initialDate.valid">
      <mat-icon class="icon">info_outline</mat-icon>
      <span class="body-1" *ngIf="!duplicateFromTo">
        {{ jobsCount }} job(s) will be moved from {{ fromCompanyName }} to {{ toCompanyName }} starting
        {{ initialDateString }}.
      </span>
      <span class="body-1" *ngIf="duplicateFromTo"> You must select two different quoting systems. </span>
    </div>
  </form>
</div>
<mat-dialog-actions>
  <button mat-raised-button color="accent" mat-dialog-close>CANCEL</button>
  <button
    mat-raised-button
    [disabled]="loading || moveQSForm.invalid || moveQSForm.pristine || duplicateFromTo"
    (click)="confirmMoveQuotingSystem()"
    color="primary">
    SAVE
  </button>
</mat-dialog-actions>
