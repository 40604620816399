import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'objectDeepValue'
})
export class ObjectDeepValuePipe implements PipeTransform {
  transform(item: any, property?: any): any {
    const properties = property?.split('.');
    if (!properties?.length) {
      return null;
    }
    let value = item[properties?.shift()];
    for (const prop of properties) {
      value = value[prop];
    }
    return value;
  }
}
