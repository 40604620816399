<div class="title-1">
  {{ data?.repairerSiteId ? 'Access ' + data?.repairerSiteName : 'Exit ' + data?.repairerSiteName }}
</div>
<div mat-dialog-content class="subtitle-2">
  <div>
    {{
      data?.repairerSiteId
        ? 'You will be granted access to ' + data.repairerSiteName
        : 'You will exit ' + data.repairerSiteName + ' and return to the administration page.'
    }}
  </div>
</div>
<mat-dialog-actions>
  <button mat-raised-button mat-dialog-close color="accent">CANCEL</button>
  <button
    (click)="data?.repairerSiteId ? grantAccessRepairerSite() : revokeAccessRepairerSite()"
    cdkFocusInitial
    color="primary"
    mat-button
    mat-raised-button>
    OK
  </button>
</mat-dialog-actions>
