import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MessagingService } from '../components/messaging/messaging.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AuthService } from './auth.service';
import { WebsocketService } from '../services/websocket.service';
import { WebRTCService } from '../services/webRTC.service';

@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {
  private messagingService = inject(MessagingService);
  private router = inject(Router);
  private matDialogs = inject(MatDialog);
  private authService = inject(AuthService);
  private webRTC = inject(WebRTCService);
  private websocketService = inject(WebsocketService);

  intercept = (request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> =>
    next.handle(request).pipe(
      catchError((error: any) => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          if (this.router.url !== '/') {
            this.messagingService.error('Your session has expired. Please log in again.');
          }
          this.matDialogs.closeAll();
          this.authService.logout().subscribe(
            () => {
              // clears all keys stored
              localStorage.clear();
              if (this.webRTC.displayMediaStream) {
                this.webRTC.displayMediaStream.getTracks().forEach((track) => {
                  track.stop();
                });
              }
              this.webRTC.resetShareScreen();
              this.websocketService.closeWebSocketConnection();
              this.router.navigate(['/login']).catch(() => window.location.replace('/login'));
            },
            () => this.messagingService.error('There was a problem logging you out. Please try again.')
          );
          return of(null);
        }
        throw error;
      })
    );
}
