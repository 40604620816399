import { Pipe, PipeTransform } from '@angular/core';
import { BreakpointObserverHelper } from '../helpers/breakpoint-observer.helper';

@Pipe({
  name: 'breakObserver'
})
export class BreakObserverPipe implements PipeTransform {
  constructor(private breakpointObserver: BreakpointObserverHelper) {}

  transform(resolutionSize: number, resolutionLimit?: 'max' | 'min'): any {
    return resolutionLimit === 'max'
      ? this.breakpointObserver.observeMaxWidth(resolutionSize)
      : this.breakpointObserver.observeMinWidth(resolutionSize);
  }
}
