import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { ColumnHeaders } from '../../model/ColumnHeaders.model';
import { filterPredicate } from '../../consts/filterPredicate.const';
import { UserRepairerSite } from '../../model/user.model';
import { sortingDataAccessor } from '../../consts/sortFunction.const';
import { MatLegacyMenuModule } from '@angular/material/legacy-menu';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { DatePipe } from '../../pipes/date.pipe';
import { TimePipe } from '../../pipes/time.pipe';
import { ObjectDeepValuePipe } from '../../pipes/objectDeepValue.pipe';
import { KebabMenuListPipe } from '../../pipes/kebabMenuList.pipe';
import { StatusCellInfoComponent } from './status-cell-info/status-cell-info.component';
import { CommonModule } from '@angular/common';
import { MatLegacyTableDataSource as MatTableDataSource, MatLegacyTableModule } from '@angular/material/legacy-table';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';

@Component({
  selector: 'app-repairer-site-table',
  standalone: true,
  imports: [
    CommonModule,
    StatusCellInfoComponent,
    MatLegacyMenuModule,
    MatExpansionModule,
    MatSortModule,
    MatIconModule,
    DatePipe,
    TimePipe,
    ObjectDeepValuePipe,
    KebabMenuListPipe,
    MatLegacyTableModule,
    MatLegacyButtonModule
  ],
  templateUrl: './repairer-site-table.component.html',
  styleUrls: ['./repairer-site-table.component.scss']
})
export class RepairerSiteTableComponent implements OnChanges {
  @Input() currentTab: string;
  @Input() columnInfos: ColumnHeaders[];
  @Input() activeColumn: string;
  @Input() loadingRepairers: boolean;
  @Input() currentUserTimezone: string;
  @Input() repairerSites: UserRepairerSite[];
  @Input() kebabMenuButtons: any[];
  @Output() columnHeaderOrderChange = new EventEmitter();
  @Output() kebabMenuClicked = new EventEmitter();
  @Output() rowItemClicked = new EventEmitter();
  @ViewChild('tableSort', { static: true }) matSort: MatSort;
  columnHeaders: string[];
  tableDataSource: MatTableDataSource<UserRepairerSite>;

  ngOnChanges() {
    this.columnHeaders = this.columnInfos?.map((col) => col.matColumnDef);
    this.tableDataSource = new MatTableDataSource(this.repairerSites);
    this.tableDataSource.sortingDataAccessor = sortingDataAccessor;
    this.tableDataSource.sort = this.matSort;
    this.tableDataSource.filterPredicate = filterPredicate;
  }
}
