import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { User } from '../model/user.model';
import { CurrentUserService } from '../services/currentUser.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardDashboard {
  currentUser: User;

  constructor(
    private currentUserService: CurrentUserService,
    private router: Router
  ) {}

  canActivate(): Observable<boolean> | boolean {
    return this.checkUser();
  }

  checkUser() {
    const currentUser = this.currentUserService.currentUserValue;
    if (currentUser) {
      if (currentUser.privileges.includes('REPADMIN') || currentUser.privileges.length === 0) {
        return true;
      } else if (currentUser.privileges.includes('MLADMIN')) {
        this.router.navigate(['/mladmin']);
      } else {
        this.router.navigate(['/sysadmin']);
      }
    }
    return false;
  }
}
