import { AfterViewInit, Component, DestroyRef, ElementRef, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ToolbarService } from '../../services/toolbar.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ExcelService } from '../excel.service';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';
import { MessagingService } from '../../components/messaging/messaging.service';
import * as moment from 'moment';
import { EIO_DATE_BE_FORMAT } from '../../consts/localization.const';
import { ReportSummary } from '../../model/report/reportSummary.model';
import { Insurer } from '../../model/insurer.model';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort, Sort } from '@angular/material/sort';
import { JobForReport } from '../../model/report/jobForReport.model';
import { fromUnixTime } from 'date-fns';
import { DatePipe, DecimalPipe } from '@angular/common';
import { CurrentUserService } from '../../services/currentUser.service';
import { DatepickerRangeComponent } from '../../components/datepicker-range/datepicker-range.component';
import { RANGE_PRESETS } from '../../model/dates-range-preset.model';
import { CleanActiveJobHelper } from '../../helpers/clean-active-job.helper';
import { JobService } from '../../services/job.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-jobs-reports',
  templateUrl: './jobs-reports.component.html',
  styleUrls: ['./jobs-reports.component.scss'],
  providers: [DecimalPipe, DatePipe]
})
export class JobsReportsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('tableContent', { static: false }) tableContent: ElementRef;

  readonly DatepickerRangeComponent = DatepickerRangeComponent;
  readonly rangePresets = RANGE_PRESETS;

  activeJobId: number;
  timezone: string;
  loading = false;
  loadingInsurers = false;
  loadingXlsx = false;
  reportSummary: ReportSummary;
  insurers: Insurer[];
  doNotShowReports = true;
  selectedInsurer = false;
  formattedReport = [];
  currentDate = new Date();
  buttonPrimary = false;
  isLargeScreen = false;

  dataSource: MatTableDataSource<JobForReport>;
  activeColumn = 'lastUpdateDate';
  selectedType = 'All Claims';
  reportTypes = [
    { name: 'All Claims', value: 'ALL_CLAIMS' },
    { name: 'Quote Submitted', value: 'QUOTED_SUBMITTED' },
    { name: 'Repair Authorised', value: 'REPAIR_AUTHORIZED' },
    { name: 'Invoice Approved', value: 'INVOICE_APPROVED' }
  ];
  reportColumns = [
    'lastUpdateDate',
    'regoNumber',
    'ownerName',
    'claimNumber',
    'lastAssessmentAction',
    'quoteOriginalNumber',
    'insurerName',
    'assessorName',
    'vehicleDescription',
    'quoteDate',
    'authorizedDate',
    'approvedDate',
    'quotedAmount',
    'authorizedAmount',
    'approvedAmount'
  ];

  private fb = inject(UntypedFormBuilder);
  private router = inject(Router);
  private destroyRef = inject(DestroyRef);
  private currentUserService = inject(CurrentUserService);
  private excelService = inject(ExcelService);
  private jobService = inject(JobService);
  private message = inject(MessagingService);
  private toolbarService = inject(ToolbarService);
  private userService = inject(UserService);
  private datePipe = inject(DatePipe);
  private decPipe = inject(DecimalPipe);
  private cleanActiveJobHelper = inject(CleanActiveJobHelper);

  generateReportForm = this.fb.group({
    startDate: ['', Validators.required],
    endDate: ['', Validators.required],
    status: ['ALL_CLAIMS'],
    insurer: ['']
  });

  get startDate() {
    return this.generateReportForm.get('startDate');
  }
  get endDate() {
    return this.generateReportForm.get('endDate');
  }
  get status() {
    return this.generateReportForm.get('status');
  }
  get insurer() {
    return this.generateReportForm.get('insurer');
  }

  ngOnInit(): void {
    this.toolbarService.toolbarData({ pageName: 'Claim List', backLink: 'dashboard' });
    this.timezone = this.currentUserService?.timeZone;

    localStorage.setItem('selectedDateRange', this.rangePresets[0]);
    localStorage.getItem('searchCriteria') ? this.fetchStoreCriteria() : this.getClaimListSearchFilter();
    this.getListOfInsurers();
    this.getActiveJobId();

    this.generateReportForm.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: () => (this.buttonPrimary = false)
    });
  }

  ngAfterViewInit() {
    if (this.activeJobId) {
      setTimeout(() => {
        const selectedRow = document.getElementById(this.activeJobId.toString());
        if (selectedRow) {
          selectedRow.scrollIntoView({ block: 'center' });
        }
      }, 1000);
    }
  }

  ngOnDestroy() {
    localStorage.removeItem('selectedDateRange');
  }

  private getActiveJobId() {
    this.jobService.activeJobId$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((activeJobId) => {
      this.activeJobId = activeJobId;
    });
  }

  exportAsXLSX(): void {
    this.loadingXlsx = true;
    this.cleanActiveJobHelper.clearLocalStorageFromActiveJob();
    if (this.reportSummary.reports.length > 0) {
      setTimeout(() => {
        this.loadingXlsx = false;
        this.formattedReport = this.reportSummary.reports.map((value) => {
          return {
            'Last Received': value.lastUpdateDate
              ? moment(fromUnixTime(value.lastUpdateDate)).format(EIO_DATE_BE_FORMAT)
              : '',
            'Registration ': value.regoNumber || '',
            'Owner ': value.ownerName || '',
            'Claim Number': value.claimNumber || '',
            'Last Assessment Action': value.lastAssessmentAction || '',
            'Quote Number': value.quoteOriginalNumber || '',
            'Insurer Name': value.insurerName || '',
            'Assessor ': value.assessorName || '',
            'Vehicle Description': value.vehicleDescription || '',
            'Quote Submitted': value.quoteDate ? moment(fromUnixTime(value.quoteDate)).format(EIO_DATE_BE_FORMAT) : '',
            'Repairs Authorized': value.authorizedDate
              ? moment(fromUnixTime(value.authorizedDate)).format(EIO_DATE_BE_FORMAT)
              : '',
            'Invoice Approved': value.approvedDate
              ? moment(fromUnixTime(value.approvedDate)).format(EIO_DATE_BE_FORMAT)
              : '',
            'Total Quoted': value.quotedAmount ? `$ ${this.decPipe.transform(value.quotedAmount, '1.2-2')}` : '',
            'Total Authorised': value.authorizedAmount
              ? `$ ${this.decPipe.transform(value.authorizedAmount, '1.2-2')}`
              : '',
            'Total Approved': value.approvedAmount ? `$ ${this.decPipe.transform(value.approvedAmount, '1.2-2')}` : ''
          };
        });

        const date = this.datePipe.transform(this.currentDate, 'yyyy-MM-dd');
        const time = this.datePipe.transform(this.currentDate, 'HH-mm');
        this.excelService.exportAsExcelFile(this.formattedReport, `EstImage_Report_`, date, time);
      }, 2000);
    }
  }

  clearAllSelection(e) {
    if (e && this.generateReportForm.controls.insurer.value) {
      this.generateReportForm.controls.insurer.patchValue('');
      this.selectedInsurer = false;
      e.stopPropagation();
    }
  }

  change(e) {
    if (e.isUserInput) {
      e.source.selected ? (this.selectedInsurer = true) : (this.selectedInsurer = false);
    }
  }

  redirectToJob(id) {
    this.jobService.activeJobId = id;
    this.router.navigate([`/jobViewer/${id}`]);
    localStorage.setItem('previousUrl', 'claimList');
  }

  getListOfInsurers() {
    this.loadingInsurers = true;
    this.toolbarService.setCloudState('FETCHING');
    this.userService.getInsurers().subscribe(
      (insurers) => (this.insurers = insurers),
      () => {
        this.message.error(`Could not retrieve the list of insurers.`);
        this.loadingInsurers = false;
        this.toolbarService.setCloudState('RESTING');
      },
      () => {
        this.insurers.sort((x, y) => (x.name > y.name ? 1 : y.name > x.name ? -1 : 0));
        this.loadingInsurers = false;
        this.toolbarService.setCloudState('RESTING');
      }
    );
  }

  fetchStoreCriteria() {
    const searchCriteria = JSON.parse(localStorage.getItem('searchCriteria'));
    this.startDate.setValue(searchCriteria.startDate);
    this.endDate.setValue(searchCriteria.endDate);
    this.status.setValue(searchCriteria.status);
    this.insurer.setValue(searchCriteria.insurer);
    this.generateReport();
  }

  getClaimListSearchFilter() {
    let startDate = this.timezone
      ? moment().tz(this.timezone).subtract(30, 'days').startOf('day')
      : moment().subtract(30, 'days').startOf('day');
    let endDate = this.timezone ? moment().tz(this.timezone).endOf('day') : moment().endOf('day');

    this.userService.getClaimListSearchFilter().subscribe({
      next: (criteria) => {
        const { from = '', ids = '', jobReportType = 'ALL_CLAIMS', to = '' } = criteria ?? {};
        startDate = from ? moment.unix(from).utc() : startDate;
        endDate = to ? moment.unix(to).utc() : endDate;
        this.generateReportForm.patchValue({ status: jobReportType, insurer: ids });
      },
      complete: () => {
        this.generateReportForm.patchValue({ startDate, endDate });
        this.generateReport();
      }
    });
  }

  generateReport() {
    this.loading = true;
    this.toolbarService.setCloudState('FETCHING');
    this.doNotShowReports = true;

    const fromEpochSeconds = moment(this.startDate.value).startOf('day').utc().unix();
    const toEpochSeconds = moment(this.endDate.value).endOf('day').utc().unix();
    const reportType = this.status.value === '' ? '' : this.status.value;
    const insurerIds = this.insurer.value === '' ? [] : this.insurer.value;
    const searchCriteria = {
      startDate: this.startDate.value,
      endDate: this.endDate.value,
      status: this.status.value,
      insurer: this.insurer.value
    };
    localStorage.setItem('searchCriteria', JSON.stringify(searchCriteria));

    this.userService.getReports(fromEpochSeconds, toEpochSeconds, reportType, insurerIds).subscribe(
      (reportSummary) => (this.reportSummary = reportSummary),
      () => {
        this.message.error(`Could not generate the report.`);
        this.loading = false;
        this.toolbarService.setCloudState('RESTING');
      },
      () => {
        this.loading = false;

        const { sortColumn = 'lastUpdateDate', sortOrder = 'asc', rows = 0, reports = [] } = this.reportSummary ?? {};
        this.doNotShowReports = rows === 0;
        this.dataSource = new MatTableDataSource(reports);
        this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
        this.dataSource.sort = this.sort;

        this.sort.direction = sortOrder;
        this.sort.active = sortColumn;
        this.sort.sortChange.emit({ direction: sortOrder, active: sortColumn });

        setTimeout(() => {
          this.tableContent.nativeElement.scrollLeft = 0;
        }, 1);
        this.toolbarService.setCloudState('RESTING');
      }
    );
  }

  sortingDataAccessor(item, property) {
    switch (property) {
      case 'lastUpdateDate':
        return item.lastUpdateDate;
      case 'quoteDate':
        return item.quoteDate;
      case 'authorizedDate':
        return item.authorizedDate;
      case 'approvedDate':
        return item.approvedDate;
      case 'quotedAmount':
        return item.quotedAmount;
      case 'authorizedAmount':
        return item.authorizedAmount;
      case 'approvedAmount':
        return item.approvedAmount;
      default:
        return item[property]?.toLowerCase();
    }
  }

  dateChange() {
    if (
      moment(this.startDate.value).format() === 'Invalid date' ||
      moment(this.endDate.value).format() === 'Invalid date'
    ) {
      localStorage.setItem('selectedDateRange', null);
    }
  }

  sortChange(event: Sort) {
    this.activeColumn = event.active;

    this.userService
      .updateClaimListSortOrder(event.active, event.direction)
      .pipe(debounceTime(200))
      .subscribe(() => {});
  }

  refresh() {
    this.generateReport();
  }
}
