import { Injectable } from '@angular/core';
import { MlAdminUser, User } from '../model/user.model';

@Injectable({ providedIn: 'root' })
export class CurrentUserService {
  private currentUser: User & MlAdminUser;
  private _timeZone: string;

  constructor() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this._timeZone = localStorage.getItem('timeZone');
  }

  public get currentUserValue(): User & MlAdminUser {
    return this.currentUser;
  }

  get timeZone(): string {
    return this._timeZone;
  }
  set timeZone(value: string) {
    localStorage.setItem('timeZone', value);
    this._timeZone = value;
  }

  userLogin(user) {
    localStorage.setItem('currentUser', JSON.stringify(user));
    this.currentUser = user;
  }

  userLogout() {
    localStorage.removeItem('currentUser');
    this.currentUser = null;
  }

  updateUserPreferences() {
    localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
  }
}
