import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DocumentItem } from '../../../model/document.model';
import { Diary, Job } from '../../../model/job.model';

@Component({
  selector: 'app-job-tasks',
  templateUrl: './job-tasks.component.html',
  styleUrls: ['./job-tasks.component.scss']
})
export class JobTasksComponent {
  @Input() job: Job;
  @Input() noQuoteResponse: boolean;
  @Input() invoiceCreated: boolean;
  @Input() supplementQuote: boolean;
  @Input() invoicePendingReview: boolean;
  @Input() showBooking: boolean;
  @Input() displayAddInvoiceButton: boolean;
  @Input() showSelfAuthorisationCurtain: boolean;
  @Input() unSubmittedQuote: DocumentItem;
  @Input() jobStates: any;
  @Input() invoiceButtonMsg: string;
  @Input() inputFileValue: string;
  @Input() ownerName: string;
  @Input() documentFeatureEnabled: boolean;
  @Input() unSubmittedDiary: Diary;
  @Output() taskButtonClicked = new EventEmitter();
  constructor() {}
}
