<div class="partial-invoice-section">
  <mat-card #panel>
    <mat-card-content>
      <div class="invoice-title">
        <div *ngIf="document.date" class="received-date">
          {{ document.date | dateepoch: timezone }} {{ document.date | time: timezone }}
        </div>
        <div class="invoice-number">
          <h5><span class="subtitle-1">Invoice</span> {{ invoice.invoiceNumber }}</h5>
          <div
            (click)="$event.stopPropagation(); tooltipApprovedDate.toggle()"
            *ngIf="invoice.status === 'APPROVED'"
            class="invoice"
            [appHighlightStatus]="invoice.status">
            APPROVED
            <div
              #tooltipApprovedDate="matTooltip"
              matTooltip="{{ invoice.acceptedOrRejectedDateTime | dateepoch: timezone }} {{
                invoice.acceptedOrRejectedDateTime | time: timezone
              }}"
              matTooltipClass="timestamp-tooltip"></div>
          </div>
          <div
            (click)="$event.stopPropagation(); tooltipAdjustedDate.toggle()"
            *ngIf="invoice.status === 'ADJUSTED'"
            class="invoice"
            [appHighlightStatus]="invoice.status">
            ADJUSTED
            <div
              #tooltipAdjustedDate="matTooltip"
              matTooltip="{{ invoice.acceptedOrRejectedDateTime | dateepoch: timezone }} {{
                invoice.acceptedOrRejectedDateTime | time: timezone
              }}"
              matTooltipClass="timestamp-tooltip"></div>
          </div>
          <div
            (click)="$event.stopPropagation(); tooltipRejectedDate.toggle()"
            *ngIf="invoice.status === 'REJECTED'"
            class="invoice"
            [appHighlightStatus]="invoice.status">
            REJECTED
            <div
              #tooltipRejectedDate="matTooltip"
              matTooltip="{{ invoice.acceptedOrRejectedDateTime | dateepoch: timezone }} {{
                invoice.acceptedOrRejectedDateTime | time: timezone
              }}"
              matTooltipClass="timestamp-tooltip"></div>
          </div>
          <div
            (click)="$event.stopPropagation(); tooltipPendingDate.toggle()"
            *ngIf="invoice.status === 'PENDING_FOR_REVIEW'"
            class="invoice"
            [appHighlightStatus]="invoice.status">
            PENDING
            <div
              #tooltipPendingDate="matTooltip"
              matTooltip="{{ invoice.acceptedOrRejectedDateTime | dateepoch: timezone }} {{
                invoice.acceptedOrRejectedDateTime | time: timezone
              }}"
              matTooltipClass="timestamp-tooltip"></div>
          </div>
          <div class="invoice" appHighlightStatus="MIGRATED">MIGRATED</div>
        </div>
      </div>
      <div>
        <ng-container *ngIf="invoice.partialTotalInvoicedAmount">
          <div class="labels body-2">Total Invoiced</div>
          <div class="data-values subtitle-2">$ {{ invoice.partialTotalInvoicedAmount | number: '1.2-2' }}</div>
        </ng-container>
      </div>
      <div>
        <div class="secondary-icon">
          <a (click)="showErrorAttachment(); $event.stopPropagation()" rel="noopener">
            <button mat-icon-button>
              <mat-icon>picture_as_pdf_24px</mat-icon>
            </button>
          </a>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
