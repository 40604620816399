import { Component, EventEmitter, inject, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DocumentReference, Job } from '../../../model/job.model';
import { ConfirmBoxComponent } from '../../../components/confirm-box/confirm-box.component';
import { UserService } from '../../../services/user.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ToolbarService } from '../../../services/toolbar.service';
import { MessagingService } from '../../../components/messaging/messaging.service';
import { MatExpansionPanel } from '@angular/material/expansion';
import { ModalConfigsHelper } from '../../../helpers/modal-configs.helper';
import { smallModalConfig } from '../../../consts/modal-config.const';
import { CurrentUserService } from '../../../services/currentUser.service';

@Component({
  selector: 'app-documents-curtain',
  templateUrl: './documents-curtain.component.html',
  styleUrls: ['./documents-curtain.component.scss']
})
export class DocumentsCurtainComponent implements OnChanges {
  @Input() job: Job;
  @Input() docs: DocumentReference[];
  @Input() blockExpansion = false;
  @Input() shouldCollapseCurtain: boolean;
  @Output() refreshJobViewer: EventEmitter<any> = new EventEmitter();
  @ViewChild('panel', { static: false }) panel: MatExpansionPanel;
  documentsCurtainOpened = false;
  extensionToOpen = ['png', 'jpeg', 'jpg', 'pdf'];

  private userService = inject(UserService);
  private confirmBox = inject(MatDialog);
  private message = inject(MessagingService);
  private modalConfigsHelper = inject(ModalConfigsHelper);
  toolbarService = inject(ToolbarService);
  currentUserService = inject(CurrentUserService);

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.shouldCollapseCurtain && !changes.shouldCollapseCurtain.firstChange) {
      if (changes.shouldCollapseCurtain.currentValue) {
        this.panel.close();
      }
    }
  }

  openOrDownloadFile(doc: DocumentReference, action: string) {
    const url = `/jobAttachments/${this.job.repairerSiteId}/${this.job.jobId}/${doc.id}.${doc.extension}`;
    if (action === 'download') {
      const link = document.createElement('a');
      link.href = url;
      document.body.appendChild(link);
      link.download = doc.filename;
      link.click();
      link.remove();
    } else if (this.extensionToOpen.includes(doc.extension)) {
      window.open(url, '_blank');
    }
  }

  confirmDeleteDoc(id: number, submitted: boolean) {
    const alertMessage = `${submitted ? 'The' : 'Your'} document will be deleted.`;
    const data = { title: 'Delete Document', alertMessage, confirmButton: 'DELETE' };
    const dialogRef = this.confirmBox.open(
      ConfirmBoxComponent,
      this.modalConfigsHelper.buildMediumModalConfig(data, smallModalConfig)
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'DELETE') {
        this.deleteDocument(id);
      }
    });
  }

  deleteDocument(id: number) {
    this.toolbarService.setCloudState('SAVING');
    this.userService.deleteJobFile(id, this.job.jobId, 'deleteJobDocument').subscribe(
      () => this.toolbarService.setCloudState('RESTING'),
      () => {
        this.message.error('Document could not be deleted.');
        this.toolbarService.setCloudState('RESTING');
      },
      () => {
        this.message.confirm('Document Deleted.');
        this.refreshJobViewer.emit();
      }
    );
  }
}
