<div class="history-table">
  <table [dataSource]="jobHistory" mat-table>
    <ng-container matColumnDef="timeStamp">
      <th *matHeaderCellDef class="time-stamp-header body-2" mat-header-cell>
        Timestamp
        <mat-icon inline="true">arrow_downward</mat-icon>
      </th>
      <td class="body-1" *matCellDef="let item" mat-cell>
        <div class="time-stamp">
          {{ item.transactionDateTime | dateHistoryEpoch: timezone }} {{ item.transactionDateTime | time: timezone }}
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="rego">
      <th *matHeaderCellDef class="rego-header body-2" mat-header-cell>REGO</th>
      <td class="body-1" *matCellDef="let item" mat-cell>
        <div class="rego">
          <span class="body-2" [class.rego-span]="item?.rego">{{ item.rego }}</span>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="owner">
      <th *matHeaderCellDef class="owner-header body-2" mat-header-cell>Owner</th>
      <td class="body-1" *matCellDef="let item" mat-cell>
        <div class="owner">{{ item.ownerName }}</div>
      </td>
    </ng-container>
    <ng-container matColumnDef="action">
      <th *matHeaderCellDef class="action-header body-2" mat-header-cell>Action</th>
      <td class="body-1" *matCellDef="let item" mat-cell>
        <mat-icon class="action-icon" *ngIf="!(item?.description === ' ')" inline="true">{{
          item.inbound ? 'move_to_inbox' : 'send'
        }}</mat-icon>
        {{ item.description }}
      </td>
    </ng-container>
    <tr *matHeaderRowDef="jobHistoryColumns; sticky: true" mat-header-row></tr>
    <tr (click)="redirectToJob(row.jobId)" *matRowDef="let row; columns: jobHistoryColumns" mat-row></tr>
  </table>
  <div *ngIf="canLoadMore" class="load-more-button-container">
    <button (click)="loadMoreItems.emit()" mat-raised-button color="accent">LOAD 25 MORE...</button>
  </div>
</div>
