<div class="dialog-header" mat-dialog-title>
  <h6>Add Invoice</h6>
  <button aria-label="Close" mat-dialog-close mat-icon-button>
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-divider></mat-divider>
<div mat-dialog-content>
  <div class="title-1">Send the invoice directly to {{ data?.insurerName }}</div>
  <div class="info-section subtitle-2">
    <mat-icon>info_outlined</mat-icon>
    <span
      >The insurer is not using EstImage for this job and the invoice must be sent by other means. You can remove the
      job from your Dashboard</span
    >
  </div>
</div>
<mat-dialog-actions>
  <button mat-raised-button color="accent" mat-dialog-close>CANCEL</button>
  <button [disabled]="pending" (click)="hideJob()" color="primary" mat-raised-button>REMOVE JOB</button>
</mat-dialog-actions>
