import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appEscapeDialog]',
  standalone: true
})
export class EscapeDialogDirective {
  @Input() appEscapeDialog: any; // This will be the dialog reference passed to the directive.

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.key === 'Escape' && this.appEscapeDialog) {
      this.appEscapeDialog.close();
    }
  }
}
