<mat-expansion-panel class="curtains" #panel>
  <mat-expansion-panel-header [collapsedHeight]="'70px'" [expandedHeight]="'70px'">
    <mat-panel-title class="diary-title">
      <div class="diary-count">
        <h5>{{ diaries?.length }} {{ diaries?.length === 1 ? 'Diary' : 'Diaries' }}</h5>
        <div class="unread-box body-2" *ngIf="unreadDiaries?.length">{{ unreadDiaries?.length }} UNREAD</div>
      </div>
      <div>
        <div class="body-2">Last diary received</div>
        <div class="subtitle-2">
          {{ lastReceivedDate | dateepoch: timezone }}
        </div>
      </div>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div *ngFor="let diary of diaries" class="diary-tile">
    <div>
      <div class="diary-line right">
        <div *ngIf="diary.date" class="received-date">
          {{ diary.date | dateepoch: timezone }} {{ diary.date | time: timezone }}
        </div>
        <div *ngIf="diary.status === 'READY_TO_READ'" class="unread-mark">
          <span class="read-box body-2">UNREAD</span>
          <div class="mark-as-read" (click)="markReadDiary(diary)"></div>
        </div>
      </div>
    </div>
    <div class="diary-line">
      <div class="red-circle" *ngIf="diary.status === 'READY_TO_READ'"></div>
      <div class="subtitle-2">{{ diary.sourceName }}</div>
    </div>
    <div class="body subtitle-2">{{ diary.subject }}</div>
    <div class="body subtitle-2">{{ diary.body }}</div>
  </div>
</mat-expansion-panel>
