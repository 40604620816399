import { ApplicationRef, Injectable, OnDestroy } from '@angular/core';
import { VersionReadyEvent, SwUpdate } from '@angular/service-worker';
import { concat, interval, Subscription } from 'rxjs';
import { filter, first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CheckForUpdateService implements OnDestroy {
  swSubscription: Subscription;

  constructor(
    appRef: ApplicationRef,
    private updates: SwUpdate
  ) {
    if (updates.isEnabled) {
      const appIsStable = appRef.isStable.pipe(first((isStable) => isStable === true));
      const timeInterval = interval(1000 * 60 * 60 * 2);

      concat(appIsStable, timeInterval).subscribe(() => updates.checkForUpdate());
    }
  }

  public checkForUpdates(): void {
    this.swSubscription = this.updates.versionUpdates
      .pipe(filter((event: VersionReadyEvent) => event.type === 'VERSION_READY'))
      .subscribe({
        next: () => {
          document.location.reload();
        },
        error: (error) => console.error(`SW Update Available: ${error}`)
      });
  }

  ngOnDestroy() {
    this.swSubscription.unsubscribe();
  }
}
