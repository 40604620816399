import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VehicleDetails } from '../../../../model/vehicle-details.model';
import { FormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-extracted-vehicle-details',
  standalone: true,
  imports: [CommonModule, FormsModule, MatCheckboxModule, MatDividerModule, MatIconModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './extracted-vehicle-details.component.html'
})
export class ExtractedVehicleDetailsComponent implements OnInit {
  @Input() data: VehicleDetails;
  @Output() importData = new EventEmitter<boolean>();
  shouldImportData = true;
  ngOnInit() {
    this.data = {
      ...this.data,
      vehicleDescription: `${this.data?.make} ${this.data?.model} ${this.data?.modelYear}`
    };
    this.importData.emit(true);
  }
}
