import { Pipe, PipeTransform } from '@angular/core';
import { EIO_DATE_UI_FORMAT_MOMENT } from '../consts/localization.const';
import * as moment from 'moment';

@Pipe({
  name: 'momentTz',
  pure: true
})
export class MomentDateTzPipe implements PipeTransform {
  transform(date: number | string, timezone: string) {
    return timezone
      ? moment(date).tz(timezone).format(EIO_DATE_UI_FORMAT_MOMENT)
      : moment(date).format(EIO_DATE_UI_FORMAT_MOMENT);
  }
}
