<section class="flex justify-between items-center my-20">
  <div class="flex items-center">
    <h4>
      {{ bucket?.title }}
      <span [id]="bucket?.id" class="anchor"></span>
    </h4>
    <div class="flex items-center text-secondary-200">
      <span class="material-icons text-sm ml-20 mr-5"> sort </span>
      <p class="body-2 capitalize">{{ sortOption.label }}</p>
    </div>
  </div>

  <div class="hidden 3xl:block" *ngIf="allowFilter">
    <ng-container *ngTemplateOutlet="noFilteredJobsFound"></ng-container>
  </div>

  <div class="flex justify-between items-end">
    <mat-form-field appearance="outline" class="no-padding-bottom min-w-[390px] input-search" *ngIf="allowFilter">
      <input
        matInput
        [placeholder]="filterPlaceholder | titlecase"
        [ngModel]="filterValue()"
        (ngModelChange)="this.filterValue.set($event)" />

      <button
        *ngIf="filterValue()"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        class="text-sm mt-5"
        (click)="filterValue.set('')">
        <mat-icon> close </mat-icon>
      </button>
      <mat-icon *ngIf="!filterValue()" class="text-lg mt-[7px]" matSuffix> filter_list </mat-icon>
    </mat-form-field>

    <mat-form-field appearance="outline" class="no-padding-bottom min-w-[315px]" *ngIf="allowSort">
      <mat-label>Sort By</mat-label>
      <mat-select
        [(value)]="sortOption"
        [compareWith]="optionComparisonFunction"
        (selectionChange)="onChangeDashboardSortOrder($event)">
        <mat-option [value]="option" *ngFor="let option of sortOptionsList">{{ option.label }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</section>

<div *ngIf="allowFilter" class="mb-25 3xl:hidden">
  <ng-container *ngTemplateOutlet="noFilteredJobsFound"></ng-container>
</div>

<div *ngIf="filteredJobs?.length; else noBucket" class="jobs-container">
  <app-repairer-tile
    *ngFor="let jobBucket of filteredJobs | orderByEpochTime: sortOption.value : sortOption.direction"
    [id]="jobBucket.jobId"
    [currentUser]="currentUser"
    [job]="jobBucket"
    [bucket]="bucket?.bucketName"
    [photoLoaded]="bucket?.photoLoaded"
    [repairerSite]="repairerSite"
    [hasRedBudge]="
      [
        'REQUEST_FOR_QUOTE',
        'MORE_INFO_REQUIRED',
        'MORE_INFO_SUPPLEMENT',
        'READY_TO_INVOICE',
        'INVOICE_REJECTED'
      ].includes(bucket?.bucketName) && !jobBucket?.unsubmittedDocument
    "
    (getJobs)="getJobs.emit()">
  </app-repairer-tile>
</div>

<ng-template #noBucket>
  <div class="guidance-message-container empty-state">
    <mat-icon>error_outline</mat-icon>
    <span>{{ bucket?.errormessage }}</span>
  </div>
</ng-template>

<ng-template #noFilteredJobsFound>
  <div *ngIf="hasNoMatchingJobs" class="flex justify-center items-center">
    <mat-icon class="mr-10">info_outline</mat-icon>
    {{ noResultFilterMessage }}
  </div>
</ng-template>

<mat-divider *ngIf="bucket?.showSeparator" class="bucket-separator"></mat-divider>
