import { inject, Injectable, signal, WritableSignal } from '@angular/core';
import { MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { EMPTY, Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { DynamicDialogService } from '../../../components/dynamic-dialog/dynamic-dialog-service';
import { MessagingService } from '../../../components/messaging/messaging.service';
import { ResponseEnumEnum } from '../../../enums/responseEnum.enum';
import { Invoice } from '../../../model/document.model';
import { DisbursementAccount } from '../../../model/estimage-pay.model';
import { FinanceCompany } from '../../../model/financing-company.model';
import { ToolbarService } from '../../../services/toolbar.service';
import { UserService } from '../../../services/user.service';

export interface InvoiceState extends EntityState<Invoice> {
  isLoading: boolean;
  financeCompanies: FinanceCompany[];
}

export const adapter: EntityAdapter<Invoice> = createEntityAdapter<Invoice>();
export const initialState: InvoiceState = adapter.getInitialState({
  isLoading: false,
  financeCompanies: []
});

const { selectAll } = adapter.getSelectors();

@Injectable({ providedIn: 'root' })
export class InvoiceStore extends ComponentStore<InvoiceState> {
  invoice = this.selectSignal((state) => selectAll(state));
  isLoading = this.selectSignal((state) => state.isLoading);
  financeCompanies = this.selectSignal((state) => state.financeCompanies);

  #dynamicDialogService = inject(DynamicDialogService);
  #message = inject(MessagingService);
  #toolbarService = inject(ToolbarService);
  #userService = inject(UserService);
  refreshInvoice: WritableSignal<any> = signal(false);

  constructor() {
    super(initialState);
  }

  openDynamicDialog = this.effect((trigger$: Observable<MatDialogConfig>) =>
    trigger$.pipe(
      switchMap((dialogConfig) => {
        return this.#dynamicDialogService
          .openDialog(dialogConfig)
          .afterClosed()
          .pipe(
            tapResponse(
              (response: ResponseEnumEnum) => {
                this.#dynamicDialogService.dialogRefSignal.set(null);
                response === ResponseEnumEnum.SUCCESS && this.refreshInvoice.set(true);
              },
              () => {
                return EMPTY;
              }
            )
          );
      })
    )
  );

  getDisbursementAccounts = this.effect((trigger$: Observable<MatDialogConfig>) =>
    trigger$.pipe(
      tap(() => {
        this.#toolbarService.setCloudState('FETCHING');
      }),
      switchMap((dialogConfig) => {
        return this.#userService.getDisbursementAccounts().pipe(
          tapResponse(
            (disbursementAccounts: DisbursementAccount[]) => {
              if (disbursementAccounts && dialogConfig) {
                dialogConfig.data.estImagePayRequest.disbursementAccounts = disbursementAccounts;
                this.openDynamicDialog(dialogConfig);
              }
              this.#toolbarService.setCloudState('RESTING');
            },
            () => {
              this.openDynamicDialog(dialogConfig);
              this.#toolbarService.setCloudState('RESTING');
            }
          )
        );
      })
    )
  );

  useEstimagePay = this.effect((trigger$: Observable<{ invoiceId: number; accountId: string }>) =>
    trigger$.pipe(
      tap(() => {
        this.#toolbarService.setCloudState('FETCHING');
      }),
      switchMap((data) => {
        const { invoiceId, accountId } = data;
        return this.#userService.useEstimagePayForInvoice(invoiceId, accountId).pipe(
          tapResponse(
            () => {
              this.#dynamicDialogService.closeDialog(
                ResponseEnumEnum.SUCCESS,
                'Disbursement Account selected for EstImage Pay'
              );
              this.#toolbarService.setCloudState('RESTING');
            },
            () => {
              this.#dynamicDialogService.closeDialog(
                ResponseEnumEnum.ERROR,
                'Could not select Disbursement Account for EstImage Pay'
              );
              this.#toolbarService.setCloudState('RESTING');
            }
          )
        );
      })
    )
  );

  removeEstimagePay = this.effect((trigger$: Observable<number>) =>
    trigger$.pipe(
      tap(() => this.#toolbarService.setCloudState('FETCHING')),
      switchMap((invoiceId) => {
        return this.#userService.removeEstimagePayForInvoice(invoiceId).pipe(
          tapResponse(
            () => this.refreshInvoice.set(true),
            () => this.#message.error('Could not remove Disbursement Account for EstImage Pay'),
            () => this.#toolbarService.setCloudState('RESTING')
          )
        );
      })
    )
  );

  getFinanceCompanies = this.effect((trigger$) =>
    trigger$.pipe(
      tap(() => this.#toolbarService.setCloudState('FETCHING')),
      switchMap(() =>
        this.#userService.getFinancingCompaniesByCountry().pipe(
          tapResponse(
            (companies) => {
              const sortedCompanies = companies.sort((a, b) => a.companyName.localeCompare(b.companyName));
              this.patchState({ financeCompanies: sortedCompanies });
            },
            (error) => {
              this.#message.error('Could not retrieve the list of finance companies.');
            }
          )
        )
      )
    )
  );
}
