<footer>
  <div class="text-footer">
    <div class="assistance-container body-2">
      <div class="assistance-text"><span>Need assistance with EstImage?</span> Please call Stelvio Support on</div>
      <div class="phone">
        <a href="tel:+0294551055"><mat-icon>phone_in_talk</mat-icon></a> 02 9455 1055
      </div>
    </div>
    <div class="copyright legal">
      All contents Copyright &copy; 1990-{{ currentYear }} Stelvio Inc. All Rights Reserved. build.dev_b2582
    </div>
  </div>
  <div class="logo">
    <a href="http://www.stelvio.com" rel="noopener" target="_blank">
      <img src="assets/images/stelvio_footer.svg" />
    </a>
  </div>
</footer>
