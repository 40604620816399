import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-guidance-box',
  templateUrl: './guidance-box.component.html',
  styleUrls: ['./guidance-box.component.scss']
})
export class GuidanceBoxComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<GuidanceBoxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit(): void {}
}

export interface DialogData {
  title: string;
  alertMessage: string;
  confirmButton: string;
}
