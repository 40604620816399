import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Authority } from '../../../model/authority.model';
import { DecimalPipe } from '@angular/common';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { UserService } from '../../../services/user.service';
import { MessagingService } from '../../../components/messaging/messaging.service';
import { ToolbarService } from '../../../services/toolbar.service';
import { CurrentUserService } from '../../../services/currentUser.service';
import { InsurerContactInfo } from '../../../model/insurer.model';
import { MatExpansionPanel } from '@angular/material/expansion';
import { CheckExistenceOfFileHelper } from '../../../helpers/check-existence-of-file.helper';

@Component({
  selector: 'app-authority-curtain',
  templateUrl: './authority-curtain.component.html',
  styleUrls: ['./authority-curtain.component.scss'],
  providers: [DecimalPipe]
})
export class AuthorityCurtainComponent implements OnInit, OnChanges {
  @Input() authority: Authority;
  @Input() repairerSiteId: number;
  @Input() jobId: number;
  @Input() editable: boolean;
  @Input() excessType: string;
  @Input() insurer: InsurerContactInfo;
  @Input() shouldCollapseCurtain: boolean;
  @Output() update: EventEmitter<any> = new EventEmitter();
  @Output() disableSendButton: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('panel', { static: false }) panel: MatExpansionPanel;

  totalsColumns = ['section', 'estimated', 'assessed', 'assessedTotals'];
  labourColumnHeaders = ['labour', 'estimated', 'assessed', 'assessedTotals'];
  partsColumnHeaders = ['parts', 'estimated', 'assessed', 'assessedTotals'];
  subletsColumnHeaders = ['sublets', 'estimated', 'assessed', 'assessedTotals'];
  authorityData = [];
  authorityForm: UntypedFormGroup;
  Math = Math;
  excessToDisplay: '';
  saving = false;
  timezone: string;

  constructor(
    private fb: UntypedFormBuilder,
    private decPipe: DecimalPipe,
    private userService: UserService,
    private currentUserService: CurrentUserService,
    private message: MessagingService,
    public toolbarService: ToolbarService,
    private checkExistenceOfFileHelper: CheckExistenceOfFileHelper
  ) {}

  get ownerContribution() {
    return this.authorityForm.get('ownerContribution');
  }

  ngOnInit(): void {
    this.timezone = this.currentUserService?.timeZone;
    this.authorityForm = this.fb.group({
      ownerContribution: [
        { value: this.authority.ownerContribution },
        Validators.pattern('\\-?(\\d+|\\d{1,3}(,\\d{3})*)(\\.\\d{1,2})?')
      ]
    });
    this.populateAuthorityValue();
    this.authorityData = this.mapToAuthorityData(this.authority);
    this.editable ? this.authorityForm.enable() : this.authorityForm.disable();

    this.ownerContribution.valueChanges.subscribe(() => {
      if (this.ownerContribution.invalid) {
        this.ownerContribution.markAsTouched();
        this.disableSendButton.emit(true);
      }
    });

    this.ownerContribution.valueChanges.pipe(debounceTime(250), distinctUntilChanged()).subscribe(() => {
      if (this.ownerContribution.dirty && this.ownerContribution.valid) {
        this.disableSendButton.emit(false);
        this.updateJob();
      }
    });

    this.excessToDisplay = this.determineExcess(this.authority, this.excessType);
  }

  updateJob() {
    this.saving = true;
    const fieldValue = this.ownerContribution.value
      ? this.ownerContribution.value.toString().replace(',', '')
      : this.ownerContribution.value;
    this.toolbarService.setCloudState('SAVING');
    this.userService.updateJob(fieldValue, this.jobId, 'ownerContribution').subscribe(
      () => this.toolbarService.setCloudState('RESTING'),
      () => {
        this.saving = false;
        this.message.error('Could not update field.');
        this.toolbarService.setCloudState('RESTING');
      },
      () => {
        this.authority.ownerContribution = this.ownerContribution.value;
        this.update.emit(fieldValue);
        this.saving = false;
      }
    );
  }

  determineExcess(authority, excessType) {
    if (excessType) {
      return excessType;
    } else {
      if (authority?.excess) {
        return this.decPipe.transform(authority?.excess, '1.2-2');
      } else {
        return 'Not Specified';
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.authority?.firstChange) {
      this.populateAuthorityValue();
    }
    if (changes.shouldCollapseCurtain && !changes.shouldCollapseCurtain.firstChange) {
      if (changes.shouldCollapseCurtain.currentValue) {
        this.panel.close();
      }
    }
  }

  populateAuthorityValue() {
    if (this.saving) {
      this.ownerContribution.setValue(this.decPipe.transform(this.ownerContribution.value, '1.2-2'), {
        emitEvent: false
      });
    } else {
      this.ownerContribution.setValue(this.decPipe.transform(this.authority.ownerContribution, '1.2-2'), {
        emitEvent: false
      });
    }
  }

  showErrorAttachment() {
    const url = `/jobAttachments/${this.repairerSiteId}/${this.jobId}/authority.pdf`;
    if (this.checkExistenceOfFileHelper.urlExists(url)) {
      window.open(url, '_blank');
    } else {
      this.message.error('There was an error loading the authority PDF');
    }
  }

  mapToAuthorityData(authority: Authority) {
    return [
      {
        labourSection: [
          {
            section: 'Remove & replace',
            estimated: authority.estRRTotal,
            assessed: authority.assessedRRTotal,
            subSection: true
          },
          {
            section: 'Repair',
            estimated: authority.estRepTotal,
            assessed: authority.assessedRepTotal,
            subSection: true
          },
          {
            section: 'Paint',
            estimated: authority.estPaintTotal,
            assessed: authority.assessedPaintTotal,
            subSection: true
          },
          {
            section: 'Mechanical',
            estimated: authority.estMechTotal,
            assessed: authority.assessedMechTotal,
            subSection: true
          },
          {
            section: 'Miscellaneous',
            estimated: authority.estMiscTotal,
            assessed: authority.assessedMiscTotal,
            subSection: true
          },
          {
            section: 'Total Labour',
            estimated: authority.estLabourTotal,
            assessed: authority.assessedLabourTotal,
            subSection: true
          }
        ].filter((data) => data.estimated || data.assessed),
        partSection: [
          {
            section: 'Subtotal',
            estimated: authority.estPartSubTotal,
            assessed: authority.assessedPartSubTotal,
            subSection: true
          },
          {
            section: 'Markup',
            estimated: authority.estPartMarkupTotal,
            assessed: authority.assessedPartMarkupTotal,
            subSection: true
          },
          {
            section: 'Total Parts',
            estimated: authority.estPartsTotal,
            assessed: authority.assessedPartsTotal,
            subSection: true
          }
        ].filter((data) => data.estimated || data.assessed),
        subletSection: [
          {
            section: 'Total Sublets',
            estimated: authority.estSubletTotal,
            assessed: authority.assessedSubletTotal,
            subSection: true
          }
        ].filter((data) => data.estimated || data.assessed),
        totalSection: [
          {
            section: 'Subtotal',
            estimated: authority.estSubTotal,
            assessed: authority.assessedSubTotal,
            subSection: false
          },
          {
            section: 'GST',
            estimated: authority.estGSTTotal,
            assessed: authority.assessedGSTTotal,
            subSection: false
          },
          {
            section: 'Total',
            estimated: authority.estTotal,
            assessed: authority.assessedTotal,
            subSection: false
          }
        ].filter((data) => data.estimated || data.assessed)
      }
    ];
  }
}
