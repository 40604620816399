import { Pipe, PipeTransform } from '@angular/core';
import { format, fromUnixTime } from 'date-fns';
import { EIO_DATE_UI_FORMAT_PIPE_HISTORY } from '../consts/localization.const';
import { utcToZonedTime } from 'date-fns-tz';

@Pipe({
  name: 'dateHistoryEpoch',
  pure: true
})
export class DateHistoryPipe implements PipeTransform {
  transform(epoch: number, timezone: string) {
    const userTimezone = timezone ? timezone : Intl.DateTimeFormat().resolvedOptions().timeZone;

    return format(utcToZonedTime(fromUnixTime(epoch), userTimezone), EIO_DATE_UI_FORMAT_PIPE_HISTORY);
  }
}
