import {animate, query, stagger, style, transition} from '@angular/animations';

export const LIST_ANIMATION = [
  transition('* => *', [
    query(
      ':enter',
      [
        style({opacity: 0, transform: 'translateY(10px)'}),
        stagger(100, [animate('0.5s', style({opacity: 1, transform: 'translateY(0)'}))])
      ],
      {optional: true}
    )
  ])
];

export const ITEM_ANIMATION = [
  transition(':enter', [style({opacity: 0}), animate('500ms ease-in', style({opacity: 1}))])
];
