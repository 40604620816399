import { Injectable } from '@angular/core';
import * as piexif from 'piexif-ts';
import { IExif, TagValues } from 'piexif-ts';
import { Subject } from 'rxjs';
// @ts-ignore
import { UpdatePhotoFromBlobHelper } from './updatePhotoFromBlob.helper';

@Injectable({
  providedIn: 'root'
})
export class ExifHelper {
  constructor(private updatePhotoFromBlobHelper: UpdatePhotoFromBlobHelper) {}

  adjustImageExifAfterScaleDown(
    srcImageFile: File,
    dstImageBase64: string,
    width: number,
    height: number
  ): Subject<Blob> {
    const subject: Subject<Blob> = new Subject<Blob>();
    let resultDstImageBase64 = dstImageBase64;
    const reader: FileReader = new FileReader();

    reader.onload = () => {
      let exifImg: IExif = null;
      try {
        exifImg = piexif.load(reader.result as string);
        if (exifImg && exifImg['0th']) {
          exifImg['0th'][TagValues.ImageIFD.Orientation] = 0;
          exifImg['0th'][TagValues.ImageIFD.ImageWidth] = width;
          exifImg['0th'][TagValues.ImageIFD.ImageLength] = height;
          try {
            resultDstImageBase64 = piexif.insert(piexif.dump(exifImg), dstImageBase64);
          } catch {}
        }
      } catch (e) {
        exifImg = null;
      }

      subject.next(this.updatePhotoFromBlobHelper.b64toBlob(resultDstImageBase64, srcImageFile.type));
      subject.complete();
    };

    reader.readAsDataURL(srcImageFile);

    return subject;
  }
}
