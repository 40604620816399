import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BreakpointObserverHelper {
  maxWidthDefault = 1024;
  minWidthDefault = 1200;
  constructor(private breakpointObserver: BreakpointObserver) {}

  observeMaxWidth(maxWidth?: number): Observable<boolean> {
    const breakPoint = `(max-width: ${maxWidth || this.maxWidthDefault}px)`;
    return this.breakpointObserver.observe([breakPoint]).pipe(map((result) => result.breakpoints[breakPoint]));
  }

  observeMinWidth(minWidth?: number): Observable<boolean> {
    const breakPoint = `(min-width: ${minWidth || this.minWidthDefault}px)`;
    return this.breakpointObserver.observe([breakPoint]).pipe(map((resolution) => resolution.breakpoints[breakPoint]));
  }
}
