import { Buckets } from '../model/dashoard.model';

export const QUOTING_BUCKET_DETAILS = (buckets: Buckets) => {
  return [
    {
      label: 'Request for Quote',
      bucketName: 'requestForQuote',
      bucketLength: buckets?.REQUEST_FOR_QUOTE?.length || 0
    },
    {
      label: 'More Information Required',
      bucketName: 'moreInfoRequired',
      bucketLength: buckets?.MORE_INFO_REQUIRED?.length || 0
    },
    {
      label: 'Pending Assessment',
      bucketName: 'pendingAssessment',
      bucketLength: buckets?.PENDING_ASSESSMENT?.length || 0
    },
    {
      label: 'Assessed Without Prejudice',
      bucketName: 'assessmentCompleted',
      bucketLength: buckets?.ASSESSMENT_COMPLETED?.length || 0
    }
  ];
};

export const WORK_AUTHORISED = (buckets: Buckets) => {
  return [
    {
      label: 'Authorised',
      bucketName: 'authorised',
      bucketLength: buckets?.AUTHORIZED?.length || 0
    },
    {
      label: 'Self-Authorised',
      bucketName: 'selfAuthorised',
      bucketLength: buckets?.PRE_AUTHORIZED?.length || 0
    },
    {
      label: 'Repairs Booked',
      bucketName: 'repairsBooked',
      bucketLength: buckets?.REPAIRS_BOOKED?.length || 0
    },
    {
      label: 'Pending Supplement Assessment',
      bucketName: 'pendingSupplementAssessment',
      bucketLength: buckets?.PENDING_SUPPLEMENT_ASSESSMENT?.length || 0
    },
    {
      label: 'Supplement Assessed Without Prejudice',
      bucketName: 'supplementAssessmentCompleted',
      bucketLength: buckets?.SUPPLEMENT_ASSESSMENT_COMPLETED?.length || 0
    },
    {
      label: 'More Information Supplement',
      bucketName: 'moreInformationSupplement',
      bucketLength: buckets?.MORE_INFO_SUPPLEMENT?.length || 0
    }
  ];
};

export const INVOICING = (buckets: Buckets) => {
  return [
    {
      label: 'Ready To Invoice',
      bucketName: 'readyToInvoice',
      bucketLength: buckets?.READY_TO_INVOICE?.length || 0
    },
    {
      label: 'Pending Invoice Approval',
      bucketName: 'pendingInvoiceApproval',
      bucketLength: buckets?.PENDING_INVOICE_APPROVAL?.length || 0
    },
    {
      label: 'Invoice Rejected',
      bucketName: 'invoiceRejected',
      bucketLength: buckets?.INVOICE_REJECTED?.length || 0
    },
    {
      label: 'Invoice Approved',
      bucketName: 'invoiceApproved',
      bucketLength: buckets?.INVOICE_APPROVED?.length || 0
    }
  ];
};
