import { CustomerFeedbackType } from '../types/customerFeedbackType.type';
import { GicopRecipientType } from '../types/gicopRecipient.type';

export interface CustomerFeedback {
  id?: number;
  jobId: number;
  createdTime?: number;
  date?: string;
  feedbackType: CustomerFeedbackType;
  raisedBy?: string;
  details?: string;
  actionToResolve?: string;
  submittedTime?: number;
  gicopNotificationType?: string;
}

export interface CustomerContact {
  id?: number;
  jobId: number;
  createdTime?: number;
  date?: string;
  time?: string;
  contactFrom?: GicopRecipient;
  contactTo?: GicopRecipient;
  contactMethodType?: string;
  isContactSuccessful: boolean;
  isIssueDiscussed: boolean;
  issueResolutionType?: string;
  additionalDetails?: string;
  issueDiscussionDetails?: string;
  gicopNotificationType?: string;
  submittedTime?: number;
}

export interface CustomerContact {
  id?: number;
  jobId: number;
  createdTime?: number;
  date?: string;
  time?: string;
  contactFrom?: GicopRecipient;
  contactTo?: GicopRecipient;
  contactMethodType?: string;
  isContactSuccessful: boolean;
  isIssueDiscussed: boolean;
  issueResolutionType?: string;
  additionalDetails?: string;
  issueDiscussionDetails?: string;
  gicopNotificationType?: string;
  submittedTime?: number;
}

export interface PotentialBreachOfCode {
  id?: number;
  jobId: number;
  raisedBy: string;
  dateOfIncident: string;
  dateDiscovered: string;
  partOfCode: string;
  codeOfPracticeParagraph: string;
  description: string;
  gicopNotificationType?: string;
}

export interface CustomerVulnerability {
  id?: number;
  jobId: number;
  createdTime?: number;
  customerVulnerability?: string;
  languagesSpoken?: string[];
  details?: string;
  contactPerson?: string;
  email?: string;
  phoneNumber: string;
  position: string;
  gicopNotificationType?: string;
  submittedTime?: number;
}

export interface Gicop {
  lastNotificationReceived: number;
  customerFeedbacks: CustomerFeedback[];
  customerContacts: CustomerContact[];
  customerVulnerabilities: CustomerVulnerability[];
  codeOfPracticeBreaches: PotentialBreachOfCode[];
  unsubmittedGicopNotification: {};
}

export interface GicopRecipient {
  type: GicopRecipientType;
  displayName: string;
}

export enum IssueResolutionEnum {
  RESOLVED = 'Resolved',
  UNRESOLVED = 'Unresolved'
}

export enum CustomerContactMethodEnum {
  EMAIL = 'E-mail',
  FACE_TO_FACE = 'Face to Face',
  FAX = 'FAX',
  INTERNET = 'Internet',
  LETTER = 'Letter',
  PHONE = 'Phone',
  SMS = 'SMS'
}

export const CUSTOMER_VULNERABILITY = [
  'Accessibility',
  'Additional Care',
  'Cognitive Care',
  'Communication Verbal',
  'Community Care',
  'Customer Speaks',
  'Other',
  'Payment Assistance',
  'Physical',
  'Physical Assistance',
  'Sensitive Case',
  'Social Assistance'
];

export enum PartOfCodeEnum {
  PART5 = 'Part 5 - Standards for our Service Suppliers',
  PART8 = 'Part 8 - Making a claim',
  PART9 = 'Part 9 - Supporting customers experiencing vulnerability',
  PART12 = 'Part 12 - Your access to information'
}
