import { ValidationErrors } from '@angular/forms';

export const getErrorMessage = (errors: ValidationErrors): string => {
  const [errorKey, errorValue] = Object.entries(errors).shift();

  switch (errorKey) {
    case 'required':
      // Will always be preceded by the field name
      return 'required';
    case 'pattern':
      return 'Invalid format';
    case 'matDatepickerParse':
      return 'Invalid date';
    case 'uniqueName':
      return 'Name already exists';
    case 'invalidABN':
      return 'Invalid ABN';
    case 'duplicateAbn':
      return 'ABN is already in use';
    case 'invalidPassword':
      return 'Invalid password';
  }
};
