import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EscapeDialogHelper {
  escapeDialog(dialog): void {
    dialog.keydownEvents().subscribe((event) => {
      if (event.key === 'Escape') {
        dialog.close();
      }
    });
  }
}
