import { DragDropModule } from '@angular/cdk/drag-drop';
import { TextFieldModule } from '@angular/cdk/text-field';
import { HTTP_INTERCEPTORS, HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MatMomentDateModule,
  MomentDateAdapter
} from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import {
  MAT_LEGACY_FORM_FIELD_DEFAULT_OPTIONS as MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatLegacyFormFieldModule as MatFormFieldModule
} from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import {
  CalendarDateFormatter,
  CalendarModule,
  CalendarMomentDateFormatter,
  DateAdapter as DateAdapter2,
  MOMENT
} from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import { DragAndDropModule } from 'angular-draggable-droppable';
import * as moment from 'moment';
import { environment } from '../environments/environment';
import { AcceptEulaComponent } from './accept-eula/accept-eula.component';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthInterceptor } from './auth/auth.interceptor';
import { AddressBlockComponent } from './components/address-block/address-block.component';
import { CarPlaceholderOrPhotoComponent } from './components/car-placeholder-or-photo/car-placeholder-or-photo.component';
import { ConfigureImportDirectoriesComponent } from './components/configure-import-directories/configure-import-directories.component';
import { SetDirectoryComponent } from './components/configure-import-directories/set-directory/set-directory.component';
import { ConfirmBoxComponent } from './components/confirm-box/confirm-box.component';
import { DatepickerRangeComponent } from './components/datepicker-range/datepicker-range.component';
import { DynamicDialogService } from './components/dynamic-dialog/dynamic-dialog-service';
import { GuidanceBoxComponent } from './components/guidance-box/guidance-box.component';
import { ImportFileFromDiskComponent } from './components/import-file-from-disk/import-file-from-disk.component';
import { InfoMessageComponent } from './components/info-message/info-message.component';
import { InfosCardComponent } from './components/infos-card/infos-card.component';
import { InfosGuidanceComponent } from './components/infos-guidance/infos-guidance.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { MessageComponent } from './components/messaging/message/message.component';
import { RefreshComponent } from './components/refresh/refresh.component';
import { RepairerSiteTableComponent } from './components/repairer-site-table/repairer-site-table.component';
import { StatusCellInfoComponent } from './components/repairer-site-table/status-cell-info/status-cell-info.component';
import { ICONS } from './consts/icons.const';
import { EIO_DATE_FORMATS_LONG } from './consts/localization.const';
import { ConfirmShareScreenComponent } from './dashboard/confirm-share-screen/confirm-share-screen.component';
import { ExcelService } from './dashboard/excel.service';
import { JobSearchComponent } from './dashboard/job-search/job-search.component';
import { AddDiaryComponent } from './dashboard/job-viewer/add-diary/add-diary.component';
import { AddInvoiceComponent } from './dashboard/job-viewer/add-invoice/add-invoice.component';
import { AddQuoteComponent } from './dashboard/job-viewer/add-quote/add-quote.component';
import { ExtractedVehicleDetailsComponent } from './dashboard/job-viewer/add-quote/extracted-vehicle-details/extracted-vehicle-details.component';
import { AddReturnNoQuoteComponent } from './dashboard/job-viewer/add-return-no-quote/add-return-no-quote.component';
import { AuthorityCurtainComponent } from './dashboard/job-viewer/authority-curtain/authority-curtain.component';
import { BookingComponent } from './dashboard/job-viewer/booking/booking.component';
import { ClaimCurtainComponent } from './dashboard/job-viewer/claim-curtain/claim-curtain.component';
import { DiaryCurtainComponent } from './dashboard/job-viewer/diary-curtain/diary-curtain.component';
import { DocumentsCurtainComponent } from './dashboard/job-viewer/documents-curtain/documents-curtain.component';
import { EditContactComponent } from './dashboard/job-viewer/edit-contact/edit-contact.component';
import { GalleryCurtainComponent } from './dashboard/job-viewer/gallery-curtain/gallery-curtain.component';
import { CustomerContactTableComponent } from './dashboard/job-viewer/gicop-notifications-curtain/customer-contact-table/customer-contact-table.component';
import { CustomerFeedbackTableComponent } from './dashboard/job-viewer/gicop-notifications-curtain/customer-feedback-table/customer-feedback-table.component';
import { CustomerVulnerabilityTableComponent } from './dashboard/job-viewer/gicop-notifications-curtain/customer-vulnerability-table/customer-vulnerability-table.component';
import { GicopNotificationsCurtainComponent } from './dashboard/job-viewer/gicop-notifications-curtain/gicop-notifications-curtain.component';
import { PotentialBreachCodeTableComponent } from './dashboard/job-viewer/gicop-notifications-curtain/potential-breach-code-table/potential-breach-code-table.component';
import { AddCustomerContactComponent } from './dashboard/job-viewer/gicop-notifications-modals/add-customer-contact/add-customer-contact.component';
import { AddCustomerFeedbackComponent } from './dashboard/job-viewer/gicop-notifications-modals/add-customer-feedback/add-customer-feedback.component';
import { AddCustomerVulnerabilityComponent } from './dashboard/job-viewer/gicop-notifications-modals/add-customer-vulnerability/add-customer-vulnerability.component';
import { AddPotentialBreachCodeComponent } from './dashboard/job-viewer/gicop-notifications-modals/add-potential-breach-code/add-potential-breach-code.component';
import { InvoiceCurtainComponent } from './dashboard/job-viewer/invoice-curtain/invoice-curtain.component';
import { UseEstimagePayModalComponent } from './dashboard/job-viewer/invoice-curtain/use-estimage-pay-modal/use-estimage-pay-modal.component';
import { JobHistoryComponent } from './dashboard/job-viewer/job-history/job-history.component';
import { JobTasksComponent } from './dashboard/job-viewer/job-tasks/job-tasks.component';
import { JobViewerComponent } from './dashboard/job-viewer/job-viewer.component';
import { LightboxComponent } from './dashboard/job-viewer/lightbox/lightbox.component';
import { PartialInvoiceComponent } from './dashboard/job-viewer/partial-invoice/partial-invoice.component';
import { PreAuthorityCurtainComponent } from './dashboard/job-viewer/pre-authority-curtain/pre-authority-curtain.component';
import { QuoteCurtainComponent } from './dashboard/job-viewer/quote-curtain/quote-curtain.component';
import { RemoveJobComponent } from './dashboard/job-viewer/remove-job/remove-job.component';
import { RepairTimelineBoxComponent } from './dashboard/job-viewer/repair-timeline-curtain/repair-timeline-box/repair-timeline-box.component';
import { RepairTimelineCurtainComponent } from './dashboard/job-viewer/repair-timeline-curtain/repair-timeline-curtain.component';
import { SelfAuthoriseComponent } from './dashboard/job-viewer/self-authorise/self-authorise.component';
import { SendToInscoTabsComponent } from './dashboard/job-viewer/send-to-insco-tabs/send-to-insco-tabs.component';
import { SubmitDocumentComponent } from './dashboard/job-viewer/submit-document/submit-document.component';
import { UploadFilesComponent } from './dashboard/job-viewer/upload-files/upload-files.component';
import { VehicleSelectionComponent } from './dashboard/job-viewer/vehicle-selection/vehicle-selection.component';
import { JobsReportsComponent } from './dashboard/jobsReports/jobs-reports.component';
import { AddUserComponent } from './dashboard/location-admin/add-user/add-user.component';
import { CompanyInformationComponent } from './dashboard/location-admin/company-information/company-information.component';
import { EditAddressComponent } from './dashboard/location-admin/edit-address/edit-address.component';
import { EditBankingDetailsComponent } from './dashboard/location-admin/edit-banking-details/edit-banking-details.component';
import { HistoryOfCompanyComponent } from './dashboard/location-admin/history-of-company/history-of-company.component';
import { LocationAdminComponent } from './dashboard/location-admin/location-admin.component';
import { PasswordConfirmationComponent } from './dashboard/location-admin/password-confirmation/password-confirmation.component';
import { UserManagementComponent } from './dashboard/location-admin/user-management/user-management.component';
import { PickupJobComponent } from './dashboard/pickup-job/pickup-job.component';
import { JobHistoryTabComponent } from './dashboard/repairer-dashboard/job-history-tab/job-history-tab.component';
import { JobCardComponent } from './dashboard/repairer-dashboard/repair-progress/job-card/job-card.component';
import { RepairProgressComponent } from './dashboard/repairer-dashboard/repair-progress/repair-progress.component';
import { RepairerBucketsComponent } from './dashboard/repairer-dashboard/repairer-buckets/repairer-buckets.component';
import { RepairerDashboardComponent } from './dashboard/repairer-dashboard/repairer-dashboard.component';
import { RepairerTileComponent } from './dashboard/repairer-dashboard/repairer-tile/repairer-tile.component';
import { TabHeadersComponent } from './dashboard/repairer-dashboard/tab-headers/tab-headers.component';
import { UpdateRepairTimelineComponent } from './dashboard/repairer-dashboard/update-repair-timeline/update-repair-timeline.component';
import { ShellComponent } from './dashboard/shell.component';
import { AccessRepairerSiteComponent } from './dashboard/sysadmin-dashboard/access-repairer-site/access-repairer-site.component';
import { AddFinancingCompanyComponent } from './dashboard/sysadmin-dashboard/add-financing-company/add-financing-company.component';
import { AddQuotingSystemComponent } from './dashboard/sysadmin-dashboard/add-quoting-system/add-quoting-system.component';
import { AddRepairerSiteComponent } from './dashboard/sysadmin-dashboard/add-repairer-site/add-repairer-site.component';
import { AdministratorsSectionComponent } from './dashboard/sysadmin-dashboard/administrators-section/administrators-section.component';
import { ChangeCompanyInformationComponent } from './dashboard/sysadmin-dashboard/change-company-information/change-company-information.component';
import { ChangeQuotingSystemComponent } from './dashboard/sysadmin-dashboard/change-quoting-system/change-quoting-system.component';
import { CobrowsingScreenComponent } from './dashboard/sysadmin-dashboard/cobrowsing-screen/cobrowsing-screen.component';
import { CobrowsingUserComponent } from './dashboard/sysadmin-dashboard/cobrowsing-screen/cobrowsing-user/cobrowsing-user.component';
import { ConfigureOAuthInterfaceComponent } from './dashboard/sysadmin-dashboard/configure-o-auth-interface/configure-o-auth-interface.component';
import { EditSysadminComponent } from './dashboard/sysadmin-dashboard/edit-sysadmin/edit-sysadmin.component';
import { FinancingCompaniesSectionComponent } from './dashboard/sysadmin-dashboard/financing-companies-section/financing-companies-section.component';
import { MultiLocationAdminSectionComponent } from './dashboard/sysadmin-dashboard/multi-location-admin-section/multi-location-admin-section.component';
import { QuotingSystemSectionComponent } from './dashboard/sysadmin-dashboard/quoting-system-section/quoting-system-section.component';
import { RepairerSitesComponent } from './dashboard/sysadmin-dashboard/repairer-sites/repairer-sites.component';
import { SetDashboardDayComponent } from './dashboard/sysadmin-dashboard/set-dashboard-day/set-dashboard-day.component';
import { SysadminDashboardComponent } from './dashboard/sysadmin-dashboard/sysadmin-dashboard.component';
import { ViewEulaComponent } from './dashboard/view-eula/view-eula.component';
import { AddressAutocompleteDirective } from './directives/address-autocomplete.directive';
import { BrowserAutoFillDisabledDirective } from './directives/browser-auto-fill-disabled.directive';
import { FormatInputNumberDirective } from './directives/format-input-number.directive';
import { HighlightStatusDirective } from './directives/highlight-status.directive';
import { MonthYearDateFormatDirective } from './directives/monthYearDateFormat.directives';
import { NumbersOnlyDirective } from './directives/numbers-only.directive';
import { RetrySrcDirective } from './directives/retry-src.directive';
import { TrimInputDirective } from './directives/trim-input.directive';
import { LoginComponent } from './login/login.component';
import { MonitorComponent } from './monitor/monitor.component';
import { BreakObserverPipe } from './pipes/breakObserver.pipe';
import { DateHistoryPipe } from './pipes/date.history.pipe';
import { DatePipe } from './pipes/date.pipe';
import { DaysSincePipe } from './pipes/days-since.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { GetControlPipe } from './pipes/get-control.pipe';
import { Highlight } from './pipes/highlight.pipe';
import { InitialsPipe } from './pipes/initials.pipe';
import { KebabMenuListPipe } from './pipes/kebabMenuList.pipe';
import { MomentDateTzPipe } from './pipes/momentDateTz.pipe';
import { ObjectDeepValuePipe } from './pipes/objectDeepValue.pipe';
import { OrderByDatePipe } from './pipes/orderByDate.pipe';
import { OrderByEpochTimePipe } from './pipes/orderByEpochTime.pipe';
import { SkateholdersInfoPipe } from './pipes/skateholders-info.pipe';
import { TimePipe } from './pipes/time.pipe';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SetPasswordComponent } from './set-password/set-password.component';
import { StaticFooterComponent } from './static-footer/static-footer.component';
import { EstImagePayInformationComponent } from './dashboard/location-admin/estimage-pay-information/estimage-pay-information.component';
import { SharedModule } from './shared.module';
import { HumanizePipe } from './pipes/humanize.pipe';
import { InfosActionCardComponent } from './components/infos-action-card/infos-action-card.component';
import { StatusChipComponent } from './components/status-chip/status-chip.component';
import { EstimagePayGuidanceComponent } from './dashboard/job-viewer/invoice-curtain/estimage-pay-guidance/estimage-pay-guidance.component';
import { FinanceCompanyGuidanceComponent } from './dashboard/job-viewer/invoice-curtain/finance-company-guidance/finance-company-guidance.component';
import { InfosContentComponent } from './components/infos-content/infos-content.component';

export function momentAdapterFactory() {
  return adapterFactory(moment);
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ShellComponent,
    MessageComponent,
    SysadminDashboardComponent,
    EditSysadminComponent,
    RefreshComponent,
    AddRepairerSiteComponent,
    SetPasswordComponent,
    DateHistoryPipe,
    LocationAdminComponent,
    RepairerDashboardComponent,
    JobViewerComponent,
    EditContactComponent,
    QuoteCurtainComponent,
    UploadFilesComponent,
    MonthYearDateFormatDirective,
    GalleryCurtainComponent,
    LightboxComponent,
    VehicleSelectionComponent,
    FilterPipe,
    OrderByEpochTimePipe,
    OrderByDatePipe,
    AuthorityCurtainComponent,
    SubmitDocumentComponent,
    ConfirmBoxComponent,
    JobSearchComponent,
    AddInvoiceComponent,
    Highlight,
    InvoiceCurtainComponent,
    AddFinancingCompanyComponent,
    EditAddressComponent,
    GetControlPipe,
    ResetPasswordComponent,
    AddDiaryComponent,
    EditBankingDetailsComponent,
    PasswordConfirmationComponent,
    AddReturnNoQuoteComponent,
    JobsReportsComponent,
    PartialInvoiceComponent,
    GuidanceBoxComponent,
    AddQuotingSystemComponent,
    ConfigureOAuthInterfaceComponent,
    ChangeQuotingSystemComponent,
    JobHistoryComponent,
    AcceptEulaComponent,
    ViewEulaComponent,
    RetrySrcDirective,
    PickupJobComponent,
    BookingComponent,
    StaticFooterComponent,
    DiaryCurtainComponent,
    PreAuthorityCurtainComponent,
    SelfAuthoriseComponent,
    SetDashboardDayComponent,
    MonitorComponent,
    UpdateRepairTimelineComponent,
    MomentDateTzPipe,
    DatepickerRangeComponent,
    AddCustomerFeedbackComponent,
    GicopNotificationsCurtainComponent,
    CustomerFeedbackTableComponent,
    AddCustomerContactComponent,
    AddPotentialBreachCodeComponent,
    CustomerContactTableComponent,
    PotentialBreachCodeTableComponent,
    AddCustomerVulnerabilityComponent,
    CustomerVulnerabilityTableComponent,
    RemoveJobComponent,
    CobrowsingScreenComponent,
    ConfirmShareScreenComponent,
    BrowserAutoFillDisabledDirective,
    RepairerTileComponent,
    DocumentsCurtainComponent,
    AddQuoteComponent,
    RepairProgressComponent,
    JobCardComponent,
    DaysSincePipe,
    InfoMessageComponent,
    SendToInscoTabsComponent,
    SkateholdersInfoPipe,
    RepairTimelineCurtainComponent,
    RepairTimelineBoxComponent,
    TabHeadersComponent,
    AdministratorsSectionComponent,
    QuotingSystemSectionComponent,
    FinancingCompaniesSectionComponent,
    ClaimCurtainComponent,
    RepairerSitesComponent,
    RepairerBucketsComponent,
    ClaimCurtainComponent,
    TabHeadersComponent,
    CompanyInformationComponent,
    UserManagementComponent,
    JobHistoryTabComponent,
    BreakObserverPipe,
    CarPlaceholderOrPhotoComponent,
    HighlightStatusDirective,
    ImportFileFromDiskComponent,
    ConfigureImportDirectoriesComponent,
    JobTasksComponent,
    SetDirectoryComponent,
    AddressBlockComponent,
    NumbersOnlyDirective,
    AddressAutocompleteDirective,
    ChangeCompanyInformationComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatIconModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDividerModule,
    MatButtonModule,
    MatSnackBarModule,
    HttpClientModule,
    MatCardModule,
    MatDialogModule,
    MatTabsModule,
    MatTableModule,
    MatSortModule,
    MatSelectModule,
    MatCheckboxModule,
    FormsModule,
    MatMenuModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatGridListModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    DragDropModule,
    MatTooltipModule,
    MatChipsModule,
    MatAutocompleteModule,
    TextFieldModule,
    MatRadioModule,
    HttpClientJsonpModule,
    GoogleMapsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    CalendarModule.forRoot(
      {
        provide: DateAdapter2,
        useFactory: momentAdapterFactory
      },
      {
        dateFormatter: {
          provide: CalendarDateFormatter,
          useClass: CalendarMomentDateFormatter
        }
      }
    ),
    DragAndDropModule,
    ExtractedVehicleDetailsComponent,
    MultiLocationAdminSectionComponent,
    DatePipe,
    TimePipe,
    ObjectDeepValuePipe,
    KebabMenuListPipe,
    StatusCellInfoComponent,
    RepairerSiteTableComponent,
    AddUserComponent,
    AccessRepairerSiteComponent,
    LoadingSpinnerComponent,
    HistoryOfCompanyComponent,
    InfosGuidanceComponent,
    InfosCardComponent,
    FormatInputNumberDirective,
    MatPaginatorModule,
    CobrowsingUserComponent,
    InitialsPipe,
    TrimInputDirective,
    EstImagePayInformationComponent,
    SharedModule,
    HumanizePipe,
    UseEstimagePayModalComponent,
    InfosActionCardComponent,
    StatusChipComponent,
    EstimagePayGuidanceComponent,
    FinanceCompanyGuidanceComponent,
    InfosContentComponent
  ],
  providers: [
    {
      provide: MOMENT,
      useValue: moment
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {
      provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
      useValue: { useUtc: false, strict: true }
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: EIO_DATE_FORMATS_LONG
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'en-AU'
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { floatLabel: 'always' }
    },
    ExcelService,
    DynamicDialogService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  customIcons: Array<{ iconName: string; iconPath: string }> = ICONS;

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.customIcons.forEach((icon: { iconName: string; iconPath: string }) => {
      this.matIconRegistry.addSvgIcon(icon.iconName, this.domSanitizer.bypassSecurityTrustResourceUrl(icon.iconPath));
    });
  }
}
