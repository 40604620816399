import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { inject, Injectable, signal, WritableSignal } from '@angular/core';
import { SortDirection } from '@angular/material/sort';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { MigrationStatus } from '../enums/migrationStatus.enum';
import { AddInvoice } from '../model/add-invoice.model';
import { ClaimListSearchFilter } from '../model/claim-list-search-filter.model';
import { DashboardSortOrderBuckets } from '../model/dashboard-sort-order.model';
import { Dashboard } from '../model/dashoard.model';
import { LabourItem, PartItem, Quote, SubletItem } from '../model/document.model';
import { DisbursementAccount } from '../model/estimage-pay.model';
import { FinanceCompany } from '../model/financing-company.model';
import { Insurer, InsurerPickUp } from '../model/insurer.model';
import { Diary, Job } from '../model/job.model';
import { DashboardHistory, JobHistory } from '../model/jobHistory.model';
import { JobSearchResult } from '../model/jobSearchResult.model';
import { PickUpFormRequest, PickUpFormResponse } from '../model/pickUpFormRequest.model';
import { RepairTimelineDates } from '../model/repairTimelineDates.model';
import { ReportSummary } from '../model/report/reportSummary.model';
import { OwnerContactUpdate, RepairerGroup, RepairerSiteUpdate, User, UserRepairerSite } from '../model/user.model';
import { VehicleDetails } from '../model/vehicle-details.model';
import { BucketSortName, DashboardSortOrderType } from '../types/dashboardSortOrder.type';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  isAlreadyCompleted = new BehaviorSubject<boolean>(true);
  repairersGroup = new BehaviorSubject<RepairerGroup[]>(null);
  dashboardSortOrder: WritableSignal<DashboardSortOrderBuckets> = signal({
    requestForQuoteSortOrder: 'LAST_RECEIVED_DATETIME_ASC',
    pendingAssessmentSortOrder: 'LAST_RECEIVED_DATETIME_ASC'
  });

  private http = inject(HttpClient);

  private static handleError(error: any) {
    return Promise.reject(error.error || error.details || error);
  }

  migrationCompletionStatus() {
    return this.isAlreadyCompleted
      .asObservable()
      .pipe(switchMap((migrated) => (migrated ? of(migrated) : this.getMigrationStatus())));
  }

  getCurrentUser() {
    return this.http.get<User>('ui/mainService/loggedInUser').pipe(catchError(UserService.handleError));
  }

  getRepairerSiteForUser() {
    return this.http.get<UserRepairerSite>('ui/mainService/repairerSiteForUser').pipe(
      tap((repairerSite) =>
        this.isAlreadyCompleted.next(
          repairerSite.migrationStatus === MigrationStatus.COMPLETED ||
            repairerSite.migrationStatus === MigrationStatus.NOT_STARTED
        )
      ),
      catchError(UserService.handleError)
    );
  }

  getRepairerGroup() {
    return this.http.get<RepairerGroup[]>('ui/mainService/repairerGroups').pipe(
      tap((groups) => this.repairersGroup.next([{ id: null, name: '' }, ...groups])),
      catchError(UserService.handleError)
    );
  }

  getJobs(tab: string) {
    return this.http.get<Dashboard>(`ui/mainService/dashboard?tab=${tab}`).pipe(catchError(UserService.handleError));
  }

  getJob(jobId: number) {
    return this.http.get<Job>(`ui/mainService/job/${jobId}`).pipe(catchError(UserService.handleError));
  }

  updateJob(update: string, id: number, fieldName: string) {
    return this.http
      .post(`ui/mainService/update/job/${id}/${fieldName}`, JSON.stringify(update))
      .pipe(catchError(UserService.handleError));
  }

  toggleJobReadStatus(isUnread: boolean, id: number) {
    return this.http
      .post(`ui/mainService/job/${isUnread ? 'markRead' : 'markUnread'}/${id}`, null)
      .pipe(catchError(UserService.handleError));
  }

  updateOwnerContact(id: number, ownerContactData: OwnerContactUpdate) {
    return this.http
      .post(`ui/mainService/update/jobOwner/${id}`, ownerContactData)
      .pipe(catchError(UserService.handleError));
  }

  submitJob(id: number) {
    return this.http.post(`ui/mainService/job/submit/${id}`, null).pipe(catchError(UserService.handleError));
  }

  updateQuote(update: string, id: number, fieldName: string) {
    return this.http
      .post(`ui/mainService/update/quote/${id}/${fieldName}`, JSON.stringify(update))
      .pipe(catchError(UserService.handleError));
  }

  uploadJobFile(jobId: number, file: FormData, path: string) {
    const options = { headers: new HttpHeaders({ enctype: 'multipart/form-data' }) };
    return this.http
      .post(`ui/mainService/${path}?jobId=${jobId}`, file, options)
      .pipe(catchError(UserService.handleError));
  }

  importQuote(jobId: number, quote: FormData, importVehicleInfo: boolean) {
    const options = {
      headers: new HttpHeaders({ enctype: 'multipart/form-data' }),
      params: new HttpParams().set('importVehicle', importVehicleInfo)
    };
    return this.http
      .post(`ui/mainService/jobs/${jobId}/importQuote`, quote, options)
      .pipe(catchError(UserService.handleError));
  }

  importInvoice(jobId: number, invoiceNumber: string, invoice: FormData) {
    const options = { headers: new HttpHeaders({ enctype: 'multipart/form-data' }) };
    return this.http
      .post(`ui/mainService/jobs/${jobId}/importInvoice?invoiceNumber=${invoiceNumber}`, invoice, options)
      .pipe(catchError(UserService.handleError));
  }

  extractVehicleInfoFromQuote(jobId: number, quote: FormData) {
    const options = { headers: new HttpHeaders({ enctype: 'multipart/form-data' }) };
    return this.http
      .post<VehicleDetails>(`ui/mainService/jobs/${jobId}/extractVehicleInfo`, quote, options)
      .pipe(catchError(UserService.handleError));
  }

  getJobHistory(count: number): Observable<HttpResponse<DashboardHistory>> {
    return this.http
      .get<DashboardHistory>(`ui/mainService/jobHistory?count=${count}`, { observe: 'response' })
      .pipe(catchError(UserService.handleError));
  }

  getJobHistoryForJob(jobId: number) {
    return this.http.get<JobHistory[]>(`ui/mainService/jobHistory/${jobId}`).pipe(catchError(UserService.handleError));
  }

  deleteQuote(id: number) {
    return this.http.post(`ui/mainService/deleteQuote/${id}`, null).pipe(catchError(UserService.handleError));
  }

  deleteJobFile(id: number, jobId: number, path: string) {
    return this.http.post(`ui/mainService/jobs/${jobId}/${path}/${id}`, null).pipe(catchError(UserService.handleError));
  }

  deleteUnsubmittedPhotos(jobId: number) {
    return this.http
      .post(`ui/mainService/deleteUnsubmittedPhotos/${jobId}`, null)
      .pipe(catchError(UserService.handleError));
  }

  searchJobs(query: string): Observable<HttpResponse<JobSearchResult[]>> {
    return this.http
      .get<JobSearchResult[]>(`ui/mainService/searchJobs?query=${query}`, { observe: 'response' })
      .pipe(catchError(UserService.handleError));
  }

  addInvoice(addInvoice: AddInvoice, jobId: number) {
    return this.http
      .post(`ui/mainService/jobs/${jobId}/addInvoice`, addInvoice)
      .pipe(catchError(UserService.handleError));
  }

  addManualQuote(quoteNumber: string, jobId: number, importLastQuote: boolean) {
    return this.http
      .post(
        `ui/mainService/addManualQuote/${jobId}?quoteNumber=${quoteNumber}&importLastQuote=${importLastQuote}`,
        null
      )
      .pipe(catchError(UserService.handleError));
  }

  updateInvoice(update: string | boolean, id: number, fieldName: string) {
    return this.http
      .post(`ui/mainService/update/invoice/${id}/${fieldName}`, JSON.stringify(update))
      .pipe(catchError(UserService.handleError));
  }

  fetchMissingInvoicePDF(invoiceId: number, url: string) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', responseType: 'blob' });
    const responseType = 'blob' as 'json';
    return this.http.put(`ui/mainService/fetchMissingInvoicePDF/${invoiceId}`, null, { headers, responseType }).pipe(
      map(() => {
        window.open(url, '_blank');
      }),
      catchError(UserService.handleError)
    );
  }

  cancelInvoice(id: number) {
    return this.http.put(`ui/mainService/cancelInvoice/${id}`, null).pipe(catchError(UserService.handleError));
  }

  updateInvoicePart(update: string, id: number) {
    return this.http
      .post(`ui/mainService/update/invoicePart/${id}`, JSON.stringify(update))
      .pipe(catchError(UserService.handleError));
  }

  updateInvoiceSublet(update: string, id: number) {
    return this.http
      .post(`ui/mainService/update/invoiceSublet/${id}`, JSON.stringify(update))
      .pipe(catchError(UserService.handleError));
  }

  updateInvoiceLabour(update: string, id: number) {
    return this.http
      .post(`ui/mainService/update/invoiceLabour/${id}`, JSON.stringify(update))
      .pipe(catchError(UserService.handleError));
  }

  markDiaryAsRead(id: number) {
    return this.http.post(`ui/mainService/diary/markRead/${id}`, null).pipe(catchError(UserService.handleError));
  }

  addDiary(diary: Diary) {
    return this.http
      .post(`ui/mainService/jobs/${diary.jobId}/diaries`, diary)
      .pipe(catchError(UserService.handleError));
  }

  updateDiary(jobId: number, diary: Diary) {
    return this.http
      .put(`/ui/mainService/jobs/${jobId}/diaries/${diary.id}`, diary)
      .pipe(catchError(UserService.handleError));
  }

  deleteDiary(jobId: number, diaryId: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http
      .delete(`ui/mainService/jobs/${jobId}/diaries/${diaryId}`, httpOptions)
      .pipe(catchError(UserService.handleError));
  }

  getSystemProperty() {
    return this.http.get<string>(`ui/mainService/googleApiKey`).pipe(catchError(UserService.handleError));
  }

  updateRepairerSiteWithAddress(repairerId: number, repairerSiteUpdate: RepairerSiteUpdate) {
    return this.http
      .put(`ui/mainService/updateCore/repairerSites/${repairerId}`, repairerSiteUpdate)
      .pipe(catchError(UserService.handleError));
  }

  getReports(from: number, to: number, reportType: string, insurerIds: number[]) {
    return this.http
      .get<ReportSummary>(
        `ui/mainService/reports?from=${from}&to=${to}&reportType=${reportType}&insurerIds=${insurerIds}`
      )
      .pipe(catchError(UserService.handleError));
  }

  getInsurers() {
    return this.http.get<Insurer[]>(`ui/mainService/insurers`).pipe(catchError(UserService.handleError));
  }

  getInsurersForPickUp() {
    return this.http
      .get<InsurerPickUp[]>(`ui/mainService/insurers/enableRepairerPickUp`)
      .pipe(catchError(UserService.handleError));
  }

  deleteUnsubmittedInvoice(invoiceId: number, jobId: number) {
    return this.http
      .post(`ui/mainService/jobs/${jobId}/deleteInvoice/${invoiceId}`, null)
      .pipe(catchError(UserService.handleError));
  }

  getNextAvailableInspectionDates(insurerId: number) {
    return this.http
      .get<number[]>(`ui/mainService/insurers/${insurerId}/nextAvailableInspectionDates`)
      .pipe(catchError(UserService.handleError));
  }

  createPendingAssessment(insurerId: number, pickUpFormRequest: PickUpFormRequest) {
    return this.http
      .post<PickUpFormResponse>(`ui/mainService/insurers/${insurerId}/createPendingAssessment`, pickUpFormRequest)
      .pipe(catchError(UserService.handleError));
  }

  updateLoggedUserTimezone(timezoneId: string) {
    return this.http
      .put('ui/mainService/loggedInUser/timezone', JSON.stringify(timezoneId))
      .pipe(catchError(UserService.handleError));
  }

  updateRepairDates(timelineDates: RepairTimelineDates, jobId: number) {
    return this.http
      .post(`ui/mainService/updateRepairDates/job/${jobId}`, timelineDates)
      .pipe(catchError(UserService.handleError));
  }

  moveToNextRepairState(jobId: number) {
    return this.http
      .post(`ui/mainService/moveToNextRepairState/job/${jobId}`, null)
      .pipe(catchError(UserService.handleError));
  }

  moveToPreviousRepairState(jobId: number) {
    return this.http
      .post(`ui/mainService/moveToPreviousRepairState/job/${jobId}`, null)
      .pipe(catchError(UserService.handleError));
  }

  moveToNextRepairStateWithDates(timelineDates: RepairTimelineDates, jobId: number) {
    return this.http
      .post(`ui/mainService/moveToNextRepairState/job/${jobId}`, timelineDates)
      .pipe(catchError(UserService.handleError));
  }

  updateNotification(jobId: number, typeOfNotification: string, notificationObject: any) {
    return this.http
      .put(`/ui/mainService/jobs/${jobId}/${typeOfNotification}/${notificationObject.id}`, notificationObject)
      .pipe(catchError(UserService.handleError));
  }

  deleteNotification(jobId: number, typeOfNotification: string, notificationId: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http
      .delete(`ui/mainService/jobs/${jobId}/${typeOfNotification}/${notificationId}`, httpOptions)
      .pipe(catchError(UserService.handleError));
  }

  addNotification(typeOfNotification: string, notificationObject: any) {
    return this.http
      .post(`ui/mainService/jobs/${notificationObject.jobId}/${typeOfNotification}`, notificationObject)
      .pipe(catchError(UserService.handleError));
  }

  hideFromDashboard(jobId: number, dashboardContext = false) {
    return this.http
      .put(`ui/mainService/jobs/${jobId}/hideFromDashboard?dashboardContext=${dashboardContext}`, null)
      .pipe(catchError(UserService.handleError));
  }

  selectTopImage(jobId: number, topImageId: number) {
    return this.http
      .post(`ui/mainService/job/${jobId}/selectTopImage/${topImageId}`, null)
      .pipe(catchError(UserService.handleError));
  }

  getReportRepairer(exportUrl: string) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', responseType: 'blob' });
    const responseType = 'blob' as 'json';
    const url = `ui/mainService/${exportUrl}`;
    return this.http.get<Blob>(url, { headers, responseType }).pipe(
      map(() => {
        // Second call will retrieve the pdf from the cache
        window.open(url, '_blank');
      }),
      catchError(UserService.handleError)
    );
  }

  getFinancingCompaniesByCountry() {
    return this.http
      .get<FinanceCompany[]>('ui/mainService/factoryCompaniesByCountry')
      .pipe(catchError(UserService.handleError));
  }

  getConfig() {
    return this.http.get('ui/mainService/config').pipe(catchError(UserService.handleError));
  }

  getdocumentFeatureStatus() {
    return this.http.get<boolean>(`ui/mainService/documentFeatureEnabled`).pipe(catchError(UserService.handleError));
  }

  setEstimatedRate(quoteId: number, labourType: string, rate: number) {
    return this.http
      .post<Quote>(`ui/mainService/estimatedRate/${quoteId}/${labourType}`, JSON.stringify(rate))
      .pipe(catchError(UserService.handleError));
  }

  addOrEditLabourItem(quoteId: number, labourType: string, labourItem: LabourItem) {
    return this.http
      .post<Quote>(`ui/mainService/labourItem/${quoteId}/${labourType}`, labourItem)
      .pipe(catchError(UserService.handleError));
  }

  addOrEditPartOrSubletItem(quoteId: number, section: string, sectionItem: PartItem | SubletItem) {
    return this.http
      .post<Quote>(`ui/mainService/${section}/${quoteId}`, sectionItem)
      .pipe(catchError(UserService.handleError));
  }

  deleteItem(itemId: number, itemType: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http
      .delete<Quote>(`ui/mainService/${itemType}/${itemId}`, httpOptions)
      .pipe(catchError(UserService.handleError));
  }

  getMigrationStatus() {
    return this.http.get<boolean>(`ui/mainService/migrationCompleted`).pipe(catchError(UserService.handleError));
  }

  updateDashboardOrder(sortName: BucketSortName, sortOrder: DashboardSortOrderType) {
    return this.http.post<null>(`ui/mainService/dashboard/${sortName}/${sortOrder}`, null).pipe(
      tap(() => this.dashboardSortOrder.mutate((dashboardSort) => (dashboardSort[sortName] = sortOrder))),
      catchError(UserService.handleError)
    );
  }

  getClaimListSearchFilter() {
    return this.http
      .get<ClaimListSearchFilter>(`ui/mainService/claimListSearchFilter`)
      .pipe(catchError(UserService.handleError));
  }

  updateClaimListSortOrder(sortColumn: string, sortDirection: SortDirection) {
    return this.http
      .put<null>(`ui/mainService/updateClaimListSorOrder/${sortColumn}/${sortDirection}`, null)
      .pipe(catchError(UserService.handleError));
  }

  chooseCompanyInformation(action: string, invoiceId: number) {
    return this.http.put<null>(`ui/mainService/${action}/${invoiceId}`, null).pipe(catchError(UserService.handleError));
  }

  getDisbursementAccounts() {
    return this.http
      .get<DisbursementAccount[]>('ui/mainService/disbursementAccounts')
      .pipe(catchError(UserService.handleError));
  }

  useEstimagePayForInvoice(invoiceId: number, accountId: string) {
    return this.http
      .post<void>(`ui/mainService/useEstimagePayForInvoice/${invoiceId}/${accountId}`, null)
      .pipe(catchError(UserService.handleError));
  }

  removeEstimagePayForInvoice(invoiceId: number) {
    return this.http
      .post<void>(`ui/mainService/removeEstimagePayFromInvoice/${invoiceId}`, null)
      .pipe(catchError(UserService.handleError));
  }
}
