import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
  inject,
  DestroyRef
} from '@angular/core';
import { UserRepairerSite } from '../../../../model/user.model';
import { CurrentUserService } from '../../../../services/currentUser.service';
import { Job } from '../../../../model/job.model';
import { UserService } from '../../../../services/user.service';
import { MessagingService } from '../../../../components/messaging/messaging.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import * as moment from 'moment';
import { ToolbarService } from '../../../../services/toolbar.service';
import { filter } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { EIO_DATE_UI_FORMAT_PIPE } from '../../../../consts/localization.const';
import { ModalConfigsHelper } from '../../../../helpers/modal-configs.helper';
import { ConfirmBoxComponent } from '../../../../components/confirm-box/confirm-box.component';
import { smallModalConfig } from '../../../../consts/modal-config.const';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { JobService } from '../../../../services/job.service';

@Component({
  selector: 'app-job-card',
  templateUrl: './job-card.component.html',
  styleUrls: ['./job-card.component.scss'],
  providers: [DatePipe]
})
export class JobCardComponent implements OnInit, AfterViewInit, OnChanges {
  timezone: string;
  dateInBucket: string;
  @Input() job: Job;
  @Input() id: number;
  @Input() repairerSite: UserRepairerSite;
  @Input() bucket: string;
  @Output() updateBuckets: EventEmitter<boolean> = new EventEmitter();
  photoLoaded = {};
  activeJobId: number;
  largeNumberDays = false;
  relevantDate = '';
  nextButtonpending = false;
  previousButtonpending = false;

  private cdr = inject(ChangeDetectorRef);
  private destroyRef = inject(DestroyRef);
  private elRef = inject(ElementRef);
  private matDialog = inject(MatDialog);
  private currentUserService = inject(CurrentUserService);
  private jobService = inject(JobService);
  private message = inject(MessagingService);
  private toolbarService = inject(ToolbarService);
  private userService = inject(UserService);
  private datePipe = inject(DatePipe);
  private modalConfigsHelper = inject(ModalConfigsHelper);

  ngOnInit(): void {
    this.timezone = this.currentUserService?.timeZone;
    this.setDateInBucket();
    this.getActiveJobId();
  }

  ngOnChanges() {
    this.previousButtonpending = false;
    this.nextButtonpending = false;
  }

  ngAfterViewInit() {
    if (this.activeJobId === this.job.jobId) {
      setTimeout(() => {
        this.elRef.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
      }, 200);
    }
  }

  setTextAnimation(parent: any, child: any) {
    return child.offsetWidth > parent.offsetWidth;
  }

  private getActiveJobId() {
    this.jobService.activeJobId$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((activeJobId) => {
      this.activeJobId = activeJobId;
    });
  }

  moveToNextBucket() {
    this.nextButtonpending = true;
    this.userService
      .moveToNextRepairState(this.job.jobId)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        error: () => {
          this.message.error(`Could not move job to next bucket.`);
          this.nextButtonpending = false;
          this.cdr.detectChanges();
        },
        complete: () => {
          this.saveActiveJob(this.job.jobId);
          this.updateBuckets.emit(true);
        }
      });
  }

  moveToPreviousBucket() {
    this.previousButtonpending = true;
    this.userService
      .moveToPreviousRepairState(this.job.jobId)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.saveActiveJob(this.job.jobId);
          this.updateBuckets.emit(true);
        },
        error: () => {
          this.message.error(`Could not move job to previous bucket.`);
          this.previousButtonpending = false;
          this.cdr.detectChanges();
        }
      });
  }

  setDateInBucket() {
    switch (this.bucket) {
      case 'REPAIR_BOOKED':
        this.dateInBucket = this.job.vehicleDateIn;
        this.relevantDate = `Vehicle Scheduled In For: ${this.datePipe.transform(
          this.job.vehicleDateIn,
          EIO_DATE_UI_FORMAT_PIPE
        )}`;
        break;
      case 'VEHICLE_IN':
        this.dateInBucket = this.job.vehicleDateIn;
        this.relevantDate = `Vehicle In: ${this.datePipe.transform(this.job.vehicleDateIn, EIO_DATE_UI_FORMAT_PIPE)}`;
        break;
      case 'REPAIR_STARTED':
        this.dateInBucket = this.job.repairStartedDate;
        this.relevantDate = `Repair Started On: ${this.datePipe.transform(
          this.job.repairStartedDate,
          EIO_DATE_UI_FORMAT_PIPE
        )}`;
        break;
      case 'REPAIR_COMPLETED':
        this.dateInBucket = this.job.repairCompletedDate;
        this.relevantDate = `Repair Completed On: ${this.datePipe.transform(
          this.job.repairCompletedDate,
          EIO_DATE_UI_FORMAT_PIPE
        )}`;
        break;
      case 'VEHICLE_OUT':
        this.dateInBucket = this.job.vehicleDateOut;
        this.relevantDate = `Vehicle Out: ${this.datePipe.transform(this.job.vehicleDateOut, EIO_DATE_UI_FORMAT_PIPE)}`;
        break;
    }

    const dateEntered = this.timezone
      ? moment(this.dateInBucket).tz(this.timezone).valueOf()
      : moment(this.timezone).valueOf();
    const today = moment(Date.now()).unix();
    const diff = today - dateEntered;
    const days = Math.floor(diff / (3600 * 24));
    this.largeNumberDays = days > 99;
  }

  saveActiveJob(jobId: number) {
    this.jobService.activeJobId = jobId;
    localStorage.setItem('previousUrl', 'jobViewer');
  }

  archiveJob() {
    const data = {
      title: 'Archive',
      alertMessage: 'Are you sure you want to archive this claim?',
      confirmButton: 'ARCHIVE',
      hideCannotBeUndone: true
    };
    const dialogRef = this.matDialog.open(
      ConfirmBoxComponent,
      this.modalConfigsHelper.buildMediumModalConfig(data, smallModalConfig)
    );
    dialogRef
      .afterClosed()
      .pipe(filter((value) => value === 'ARCHIVE'))
      .subscribe({
        next: () => {
          this.toolbarService.setCloudState('SAVING');
          this.userService
            .hideFromDashboard(this.job.jobId, true)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
              next: () => {
                this.message.confirm('Job archived');
                this.toolbarService.setCloudState('RESTING');
                this.updateBuckets.emit(true);
              },
              error: () => {
                this.message.error('Could not archived the job.');
                this.toolbarService.setCloudState('RESTING');
              }
            });
        }
      });
  }

  toggleJobReadStatus(isUnread: boolean, id: number): void {
    this.userService.toggleJobReadStatus(isUnread, id).subscribe(() => this.updateBuckets.emit(true));
  }
}
