import { Bucket, Dashboard } from '../model/dashoard.model';

export const QUOTING_BUCKETS = (jobs: Dashboard): Bucket[] => {
  return [
    {
      title: 'Request for Quote',
      id: 'requestForQuote',
      jobBuckets: jobs?.buckets?.REQUEST_FOR_QUOTE,
      hasAutoArchiveMenu: false,
      showSeparator: true,
      bucketName: 'REQUEST_FOR_QUOTE',
      photoLoaded: {},
      errormessage: 'New Requests for Quote received from EstImage insurers will be shown here.'
    },
    {
      title: 'More Information Required',
      id: 'moreInfoRequired',
      jobBuckets: jobs?.buckets?.MORE_INFO_REQUIRED,
      hasAutoArchiveMenu: false,
      showSeparator: true,
      bucketName: 'MORE_INFO_REQUIRED',
      photoLoaded: {},
      errormessage:
        'When an assessor or insurer requires more information about an original quote, the job will be shown here.'
    },
    {
      title: 'Pending Assessment',
      id: 'pendingAssessment',
      jobBuckets: jobs?.buckets?.PENDING_ASSESSMENT,
      hasAutoArchiveMenu: true,
      showSeparator: true,
      bucketName: 'PENDING_ASSESSMENT',
      photoLoaded: {},
      errormessage: 'Jobs for which you have submitted an original quote will be shown here until assessed.'
    },
    {
      title: 'Assessed Without Prejudice',
      id: 'assessmentCompleted',
      jobBuckets: jobs?.buckets?.ASSESSMENT_COMPLETED,
      hasAutoArchiveMenu: true,
      showSeparator: false,
      bucketName: 'ASSESSMENT_COMPLETED',
      photoLoaded: {},
      errormessage: 'Jobs that are assessed without prejudice will be shown here.'
    }
  ];
};

export const WORK_AUTHORISED_BUCKETS = (jobs: Dashboard): Bucket[] => {
  return [
    {
      title: 'Authorised',
      id: 'authorised',
      jobBuckets: jobs?.buckets?.AUTHORIZED,
      hasAutoArchiveMenu: false,
      showSeparator: true,
      bucketName: 'AUTHORIZED',
      photoLoaded: {},
      errormessage: 'When a job is Authorised by the insurer it will be available here.'
    },
    {
      title: 'Self-Authorised',
      id: 'selfAuthorised',
      jobBuckets: jobs?.buckets?.PRE_AUTHORIZED,
      hasAutoArchiveMenu: false,
      showSeparator: true,
      bucketName: 'PRE_AUTHORIZED',
      photoLoaded: {},
      errormessage: 'Jobs that are self-authorised will be shown here until you submit the original quote.'
    },
    {
      title: 'Repairs Booked',
      id: 'repairsBooked',
      jobBuckets: jobs?.buckets?.REPAIRS_BOOKED,
      hasAutoArchiveMenu: false,
      showSeparator: true,
      photoLoaded: {},
      errormessage:
        'Jobs for which the customer has been contacted to book repairs will appear here until the vehicle comes in.'
    },
    {
      title: 'Pending Supplement Assessment',
      id: 'pendingSupplementAssessment',
      jobBuckets: jobs?.buckets?.PENDING_SUPPLEMENT_ASSESSMENT,
      hasAutoArchiveMenu: false,
      showSeparator: true,
      photoLoaded: {},
      bucketName: 'PENDING_SUPPLEMENT_ASSESSMENT',
      errormessage: 'Jobs for which you have submitted a supplement will be shown here until assessed/authorised.'
    },
    {
      title: 'Supplement Assessed Without Prejudice',
      id: 'supplementAssessmentCompleted',
      jobBuckets: jobs?.buckets?.SUPPLEMENT_ASSESSMENT_COMPLETED,
      hasAutoArchiveMenu: true,
      showSeparator: true,
      bucketName: 'SUPPLEMENT_ASSESSMENT_COMPLETED',
      photoLoaded: {},
      errormessage: 'Jobs with supplements that are assessed without prejudice will appear here.'
    },
    {
      title: 'More Information Supplement',
      id: 'moreInformationSupplement',
      jobBuckets: jobs?.buckets?.MORE_INFO_SUPPLEMENT,
      hasAutoArchiveMenu: false,
      showSeparator: false,
      bucketName: 'MORE_INFO_SUPPLEMENT',
      photoLoaded: {},
      errormessage:
        'When an assessor or insurer requires more information about a supplement quote, the job will be shown here.'
    }
  ];
};

export const INVOICING_BUCKETS = (jobs: Dashboard): Bucket[] => {
  return [
    {
      title: 'Ready To Invoice',
      id: 'readyToInvoice',
      jobBuckets: jobs?.buckets?.READY_TO_INVOICE,
      hasAutoArchiveMenu: false,
      bucketName: 'READY_TO_INVOICE',
      showSeparator: true,
      photoLoaded: {},
      errormessage: 'Jobs that are ready to invoice will appear here until an invoice has been submitted.'
    },
    {
      title: 'Pending Invoice Approval',
      id: 'pendingInvoiceApproval',
      jobBuckets: jobs?.buckets?.PENDING_INVOICE_APPROVAL,
      hasAutoArchiveMenu: false,
      showSeparator: true,
      bucketName: 'PENDING_INVOICE_APPROVAL',
      photoLoaded: {},
      errormessage: 'Invoices you have submitted will be shown here while they are under review.'
    },
    {
      title: 'Invoice Rejected',
      id: 'invoiceRejected',
      jobBuckets: jobs?.buckets?.INVOICE_REJECTED,
      hasAutoArchiveMenu: false,
      showSeparator: true,
      bucketName: 'INVOICE_REJECTED',
      photoLoaded: {},
      errormessage: 'If an invoice is rejected for a job, it will be shown here.'
    },
    {
      title: 'Invoice Approved',
      id: 'invoiceApproved',
      jobBuckets: jobs?.buckets?.INVOICE_APPROVED,
      hasAutoArchiveMenu: true,
      showSeparator: false,
      bucketName: 'INVOICE_APPROVED',
      photoLoaded: {},
      errormessage: 'Jobs for which the latest invoice has been approved will show here.'
    }
  ];
};
