import { NgTemplateOutlet } from '@angular/common';
import { booleanAttribute, ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'infos-guidance',
  standalone: true,
  imports: [MatIconModule, NgTemplateOutlet],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="flex mx-20 items-center justify-center my-10">
      <mat-icon class="mr-10" [color]="warningGuidance ? 'primary' : 'accent'">
        {{ warningGuidance ? 'warning' : 'info_outlined' }}
      </mat-icon>
      <ng-container [ngTemplateOutlet]="guidanceTemplate" />
    </div>
  `
})
export class InfosGuidanceComponent {
  @Input({ required: true }) guidanceTemplate: TemplateRef<any>;
  @Input({ transform: booleanAttribute }) warningGuidance = false;
}
