<div class="upload-photos-box">
  <div class="cloud-icon-holder">
    <mat-icon>cloud_upload</mat-icon>
  </div>
  <h6 class="title">
    Uploading {{ data.isQuote ? 'Quote' : data.isInvoice ? 'Invoice' : data.isPhotos ? 'Photos' : 'Documents' }}
  </h6>
  <ng-container *ngIf="!wrongFormatMessage && !maxSizeExceedMessage">
    <mat-progress-bar class="progress-bar" mode="determinate" value="{{ progress }}"></mat-progress-bar>
    <div class="photo-counter body-2">uploading {{ uploadedFiles }}/{{ totalFiles }}</div>
  </ng-container>

  <div *ngIf="wrongFormatMessage || maxSizeExceedMessage" class="error-message-box">
    <div class="warn-box">
      <mat-icon matPrefix>report_problem</mat-icon>
      <div class="warn-box-max-size-exceed-msg">{{ maxSizeExceedMessage }}</div>
      <div>{{ wrongFormatMessage }}</div>
    </div>
  </div>

  <mat-divider></mat-divider>
  <button
    (click)="!wrongFormatMessage && !maxSizeExceedMessage && stopUpload()"
    class="upload-button"
    mat-button
    mat-dialog-close>
    {{ wrongFormatMessage || maxSizeExceedMessage ? 'OK' : 'STOP UPLOAD' }}
  </button>
</div>
