import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UserService } from '../../../services/user.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ToolbarService } from '../../../services/toolbar.service';
import { MessagingService } from '../../../components/messaging/messaging.service';
import { ConfirmBoxComponent } from '../../../components/confirm-box/confirm-box.component';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { smallModalConfig } from '../../../consts/modal-config.const';
import { ModalConfigsHelper } from '../../../helpers/modal-configs.helper';

@Component({
  selector: 'app-add-return-no-quote',
  templateUrl: './add-return-no-quote.component.html',
  styleUrls: ['./add-return-no-quote.component.scss']
})
export class AddReturnNoQuoteComponent implements OnInit, AfterViewInit {
  @Input() jobId: number;
  @Input() assessmentAction: string;
  @Input() assessorAllocated: boolean;
  @Input() hasQuotes: boolean;
  @Input() displayRFA: boolean;
  @Input() displaySupplementRFA: boolean;
  @Input() note: string;
  @Output() refreshJobViewer: EventEmitter<any> = new EventEmitter();

  replyForm: UntypedFormGroup;

  constructor(
    private userService: UserService,
    private confirmBox: MatDialog,
    public toolbarService: ToolbarService,
    private message: MessagingService,
    private elRef: ElementRef,
    private fb: UntypedFormBuilder,
    private modalConfigsHelper: ModalConfigsHelper
  ) {}

  get noQuoteResponseAssessmentAction() {
    return this.replyForm.get('noQuoteResponseAssessmentAction');
  }
  get noQuoteResponseNote() {
    return this.replyForm.get('noQuoteResponseNote');
  }

  ngOnInit(): void {
    this.replyForm = this.fb.group({
      noQuoteResponseAssessmentAction: [this.assessmentAction, [Validators.required]],
      noQuoteResponseNote: ['']
    });

    this.populateReplyForm();

    this.noQuoteResponseNote.valueChanges.pipe(debounceTime(750), distinctUntilChanged()).subscribe(() => {
      if (this.noQuoteResponseNote.dirty) {
        this.updateJob('noQuoteResponseNote');
      }
    });
  }

  ngAfterViewInit(): void {
    this.elRef.nativeElement.scrollIntoView({
      behavior: 'smooth'
    });

    if (this.assessmentAction) {
      this.updateJob('noQuoteResponseAssessmentAction');
    }
  }

  populateReplyForm() {
    this.replyForm.get('noQuoteResponseAssessmentAction').setValue(this.assessmentAction);
    this.replyForm.get('noQuoteResponseNote').setValue(this.note);
  }

  updateJob(fieldName: string) {
    this.toolbarService.setCloudState('SAVING');
    this.userService.updateJob(this.replyForm.get(fieldName).value, this.jobId, fieldName).subscribe(
      () => this.toolbarService.setCloudState('RESTING'),
      () => {
        this.message.error('Could not update field.');
        this.toolbarService.setCloudState('RESTING');
      },
      () => {
        this.refreshJobViewer.emit(this.replyForm.get(fieldName).value === 'Total Loss' ? null : 'isNotTotalLossCase');
        this.replyForm.controls[fieldName].markAsPristine();
      }
    );
  }

  confirmDeleteReplyCard() {
    const data = { title: 'Delete Reply', alertMessage: 'This reply will be deleted.', confirmButton: 'DELETE' };
    const dialogRef = this.confirmBox.open(
      ConfirmBoxComponent,
      this.modalConfigsHelper.buildMediumModalConfig(data, smallModalConfig)
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'DELETE') {
        this.deleteReply();
      }
    });
  }

  deleteReply() {
    this.userService.updateJob('false', this.jobId, 'noQuoteResponse').subscribe(
      () => this.toolbarService.setCloudState('RESTING'),
      () => {
        this.message.error('Could not update field.');
        this.toolbarService.setCloudState('RESTING');
      },
      () => this.refreshJobViewer.emit('DeleteReturnToAssessor')
    );
  }
}
