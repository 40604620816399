import { Component, effect, inject, Input, OnChanges, OnDestroy, OnInit, signal, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig
} from '@angular/material/legacy-dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfirmBoxComponent } from '../../../components/confirm-box/confirm-box.component';
import { MessagingService } from '../../../components/messaging/messaging.service';
import { extraWideModalConfig, smallModalConfig, wideModalConfig } from '../../../consts/modal-config.const';
import { RepairerSitesSortEnum } from '../../../enums/RepairerSitesSortEnum.enum';
import { ModalConfigsHelper } from '../../../helpers/modal-configs.helper';
import { ColumnHeaders } from '../../../model/ColumnHeaders.model';
import {
  RepairerGroup,
  RepairerSiteView,
  UserRepairerSite,
  UserRepairerSitesResponse
} from '../../../model/user.model';
import { CurrentUserService } from '../../../services/currentUser.service';
import { SysAdminService } from '../../../services/sys-admin.service';
import { ToolbarService } from '../../../services/toolbar.service';
import { EditAddressComponent } from '../../location-admin/edit-address/edit-address.component';
import { AccessRepairerSiteComponent } from '../access-repairer-site/access-repairer-site.component';
import { AddRepairerSiteComponent } from '../add-repairer-site/add-repairer-site.component';
import { ChangeCompanyInformationComponent } from '../change-company-information/change-company-information.component';
import { ChangeQuotingSystemComponent } from '../change-quoting-system/change-quoting-system.component';
import { CobrowsingScreenComponent } from '../cobrowsing-screen/cobrowsing-screen.component';
import { SetDashboardDayComponent } from '../set-dashboard-day/set-dashboard-day.component';
import { RepairerSitesStore } from './repairer-sites-store.component';

@Component({
  selector: 'app-repairer-sites',
  templateUrl: './repairer-sites.component.html',
  styleUrls: ['./repairer-sites.component.scss']
})
export class RepairerSitesComponent implements OnInit, OnDestroy, OnChanges {
  @Input() userTimezone: string;
  @Input() isToBeRefreshed = {};
  @Input() repairersGroup: RepairerGroup[];
  columnHeadersRepairerSites: ColumnHeaders[];
  repairerSiteTotalResult: number;
  searchPlaceholder =
    'Company Name, Physical Address Suburb, Physical Address Postcode, Repairer ID, Legacy Repairer ID, Repairer Group';
  searchInputValue: string;
  pageSize = 0;
  form: FormGroup;
  kebabMenuButtons: any[];
  statusOptions: { label: string; value: string }[] = [
    { label: 'All', value: '' },
    { label: 'Active', value: 'ACTIVE' },
    { label: 'Pending', value: 'PENDING' },
    { label: 'Archived', value: 'ARCHIVED' }
  ];

  loadingRepairers = signal(false);

  private unsubscribe = new Subject<void>();
  private toolbarService = inject(ToolbarService);
  public sysAdminService = inject(SysAdminService);
  private message = inject(MessagingService);
  private currentUser = inject(CurrentUserService);
  private matDialog = inject(MatDialog);
  private modalConfigsHelper = inject(ModalConfigsHelper);
  private repairerSitesStore = inject(RepairerSitesStore);
  private fb = inject(FormBuilder);

  get searchFilter() {
    return this.form.controls.searchFilter;
  }

  get page() {
    return this.form.controls.page;
  }

  constructor() {
    effect(
      () => {
        if (this.repairerSitesStore.refreshRepairerSites()) {
          this.form.patchValue({ searchFilter: '', statusFilter: '', page: 0 });
          this.getRepairerSites();
          this.repairerSitesStore.refreshRepairerSites.set(false);
        }
      },
      { allowSignalWrites: true }
    );
  }

  ngOnInit() {
    this.form = this.fb.group({
      statusFilter: '',
      searchFilter: '',
      page: 0,
      sort: 'companyName',
      direction: ''
    });

    this.columnHeadersRepairerSites = [
      {
        matColumnDef: 'companyName',
        columnTitle: 'Company Name',
        property: 'companyName'
      },
      {
        matColumnDef: 'repairerGroup',
        columnTitle: 'Repairer Group',
        property: 'repairerGroupName'
      },
      {
        matColumnDef: 'id',
        columnTitle: 'Repairer ID',
        property: 'id'
      },
      {
        matColumnDef: 'legacyCustomerId',
        columnTitle: 'Legacy ID',
        property: 'legacyCustomerId'
      },
      {
        matColumnDef: 'abn',
        columnTitle: 'ABN',
        property: 'abn'
      },
      {
        matColumnDef: 'mainContact.phone',
        columnTitle: 'Phone',
        property: 'mainContact.phone'
      },
      {
        matColumnDef: 'addressLine1',
        columnTitle: 'Address',
        property: 'addressString'
      },
      {
        matColumnDef: 'quotingSystems',
        columnTitle: 'Quoting System',
        property: 'quotingSystemsString'
      },
      {
        matColumnDef: 'published',
        columnTitle: 'Published',
        property: 'publishedDateTime'
      },
      {
        matColumnDef: 'status',
        columnTitle: 'Status'
      },
      {
        matColumnDef: 'kebab'
      }
    ];
    this.kebabMenuButtons = [
      {
        label: 'Access Repairer Site',
        action: 'AccessRepairerItem'
      },
      {
        label: 'Revoke All Tokens',
        action: 'RevokeAllTokens'
      },
      {
        label: 'Change Company Information',
        action: 'ChangeCompanyInformation'
      },
      {
        label: 'Change Quoting System',
        action: 'ChangeQuotingSystem'
      },
      {
        label: 'Set Autoarchive Days',
        action: 'SetAutoArchiveDays'
      },
      {
        label: 'Archive Repairer Site',
        action: 'ArchiveRepairerItem'
      },
      {
        label: 'Request Screen Sharing',
        action: 'RequestScreenSharing'
      },
      {
        label: 'Re-invite repairer',
        action: 'ReInviteRepairer'
      },
      {
        label: 'Delete',
        action: 'Delete'
      }
    ];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes?.isToBeRefreshed?.isFirstChange() && changes?.isToBeRefreshed?.currentValue?.status) {
      this.getRepairerSites(true);
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  getRepairerSites(resetPage: boolean = false) {
    this.loadingRepairers.set(true);
    this.toolbarService.setCloudState('FETCHING');
    resetPage && this.page.setValue(0);
    const { searchFilter, statusFilter, page, sort, direction } = this.form.getRawValue();
    this.searchInputValue = searchFilter;
    this.sysAdminService
      .getRepairerSites(
        statusFilter,
        searchFilter,
        page,
        this.getSortOrder(sort || 'companyName'),
        direction === 'desc'
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (payload: UserRepairerSitesResponse) => {
          this.sysAdminService.repairerSitesList.update(() =>
            payload.repairerSites.filter((repairerSite) => !repairerSite.clientId)
          );
          this.repairerSiteTotalResult = payload.numberOfMatches;
          this.pageSize = payload.numberOfSitesPerPage;
          payload.numberOfMatches === 0 && this.form.patchValue({ sort: 'companyName', direction: '' });
          this.loadingRepairers.set(false);
          this.currentUser.timeZone = 'Australia/NSW';
          this.toolbarService.setCloudState('RESTING');
        },
        error: () => {
          this.message.error('Could not retrieve the list of repairer sites.');
          this.loadingRepairers.set(false);
          this.toolbarService.setCloudState('RESTING');
        }
      });
  }

  getSortOrder(columnName: string): RepairerSitesSortEnum {
    switch (columnName) {
      case 'companyName':
        return RepairerSitesSortEnum.COMPANY_NAME;
      case 'id':
        return RepairerSitesSortEnum.REPAIRER_ID;
      case 'mainContact.username':
        return RepairerSitesSortEnum.ADMIN_USERNAME;
      case 'mainContact.firstName':
        return RepairerSitesSortEnum.ADMINISTRATOR_NAME;
      case 'mainContact.emailAddress':
        return RepairerSitesSortEnum.ADMIN_EMAIL;
      case 'userCount':
        return RepairerSitesSortEnum.NB_USERS;
      case 'mainContact.phone':
        return RepairerSitesSortEnum.PHONE;
      case 'abn':
        return RepairerSitesSortEnum.ABN;
      case 'addressLine1':
        return RepairerSitesSortEnum.ADDRESS;
      case 'quotingSystems':
        return RepairerSitesSortEnum.QUOTING_SYSTEM;
      case 'dashboardDays':
        return RepairerSitesSortEnum.DASHBOARD_DAYS;
      case 'repairerGroup':
        return RepairerSitesSortEnum.REPAIRER_GROUP;
      case 'legacyCustomerId':
        return RepairerSitesSortEnum.LEGACY_CUSTOMER_ID;
      case 'published':
        return RepairerSitesSortEnum.PUBLISHED_DATETIME;
      default:
        return null;
    }
  }

  getRepairerSite(id: number) {
    this.toolbarService.setCloudState('FETCHING');
    this.sysAdminService
      .getRepairerSite(id)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (repairerSite: RepairerSiteView) => {
          repairerSite.status === 'PENDING'
            ? this.editPendingRepairerSite(repairerSite)
            : this.editActiveRepairerSite(repairerSite);
          this.toolbarService.setCloudState('RESTING');
        },
        error: () => {
          this.message.error('Could not retrieve the repairer site.');
          this.toolbarService.setCloudState('RESTING');
        }
      });
  }

  editActiveRepairerSite(repairer: RepairerSiteView) {
    const dialogConfig: MatDialogConfig = wideModalConfig;
    dialogConfig.data = { repairer, repairersGroup: this.repairersGroup };
    const dialogRef = this.matDialog.open(EditAddressComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'Success') {
        this.message.confirm('Repairer Site Updated.');
        this.getRepairerSites(true);
      }
    });
  }

  kebabMenuClick(item: { action: string; param: UserRepairerSite }) {
    switch (item?.action) {
      case 'AccessRepairerItem':
        this.confirmAccessRepairerSite(item?.param);
        break;
      case 'ChangeQuotingSystem':
        this.changeQuotingSystem(item?.param);
        break;
      case 'ChangeCompanyInformation':
        this.changeCompanyInformation(item?.param);
        break;
      case 'RevokeAllTokens':
        this.confirmRevokeAllTokens(item?.param);
        break;
      case 'SetAutoArchiveDays':
        this.setDashboardDays(item?.param);
        break;
      case 'ArchiveRepairerItem':
        this.confirmArchiveRepairerSite(item?.param);
        break;
      case 'RequestScreenSharing':
        this.requestScreenSharing(item?.param?.id);
        break;
      case 'ReInviteRepairer':
        this.reinviteRepairer(item?.param?.id);
        break;
      case 'Delete':
        this.confirmDeletePendingInvite(item?.param?.id);
        break;
    }
  }

  confirmAccessRepairerSite(repairerSite: UserRepairerSite) {
    const data = { repairerSiteId: repairerSite.id, repairerSiteName: repairerSite.companyName };
    this.matDialog.open(
      AccessRepairerSiteComponent,
      this.modalConfigsHelper.buildMediumModalConfig(data, smallModalConfig)
    );
  }

  changeCompanyInformation(repairerSite: UserRepairerSite) {
    const dialogConfig = {
      ...wideModalConfig,
      data: {
        title: 'Change Company Information',
        action: 'SAVE',
        displayClose: true,
        component: ChangeCompanyInformationComponent
      }
    };
    this.repairerSitesStore.getRepairerSite({ repairerSite, dialogConfig });
  }

  changeQuotingSystem(repairerSite: UserRepairerSite) {
    const dialogConfig: MatDialogConfig = wideModalConfig;
    dialogConfig.data = { repairerSiteId: repairerSite.id };
    const dialogRef = this.matDialog.open(ChangeQuotingSystemComponent, dialogConfig);
    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === 'Success') {
          this.message.confirm('Quoting System Changed.');
          this.getRepairerSites(true);
        }
      }
    });
  }

  confirmRevokeAllTokens(event: any) {
    const data = {
      title: 'Revoke All Tokens',
      alertMessage: 'All users tokens will be revoked. They will need to redo the linking process.',
      confirmButton: 'REVOKE'
    };
    const dialogRef = this.matDialog.open(
      ConfirmBoxComponent,
      this.modalConfigsHelper.buildMediumModalConfig(data, smallModalConfig)
    );
    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === 'REVOKE') {
          event.isQuotingSystem
            ? this.revokingTokensOfQuotingSystem(event.id)
            : this.revokingTokensForRepairSite(event.id);
        }
      }
    });
  }

  revokingTokensOfQuotingSystem(repairerSiteId: number) {
    this.toolbarService.setCloudState('FETCHING');
    this.sysAdminService
      .revokeTokensOfQuotingSystem(repairerSiteId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: () => this.message.confirm('Tokens revoked for Quoting System.'),
        error: () => {
          this.message.error('Could not revoke tokens for Quoting System.');
          this.toolbarService.setCloudState('RESTING');
        },
        complete: () => this.toolbarService.setCloudState('RESTING')
      });
  }

  revokingTokensForRepairSite(repairerSiteId: number) {
    this.toolbarService.setCloudState('FETCHING');
    this.sysAdminService
      .revokeTokensOfRepairerSite(repairerSiteId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: () => this.message.confirm('Tokens revoked for Repairer Site.'),
        error: () => {
          this.message.error('Could not revoke tokens for Repairer Site.');
          this.toolbarService.setCloudState('RESTING');
        },
        complete: () => this.toolbarService.setCloudState('RESTING')
      });
  }

  setDashboardDays(repairerSite: UserRepairerSite) {
    const dialogConfig = {
      ...wideModalConfig,
      panelClass: 'wide-modal',
      data: {
        title: 'Set Autoarchive Days',
        action: 'SET AUTOARCHIVE DAYS',
        displayClose: true,
        component: SetDashboardDayComponent
      }
    };
    this.repairerSitesStore.getRepairerSite({ repairerSite, dialogConfig });
  }

  confirmArchiveRepairerSite(repairerSite: UserRepairerSite) {
    const data = {
      title: 'Archive Repairer Site',
      alertMessage: 'All users for this site will be disabled and the repairer site will no longer be accessible.',
      confirmButton: 'ARCHIVE'
    };
    const dialogRef = this.matDialog.open(
      ConfirmBoxComponent,
      this.modalConfigsHelper.buildMediumModalConfig(data, smallModalConfig)
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'ARCHIVE') {
        this.archiveRepairerSite(repairerSite.id);
      }
    });
  }

  archiveRepairerSite(repairerSiteId: number) {
    this.toolbarService.setCloudState('SAVING');
    this.sysAdminService
      .archiveRepairerSite(repairerSiteId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: () => this.toolbarService.setCloudState('RESTING'),
        error: () => {
          this.message.error('Repairer Site could not be archived.');
          this.toolbarService.setCloudState('RESTING');
        },
        complete: () => {
          this.message.confirm('Repairer Site Archived');
          this.getRepairerSites(true);
        }
      });
  }

  requestScreenSharing(id: number) {
    const dialogConfig: MatDialogConfig = extraWideModalConfig;
    dialogConfig.panelClass = 'full-screen-modal';
    dialogConfig.data = {
      repairerSiteId: id,
      sysadminName: `${this.currentUser.currentUserValue?.firstName} ${this.currentUser?.currentUserValue?.lastName}`
    };
    this.matDialog.open(CobrowsingScreenComponent, dialogConfig);
  }

  reinviteRepairer(repairerId: number) {
    const dialogConfig: MatDialogConfig = wideModalConfig;
    dialogConfig.data = {
      repairerSite: this.sysAdminService.repairerSitesList().find((pendingSite) => pendingSite.id === repairerId),
      repairersGroup: this.repairersGroup
    };
    const dialogRef = this.matDialog.open(AddRepairerSiteComponent, dialogConfig);
    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === 'Success') {
          this.message.confirm('Repairer is re-invited');
          this.getRepairerSites(true);
        }
      }
    });
  }

  confirmDeletePendingInvite(repairerSiteId: number) {
    const data = {
      title: 'Delete Pending Invite',
      alertMessage: 'Pending Invite will be deleted.',
      confirmButton: 'DELETE'
    };
    const dialogRef = this.matDialog.open(
      ConfirmBoxComponent,
      this.modalConfigsHelper.buildMediumModalConfig(data, smallModalConfig)
    );
    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === 'DELETE') {
          this.deletePendingInvite(repairerSiteId);
        }
      }
    });
  }

  deletePendingInvite(repairerSiteId: number) {
    this.toolbarService.setCloudState('SAVING');
    this.sysAdminService.deleteRepairerSite(repairerSiteId).subscribe({
      next: () => this.toolbarService.setCloudState('RESTING'),
      error: () => {
        this.message.error('Repairer Site could not be deleted.');
        this.toolbarService.setCloudState('RESTING');
      },
      complete: () => {
        this.message.confirm('Repairer Site Deleted');
        this.getRepairerSites(true);
      }
    });
  }

  editPendingRepairerSite(repairerSite: RepairerSiteView) {
    const dialogConfig: MatDialogConfig = wideModalConfig;
    dialogConfig.data = {
      repairerSite,
      title: 'Edit Repairer',
      isEditRepairerSite: true,
      repairersGroup: this.repairersGroup
    };
    const dialogRef = this.matDialog.open(AddRepairerSiteComponent, dialogConfig);
    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === 'Success') {
          this.message.confirm('Repairer Site Edited');
          this.getRepairerSites(true);
        }
      }
    });
  }

  pageSearch(page: number) {
    this.form.patchValue({ page });
    this.getRepairerSites();
  }

  sortedSearch(sort?: string, direction?: string) {
    this.form.patchValue({ sort: sort || 'companyName', direction });
    this.getRepairerSites();
  }
}
