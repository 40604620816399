import { AfterContentInit, Directive, ElementRef, HostListener, inject, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { DecimalPipe } from '@angular/common';

@Directive({
  selector: '[formatInputNumber]',
  standalone: true,
  providers: [DecimalPipe]
})
export class FormatInputNumberDirective implements AfterContentInit {
  @Input() minDecimals = 0;
  @Input() maxDecimals = 0;
  @Input() noSeparator = false;

  ngControl = inject(NgControl);
  decimalPipe = inject(DecimalPipe);
  elementRef = inject(ElementRef);

  ngAfterContentInit() {
    this.formatValue(this.elementRef.nativeElement, this.ngControl.value , `1.${this.minDecimals}-${this.maxDecimals}`);
  }

  @HostListener('input', ['$event'])
  onInput(event: InputEvent): void {
    const inputElement = event.target as HTMLInputElement;
    const inputValue = inputElement.value;

    if (this.shouldSkip(inputValue)) { return; }

    const numericValue = this.sanitizeInput(inputValue);
    const digitsInfo = `1.0-${this.maxDecimals}`;
    this.formatValue(inputElement, numericValue, digitsInfo);
  }

  /**
   * Reset the value if the pattern mismatches.
   * Otherwise, do a final format with the correct number of decimals.
   */
  @HostListener('blur', ['$event'])
  onBlur(event: FocusEvent): void {
    const inputElement = event.target as HTMLInputElement;
    const numericValue = this.ngControl?.control.value;

    if (inputElement.validity.patternMismatch) {
      this.setValue(inputElement, null, '');
    } else if (numericValue) {
      const digitsInfo = `1.${this.minDecimals}-${this.maxDecimals}`;
      this.formatValue(inputElement, numericValue, digitsInfo);
    }
  }

  private shouldSkip(inputValue: string): boolean {
    return [inputValue === '', inputValue.endsWith('.'), inputValue.endsWith('-'), inputValue.endsWith('-0')].some(
      (shouldSkip) => shouldSkip
    );
  }

  private formatValue(inputElement: HTMLInputElement, numericValue: string, digitsInfo: string): void {
    const viewValue = this.getViewValue(numericValue, digitsInfo);
    const controlValue = this.getControlValue(viewValue);

    this.setValue(inputElement, controlValue, viewValue);
  }

  /**
   * Remove any non-numeric characters, except for a period (for decimals)
   * and a starting minus sign (for negative numbers).
   * If it ends with a minus sign (ex: 1-) or if there are more than one occurance for period (ex: 1..),
   * then return an empty string.
   * If there are more decimals than allowed, remove the extra decimals.
   */
  private sanitizeInput(value: string): string {
    const invalidFormat = value.length > 1 && (value.endsWith('-') || value.split('.').length - 1 > 1);
    if (invalidFormat) { return ''; }

    let numericValue = value.replace(/[^0-9.-]/g, '');
    const [_, decimals] = numericValue.split('.');
    decimals?.length > this.maxDecimals && (numericValue = numericValue.slice(0, this.maxDecimals - decimals.length));

    return numericValue;
  }

  /**
   * Transforms the given value to a decimal string, separated by commas.
   */
  private getViewValue(numericValue: string, digitsInfo: string): string {
    return numericValue === '' ? '' : this.decimalPipe.transform(numericValue, digitsInfo);
  }

  /**
   * Transforms the given value to a decimal number, without any commas.
   */
  private getControlValue(viewValue: string): number {
    return viewValue === '' ? null : parseFloat(viewValue.replace(/,/g, ''));
  }

  /**
   * 1. Set a numeric value for the form control.
   * 2. Set a string value for the view portion of the input.
   * Order here matters.
   */
  private setValue(inputElement: HTMLInputElement, controlValue: number, viewValue: string): void {
    this.ngControl?.control.setValue(controlValue, { onlySelf: true });
    inputElement.value = this.noSeparator ? viewValue.replace(/,/g, '') : viewValue;
  }
}
