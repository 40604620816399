import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { SiteFunctionInvocationStats } from './site-function-invocation-stats.model';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MonitorService {
  constructor(private http: HttpClient) {}

  private static handleError(error: any) {
    return Promise.reject(error.error || error.details || error);
  }

  getSiteFunctionInvocationStats(): any {
    return this.http
      .get<SiteFunctionInvocationStats>('ui/monitor/siteFunctionInvocationStats')
      .pipe(catchError(MonitorService.handleError));
  }

  clearSiteFunctionInvocationStats(): any {
    return this.http
      .delete<SiteFunctionInvocationStats>('ui/monitor/siteFunctionInvocationStats')
      .pipe(catchError(MonitorService.handleError));
  }
}
