import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConfirmBoxComponent } from '../../../components/confirm-box/confirm-box.component';
import { UserService } from '../../../services/user.service';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig
} from '@angular/material/legacy-dialog';
import { ToolbarService } from '../../../services/toolbar.service';
import { MessagingService } from '../../../components/messaging/messaging.service';
import { Diary } from '../../../model/job.model';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { smallModalConfig } from '../../../consts/modal-config.const';
import { ModalConfigsHelper } from '../../../helpers/modal-configs.helper';

@Component({
  selector: 'app-add-diary',
  templateUrl: './add-diary.component.html',
  styleUrls: ['./add-diary.component.scss']
})
export class AddDiaryComponent implements OnInit, AfterViewInit {
  @Input() diary: Diary;
  @Output() refreshJobViewer: EventEmitter<any> = new EventEmitter();
  @Output() disableSendButton: EventEmitter<any> = new EventEmitter();
  diaryForm: UntypedFormGroup;

  constructor(
    private userService: UserService,
    private confirmBox: MatDialog,
    public toolbarService: ToolbarService,
    private message: MessagingService,
    private fb: UntypedFormBuilder,
    private elRef: ElementRef,
    private modalConfigsHelper: ModalConfigsHelper
  ) {}

  get body() {
    return this.diaryForm.get('body');
  }

  get subject() {
    return this.diaryForm.get('subject');
  }

  ngOnInit(): void {
    this.diaryForm = this.fb.group({
      subject: ['', Validators.required],
      body: ['']
    });

    this.populateDiary();

    this.body.valueChanges.pipe(debounceTime(750), distinctUntilChanged()).subscribe(() => {
      this.updateUnsubmittedDiaries();
    });

    this.subject.valueChanges.pipe(debounceTime(750), distinctUntilChanged()).subscribe(() => {
      if (this.subject.invalid) {
        this.subject.markAsTouched();
      }
      this.disableSendButton.emit();
      this.updateUnsubmittedDiaries();
    });
  }

  ngAfterViewInit() {
    this.elRef.nativeElement.scrollIntoView({
      behavior: 'smooth'
    });
  }

  populateDiary() {
    this.body.setValue(this.diary.body);
    this.subject.setValue(this.diary.subject);
  }

  confirmDeleteUnsubmittedDiaries() {
    const data = { title: 'Delete Diary', alertMessage: 'This diary will be deleted.', confirmButton: 'DELETE' };
    const dialogRef = this.confirmBox.open(
      ConfirmBoxComponent,
      this.modalConfigsHelper.buildMediumModalConfig(data, smallModalConfig)
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'DELETE') {
        this.deleteUnsubmittedDiaries();
      }
    });
  }

  deleteUnsubmittedDiaries() {
    this.toolbarService.setCloudState('SAVING');
    this.userService.deleteDiary(this.diary.jobId, this.diary.id).subscribe(
      () => this.toolbarService.setCloudState('RESTING'),
      () => {
        this.message.error('Diary could not be deleted.');
        this.toolbarService.setCloudState('RESTING');
      },
      () => {
        this.message.confirm('Diary Deleted.');
        this.refreshJobViewer.emit();
      }
    );
  }

  updateUnsubmittedDiaries() {
    this.toolbarService.setCloudState('SAVING');
    const diary: Diary = {
      id: this.diary.id,
      jobId: this.diary.jobId,
      subject: this.subject.value.trim(),
      body: this.body.value.trim()
    };
    this.userService.updateDiary(this.diary.jobId, diary).subscribe(
      () => this.toolbarService.setCloudState('RESTING'),
      () => {
        this.message.error('Diary could not be updated.');
        this.toolbarService.setCloudState('RESTING');
      },
      () => {
        this.refreshJobViewer.emit();
      }
    );
  }
}
