import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'orderByEpochTime'
})
export class OrderByEpochTimePipe implements PipeTransform {
  transform(list: {}[], prop: string, direction: 'ASC' | 'DESC' = 'ASC') {
    return list.sort((a, b) => this.compareItems(prop, a, b, direction));
  }

  private compareItems(prop: string, a: {}, b: {}, direction: 'ASC' | 'DESC'): number {
    if (!a[prop]) {
      return direction === 'ASC' ? 1 : -1;
    } else if (!b[prop]) {
      return direction === 'ASC' ? -1 : 1;
    }

    const aValue = moment(a[prop]).unix();
    const bValue = moment(b[prop]).unix();

    return direction === 'ASC' ? aValue - bValue : bValue - aValue;
  }
}
