import { Component, inject, OnInit, ViewEncapsulation } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogModule,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MessagingService } from '../../../components/messaging/messaging.service';
import { SimpleUserUpdate, UserCreate } from '../../../model/user.model';
import { ToolbarService } from '../../../services/toolbar.service';
import { EscapeDialogHelper } from '../../../helpers/escape-dialog.helper';
import { RepairerAdminService } from '../../../services/repairer-admin.service';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import { MatLegacyOptionModule } from '@angular/material/legacy-core';
import { MatLegacyCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MultiLocalAdminService } from '../../../services/ml-admin.service';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatDividerModule,
    ReactiveFormsModule,
    FormsModule,
    MatLegacyFormFieldModule,
    MatLegacySelectModule,
    MatLegacyOptionModule,
    MatLegacyCheckboxModule,
    MatLegacyDialogModule,
    MatLegacyInputModule,
    MatLegacyButtonModule
  ],
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddUserComponent implements OnInit {
  addUserForm: UntypedFormGroup;
  pending = false;

  dialogRef = inject(MatDialogRef<AddUserComponent>);
  data = inject(MAT_DIALOG_DATA);
  private fb = inject(UntypedFormBuilder);
  public toolbarService = inject(ToolbarService);
  private message = inject(MessagingService);
  private escapeDialogRef = inject(EscapeDialogHelper);
  private mlAdminService = inject(MultiLocalAdminService);
  private repAdminService = inject(RepairerAdminService);

  get username() {
    return this.addUserForm.get('username');
  }
  get firstName() {
    return this.addUserForm.get('firstName');
  }
  get lastName() {
    return this.addUserForm.get('lastName');
  }
  get email() {
    return this.addUserForm.get('email');
  }
  get phone() {
    return this.addUserForm.get('phone');
  }
  get permission() {
    return this.addUserForm.get('permission');
  }
  get primaryContact() {
    return this.addUserForm.get('primaryContact');
  }

  ngOnInit() {
    this.addUserForm = this.fb.group({
      username: [this.data && this.data.user ? this.data.user.username : '', [Validators.required]],
      firstName: [this.data && this.data.user ? this.data.user.firstName : '', [Validators.required]],
      lastName: [this.data && this.data.user ? this.data.user.lastName : '', [Validators.required]],
      email: [
        this.data && this.data.user ? this.data.user.email : '',
        [
          Validators.required,
          Validators.pattern(
            '^([^@\\s\\."\'\\(\\)\\[\\]\\{\\}\\\\/,:;]+\\.)*[^@\\s\\."\'\\(\\)\\[\\]\\{\\}\\\\/,:;]' +
              '+@[^@\\s\\."\'\\(\\)\\[\\]\\{\\}\\\\/,:;]+(\\.[^@\\s\\."\'\\(\\)\\[\\]\\{\\}\\\\/,:;]+)+$'
          )
        ]
      ],
      phone: [this.data && this.data.user ? this.data.user.phone : ''],
      permission: {
        value: this.data?.user?.privileges.includes('REPADMIN') ? 'REPADMIN' : 'User',
        disabled: this.data?.user?.privileges.includes('MLADMIN')
      },
      primaryContact: {
        value: this.data?.user?.isPrimaryContact ?? false,
        disabled: this.data?.user?.privileges.includes('MLADMIN')
      }
    });

    this.escapeDialogRef.escapeDialog(this.dialogRef);

    if (this.data?.user) {
      this.username.disable();
    }
  }

  saveUpdateUser() {
    this.pending = true;
    this.toolbarService.setCloudState('SAVING');

    const user: UserCreate = {
      username: this.username.value.trim(),
      firstName: this.firstName.value.trim(),
      lastName: this.lastName.value.trim(),
      email: this.email.value.trim(),
      phone: this.phone.value.trim(),
      setAsContact: this.primaryContact.value
    };

    if (this.data?.user?.privileges.includes('MLADMIN')) {
      this.editMlAdminUser(user);
    } else {
      if (this.permission.value === 'REPADMIN') {
        user.privilege = this.permission.value;
      }
      if (!this.data?.user) {
        this.repAdminService.createUser(user).subscribe(
          () => this.dialogRef.close({ action: 'add', response: 'Success' }),
          (error) => {
            if (error.error === 'DUPLICATE_USERNAME') {
              this.username.setErrors({ usernameDuplicate: true });
            } else {
              this.message.error('Could not create the user.');
            }
            this.toolbarService.setCloudState('RESTING');
            this.pending = false;
          },
          () => {
            this.toolbarService.setCloudState('RESTING');
            this.pending = false;
          }
        );
      } else {
        this.repAdminService.updateSimpleUser(user, this.data.user.username).subscribe(
          () => this.dialogRef.close({ action: 'edit', response: 'Success' }),
          () => {
            this.message.error('Could not update user.');
            this.toolbarService.setCloudState('RESTING');
            this.pending = false;
          },
          () => {
            this.toolbarService.setCloudState('RESTING');
            this.pending = false;
          }
        );
      }
    }
  }

  private editMlAdminUser(user: SimpleUserUpdate) {
    this.mlAdminService.updateMlAdmin(this.data.user.username, user, [], false).subscribe(() => {
      this.dialogRef.close({ action: 'edit', response: 'Success' });
    });
  }
}
