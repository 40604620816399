import { inject, Injectable } from '@angular/core';
import { MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { EMPTY, Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { DynamicDialogService } from '../../components/dynamic-dialog/dynamic-dialog-service';
import { MessagingService } from '../../components/messaging/messaging.service';
import { ResponseEnumEnum } from '../../enums/responseEnum.enum';
import { BankingDetails } from '../../model/banking-details.model';
import { EstImagePayRepairerSite } from '../../model/estimage-pay.model';
import { RepairerSiteHistory, User, UserRepairerSite } from '../../model/user.model';
import { CurrentUserService } from '../../services/currentUser.service';
import { RepairerAdminService } from '../../services/repairer-admin.service';
import { ToolbarService } from '../../services/toolbar.service';
import { UserService } from '../../services/user.service';

export interface LocationAdminState {
  userRepairerSite: UserRepairerSite;
  usersOfRepairerSite: User[];
  bankingDetails: BankingDetails | null;
  repairerSiteHistories: RepairerSiteHistory[] | [];
  estImagePay: EstImagePayRepairerSite | null;
  isLoading: boolean;
}

export const adapter: EntityAdapter<User> = createEntityAdapter<User>();
export const initialState: LocationAdminState = adapter.getInitialState({
  userRepairerSite: null,
  usersOfRepairerSite: [],
  bankingDetails: null,
  repairerSiteHistories: [],
  estImagePay: null,
  isLoading: false
});

const { selectAll } = adapter.getSelectors();

@Injectable({ providedIn: 'root' })
export class LocationAdminStore extends ComponentStore<LocationAdminState> {
  userRepairerSite = this.selectSignal((state) => state.userRepairerSite);
  // Add bankingDetails and usersOfRepairerSite for future use
  bankingDetails = this.selectSignal((state) => state.bankingDetails);
  usersOfRepairerSite = this.selectSignal((state) => state.usersOfRepairerSite);
  repairerSiteHistories = this.selectSignal((state) => state.repairerSiteHistories);
  estImagePay = this.selectSignal((state) => state.estImagePay);
  isLoading = this.selectSignal((state) => state.isLoading);

  #currentUserService = inject(CurrentUserService);
  #dynamicDialogService = inject(DynamicDialogService);
  #message = inject(MessagingService);
  #toolbarService = inject(ToolbarService);
  #repairerAdminService = inject(RepairerAdminService);
  #userService = inject(UserService);
  #repAdminService = inject(RepairerAdminService);

  constructor() {
    super(initialState);
  }

  // For future use in location-admin component
  getRepairerSiteForUser = this.effect((trigger$) =>
    trigger$.pipe(
      tap(() => {
        this.#toolbarService.setCloudState('FETCHING');
        this.patchState({ isLoading: true });
      }),
      switchMap(() => {
        return this.#userService.getRepairerSiteForUser().pipe(
          tapResponse(
            (repairerSite: UserRepairerSite) => {
              this.setUserRepairerSite(repairerSite);
              this.getCompanyInformationHistory(repairerSite.id);
              this.#currentUserService.timeZone = repairerSite?.timezone;
            },
            (error) => {
              this.#message.error('Could not retrieve the repairer site');
              this.patchState({ isLoading: false });
              this.#toolbarService.setCloudState('RESTING');
            }
          )
        );
      })
    )
  );

  getUsersOfRepairerSite = this.effect((trigger$) =>
    trigger$.pipe(
      tap(() => {
        this.#toolbarService.setCloudState('FETCHING');
        this.patchState({ isLoading: true });
      }),
      switchMap(() => {
        return this.#repAdminService.getUsersOfRepairerSite().pipe(
          tapResponse(
            (usersOfRepairerSite: User[]) => {
              this.patchState({
                usersOfRepairerSite,
                isLoading: false
              });
              this.#toolbarService.setCloudState('RESTING');
            },
            () => {
              this.#message.error('Could not retrieve the users of repairer site.');
              this.patchState({ isLoading: false });
              this.#toolbarService.setCloudState('RESTING');
            }
          )
        );
      })
    )
  );

  openDynamicDialog = this.effect((trigger$: Observable<MatDialogConfig>) =>
    trigger$.pipe(
      switchMap((dialogConfig) => {
        return this.#dynamicDialogService
          .openDialog(dialogConfig)
          .afterClosed()
          .pipe(
            tapResponse(
              (response: ResponseEnumEnum) => {
                this.#dynamicDialogService.dialogRefSignal.set(null);
              },
              () => {
                return EMPTY;
              }
            )
          );
      })
    )
  );

  getCompanyInformationHistory = this.effect((trigger$: Observable<number>) => {
    return trigger$.pipe(
      tap(() => {
        this.patchState({ isLoading: true });
        this.#toolbarService.setCloudState('FETCHING');
      }),
      switchMap((id: number) => {
        return this.#repairerAdminService.getRepairerSiteHistory(id).pipe(
          tapResponse(
            (repairerSiteHistories: RepairerSiteHistory[]) => {
              this.patchState({ isLoading: false, repairerSiteHistories });
              this.#toolbarService.setCloudState('RESTING');
            },
            (error) => {
              this.#message.error('Could not get repairer site history');
              this.patchState({ isLoading: false, repairerSiteHistories: [] });
              this.#toolbarService.setCloudState('RESTING');
            }
          )
        );
      })
    );
  });

  applyToEstImage = this.effect((trigger$: Observable<string>) => {
    return trigger$.pipe(
      tap(() => {
        this.#toolbarService.setCloudState('SAVING');
      }),
      switchMap((email: string) => {
        return this.#repairerAdminService.applyRepairerSiteToEstimagePay(email).pipe(
          tapResponse(
            ({ onboardingUrl }) => {
              this.#dynamicDialogService.closeDialog(ResponseEnumEnum.SUCCESS, 'Application sent to EstImage Pay');
              this.patchState({
                estImagePay: {
                  ...this.estImagePay(),
                  status: 'PENDING',
                  onBoardingUrl: onboardingUrl
                }
              });
            },
            (error) => {
              this.#dynamicDialogService.closeDialog(ResponseEnumEnum.ERROR, 'Could not apply to EstImage Pay');
            }
          )
        );
      })
    );
  });

  setUserRepairerSite(userRepairerSite: UserRepairerSite): void {
    this.patchState({
      userRepairerSite,
      estImagePay: {
        status: userRepairerSite.estimagePayStatus,
        onBoardingUrl: userRepairerSite.estimagePayOnboardingUrl,
        flipPayId: userRepairerSite.flipPayId
      }
    });
  }

  resetStoreState() {
    this.patchState({
      userRepairerSite: null,
      estImagePay: null,
      repairerSiteHistories: [],
      usersOfRepairerSite: []
    });
  }
}
