<div class="dashboard-section">
  <div *ngIf="repairerSite?.status === 'PENDING'" class="guidance-message-container">
    <mat-card>
      <mat-icon>error_outline</mat-icon>
      <span *ngIf="currentUser?.privileges?.includes('REPADMIN'); else user">
        You will be able to manage your EstImage jobs here once your location has been published. Head to
        <a routerLink="/locationAdmin">Administration</a> to get started.
      </span>
      <ng-template #user>
        <span
          >You will be able to manage your EstImage jobs here once your site administrator has published your
          location.</span
        >
      </ng-template>
    </mat-card>
  </div>
  <mat-tab-group
    (selectedTabChange)="tabSelected($event); historyCount = 25"
    animationDuration="0ms"
    class="dashboard-group"
    [selectedIndex]="selectedTabIndex"
    color="primary"
    tabGroup>
    <mat-tab #quotesTab>
      <ng-template mat-tab-label>
        <div class="title-1" [class.label-color]="quotesTab.isActive">Quoting</div>
        <div [class.red-background]="quotesTab.isActive" class="section-counter subtitle-1">
          <span>{{ quotesTabCounter }}</span>
        </div>
      </ng-template>
      <div class="section-container">
        <app-tab-headers
          [bucketDetails]="quotingBucketDetails"
          [dates]="{ oldestReceivedOn: oldestReceivedOn, newestReceivedOn: newestReceivedOn, timezone: timezone }"
          [bucketSelected]="bucketSelected"
          [unreadJobFilter]="unreadJobFilter"
          (filterUnreadJobs)="updateBucketsByUnreadStatus($event)"
          (scrollBucket)="scrollBucket($event)">
        </app-tab-headers>
        <ng-template ngFor let-quotingBucket [ngForOf]="quotingBuckets">
          <app-repairer-buckets
            [bucket]="quotingBucket"
            [currentUser]="currentUser"
            [repairerSite]="repairerSite"
            (getJobs)="getJobs()"></app-repairer-buckets>
        </ng-template>
      </div>
    </mat-tab>
    <mat-tab #workInProgressTab>
      <ng-template mat-tab-label>
        <div class="title-1" [class.label-color]="workInProgressTab.isActive">Work Authorised</div>
        <div [class.red-background]="workInProgressTab.isActive" class="section-counter subtitle-1">
          <span>{{ wipTabCounter }}</span>
        </div>
      </ng-template>
      <div class="section-container">
        <app-tab-headers
          [bucketDetails]="workAuthorisedBucketDetails"
          [dates]="{ oldestReceivedOn: oldestReceivedOn, newestReceivedOn: newestReceivedOn, timezone: timezone }"
          [bucketSelected]="bucketSelected"
          [unreadJobFilter]="unreadJobFilter"
          (filterUnreadJobs)="updateBucketsByUnreadStatus($event)"
          (scrollBucket)="scrollBucket($event)">
        </app-tab-headers>
        <ng-template ngFor let-workAuthorisedBucket [ngForOf]="workAuthorisedBuckets">
          <app-repairer-buckets
            [bucket]="workAuthorisedBucket"
            [currentUser]="currentUser"
            [repairerSite]="repairerSite"
            (getJobs)="getJobs()"></app-repairer-buckets>
        </ng-template>
      </div>
    </mat-tab>
    <mat-tab #invoicesTab>
      <ng-template mat-tab-label>
        <div class="title-1" [class.label-color]="invoicesTab.isActive">Invoicing</div>
        <div [class.red-background]="invoicesTab.isActive" class="section-counter subtitle-1">
          <span>{{ invoicesTabCounter }}</span>
        </div>
      </ng-template>
      <div class="section-container">
        <app-tab-headers
          [bucketDetails]="invoicingBucketDetails"
          [dates]="{ oldestReceivedOn: oldestReceivedOn, newestReceivedOn: newestReceivedOn, timezone: timezone }"
          [bucketSelected]="bucketSelected"
          [unreadJobFilter]="unreadJobFilter"
          (filterUnreadJobs)="updateBucketsByUnreadStatus($event)"
          (scrollBucket)="scrollBucket($event)">
        </app-tab-headers>
        <ng-template ngFor let-invoicingBucket [ngForOf]="invoicingBuckets">
          <app-repairer-buckets
            [bucket]="invoicingBucket"
            [currentUser]="currentUser"
            [repairerSite]="repairerSite"
            (getJobs)="getJobs()"></app-repairer-buckets>
        </ng-template>
      </div>
    </mat-tab>
    <mat-tab #repairProgressTab [labelClass]="['repairer-progress', !repairPickUpAvailable ? 'no-pickup-job' : '']">
      <ng-template mat-tab-label>
        <div class="title-1" [class.label-color]="repairProgressTab.isActive">Repair Progress</div>
        <div [class.red-background]="repairProgressTab.isActive" class="section-counter subtitle-1">
          <span>{{ rpTabCounter }}</span>
        </div>
      </ng-template>
      <div class="section-container" *ngIf="selectedTabIndex === 3">
        <div *ngIf="loadingJobs" class="section-container-spinner">
          <mat-spinner color="primary" mode="indeterminate"></mat-spinner>
        </div>
        <app-repair-progress
          *ngIf="!loadingJobs && jobs?.repairProgressJobs"
          [buckets]="jobs?.repairProgressJobs"
          [repairerSite]="repairerSite"
          (updateBuckets)="updateBuckets($event)"></app-repair-progress>
      </div>
    </mat-tab>
    <mat-tab #jobHistoryTab [labelClass]="['history', !repairPickUpAvailable ? 'no-pickup-job' : '']">
      <ng-template mat-tab-label>
        <mat-icon class="history-tab" [class.red-color]="jobHistoryTab.isActive">history</mat-icon>
      </ng-template>
      <ng-container *ngIf="jobHistory">
        <app-job-history-tab
          [jobHistory]="jobHistory"
          [canLoadMore]="canLoadMore"
          [timezone]="timezone"
          (loadMoreItems)="loadMoreItems()"></app-job-history-tab>
      </ng-container>
    </mat-tab>
    <mat-tab disabled [labelClass]="['import-config', !repairPickUpAvailable ? 'no-pickup-job' : '']">
      <ng-template mat-tab-label>
        <button mat-icon-button (click)="configureImportDirectories()" type="button">
          <mat-icon svgIcon="folder_edit"></mat-icon>
        </button>
      </ng-template>
    </mat-tab>
    <mat-tab disabled [labelClass]="'pick-up-job'" *ngIf="repairPickUpAvailable">
      <ng-template mat-tab-label>
        <button (click)="pickupJob()" mat-raised-button>+ <mat-icon svgIcon="allianz"></mat-icon></button>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
