import { Component, inject, OnInit, signal } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UserService } from '../../../services/user.service';
import { CurrentUserService } from '../../../services/currentUser.service';
import { ToolbarService } from '../../../services/toolbar.service';
import { MessagingService } from '../../../components/messaging/messaging.service';
import { EscapeDialogHelper } from '../../../helpers/escape-dialog.helper';
import { UploadFilesComponent } from '../upload-files/upload-files.component';
import { mediumModalConfig } from '../../../consts/modal-config.const';
import { ModalConfigsHelper } from '../../../helpers/modal-configs.helper';
import { VehicleDetails } from '../../../model/vehicle-details.model';
import { ParseXMLService } from '../../../services/parseXML.service';

@Component({
  selector: 'app-add-quote',
  templateUrl: './add-quote.component.html'
})
export class AddQuoteComponent implements OnInit {
  quoteForm: UntypedFormGroup;
  pending = signal(false);
  pendingImages = signal(false);
  fileName: string;
  extractedVehicleData: VehicleDetails;
  importVehicleDetails = false;
  lastModified: number;
  quoteFiles: any;
  importQuoteFiles: any;
  imageFiles: any;
  timezone: string;
  uploadImages = true;
  fileDeleted = false;
  selectedIndex = 0;
  imagesPluralMapping = { '=1': '1 image', other: '# images' };
  get quoteNumber() {
    return this.quoteForm.get('quoteNumber');
  }
  get importLastQuote() {
    return this.quoteForm.get('importLastQuote');
  }

  data = inject(MAT_DIALOG_DATA);
  private dialogRef = inject(MatDialogRef<AddQuoteComponent>);
  private uploadFiles = inject(MatDialog);
  private fb = inject(UntypedFormBuilder);
  private toolbarService = inject(ToolbarService);
  private message = inject(MessagingService);
  private userService = inject(UserService);
  private currentUserService = inject(CurrentUserService);
  private escapeDialogRef = inject(EscapeDialogHelper);
  private modalConfigsHelper = inject(ModalConfigsHelper);
  private parseXMLService = inject(ParseXMLService);

  ngOnInit(): void {
    this.quoteForm = this.fb.group({
      quoteNumber: ['', Validators.required],
      importLastQuote: true
    });
    this.escapeDialogRef.escapeDialog(this.dialogRef);
    this.timezone = this.currentUserService?.timeZone;
    this.selectedIndex = this.data?.selectedIndex;
  }

  async importQuote(quoteInput: any) {
    if (quoteInput.files.length) {
      this.pending.set(true);
      const xmlValid = await this.parseXMLService.checkXmlValidity(quoteInput.files[0]);
      if (xmlValid) {
        this.pendingImages.set(true);
        this.fileDeleted = false;
        this.fileName = quoteInput.files[0].name;
        this.lastModified = quoteInput.files[0].lastModified;
        this.quoteFiles = quoteInput.files;
        this.importQuoteFiles = quoteInput.files;
        this.extractVehicleInfo();
      } else {
        quoteInput.value = '';
      }
      this.pending.set(false);
    }
  }

  setFileFromHandler(file: any) {
    this.pendingImages.set(true);
    this.fileName = file?.name;
    this.lastModified = file?.lastModified;
    this.quoteFiles = file;
    this.extractVehicleInfo();
  }

  setImages(images: any[]) {
    this.imageFiles = images;
    this.pendingImages.set(false);
  }

  deleteFile(quoteInput: any) {
    this.fileName = '';
    this.lastModified = undefined;
    this.quoteFiles = undefined;
    delete this.importQuoteFiles;
    quoteInput.value = '';
    this.fileDeleted = true;
    this.imageFiles = null;
    this.extractedVehicleData = null;
    this.importVehicleDetails = false;
  }

  private extractVehicleInfo() {
    if ((this.data?.notAuthorised || this.data?.noQuote) && this.quoteFiles) {
      const formData: FormData = new FormData();
      formData.append('quote', this.quoteFiles.length ? this.quoteFiles[0] : this.quoteFiles);

      this.userService.extractVehicleInfoFromQuote(this.data.jobId, formData).subscribe((result) => {
        this.extractedVehicleData = result;
      });
    }
  }

  addQuote(manualQuote: boolean) {
    if (manualQuote) {
      this.pending.set(true);
      this.toolbarService.setCloudState('SAVING');
      this.userService
        .addManualQuote(
          this.quoteNumber.value.trim(),
          this.data.jobId,
          this.data?.notAuthorised ? this.importLastQuote.value : false
        )
        .subscribe({
          next: () => this.dialogRef.close('Success'),
          error: () => {
            this.message.error('Could not add quote.');
            this.toolbarService.setCloudState('RESTING');
            this.pending.set(false);
          },
          complete: () => {
            this.toolbarService.setCloudState('RESTING');
            this.pending.set(false);
          }
        });
    } else {
      const data = {
        jobId: this.data.jobId,
        files: this.quoteFiles,
        isQuote: true,
        importVehicleInfo: this.importVehicleDetails
      };
      const dialogRef = this.uploadFiles.open(
        UploadFilesComponent,
        this.modalConfigsHelper.buildMediumModalConfig(data, mediumModalConfig)
      );
      dialogRef.afterClosed().subscribe((result) => {
        if (result === 'Success') {
          this.message.confirm('Quote Uploaded');
          this.uploadImages && this.imageFiles?.length ? this.addImages() : this.dialogRef.close('Success');
        }
      });
    }
  }

  private addImages() {
    const data = { jobId: this.data.jobId, files: this.imageFiles, isPhotos: true };
    const dialogRef = this.uploadFiles.open(
      UploadFilesComponent,
      this.modalConfigsHelper.buildMediumModalConfig(data, mediumModalConfig)
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'Success') {
        this.message.confirm(`Photos Uploaded`);
      }
      this.dialogRef.close('Success');
    });
  }
}
