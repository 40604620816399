import { Injectable, Injector } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { LightboxComponent } from './lightbox.component';
import { LightboxRef } from './lightbox-ref';
import { IMAGE_TOKEN } from './lightbox.tokens';
import { PhotoReference } from '../../../model/job.model';

const lightboxConfig: LightboxConfig = {
  hasBackdrop: true,
  backdropClass: 'dark-backdrop',
  panelClass: 'tm-file-preview-dialog-panel',
  imageGallerySelector: null
};

@Injectable()
export class LightboxService {
  overlayRef: OverlayRef;
  boxRef: LightboxRef;

  constructor(
    private injector: Injector,
    private overlay: Overlay
  ) {}

  open(config: LightboxConfig = {}) {
    const boxConfig = { ...lightboxConfig, ...config };
    this.overlayRef = this.createOverlay(boxConfig);

    // Instantiate remote control
    this.boxRef = new LightboxRef(this.overlayRef);

    this.attachBoxContainer(this.overlayRef, boxConfig, this.boxRef);
  }

  private createOverlay(config: LightboxConfig) {
    const overlayConfig = this.getOverlayConfig(config);
    return this.overlay.create(overlayConfig);
  }

  private getOverlayConfig(config: LightboxConfig): OverlayConfig {
    const positionStrategy = this.overlay.position().global().centerHorizontally().centerVertically();

    return new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      panelClass: config.panelClass,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy
    });
  }

  private attachBoxContainer(overlayRef: OverlayRef, config: LightboxConfig, boxRef: LightboxRef) {
    const injector = Injector.create({
      parent: this.injector,
      providers: [
        { provide: LightboxRef, useValue: boxRef },
        { provide: IMAGE_TOKEN, useValue: config.imageGallerySelector }
      ]
    });
    const containerPortal = new ComponentPortal(LightboxComponent, null, injector);
    overlayRef.attach(containerPortal);
  }

  closeAll() {
    this.boxRef?.close();
  }
}

interface LightboxConfig {
  panelClass?: string;
  hasBackdrop?: boolean;
  backdropClass?: string;
  imageGallerySelector?: ImageGallerySelector;
}

export interface ImageGallerySelector {
  images: PhotoReference[];
  repairerSiteId: number;
  jobId: number;
  selectedIndex: number;
}
