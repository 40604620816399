<form [formGroup]="form" (ngSubmit)="saveOrExecuteAction()" class="mt-25">
  <div class="grid grid-cols-[auto,160px] gap-20">
      <mat-form-field appearance="outline" color="accent">
        <mat-label>Company</mat-label>
        <input trimInput matInput formControlName="companyName"/>
        <mat-error *ngIf="companyName.hasError('required')">
          Company Name is <strong>{{getErrorMessage(companyName.errors)}}</strong>
        </mat-error>
      </mat-form-field>
    <mat-form-field appearance="outline" color="accent">
        <mat-label>Country</mat-label>
        <mat-select
          formControlName="country"
          (selectionChange)="clearStateAndCity(countryName.value)"
          #countryName>
          <mat-option *ngFor="let country of countries" [value]="country.stateName">
            {{ country.abbreviation }}
          </mat-option>
        </mat-select>
      <mat-error *ngIf="country.hasError('required')"> Country is <strong>{{getErrorMessage(country.errors)}}</strong>
      </mat-error>
      </mat-form-field>
    </div>

  <div class="grid grid-cols-[160px,auto,160px] gap-20">
    <mat-form-field appearance="outline" color="accent">
        <mat-label>{{ !country?.value || country?.value === 'Australia' ? 'ABN' : 'NZBN' }}</mat-label>
      <input [trimInput]="country.value === 'New Zealand'" matInput formControlName="abn"
             (focusout)="adjustFormatOfAbn()"/>
        <mat-error *ngIf="abn.hasError('required')">
          {{ !country?.value || country?.value === 'Australia' ? 'ABN' : 'NZBN' }} is
          <strong>{{getErrorMessage(abn.errors)}}</strong>
        </mat-error>
      <mat-error *ngIf="abn.hasError('invalidABN') && !abn.hasError('required') && !abn.hasError('duplicateAbn')">
        {{getErrorMessage(abn.errors)}}
        </mat-error>
      <mat-error *ngIf="abn.hasError('duplicateAbn')">{{getErrorMessage(abn.errors)}}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" color="accent">
        <mat-label>Email</mat-label>
        <input trimInput matInput formControlName="email"/>
        <mat-error *ngIf="email.hasError('required')"> Email is <strong>{{getErrorMessage(email.errors)}}</strong>
        </mat-error>
        <mat-error *ngIf="!email.valid && !email.hasError('required')">
          Please enter a valid email address
        </mat-error>
      </mat-form-field>
    <mat-form-field appearance="outline" color="accent">
        <mat-label>Contact</mat-label>
        <input matInput formControlName="phone"/>
      </mat-form-field>
    </div>

  <div class="grid grid-cols-2 gap-20">
      <mat-form-field appearance="outline" color="accent">
        <mat-label>Address</mat-label>
        <input trimInput matInput formControlName="addressLine1"/>
      </mat-form-field>
      <mat-form-field appearance="outline" color="accent">
        <mat-label>Address 2</mat-label>
        <input trimInput matInput formControlName="addressLine2"/>
      </mat-form-field>
    </div>

  <div class="grid"
       [ngClass]="!country.value ? 'grid-cols-[auto,160px] gap-20' : 'grid-cols-[auto,160px,160px] gap-[23px]'">
    <mat-form-field appearance="outline" color="accent">
        <mat-label>Suburb / Town</mat-label>
      <input trimInput matInput formControlName="suburb"/>
      </mat-form-field>
      <mat-form-field
        appearance="outline"
        color="accent"
        *ngIf="country.value === 'Australia'">
        <mat-label>State</mat-label>
        <mat-select formControlName="state">
          <mat-option *ngFor="let state of states" [value]="state.abbreviation">
            {{ state.abbreviation }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field
        appearance="outline"
        color="accent"
        *ngIf="country.value === 'New Zealand'">
        <mat-label>City</mat-label>
        <input trimInput matInput formControlName="city"/>
      </mat-form-field>
    <mat-form-field appearance="outline" color="accent">
        <mat-label>Postcode</mat-label>
      <input trimInput matInput formControlName="postCode"/>
      </mat-form-field>
    </div>
  </form>

