import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SetDirectoryData } from '../../../model/directory-data.model';

@Component({
  selector: 'app-set-directory',
  templateUrl: './set-directory.component.html',
  styleUrls: ['./set-directory.component.scss']
})
export class SetDirectoryComponent {
  @Input() data: SetDirectoryData;
  @Output() setDirectory: EventEmitter<string> = new EventEmitter();
  @Output() deleteDirectory: EventEmitter<string> = new EventEmitter();
  constructor() {}
}
