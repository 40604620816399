import {
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { UserRepairerSite } from '../../../model/user.model';
import { GoogleMapsService } from '../../../services/google-map.service';
import { BreakpointObserverHelper } from '../../../helpers/breakpoint-observer.helper';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { combineLatest, Observable } from 'rxjs';

interface ColumnClassMapping {
  dataColumn: number;
  minWidth: number;
  dataColumnClass: number;
}

@Component({
  selector: 'company-information',
  templateUrl: './company-information.component.html',
  styleUrls: ['./company-information.component.scss']
})
export class CompanyInformationComponent implements OnInit, OnChanges {
  @Input() repairerSite: UserRepairerSite;
  @Input() markerOptions: google.maps.MarkerOptions;
  @Input() options: google.maps.MapOptions;
  @Output() editCompanyAddress: EventEmitter<any> = new EventEmitter<any>();
  dataColumnStyle = '';
  businessNameStyle = '';
  private dataColumnNumber = 2;

  googleMapsService = inject(GoogleMapsService);
  private breakpointObserver = inject(BreakpointObserverHelper);
  private destroyRef = inject(DestroyRef);

  ngOnInit() {
    const maxWidthBreakpointsArray = [800, 900, 1100, 1200, 1300];
    const breakpointObservables: Observable<boolean>[] = [];
    maxWidthBreakpointsArray.forEach((width) =>
      breakpointObservables.push(this.breakpointObserver.observeMaxWidth(width))
    );

    combineLatest(breakpointObservables)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => this.updateColumnNumberByScreenSize()
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.repairerSite?.currentValue) {
      this.dataColumnNumber = this.getDataColumnNumber();
      this.updateColumnNumberByScreenSize();
    }
  }

  /**
   * Counts the number of non-empty data keys to determine the data column count dynamically.
   */
  private getDataColumnNumber(): number {
    const dataKeysArray = ['companyName', 'abn', 'licenseNumber', 'phoneNumber', 'email', 'quotingSystemsString'];
    return dataKeysArray.filter((key) => this.repairerSite[key]).length;
  }

  /**
   * Updates the column number and styling based on the current screen size.
   * Uses predefined mappings to adjust column classes.
   * If no matching mapping is found, applies default classes.
   */
  private updateColumnNumberByScreenSize(): void {
    const windowWidth = window.innerWidth;

    const columnMapping: ColumnClassMapping[] = [
      { dataColumn: 6, minWidth: 1100, dataColumnClass: 3 },
      { dataColumn: 6, minWidth: 1300, dataColumnClass: this.dataColumnNumber - 1 },
      { dataColumn: 5, minWidth: 800, dataColumnClass: 2 },
      { dataColumn: 5, minWidth: 1200, dataColumnClass: this.dataColumnNumber - 1 },
      { dataColumn: 4, minWidth: 900, dataColumnClass: this.dataColumnNumber - 1 }
    ];

    /**
     * Find a mapping from the columnMapping array that matches the current dataColumnNumber and screen width.
     * This mapping will be used to apply appropriate column styling.
     */
    const matchingMapping = columnMapping.find(
      (mapping) => this.dataColumnNumber === mapping.dataColumn && windowWidth <= mapping.minWidth
    );

    if (matchingMapping) {
      this.applyClassesFromMapping(matchingMapping.dataColumnClass);
    } else {
      this.defaultClasses();
    }
  }

  /**
   * Adjusts business name class and parent div class based on the provided mapping.
   */
  private applyClassesFromMapping(columnNumber: number): void {
    this.dataColumnStyle = `repeat(${columnNumber}, minmax(0, 1fr))`;
    this.businessNameStyle = columnNumber === 0 ? '' : `span ${columnNumber} / span ${columnNumber}`;
  }

  /**
   * Sets default business name class and parent div class.
   */
  private defaultClasses(): void {
    this.dataColumnStyle = `repeat(${this.dataColumnNumber + 1}, minmax(0, 1fr))`;
    this.businessNameStyle = 'span 2 / span 2';
  }
}
