export enum RepairerSitesSortEnum {
  COMPANY_NAME = 'COMPANY_NAME',
  REPAIRER_ID = 'REPAIRER_ID',
  ADMINISTRATOR_NAME = 'ADMINISTRATOR_NAME',
  ADMIN_USERNAME = 'ADMIN_USERNAME',
  ADMIN_EMAIL = 'ADMIN_EMAIL',
  NB_USERS = 'NB_USERS',
  PHONE = 'PHONE',
  ABN = 'ABN',
  ADDRESS = 'ADDRESS',
  QUOTING_SYSTEM = 'QUOTING_SYSTEM',
  DASHBOARD_DAYS = 'DASHBOARD_DAYS',
  REPAIRER_GROUP = 'GROUP',
  LEGACY_CUSTOMER_ID = 'LEGACY_CUSTOMER_ID',
  PUBLISHED_DATETIME = 'PUBLISHED_DATETIME'
}
