import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { MessagingService } from '../components/messaging/messaging.service';
import { UserService } from '../services/user.service';
import { RedirectHelper } from '../helpers/redirect.helper';
import { CurrentUserService } from '../services/currentUser.service';
import { ToolbarService } from '../services/toolbar.service';
import { takeUntil } from 'rxjs/operators';
import { SysAdminService } from '../services/sys-admin.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  which: 'LOGIN' | 'RESET' = 'LOGIN';
  loginForm: UntypedFormGroup;
  unauthorized = '';
  hidePassword = true;
  private unsubscribe = new Subject();

  constructor(
    private auth: AuthService,
    private router: Router,
    private message: MessagingService,
    private userService: UserService,
    private redirectHelper: RedirectHelper,
    private currentUserService: CurrentUserService,
    private toolbarService: ToolbarService,
    private sysAdminService: SysAdminService
  ) {}

  ngOnInit() {
    this.toolbarService.pausePingInterval.next(true);
    this.loginForm = new UntypedFormGroup({
      username: new UntypedFormControl('', [Validators.required]),
      password: new UntypedFormControl('', [Validators.required])
    });
  }

  login() {
    const username: string = this.loginForm.get('username').value;
    const password: string = this.loginForm.get('password').value;
    this.auth
      .login({ username, password })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: () => {
          this.toolbarService.pausePingInterval.next(false);
        },
        error: (error) => {
          if (error.status === 403) {
            if (error.error.errorCode === 'FAILED_ATTEMPT') {
              // Auth Provider rejected the credentials
              this.unauthorized = 'Your username or password could not be validated. Please review your information.';
            } else if (error.error.errorCode === 'LOCKED') {
              this.unauthorized = 'Too many attempts, your account has been locked. Please try again in 2 minutes.';
            } else if (error.error.errorCode === 'USER_NOT_ENABLED') {
              this.unauthorized =
                'Your account information could not be verified. Please contact your System Administrator.';
            }
          } else {
            // There was am unexpected problem
            this.message.error('There was a problem logging you in. Please try again.');
          }
        },
        complete: () => {
          this.userService
            .getCurrentUser()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe({
              next: (user) => this.currentUserService.userLogin(user),
              error: () => {},
              complete: () => {
                this.revokeAccessRepairerSite();
              }
            });
        }
      });
  }

  reset() {
    const params = {
      queryParams: { username: this.loginForm.get('username').value }
    };

    if (this.loginForm.get('username').value) {
      this.router.navigate(['/resetPassword/'], params);
    } else {
      this.router.navigate(['/resetPassword']);
    }
  }

  revokeAccessRepairerSite() {
    if (
      this.currentUserService.currentUserValue.privileges.some((privilege) =>
        ['SYSADMIN', 'MLADMIN'].includes(privilege)
      ) &&
      this.currentUserService.currentUserValue.privileges.includes('REPADMIN')
    ) {
      this.sysAdminService.revokeAccessRepairerSite().subscribe();
    } else {
      this.redirectHelper.manageRedirection(this.currentUserService.currentUserValue, this.router);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
