import { Component, OnInit, ViewChild } from '@angular/core';
import { MonitorService } from './monitor.service';
import { MessagingService } from '../components/messaging/messaging.service';
import { SiteFunctionInvocationStats } from './site-function-invocation-stats.model';
import { ToolbarService } from '../services/toolbar.service';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { FunctionInvocationStats } from './function-invocation-stats.model';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-monitor',
  templateUrl: './monitor.component.html',
  styleUrls: ['./monitor.component.scss']
})
export class MonitorComponent implements OnInit {
  @ViewChild('functionInvocationStatsSort', { static: true }) functionInvocationStatsSort: MatSort;

  siteFunctionInvocationStats: SiteFunctionInvocationStats;
  functionInvocationStatsActiveColumn = 'function';
  functionInvocationStatsListDataSource: MatTableDataSource<FunctionInvocationStats>;
  functionInvocationStatsListColumnHeaders = [
    'functionName',
    'totalExecutionTimeMilli',
    'averageExecutionTimeMilli',
    'worstExecutionTimeMilli',
    'nbSuccess',
    'nbFailure'
  ];

  constructor(
    private monitorService: MonitorService,
    private message: MessagingService,
    public toolbarService: ToolbarService
  ) {}

  ngOnInit(): void {
    this.toolbarService.toolbarData({ pageName: 'Monitor', backLink: '' });

    this.getSiteFunctionInvocationStats();
  }

  getSiteFunctionInvocationStats(): any {
    return this.monitorService.getSiteFunctionInvocationStats().subscribe(
      (siteFunctionInvocationStats) => (this.siteFunctionInvocationStats = siteFunctionInvocationStats),
      () => {
        this.message.error('Could not retrieve the list of function invocation stats.');
      },
      () => {
        this.functionInvocationStatsListDataSource = new MatTableDataSource(
          this.siteFunctionInvocationStats.functionInvocationStatsList
        );
        this.functionInvocationStatsListDataSource.sortingDataAccessor = this.sortingFunctionInvocationStats;
        this.functionInvocationStatsListDataSource.sort = this.functionInvocationStatsSort;
      }
    );
  }

  clearSiteFunctionInvocationStats(): any {
    return this.monitorService.clearSiteFunctionInvocationStats().subscribe(
      (siteFunctionInvocationStats) => (this.siteFunctionInvocationStats = siteFunctionInvocationStats),
      () => {
        this.message.error('Could not clear the list of function invocation stats.');
      },
      () => {
        this.functionInvocationStatsListDataSource = new MatTableDataSource(
          this.siteFunctionInvocationStats.functionInvocationStatsList
        );
        this.functionInvocationStatsListDataSource.sortingDataAccessor = this.sortingFunctionInvocationStats;
        this.functionInvocationStatsListDataSource.sort = this.functionInvocationStatsSort;
      }
    );
  }

  sortingFunctionInvocationStats(item, property) {
    return item[property] ? item[property].toString().toLowerCase() : '';
  }
}
