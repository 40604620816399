import { Component, Inject, OnInit } from '@angular/core';
import { EscapeDialogHelper } from '../../helpers/escape-dialog.helper';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UserService } from '../../services/user.service';
import { InsurerPickUp } from '../../model/insurer.model';
import { MessagingService } from '../../components/messaging/messaging.service';
import { Moment } from 'moment';
import * as moment from 'moment';
import { PickUpFormRequest } from '../../model/pickUpFormRequest.model';
import { EIO_DATE_BE_FORMAT, EIO_DATE_UI_FORMAT_MOMENT } from '../../consts/localization.const';
import { ToolbarService } from '../../services/toolbar.service';
import { DateInputHelper } from '../../helpers/date-input.helper';

@Component({
  selector: 'app-pickup-job',
  templateUrl: './pickup-job.component.html',
  styleUrls: ['./pickup-job.component.scss']
})
export class PickupJobComponent implements OnInit {
  pickupForm: UntypedFormGroup;
  insurers: InsurerPickUp[];
  showInspectionDate = false;
  availableDates: Moment[] = [];
  minDate: Moment;
  pending = false;
  showProgressBar = false;

  constructor(
    public dialogRef: MatDialogRef<PickupJobComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder,
    private userService: UserService,
    private message: MessagingService,
    public toolbarService: ToolbarService,
    private escapeDialogRef: EscapeDialogHelper,
    public dateInputHelper: DateInputHelper
  ) {}

  get insurer() {
    return this.pickupForm.get('insurer');
  }

  get rego() {
    return this.pickupForm.get('rego');
  }

  get claimNumber() {
    return this.pickupForm.get('claimNumber');
  }

  get inspectionDate() {
    return this.pickupForm.get('inspectionDate');
  }

  ngOnInit(): void {
    this.escapeDialogRef.escapeDialog(this.dialogRef);

    this.pickupForm = this.fb.group({
      insurer: ['', Validators.required],
      rego: ['', Validators.required],
      claimNumber: [''],
      inspectionDate: ''
    });

    this.insurers = this.data.insurers;
    this.minDate = this.data.currentTimeZone ? moment().tz(this.data.currentTimeZone) : moment();
  }

  selectInsurer() {
    const insurer = this.insurers.find((ins) => ins.insurerId === this.insurer.value);
    if (insurer.rule && insurer.rule.actionRules) {
      const validators = [];
      insurer.rule.actionRules.forEach((rule) => {
        if (rule.comparedAttribute === 'inspectionDate') {
          if (rule.attributeComparator === 'valuesNotIn' && rule.compareValues) {
            let found = false;
            const values = rule.compareValues.split(';');
            for (const value of values) {
              found = insurer.repairerLabels.includes(value);
              if (found) {
                return;
              }
            }
            this.showInspectionDate = !found;
          }
        }
      });
    }
    if (this.showInspectionDate) {
      this.userService.getNextAvailableInspectionDates(this.insurer.value).subscribe(
        (availableDates) => availableDates.forEach((date) => this.availableDates.push(moment.unix(date))),
        () => this.message.error('Could not retrieve the next available inspection dates.')
      );
    }
    if (!insurer.rule.actionRules.find((rule) => rule.comparedAttribute === 'claimNumber')) {
      this.claimNumber.clearValidators();
    }
    this.claimNumber.updateValueAndValidity();
  }

  availableDatesFilter = (m: Moment | null): boolean => {
    let found = false;
    for (const date of this.availableDates) {
      found =
        (m || moment()).date() === date.date() &&
        (m || moment()).month() === date.month() &&
        (m || moment()).year() === date.year();
      if (found) {
        break;
      }
    }
    return found;
  };

  pickUpJob() {
    this.pending = true;
    this.showProgressBar = true;
    this.toolbarService.setCloudState('SAVING');
    const request: PickUpFormRequest = {
      rego: this.rego.value
    };
    if (this.claimNumber.value) {
      request.claimNumber = this.claimNumber.value;
    }
    if (this.inspectionDate.value) {
      request.inspectionDate = this.availableDates
        .find((d) => d.format(EIO_DATE_BE_FORMAT) === moment(this.inspectionDate.value).format(EIO_DATE_BE_FORMAT))
        .unix();
    }
    this.userService.createPendingAssessment(this.insurer.value, request).subscribe(
      (response) => {
        if (response) {
          this.dialogRef.close({
            status: response.statusCode,
            result: response.result,
            inspectionDate:
              response.result === 'PreAssigned'
                ? response.inspectionDate
                  ? moment(response.inspectionDate.gmtDate).format(EIO_DATE_UI_FORMAT_MOMENT)
                  : ''
                : this.showInspectionDate
                ? this.inspectionDate.value
                  ? moment(this.inspectionDate.value).format(EIO_DATE_UI_FORMAT_MOMENT)
                  : 'Invalid date'
                : ''
          });
        } else {
          // We just receive null when there is an error.
          this.message.error('There was a technical problem while trying to process your request');
        }
      },
      () => {
        this.message.error('There was a technical problem while trying to process your request');
        this.toolbarService.setCloudState('RESTING');
        this.pending = false;
        this.showProgressBar = false;
      },
      () => {
        this.toolbarService.setCloudState('RESTING');
        this.pending = false;
        this.showProgressBar = false;
      }
    );
  }
}
