export enum RepairTimelineStatus {
  REPAIRS_BOOKED = 'Repairs Booked',
  VEHICLE_IN = 'Vehicle In',
  REPAIRS_STARTED = 'Repairs Started',
  REPAIRS_COMPLETED = 'Repairs Completed',
  VEHICLE_OUT = 'Vehicle Out'
}

export enum RepairTimelineColors {
  PRIMARY = '#0BA14B',
  SECONDARY = '#FDF1BA'
}
