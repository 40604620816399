import { Directive, ElementRef, HostListener, Input, OnDestroy } from '@angular/core';

@Directive({
  selector: '[appRetrySrc]'
})
export class RetrySrcDirective implements OnDestroy {
  private retrySrcName: string;
  private timer;

  constructor(private element: ElementRef<HTMLImageElement>) {}

  @HostListener('error') onError() {
    this.loadError();
  }

  @HostListener('load') onLoad() {
    this.clearTimer();
  }

  @Input('appRetrySrc')
  set appRetrySrc(value: string) {
    this.clearTimer();
    this.retrySrcName = value;
    this.element.nativeElement.src = value;
  }

  ngOnDestroy() {
    this.clearTimer();
  }

  loadError() {
    this.clearTimer();
    this.timer = setTimeout(() => (this.element.nativeElement.src = this.retrySrcName), 5000);
  }

  clearTimer() {
    if (this.timer) {
      clearTimeout(this.timer);
      delete this.timer;
    }
  }
}
