<div class="dialog-header" mat-dialog-title>
  <h6>End-User License Agreement</h6>
  <div class="eula-accepted-info body-1">
    <mat-icon>info_outline</mat-icon>
    <div class="accepted-info-text">
      Accepted on {{ currentUser.eulaApprovedDateTime | dateepoch: timezone }} by {{ currentUser.eulaApprovedBy }}
    </div>
  </div>
  <button aria-label="Close" mat-dialog-close mat-icon-button>
    <mat-icon>close</mat-icon>
  </button>
</div>
<div mat-dialog-content>
  <mat-divider></mat-divider>
  <div class="eula subtitle-2" [innerHTML]="eulaContent"></div>
  <mat-divider></mat-divider>
</div>
