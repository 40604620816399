export interface Address {
  addressLine1: string;
  addressLine2: string;
  postCode: string;
  state?: string;
  city?: string;
  suburb: string;
  country?: string;
  latitude?: number;
  longitude?: number;
}

export const AU_STATES: string[] = ['ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA'];

export enum CountryEnum {
  'Australia' = 'AU',
  'New Zealand' = 'NZ'
}
