import { animate, style, transition, trigger } from '@angular/animations';

export const fadeSlideUpDown = trigger('fadeSlideUpDown', [
  transition(':leave', [
    style({ opacity: 1, height: '*', transform: 'scaleY(1)' }),
    animate('150ms ease', style({ opacity: 0, height: 0, transform: 'scaleY(.1)' }))
  ]),
  transition(':enter', [
    style({ opacity: 0, height: 0, transform: 'scaleY(.1)' }),
    animate('150ms ease', style({ opacity: 1, height: '*', transform: 'scale(1)' }))
  ])
]);
