<mat-expansion-panel
  (closed)="documentsCurtainOpened = false"
  (opened)="documentsCurtainOpened = true"
  class="curtains documents-curtain"
  [hideToggle]="blockExpansion"
  [expanded]="blockExpansion"
  #panel>
  <mat-expansion-panel-header
    [collapsedHeight]="'100px'"
    [expandedHeight]="'100px'"
    class="document-curtain-header"
    [class.header-not-expanded]="blockExpansion">
    <mat-panel-title [ngClass]="{ 'opened-gallery': documentsCurtainOpened }" class="documents-title">
      <h6>{{ docs.length }} {{ docs.length === 1 ? 'Document' : 'Documents' }}</h6>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <mat-panel-description class="document-gallery-container">
    <div class="document-gallery-row">
      <div class="document-item" *ngFor="let doc of docs; let i = index">
        <div
          class="document-icon"
          [class.doc-pdf]="extensionToOpen.includes(doc.extension)"
          (click)="
            extensionToOpen.includes(doc.extension) && openOrDownloadFile(doc, 'open'); $event.stopPropagation()
          ">
          <mat-icon svgIcon="{{ doc.extension + '_icon' }}"></mat-icon>
        </div>
        <div class="document-info">
          <div class="subtitle-2 display-ellipsis" [matTooltip]="doc.filename" matTooltipClass="" showTruncatedText>
            {{ doc.filename }}
          </div>
        </div>
        <button
          type="button"
          mat-icon-button
          class="kebab-panel"
          [mat-menu-trigger-for]="actionsMenu"
          (click)="$event.stopPropagation()">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #actionsMenu="matMenu" backdropClass="custom-menu">
          <button type="button" mat-menu-item class="custom-menu-item" (click)="openOrDownloadFile(doc, 'download')">
            Download
          </button>
          <button
            type="button"
            mat-menu-item
            class="custom-menu-item"
            *ngIf="extensionToOpen.includes(doc.extension)"
            (click)="openOrDownloadFile(doc, 'open')">
            Open
          </button>
          <button
            type="button"
            mat-menu-item
            class="custom-menu-item"
            *ngIf="
              !doc.submitted ||
              (currentUserService.currentUserValue?.privileges?.includes('SYSADMIN') && !doc.isFromEIM)
            "
            (click)="confirmDeleteDoc(doc.id, doc.submitted)">
            Delete
          </button>
        </mat-menu>
      </div>
    </div>
  </mat-panel-description>
</mat-expansion-panel>
