<div class="dialog-header" mat-dialog-title>
  <h6>
    {{ data?.repairerSite ? (data?.isEditRepairerSite ? 'Edit Repairer' : 'Re-Invite Repairer') : 'Add Repairer' }}
  </h6>
  <button aria-label="Close" mat-dialog-close mat-icon-button>
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-divider></mat-divider>
<form
  [formGroup]="repairerForm"
  (ngSubmit)="
    data?.repairerSite ? (data.isEditRepairerSite ? editRepairerSite() : reinviteRepairerSite()) : addRepairerSite()
  ">
  <section mat-dialog-content>
    <div class="title-1 pt-5 text-dove-gray mb-10">Company Information</div>

    <article class="grid grid-cols-3 gap-20">
      <mat-form-field appearance="outline" color="accent" class="col-span-2">
        <mat-label>Company Name</mat-label>
        <input formControlName="companyName" matInput required />
        <mat-error *ngIf="companyName.hasError('required')"> Company Name is <strong>required</strong> </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" color="accent">
        <mat-label>Repairer Group</mat-label>
        <mat-select formControlName="repairerGroupId">
          <mat-option *ngFor="let group of data?.repairersGroup" [value]="group.id">
            {{ group.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </article>

    <article class="grid grid-cols-3 gap-x-20">
      <mat-form-field appearance="outline" color="accent">
        <mat-label>Country</mat-label>
        <mat-select formControlName="country" required>
          <mat-option *ngFor="let country of countries" [value]="country.stateName">
            {{ country.abbreviation }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="country.hasError('required')"> Country is <strong>required</strong> </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" color="accent">
        <mat-label>{{ !country?.value || country?.value === 'Australia' ? 'ABN' : 'NZBN' }}</mat-label>
        <input formControlName="abn" matInput required (focusout)="adjustFormatOfAbn()" />
        <mat-error *ngIf="abn.hasError('required')">
          {{ !country?.value || country?.value === 'Australia' ? 'ABN' : 'NZBN' }} is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="!abn.valid && !abn.hasError('required')" class="mat-error">
          Please enter a valid ABN
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" color="accent">
        <mat-label>License Number</mat-label>
        <input formControlName="licenseNumber" matInput />
      </mat-form-field>

      <mat-form-field appearance="outline" color="accent">
        <mat-label>Legacy Customer ID</mat-label>
        <input formControlName="legacyCustomerId" type="number" matInput />
      </mat-form-field>

      <mat-form-field appearance="outline" color="accent">
        <mat-label>Quoting System</mat-label>
        <mat-select formControlName="quotingSystem">
          <mat-option *ngFor="let item of quotingSystemList" [value]="item">
            {{ item }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </article>

    <article class="grid grid-cols-2 gap-20">
      <mat-form-field appearance="outline" color="accent">
        <mat-label>Phone Number</mat-label>
        <input formControlName="phoneNumber" appNumbersOnly matInput required />
        <mat-error *ngIf="phoneNumber.invalid"> Phone number is <b>required</b> </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" color="accent">
        <mat-label>Email</mat-label>
        <input formControlName="emailCompany" matInput required />
        <mat-error *ngIf="emailCompany.invalid && emailCompany.hasError('required')">
          Email is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="emailCompany.invalid && emailCompany.hasError('pattern')">
          Please enter a valid email address
        </mat-error>
      </mat-form-field>
    </article>

    <div class="title-1 pt-5 text-dove-gray mb-10">Repairer Administrator</div>

    <article class="grid grid-cols-3 gap-20">
      <mat-form-field appearance="outline" color="accent">
        <mat-label>Username</mat-label>
        <input formControlName="username" matInput required />
        <mat-error *ngIf="username.hasError('required')"> Username is <strong>required</strong> </mat-error>
        <mat-error *ngIf="!username.valid && !username.hasError('required')">
          This username exists already in the system
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" color="accent">
        <mat-label>First Name</mat-label>
        <input formControlName="firstName" matInput required />
        <mat-error *ngIf="firstName.hasError('required')"> First Name is <strong>required</strong> </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" color="accent">
        <mat-label>Last Name</mat-label>
        <input formControlName="lastName" matInput required />
        <mat-error *ngIf="lastName.hasError('required')"> Last Name is <strong>required</strong> </mat-error>
      </mat-form-field>
    </article>

    <article class="grid grid-cols-2 gap-20">
      <mat-form-field appearance="outline" color="accent">
        <mat-label>Email</mat-label>
        <input formControlName="emailRepairer" matInput required />
        <mat-error *ngIf="emailRepairer.hasError('required')"> Email is <strong>required</strong> </mat-error>
        <mat-error *ngIf="!emailRepairer.valid && !emailRepairer.hasError('required')" class="mat-error">
          Please enter a valid email address
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" color="accent">
        <mat-label>Contact</mat-label>
        <input formControlName="phone" matInput />
      </mat-form-field>
    </article>

    <div
      class="flex items-center justify-center pb-5 text-dove-gray"
      *ngIf="
        !data?.isEditRepairerSite ||
        (!!emailRepairer.value && emailRepairer.value !== data.repairerSite.mainContact.emailAddress)
      ">
      <mat-icon class="pr-5">info_outline</mat-icon>
      <span class="body-1">An email will be sent to this email address to complete repairer information.</span>
    </div>
  </section>
  <mat-dialog-actions>
    <button mat-raised-button mat-dialog-close color="accent" type="button">CANCEL</button>
    <button [disabled]="repairerForm.invalid || pending" color="primary" mat-raised-button type="submit">
      {{ data?.repairerSite ? (data?.isEditRepairerSite ? 'SAVE' : 'RE-INVITE') : 'INVITE' }}
    </button>
  </mat-dialog-actions>
</form>
