import { Component, inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { GoogleMap, MapMarker } from '@angular/google-maps';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig
} from '@angular/material/legacy-dialog';
import { MatSort } from '@angular/material/sort';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfirmBoxComponent } from '../../components/confirm-box/confirm-box.component';
import { MessagingService } from '../../components/messaging/messaging.service';
import { smallModalConfig, wideModalConfig } from '../../consts/modal-config.const';
import { ModalConfigsHelper } from '../../helpers/modal-configs.helper';
import { BankingDetailsProperty } from '../../model/banking-details.model';
import { Refreshable } from '../../model/refreshable.model';
import { RepairerGroup, User, UserRepairerSite } from '../../model/user.model';
import { CurrentUserService } from '../../services/currentUser.service';
import { GoogleMapsService } from '../../services/google-map.service';
import { RepairerAdminService } from '../../services/repairer-admin.service';
import { SysAdminService } from '../../services/sys-admin.service';
import { ToolbarService } from '../../services/toolbar.service';
import { UserService } from '../../services/user.service';
import { AddUserComponent } from './add-user/add-user.component';
import { EditAddressComponent } from './edit-address/edit-address.component';
import { EditBankingDetailsComponent } from './edit-banking-details/edit-banking-details.component';
import { LocationAdminStore } from './location.admin.store.component';

@Component({
  selector: 'app-location-admin',
  templateUrl: './location-admin.component.html',
  styleUrls: ['./location-admin.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LocationAdminComponent implements OnInit, Refreshable, OnDestroy {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;
  @ViewChild(MapMarker, { static: false }) public mapMarker: MapMarker;

  loading = false;
  loadingRepairerSite = false;
  loadingUsersOfRepairer = false;
  markerOptions: google.maps.MarkerOptions;
  options: google.maps.MapOptions;

  repairerSite: UserRepairerSite;
  repairersGroup: RepairerGroup[];
  bankingDetailsMap: BankingDetailsProperty[] = [];
  private unsubscribe = new Subject<void>();

  sysAdminService = inject(SysAdminService);
  toolbarService = inject(ToolbarService);
  googleMapsService = inject(GoogleMapsService);
  #locationAdminStore = inject(LocationAdminStore);
  #userService = inject(UserService);
  #addUser = inject(MatDialog);
  #editAddress = inject(MatDialog);
  #bankingDetails = inject(MatDialog);
  #message = inject(MessagingService);
  #confirmBox = inject(MatDialog);
  #currentUserService = inject(CurrentUserService);
  #repAdminService = inject(RepairerAdminService);
  #modalConfigsHelper = inject(ModalConfigsHelper);

  ngOnInit() {
    this.toolbarService.toolbarData({ pageName: 'Administration', backLink: 'dashboard' });
    this.#userService.repairersGroup
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({ next: (groups) => (this.repairersGroup = groups) });
    this.getRepairerSiteForUser();
    this.#locationAdminStore.getUsersOfRepairerSite();
  }

  get currentUser() {
    return this.#currentUserService.currentUserValue;
  }

  getRepairerSiteForUser() {
    this.loading = this.loadingRepairerSite = true;
    this.toolbarService.setCloudState('FETCHING');
    this.#userService
      .getRepairerSiteForUser()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (repairerSite) => {
          this.repairerSite = repairerSite;
          if (repairerSite?.status === 'PENDING') {
            this.editAddressInterface();
          }
          this.#locationAdminStore.setUserRepairerSite(repairerSite);
          this.#locationAdminStore.getCompanyInformationHistory(repairerSite.id);
          this.#currentUserService.timeZone = repairerSite?.timezone;
        },
        error: () => {
          this.#message.error('Could not retrieve the repairer site.');
          this.loadingRepairerSite = false;
          this.endOfLoading();
        },
        complete: () => {
          this.loadingRepairerSite = false;
          this.initMap();
          this.initBankingDetails();
          this.endOfLoading();
        }
      });
  }

  editAddressInterface() {
    const dialogConfig: MatDialogConfig = wideModalConfig;
    dialogConfig.data = { repairer: this.repairerSite, repairersGroup: this.repairersGroup };
    const dialogRef = this.#editAddress.open(EditAddressComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'Success') {
        this.#message.confirm('Address Updated.');
        this.getRepairerSiteForUser();
      }
      if (result === 'PublishRepairerSucessful') {
        this.refresh();
      }
    });
  }

  endOfLoading() {
    if (!this.loadingUsersOfRepairer && !this.loadingRepairerSite) {
      this.loading = false;
      this.toolbarService.setCloudState('RESTING');
    }
  }

  editBankingDetails() {
    const dialogConfig: MatDialogConfig = wideModalConfig;
    dialogConfig.data = {
      username: this.currentUser.username,
      bsb: this.repairerSite.bsb,
      accountName: this.repairerSite.accountName,
      accountNumber: this.repairerSite.accountNumber
    };
    const dialogRef = this.#bankingDetails.open(EditBankingDetailsComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'Success') {
        this.#message.confirm('Banking Details Edited');
        this.getRepairerSiteForUser();
      }
    });
  }

  addEditUser(user?: User) {
    const dialogConfig: MatDialogConfig = wideModalConfig;
    dialogConfig.data = user ? { user } : null;
    const dialogRef = this.#addUser.open(AddUserComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result.response === 'Success') {
        if (result.action === 'add') {
          this.#message.confirm('New User Created');
        } else if (result.action === 'edit') {
          this.#message.confirm('User Updated');
        }
        this.#locationAdminStore.getUsersOfRepairerSite();
      }
    });
  }

  enableDisableUser(event: { disable: boolean; user: User }): void {
    const username = event.user?.username;
    const action = event.disable ? 'disable' : 'enable';
    const service = this.currentUser?.privileges?.includes('SYSADMIN') ? this.sysAdminService : this.#repAdminService;
    const actionRequest = action === 'disable' ? service.disableUser(username) : service.enableUser(username);

    actionRequest.subscribe(() => this.refresh());
  }

  confirmDeleteBankingDetails() {
    const data = {
      title: 'Delete Banking Details',
      alertMessage: 'Banking details will be deleted.',
      confirmButton: 'DELETE'
    };
    const dialogRef = this.#confirmBox.open(
      ConfirmBoxComponent,
      this.#modalConfigsHelper.buildMediumModalConfig(data, smallModalConfig)
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'DELETE') {
        this.deleteBankingDetails();
      }
    });
  }

  deleteBankingDetails() {
    const bankingDetails = {
      bsb: '',
      accountName: '',
      accountNumber: ''
    };

    this.toolbarService.setCloudState('SAVING');

    this.#repAdminService
      .updateBankingDetails(bankingDetails)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: () => this.toolbarService.setCloudState('RESTING'),
        error: () => {
          this.#message.error('Could not delete banking details.');
          this.toolbarService.setCloudState('RESTING');
        },
        complete: () => {
          this.getRepairerSiteForUser();
          this.#message.confirm('Banking details deleted.');
        }
      });
  }

  confirmDeleteInvitedUser(username: string) {
    const dialogConfig: MatDialogConfig = smallModalConfig;
    dialogConfig.panelClass = 'medium-small-modal';
    dialogConfig.data = {
      title: 'Delete Invited User',
      alertMessage: 'Invited user will be deleted.',
      confirmButton: 'DELETE'
    };
    const dialogRef = this.#confirmBox.open(ConfirmBoxComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'DELETE') {
        this.deleteInvitedUser(username);
      }
    });
  }

  deleteInvitedUser(username: string) {
    this.toolbarService.setCloudState('SAVING');
    this.#repAdminService
      .deleteUser(username)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: () => this.toolbarService.setCloudState('RESTING'),
        error: () => {
          this.#message.error('Invited User could not be deleted.');
          this.toolbarService.setCloudState('RESTING');
        },
        complete: () => {
          this.#message.confirm('Invited User Deleted');
          this.#locationAdminStore.getUsersOfRepairerSite();
        }
      });
  }

  refresh() {
    this.getRepairerSiteForUser();
    this.#locationAdminStore.getUsersOfRepairerSite();
  }

  initMap() {
    this.googleMapsService.googleMapsLoaded.pipe(takeUntil(this.unsubscribe)).subscribe((mapLoaded) => {
      if (mapLoaded && this.repairerSite.latitude && this.repairerSite.longitude) {
        this.updateMap();
      }
    });
  }

  updateMap() {
    this.markerOptions = {
      draggable: false,
      position: {
        lat: this.repairerSite.latitude,
        lng: this.repairerSite.longitude
      }
    };

    this.options = {
      center: this.markerOptions.position,
      mapTypeId: 'roadmap',
      zoom: 15,
      disableDefaultUI: true,
      clickableIcons: false,
      keyboardShortcuts: false,
      styles: [{ featureType: 'transit', stylers: [{ visibility: 'off' }] }],
      draggable: false
    };
  }

  initBankingDetails() {
    this.bankingDetailsMap = [];
    this.bankingDetailsMap.push(
      { label: 'BSB', value: this.repairerSite?.bsb },
      { label: 'Account Number', value: this.repairerSite?.accountNumber },
      { label: 'Account Name', value: this.repairerSite?.accountName }
    );
  }

  ngOnDestroy() {
    this.#locationAdminStore.resetStoreState();
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
