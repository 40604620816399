import { MatDateFormats } from '@angular/material/core';

// Moment syntax for Moment and Material Components (MAT_DATE_FORMATS) https://momentjs.com/docs/#/displaying/format/
export const EIO_DATE_UI_FORMAT_MOMENT = 'DD/MM/YYYY';
export const EIO_DATE_TIME_UI_FORMAT_MOMENT = 'DD/MM/YYYY HH:mm';

// Same result as above but in the syntax of Angular DatePipe / fns https://angular.io/api/common/DatePipe
export const EIO_DATE_UI_FORMAT_PIPE = 'dd/MM/yyyy';

// Same result as above but in the syntax of Angular DatePipe / fns https://angular.io/api/common/DatePipe
export const EIO_DATE_UI_FORMAT_PIPE_HISTORY = 'dd MMM y';

// Same result as above but in the syntax of Angular DatePipe / fns https://angular.io/api/common/DatePipe
export const EIO_DATE_UI_FORMAT_MMYY_PIPE = 'MM/yyyy';

// Backend date format
export const EIO_DATE_BE_FORMAT = 'YYYY-MM-DD';

// Backend date format for angular-calendar
export const EIO_DATE_BE_FORMAT_CALENDAR = 'YYYY-MM-dd';

// Backend date format
export const EIO_DATE_BE_FORMAT_MMYY = 'YYYY-MM';

export const EIO_TIME_FORMAT = 'HH:mm';

export const EIO_DATE_FORMATS_LONG: MatDateFormats = {
  parse: {
    dateInput: EIO_DATE_UI_FORMAT_MOMENT
  },
  display: {
    dateInput: EIO_DATE_UI_FORMAT_MOMENT,
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};
