import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Diary } from '../../../model/job.model';
import { UserService } from '../../../services/user.service';
import { ToolbarService } from '../../../services/toolbar.service';
import { MessagingService } from '../../../components/messaging/messaging.service';
import { CurrentUserService } from '../../../services/currentUser.service';
import { MatExpansionPanel } from '@angular/material/expansion';

@Component({
  selector: 'app-diary-curtain',
  templateUrl: './diary-curtain.component.html',
  styleUrls: ['./diary-curtain.component.scss']
})
export class DiaryCurtainComponent implements OnInit, OnChanges {
  @Input() diaries: Diary[];
  @Input() shouldCollapseCurtain: boolean;
  @ViewChild('panel', { static: false }) panel: MatExpansionPanel;

  unreadDiaries: Diary[];
  lastReceivedDate: number;
  timezone: string;

  constructor(
    private userService: UserService,
    private currentUserService: CurrentUserService,
    public toolbarService: ToolbarService,
    private message: MessagingService
  ) {}

  ngOnInit(): void {
    this.timezone = this.currentUserService?.timeZone;
    this.initializeData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.diaries && !changes.diaries.firstChange) {
      this.initializeData();
    }
    if (changes.shouldCollapseCurtain && !changes.shouldCollapseCurtain.firstChange) {
      if (changes.shouldCollapseCurtain.currentValue) {
        this.panel.close();
      }
    }
  }

  initializeData() {
    this.unreadDiaries = this.diaries.filter((diary) => diary.status === 'READY_TO_READ');
    this.diaries.sort((a, b) => a.date - b.date);
    this.lastReceivedDate = this.diaries[this.diaries.length - 1].date;
  }

  markReadDiary(diary: Diary) {
    this.toolbarService.setCloudState('SAVING');
    this.userService.markDiaryAsRead(diary.id).subscribe(
      () => this.toolbarService.setCloudState('RESTING'),
      () => {
        this.message.error('Could not mark diary as read.');
        this.toolbarService.setCloudState('RESTING');
      },
      () => {
        this.toolbarService.setCloudState('RESTING');
        diary.status = 'READ';
        this.unreadDiaries = this.diaries.filter((d) => d.status === 'READY_TO_READ');
      }
    );
  }
}
