import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'menuList',
  pure: true
})
export class KebabMenuListPipe implements PipeTransform {
  transform(list: any[], item: any, status: string) {
    if (!status) {
      return [];
    }
    switch (status) {
      case 'PENDING':
        if (!item?.mainContact?.isActivated) {
          return list.filter((l) => ['ReInviteRepairer', 'Delete'].includes(l.action));
        }
        return list.filter((l) => ['AccessRepairerItem', 'ArchiveRepairerItem'].includes(l.action));
      case 'ACTIVE':
        const quotingSystems = item?.quotingSystemsString?.split(',');
        if (quotingSystems?.length > 1) {
          return list.filter((l) => !['ReInviteRepairer', 'Delete'].includes(l.action));
        }
        return list.filter((l) => !['ChangeQuotingSystem', 'ReInviteRepairer', 'Delete'].includes(l.action));
      case 'ARCHIVED':
        return list.filter((l) => l.action === 'AccessRepairerItem');
      default:
        return [];
    }
  }
}
