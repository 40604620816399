import { Component, inject } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { wideModalConfig } from '../../../consts/modal-config.const';
import { LocationAdminStore } from '../location.admin.store.component';
import { ApplyToEstImagePayModalComponent } from './apply-to-estimage-pay-modal/apply-to-estimage-pay-modal.component';
import { StatusChipComponent } from '../../../components/status-chip/status-chip.component';
import { HumanizePipe } from '../../../pipes/humanize.pipe';
import { InfosGuidanceComponent } from '../../../components/infos-guidance/infos-guidance.component';

@Component({
  selector: 'estimage-pay-information',
  standalone: true,
  imports: [
    CommonModule,
    MatDividerModule,
    MatLegacyButtonModule,
    MatIconModule,
    StatusChipComponent,
    HumanizePipe,
    InfosGuidanceComponent,
    NgOptimizedImage
  ],
  template: `
    <section class="header-container items-end pl-10 pt-5">
      <img ngSrc="../../assets/images/estimage_pay_banner.svg" [width]="340" [height]="105" alt="EstImage Pay Banner" />
      <button
        *ngIf="!locationAdminStore.estImagePay()?.status; else onBoardStatus"
        mat-raised-button
        color="accent"
        (click)="applyToEstImagePay()">
        APPLY TO USE ESTIMAGE PAY
      </button>
      <ng-template #onBoardStatus>
        <status-chip [status]="locationAdminStore.estImagePay().status" />
      </ng-template>
    </section>
    <mat-divider class="mb-20" />
    <section class="mb-25 flex justify-between mx-20">
      <div class="flex flex-col gap-y-20 subtitle-2">
        <ng-container *ngIf="!locationAdminStore.estImagePay()?.status; else estImagePayStatusInfo">
          <span>
            EstImage Pay is an on-demand payment and finance facility for repairers. It offers instant invoice
            authorisations and daily settlements, providing a streamlined, embedded solution for B2B payments.
          </span>
          <span>
            Learn more here:
            <a href="https://public.estimage.com/estimage-pay" target="_blank">
              https://public.estimage.com/estimage-pay</a
            >.
          </span>
        </ng-container>

        <ng-template #estImagePayStatusInfo>
          <div class="flex flex-col">
            <ng-container *ngIf="locationAdminStore.estImagePay().status !== 'APPROVED'; else approvedGuidance">
              <span>
                Your request for approval to use EstImage Pay is
                {{ locationAdminStore.estImagePay().status | lowercase | humanize }}.
              </span>
              <span>
                You can access your request to complete the required form here:
                <a [href]="locationAdminStore.estImagePay().onBoardingUrl">
                  {{ locationAdminStore.estImagePay().onBoardingUrl }}
                </a>
              </span>
            </ng-container>
            <ng-template #approvedGuidance>
              <span>You are approved to use EstImage Pay to request invoice financing.</span>
            </ng-template>
          </div>
        </ng-template>

        <span>
          EstImage Pay is powered by FlipPay, who can be reached for support at 1300 354 766 or by e-mail at
          support@flippay.com.au.
        </span>
      </div>
      <div
        class="flex flex-col"
        *ngIf="locationAdminStore.estImagePay()?.status === 'APPROVED' && locationAdminStore.estImagePay()?.flipPayId">
        <span class="body-2">Merchant ID</span>
        <span class="subtitle-2">
          {{ locationAdminStore.estImagePay().flipPayId }}
        </span>
      </div>
    </section>
  `
})
export class EstImagePayInformationComponent {
  locationAdminStore = inject(LocationAdminStore);

  applyToEstImagePay() {
    const dialogConfig = {
      ...wideModalConfig,
      data: {
        title: 'Apply to EstImage Pay',
        action: 'APPLY',
        displayClose: true,
        component: ApplyToEstImagePayModalComponent
      }
    };
    this.locationAdminStore.openDynamicDialog(dialogConfig);
  }
}
