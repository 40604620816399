import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToolbarService } from '../../../services/toolbar.service';
import { MessagingService } from '../../../components/messaging/messaging.service';
import { urlValidator } from '../../../validators/urlValidator.validator';
import { ConfirmBoxComponent } from '../../../components/confirm-box/confirm-box.component';
import { QuotingSystemOAuth2 } from '../../../model/quoting-system/quoting-system-oAuth.model';
import { EscapeDialogHelper } from '../../../helpers/escape-dialog.helper';
import { SysAdminService } from '../../../services/sys-admin.service';
import { mediumModalConfig } from '../../../consts/modal-config.const';
import { ModalConfigsHelper } from '../../../helpers/modal-configs.helper';

@Component({
  selector: 'app-configure-o-auth-interface',
  templateUrl: './configure-o-auth-interface.component.html',
  styleUrls: ['./configure-o-auth-interface.component.scss']
})
export class ConfigureOAuthInterfaceComponent implements OnInit {
  oAuthForm: UntypedFormGroup;
  loadingProductionMode = false;
  pending = false;
  isProd: boolean;

  constructor(
    public dialogRef: MatDialogRef<ConfigureOAuthInterfaceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder,
    public toolbarService: ToolbarService,
    private message: MessagingService,
    private confirmBox: MatDialog,
    private escapeDialogRef: EscapeDialogHelper,
    private sysAdminService: SysAdminService,
    private modalConfigsHelper: ModalConfigsHelper
  ) {}

  get clientId() {
    return this.oAuthForm.get('clientId');
  }

  get clientSecret() {
    return this.oAuthForm.get('clientSecret');
  }

  get redirectUri() {
    return this.oAuthForm.get('redirectUri');
  }

  ngOnInit(): void {
    this.checkProductionMode();
    this.populateOAuthForm();

    this.clientSecret.valueChanges.subscribe(() => {
      if (this.clientSecret.invalid) {
        this.clientSecret.markAsTouched();
      }
    });

    this.redirectUri.valueChanges.subscribe(() => {
      if (this.redirectUri.invalid) {
        this.redirectUri.markAsTouched();
      }
    });

    this.escapeDialogRef.escapeDialog(this.dialogRef);
  }

  populateOAuthForm() {
    this.oAuthForm = this.fb.group({
      clientId: [{ value: this.data ? this.data.clientId : '', disabled: true }],
      clientSecret: [this.data ? this.data.clientSecret : '', Validators.required],
      redirectUri: [this.data ? this.data.redirectUri : '', [Validators.required, urlValidator(this.isProd)]]
    });
  }

  checkProductionMode() {
    this.loadingProductionMode = true;
    this.toolbarService.setCloudState('FETCHING');
    this.sysAdminService.productionMode().subscribe(
      (isProd) => (this.isProd = isProd),
      () => {
        this.message.error('Could not retrieve the production mode.');
        this.loadingProductionMode = false;
      },
      () => {
        this.loadingProductionMode = false;
      }
    );
  }

  confirmChangeStatusOfInterface(enable: boolean) {
    enable = !enable;
    const data = {
      title: enable ? 'Enable Interface' : 'Disable Interface',
      alertMessage: !enable
        ? 'The interface between ' +
          this.data.companyName +
          ' and EstImage Online (' +
          window.location.hostname +
          ') will be disabled. ' +
          this.data.companyName +
          ' users will no longer be able to send work to their EstImage Online site.'
        : 'The interface between ' +
          this.data.companyName +
          ' and EstImage Online (' +
          window.location.hostname +
          ') will be enabled. Users will be able to authorise ' +
          this.data.companyName +
          ' to send quotes, images, etc to their EstImage Online site.',
      confirmButton: enable ? 'ENABLE' : 'DISABLE',
      hideCannotBeUndone: true
    };
    const dialogRef = this.confirmBox.open(
      ConfirmBoxComponent,
      this.modalConfigsHelper.buildMediumModalConfig(data, mediumModalConfig)
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'DISABLE' || result === 'ENABLE') {
        this.data.oAuth2Enabled = enable;
        this.updateQuotingSystemOauth2();
      }
    });
  }

  updateQuotingSystemOauth2() {
    this.pending = true;

    const Oauth2InterfaceData: QuotingSystemOAuth2 = {
      clientId: this.data.clientId,
      clientSecret: this.clientSecret.value.trim(),
      redirectUri: this.redirectUri.value.trim(),
      enable: this.data.oAuth2Enabled
    };

    this.toolbarService.setCloudState('SAVING');
    this.sysAdminService.updateQuotingSystemOauth2(Oauth2InterfaceData).subscribe(
      () => this.dialogRef.close('Success oAuth'),
      () => {
        this.message.error('Could not configure OAuth2 interface.');
        this.toolbarService.setCloudState('RESTING');
        this.pending = false;
      },
      () => {
        this.toolbarService.setCloudState('RESTING');
        this.pending = false;
      }
    );
  }
}
