import { Component, inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { RepairerSitesStore } from '../repairer-sites/repairer-sites-store.component';
import { AbstractDynamicDialogComponent } from '../../../components/dynamic-dialog/abstract-dynamic-dialog.component';

@Component({
  selector: 'app-set-dashboard-day',
  templateUrl: './set-dashboard-day.component.html',
  styleUrls: ['./set-dashboard-day.component.scss']
})
export class SetDashboardDayComponent extends AbstractDynamicDialogComponent implements OnInit {
  #dialogRef = inject(MatDialogRef<SetDashboardDayComponent>);
  #fb = inject(UntypedFormBuilder);
  #repairerSitesStore = inject(RepairerSitesStore);

  dashboardDaysForm: UntypedFormGroup = this.#fb.group({
    days: [null, Validators.min(1)]
  });

  get days() {
    return this.dashboardDaysForm.controls.days;
  }

  ngOnInit() {
    this.dynamicDialogService.dialogRefSignal.set(this.#dialogRef);

    const { dashboardDays = 30 } = this.#repairerSitesStore.selectedRepairerSite() ?? {};
    this.days.patchValue(dashboardDays);
  }

  saveOrExecuteAction() {
    this.#repairerSitesStore.updateDashboardDays(this.days.value);
  }
}
