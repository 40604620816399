<infos-action-card
  label="EstImage Pay"
  [ngClass]="{ 'cursor-pointer': !submitted }"
  (click)="!submitted && useEstimagePay.emit()">
  <mat-icon info-icon class="custom-mat-icon mat-icon-sm mt-[2px]" svgIcon="estimage_pay" />

  <ng-container info-guidance *ngIf="invoiceApprovedOrAccepted(); else notApproved">
    <span *ngIf="paymentFailed(); else showStatus"> The request for payment failed. Please contact Support. </span>

    <ng-template #showStatus>
      <span class="flex items-center gap-x-10">
        Your request for payment of this invoice is
        <status-chip smallChip [status]="_invoice().estimagePayPaymentRequestStatus" />
      </span>
      <ng-container [ngSwitch]="_invoice().estimagePayPaymentRequestStatus">
        <ng-template
          *ngSwitchCase="'SUBMITTED'"
          [ngTemplateOutlet]="subGuidance"
          [ngTemplateOutletContext]="{
            guidance: 'Your payment request has been created and is pending manual review.'
          }" />
        <ng-template
          *ngSwitchCase="'ACTIVE'"
          [ngTemplateOutlet]="subGuidance"
          [ngTemplateOutletContext]="{
            guidance: 'Your payment request has been approved, disbursement has been created and will be sent shortly.'
          }" />
        <ng-template
          *ngSwitchCase="'DECLINED'"
          [ngTemplateOutlet]="subGuidance"
          [ngTemplateOutletContext]="{
            guidance: 'Your payment request was declined and won’t be funded; please contact Support.'
          }" />
        <ng-template
          *ngSwitchCase="'CANCELLED'"
          [ngTemplateOutlet]="subGuidance"
          [ngTemplateOutletContext]="{
            guidance: 'Your payment request has been cancelled outside of EstImage by an authorised user.'
          }" />
        <ng-template
          *ngSwitchCase="'COMPLETED'"
          [ngTemplateOutlet]="subGuidance"
          [ngTemplateOutletContext]="{ guidance: 'Your payment request has been settled.' }" />
      </ng-container>
    </ng-template>
  </ng-container>

  <ng-template #notApproved>
    <span info-guidance *ngIf="invoiceRejected(); else maxExceeded">
      {{
        ['DELETED_AFTER_INVOICE_APPROVAL'].includes(_invoice().estimagePayPaymentRequestStatus)
          ? 'Your request for payment of this invoice has been cancelled (invoice rejected).'
          : ['FAILED_TO_BE_DELETED'].includes(_invoice().estimagePayPaymentRequestStatus)
          ? 'There was an error cancelling this request for payment on invoice rejection, please contact Support.'
          : 'Your request for payment of this invoice has not been sent (invoice rejected)'
      }}
    </span>

    <ng-template #maxExceeded>
      <span info-guidance *ngIf="totalPayable > _invoice().estimagePayMaxAmount; else generic">
        This invoice cannot be paid through EstImage Pay as the total payable is greater than the maximum amount allowed
        ({{ _invoice().estimagePayMaxAmount | number: '1.2-2' }}$). On submission of the invoice, this request for
        payment will be removed.
      </span>

      <ng-template #generic>
        <span info-guidance>
          A request for payment will be sent through EstImage Pay on approval of this invoice.
        </span>
      </ng-template>
    </ng-template>
  </ng-template>

  <div
    info-details
    class="grid gap-x-40 gap-y-15"
    [ngClass]="_invoice().estimagePayRequestId ? 'grid-cols-3' : 'grid-cols-2'">
    <ng-template
      [ngTemplateOutlet]="info"
      [ngTemplateOutletContext]="{
        field: 'Amount Requested',
        value:
          '$ ' +
          ((_invoice().estimagePayPaymentRequestStatus === 'WAITING_FOR_INVOICE_APPROVAL_TO_SEND_REQUEST'
            ? totalPayable
            : _invoice().estimagePayAmountRequested
          ) | number: '1.2-2')
      }" />
    <ng-template
      [ngTemplateOutlet]="info"
      [ngTemplateOutletContext]="{ field: 'Account Name', value: _invoice().estimagePayDisbursementAccountName }" />
    <ng-template
      [ngTemplateOutlet]="info"
      [ngTemplateOutletContext]="{ field: 'Payment Request ID', value: _invoice().estimagePayRequestId }" />
    <ng-template
      [ngTemplateOutlet]="info"
      [ngTemplateOutletContext]="{ field: 'Account Number', value: _invoice().estimagePayDisbursementAccountNumber }" />
    <ng-template
      [ngTemplateOutlet]="info"
      [ngTemplateOutletContext]="{ field: 'BSB', value: _invoice().estimagePayDisbursementBsb }" />
  </div>

  <button
    delete-icon
    *ngIf="!submitted"
    (click)="$event.stopPropagation(); removeEstImagePay()"
    mat-icon-button
    type="button"
    class="custom-mat-button">
    <mat-icon>delete</mat-icon>
  </button>
</infos-action-card>

<ng-template #info let-field="field" let-value="value">
  <infos-content *ngIf="value" [field]="field" [value]="value" />
</ng-template>

<ng-template #subGuidance let-guidance="guidance">
  <span class="body-2">{{ guidance }}</span>
</ng-template>
