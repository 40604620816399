import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { ToolbarService } from '../../../services/toolbar.service';
import { MessagingService } from '../../../components/messaging/messaging.service';
import { QuotingSystemCreate, QuotingSystemUpdate } from '../../../model/quoting-system/quoting-system-create.model';
import { EscapeDialogHelper } from '../../../helpers/escape-dialog.helper';
import { SysAdminService } from '../../../services/sys-admin.service';

@Component({
  selector: 'app-add-quoting-system',
  templateUrl: './add-quoting-system.component.html',
  styleUrls: ['./add-quoting-system.component.scss']
})
export class AddQuotingSystemComponent implements OnInit {
  quotingSystemForm: UntypedFormGroup;
  pending = false;

  constructor(
    public dialogRef: MatDialogRef<AddQuotingSystemComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder,
    public toolbarService: ToolbarService,
    private message: MessagingService,
    private escapeDialogRef: EscapeDialogHelper,
    private sysAdminService: SysAdminService
  ) {}

  get contactPhoneNumber() {
    return this.quotingSystemForm.get('contactPhoneNumber');
  }
  get companyName() {
    return this.quotingSystemForm.get('companyName');
  }

  ngOnInit(): void {
    this.quotingSystemForm = this.fb.group({
      companyName: [
        this.data && this.data?.quotingSystem?.clientId ? this.data?.quotingSystem?.companyName : '',
        [Validators.required]
      ],
      contactPhoneNumber: [
        this.data && this.data?.quotingSystem?.clientId ? this.data?.quotingSystem?.contactPhoneNumber : ''
      ]
    });
    this.escapeDialogRef.escapeDialog(this.dialogRef);
    this.companyName.valueChanges.subscribe(() => {
      if (this.companyName.invalid) {
        this.companyName.markAsTouched();
      }
    });
  }

  addQuotingSystem() {
    this.pending = true;
    this.toolbarService.setCloudState('SAVING');
    const newQSystem: QuotingSystemCreate = {
      companyName: this.companyName.value.trim(),
      contactPhoneNumber: this.contactPhoneNumber.value.trim()
    };
    this.sysAdminService.createQuotingSystem(newQSystem).subscribe(
      () => this.dialogRef.close('Success'),
      (error) => {
        this.message.error('Could not add quoting system.');
        this.toolbarService.setCloudState('RESTING');
        this.pending = false;
      },
      () => {
        this.toolbarService.setCloudState('RESTING');
        this.pending = false;
      }
    );
  }

  editQuotingSystem() {
    this.pending = true;
    const quotingSystemData: QuotingSystemUpdate = {};
    quotingSystemData.companyName = this.companyName.value.trim();
    quotingSystemData.contactPhoneNumber = this.contactPhoneNumber.value.trim();

    this.toolbarService.setCloudState('SAVING');
    this.sysAdminService.updateQuotingSystem(this.data.quotingSystem.clientId, quotingSystemData).subscribe(
      () => this.dialogRef.close('Success'),
      (error) => {
        this.message.error('Could not update quoting system.');
        this.toolbarService.setCloudState('RESTING');
        this.pending = false;
      },
      () => {
        this.toolbarService.setCloudState('RESTING');
        this.pending = false;
      }
    );
  }
}
