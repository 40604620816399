import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { User } from '../../../model/user.model';
import { EditSysadminComponent } from '../edit-sysadmin/edit-sysadmin.component';
import { MessagingService } from '../../../components/messaging/messaging.service';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig
} from '@angular/material/legacy-dialog';
import { ToolbarService } from '../../../services/toolbar.service';
import { UserService } from '../../../services/user.service';
import { SysAdminService } from '../../../services/sys-admin.service';
import { ConfirmBoxComponent } from '../../../components/confirm-box/confirm-box.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ModalConfigsHelper } from '../../../helpers/modal-configs.helper';
import { smallModalConfig, wideModalConfig } from '../../../consts/modal-config.const';

@Component({
  selector: 'app-administrators-section',
  templateUrl: './administrators-section.component.html',
  styleUrls: ['./administrators-section.component.scss']
})
export class AdministratorsSectionComponent implements OnDestroy {
  @Input() sysadminList: User[] = [];
  @Output() updateSysadminList: EventEmitter<any> = new EventEmitter<any>();
  administratorsExpanded = false;
  private unsubscribe = new Subject();

  constructor(
    private message: MessagingService,
    private editSysadmin: MatDialog,
    public toolbarService: ToolbarService,
    private userService: UserService,
    private sysAdminService: SysAdminService,
    private confirmBox: MatDialog,
    private modalConfigsHelper: ModalConfigsHelper
  ) {}

  addSysAdmin(user?: User) {
    const dialogConfig: MatDialogConfig = wideModalConfig;
    dialogConfig.data = user || null;
    const dialogRef = this.editSysadmin.open(EditSysadminComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.message.confirm(result === 'created' ? 'New Admin Invited' : 'Admin saved');
        this.updateSysadminList.emit();
      }
    });
  }

  enableDisableSysAdmin(user: User, enable: boolean) {
    const request = enable
      ? this.sysAdminService.enableUser(user.username)
      : this.sysAdminService.disableUser(user.username);

    request.subscribe(() => this.updateSysadminList.emit());
  }

  confirmArchiveSysAdmin(username: string) {
    const data = { title: 'Archive User', alertMessage: 'User will be archived.', confirmButton: 'ARCHIVE' };
    const dialogRef = this.confirmBox.open(
      ConfirmBoxComponent,
      this.modalConfigsHelper.buildMediumModalConfig(data, smallModalConfig)
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'ARCHIVE') {
        this.archiveSysAdmin(username);
      }
    });
  }

  archiveSysAdmin(username: string) {
    this.sysAdminService.archiveSysadmin(username).subscribe({
      complete: () => this.updateSysadminList.emit()
    });
  }

  confirmDeleteInvitedSysAdmin(username: string) {
    const data = {
      title: 'Delete Invited User',
      alertMessage: 'Invited user will be deleted.',
      confirmButton: 'DELETE'
    };
    const dialogRef = this.confirmBox.open(
      ConfirmBoxComponent,
      this.modalConfigsHelper.buildMediumModalConfig(data, smallModalConfig)
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'DELETE') {
        this.deleteInvitedSysAdmin(username);
      }
    });
  }

  deleteInvitedSysAdmin(username: string) {
    this.sysAdminService
      .deleteSysadminOrMlAdmin(username)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        complete: () => {
          this.updateSysadminList.emit();
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
