<company-information
  [repairerSite]="repairerSite"
  [markerOptions]="markerOptions"
  [options]="options"
  (editCompanyAddress)="editAddressInterface()"/>

<div class="header-container">
  <h3>Banking Details</h3>
  <div *ngIf="currentUser?.privileges?.includes('REPADMIN')">
    <button (click)="editBankingDetails()" mat-raised-button color="accent">
      {{ repairerSite?.bsb ? 'EDIT' : 'ADD' }}
    </button>
    <div *ngIf="repairerSite?.bsb" class="kebab-banking secondary-icon">
      <button mat-icon-button [matMenuTriggerFor]="bankingMenu" (click)="$event.stopPropagation()">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #bankingMenu="matMenu" class="kebab-menu">
        <button mat-menu-item (click)="confirmDeleteBankingDetails()">
          <span>Delete Banking Details</span>
        </button>
      </mat-menu>
    </div>
  </div>
</div>
<mat-divider></mat-divider>
<div class="banking-details-section">
  <div *ngIf="!repairerSite?.bsb" class="banking-info">
    <mat-icon>info_outlined</mat-icon>
    <span class="subtitle-2"
      >Please add your banking details. They will be saved and available for future invoices.</span
    >
  </div>
  <div *ngIf="repairerSite?.bsb" class="banking-grid">
    <div *ngFor="let property of bankingDetailsMap">
      <div class="body-2">
        {{ property.label }}
      </div>
      <div class="subtitle-2">
        {{ property.value }}
      </div>
    </div>
  </div>
</div>

<estimage-pay-information *ngIf="sysAdminService.estimagePayEnabled()" />

<user-management
  [repairSiteStatus]="repairerSite?.status"
  [currentUser]="currentUser"
  (addEditUser)="addEditUser($event)"
  (enableDisableUser)="enableDisableUser($event)"
  (confirmDeleteInvitedUser)="confirmDeleteInvitedUser($event)"/>

<history-of-company/>
