import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { User } from '../model/user.model';
import { PermissionHelper } from '../helpers/permission.helper';
import { CurrentUserService } from '../services/currentUser.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardMladmin {
  currentUser: User;

  private currentUserService = inject(CurrentUserService);
  private router = inject(Router);
  private permissionHelper = inject(PermissionHelper);

  canActivate(): Observable<boolean> | boolean {
    return this.checkUser();
  }

  checkUser(): boolean {
    const currentUser = this.currentUserService.currentUserValue;
    return currentUser && this.permissionHelper.checkPermission(currentUser, 'MLADMIN', this.router);
  }
}
