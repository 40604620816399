<div class="file-box">
  <section class="flex justify-between items-center">
    <div class="flex">
      <mat-icon class="text-wood-smoke custom-mat-icon mat-icon-xxl mr-5">directions_car</mat-icon>
      <div
        class="grow"
        *ngTemplateOutlet="vehicleData; context: { name: 'Vehicle', value: 'vehicleDescription' }"></div>
    </div>
    <ng-container
      *ngTemplateOutlet="vehicleData; context: { name: 'Rego', value: 'registrationNumber' }"></ng-container>
  </section>
  <section class="flex justify-between items-center">
    <ng-container
      *ngTemplateOutlet="vehicleData; context: { name: 'Rego state', value: 'auRegistrationState' }"></ng-container>
    <ng-container
      *ngTemplateOutlet="vehicleData; context: { name: 'Rego exp.date', value: 'expiryDate' }"></ng-container>
    <ng-container *ngTemplateOutlet="vehicleData; context: { name: 'VIN Number', value: 'vin' }"></ng-container>
    <ng-container
      *ngTemplateOutlet="vehicleData; context: { name: 'Odometer', value: 'odometer', option: 'km' }"></ng-container>
    <ng-container *ngTemplateOutlet="vehicleData; context: { name: 'Body color', value: 'colour' }"></ng-container>
  </section>
  <mat-divider class="mt-5"></mat-divider>
  <mat-checkbox color="primary" [(ngModel)]="shouldImportData" (change)="importData.emit($event?.checked)"
    >EstImage found the following vehicle details that can be added to the job. Import these as well (they will be
    applied and saved immediately)?</mat-checkbox
  >
</div>

<ng-template #vehicleData let-name="name" let-value="value" let-option="option">
  <div class="m-[8px] min-w-[80px] grid grid-rows-2">
    <div class="body-2 text-silver-chalice mb-5">{{ name }}</div>
    <div class="body-1 font-bold text-charcoal">{{ data?.[value] ?? '' }} {{ data?.[value] ? option : '' }}</div>
  </div>
</ng-template>
