import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { MessagingService } from '../../../components/messaging/messaging.service';
import * as moment from 'moment';
import { EIO_DATE_UI_FORMAT_MOMENT } from '../../../consts/localization.const';
import { ToolbarService } from '../../../services/toolbar.service';
import { ConfirmBoxComponent } from '../../../components/confirm-box/confirm-box.component';
import { EscapeDialogHelper } from '../../../helpers/escape-dialog.helper';
import { DateInputHelper } from '../../../helpers/date-input.helper';
import { SysAdminService } from '../../../services/sys-admin.service';
import { ModalConfigsHelper } from '../../../helpers/modal-configs.helper';
import { smallModalConfig } from '../../../consts/modal-config.const';
import { BasicQuotingSystemView } from '../../../model/user.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-change-quoting-system',
  templateUrl: './change-quoting-system.component.html',
  styleUrls: ['./change-quoting-system.component.scss']
})
export class ChangeQuotingSystemComponent implements OnInit {
  moveQSForm: UntypedFormGroup;
  loading = false;
  jobsCount: number;
  fromCompanyName: string;
  toCompanyName: string;
  initialDateString: string;
  duplicateFromTo = false;
  quotingSystemsList: BasicQuotingSystemView[] = [];

  get initialDate() {
    return this.moveQSForm.get('initialDate');
  }
  get from() {
    return this.moveQSForm.get('from');
  }
  get to() {
    return this.moveQSForm.get('to');
  }

  data: { repairerSiteId: number } = inject(MAT_DIALOG_DATA);
  dialogRef = inject(MatDialogRef<ChangeQuotingSystemComponent>);
  dateInputHelper = inject(DateInputHelper);
  private confirmBox = inject(MatDialog);
  private fb = inject(UntypedFormBuilder);
  private message = inject(MessagingService);
  private toolbarService = inject(ToolbarService);
  private escapeDialogRef = inject(EscapeDialogHelper);
  private sysAdminService = inject(SysAdminService);
  private modalConfigsHelper = inject(ModalConfigsHelper);
  private destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this.getQuotingSystemList();

    this.escapeDialogRef.escapeDialog(this.dialogRef);

    this.moveQSForm = this.fb.group({
      from: ['', Validators.required],
      to: ['', Validators.required],
      initialDate: ['', Validators.required]
    });

    this.moveQSForm.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.getNumberOfJobs();
    });
  }

  private getQuotingSystemList(): void {
    this.sysAdminService.getRepairerSite(this.data.repairerSiteId).subscribe((repairerSite) => {
      if (repairerSite) {
        this.quotingSystemsList = repairerSite.quotingSystems;
      }
    });
  }

  getNumberOfJobs() {
    if (this.initialDate.valid && this.from.valid && this.to.valid) {
      if (this.from.value !== this.to.value) {
        this.duplicateFromTo = false;
        this.loading = true;
        this.fromCompanyName = this.quotingSystemsList.find((qs) => qs.clientId === this.from.value).companyName;
        this.toCompanyName = this.quotingSystemsList.find((qs) => qs.clientId === this.to.value).companyName;
        this.toolbarService.setCloudState('FETCHING');
        this.sysAdminService
          .getQuotingSystemJobCounts(
            this.from.value,
            this.data.repairerSiteId,
            moment(this.initialDate.value).startOf('day').utc().unix()
          )
          .subscribe(
            (jobsCount) => (this.jobsCount = jobsCount),
            () => {
              this.message.error('Could not retrieve the jobs count for the quoting system.');
              this.toolbarService.setCloudState('RESTING');
              this.loading = false;
            },
            () => {
              this.toolbarService.setCloudState('RESTING');
              this.loading = false;
              this.initialDateString = moment(this.initialDate.value).format(EIO_DATE_UI_FORMAT_MOMENT);
            }
          );
      } else {
        this.duplicateFromTo = true;
      }
    }
  }

  confirmMoveQuotingSystem() {
    const data = {
      title: 'Change Quoting System',
      alertMessage:
        this.jobsCount +
        ' job(s) will be moved from ' +
        this.fromCompanyName +
        ' to ' +
        this.toCompanyName +
        ' starting ' +
        this.initialDateString +
        '.',
      confirmButton: 'SAVE',
      hideCannotBeUndone: true
    };
    const dialogRef = this.confirmBox.open(
      ConfirmBoxComponent,
      this.modalConfigsHelper.buildMediumModalConfig(data, smallModalConfig)
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'SAVE') {
        this.loading = true;
        this.toolbarService.setCloudState('SAVING');
        this.sysAdminService
          .updateQuotingSystemJob(
            this.from.value,
            this.data.repairerSiteId,
            this.to.value,
            moment(this.initialDate.value).startOf('day').utc().unix()
          )
          .subscribe(
            () => this.dialogRef.close('Success'),
            () => {
              this.message.error('Could not update the jobs of the quoting system.');
              this.toolbarService.setCloudState('RESTING');
              this.loading = false;
            },
            () => {
              this.toolbarService.setCloudState('RESTING');
              this.loading = false;
            }
          );
      }
    });
  }
}
