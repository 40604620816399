<mat-expansion-panel class="curtains">
  <mat-expansion-panel-header [collapsedHeight]="'70px'" [expandedHeight]="'70px'">
    <mat-panel-title class="gicop-title">
      <div class="diary-count">
        <h5>GICoP Notifications</h5>
      </div>
      <div>
        <div class="body-2">Last Notification Received</div>
        <div class="subtitle-2">
          {{ gicopNotifications.lastNotificationReceived | dateepoch: timezone }}
        </div>
      </div>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <mat-divider></mat-divider>
  <div>
    <ng-container *ngIf="gicopNotifications?.customerContacts.length">
      <app-customer-contact-table
        [customerContacts]="gicopNotifications?.customerContacts"
        [timezone]="timezone"></app-customer-contact-table>
    </ng-container>
    <ng-container *ngIf="gicopNotifications?.customerFeedbacks.length">
      <app-customer-feedback-table
        [customerFeedbacks]="gicopNotifications?.customerFeedbacks"
        [timezone]="timezone"></app-customer-feedback-table>
    </ng-container>
    <ng-container *ngIf="gicopNotifications?.codeOfPracticeBreaches.length">
      <app-potential-breach-code-table
        [codeOfPracticeBreaches]="gicopNotifications?.codeOfPracticeBreaches"
        [timezone]="timezone"></app-potential-breach-code-table>
    </ng-container>
    <ng-container *ngIf="gicopNotifications?.customerVulnerabilities.length">
      <app-customer-vulnerability-table
        [customerVulnerabilities]="gicopNotifications?.customerVulnerabilities"
        [timezone]="timezone"></app-customer-vulnerability-table>
    </ng-container>
  </div>
</mat-expansion-panel>
