<infos-action-card
  label="Financed Invoice"
  [ngClass]="{ 'cursor-pointer': !submitted }"
  (click)="!submitted && useFinancingCompany.emit()">
  <mat-icon info-icon>monetization_on</mat-icon>
  <span info-guidance>
    This invoice will be sent by email to the finance company. The finance company will be notified of approval.
  </span>
  <div info-details class="grid grid-cols-3 gap-x-40 gap-y-15">
    <ng-template
      [ngTemplateOutlet]="info"
      [ngTemplateOutletContext]="{ field: 'Finance Company', value: invoice.paymentTo }" />
    <ng-template
      [ngTemplateOutlet]="info"
      [ngTemplateOutletContext]="{
        field: invoice.repairerCountry === 'Australia' ? 'ABN' : 'NZBN',
        value: invoice.paymentToAbn
      }" />
    <ng-template
      [ngTemplateOutlet]="info"
      [ngTemplateOutletContext]="{ field: 'Email', value: invoice.paymentToEmail, truncate: true }" />
    <ng-template
      [ngTemplateOutlet]="info"
      [ngTemplateOutletContext]="{ field: 'Phone', value: invoice.paymentToPhone }" />
    <ng-template
      [ngTemplateOutlet]="info"
      [ngTemplateOutletContext]="{
        field: 'Business Address',
        value: invoice.paymentToAddress,
        class: 'col-span-2'
      }" />
  </div>

  <button delete-icon *ngIf="!submitted" (click)="removeFinancingCompany.emit()" mat-icon-button type="button">
    <mat-icon>delete</mat-icon>
  </button>
</infos-action-card>

<ng-template #info let-field="field" let-value="value" let-class="class" let-truncate="truncate">
  <infos-content *ngIf="value" [field]="field" [value]="value" [ngClass]="class" [overflowTooltip]="truncate" />
</ng-template>
