<div
  class="repair-timeline-box"
  (click)="!editDisabled && editRepairTimeline.emit()"
  [class.edit-disabled]="editDisabled"
  [class.repair-timeline-box--green]="timelineBox?.isDateSetInPast"
  [ngClass]="{
    'repair-timeline-box-green': timelineBox?.isDateSetInPast,
    'repair-timeline-box-green-future': timelineBox?.date && !timelineBox?.isDateSetInPast
  }">
  <div class="repair-timeline-box-header body-1">{{ timelineBox?.status }}</div>
  <div class="repair-timeline-box-body body-2" *ngIf="timelineBox?.date">
    {{ timelineBox?.date | date: 'dd/MM/yyyy' }}
  </div>
</div>
