import { DecimalPipe } from '@angular/common';
import {
  Component,
  effect,
  EventEmitter,
  inject,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  signal,
  SimpleChanges,
  untracked,
  ViewChild,
  WritableSignal
} from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatExpansionPanel } from '@angular/material/expansion';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig
} from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, finalize, takeUntil, tap } from 'rxjs/operators';
import { ConfirmBoxComponent } from '../../../components/confirm-box/confirm-box.component';
import { DynamicDialogService } from '../../../components/dynamic-dialog/dynamic-dialog-service';
import { MessagingService } from '../../../components/messaging/messaging.service';
import {
  invoiceCurtainPartColumnHeadersLargeScreen,
  invoiceCurtainPartColumnHeadersSmallScreen
} from '../../../consts/columnHeaders.const';
import { mediumModalConfig, smallModalConfig, wideModalConfig } from '../../../consts/modal-config.const';
import { BreakpointObserverHelper } from '../../../helpers/breakpoint-observer.helper';
import { CheckExistenceOfFileHelper } from '../../../helpers/check-existence-of-file.helper';
import { DateInputHelper } from '../../../helpers/date-input.helper';
import { ModalConfigsHelper } from '../../../helpers/modal-configs.helper';
import { BankingDetailsBanner } from '../../../model/banking-details.model';
import { DocumentItem, Invoice, InvoiceLabour, InvoicePart, InvoiceSublet } from '../../../model/document.model';
import { DisbursementAccount, EstImagePayRequest } from '../../../model/estimage-pay.model';
import { FinanceCompany } from '../../../model/financing-company.model';
import { User } from '../../../model/user.model';
import { CurrentUserService } from '../../../services/currentUser.service';
import { SysAdminService } from '../../../services/sys-admin.service';
import { ToolbarService } from '../../../services/toolbar.service';
import { UserService } from '../../../services/user.service';
import { SharedModule } from '../../../shared.module';
import { EditBankingDetailsComponent } from '../../location-admin/edit-banking-details/edit-banking-details.component';
import { InvoiceStore } from './invoice.store.component';
import { UseEstimagePayModalComponent } from './use-estimage-pay-modal/use-estimage-pay-modal.component';
import { UseFinanceCompanyModalComponent } from './use-finance-company-modal/use-finance-company-modal.component';

@Component({
  selector: 'app-invoice-curtain',
  templateUrl: './invoice-curtain.component.html',
  styleUrls: ['./invoice-curtain.component.scss'],
  providers: [DecimalPipe, SharedModule]
})
export class InvoiceCurtainComponent implements OnInit, OnChanges, OnDestroy {
  @Input() document: DocumentItem;
  @Input() isSentToInsurerTray = false;
  @Input() shouldCollapseCurtain: boolean;
  @Input() lastExcessChangeTimestamp: number;
  @Input() repairerSiteId: number;
  @Output() refreshInvoice: EventEmitter<any> = new EventEmitter();
  @Output() validateField: EventEmitter<any> = new EventEmitter();
  @Output() disableSendButton: EventEmitter<boolean> = new EventEmitter();
  @Output() synchronizeOwnerContribution: EventEmitter<number> = new EventEmitter();
  @ViewChild('panel', { static: false }) panel: MatExpansionPanel;

  Math = Math;
  invoice: Invoice;
  invoiceForm: UntypedFormGroup;
  labourColumnHeaders = ['description', 'assessedAmount', 'invoicedAmount', 'approvedAmount'];
  labourDataSource = new MatTableDataSource<any | GroupBy>([]);
  partColumnHeaders = ['partDescriptionNumber', 'partSource', 'assessorNote', 'assessedAmount', 'invoicedAmount'];
  partDataSource = new MatTableDataSource<any | GroupBy>([]);
  subletsColumnHeaders = ['description', 'note', 'assessedAmount', 'invoicedAmount', 'approvedAmount'];
  subletsDataSource = new MatTableDataSource<any | GroupBy>([]);
  totalAssessedLabour: number;
  totalAssessedParts: number;
  totalAssessedSublet: number;
  totalInvoicedParts: number;
  totalInvoicedSublet: number;
  totalInvoicedLabour: number;
  totalApprovedParts: number;
  totalApprovedSublet: number;
  totalApprovedLabour: number;
  invoicedSubtotal: number;
  invoicedGST: number;
  invoicedTotal: number;
  assessedTotal: number;
  totalPayable: number;
  approvedTotal: number;
  approvedSubtotal: number;
  approvedGST: number;
  totalAssessedLessExcessContributions: number;
  paymentByCheque: boolean;
  usingFinancingCompany: WritableSignal<boolean> = signal(false);
  selectedFinancingCompany: WritableSignal<FinanceCompany> = signal(null);
  isLargeScreen = false;
  currentUser: User;
  timezone: string;
  loadingPDF = false;
  atLeastOneComment = false;
  private unsubscribe = new Subject<void>();
  bankingDetailsBanner: WritableSignal<BankingDetailsBanner> = signal(null);

  #dynamicDialogService = inject(DynamicDialogService);
  #injector = inject(Injector);
  #invoiceStore = inject(InvoiceStore);
  #breakpointObserver = inject(BreakpointObserverHelper);
  #fb = inject(UntypedFormBuilder);
  #decPipe = inject(DecimalPipe);
  #userService = inject(UserService);
  #bankingDetails = inject(MatDialog);
  #message = inject(MessagingService);
  #confirmBox = inject(MatDialog);
  toolbarService = inject(ToolbarService);
  dateInputHelper = inject(DateInputHelper);
  #currentUserService = inject(CurrentUserService);
  #checkExistenceOfFileHelper = inject(CheckExistenceOfFileHelper);
  #modalConfigsHelper = inject(ModalConfigsHelper);
  sysAdminService = inject(SysAdminService);

  constructor() {
    effect(
      () => {
        if (this.#invoiceStore.refreshInvoice()) {
          this.validateField.emit();
          this.refreshInvoice.emit();
          this.#invoiceStore.refreshInvoice.set(false);
        }
      },
      { allowSignalWrites: true }
    );
  }

  get invoiceNumber() {
    return this.invoiceForm.get('invoiceNumber');
  }

  get preparedBy() {
    return this.invoiceForm.get('preparedBy');
  }

  get ownerContribution() {
    return this.invoiceForm.get('ownerContribution');
  }

  get labourItems() {
    return this.invoiceForm.get('labourItems') as UntypedFormArray;
  }

  get partItems() {
    return this.invoiceForm.get('partItems') as UntypedFormArray;
  }

  get subletItems() {
    return this.invoiceForm.get('subletItems') as UntypedFormArray;
  }

  get financingCompany() {
    return this.invoiceForm.get('financingCompany');
  }

  get repairerComment() {
    return this.invoiceForm.get('repairerComment');
  }

  ngOnInit(): void {
    this.currentUser = this.#currentUserService.currentUserValue;
    this.timezone = this.#currentUserService?.timeZone;
    this.invoice = this.document.document as Invoice;

    this.#breakpointObserver
      .observeMinWidth()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (result) => {
          this.isLargeScreen = result;
          this.partColumnHeaders = this.isLargeScreen
            ? invoiceCurtainPartColumnHeadersLargeScreen
            : invoiceCurtainPartColumnHeadersSmallScreen;
        }
      });
    this.updateBankingDetailsBanner(this.invoice);
    this.groupDataSource(this.invoice.labour, this.labourDataSource);
    this.groupDataSource(this.invoice.parts, this.partDataSource);
    this.groupDataSource(this.invoice.sublets, this.subletsDataSource);

    this.atLeastOneComment = !![...this.invoice.labour, ...this.invoice.parts, ...this.invoice.sublets]
      .map(({ costControlComment }) => costControlComment)
      .filter(Boolean).length;

    this.invoiceForm = this.#fb.group({
      ownerContribution: [
        this.invoice.ownerContribution,
        Validators.pattern('\\-?(\\d+|\\d{1,3}(,\\d{3})*)(\\.\\d{1,2})?')
      ],
      invoiceNumber: [this.invoice.invoiceNumber],
      preparedBy: [this.invoice.preparedBy],
      labourItems: new UntypedFormArray([]),
      partItems: new UntypedFormArray([]),
      subletItems: new UntypedFormArray([]),
      financingCompany: [null],
      repairerComment: this.invoice.repairerComment ?? null
    });

    if (this.document.submitted) {
      this.invoiceNumber.disable();
      this.preparedBy.disable();
      this.ownerContribution.disable();
      this.financingCompany.disable();
      this.repairerComment.disable();
    }

    this.totalAssessedLabour = this.invoice.labour.reduce((acc, labour) => acc + labour.assessedAmount, 0);
    this.totalAssessedParts = this.invoice.parts.reduce((acc, part) => acc + part.assessedAmount, 0);
    this.totalAssessedSublet = this.invoice.sublets.reduce((acc, sublet) => acc + sublet.assessedAmount, 0);
    let assessed = this.totalAssessedLabour + this.totalAssessedParts + this.totalAssessedSublet;
    assessed += assessed * this.invoice.taxRate;
    this.assessedTotal = assessed;

    this.paymentByCheque = this.invoice.paymentByCheque;

    this.#initializeFinanceCompany();

    this.populateLabourItems();
    this.populatePartItems();
    this.populateSubletItems();
    this.calculateTotals();
    this.formatOwnerContribution();

    if (this.document.submitted) {
      this.labourItems.controls.forEach((item) => item.disable());
      this.partItems.controls.forEach((item) => item.disable());
      this.subletItems.controls.forEach((item) => item.disable());
    }

    this.ownerContribution.valueChanges.subscribe(() => {
      if (this.ownerContribution.invalid) {
        this.ownerContribution.markAsTouched();
        this.disableSendButton.emit(true);
      }
    });

    this.ownerContribution.valueChanges.pipe(debounceTime(250), distinctUntilChanged()).subscribe(() => {
      if (this.ownerContribution.dirty && this.ownerContribution.valid) {
        this.disableSendButton.emit(false);
        this.update('ownerContribution');
      }
    });

    this.invoiceNumber.valueChanges.pipe(debounceTime(750), distinctUntilChanged()).subscribe(() => {
      this.update('invoiceNumber');
      setTimeout(() => this.validateField.emit(this.invoiceNumber.invalid ? this.invoiceNumber : null), 1000);
    });

    this.preparedBy.valueChanges.pipe(debounceTime(750), distinctUntilChanged()).subscribe(() => {
      if (this.preparedBy.valid) {
        this.update('preparedBy');
      }
    });
  }

  #initializeFinanceCompany() {
    // ToDo move this call to the constructor of the invoiceStore when we remove providerIn: root
    this.#invoiceStore.getFinanceCompanies();
    this.usingFinancingCompany.set(this.invoice.factoryCompanyEnable);

    if (this.usingFinancingCompany() && this.#invoiceStore.financeCompanies().length) {
      this.selectedFinancingCompany.set(
        this.#invoiceStore.financeCompanies().find((company) => company.companyName === this.invoice.paymentTo)
      );
    }

    effect(
      () => {
        // If the list was empty at initialization
        if (this.#invoiceStore.financeCompanies().length) {
          untracked(
            () =>
              this.usingFinancingCompany() &&
              this.selectedFinancingCompany.set(
                this.#invoiceStore.financeCompanies().find((company) => company.companyName === this.invoice.paymentTo)
              )
          );
        }
      },
      { injector: this.#injector }
    );
  }

  updateBankingDetailsBanner(details: Invoice | BankingDetailsBanner): void {
    const {
      bsb,
      accountName,
      accountNumber,
      companyInformationChangeDateTime,
      repairerName,
      repairerAbn,
      isUsingPreviousCompanyInformation
    } = details;

    this.bankingDetailsBanner.set({
      bsb,
      accountName,
      accountNumber,
      companyInformationChangeDateTime,
      repairerName,
      repairerAbn,
      isUsingPreviousCompanyInformation
    });
  }

  checkIfFileExists() {
    const url = `/jobAttachments/${this.repairerSiteId}/${this.invoice.jobId}/${this.invoice.id}_invoice.pdf`;
    if (this.#checkExistenceOfFileHelper.urlExists(url)) {
      window.open(url, '_blank');
    } else {
      this.loadingPDF = true;
      this.#userService
        .fetchMissingInvoicePDF(this.invoice.id, url)
        .pipe(
          catchError((err) => {
            this.#message.error('There was an error loading the invoice PDF');
            return of(err);
          }),
          finalize(() => (this.loadingPDF = false))
        )
        .subscribe();
    }
  }

  addBankingDetails() {
    const dialogConfig: MatDialogConfig = wideModalConfig;
    dialogConfig.data = { username: this.currentUser.username };
    const dialogRef = this.#bankingDetails.open(EditBankingDetailsComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'Success') {
        this.#message.confirm('Banking Details Edited');
        this.validateField.emit();
        this.refreshInvoice.emit();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.document && !changes.document.firstChange) {
      this.invoice = this.document.document as Invoice;
      this.formatOwnerContribution();
      this.populateLabourItems();
      this.populatePartItems();
      this.populateSubletItems();
      this.calculateTotals();
      this.updateBankingDetailsBanner(this.invoice);
    }
    if (changes.shouldCollapseCurtain && !changes.shouldCollapseCurtain.firstChange) {
      if (changes.shouldCollapseCurtain.currentValue) {
        this.panel.close();
      }
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  groupDataSource(
    arrayToGroup: InvoiceLabour[] | InvoicePart[] | InvoiceSublet[],
    dataSource: MatTableDataSource<any | GroupBy>
  ) {
    this.getGroups(arrayToGroup).forEach((group) => {
      const header = {} as GroupBy;
      header.quoteNumber = group.key;
      header.isGroupBy = true;
      header.quoteVersion = group.list[0].originalQuote ? 'Quote' : 'Supplement';
      dataSource.data.push(header);
      group.list.forEach((item) => dataSource.data.push(item));
    });
  }

  formatOwnerContribution() {
    this.ownerContribution.setValue(
      this.invoice.ownerContribution ? this.#decPipe.transform(this.invoice.ownerContribution, '1.2-2') : '',
      { emitEvent: false }
    );
    this.ownerContribution.setErrors(null);
  }

  populateLabourItems() {
    this.invoice.labour.forEach((labour) => {
      const labourControl = this.#fb.group({
        id: labour.id,
        invoicedAmount: [this.#decPipe.transform(labour.invoicedAmount, '1.2-2')]
      });
      this.labourItems.push(labourControl);
    });
  }

  populatePartItems() {
    this.invoice.parts.forEach((part) => {
      const partControl = this.#fb.group({
        id: part.id,
        invoicedAmount: [this.#decPipe.transform(part.invoicedAmount, '1.2-2')]
      });
      this.partItems.push(partControl);
    });
  }

  populateSubletItems() {
    this.invoice.sublets.forEach((sublet) => {
      const subletControl = this.#fb.group({
        id: sublet.id,
        invoicedAmount: [this.#decPipe.transform(sublet.invoicedAmount, '1.2-2')]
      });
      this.subletItems.push(subletControl);
    });
  }

  calculateTotals() {
    this.totalInvoicedParts = this.invoice.parts.reduce(
      (acc, part) => acc + (part.invoicedAmount ? part.invoicedAmount : 0),
      0
    );
    this.totalInvoicedSublet = this.invoice.sublets.reduce(
      (acc, sublet) => acc + (sublet.invoicedAmount ? sublet.invoicedAmount : 0),
      0
    );
    this.totalInvoicedLabour = this.invoice.labour.reduce(
      (acc, labour) => acc + (labour.invoicedAmount ? labour.invoicedAmount : 0),
      0
    );
    this.invoicedSubtotal = this.totalInvoicedLabour + this.totalInvoicedParts + this.totalInvoicedSublet;
    this.invoicedGST = this.invoicedSubtotal * this.invoice.taxRate;
    this.invoicedTotal = this.invoicedSubtotal + this.invoicedGST;

    this.totalApprovedParts = this.invoice.parts.reduce(
      (acc, part) => acc + (part.approvedAmount ? part.approvedAmount : 0),
      0
    );
    this.totalApprovedSublet = this.invoice.sublets.reduce(
      (acc, sublet) => acc + (sublet.approvedAmount ? sublet.approvedAmount : 0),
      0
    );
    this.totalApprovedLabour = this.invoice.labour.reduce(
      (acc, labour) => acc + (labour.approvedAmount ? labour.approvedAmount : 0),
      0
    );
    this.approvedSubtotal = this.totalApprovedLabour + this.totalApprovedParts + this.totalApprovedSublet;
    this.approvedGST = this.approvedSubtotal * this.invoice.taxRate;
    this.approvedTotal = this.approvedSubtotal + this.approvedGST;

    if (
      this.invoice.status === 'CREATED' ||
      this.invoice.status === 'PENDING_FOR_REVIEW' ||
      this.invoice.status === 'REJECTED'
    ) {
      this.totalPayable =
        this.invoicedTotal -
        (this.invoice.excess ? this.invoice.excess : 0) -
        (this.invoice.ownerContribution ? this.invoice.ownerContribution : 0);
      if (!this.invoice.isFirstInvoice) {
        this.totalPayable = this.invoicedTotal;
      }
    } else if (this.invoice.status === 'APPROVED' || this.invoice.status === 'ADJUSTED') {
      if (!this.invoice.isFirstInvoice) {
        this.totalPayable = this.approvedTotal;
      } else {
        this.totalPayable =
          this.approvedTotal -
          (this.invoice.excess ? this.invoice.excess : 0) -
          (this.invoice.ownerContribution ? this.invoice.ownerContribution : 0);
      }
    }

    this.totalAssessedLessExcessContributions =
      this.invoicedTotal -
      (this.invoice.excess ? this.invoice.excess : 0) -
      (this.invoice.ownerContribution ? this.invoice.ownerContribution : 0);
  }

  setPaymentByCheque(checked: boolean) {
    this.paymentByCheque = checked;
    this.update('paymentByCheque');
  }

  update(fieldName: string) {
    let fieldValue;

    if (fieldName === 'financeCompany') {
      fieldValue = this.selectedFinancingCompany()?.id ?? '';
    } else if (fieldName === 'paymentByCheque') {
      fieldValue = this.paymentByCheque;
    } else if (fieldName === 'ownerContribution') {
      fieldValue = this.ownerContribution.value
        ? this.ownerContribution.value.toString().replace(',', '')
        : this.ownerContribution.value;
    } else {
      fieldValue = this.invoiceForm.controls[fieldName].value;
    }

    if (fieldName === 'ownerContribution' && this.invoiceForm.controls[fieldName].valid) {
      this.invoice.ownerContribution = +fieldValue;
      this.calculateTotals();
      this.toolbarService.setCloudState('SAVING');
      this.#userService.updateJob(fieldValue, this.invoice.jobId, fieldName).subscribe(
        () => this.toolbarService.setCloudState('RESTING'),
        () => {
          this.#message.error('Could not update field.');
          this.toolbarService.setCloudState('RESTING');
        },
        () => {
          this.synchronizeOwnerContribution.emit(fieldValue);
        }
      );
    } else {
      this.toolbarService.setCloudState('SAVING');
      this.#userService.updateInvoice(fieldValue, this.invoice.id, fieldName).subscribe(
        () => {
          this.toolbarService.setCloudState('RESTING');
        },
        (error) => {
          if (error.error === 'DUPLICATE_INVOICE_NUMBER') {
            this.invoiceNumber.setErrors({ duplicateInvoice: true });
          } else {
            this.#message.error('Could not add invoice.');
          }
          this.toolbarService.setCloudState('RESTING');
          if (fieldName === 'financeCompany') {
            this.selectedFinancingCompany.set(null);
          }
        },
        () => {
          this.refreshInvoice.emit();
        }
      );
    }
  }

  updateJob(fieldName: string) {
    const fieldValue = this.invoiceForm.controls[fieldName].value;

    if (this.invoiceForm.controls[fieldName].valid) {
      this.toolbarService.setCloudState('SAVING');
      this.#userService.updateJob(fieldValue, this.invoice.jobId, fieldName).subscribe(
        () => this.toolbarService.setCloudState('RESTING'),
        () => {
          this.#message.error('Could not update field.');
          this.toolbarService.setCloudState('RESTING');
          this.invoiceForm.controls[fieldName].setValue(this.invoice[fieldName]);
        },
        () => {
          this.refreshInvoice.emit();
          this.invoiceForm.controls[fieldName].markAsPristine();
        }
      );
    }
  }

  updateParts(partId: number) {
    const partCtrl = this.partItems.controls.find((item) => item.value.id === partId);
    const partValue = partCtrl.get('invoicedAmount');
    partValue.setValue(partCtrl.get('invoicedAmount').value.toString().replace(/,/g, ''));
    partValue.setValidators(Validators.pattern('\\-?\\d*\\.?\\d{1,2}'));
    partValue.updateValueAndValidity();
    if (partValue) {
      if (partValue.value === '') {
        partValue.setValue('0.00');
      } else {
        const amount = partValue.value.toString().replace(',', '');
        partValue.setValue(amount);
      }
      if (partValue && partValue.valid) {
        this.disableSendButton.emit(false);
        this.toolbarService.setCloudState('SAVING');
        this.#userService.updateInvoicePart(partValue.value, partId).subscribe(
          () => this.toolbarService.setCloudState('RESTING'),
          () => {
            this.#message.error('Could not update field.');
            this.toolbarService.setCloudState('RESTING');
          },
          () => {
            this.invoice.parts.find((x) => x.id === partId).invoicedAmount = +partValue.value;
            this.calculateTotals();
            partValue.setValue(this.#decPipe.transform(partValue.value, '1.2-2'));
            partValue.setErrors(null);
          }
        );
      }
    } else {
      this.disableSendButton.emit(true);
    }
  }

  updateSublet(subletId: number) {
    const subletCtrl = this.subletItems.controls.find((item) => item.value.id === subletId);
    const subletValue = subletCtrl.get('invoicedAmount');
    subletValue.setValue(subletCtrl.get('invoicedAmount').value.toString().replace(/,/g, ''));
    subletValue.setValidators(Validators.pattern('\\-?\\d*\\.?\\d{1,2}'));
    subletValue.updateValueAndValidity();
    if (subletValue) {
      if (subletValue.value === '') {
        subletValue.setValue('0.00');
      } else {
        const amount = subletValue.value.toString().replace(',', '');
        subletValue.setValue(amount);
      }
    }
    if (subletValue && subletValue.valid) {
      this.disableSendButton.emit(false);
      this.toolbarService.setCloudState('SAVING');
      this.#userService.updateInvoiceSublet(subletValue.value, subletId).subscribe(
        () => this.toolbarService.setCloudState('RESTING'),
        () => {
          this.#message.error('Could not update field.');
          this.toolbarService.setCloudState('RESTING');
        },
        () => {
          this.invoice.sublets.find((x) => x.id === subletId).invoicedAmount = +subletValue.value;
          this.calculateTotals();
          subletValue.setValue(this.#decPipe.transform(subletValue.value, '1.2-2'));
          subletValue.setErrors(null);
        }
      );
    } else {
      this.disableSendButton.emit(true);
    }
  }

  updateLabour(labourId: number) {
    const labourCtrl = this.labourItems.controls.find((item) => item.value.id === labourId);
    const labourValue = labourCtrl.get('invoicedAmount');
    labourValue.setValue(labourCtrl.get('invoicedAmount').value.toString().replace(/,/g, ''));
    labourValue.setValidators(Validators.pattern('\\-?\\d*\\.?\\d{1,2}'));
    labourValue.updateValueAndValidity();
    if (labourValue) {
      if (labourValue.value === '') {
        labourValue.setValue('0.00');
      }
    } else {
      const amount = labourValue.value.toString().replace(',', '');
      labourValue.setValue(amount);
    }
    if (labourValue && labourValue.valid) {
      this.disableSendButton.emit(false);
      this.toolbarService.setCloudState('SAVING');
      this.#userService.updateInvoiceLabour(labourValue.value, labourId).subscribe(
        () => this.toolbarService.setCloudState('RESTING'),
        () => {
          this.#message.error('Could not update field.');
          this.toolbarService.setCloudState('RESTING');
        },
        () => {
          this.invoice.labour.find((x) => x.id === labourId).invoicedAmount = +labourValue.value;
          this.calculateTotals();
          labourValue.setValue(this.#decPipe.transform(labourValue.value, '1.2-2'));
          labourValue.setErrors(null);
        }
      );
    } else {
      this.disableSendButton.emit(true);
    }
  }

  getGroups(arrayToGroup: InvoiceLabour[] | InvoicePart[] | InvoiceSublet[]) {
    const group = this.groupBy(arrayToGroup, 'quoteNumber');
    return Object.keys(group).map((key) => ({ key, list: group[key] }));
  }

  groupBy(data, property) {
    return data.reduce((acc, obj) => {
      const key = obj[property];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  }

  isGroup(index: number, item: any): boolean {
    return item.isGroupBy;
  }

  useFinancingCompany() {
    const dialogConfig: MatDialogConfig = {
      ...wideModalConfig,
      data: {
        title: 'Select Finance Company',
        action: 'SELECT',
        displayClose: true,
        component: UseFinanceCompanyModalComponent,
        selectFinanceCompanyId: this.selectedFinancingCompany()?.id || null
      }
    };
    this.#dynamicDialogService
      .openDialog(dialogConfig)
      .afterClosed()
      .subscribe(({ selectedFinanceCompany }) => {
        if (selectedFinanceCompany !== undefined) {
          this.usingFinancingCompany.set(selectedFinanceCompany !== null);
          this.selectedFinancingCompany.set(selectedFinanceCompany);
          this.validateField.emit();
          this.update('financeCompany');
        }
      });
  }

  useEstimagePay() {
    const accountSelected: Omit<DisbursementAccount, 'accountId'> = this.invoice.estimagePayDisbursementAccountName
      ? {
          accountName: this.invoice.estimagePayDisbursementAccountName,
          accountNumber: this.invoice.estimagePayDisbursementAccountNumber,
          bsb: this.invoice.estimagePayDisbursementBsb
        }
      : null;
    const estImagePayRequest: Omit<EstImagePayRequest, 'disbursementAccounts'> = {
      invoiceNumber: this.invoice.invoiceNumber,
      invoiceId: this.invoice.id,
      amountRequested: this.totalPayable,
      accountSelected
    };

    const dialogConfig = {
      ...wideModalConfig,
      data: {
        title: 'Request Payment through EstImage Pay',
        action: 'REQUEST PAYMENT',
        displayClose: true,
        component: UseEstimagePayModalComponent,
        estImagePayRequest
      }
    };

    this.#invoiceStore.getDisbursementAccounts(dialogConfig);
  }

  removeFinancingCompany(): void {
    this.usingFinancingCompany.set(false);
    this.selectedFinancingCompany.set(null);
    this.validateField.emit();
    this.update('financeCompany');
  }

  deleteInvoice(id: number, jobId: number) {
    this.toolbarService.setCloudState('SAVING');
    this.#userService.deleteUnsubmittedInvoice(id, jobId).subscribe(
      () => this.toolbarService.setCloudState('RESTING'),
      () => {
        this.#message.error('Could not delete invoice.');
        this.toolbarService.setCloudState('RESTING');
      },
      () => {
        this.refreshInvoice.emit(true);
        this.#message.confirm('Invoice deleted.');
      }
    );
  }

  confirmDeleteInvoice(id: number, jobId: number) {
    const data = { title: 'Delete Invoice', alertMessage: 'Invoice will be deleted.', confirmButton: 'DELETE' };
    const dialogRef = this.#confirmBox.open(
      ConfirmBoxComponent,
      this.#modalConfigsHelper.buildMediumModalConfig(data, smallModalConfig)
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'DELETE') {
        this.deleteInvoice(id, jobId);
      }
    });
  }

  cancelInvoice(invoiceId: number) {
    const data = {
      title: 'Cancel Invoice',
      alertMessage: 'Are you sure you wish to cancel your invoice?',
      confirmButton: 'CONFIRM',
      hideCannotBeUndone: true,
      biggerButtons: true
    };
    const dialogRef = this.#confirmBox.open(
      ConfirmBoxComponent,
      this.#modalConfigsHelper.buildMediumModalConfig(data, mediumModalConfig)
    );
    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === 'CONFIRM') {
          this.#userService
            .cancelInvoice(invoiceId)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe({
              next: () => {
                this.#message.confirm('Invoice cancelled');
                this.refreshInvoice.emit();
              },
              error: () => this.#message.error('Could not cancel invoice')
            });
        }
      }
    });
  }

  changeCompanyInformation(action: string) {
    this.#userService
      .chooseCompanyInformation(action, this.invoice.id)
      .pipe(tap(() => this.toolbarService.setCloudState('SAVING')))
      .subscribe(
        (bankingDetailsBanner: BankingDetailsBanner) => {
          this.updateBankingDetailsBanner(bankingDetailsBanner);
          this.toolbarService.setCloudState('RESTING');
        },
        () => {
          this.#message.error(
            `Could not use the ${
              action === 'updateToPreviousCompanyInformation' ? 'old' : 'updated'
            } company information.`
          );
          this.toolbarService.setCloudState('RESTING');
        }
      );
  }
}

export interface GroupBy {
  quoteNumber: string;
  quoteVersion: string;
  isGroupBy: boolean;
}
