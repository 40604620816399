<div class="status-bar">
  <div class="job-status-container">
    <span class="subtitle-1">{{ job?.lastAssessmentAction }}</span>
    <div
      class="job-status body-2"
      *ngIf="!job?.archived && job?.status !== 'HIDDEN' && job?.status"
      (click)="navigateToBucket(job?.status)">
      {{ jobStatusEnum[job?.status] }}
    </div>
    <div class="job-status body-2" *ngIf="job?.repairProgressColumn" (click)="navigateToBucket('REPAIR_PROGRESS')">
      <span>{{ repairProgressMapping[job?.repairProgressColumn] }}</span>
    </div>
    <div class="job-status body-2 archived" *ngIf="job?.archived && job?.status !== 'HIDDEN'">
      {{ job?.status | humanize | titlecase }} [Archived]
    </div>
  </div>
  <div>
    <span class="status-label body-2">LAST RECEIVED </span>
    <span *ngIf="job?.lastReceivedDateTime" class="status-date subtitle-1"
      >{{ job.lastReceivedDateTime | dateepoch: timezone }} {{ job.lastReceivedDateTime | time: timezone }}</span
    >
    <span>
      <button (click)="viewJobHistory()" mat-icon-button><mat-icon>history</mat-icon></button>
    </span>
  </div>
</div>

<mat-accordion #accordion="matAccordion" [multi]="true">
  <div class="main-section" [class.margin-bottom-40]="!job?.cancelled">
    <div class="claim-section">
      <app-repair-timeline-curtain
        *ngIf="job?.authority || job?.selfAuthorized"
        [job]="job"
        [currentTimezone]="timezone"
        (editRepairTimeline)="reviewTimeline($event)"></app-repair-timeline-curtain>
      <app-claim-curtain
        *ngIf="job"
        [job]="job"
        [currentUserTimezone]="timezone"
        [showBooking]="showBooking"
        [savedJobStatus]="saveJobResponseStatus"
        [shouldCollapseCurtain]="collapseOpenedCurtain"
        (disableSendButton)="disableSendButton = $event"
        (fetchJob)="getJob()"
        (saveJob)="saveJob($event?.fieldValue, $event?.fieldName)"
        (validateSendJob)="receiveClaimCurtainData($event)"
        (inspectionBooking)="performingTask($event)">
      </app-claim-curtain>
      <mat-grid-list
        *ngIf="job"
        class="contact-section"
        cols="3"
        gutterSize="10px"
        rowHeight="{{ containNameLine2 ? '141px' : '121px' }}"
        [class.less-margin]="containNameLine2">
        <div *ngFor="let card of job.cards">
          <mat-grid-tile>
            <mat-card class="contact-card body-2">
              <div class="contact-name">
                <div class="body-2">
                  {{ card.role | humanize }}
                </div>
                <div class="nameLine1 subtitle-1">
                  {{ card.nameLine1 }}
                </div>
                <div *ngIf="card.nameLine2" class="nameLine2">
                  {{ card.nameLine2 }}
                </div>
              </div>
              <mat-tab-group animationDuration="0ms" class="contact-info" color="primary">
                <mat-tab #email *ngIf="card.email" class="info-label">
                  <ng-template class="info-label" mat-tab-label>
                    <mat-icon [class.tab-icon-color-active]="email.isActive">email</mat-icon>
                  </ng-template>
                  <div class="contacts body-1"><span>Email</span> {{ card.email }}</div>
                </mat-tab>
                <mat-tab #postCode *ngIf="card.postCode">
                  <ng-template mat-tab-label>
                    <mat-icon [class.tab-icon-color-active]="postCode.isActive">pin_drop</mat-icon>
                  </ng-template>
                  <div class="contacts body-1"><span>Postcode</span> {{ card.postCode }}</div>
                </mat-tab>
                <mat-tab #home *ngIf="card.homePhone">
                  <ng-template mat-tab-label>
                    <mat-icon [class.tab-icon-color-active]="home.isActive">home</mat-icon>
                  </ng-template>
                  <div class="contacts body-1"><span>Home phone</span> {{ card.homePhone }}</div>
                </mat-tab>
                <mat-tab #mobile *ngIf="card.mobilePhone">
                  <ng-template mat-tab-label>
                    <mat-icon [class.tab-icon-color-active]="mobile.isActive">phone_iphone</mat-icon>
                  </ng-template>
                  <div class="contacts body-1"><span>Mobile phone</span> {{ card.mobilePhone }}</div>
                </mat-tab>
                <mat-tab #work *ngIf="card.workPhone">
                  <ng-template mat-tab-label>
                    <mat-icon [class.tab-icon-color-active]="work.isActive">work</mat-icon>
                  </ng-template>
                  <div class="contacts body-1"><span>Work phone</span> {{ card.workPhone }}</div>
                </mat-tab>
              </mat-tab-group>
              <div class="secondary-icon pencil">
                <button (click)="editContact()" *ngIf="card.role === 'OWNER' && job?.editable" mat-icon-button>
                  <mat-icon>create</mat-icon>
                </button>
              </div>
            </mat-card>
          </mat-grid-tile>
        </div>
      </mat-grid-list>

      <ng-container *ngIf="submittedPhotos?.length">
        <app-gallery-curtain
          (refreshJobViewer)="refresh()"
          (updateSelectedPhoto)="updateSelectedPhoto($event)"
          [job]="job"
          [photos]="submittedPhotos"
          [shouldCollapseCurtain]="collapseOpenedCurtain">
        </app-gallery-curtain>
      </ng-container>

      <ng-container *ngIf="submittedDocs?.length">
        <app-documents-curtain
          (refreshJobViewer)="refresh()"
          [job]="job"
          [docs]="submittedDocs"
          [shouldCollapseCurtain]="collapseOpenedCurtain">
        </app-documents-curtain>
      </ng-container>

      <ng-container *ngIf="job?.preAuthority">
        <app-pre-authority-curtain
          (disableSendButton)="disableSendButton = showValidationGuidance || $event"
          (refreshJobViewer)="refresh()"
          (updateOwnerContribution)="
            synchronizeAuthorityOwnerContribution($event); synchronizeInvoiceOwnerContribution($event)
          "
          [editable]="!job?.cancelled"
          [jobId]="job?.jobId"
          [ownerContributionEditable]="!job?.cancelled && !(submittedInvoices.length > 0)"
          [preAuthority]="job?.preAuthority"
          (openPdfReport)="openPdfReport('pre_authorized')">
        </app-pre-authority-curtain>
      </ng-container>

      <ng-container *ngIf="submittedDiaries?.length">
        <app-diary-curtain
          [diaries]="submittedDiaries"
          [shouldCollapseCurtain]="collapseOpenedCurtain"></app-diary-curtain>
      </ng-container>

      <ng-container
        *ngIf="
          job?.gicopUI.customerFeedbacks.length ||
          job?.gicopUI.customerContacts.length ||
          job?.gicopUI.codeOfPracticeBreaches.length ||
          job?.gicopUI.customerVulnerabilities.length
        ">
        <app-gicop-notifications-curtain
          [gicopNotifications]="job?.gicopUI"
          [timezone]="timezone"></app-gicop-notifications-curtain>
      </ng-container>

      <div *ngFor="let item of submittedItems">
        <app-quote-curtain
          *ngIf="item.type === 'QUOTE' && (!unSubmittedQuote || item.document.authorized)"
          [document]="item"
          [isSentToInsurerTray]="false"
          [shouldCollapseCurtain]="collapseOpenedCurtain"
          [quotingSystemName]="job.quotingSystem?.companyName"
          showTotals></app-quote-curtain>

        <app-invoice-curtain
          (refreshInvoice)="refresh()"
          *ngIf="item.type === 'INVOICE' && !item.document.isPartial"
          [document]="item"
          [isSentToInsurerTray]="false"
          [shouldCollapseCurtain]="collapseOpenedCurtain"
          [repairerSiteId]="job?.repairerSiteId"></app-invoice-curtain>

        <app-partial-invoice
          *ngIf="item.type === 'INVOICE' && item.document.isPartial"
          [document]="item"
          [repairerSiteId]="job?.repairerSiteId"
          [shouldCollapseCurtain]="collapseOpenedCurtain"></app-partial-invoice>

        <app-authority-curtain
          (disableSendButton)="disableSendButton = $event"
          (update)="synchronizeInvoiceOwnerContribution($event); synchronizePreAuthority($event)"
          *ngIf="item.documentType === 'AUTHORITY'"
          [authority]="item"
          [editable]="!(submittedInvoices.length > 0) && !job?.cancelled"
          [excessType]="job?.excessType"
          [jobId]="job?.jobId"
          [repairerSiteId]="job?.repairerSiteId"
          [insurer]="insurer"
          [shouldCollapseCurtain]="collapseOpenedCurtain"></app-authority-curtain>
      </div>
    </div>

    <div
      *ngIf="
        unSubmittedDocuments?.length ||
        unSubmittedPhotos?.length ||
        unSubmittedDocs?.length ||
        unSubmittedInvoice ||
        unSubmittedDiary ||
        job?.gicopUI?.unsubmittedGicopNotification ||
        noQuoteResponse ||
        showBooking ||
        showSelfAuthorisationCurtain
      "
      [class.guidance]="showValidationGuidance"
      class="send-to-insco-section">
      <app-send-to-insco-tabs
        [job]="job"
        [recipient]="job?.cards | skateholdersInfo: 'role' : 'ASSESSOR'"
        [ownerName]="ownerName"
        [unSubmittedDocs]="unSubmittedDocs"
        [unSubmittedPhotos]="unSubmittedPhotos"
        [unSubmittedQuote]="unSubmittedQuote"
        [unSubmittedInvoice]="unSubmittedInvoice"
        [noQuoteResponse]="noQuoteResponse"
        [noQuoteResponseAssessmentAction]="noQuoteResponseAssessmentAction"
        [submittedQuotes]="submittedQuotes"
        [unSubmittedDiary]="unSubmittedDiary"
        [unSubmittedCustomerContact]="unSubmittedCustomerContact"
        [unSubmittedPotentialBreachOfCode]="unSubmittedPotentialBreachOfCode"
        [unSubmittedCustomerVulnerability]="unSubmittedCustomerVulnerability"
        [showSelfAuthorisationCurtain]="showSelfAuthorisationCurtain"
        [submittedQuoteNotAuthorised]="submittedQuoteNotAuthorised"
        [unSubmittedCustomerFeedback]="unSubmittedCustomerFeedback"
        [showValidationGuidance]="showValidationGuidance"
        [showBooking]="showBooking"
        [hasQuotes]="hasQuotes"
        [indexTab]="indexTab"
        [expandCurtainInTray]="expandCurtainInTray"
        (refresh)="refresh($event)"
        (updateSelectedPhoto)="updateSelectedPhoto($event)"
        (validateSendJob)="validateSendJob($event)"
        (openPdfReport)="openPdfReport($event)"
        (disableSendButton)="disableSendButton = $event"
        (synchronize)="synchronizeAuthorityOwnerContribution($event); synchronizePreAuthority($event)">
      </app-send-to-insco-tabs>
    </div>
  </div>
  <div *ngIf="!job?.cancelled" class="action-bar">
    <div class="action-bar-content">
      <app-job-tasks
        [job]="job"
        [jobStates]="jobStates"
        [noQuoteResponse]="noQuoteResponse"
        [invoiceCreated]="invoiceCreated"
        [supplementQuote]="supplementQuote"
        [invoicePendingReview]="invoicePendingReview"
        [showBooking]="showBooking"
        [displayAddInvoiceButton]="displayAddInvoiceButton"
        [showSelfAuthorisationCurtain]="showSelfAuthorisationCurtain"
        [unSubmittedQuote]="unSubmittedQuote"
        [ownerName]="ownerName"
        [invoiceButtonMsg]="invoiceButtonMsg"
        [inputFileValue]="inputFileValue"
        [documentFeatureEnabled]="documentFeatureEnabled$ | async"
        [unSubmittedDiary]="unSubmittedDiary"
        (taskButtonClicked)="performingTask($event)">
      </app-job-tasks>
      <div>
        <span
          class="pending-items-guidance body-2"
          *ngIf="
            unSubmittedDocuments?.length ||
            unSubmittedPhotos?.length ||
            unSubmittedDocs?.length ||
            unSubmittedDiary ||
            showBooking ||
            noQuoteResponse ||
            showSelfAuthorisationCurtain ||
            job?.gicopUI?.unsubmittedGicopNotification
          ">
          {{ countItemsToSend }} {{ countItemsToSend === 1 ? 'item' : 'items' }} pending
        </span>
        <button
          (click)="submitJob()"
          [disabled]="
            disableSendButton ||
            !(
              unSubmittedQuote ||
              unSubmittedPhotos?.length ||
              unSubmittedDocs?.length ||
              unSubmittedInvoice ||
              unSubmittedDiary ||
              noQuoteResponse ||
              showBooking ||
              showSelfAuthorisationCurtain ||
              job?.gicopUI?.unsubmittedGicopNotification
            ) ||
            jobService.displayWarningQuoteTab()
          "
          class="sent-button"
          color="primary"
          mat-raised-button>
          <mat-icon>send</mat-icon>
          SEND
        </button>
      </div>

      <div
        *ngIf="
          (showValidationGuidance &&
            (unSubmittedQuote ||
              unSubmittedPhotos?.length ||
              unSubmittedDocs?.length ||
              unSubmittedInvoice ||
              unSubmittedDiary ||
              noQuoteResponse ||
              showBooking ||
              unSubmittedCustomerFeedback ||
              unSubmittedCustomerContact ||
              unSubmittedPotentialBreachOfCode ||
              unSubmittedCustomerVulnerability ||
              isVehicleInfosInvalid)) ||
          jobService.displayWarningQuoteTab()
        "
        class="incomplete-guidance body-1">
        <mat-icon class="info-outline-icon">info_outline</mat-icon>
        <div class="guidance-text">
          <ng-container *ngIf="!jobService.displayWarningQuoteTab(); else warningConflictQuote">
            <div class="instructions subtitle-1">Complete</div>
            <div class="validationDetails subtitle-1" [innerHTML]="validationDetails"></div>
          </ng-container>
          <ng-template #warningConflictQuote>
            <div class="instructions subtitle-1">Resolve quoting conflict</div>
            <div class="validationDetails subtitle-1">to send</div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</mat-accordion>
