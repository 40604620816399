<table
  [class.hidden]="loadingRepairers"
  #tableSort="matSort"
  (matSortChange)="activeColumn = $event.active"
  [dataSource]="tableDataSource"
  mat-table
  matSort
  matSortActive="companyName"
  matSortDirection="asc"
  matSortDisableClear>
  <ng-container *ngFor="let columnInfo of columnInfos" [matColumnDef]="columnInfo?.matColumnDef">
    <ng-container *ngIf="!['kebab', 'status'].includes(columnInfo?.matColumnDef); else SortlessColumnHeader">
      <th
        (click)="columnHeaderOrderChange.emit({ sort: tableSort?.active, direction: tableSort?.direction })"
        *matHeaderCellDef
        [class.active]="activeColumn === columnInfo?.matColumnDef"
        mat-header-cell
        mat-sort-header>
        {{ columnInfo?.columnTitle | titlecase }}
      </th>
    </ng-container>
    <ng-template #SortlessColumnHeader>
      <th *matHeaderCellDef [class.active]="activeColumn === columnInfo?.matColumnDef" mat-header-cell>
        {{ columnInfo?.columnTitle ?? '' | titlecase }}
      </th>
    </ng-template>
    <td *matCellDef="let item" mat-cell>
      <span *ngIf="!['status', 'kebab', 'mainContact.phone', 'published'].includes(columnInfo?.matColumnDef)">
        {{ item | objectDeepValue: columnInfo.property }}
      </span>
      <span *ngIf="'mainContact.phone' === columnInfo?.matColumnDef">
        {{ item | objectDeepValue: (item?.phoneNumber ? 'phoneNumber' : 'mainContact.phone') }}
      </span>
      <span *ngIf="'published' === columnInfo?.matColumnDef && item.publishedDateTime">
        {{ item.publishedDateTime | dateepoch: currentUserTimezone }}
        {{ item.publishedDateTime | time: currentUserTimezone }}
      </span>
      <app-status-cell-info
        *ngIf="columnInfo?.matColumnDef === 'status' || columnInfo?.columnTitle === 'status'"
        [data]="item"
        [currentUserTimezone]="currentUserTimezone">
      </app-status-cell-info>

      <mat-panel-description class="kebab-panel" *ngIf="columnInfo?.matColumnDef === 'kebab'">
        <div class="secondary-icon">
          <button mat-icon-button (click)="$event.stopPropagation()" [matMenuTriggerFor]="revokeTokenMenu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #revokeTokenMenu="matMenu" class="kebab-menu">
            <ng-container *ngFor="let kebabMenuButton of kebabMenuButtons | menuList: item : item?.status">
              <button mat-menu-item (click)="kebabMenuClicked.emit({ param: item, action: kebabMenuButton?.action })">
                <span>{{ kebabMenuButton?.label }}</span>
              </button>
            </ng-container>
          </mat-menu>
        </div>
      </mat-panel-description>
    </td>
  </ng-container>

  <tr *matHeaderRowDef="columnHeaders" mat-header-row></tr>
  <tr
    *matRowDef="let row; columns: columnHeaders"
    mat-row
    [ngClass]="{ 'cursor-default': row.status === 'ARCHIVED' }"
    (click)="row.status !== 'ARCHIVED' && rowItemClicked.emit(+row.id);"></tr>
</table>
