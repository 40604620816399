<div class="header-container">
  <h3>User Management</h3>
  <button [disabled]="repairSiteStatus === 'PENDING'" (click)="addEditUser.emit()" mat-raised-button color="accent">
    ADD NEW USER
  </button>
</div>
<mat-divider></mat-divider>

<mat-tab-group animationDuration="0ms" class="user-management-section" color="primary">
  <mat-tab>
    <div>
      <table
        (matSortChange)="onSortEvent($event)"
        [dataSource]="usersDataSource"
        class="table-style"
        mat-table
        matSort
        matSortActive="permission"
        matSortDirection="asc"
        matSortDisableClear>
        <ng-container matColumnDef="permission">
          <th *matHeaderCellDef [class.active]="activeColumn === 'permission'" mat-header-cell mat-sort-header>
            Permissions
          </th>
          <td *matCellDef="let user" mat-cell>
            <div class="primary-contact">
              {{ user.privileges.length > 0 ? 'Administrator' : 'Repairer' }}
              <mat-icon
                *ngIf="user.isPrimaryContact"
                [matTooltipPosition]="'above'"
                matTooltip="Primary contact"
                tooltipClass="body-2">
                phone_callback
              </mat-icon>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="username">
          <th *matHeaderCellDef [class.active]="activeColumn === 'username'" mat-header-cell mat-sort-header>
            Username
          </th>
          <td *matCellDef="let user" mat-cell>{{ user.username }}</td>
        </ng-container>
        <ng-container matColumnDef="firstName">
          <th *matHeaderCellDef [class.active]="activeColumn === 'firstName'" mat-header-cell mat-sort-header>
            First Name
          </th>
          <td *matCellDef="let user" mat-cell>{{ user.firstName }}</td>
        </ng-container>
        <ng-container matColumnDef="lastName">
          <th *matHeaderCellDef [class.active]="activeColumn === 'lastName'" mat-header-cell mat-sort-header>
            Last Name
          </th>
          <td *matCellDef="let user" mat-cell>{{ user.lastName }}</td>
        </ng-container>
        <ng-container matColumnDef="phone">
          <th *matHeaderCellDef [class.active]="activeColumn === 'phone'" mat-header-cell mat-sort-header>Phone</th>
          <td *matCellDef="let user" mat-cell>{{ user.phone }}</td>
        </ng-container>
        <ng-container matColumnDef="email">
          <th *matHeaderCellDef [class.active]="activeColumn === 'email'" mat-header-cell mat-sort-header>Email</th>
          <td *matCellDef="let user" mat-cell>{{ user.email }}</td>
        </ng-container>
        <ng-container matColumnDef="isActivated">
          <th *matHeaderCellDef [class.active]="activeColumn === 'isActivated'" mat-header-cell mat-sort-header>
            Status
          </th>
          <td *matCellDef="let user" mat-cell>
            <span
              [appHighlightStatus]="
                user.isActivated ? (user.status === 'ENABLED' ? 'ACTIVE' : user.status) : 'INVITED'
              ">
              {{ user.isActivated ? (user.status === 'ENABLED' ? 'Active' : (user.status | titlecase)) : 'Invited' }}
            </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="kebab">
          <th *matHeaderCellDef mat-header-cell></th>
          <td *matCellDef="let user" mat-cell>
            <div
              class="secondary-icon"
              *ngIf="currentUser?.privileges?.includes('REPADMIN') || currentUser?.privileges?.includes('SYSADMIN')">
              <button mat-icon-button [matMenuTriggerFor]="userMenu" (click)="$event.stopPropagation()">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #userMenu="matMenu" class="kebab-menu">
                <button mat-menu-item *ngIf="!user.isActivated" (click)="confirmDeleteInvitedUser.emit(user.username)">
                  <span>Delete</span>
                </button>
                <button
                  mat-menu-item
                  *ngIf="user.isActivated && (user.status === 'ENABLED' || user.status === 'SUSPENDED')"
                  (click)="enableDisableUser.emit({ user: user, disable: true })">
                  <span>Disable User</span>
                </button>
                <button
                  mat-menu-item
                  *ngIf="user.isActivated && (user.status === 'DISABLED' || user.status === 'SUSPENDED')"
                  (click)="enableDisableUser.emit({ user: user, disable: false })">
                  <span>Enable User</span>
                </button>
              </mat-menu>
            </div>
          </td>
        </ng-container>

        <tr *matHeaderRowDef="usersColumnHeaders" mat-header-row></tr>
        <tr *matRowDef="let row; columns: usersColumnHeaders" mat-row (click)="addEditUser.emit(row)"></tr>
      </table>
      <div *ngIf="!locationAdminStore.usersOfRepairerSite()?.length">
        <div class="no-results">No users</div>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>
