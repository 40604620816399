import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { RepairerSiteCreate, UserCreate } from '../../../model/user.model';
import { MessagingService } from '../../../components/messaging/messaging.service';
import { ToolbarService } from '../../../services/toolbar.service';
import { COUNTRIES } from '../../../model/states.model';
import { EscapeDialogHelper } from '../../../helpers/escape-dialog.helper';
import { SysAdminService } from '../../../services/sys-admin.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EMAIL_PATTERN_STRING } from '../../../consts/patterns.const';
import { QUOTING_SYSTEMS } from '../../../consts/quotingSystems.const';
import { abnValidator } from '../../../validators/abn.validator';

@Component({
  selector: 'app-add-repairer-site',
  templateUrl: './add-repairer-site.component.html'
})
export class AddRepairerSiteComponent implements OnInit, OnDestroy {
  repairerForm: UntypedFormGroup;
  countries = COUNTRIES;
  quotingSystemList = QUOTING_SYSTEMS;
  pending = false;
  private unsubscribe = new Subject();

  data = inject(MAT_DIALOG_DATA);
  private dialogRef = inject(MatDialogRef<AddRepairerSiteComponent>);
  private fb = inject(UntypedFormBuilder);
  private sysAdminService = inject(SysAdminService);
  private toolbarService = inject(ToolbarService);
  private message = inject(MessagingService);
  private escapeDialogRef = inject(EscapeDialogHelper);

  get username() {
    return this.repairerForm.get('username');
  }
  get emailRepairer() {
    return this.repairerForm.get('emailRepairer');
  }
  get abn() {
    return this.repairerForm.get('abn');
  }
  get companyName() {
    return this.repairerForm.get('companyName');
  }
  get phoneNumber() {
    return this.repairerForm.get('phoneNumber');
  }
  get licenseNumber() {
    return this.repairerForm.get('licenseNumber');
  }
  get emailCompany() {
    return this.repairerForm.get('emailCompany');
  }
  get country() {
    return this.repairerForm.get('country');
  }
  get firstName() {
    return this.repairerForm.get('firstName');
  }
  get lastName() {
    return this.repairerForm.get('lastName');
  }
  get phone() {
    return this.repairerForm.get('phone');
  }
  get legacyCustomerId() {
    return this.repairerForm.get('legacyCustomerId');
  }
  get quotingSystem() {
    return this.repairerForm.get('quotingSystem');
  }
  get repairerGroupId() {
    return this.repairerForm.get('repairerGroupId');
  }

  ngOnInit() {
    const pendingPublication = this.data && this.data.repairerSite && this.data.repairerSite.mainContact.isActivated;
    this.repairerForm = this.fb.group({
      companyName: [
        {
          value: this.data && this.data.repairerSite ? this.data.repairerSite.companyName : '',
          disabled: pendingPublication
        },
        [Validators.required]
      ],
      emailCompany: [
        {
          value: this.data && this.data.repairerSite ? this.data.repairerSite.email : '',
          disabled: pendingPublication
        },
        [Validators.required, Validators.pattern(EMAIL_PATTERN_STRING)]
      ],
      phoneNumber: [
        {
          value: this.data && this.data.repairerSite ? this.data.repairerSite.phoneNumber : '',
          disabled: pendingPublication
        },
        [Validators.required]
      ],
      licenseNumber: [
        {
          value: this.data && this.data.repairerSite ? this.data.repairerSite.licenseNumber : '',
          disabled: pendingPublication
        }
      ],
      abn: [
        {
          value: this.data && this.data.repairerSite ? this.data.repairerSite.abn : '',
          disabled: pendingPublication
        },
        this.data?.repairerSite?.country === this.countries[0].stateName
          ? [Validators.required, abnValidator]
          : [Validators.required]
      ],
      country: [
        {
          value: this.data && this.data.repairerSite ? this.data.repairerSite.country : '',
          disabled: pendingPublication
        },
        [Validators.required]
      ],
      username: [
        {
          value: this.data && this.data.repairerSite ? this.data.repairerSite.mainContact.username : '',
          disabled: this.data && this.data.repairerSite && !this.data?.repairerSite?.mainContact.isActivated
        },
        [Validators.required]
      ],
      firstName: [
        {
          value: this.data && this.data.repairerSite ? this.data.repairerSite.mainContact.firstName : '',
          disabled: pendingPublication
        },
        [Validators.required]
      ],
      lastName: [
        {
          value: this.data && this.data.repairerSite ? this.data.repairerSite.mainContact.lastName : '',
          disabled: pendingPublication
        },
        [Validators.required]
      ],
      emailRepairer: [
        {
          value: this.data && this.data.repairerSite ? this.data.repairerSite.mainContact.emailAddress : '',
          disabled: pendingPublication
        },
        [Validators.required, Validators.pattern(EMAIL_PATTERN_STRING)]
      ],
      phone: {
        value:
          this.data && this.data.repairerSite && this.data.repairerSite.mainContact.phone
            ? this.data.repairerSite.mainContact.phone
            : '',
        disabled: pendingPublication
      },
      legacyCustomerId: this.data && this.data.repairerSite ? this.data.repairerSite.legacyCustomerId : '',
      quotingSystem: [
        {
          value: this.data && this.data.repairerSite ? this.data.repairerSite.quotingSystem : '',
          disabled: pendingPublication
        }
      ],
      repairerGroupId: [this.data?.repairerSite?.repairerGroupId]
    });

    this.escapeDialogRef.escapeDialog(this.dialogRef);
    this.country.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe({
      next: (country: string) => {
        if (country === this.countries[0].stateName) {
          this.abn.setValidators([Validators.required, abnValidator]);
          this.adjustFormatOfAbn();
        } else {
          this.abn.setValidators(Validators.required);
        }
        this.abn.updateValueAndValidity();
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  adjustFormatOfAbn() {
    this.abn.updateValueAndValidity();
    if (this.abn.valid && this.country.value === this.countries[0].stateName) {
      this.abn.patchValue(this.abn.value.replace(/\B(?=(\d{3})+(?!\d))/g, ' '));
    }
  }

  private getRepairSiteData(): RepairerSiteCreate {
    return {
      abn: this.abn.value,
      companyName: this.companyName.value,
      phoneNumber: this.phoneNumber.value,
      licenseNumber: this.licenseNumber.value,
      email: this.emailCompany.value,
      country: this.country.value,
      legacyCustomerId: this.legacyCustomerId.value,
      quotingSystem: this.quotingSystem.value,
      repAdmin: {
        username: this.username.value.trim(),
        firstName: this.firstName.value.trim(),
        lastName: this.lastName.value.trim(),
        email: this.emailRepairer.value.trim(),
        phone: this.phone.value.trim()
      } as UserCreate,
      repairerGroupId: this.repairerGroupId.value
    };
  }

  addRepairerSite() {
    this.pending = true;
    this.toolbarService.setCloudState('SAVING');

    this.sysAdminService.createRepairerSite(this.getRepairSiteData()).subscribe(
      () => this.dialogRef.close('Success'),
      (error) => {
        if (error.error === 'DUPLICATE_USERNAME') {
          this.username.setErrors({ usernameDuplicate: true });
        } else {
          this.message.error('Could not create the repairer site.');
        }
        this.toolbarService.setCloudState('RESTING');
        this.pending = false;
      },
      () => this.toolbarService.setCloudState('RESTING')
    );
  }

  reinviteRepairerSite() {
    this.pending = true;
    this.toolbarService.setCloudState('SAVING');

    const emailRepairer = this.emailRepairer.dirty ? encodeURIComponent(this.emailRepairer.value.trim()) : '';

    this.sysAdminService.updateAndReinviteRepairerSite(emailRepairer, this.data.repairerSite.id).subscribe(
      () => this.dialogRef.close('Success'),
      () => {
        this.message.error('Could not re-invite the repairer site.');
        this.toolbarService.setCloudState('RESTING');
        this.pending = false;
      },
      () => this.toolbarService.setCloudState('RESTING')
    );
  }

  editRepairerSite() {
    this.pending = true;
    this.toolbarService.setCloudState('SAVING');
    const repairerSite = this.getRepairSiteData();
    this.sysAdminService.updateUnpublishedRepairerSites(this.data.repairerSite.id, repairerSite).subscribe({
      next: () => {
        this.dialogRef.close('Success');
        this.toolbarService.setCloudState('RESTING');
      },
      error: () => {
        this.message.error('Could not edit the repairer site.');
        this.toolbarService.setCloudState('RESTING');
        this.pending = false;
      }
    });
  }
}
