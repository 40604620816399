import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { DateInputHelper } from '../../../helpers/date-input.helper';
import * as moment from 'moment-timezone';
import { EIO_DATE_BE_FORMAT } from '../../../consts/localization.const';
import { UserService } from '../../../services/user.service';
import { ToolbarService } from '../../../services/toolbar.service';
import { MessagingService } from '../../../components/messaging/messaging.service';
import { RepairTimelineDates } from '../../../model/repairTimelineDates.model';
import {
  CalendarEvent,
  CalendarView,
  CalendarMonthViewEventTimesChangedEvent,
  CalendarDateFormatter,
  CalendarMomentDateFormatter
} from 'angular-calendar';
import { isValid } from 'date-fns';
import { EscapeDialogHelper } from '../../../helpers/escape-dialog.helper';
import { RepairTimelineColors, RepairTimelineStatus } from '../../../enums/RepairTimelineStatusEnum.enum';
import { PhotoReference } from '../../../model/job.model';

@Component({
  selector: 'app-review-timeline',
  templateUrl: './update-repair-timeline.component.html',
  styleUrls: ['./update-repair-timeline.component.scss'],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CalendarMomentDateFormatter
    }
  ]
})
export class UpdateRepairTimelineComponent implements OnInit {
  view: CalendarView = CalendarView.Month;
  activeDayIsOpen = true;
  repairTimelineForm: UntypedFormGroup;
  todayDate: any;
  pending = false;
  title = 'Update Repair Timeline';
  viewDate = moment().toDate();
  events = [];
  type = null;
  timelineDates: RepairTimelineDates = {
    repairsBookedDate: null,
    vehicleDateIn: null,
    repairsStartedDate: null,
    repairsCompletedDate: null,
    vehicleDateOut: null
  };
  isSaveBtnDisabled = true;
  chronologicalOrderMessage: string;
  eventColorOndrag = '#666666';
  readonly repairTimelineStatus = RepairTimelineStatus;
  starredPhotoInfo: PhotoReference;

  externalEvents: CalendarEvent[] = [
    {
      id: 0,
      title: RepairTimelineStatus.REPAIRS_BOOKED,
      color: {
        primary: RepairTimelineColors.PRIMARY,
        secondary: RepairTimelineColors.SECONDARY
      },
      cssClass: 'repairer-booked',
      start: this.data?.repairTimelineDates?.repairBookedDate
        ? moment(this.data?.repairTimelineDates?.repairBookedDate).toDate()
        : null,
      draggable: true
    },
    {
      id: 1,
      title: RepairTimelineStatus.VEHICLE_IN,
      color: {
        primary: RepairTimelineColors.PRIMARY,
        secondary: RepairTimelineColors.SECONDARY
      },
      cssClass: 'vehicle-in',
      start: this.data?.repairTimelineDates?.vehicleDateIn
        ? moment(this.data?.repairTimelineDates?.vehicleDateIn).toDate()
        : null,
      draggable: true
    },
    {
      id: 2,
      title: RepairTimelineStatus.REPAIRS_STARTED,
      color: {
        primary: RepairTimelineColors.PRIMARY,
        secondary: RepairTimelineColors.SECONDARY
      },
      cssClass: 'repairer-started',
      start: this.data?.repairTimelineDates?.repairStartedDate
        ? moment(this.data?.repairTimelineDates?.repairStartedDate).toDate()
        : null,
      draggable: true
    },
    {
      id: 3,
      title: RepairTimelineStatus.REPAIRS_COMPLETED,
      color: {
        primary: RepairTimelineColors.PRIMARY,
        secondary: RepairTimelineColors.SECONDARY
      },
      cssClass: 'repairer-completed',
      start: this.data?.repairTimelineDates?.repairCompletedDate
        ? moment(this.data?.repairTimelineDates?.repairCompletedDate).toDate()
        : null,
      draggable: true
    },
    {
      id: 4,
      title: RepairTimelineStatus.VEHICLE_OUT,
      color: {
        primary: RepairTimelineColors.PRIMARY,
        secondary: RepairTimelineColors.SECONDARY
      },
      cssClass: 'vehicle-out',
      start: this.data?.repairTimelineDates?.vehicleDateOut
        ? moment(this.data?.repairTimelineDates?.vehicleDateOut).toDate()
        : null,
      draggable: true
    }
  ];

  isPlacedInCalendar = (events, externalEvt) => {
    const externalDate = moment(externalEvt.start).format(EIO_DATE_BE_FORMAT);
    const todayDate = moment().tz(this.data.userTimezone);
    return (
      !!events?.find((evt) => evt.title === externalEvt.title) &&
      moment.tz(externalDate, this.data.userTimezone).isSameOrBefore(todayDate)
    );
  };

  constructor(
    public dialogRef: MatDialogRef<UpdateRepairTimelineComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder,
    public dateInputHelper: DateInputHelper,
    private userService: UserService,
    public toolbarService: ToolbarService,
    private message: MessagingService,
    private escapeDialogRef: EscapeDialogHelper
  ) {}

  get explanation() {
    return this.repairTimelineForm.get('explanation');
  }
  get eventWithTitles() {
    return this.events?.filter((evt) => !!evt.title && !evt.notMwlDraggable);
  }
  get isCalendarInError() {
    return this.events.some((evt) => evt?.errorMessages?.length > 0);
  }

  eventDropped({ event, newStart, newEnd, day, type }: CalendarMonthViewEventTimesChangedEvent): void {
    this.type = type;
    const externalIndex = this.externalEvents.findIndex((evt) => evt.title === event.title);
    event.start = isValid(newStart) ? newStart : moment(day.date).toDate();
    const currentEventIndex = this.events.findIndex((evt) => evt.title === event.title);
    currentEventIndex > -1 ? this.events.splice(currentEventIndex, 1, event) : this.events.push(event);
    this.externalEvents[externalIndex].start = event.start;
    this.events.sort((a, b) => a.id - b.id);
    this.setDates(event);
    if (newEnd) {
      event.end = newEnd;
    }
    if (this.view === 'month') {
      this.viewDate = event.start;
      this.activeDayIsOpen = true;
    }
    this.validateDateFields();
    this.validateDatesChronology();
  }

  ngOnInit(): void {
    this.escapeDialogRef.escapeDialog(this.dialogRef);
    this.repairTimelineForm = this.fb.group({
      explanation: ['']
    });

    this.events = this.externalEvents.filter((externalEvent) => externalEvent.start);
    if (this.events.length > 0) {
      this.events = this.events.map((event) => {
        this.setDates(event);
        return event;
      });
      this.validateDateFields();
      this.validateDatesChronology();
    }
    this.viewDate =
      this.events.length > 0
        ? this.events.filter((e) => e.id)[this.events.filter((e) => e.id).length - 1].start
        : moment().toDate();
    this.starredPhotoInfo =
      this.data?.job?.photoReferences?.find((photo: PhotoReference) => photo.topImage) ||
      this.data?.job?.photoIdentityUI;
  }

  validateDateFields() {
    const todayDate = moment().tz(this.data.userTimezone);
    const isAnInvoiceAddedOrSelfAuthQuotedSubmitted =
      (this.data?.job.documents?.some((doc) => doc.type === 'INVOICE' && doc.submitted) &&
        !this.data?.job.selfAuthorized) ||
      (this.data?.job.documents?.some((doc) => doc.type === 'QUOTE' && doc.submitted) && this.data?.job.selfAuthorized);
    const isAnInvoiceAdded =
      this.data?.job.documents?.some((doc) => doc.type === 'INVOICE' && !doc.submitted) &&
      !this.data?.job.selfAuthorized;
    const isAppropriatedValidationCase =
      this.data?.job.documents?.some((doc) => doc.type === 'QUOTE') && !!this.data?.job.authorityDate;
    Object.values(this.repairTimelineStatus).map((status: RepairTimelineStatus) => {
      const isVehicleInRequired =
        !!this.externalEvents?.find((event) => event.title === RepairTimelineStatus.REPAIRS_BOOKED)?.start &&
        !this.externalEvents?.find((event) => event.title === RepairTimelineStatus.VEHICLE_IN)?.start;
      const evt = this.events.find((evt) => evt.title === status && !evt?.notMwlDraggable);
      const indexEvt = this.events.findIndex((evt) => evt.title === status);
      const newEvt = {
        ...(evt || {}),
        errorMessages: []
      };
      switch (status) {
        case RepairTimelineStatus.REPAIRS_BOOKED:
          if (evt) {
            const repairsBookedDate = this.externalEvents?.find((event) => event.title === status)?.start;
            const newStartDate = !!repairsBookedDate && moment(repairsBookedDate).format(EIO_DATE_BE_FORMAT);
            const isRepairsBookedInThePast =
              !!newStartDate && moment.tz(newStartDate, this.data.userTimezone).isSameOrBefore(todayDate);
            if (!isRepairsBookedInThePast) {
              newEvt.errorMessages.push('The date the repairs were booked on must be today or in the past');
            } else if (isVehicleInRequired && !isAnInvoiceAddedOrSelfAuthQuotedSubmitted) {
              newEvt.errorMessages.push('Vehicle in is required');
            } else {
              newEvt.errorMessages = [];
            }
            this.events[indexEvt] = newEvt;
          } else if (isAnInvoiceAddedOrSelfAuthQuotedSubmitted && !this.events[indexEvt]?.notMwlDraggable) {
            newEvt.title = status;
            newEvt.start = null;
            newEvt.errorMessages = ['The Repairs Booked date is required'];
            this.events.push({ ...newEvt, notMwlDraggable: true });
          }
          break;

        case RepairTimelineStatus.VEHICLE_IN:
          if (evt) {
            const isRepairBookedDateInError = this.events.find(
              (evt) => evt?.errorMessages?.includes('The Repairs Booked date is required')
            );
            const isRepairsBookedRequired =
              !this.externalEvents?.find((event) => event.title === RepairTimelineStatus.REPAIRS_BOOKED)?.start &&
              !!this.externalEvents?.find((event) => event.title === status)?.start;
            isRepairsBookedRequired && !isRepairBookedDateInError
              ? newEvt.errorMessages.push('The date the repairs were booked on is required')
              : (newEvt.errorMessages = []);
            this.events[indexEvt] = newEvt;
          } else if (isAnInvoiceAddedOrSelfAuthQuotedSubmitted && !this.events[indexEvt]?.notMwlDraggable) {
            newEvt.title = status;
            newEvt.start = null;
            newEvt.errorMessages = ['The Vehicle In date is required'];
            this.events.push({ ...newEvt, notMwlDraggable: true });
          }
          break;
        case RepairTimelineStatus.REPAIRS_STARTED:
          if (evt) {
            const isVehicleInDateRequired =
              !!this.externalEvents?.find((event) => event.title === RepairTimelineStatus.REPAIRS_STARTED)?.start &&
              !this.externalEvents?.find((event) => event.title === RepairTimelineStatus.VEHICLE_IN)?.start;
            !isVehicleInRequired && isVehicleInDateRequired
              ? newEvt.errorMessages.push('The Vehicle In date is required')
              : (newEvt.errorMessages = []);
            this.events[indexEvt] = newEvt;
          } else if (isAnInvoiceAddedOrSelfAuthQuotedSubmitted && !this.events[indexEvt]?.notMwlDraggable) {
            newEvt.title = status;
            newEvt.start = null;
            newEvt.errorMessages = ['The Repairs Started date is required'];
            this.events.push({ ...newEvt, notMwlDraggable: true });
          }
          break;
        case RepairTimelineStatus.REPAIRS_COMPLETED:
          const repairsCompletedDate = this.externalEvents?.find(
            (event) => event.title === RepairTimelineStatus.REPAIRS_COMPLETED
          )?.start;
          const isRepairsCompletedInThePast = moment(repairsCompletedDate, this.data.userTimezone).isSameOrBefore(
            todayDate
          );
          if (evt) {
            const isRepairStartedRequiredInError = this.events.find(
              (evt) => evt?.errorMessages?.includes('The Repairs Started date is required')
            );
            const isRepairStartedRequired =
              !!repairsCompletedDate &&
              !this.externalEvents?.find((event) => event.title === RepairTimelineStatus.REPAIRS_STARTED)?.start;
            isRepairStartedRequired && !isRepairStartedRequiredInError
              ? newEvt.errorMessages.push('The Repairs Started date is required')
              : (newEvt.errorMessages = []);
            if (
              isAppropriatedValidationCase &&
              !isRepairsCompletedInThePast &&
              !['AUTHORIZED', 'PRE_AUTHORIZED'].includes(this.data.job.status) &&
              isAnInvoiceAdded &&
              !!this.type
            ) {
              newEvt.title = status;
              newEvt.errorMessages = ['The repair completed date must be today or in the past'];
            }
            this.events[indexEvt] = newEvt;
          } else if (isAnInvoiceAddedOrSelfAuthQuotedSubmitted && !this.events[indexEvt]?.notMwlDraggable) {
            newEvt.title = status;
            newEvt.start = null;
            newEvt.errorMessages = ['The Repairs Completed date is required'];
            this.events.push({ ...newEvt, notMwlDraggable: true });
          }
          break;
        case RepairTimelineStatus.VEHICLE_OUT:
          if (evt) {
            const isAlreadyRepairsCompletedInError = this.events.find(
              (evt) => evt?.errorMessages?.includes('The Repairs Completed date is required')
            );
            const isRepairCompletedRequired =
              !!this.externalEvents?.find((event) => event.title === RepairTimelineStatus.VEHICLE_OUT)?.start &&
              !this.externalEvents?.find((event) => event.title === RepairTimelineStatus.REPAIRS_COMPLETED)?.start;
            isRepairCompletedRequired && !isAlreadyRepairsCompletedInError
              ? newEvt.errorMessages.push('The Repairs Completed date is required')
              : (newEvt.errorMessages = []);
            const vehicleOutDate = this.externalEvents?.find((evt) => evt.title === RepairTimelineStatus.VEHICLE_OUT)
              ?.start;
            if (
              isAppropriatedValidationCase &&
              this.data?.job?.documents?.some((doc) => doc.type === 'INVOICE' && doc.submitted) &&
              !['AUTHORIZED', 'PRE_AUTHORIZED'].includes(this.data.job.status) &&
              !vehicleOutDate
            ) {
              newEvt.title = status;
              newEvt.start = null;
              newEvt.errorMessages = ['The vehicle out date must be set'];
            }
            this.events[indexEvt] = newEvt;
          } else if (
            isAppropriatedValidationCase &&
            this.data?.job?.documents?.some((doc) => doc.type === 'INVOICE' && doc.submitted) &&
            !['AUTHORIZED', 'PRE_AUTHORIZED'].includes(this.data.job.status) &&
            !this.events[indexEvt]?.notMwlDraggable
          ) {
            newEvt.title = status;
            newEvt.start = null;
            newEvt.errorMessages = ['The vehicle out date must be set'];
            this.events.push({ ...newEvt, notMwlDraggable: true });
          }
          break;
      }
    });
    this.isSaveBtnDisabled = this.isCalendarInError;
  }

  saveRepairerDates() {
    this.pending = true;
    this.timelineDates.timezoneId = this.data.userTimezone
      ? this.data.userTimezone
      : Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.timelineDates.explanation = this.explanation?.value;
    this.timelineDates.repairsBookedDate = this.timelineDates?.repairsBookedDate
      ? moment(this.timelineDates?.repairsBookedDate).format(EIO_DATE_BE_FORMAT)
      : null;
    this.timelineDates.vehicleDateIn = this.timelineDates?.vehicleDateIn
      ? moment(this.timelineDates?.vehicleDateIn).format(EIO_DATE_BE_FORMAT)
      : null;
    this.timelineDates.repairsStartedDate = this.timelineDates?.repairsStartedDate
      ? moment(this.timelineDates?.repairsStartedDate).format(EIO_DATE_BE_FORMAT)
      : null;
    this.timelineDates.repairsCompletedDate = this.timelineDates?.repairsCompletedDate
      ? moment(this.timelineDates?.repairsCompletedDate).format(EIO_DATE_BE_FORMAT)
      : null;
    this.timelineDates.vehicleDateOut = this.timelineDates?.vehicleDateOut
      ? moment(this.timelineDates?.vehicleDateOut).format(EIO_DATE_BE_FORMAT)
      : null;
    this.toolbarService.setCloudState('SAVING');
    this.userService.updateRepairDates(this.timelineDates, this.data.job.jobId).subscribe(
      () => this.dialogRef.close('Success'),
      () => {
        this.message.error('Could not update repair dates.');
        this.toolbarService.setCloudState('RESTING');
        this.pending = false;
      },
      () => {
        this.toolbarService.setCloudState('RESTING');
        this.pending = false;
      }
    );
  }

  externalDrop(event: CalendarEvent) {
    if (this.externalEvents.indexOf(event) > -1) {
      this.events = this.events.filter((iEvent) => iEvent.title !== event.title);
      event.start = null;
      this.setDates(event);
      this.validateDateFields();
      this.validateDatesChronology();
    }
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  setDates(event: any) {
    const dateIsSameOrBeforeToday = moment
      .tz(moment(event.start).format(EIO_DATE_BE_FORMAT), this.data.userTimezone)
      .isAfter(moment().tz(this.data.userTimezone));
    switch (event.title) {
      case 'Repairs Booked':
        this.timelineDates.repairsBookedDate = event.start;
        event.cssClass = dateIsSameOrBeforeToday ? 'repairer-booked-black' : 'repairer-booked';
        break;
      case 'Vehicle In':
        this.timelineDates.vehicleDateIn = event.start;
        event.cssClass = dateIsSameOrBeforeToday ? 'vehicle-in-black' : 'vehicle-in';
        break;
      case 'Repairs Started':
        this.timelineDates.repairsStartedDate = event.start;
        event.cssClass = dateIsSameOrBeforeToday ? 'repairer-started-black' : 'repairer-started';
        break;
      case 'Repairs Completed':
        this.timelineDates.repairsCompletedDate = event.start;
        event.cssClass = dateIsSameOrBeforeToday ? 'repairer-completed-black' : 'repairer-completed';
        break;
      case 'Vehicle Out':
        this.timelineDates.vehicleDateOut = event.start;
        event.cssClass = dateIsSameOrBeforeToday ? 'vehicle-out-black' : 'vehicle-out';
        break;
    }
  }

  validateDatesChronology() {
    let validationResult;

    const repairsBookedDate = moment(this.timelineDates?.repairsBookedDate).format(EIO_DATE_BE_FORMAT);
    const vehicleDateIn = moment(this.timelineDates?.vehicleDateIn).format(EIO_DATE_BE_FORMAT);
    const repairsStartedDate = moment(this.timelineDates?.repairsStartedDate).format(EIO_DATE_BE_FORMAT);
    const repairsCompletedDate = moment(this.timelineDates?.repairsCompletedDate).format(EIO_DATE_BE_FORMAT);
    const vehicleDateOut = moment(this.timelineDates?.vehicleDateOut).format(EIO_DATE_BE_FORMAT);

    for (const value of Object.values(RepairTimelineStatus)) {
      switch (value) {
        case RepairTimelineStatus.REPAIRS_BOOKED:
          validationResult =
            (this.timelineDates?.vehicleDateIn &&
              moment
                .tz(vehicleDateIn, this.data.userTimezone)
                .isBefore(moment.tz(repairsBookedDate, this.data.userTimezone))) ||
            (this.timelineDates?.repairsStartedDate &&
              moment
                .tz(repairsStartedDate, this.data.userTimezone)
                .isBefore(moment.tz(repairsBookedDate, this.data.userTimezone))) ||
            (this.timelineDates?.repairsCompletedDate &&
              moment
                .tz(repairsCompletedDate, this.data.userTimezone)
                .isBefore(moment.tz(repairsBookedDate, this.data.userTimezone))) ||
            (this.timelineDates?.vehicleDateOut &&
              moment
                .tz(vehicleDateOut, this.data.userTimezone)
                .isBefore(moment.tz(repairsBookedDate, this.data.userTimezone)));
          break;
        case RepairTimelineStatus.VEHICLE_IN:
          validationResult =
            (this.timelineDates?.repairsBookedDate &&
              moment
                .tz(vehicleDateIn, this.data.userTimezone)
                .isBefore(moment.tz(repairsBookedDate, this.data.userTimezone))) ||
            (this.timelineDates?.repairsStartedDate &&
              moment
                .tz(repairsStartedDate, this.data.userTimezone)
                .isBefore(moment.tz(vehicleDateIn, this.data.userTimezone))) ||
            (this.timelineDates?.repairsCompletedDate &&
              moment
                .tz(repairsCompletedDate, this.data.userTimezone)
                .isBefore(moment.tz(vehicleDateIn, this.data.userTimezone))) ||
            (this.timelineDates?.vehicleDateOut &&
              moment
                .tz(vehicleDateOut, this.data.userTimezone)
                .isBefore(moment.tz(vehicleDateIn, this.data.userTimezone)));
          break;
        case RepairTimelineStatus.REPAIRS_STARTED:
          validationResult =
            (this.timelineDates?.repairsBookedDate &&
              moment
                .tz(repairsStartedDate, this.data.userTimezone)
                .isBefore(moment.tz(repairsBookedDate, this.data.userTimezone))) ||
            (this.timelineDates?.vehicleDateIn &&
              moment
                .tz(repairsStartedDate, this.data.userTimezone)
                .isBefore(moment.tz(vehicleDateIn, this.data.userTimezone))) ||
            (this.timelineDates?.repairsCompletedDate &&
              moment
                .tz(repairsCompletedDate, this.data.userTimezone)
                .isBefore(moment.tz(repairsStartedDate, this.data.userTimezone))) ||
            (this.timelineDates?.vehicleDateOut &&
              moment
                .tz(vehicleDateOut, this.data.userTimezone)
                .isBefore(moment.tz(repairsStartedDate, this.data.userTimezone)));
          break;
        case RepairTimelineStatus.REPAIRS_COMPLETED:
          validationResult =
            (this.timelineDates?.repairsBookedDate &&
              moment
                .tz(repairsCompletedDate, this.data.userTimezone)
                .isBefore(moment.tz(repairsBookedDate, this.data.userTimezone))) ||
            (this.timelineDates?.vehicleDateIn &&
              moment
                .tz(repairsCompletedDate, this.data.userTimezone)
                .isBefore(moment.tz(vehicleDateIn, this.data.userTimezone))) ||
            (this.timelineDates?.repairsStartedDate &&
              moment
                .tz(repairsCompletedDate, this.data.userTimezone)
                .isBefore(moment.tz(repairsStartedDate, this.data.userTimezone))) ||
            (this.timelineDates?.vehicleDateOut &&
              moment
                .tz(vehicleDateOut, this.data.userTimezone)
                .isBefore(moment.tz(repairsCompletedDate, this.data.userTimezone)));
          break;
        case RepairTimelineStatus.VEHICLE_OUT:
          validationResult =
            (this.timelineDates?.repairsBookedDate &&
              moment
                .tz(vehicleDateOut, this.data.userTimezone)
                .isBefore(moment.tz(repairsBookedDate, this.data.userTimezone))) ||
            (this.timelineDates?.vehicleDateIn &&
              moment
                .tz(vehicleDateOut, this.data.userTimezone)
                .isBefore(moment.tz(vehicleDateIn, this.data.userTimezone))) ||
            (this.timelineDates?.repairsStartedDate &&
              moment
                .tz(vehicleDateOut, this.data.userTimezone)
                .isBefore(moment.tz(repairsStartedDate, this.data.userTimezone))) ||
            (this.timelineDates?.repairsCompletedDate &&
              moment
                .tz(vehicleDateOut, this.data.userTimezone)
                .isBefore(moment.tz(repairsCompletedDate, this.data.userTimezone)));
          break;
      }
      if (validationResult) {
        break;
      }
    }

    this.chronologicalOrderMessage = validationResult
      ? `The repair progress dates must be in chronological order`
      : null;
  }
}
