import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable, signal, WritableSignal } from '@angular/core';
import { MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { EMPTY, Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { DynamicDialogService } from '../../../components/dynamic-dialog/dynamic-dialog-service';
import { MessagingService } from '../../../components/messaging/messaging.service';
import { ResponseEnumEnum } from '../../../enums/responseEnum.enum';
import { CompanyInfo } from '../../../model/company-information.model';
import { FinanceCompanyUpdate } from '../../../model/financing-company.model';
import { RepairerSiteView, UserRepairerSite } from '../../../model/user.model';
import { SysAdminService } from '../../../services/sys-admin.service';
import { ToolbarService } from '../../../services/toolbar.service';

export interface RepairerSitesState extends EntityState<UserRepairerSite> {
  selectedRepairerSite: (UserRepairerSite & RepairerSiteView) | null;
  isLoading: boolean;
}

export const adapter: EntityAdapter<UserRepairerSite> = createEntityAdapter<UserRepairerSite>();

export const initialState: RepairerSitesState = adapter.getInitialState({
  selectedRepairerSite: null,
  isLoading: false
});

const { selectAll } = adapter.getSelectors();

@Injectable({ providedIn: 'root' })
export class RepairerSitesStore extends ComponentStore<RepairerSitesState> {
  #sysAdminService = inject(SysAdminService);
  #dynamicDialogService = inject(DynamicDialogService);
  #message = inject(MessagingService);
  #toolbarService = inject(ToolbarService);

  refreshRepairerSites = signal(false);
  repairerSites = this.selectSignal((state) => selectAll(state));
  selectedRepairerSite = this.selectSignal((state) => state.selectedRepairerSite);
  abnUpdateErrors: WritableSignal<any> = signal(null);

  constructor() {
    super(initialState);
  }

  getRepairerSite = this.effect(
    (trigger$: Observable<{ repairerSite: UserRepairerSite; dialogConfig?: MatDialogConfig }>) =>
      trigger$.pipe(
        tap(() => this.#toolbarService.setCloudState('FETCHING')),
        switchMap((data) => {
          const { repairerSite, dialogConfig } = data;
          return this.#sysAdminService.getRepairerSite(repairerSite.id).pipe(
            tapResponse(
              (selectedRepairerSite: RepairerSiteView) => {
                if (selectedRepairerSite) {
                  this.patchState({ selectedRepairerSite: { ...repairerSite, ...selectedRepairerSite } });
                  dialogConfig && this.openDynamicDialog(dialogConfig);
                }
                this.#toolbarService.setCloudState('RESTING');
              },
              () => {
                this.#message.error('Could not get repairer site');
                this.#toolbarService.setCloudState('RESTING');
              }
            )
          );
        })
      )
  );

  openDynamicDialog = this.effect((trigger$: Observable<MatDialogConfig>) =>
    trigger$.pipe(
      switchMap((dialogConfig) => {
        return this.#dynamicDialogService
          .openDialog(dialogConfig)
          .afterClosed()
          .pipe(
            tapResponse(
              (response: ResponseEnumEnum) => {
                this.#dynamicDialogService.dialogRefSignal.set(null);
                response === ResponseEnumEnum.SUCCESS && this.refreshRepairerSites.set(true);
              },
              () => {
                return EMPTY;
              }
            )
          );
      })
    )
  );

  updateCompanyInformation = this.effect((companyInfo$: Observable<CompanyInfo & { id: number }>) =>
    companyInfo$.pipe(
      tap(() => this.#toolbarService.setCloudState('SAVING')),
      switchMap((companyInfo) => {
        const { id, ...payload } = companyInfo;
        return this.#sysAdminService.updateCompanyInformation(payload, id).pipe(
          tapResponse(
            () => this.#dynamicDialogService.closeDialog(ResponseEnumEnum.SUCCESS, 'Company Information updated.'),
            (errorResponse: HttpErrorResponse) => {
              const errorMessage =
                errorResponse.error.error === 'DUPLICATE_ABN_POSTCODE_ADR'
                  ? errorResponse.error.details
                  : 'Could not update Company Information.';
              this.#dynamicDialogService.closeDialog(ResponseEnumEnum.ERROR, errorMessage);
            }
          )
        );
      })
    )
  );

  updateDashboardDays = this.effect((days$: Observable<number>) =>
    days$.pipe(
      tap(() => this.#toolbarService.setCloudState('SAVING')),
      switchMap((dashboardDays) =>
        this.#sysAdminService.setDashboardDays(this.selectedRepairerSite().id, dashboardDays).pipe(
          tapResponse(
            () => {
              this.#dynamicDialogService.closeDialog(ResponseEnumEnum.SUCCESS);
            },
            () => this.#dynamicDialogService.closeDialog(ResponseEnumEnum.ERROR, 'Could not update dashboard days.')
          )
        )
      )
    )
  );

  addOrUpdateFinancingCompany = this.effect(
    (trigger$: Observable<{ id?: number; financingCompany: FinanceCompanyUpdate }>) =>
      trigger$.pipe(
        tap(() => this.#toolbarService.setCloudState('SAVING')),
        switchMap((data) =>
          (data?.id
            ? this.#sysAdminService.updateFinancingCompany(data.id, data.financingCompany)
            : this.#sysAdminService.addFinancingCompany(data.financingCompany)
          ).pipe(
            tapResponse(
              () => this.#dynamicDialogService.closeDialog(ResponseEnumEnum.SUCCESS),
              (error: any) => {
                if (error?.details?.includes('DUPLICATE_ABN')) {
                  this.abnUpdateErrors.set({ duplicateAbn: true });
                  this.#toolbarService.setCloudState('RESTING');
                } else {
                  this.#dynamicDialogService.closeDialog(
                    ResponseEnumEnum.ERROR,
                    `Could not ${data?.id ? 'update' : 'add'} finance company.`
                  );
                }
              }
            )
          )
        )
      )
  );
}
