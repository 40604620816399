import { UserRepairerSite } from '../model/user.model';
export const filterPredicate = (data: UserRepairerSite, filter: string) => {
  return (
    data?.companyName?.toLowerCase()?.includes(filter) ||
    data?.mainContact?.firstName?.toLowerCase()?.includes(filter) ||
    data?.mainContact?.lastName?.toLowerCase()?.includes(filter) ||
    data?.mainContact?.username?.toLowerCase()?.includes(filter) ||
    data?.mainContact?.emailAddress?.toLowerCase()?.includes(filter) ||
    data?.addressString?.toLowerCase()?.includes(filter) ||
    data?.country?.toLowerCase()?.includes(filter)
  );
};
