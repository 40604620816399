<div class="dialog-header" mat-dialog-title>
  <h6>Vehicle Selection</h6>
  <button aria-label="Close" mat-dialog-close mat-icon-button>
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-divider></mat-divider>
<form (ngSubmit)="selectVehicle()" [formGroup]="vehicleSelectionForm">
  <div class="vehicle-selection-container" mat-dialog-content>
    <div class="fields-box">
      <mat-form-field appearance="outline" color="accent">
        <mat-label>Make</mat-label>
        <mat-select (selectionChange)="selectMake()" formControlName="make">
          <mat-option *ngFor="let make of makes" [value]="make.id">
            {{ make.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" color="accent">
        <mat-label>Model</mat-label>
        <mat-select (selectionChange)="getVariants(); getModelYears()" formControlName="model">
          <mat-option *ngFor="let model of models" [value]="model.id">
            {{ model.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" class="small-field" color="accent">
        <mat-label>Year</mat-label>
        <mat-select (selectionChange)="getVariants(); getYearModels()" formControlName="year">
          <mat-option *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="fields-box">
      <mat-form-field appearance="outline" color="accent">
        <mat-label>Type to Filter</mat-label>
        <input (keyup)="applyFilter()" formControlName="filter" matInput />
      </mat-form-field>
    </div>
    <div>
      <mat-checkbox
        color="primary"
        #bankingCheckbox
        formControlName="unlistedGlassVehicle"
        [checked]="unlistedGlassVehicle.value">
        The vehicle is not listed
      </mat-checkbox>
    </div>
  </div>

  <div class="series" *ngIf="variants.length > 0">
    <table [dataSource]="variantDataSource" mat-table>
      <ng-container matColumnDef="checkBox">
        <th class="body-2" *matHeaderCellDef mat-header-cell></th>
        <td class="body-1" *matCellDef="let element" [class.selected]="element.nvic === selectedVehicle" mat-cell>
          <mat-icon>check</mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th class="body-2" *matHeaderCellDef mat-header-cell>Series</th>
        <td class="body-1" *matCellDef="let element" mat-cell [class.selected]="element.nvic === selectedVehicle">
          {{ element.name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="bodyType">
        <th class="body-2" *matHeaderCellDef mat-header-cell>Body Type</th>
        <td class="body-1" *matCellDef="let element" mat-cell [class.selected]="element.nvic === selectedVehicle">
          {{ element.bodyType }}
        </td>
      </ng-container>

      <ng-container matColumnDef="doors">
        <th class="body-2" *matHeaderCellDef mat-header-cell>Doors</th>
        <td class="body-1" *matCellDef="let element" mat-cell [class.selected]="element.nvic === selectedVehicle">
          {{ element.doors }}
        </td>
      </ng-container>

      <ng-container matColumnDef="transmission">
        <th class="body-2" *matHeaderCellDef mat-header-cell>Transmission</th>
        <td class="body-1" *matCellDef="let element" mat-cell [class.selected]="element.nvic === selectedVehicle">
          {{ element.transmission }}
        </td>
      </ng-container>

      <ng-container matColumnDef="month">
        <th class="body-2" *matHeaderCellDef mat-header-cell>Month</th>
        <td class="body-1" *matCellDef="let element" mat-cell [class.selected]="element.nvic === selectedVehicle">
          {{ formatMonth(element.month) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="engine">
        <th class="body-2" *matHeaderCellDef mat-header-cell>Engine</th>
        <td class="body-1" *matCellDef="let element" mat-cell [class.selected]="element.nvic === selectedVehicle">
          {{ element.engine }}
        </td>
      </ng-container>

      <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
      <tr
        id="variant-{{ row.nvic }}"
        (click)="selectedVehicle = row.nvic; unlistedGlassVehicle.setValue(false)"
        *matRowDef="let row; columns: displayedColumns"
        mat-row></tr>
    </table>
  </div>
  <div class="guide-last-updated body-2">
    <mat-icon>error_outline</mat-icon>
    <div>Glass Guide last update: {{ lastUpdateDate }}</div>
  </div>

  <mat-dialog-actions>
    <button mat-raised-button mat-dialog-close color="accent">CANCEL</button>
    <button
      [disabled]="(!unlistedGlassVehicle.value && !selectedVehicle) || pending"
      class="buttons"
      color="primary"
      mat-raised-button
      type="submit">
      SELECT
    </button>
  </mat-dialog-actions>
</form>
