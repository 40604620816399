import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Invoice } from '../../../../model/document.model';
import { MatIconModule } from '@angular/material/icon';
import { InfosActionCardComponent } from '../../../../components/infos-action-card/infos-action-card.component';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { InfosContentComponent } from '../../../../components/infos-content/infos-content.component';

@Component({
  selector: 'finance-company-guidance',
  standalone: true,
  host: { class: 'w-full' },
  templateUrl: './finance-company-guidance.component.html',
  imports: [CommonModule, MatIconModule, InfosActionCardComponent, MatButtonModule, InfosContentComponent]
})
export class FinanceCompanyGuidanceComponent {
  @Input() submitted: boolean;
  @Input() invoice: Invoice;
  @Output() useFinancingCompany: EventEmitter<void> = new EventEmitter();
  @Output() removeFinancingCompany: EventEmitter<void> = new EventEmitter();
}
