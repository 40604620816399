import { Component, OnInit } from '@angular/core';
import { DateRange, MatCalendar, MatDateRangePicker } from '@angular/material/datepicker';
import { CurrentUserService } from '../../services/currentUser.service';
import { Moment } from 'moment';
import * as moment from 'moment';
import { RangePreset } from '../../model/dates-range-preset.model';
import { RANGE_PRESETS } from '../../model/dates-range-preset.model';

@Component({
  templateUrl: './datepicker-range.component.html',
  styleUrls: ['./datepicker-range.component.scss']
})
export class DatepickerRangeComponent implements OnInit {
  readonly rangePresets = RANGE_PRESETS;
  selectedDateRange = '';
  timezone: string;

  constructor(
    private calendar: MatCalendar<Moment>,
    private currentUserService: CurrentUserService,
    private picker: MatDateRangePicker<Moment>
  ) {}

  ngOnInit(): void {
    this.timezone = this.currentUserService?.timeZone;
    this.selectedDateRange = localStorage.getItem('selectedDateRange');
  }

  selectRange(range: RangePreset): void {
    const [startDate, endDate] = this.pickDateRange(range);
    const selectedDates = this.calendar.selected as DateRange<Moment>;
    if (selectedDates.start && !selectedDates.end) {
      this.picker.select(endDate);
    }
    this.picker.select(startDate);
    this.picker.select(endDate);
    this.picker.close();
    localStorage.setItem('selectedDateRange', range);
  }

  private pickDateRange(range: RangePreset): [Moment, Moment] {
    this.selectedDateRange = range;
    let startDate: Moment;
    let endDate: Moment;

    switch (range) {
      case 'Last 30 days':
        startDate = this.timezone
          ? moment().tz(this.timezone).subtract(30, 'days').startOf('day')
          : moment().subtract(30, 'days').startOf('day');
        endDate = this.timezone ? moment().tz(this.timezone).endOf('day') : moment().endOf('day');
        return [startDate, endDate];
      case 'Last 90 days':
        startDate = this.timezone
          ? moment().tz(this.timezone).subtract(90, 'days').startOf('day')
          : moment().subtract(90, 'days').startOf('day');
        endDate = this.timezone ? moment().tz(this.timezone).endOf('day') : moment().endOf('day');
        return [startDate, endDate];
      case 'This month':
        startDate = this.timezone ? moment().tz(this.timezone).startOf('month') : moment().startOf('month');
        endDate = this.timezone ? moment().tz(this.timezone).endOf('month') : moment().endOf('month');
        return [startDate, endDate];
      case 'Last month':
        startDate = this.timezone
          ? moment().tz(this.timezone).subtract(1, 'month').startOf('month')
          : moment().subtract(1, 'month').startOf('month');
        endDate = this.timezone
          ? moment().tz(this.timezone).subtract(1, 'month').endOf('month')
          : moment().subtract(1, 'month').endOf('month');
        return [startDate, endDate];
      case 'This year':
        startDate = this.timezone ? moment().tz(this.timezone).startOf('year') : moment().startOf('year');
        endDate = this.timezone ? moment().tz(this.timezone).endOf('year') : moment().endOf('year');
        return [startDate, endDate];
      default:
        return [null, null];
    }
  }
}
