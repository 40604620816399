import { Pipe, PipeTransform } from '@angular/core';
import { Card } from '../model/job.model';

@Pipe({
  name: 'skateholdersInfo'
})
export class SkateholdersInfoPipe implements PipeTransform {
  transform(value: Card[], property?: string, search?: string): any {
    return value?.length
      ? `${value.find((val) => val[property] === search)?.nameLine1 || ''} ${
          value.find((val) => val[property] === search)?.nameLine2 || ''
        }`
      : value;
  }
}
