import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomerFeedback } from '../../../../model/gicop-notifications';
import { DateInputHelper } from '../../../../helpers/date-input.helper';
import { ConfirmBoxComponent } from '../../../../components/confirm-box/confirm-box.component';
import { UserService } from '../../../../services/user.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ToolbarService } from '../../../../services/toolbar.service';
import { MessagingService } from '../../../../components/messaging/messaging.service';
import * as moment from 'moment';
import { EIO_DATE_BE_FORMAT } from '../../../../consts/localization.const';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { smallModalConfig } from '../../../../consts/modal-config.const';
import { ModalConfigsHelper } from '../../../../helpers/modal-configs.helper';

@Component({
  selector: 'app-add-customer-feedback',
  templateUrl: './add-customer-feedback.component.html',
  styleUrls: ['./add-customer-feedback.component.scss']
})
export class AddCustomerFeedbackComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() customerFeedback: CustomerFeedback;
  @Output() refreshJobViewer: EventEmitter<any> = new EventEmitter();
  @Output() disableSendButton: EventEmitter<any> = new EventEmitter();
  customerFeedbackForm: UntypedFormGroup;
  private unsubscribe = new Subject<void>();

  constructor(
    private fb: UntypedFormBuilder,
    public dateInputHelper: DateInputHelper,
    private userService: UserService,
    private confirmBox: MatDialog,
    public toolbarService: ToolbarService,
    private message: MessagingService,
    private elRef: ElementRef,
    private modalConfigsHelper: ModalConfigsHelper
  ) {}

  get date() {
    return this.customerFeedbackForm.get('date');
  }
  get raisedBy() {
    return this.customerFeedbackForm.get('raisedBy');
  }
  get feedbackType() {
    return this.customerFeedbackForm.get('feedbackType');
  }
  get details() {
    return this.customerFeedbackForm.get('details');
  }
  get actionToResolve() {
    return this.customerFeedbackForm.get('actionToResolve');
  }

  ngOnInit(): void {
    this.customerFeedbackForm = this.fb.group({
      id: this.customerFeedback.id,
      date: [this.customerFeedback.date || '', [Validators.required]],
      feedbackType: [this.customerFeedback.feedbackType || 'POSITIVE'],
      raisedBy: [this.customerFeedback.raisedBy || '', [Validators.required]],
      details: [this.customerFeedback.details || '', [Validators.required]],
      actionToResolve: [this.customerFeedback.actionToResolve || '']
    });
    this.updateCustomerFeedbackForm();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  updateCustomerFeedbackForm() {
    this.date.valueChanges.pipe(takeUntil(this.unsubscribe), debounceTime(750), distinctUntilChanged()).subscribe({
      next: () => {
        if (this.date.invalid) {
          this.date.markAsTouched();
        }
        this.updateCustomerFeedback();
      }
    });

    this.feedbackType.valueChanges
      .pipe(takeUntil(this.unsubscribe), debounceTime(750), distinctUntilChanged())
      .subscribe(() => {
        this.updateCustomerFeedback();
      });

    this.raisedBy.valueChanges
      .pipe(takeUntil(this.unsubscribe), debounceTime(750), distinctUntilChanged())
      .subscribe(() => {
        if (this.raisedBy.invalid) {
          this.raisedBy.markAsTouched();
        }
        this.updateCustomerFeedback();
      });

    this.details.valueChanges
      .pipe(takeUntil(this.unsubscribe), debounceTime(750), distinctUntilChanged())
      .subscribe(() => {
        if (this.details.invalid) {
          this.details.markAsTouched();
        }
        this.updateCustomerFeedback();
      });

    this.actionToResolve.valueChanges
      .pipe(takeUntil(this.unsubscribe), debounceTime(750), distinctUntilChanged())
      .subscribe(() => {
        this.updateCustomerFeedback();
      });
  }

  confirmDeleteCustomerFeedback() {
    const data = {
      title: 'Delete Customer Feedback',
      alertMessage: 'This customer feedback will be deleted.',
      confirmButton: 'DELETE'
    };
    const dialogRef = this.confirmBox.open(
      ConfirmBoxComponent,
      this.modalConfigsHelper.buildMediumModalConfig(data, smallModalConfig)
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'DELETE') {
        this.deleteCustomerFeedback();
      }
    });
  }

  deleteCustomerFeedback() {
    this.toolbarService.setCloudState('SAVING');
    this.userService
      .deleteNotification(this.customerFeedback.jobId, 'customerFeedbacks', this.customerFeedback.id)
      .subscribe(
        () => this.toolbarService.setCloudState('RESTING'),
        () => {
          this.message.error('Customer feedback could not be deleted.');
          this.toolbarService.setCloudState('RESTING');
        },
        () => {
          this.message.confirm('Customer feedback Deleted.');
          this.refreshJobViewer.emit();
        }
      );
  }

  updateCustomerFeedback() {
    this.toolbarService.setCloudState('SAVING');
    const feedbackRequest: CustomerFeedback = this.customerFeedbackForm.getRawValue();
    feedbackRequest.date = this.date.value ? moment(this.date.value).format(EIO_DATE_BE_FORMAT) : null;
    this.userService.updateNotification(this.customerFeedback.jobId, 'customerFeedbacks', feedbackRequest).subscribe(
      () => this.toolbarService.setCloudState('RESTING'),
      () => {
        this.message.error('Feedback has not been saved');
        this.toolbarService.setCloudState('RESTING');
      },
      () => {
        this.refreshJobViewer.emit();
      }
    );
  }

  ngAfterViewInit() {
    this.elRef.nativeElement.scrollIntoView({
      behavior: 'smooth'
    });
  }
}
