import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PermissionHelper {
  checkPermission(user, privileges, router): boolean {
    if (user.privileges.includes(privileges)) {
      return true;
    } else if (!user.privileges.includes(privileges)) {
      if (user.privileges.includes('SYSADMIN')) {
        router.navigate(['/sysadmin']);
        return false;
      }
      router.navigate(['/dashboard']);
      return false;
    }
  }
}
