import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MessageComponent } from './message/message.component';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {
  constructor(private snackBar: MatSnackBar) {}

  error = (message: string) =>
    this.snackBar.openFromComponent(MessageComponent, {
      data: {
        message,
        icon: 'error'
      },
      duration: 5000,
      verticalPosition: 'top',
      panelClass: ['ERROR']
    });

  confirm = (message: string) =>
    this.snackBar.openFromComponent(MessageComponent, {
      data: {
        message,
        icon: 'check_circle'
      },
      duration: 5000,
      verticalPosition: 'top',
      panelClass: ['CONFIRM']
    });
}
