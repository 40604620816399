import { Component, Inject, OnInit } from '@angular/core';
import { JobHistory } from '../../../model/jobHistory.model';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { EscapeDialogHelper } from '../../../helpers/escape-dialog.helper';
import { UserService } from '../../../services/user.service';
import { MessagingService } from '../../../components/messaging/messaging.service';
import { CurrentUserService } from '../../../services/currentUser.service';

@Component({
  selector: 'app-job-history',
  templateUrl: './job-history.component.html',
  styleUrls: ['./job-history.component.scss']
})
export class JobHistoryComponent implements OnInit {
  jobHistory: JobHistory[] = [];
  jobHistoryColumns = ['timeStamp', 'action'];
  stringHighlight: string;
  timezone: string;

  constructor(
    public dialogRef: MatDialogRef<JobHistoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private escapeDialogRef: EscapeDialogHelper,
    private userService: UserService,
    private currentUserService: CurrentUserService,
    private message: MessagingService
  ) {}

  ngOnInit(): void {
    this.escapeDialogRef.escapeDialog(this.dialogRef);
    this.userService.getJobHistoryForJob(this.data.jobId).subscribe(
      (jobHistory) => (this.jobHistory = jobHistory),
      () => {
        this.message.error('Could not retrieve the job history');
      }
    );
    this.timezone = this.currentUserService?.timeZone;
  }
}
