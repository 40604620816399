<div class="guidance-container mt-20">
  <div class="guidance-access" *ngIf="fileDirectoryHandler && cancelAccessAuthorisation && !file">
    <div class="bold subtitle-2 no-result">
      EstImage was not given access to the {{ type | titlecase }} import folder.
    </div>
    <div class="body-1">
      <span class="link" (click)="givePermissionToFileAccess()">Click here</span> to give EstImage access to the quote
      import folder or you may click browse to find your {{ type | titlecase }}
    </div>
  </div>
  <div class="bold subtitle-2 no-result" *ngIf="fileDirectoryHandler && !rego && !xmlFiles?.length">
    There is no REGO set on the job. Please browse to find your {{ type | titlecase }}
  </div>
  <div
    class="bold subtitle-2 no-result"
    *ngIf="fileDirectoryHandler && rego && permissionOfFileWasGiven && noFile && !xmlFiles?.length">
    EstImage could not find {{ type === 'invoice' ? 'an' : 'a' }} {{ type | titlecase }} with rego {{ rego }}. Please
    browse to find your {{ type | titlecase }}
  </div>
  <div
    class="guidance-access subtitle-2"
    *ngIf="
      fileDirectoryHandler &&
      !(permissionOfFileWasGiven && permissionOfImagesWasGiven) &&
      !cancelAccessAuthorisation &&
      rego &&
      !file &&
      !fileDeleted &&
      !xmlFiles
    ">
    <div>Your browser may ask you to allow EstImage to view the files in these folders.</div>
    <div class="bold"><span class="view-files">Click "View Files" </span>to give EstImage access.</div>
  </div>
  <div class="guidance-information">
    <div *ngIf="!fileDirectoryHandler; else fileDirectories">
      <ng-container
        *ngIf="!fileDirectoryHandler"
        [ngTemplateOutlet]="guidanceInfo"
        [ngTemplateOutletContext]="{ message: 'Select ' + (type | titlecase) + ' folder', icon: 'folder_open' }">
      </ng-container>
    </div>
    <ng-template #fileDirectories>
      <div class="directories-name">
        <ng-container
          *ngIf="fileDirectoryHandler"
          [ngTemplateOutlet]="guidanceInfo"
          [ngTemplateOutletContext]="{
            message: (type | titlecase) + ' folder: ',
            folderName: fileDirectoryHandler?.name,
            icon: 'folder_open'
          }">
        </ng-container>
        <ng-container
          *ngIf="imagesDirectoryHandler"
          [ngTemplateOutlet]="guidanceInfo"
          [ngTemplateOutletContext]="{
            message: 'Image folder: ',
            folderName: imagesDirectoryHandler?.name,
            icon: 'crop_original'
          }">
        </ng-container>
      </div>
    </ng-template>
    <button
      class="raised-button-with-icon"
      mat-icon-button
      mat-raised-button
      color="accent"
      (click)="configureImportDirectories()"
      type="button">
      <mat-icon svgIcon="folder_edit"></mat-icon>
    </button>
  </div>
</div>

<ng-template #guidanceInfo let-message="message" let-icon="icon" let-folderName="folderName">
  <div class="information">
    <mat-icon>{{ icon }}</mat-icon>
    <div class="subtitle-2 message">
      {{ message }} <span class="bold">{{ folderName }}</span>
    </div>
  </div>
</ng-template>
