<div class="dialog-header" mat-dialog-title>
  <h6>{{ data.user ? 'Edit' : 'Add' }} Multi-Location Administrator</h6>
  <button aria-label="Close" mat-dialog-close mat-icon-button>
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-divider></mat-divider>

<form [formGroup]="form" (submit)="submitMlAdminForm()">
  <div mat-dialog-content class="overflow-auto custom-thin-scrollbar">
    <section>
      <article class="grid grid-cols-3 gap-20">
        <mat-form-field
          appearance="outline"
          color="accent"
          [ngClass]="{ 'pb-15': username.hasError('usernameDuplicate') }">
          <mat-label>Username</mat-label>
          <input formControlName="username" matInput (change)="removeDuplicateError()" />
          <mat-error *ngIf="username.hasError('required')"> Username is <strong>required</strong> </mat-error>
          <mat-error *ngIf="username.hasError('usernameDuplicate')">
            This username exists already in the system
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" color="accent">
          <mat-label>First Name</mat-label>
          <input formControlName="firstName" matInput />
          <mat-error *ngIf="firstName.hasError('required')"> First Name is <strong>required</strong> </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" color="accent">
          <mat-label>Last Name</mat-label>
          <input formControlName="lastName" matInput />
          <mat-error *ngIf="lastName.hasError('required')"> Last Name is <strong>required</strong> </mat-error>
        </mat-form-field>
      </article>
      <article class="grid grid-cols-3 gap-20">
        <mat-form-field appearance="outline" color="accent" class="col-span-2">
          <mat-label>Email</mat-label>
          <input formControlName="email" matInput />
          <mat-error *ngIf="email.invalid && !email.hasError('required')" class="mat-error">
            Please enter a valid email address
          </mat-error>
          <mat-error *ngIf="email.hasError('required')"> Email is <strong>required</strong> </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" color="accent">
          <mat-label>Phone Number</mat-label>
          <input formControlName="phone" matInput />
        </mat-form-field>
      </article>
    </section>

    <section>
      <app-mladmin-modal-repairer-sites
        [userRepairerSite]="this.data.user?.repairerSites"
        (repairerSiteSelected)="repairerSiteSelected = $event"></app-mladmin-modal-repairer-sites>
    </section>
  </div>

  <mat-dialog-actions>
    <button mat-raised-button mat-dialog-close color="accent">CANCEL</button>
    <button
      [disabled]="form.invalid || mlAdminService.loadingMlAdminUser()"
      color="primary"
      mat-raised-button
      type="submit">
      SAVE
    </button>
  </mat-dialog-actions>
</form>
