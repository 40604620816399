<div class="repair-progress-container">
  <div class="progress-buckets">
    <div class="bucket">
      <div class="bucket-title">
        <div class="title-1">Scheduled</div>
        <mat-divider></mat-divider>
      </div>
      <app-job-card
        *ngFor="let job of buckets.REPAIR_BOOKED"
        [repairerSite]="repairerSite"
        [job]="job"
        (updateBuckets)="updateBuckets.emit($event)"
        [bucket]="'REPAIR_BOOKED'">
      </app-job-card>
    </div>
    <div class="bucket">
      <div class="bucket-title">
        <div class="title-1">Vehicle In</div>
        <mat-divider></mat-divider>
      </div>
      <app-job-card
        *ngFor="let job of buckets.VEHICLE_IN"
        [job]="job"
        [repairerSite]="repairerSite"
        (updateBuckets)="updateBuckets.emit($event)"
        [bucket]="'VEHICLE_IN'">
      </app-job-card>
    </div>
    <div class="bucket">
      <div class="bucket-title">
        <div class="title-1">Repair Started</div>
        <mat-divider></mat-divider>
      </div>
      <app-job-card
        *ngFor="let job of buckets.REPAIR_STARTED"
        [repairerSite]="repairerSite"
        [job]="job"
        (updateBuckets)="updateBuckets.emit($event)"
        [bucket]="'REPAIR_STARTED'">
      </app-job-card>
    </div>
    <div class="bucket">
      <div class="bucket-title">
        <div class="title-1">Repair Completed</div>
        <mat-divider></mat-divider>
      </div>
      <app-job-card
        *ngFor="let job of buckets.REPAIR_COMPLETED"
        [repairerSite]="repairerSite"
        [job]="job"
        (updateBuckets)="updateBuckets.emit($event)"
        [bucket]="'REPAIR_COMPLETED'">
      </app-job-card>
    </div>
    <div class="bucket">
      <div class="bucket-title">
        <div class="title-1">Vehicle Out</div>
        <mat-divider></mat-divider>
      </div>
      <app-job-card
        *ngFor="let job of buckets.VEHICLE_OUT"
        [repairerSite]="repairerSite"
        [job]="job"
        (updateBuckets)="updateBuckets.emit($event)"
        [bucket]="'VEHICLE_OUT'">
      </app-job-card>
    </div>
  </div>
</div>
