import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'humanize',
  standalone: true
})
export class HumanizePipe implements PipeTransform {
  transform(value: string) {
    return value?.replace(/_/g, ' ');
  }
}
