<div class="header-container">
  <h3>Multi-Location Administrators</h3>
  <div class="header-container-actions">
    <button mat-raised-button color="accent" (click)="openMlAdminModal()">ADD MULTI-LOCATION ADMIN</button>
    <mat-divider class="header-container-actions-divider" [vertical]="true"></mat-divider>
    <button mat-icon-button (click)="expandCurtain = !expandCurtain">
      <mat-icon>{{ expandCurtain ? 'expand_less' : 'expand_more' }}</mat-icon>
    </button>
  </div>
</div>
<mat-divider></mat-divider>
<div [class.hidden]="!expandCurtain" class="p-20">
  <div class="w-full flex justify-center items-center p-20" *ngIf="mlAdminService.loadingMlAdminList()">
    <mat-spinner diameter="20" color="primary" class="mr-10"></mat-spinner>
    <span class="text-primary-900">Loading...</span>
  </div>
  <table [dataSource]="dataSource" mat-table matSort matSortActive="username">
    <ng-container *ngFor="let row of columnInfos" [matColumnDef]="row?.matColumnDef">
      <th
        mat-header-cell
        mat-sort-header
        *matHeaderCellDef
        [disabled]="row?.matColumnDef === 'kebab' || row?.matColumnDef === 'status'">
        {{ row?.columnTitle }}
      </th>
      <td mat-cell *matCellDef="let item">
        <span *ngIf="!['status', 'kebab', 'repairerSites'].includes(row?.matColumnDef)">
          {{ item[row?.property] }}
        </span>

        <div *ngIf="row?.matColumnDef === 'repairerSites'">
          {{ getRepairerSiteNames(item) }}
        </div>

        <app-status-cell-info
          *ngIf="row?.matColumnDef === 'status'"
          [data]="item"
          [currentUserTimezone]="currentUserService.timeZone">
        </app-status-cell-info>

        <mat-panel-description class="kebab-panel" *ngIf="row?.matColumnDef === 'kebab'">
          <div class="secondary-icon flex justify-end">
            <button mat-icon-button [matMenuTriggerFor]="revokeTokenMenu" (click)="$event.stopPropagation()">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #revokeTokenMenu="matMenu" class="kebab-menu">
              <ng-container *ngFor="let button of kebabButtons">
                <button
                  *ngIf="item.status !== button.status && item.isActivated === button.activatedToDisplay"
                  mat-menu-item
                  (click)="onClickKebabButton(button.status, item.username)">
                  {{ button.label }}
                </button>
              </ng-container>
            </mat-menu>
          </div>
        </mat-panel-description>
      </td>
    </ng-container>

    <tr *matHeaderRowDef="columnHeaders" mat-header-row></tr>
    <tr *matRowDef="let row; columns: columnHeaders" mat-row (click)="openMlAdminModal(row)"></tr>
  </table>
</div>
