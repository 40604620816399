import { Attribute, Directive, HostBinding } from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[matInput]'
})
export class BrowserAutoFillDisabledDirective {
  @HostBinding('attr.autocomplete') auto;
  constructor(@Attribute('autocomplete') autocomplete: string) {
    this.auto = autocomplete || 'off';
  }
}
