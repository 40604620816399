import { inject, Injectable } from '@angular/core';
import { JobService } from '../services/job.service';

@Injectable({
  providedIn: 'root'
})
export class CleanActiveJobHelper {
  private jobService = inject(JobService);

  clearLocalStorageFromActiveJob() {
    this.jobService.activeJobId = null;
    localStorage.removeItem('activeJobId');
    localStorage.removeItem('searchCriteria');
    localStorage.removeItem('previousUrl');
  }
}
