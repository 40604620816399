<div *ngIf="document">
  <mat-expansion-panel [expanded]="isSentToInsurerTray" class="curtains" #panel>
    <mat-expansion-panel-header [collapsedHeight]="'70px'" [expandedHeight]="'70px'">
      <mat-panel-title class="invoice-title">
        <div>
          <div *ngIf="document.date" class="received-date">
            {{ document.date | dateepoch: timezone }} {{ document.date | time: timezone }}
          </div>
          <div class="invoice-number">
            <h5><span class="subtitle-1">Invoice</span> {{ invoice.invoiceNumber }}</h5>
            <div
              (click)="$event.stopPropagation(); tooltipApprovedDate.toggle()"
              *ngIf="invoice.status === 'APPROVED'"
              class="invoice"
              [appHighlightStatus]="invoice.status">
              APPROVED
              <div
                #tooltipApprovedDate="matTooltip"
                matTooltip="{{ invoice.acceptedOrRejectedDateTime | dateepoch: timezone }} {{
                  invoice.acceptedOrRejectedDateTime | time: timezone
                }}"
                matTooltipClass="timestamp-tooltip"></div>
            </div>
            <div
              (click)="$event.stopPropagation(); tooltipAdjustedDate.toggle()"
              *ngIf="invoice.status === 'ADJUSTED'"
              class="invoice"
              [appHighlightStatus]="invoice.status">
              ADJUSTED
              <div
                #tooltipAdjustedDate="matTooltip"
                matTooltip="{{ invoice.acceptedOrRejectedDateTime | dateepoch: timezone }} {{
                  invoice.acceptedOrRejectedDateTime | time: timezone
                }}"
                matTooltipClass="timestamp-tooltip"></div>
            </div>
            <div
              (click)="$event.stopPropagation(); tooltipRejectedDate.toggle()"
              *ngIf="invoice.status === 'REJECTED'"
              class="invoice"
              [appHighlightStatus]="invoice.status">
              REJECTED
              <div
                #tooltipRejectedDate="matTooltip"
                matTooltip="{{ invoice.acceptedOrRejectedDateTime | dateepoch: timezone }} {{
                  invoice.acceptedOrRejectedDateTime | time: timezone
                }}"
                matTooltipClass="timestamp-tooltip"></div>
            </div>
            <div *ngIf="invoice.status === 'PENDING_FOR_REVIEW'" class="invoice" [appHighlightStatus]="invoice.status">
              PENDING
            </div>
          </div>
        </div>
        <div>
          <div class="body-2">Invoiced Parts</div>
          <div class="data-values subtitle-2">$ {{ totalInvoicedParts | number: '1.2-2' }}</div>
        </div>
        <div>
          <div class="body-2">Invoiced Labour</div>
          <div class="data-values subtitle-2">$ {{ totalInvoicedLabour | number: '1.2-2' }}</div>
        </div>
        <div>
          <div class="body-2">Invoiced Sublet</div>
          <div class="data-values subtitle-2">$ {{ totalInvoicedSublet | number: '1.2-2' }}</div>
        </div>
        <div>
          <div class="body-2">Total Invoiced</div>
          <div class="data-values subtitle-2">$ {{ invoicedTotal | number: '1.2-2' }}</div>
        </div>
      </mat-panel-title>
      <mat-panel-description class="kebab-panel">
        <div *ngIf="invoice.status !== 'CREATED'" class="secondary-icon">
          <a *ngIf="!loadingPDF" (click)="checkIfFileExists(); $event.stopPropagation()" rel="noopener">
            <button mat-icon-button>
              <mat-icon>picture_as_pdf_24px</mat-icon>
            </button>
          </a>
          <div *ngIf="loadingPDF" class="section-container-spinner">
            <mat-spinner color="primary" mode="indeterminate" [diameter]="24"></mat-spinner>
          </div>
        </div>
        <div *ngIf="invoice.status === 'PENDING_FOR_REVIEW'" class="secondary-icon">
          <button
            [matMenuTriggerData]="{ invoiceId: invoice?.id }"
            [matMenuTriggerFor]="kebabMenu"
            (click)="$event.stopPropagation()"
            mat-icon-button>
            <mat-icon>more_vert_32px</mat-icon>
          </button>
        </div>
        <div class="secondary-icon">
          <button
            (click)="$event.stopPropagation(); confirmDeleteInvoice(invoice.id, invoice.jobId)"
            *ngIf="!document.submitted && invoice.status === 'CREATED'"
            mat-icon-button>
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <mat-divider />
    <form [formGroup]="invoiceForm">
      <div class="invoice-fields-grid">
        <mat-form-field appearance="outline" class="primary-icon" color="accent">
          <mat-label>Invoice Number</mat-label>
          <input formControlName="invoiceNumber" matInput required />
          <mat-error *ngIf="invoiceNumber.hasError('required')">
            Invoice Number is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="invoiceNumber.hasError('duplicateInvoice')">
            This invoice number is already associated with this job
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="two-columns" color="accent">
          <mat-label>Prepared By</mat-label>
          <input (change)="update('preparedBy')" formControlName="preparedBy" matInput />
        </mat-form-field>
        <ng-container
          *ngIf="
            invoice.status !== 'CREATED' && invoice.status !== 'PENDING_FOR_REVIEW' && invoice.costControllerComment
          ">
          <div class="three-columns">
            <span class="body-2">Cost controller Comment</span>
            <div class="cost-control-comment subtitle-2">{{ invoice.costControllerComment }}</div>
          </div>
        </ng-container>
      </div>
      <div class="three-columns invoice-section" *ngIf="usingFinancingCompany() ||
      (!['NOT_USING_FLIP_PAY'].includes(invoice.estimagePayPaymentRequestStatus) && !(totalPayable > invoice?.estimagePayMaxAmount && document.submitted))">
        <h4>Invoice</h4>
        <mat-divider />
      </div>
      <div class="flex flex-col justify-center items-center">
        <div class="grid gap-20 w-[1200px]" [ngClass]="document.submitted ? 'place-items-center' : 'grid-cols-2 my-10'">
          <infos-card
            *ngIf="!document.submitted || (['NOT_USING_FLIP_PAY'].includes(invoice.estimagePayPaymentRequestStatus) && !usingFinancingCompany())"
            class="w-[580px]" [title]="bankingDetailsBanner().repairerName">
            <div card-header-content>
              <div
                class="flex text-[10px] text-boulder"
                *ngIf="bankingDetailsBanner()?.companyInformationChangeDateTime > 0">
                <mat-icon class="text-[14px]">history</mat-icon>
                <div>
                  {{ bankingDetailsBanner()?.companyInformationChangeDateTime | dateepoch: timezone }}
                  {{ bankingDetailsBanner()?.companyInformationChangeDateTime | time: timezone }}
                </div>
              </div>
            </div>
            <div class="grid gap-10" card-body-content>
              <div
                class="grid grid-cols-4 gap-5"
                *ngIf="
                  bankingDetailsBanner().bsb ||
                    bankingDetailsBanner().accountName ||
                    bankingDetailsBanner().accountNumber;
                  else noBankingDetails
                ">
                <ng-template
                  [ngTemplateOutlet]="infosContent"
                  [ngTemplateOutletContext]="{
                    field: invoice.repairerCountry === 'Australia' ? 'ABN' : 'NZBN',
                    value: bankingDetailsBanner().repairerAbn
                  }" />
                <ng-template
                  [ngTemplateOutlet]="infosContent"
                  [ngTemplateOutletContext]="{ field: 'BSB', value: bankingDetailsBanner().bsb }" />
                <ng-template
                  [ngTemplateOutlet]="infosContent"
                  [ngTemplateOutletContext]="{ field: 'Account Name', value: bankingDetailsBanner().accountName }" />
                <ng-template
                  [ngTemplateOutlet]="infosContent"
                  [ngTemplateOutletContext]="{ field: 'Account No', value: bankingDetailsBanner().accountNumber }" />
              </div>
              <mat-tab-group animationDuration="0ms" class="contact-info" color="primary">
                <mat-tab *ngIf="invoice?.repairerAddress">
                  <ng-template mat-tab-label>
                    <mat-icon>pin_drop</mat-icon>
                  </ng-template>
                  <div class="contacts body-1"><span>Address</span> {{ invoice.repairerAddress }}</div>
                </mat-tab>

                <mat-tab *ngIf="invoice?.repairerEmail">
                  <ng-template mat-tab-label>
                    <mat-icon>email</mat-icon>
                  </ng-template>
                  <div class="contacts body-1"><span>Email</span> {{ invoice.repairerEmail }}</div>
                </mat-tab>

                <mat-tab *ngIf="invoice?.repairerPhone">
                  <ng-template mat-tab-label>
                    <mat-icon>phone</mat-icon>
                  </ng-template>
                  <div class="contacts body-1"><span>Phone</span> {{ invoice.repairerPhone }}</div>
                </mat-tab>
              </mat-tab-group>
              <ng-template #noBankingDetails>
                <div class="flex justify-between items-center">
                  <button
                    (click)="addBankingDetails()"
                    *ngIf="
                      !bankingDetailsBanner().bsb &&
                      (currentUser.privileges.includes('SYSADMIN') || currentUser.privileges.includes('REPADMIN'))
                    "
                    mat-raised-button
                    color="accent">
                    ADD BANKING DETAILS
                  </button>
                  <div *ngIf="!usingFinancingCompany()">
                    <mat-checkbox
                      #bankingCheckbox
                      color="primary"
                      (change)="setPaymentByCheque(bankingCheckbox.checked)"
                      *ngIf="(!document.submitted && !invoice.bsb) || (document.submitted && invoice.paymentByCheque)"
                      [checked]="paymentByCheque"
                      [disabled]="document.submitted">
                      Request payment by cheque
                    </mat-checkbox>
                  </div>
                </div>
                <infos-guidance
                  *ngIf="!bankingDetailsBanner().bsb && !currentUser.privileges.length"
                  [guidanceTemplate]="noBankingGuidance" />
              </ng-template>
            </div>
          </infos-card>
          <div class="w-full grid grid-rows-[1fr_auto]" *ngIf="!document.submitted">
            <infos-guidance
              class="flex justify-center"
              *ngIf="invoice.isEligibleToUsePreviousCompanyInformation && invoice.bsb"
              [guidanceTemplate]="guidance" />
            <div
              [ngClass]="sysAdminService.estimagePayEnabled() ? (
                invoice.isEligibleToUsePreviousCompanyInformation && invoice.bsb
                  ? 'flex justify-between'
                  : 'inline-grid gap-20 h-1/2') : 'block'
              ">
              <button *ngIf="sysAdminService.estimagePayEnabled()"
                class="custom-mat-button w-[280px]"
                (click)="useEstimagePay()"
                [matTooltipDisabled]="invoice.estimagePayAllowed && totalPayable <= invoice.estimagePayMaxAmount"
                [disabled]="
                  !invoice.estimagePayAllowed ||
                  totalPayable > invoice.estimagePayMaxAmount ||
                  !['NOT_USING_FLIP_PAY'].includes(invoice.estimagePayPaymentRequestStatus) ||
                  !!selectedFinancingCompany()
                "
                [matTooltip]="
                  !invoice.estimagePayAllowed
                    ? !(currentUser?.privileges && currentUser?.privileges?.includes('REPADMIN'))
                      ? 'Ask your administrator about applying for EstImage Pay'
                      : 'Apply to use EstImage Pay in your Administration page'
                    : 'The total payable amount is greater than the maximum amount supported by EstImage Pay (' +
                      (invoice.estimagePayMaxAmount | number: '1.2-2') +
                      '$)'
                "
                mat-raised-button
                color="accent">
                <mat-icon class="custom-mat-icon mat-icon-sm mr-[7px]" svgIcon="estimage_pay" />
                <span>USE ESTIMAGE PAY</span>
              </button>
              <button
                class="custom-mat-button w-[280px]"
                (click)="useFinancingCompany()"
                [disabled]="
                  usingFinancingCompany() || !['NOT_USING_FLIP_PAY'].includes(invoice.estimagePayPaymentRequestStatus)
                "
                mat-raised-button>
                USE FINANCE COMPANY
              </button>
            </div>
          </div>
        </div>
        <div class="flex flex-col gap-20 my-10 w-[1200px] place-items-center">
          <estimage-pay-guidance
            *ngIf="!['NOT_USING_FLIP_PAY'].includes(invoice.estimagePayPaymentRequestStatus) && !(totalPayable > invoice?.estimagePayMaxAmount && document.submitted)"
            [invoice]="invoice"
            [totalPayable]="totalPayable"
            [submitted]="document.submitted"
            (useEstimagePay)="useEstimagePay()" />

          <finance-company-guidance
            *ngIf="usingFinancingCompany()"
            [invoice]="invoice"
            [submitted]="document.submitted"
            (useFinancingCompany)="useFinancingCompany()"
            (removeFinancingCompany)="removeFinancingCompany()" />
        </div>
      </div>

      <mat-divider />
      <h4 class="overview-titles">Overview</h4>
      <div class="overview-grid">
        <div>
          <div class="body-2">Labour</div>
          <div class="overview-amounts body-1">
            $
            <div class="section-amount subtitle-2">{{ totalInvoicedLabour | number: '1.2-2' }}</div>
            <ng-container
              [ngTemplateOutletContext]="{ assessedAmount: totalInvoicedLabour, invoicedAmount: totalAssessedLabour }"
              [ngTemplateOutlet]="overviewTotals"></ng-container>
          </div>
        </div>
        <div>
          <div class="body-2">Parts</div>
          <div class="overview-amounts body-1">
            $
            <div class="section-amount subtitle-2">{{ totalInvoicedParts | number: '1.2-2' }}</div>
            <ng-container
              [ngTemplateOutletContext]="{ assessedAmount: totalInvoicedParts, invoicedAmount: totalAssessedParts }"
              [ngTemplateOutlet]="overviewTotals"></ng-container>
          </div>
        </div>
        <div>
          <div class="body-2">Sublet</div>
          <div class="overview-amounts body-1">
            $
            <div class="section-amount subtitle-2">{{ totalInvoicedSublet | number: '1.2-2' }}</div>
            <ng-container
              [ngTemplateOutletContext]="{ assessedAmount: totalInvoicedSublet, invoicedAmount: totalAssessedSublet }"
              [ngTemplateOutlet]="overviewTotals"></ng-container>
          </div>
        </div>
        <div>
          <div class="body-2 black-high">Subtotal</div>
          <div class="overview-amounts body-1">
            $
            <div class="subtotal-amount subtitle-2">{{ invoicedSubtotal | number: '1.2-2' }}</div>
          </div>
        </div>
        <div>
          <div class="body-2 black-high">GST</div>
          <div class="overview-amounts body-1">
            $
            <div class="subtotal-amount subtitle-2">{{ invoicedGST | number: '1.2-2' }}</div>
          </div>
        </div>
        <div>
          <div class="body-2 black-high">Total</div>
          <div class="overview-amounts body-1">
            $
            <div class="total-amount subtitle-2">{{ invoicedTotal | number: '1.2-2' }}</div>
            <ng-container
              [ngTemplateOutletContext]="{ assessedAmount: invoicedTotal, invoicedAmount: assessedTotal }"
              [ngTemplateOutlet]="overviewTotals"></ng-container>
          </div>
        </div>
      </div>
      <ng-container *ngIf="invoice.status === 'ADJUSTED'">
        <h4 class="overview-titles">Approved</h4>
        <div class="overview-grid">
          <div>
            <div class="body-2">Labour</div>
            <div class="overview-amounts body-1">
              $
              <div class="section-amount subtitle-2">{{ totalApprovedLabour | number: '1.2-2' }}</div>
              <ng-container
                [ngTemplateOutletContext]="{ assessedAmount: totalInvoicedLabour, invoicedAmount: totalApprovedLabour }"
                [ngTemplateOutlet]="overviewTotals"></ng-container>
            </div>
          </div>
          <div>
            <div class="body-2">Parts</div>
            <div class="overview-amounts body-1">
              $
              <div class="section-amount subtitle-2">{{ totalApprovedParts | number: '1.2-2' }}</div>
              <ng-container
                [ngTemplateOutletContext]="{ assessedAmount: totalInvoicedParts, invoicedAmount: totalApprovedParts }"
                [ngTemplateOutlet]="overviewTotals"></ng-container>
            </div>
          </div>
          <div>
            <div class="body-2">Sublet</div>
            <div class="overview-amounts body-1">
              $
              <div class="section-amount subtitle-2">{{ totalApprovedSublet | number: '1.2-2' }}</div>
              <ng-container
                [ngTemplateOutletContext]="{ assessedAmount: totalInvoicedSublet, invoicedAmount: totalApprovedSublet }"
                [ngTemplateOutlet]="overviewTotals"></ng-container>
            </div>
          </div>
          <div>
            <div class="body-2 black-high">Subtotal</div>
            <div class="overview-amounts body-1">
              $
              <div class="subtotal-amount subtitle-2">{{ approvedSubtotal | number: '1.2-2' }}</div>
            </div>
          </div>
          <div>
            <div class="body-2 black-high">GST</div>
            <div class="overview-amounts body-1">
              $
              <div class="subtotal-amount subtitle-2">{{ approvedGST | number: '1.2-2' }}</div>
            </div>
          </div>
          <div>
            <div class="body-2 black-high">Total</div>
            <div class="overview-amounts body-1">
              $
              <div class="total-amount subtitle-2">{{ approvedTotal | number: '1.2-2' }}</div>
              <ng-container
                [ngTemplateOutletContext]="{ assessedAmount: invoicedTotal, invoicedAmount: approvedTotal }"
                [ngTemplateOutlet]="overviewTotals"></ng-container>
            </div>
          </div>
        </div>
      </ng-container>
      <mat-divider />
      <div class="overview-grid" [ngClass]="invoice.isFirstInvoice ? 'overview-payable-line' : 'only-payable-total'">
        <ng-container *ngIf="invoice.isFirstInvoice">
          <div>
            <div *ngIf="lastExcessChangeTimestamp" class="info-guidance subtitle-2">
              <mat-icon>info_outlined</mat-icon>
              <span
                >Please be advised that the excess value changed on
                {{ lastExcessChangeTimestamp | dateepoch: timezone }}
                {{ lastExcessChangeTimestamp | time: timezone }}</span
              >
            </div>
          </div>
          <div>
            <div class="body-2">Less Excess</div>
            <div class="overview-amounts body-1">
              <ng-container *ngIf="!invoice.excessType">$</ng-container>
              <div class="subtotal-amount">
                {{ !invoice.excessType ? (invoice.excess | number: '1.2-2') : invoice.excessType }}
              </div>
            </div>
          </div>
          <div class="owner-contribution">
            <mat-form-field appearance="outline" color="accent">
              <mat-label>Owner Contribution</mat-label>
              <input (blur)="formatOwnerContribution()" formControlName="ownerContribution" matInput />
              <mat-icon *ngIf="invoiceForm.controls.ownerContribution.value" matSuffix>attach_money_24px</mat-icon>
              <mat-error *ngIf="!invoiceForm.controls.ownerContribution.valid"> Enter number</mat-error>
            </mat-form-field>
          </div>
        </ng-container>
        <div *ngIf="invoice.status !== 'REJECTED'">
          <div class="body-2 black-high">Total Payable</div>
          <div class="overview-amounts body-1">
            $
            <div class="total-amount">
              <div class="title-1">{{ totalPayable | number: '1.2-2' }}</div>
            </div>
          </div>
        </div>
      </div>

      <mat-form-field class="textarea-fields" appearance="outline" color="accent">
        <mat-label>Repairer Comments</mat-label>
        <textarea
          matInput
          formControlName="repairerComment"
          cdkAutosizeMinRows="3"
          maxlength="65535"
          (focusout)="update('repairerComment')"
          cdkTextareaAutosize="true"></textarea>
      </mat-form-field>

      <div *ngIf="invoice.labour?.length" class="invoice-table" formArrayName="labourItems">
        <h4 class="section-header">Labour</h4>
        <table [dataSource]="labourDataSource" class="table-style" mat-table>
          <ng-container matColumnDef="description">
            <th *matHeaderCellDef class="body-2" mat-header-cell>Description</th>
            <td *matCellDef="let item" class="body-1" mat-cell>
              <div class="flex items-center" [class.ml-30]="atLeastOneComment && !item?.costControlComment">
                <ng-container
                  *ngTemplateOutlet="comment; context: { costControlComment: item?.costControlComment }"></ng-container>
                <ng-container [ngSwitch]="item.labourType">
                  <span *ngSwitchCase="'RR'">Remove & Replace</span>
                  <span *ngSwitchCase="'REP'">Repair</span>
                  <span *ngSwitchCase="'PAINT'">Paint</span>
                  <span *ngSwitchCase="'MEC'">Mechanical</span>
                  <span *ngSwitchCase="'MISC'">Miscellaneous</span>
                </ng-container>
                <span *ngIf="item.groupName" class="labour-group">{{ item.groupName }}</span>
              </div>
            </td>
            <td *matFooterCellDef class="footer-label body-1" mat-footer-cell>Labour Total</td>
          </ng-container>
          <ng-container matColumnDef="assessedAmount">
            <th *matHeaderCellDef class="body-2" mat-header-cell>Assessed</th>
            <td *matCellDef="let item" class="body-1" mat-cell>
              <ng-container *ngIf="item.assessedAmount || item.assessedAmount === 0">
                <div class="amount-cell">
                  <div class="sign-cell">$</div>
                  {{ item.assessedAmount | number: '1.2-2' }}
                </div>
              </ng-container>
            </td>
            <td *matFooterCellDef mat-footer-cell class="body-1">
              <div class="amount-cell">
                <div class="sign-cell">$</div>
                {{ totalAssessedLabour | number: '1.2-2' }}
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="invoicedAmount">
            <th *matHeaderCellDef class="padding-right-10 body-2" mat-header-cell>Invoiced</th>
            <td *matCellDef="let item" class="body-1" mat-cell>
              <ng-container *ngIf="item.invoicedAmount || item.invoicedAmount === 0">
                <div class="assess-indicator">
                  <mat-form-field
                    [class.error-row]="!(labourItems | getControl: item.id).valid"
                    appearance="outline"
                    class="column-field"
                    color="accent">
                    <input
                      (change)="updateLabour(item.id)"
                      [formControl]="labourItems | getControl: item.id"
                      class="body-1"
                      matInput />
                    <div class="prefix body-1" matPrefix>$</div>
                    <mat-error>Enter number</mat-error>
                  </mat-form-field>
                  <ng-container
                    [ngTemplateOutletContext]="{
                      assessedAmount: item.assessedAmount,
                      invoicedAmount: item.invoicedAmount
                    }"
                    [ngTemplateOutlet]="differenceIndicators"></ng-container>
                </div>
              </ng-container>
            </td>
            <td *matFooterCellDef class="padding-right-12 body-1" mat-footer-cell>
              <div class="amount-cell">
                <div class="sign-cell">$</div>
                {{ totalInvoicedLabour | number: '1.2-2' }}
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="approvedAmount">
            <th *matHeaderCellDef class="body-2" mat-header-cell>Approved</th>
            <td *matCellDef="let item" class="body-1" mat-cell>
              <div [class.indicator-approved]="item.approvedAmount" class="assess-indicator">
                <ng-container *ngIf="item.approvedAmount || item.approvedAmount === 0">
                  <div class="amount-cell">
                    <div class="sign-cell">$</div>
                    {{ item.approvedAmount | number: '1.2-2' }}
                  </div>
                  <ng-container
                    [ngTemplateOutletContext]="{
                      approvedAmount: item.approvedAmount,
                      invoicedAmount: item.invoicedAmount
                    }"
                    [ngTemplateOutlet]="differenceIndicatorsApproved"></ng-container>
                </ng-container>
              </div>
            </td>
            <td *matFooterCellDef mat-footer-cell class="body-1">
              <div *ngIf="totalApprovedLabour" class="amount-cell">
                <div class="sign-cell">$</div>
                {{ totalApprovedLabour | number: '1.2-2' }}
              </div>
            </td>
          </ng-container>
          <tr *matHeaderRowDef="labourColumnHeaders" mat-header-row></tr>
          <tr
            *matRowDef="let item; let even = even; columns: labourColumnHeaders"
            [ngClass]="{ 'gray-row': even }"
            mat-row></tr>
          <tr *matFooterRowDef="labourColumnHeaders" mat-footer-row></tr>

          <!-- Group header -->
          <ng-container matColumnDef="groupHeader"
            >totalApprovedLabour
            <td *matCellDef="let groupBy" colspan="999" class="body-1" mat-cell>
              <div class="subtitle-2">
                {{ groupBy.quoteVersion }} <span class="subtitle-2">{{ groupBy.quoteNumber }}</span>
              </div>
            </td>
          </ng-container>

          <tr *matRowDef="let row; columns: ['groupHeader']; when: isGroup" class="group-row" mat-row></tr>
        </table>
      </div>
      <div *ngIf="invoice.parts?.length" class="invoice-table" formArrayName="partItems">
        <h4 class="section-header">Parts</h4>
        <table [dataSource]="partDataSource" class="table-style" mat-table>
          <ng-container *ngIf="!isLargeScreen">
            <ng-container matColumnDef="partDescriptionNumber">
              <th *matHeaderCellDef class="body-2" mat-header-cell>
                <div>Description / Part Number</div>
              </th>
              <td *matCellDef="let item" class="body-1" mat-cell>
                <div class="flex items-center" [class.ml-30]="atLeastOneComment && !item?.costControlComment">
                  <ng-container
                    *ngTemplateOutlet="
                      comment;
                      context: { costControlComment: item?.costControlComment }
                    "></ng-container>
                  <div>{{ item.description }}</div>
                  <div>{{ item.partNumber }}</div>
                </div>
              </td>
              <td *matFooterCellDef mat-footer-cell class="body-1"></td>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="isLargeScreen">
            <ng-container matColumnDef="partDescription">
              <th *matHeaderCellDef class="body-2" mat-header-cell>Description</th>
              <td *matCellDef="let item" class="body-1" mat-cell>
                <div class="flex items-center" [class.ml-30]="atLeastOneComment && !item?.costControlComment">
                  <ng-container
                    *ngTemplateOutlet="
                      comment;
                      context: { costControlComment: item?.costControlComment }
                    "></ng-container>
                  <div>{{ item.description }}</div>
                </div>
              </td>
              <td *matFooterCellDef mat-footer-cell class="body-1"></td>
            </ng-container>
            <ng-container matColumnDef="partNumber">
              <th *matHeaderCellDef class="body-2" mat-header-cell>Part Number</th>
              <td *matCellDef="let item" class="body-1" mat-cell>{{ item.partNumber }}</td>
              <td *matFooterCellDef mat-footer-cell class="body-1"></td>
            </ng-container>
          </ng-container>
          <ng-container matColumnDef="partSource">
            <th *matHeaderCellDef class="body-2" mat-header-cell>Part Source</th>
            <td *matCellDef="let item" class="body-1" mat-cell>{{ item.partSource }}</td>
            <td *matFooterCellDef mat-footer-cell class="body-1"></td>
          </ng-container>
          <ng-container matColumnDef="assessorNote">
            <th *matHeaderCellDef class="body-2" mat-header-cell>Assessor Note</th>
            <td *matCellDef="let item" mat-cell>
              <div>{{ item.assessorNote }}</div>
            </td>
            <td *matFooterCellDef class="footer-label body-1" mat-footer-cell>Parts Total</td>
          </ng-container>
          <ng-container matColumnDef="assessedAmount">
            <th *matHeaderCellDef class="body-2" mat-header-cell>Assessed</th>
            <td *matCellDef="let item" class="body-1" mat-cell>
              <ng-container *ngIf="item.assessedAmount || item.assessedAmount === 0">
                <div class="amount-cell">
                  <div class="sign-cell">$</div>
                  {{ item.assessedAmount | number: '1.2-2' }}
                </div>
              </ng-container>
            </td>
            <td *matFooterCellDef mat-footer-cell class="body-1">
              <div class="amount-cell">
                <div class="sign-cell">$</div>
                {{ totalAssessedParts | number: '1.2-2' }}
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="invoicedAmount">
            <th *matHeaderCellDef class="padding-right-10 body-2" mat-header-cell>Invoiced</th>
            <td *matCellDef="let item" class="body-1" mat-cell>
              <ng-container *ngIf="item.invoicedAmount || item.invoicedAmount === 0">
                <div class="assess-indicator">
                  <mat-form-field
                    [class.error-row]="!(partItems | getControl: item.id).valid"
                    appearance="outline"
                    class="column-field"
                    color="accent">
                    <input
                      (change)="updateParts(item.id)"
                      [formControl]="partItems | getControl: item.id"
                      class="body-1"
                      matInput />
                    <div class="prefix body-1" matPrefix>$</div>
                    <mat-error>Enter number</mat-error>
                  </mat-form-field>
                  <ng-container
                    [ngTemplateOutletContext]="{
                      assessedAmount: item.assessedAmount,
                      invoicedAmount: item.invoicedAmount
                    }"
                    [ngTemplateOutlet]="differenceIndicators"></ng-container>
                </div>
              </ng-container>
            </td>
            <td *matFooterCellDef class="padding-right-12 body-1" mat-footer-cell>
              <div class="amount-cell">
                <div class="sign-cell">$</div>
                {{ totalInvoicedParts | number: '1.2-2' }}
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="approvedAmount">
            <th *matHeaderCellDef class="body-2" mat-header-cell>Approved</th>
            <td *matCellDef="let item" class="body-1" mat-cell>
              <div [class.indicator-approved]="item.approvedAmount" class="assess-indicator">
                <ng-container *ngIf="item.approvedAmount || item.approvedAmount === 0">
                  <div class="amount-cell">
                    <div class="sign-cell">$</div>
                    {{ item.approvedAmount | number: '1.2-2' }}
                  </div>
                  <ng-container
                    [ngTemplateOutletContext]="{
                      approvedAmount: item.approvedAmount,
                      invoicedAmount: item.invoicedAmount
                    }"
                    [ngTemplateOutlet]="differenceIndicatorsApproved"></ng-container>
                </ng-container>
              </div>
            </td>
            <td *matFooterCellDef mat-footer-cell class="body-1">
              <div *ngIf="totalApprovedParts" class="amount-cell">
                <div class="sign-cell">$</div>
                {{ totalApprovedParts | number: '1.2-2' }}
              </div>
            </td>
          </ng-container>
          <tr *matHeaderRowDef="partColumnHeaders" mat-header-row></tr>
          <tr
            *matRowDef="let item; let even = even; columns: partColumnHeaders"
            [ngClass]="{ 'gray-row': even }"
            mat-row></tr>
          <tr *matFooterRowDef="partColumnHeaders" mat-footer-row></tr>

          <!-- Group header -->
          <ng-container matColumnDef="groupHeader">
            <td *matCellDef="let groupBy" colspan="999" class="body-1" mat-cell>
              <div class="subtitle-2">
                {{ groupBy.quoteVersion }} <span class="subtitle-2">{{ groupBy.quoteNumber }}</span>
              </div>
            </td>
          </ng-container>

          <tr *matRowDef="let row; columns: ['groupHeader']; when: isGroup" class="group-row" mat-row></tr>
        </table>
      </div>
      <div *ngIf="invoice.sublets?.length" class="invoice-table" formArrayName="subletItems">
        <h4 class="section-header">Sublets</h4>
        <table [dataSource]="subletsDataSource" class="table-style" mat-table>
          <ng-container matColumnDef="description">
            <th *matHeaderCellDef class="body-2" mat-header-cell>Description</th>
            <td *matCellDef="let item" class="body-1" mat-cell>
              <div class="flex items-center" [class.ml-30]="atLeastOneComment && !item?.costControlComment">
                <ng-container
                  *ngTemplateOutlet="comment; context: { costControlComment: item?.costControlComment }"></ng-container>
                <div>{{ item.description }}</div>
              </div>
            </td>
            <td *matFooterCellDef mat-footer-cell class="body-1"></td>
          </ng-container>
          <ng-container matColumnDef="note">
            <th *matHeaderCellDef class="body-2" mat-header-cell>Assessor Note</th>
            <td *matCellDef="let item" class="body-1" mat-cell>
              <div>{{ item.assessorNote }}</div>
            </td>
            <td *matFooterCellDef class="footer-label body-1" mat-footer-cell>Sublets Total</td>
          </ng-container>
          <ng-container matColumnDef="assessedAmount">
            <th *matHeaderCellDef class="body-2" mat-header-cell>Assessed</th>
            <td *matCellDef="let item" class="body-1" mat-cell>
              <ng-container *ngIf="item.assessedAmount || item.assessedAmount === 0">
                <div class="amount-cell">
                  <div class="sign-cell">$</div>
                  {{ item.assessedAmount | number: '1.2-2' }}
                </div>
              </ng-container>
            </td>
            <td *matFooterCellDef mat-footer-cell class="body-1">
              <div class="amount-cell">
                <div class="sign-cell">$</div>
                {{ totalAssessedSublet | number: '1.2-2' }}
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="invoicedAmount">
            <th *matHeaderCellDef class="padding-right-10 body-2" mat-header-cell>Invoiced</th>
            <td *matCellDef="let item" class="body-1" mat-cell>
              <div class="assess-indicator">
                <ng-container *ngIf="item.invoicedAmount || item.invoicedAmount === 0">
                  <mat-form-field
                    [class.error-row]="!(subletItems | getControl: item.id).valid"
                    appearance="outline"
                    class="column-field"
                    color="accent">
                    <input
                      (change)="updateSublet(item.id)"
                      [formControl]="subletItems | getControl: item.id"
                      class="body-1"
                      matInput />
                    <div class="prefix body-1" matPrefix>$</div>
                    <mat-error>Enter number</mat-error>
                  </mat-form-field>
                  <ng-container
                    [ngTemplateOutletContext]="{
                      assessedAmount: item.assessedAmount,
                      invoicedAmount: item.invoicedAmount
                    }"
                    [ngTemplateOutlet]="differenceIndicators"></ng-container>
                </ng-container>
              </div>
            </td>
            <td *matFooterCellDef class="padding-right-12 body-1" mat-footer-cell>
              <div class="amount-cell">
                <div class="sign-cell">$</div>
                {{ totalInvoicedSublet | number: '1.2-2' }}
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="approvedAmount">
            <th *matHeaderCellDef class="body-2" mat-header-cell>Approved</th>
            <td *matCellDef="let item" class="body-1" mat-cell>
              <div [class.indicator-approved]="item.approvedAmount" class="assess-indicator">
                <ng-container *ngIf="item.approvedAmount || item.approvedAmount === 0">
                  <div class="amount-cell">
                    <div class="sign-cell">$</div>
                    {{ item.approvedAmount | number: '1.2-2' }}
                  </div>
                  <ng-container
                    [ngTemplateOutletContext]="{
                      approvedAmount: item.approvedAmount,
                      invoicedAmount: item.invoicedAmount
                    }"
                    [ngTemplateOutlet]="differenceIndicatorsApproved"></ng-container>
                </ng-container>
              </div>
            </td>
            <td *matFooterCellDef mat-footer-cell class="body-1">
              <div *ngIf="totalApprovedSublet" class="amount-cell">
                <div class="sign-cell">$</div>
                {{ totalApprovedSublet | number: '1.2-2' }}
              </div>
            </td>
          </ng-container>
          <tr *matHeaderRowDef="subletsColumnHeaders" mat-header-row></tr>
          <tr
            *matRowDef="let item; let even = even; columns: subletsColumnHeaders"
            [ngClass]="{ 'gray-row': even }"
            mat-row></tr>
          <tr *matFooterRowDef="subletsColumnHeaders" mat-footer-row></tr>

          <!-- Group header -->
          <ng-container matColumnDef="groupHeader">
            <td *matCellDef="let groupBy" colspan="999" class="body-1" mat-cell>
              <div class="subtitle-2">
                {{ groupBy.quoteVersion }} <span class="subtitle-2">{{ groupBy.quoteNumber }}</span>
              </div>
            </td>
          </ng-container>

          <tr *matRowDef="let row; columns: ['groupHeader']; when: isGroup" class="group-row" mat-row></tr>
        </table>
      </div>
    </form>
  </mat-expansion-panel>
</div>

<ng-template #overviewTotals let-assessedAmount="assessedAmount" let-invoicedAmount="invoicedAmount">
  <div class="assess-indicator">
    <div
      [ngClass]="{
        ASSESSED_UNDER: assessedAmount !== invoicedAmount,
        ASSESSED_EQUAL: assessedAmount === invoicedAmount
      }"
      class="indicator margin-left-10">
      <mat-icon *ngIf="invoicedAmount < assessedAmount">keyboard_arrow_down</mat-icon>
      <mat-icon *ngIf="invoicedAmount > assessedAmount">keyboard_arrow_up</mat-icon>
      <mat-icon *ngIf="assessedAmount === invoicedAmount">check</mat-icon>
    </div>
  </div>
  <span *ngIf="assessedAmount !== invoicedAmount" class="overview-diff body-2"
    >$
    <span *ngIf="invoicedAmount < assessedAmount"> - </span>
    <span *ngIf="invoicedAmount > assessedAmount"> + </span>
    {{ Math.abs(invoicedAmount - assessedAmount) | number: '1.2-2' }}
  </span>
</ng-template>

<ng-template #differenceIndicators let-assessedAmount="assessedAmount" let-invoicedAmount="invoicedAmount">
  <div class="assess-indicator">
    <div
      [ngClass]="{
        ASSESSED_UNDER: assessedAmount !== invoicedAmount && invoicedAmount !== 0,
        ASSESSED_REJECTED: invoicedAmount === 0 && assessedAmount !== 0
      }"
      class="indicator margin-left-10 margin-right-minus">
      <mat-icon *ngIf="invoicedAmount && invoicedAmount < assessedAmount">keyboard_arrow_down</mat-icon>
      <mat-icon *ngIf="invoicedAmount > assessedAmount">keyboard_arrow_up</mat-icon>
      <mat-icon *ngIf="invoicedAmount === 0 && assessedAmount !== 0">clear</mat-icon>
    </div>
  </div>
</ng-template>

<ng-template #differenceIndicatorsApproved let-approvedAmount="approvedAmount" let-invoicedAmount="invoicedAmount">
  <div class="assess-indicator">
    <div
      [ngClass]="{ ASSESSED_UNDER: approvedAmount !== invoicedAmount && approvedAmount !== 0 }"
      class="indicator margin-left-10 margin-right-minus">
      <mat-icon *ngIf="approvedAmount && approvedAmount < invoicedAmount">keyboard_arrow_down</mat-icon>
      <mat-icon *ngIf="approvedAmount > invoicedAmount">keyboard_arrow_up</mat-icon>
    </div>
  </div>
</ng-template>

<mat-menu #kebabMenu="matMenu" [xPosition]="'before'" class="job-progress">
  <ng-template let-invoiceId="invoiceId" matMenuContent>
    <button (click)="cancelInvoice(invoiceId)" mat-menu-item>Cancel Invoice</button>
  </ng-template>
</mat-menu>

<ng-template #comment let-costControlComment="costControlComment">
  <mat-icon
    *ngIf="costControlComment"
    [matTooltip]="costControlComment"
    matTooltipPosition="below"
    class="custom-mat-icon mat-icon-sm mr-10"
    svgIcon="chat_black_notification"></mat-icon>
</ng-template>

<ng-template #guidance>
  <div *ngIf="!bankingDetailsBanner().isUsingPreviousCompanyInformation; else oldCompany">
    <div>
      This invoice has been updated to use the new company information.
      <span
        class="text-air-force-blue underline underline-offset-4 cursor-pointer"
        (click)="changeCompanyInformation('updateToPreviousCompanyInformation')"
        >Click here</span
      >
      to use the old company information.
    </div>
  </div>
  <ng-template #oldCompany>
    <div>
      This invoice is using the old company information.
      <span
        class="text-air-force-blue underline underline-offset-4 cursor-pointer"
        (click)="changeCompanyInformation('updateToCurrentCompanyInformation')"
        >Click here</span
      >
      to use the updated company information.
    </div>
  </ng-template>
</ng-template>

<ng-template #infosContent let-field="field" let-value="value">
  <infos-content *ngIf="value" [field]="field" [value]="value" />
</ng-template>

<ng-template #noBankingGuidance>
  <div class="subtitle-2">Please ask the administrator to complete the banking details.</div>
</ng-template>
