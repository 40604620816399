import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  effect,
  inject,
  Injector,
  OnInit,
  ViewChild
} from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyMenuModule } from '@angular/material/legacy-menu';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MlAdminUser } from '../../../model/user.model';
import { ColumnHeaders } from '../../../model/ColumnHeaders.model';
import { sortingDataAccessor } from '../../../consts/sortFunction.const';
import { MultiLocalAdminService } from '../../../services/ml-admin.service';
import { CurrentUserService } from '../../../services/currentUser.service';
import { StatusCellInfoComponent } from '../../../components/repairer-site-table/status-cell-info/status-cell-info.component';
import { MatLegacyTableDataSource as MatTableDataSource, MatLegacyTableModule } from '@angular/material/legacy-table';
import { KebabMenuListPipe } from '../../../pipes/kebabMenuList.pipe';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig
} from '@angular/material/legacy-dialog';
import { smallModalConfig, wideModalConfig } from '../../../consts/modal-config.const';
import { MladminModalComponent } from '../../mladmin-dashboard/mladmin-modal/mladmin-modal.component';
import { MatLegacyProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { ConfirmBoxComponent } from '../../../components/confirm-box/confirm-box.component';
import { UserStatus } from '../../../types/userStatus.type';
import { Observable } from 'rxjs';
import { SysAdminService } from '../../../services/sys-admin.service';
import { ModalConfigsHelper } from '../../../helpers/modal-configs.helper';

@Component({
  selector: 'app-multi-location-admin-section',
  standalone: true,
  imports: [
    CommonModule,
    MatDividerModule,
    MatIconModule,
    MatExpansionModule,
    MatLegacyButtonModule,
    MatLegacyMenuModule,
    MatSortModule,
    StatusCellInfoComponent,
    MatLegacyTableModule,
    KebabMenuListPipe,
    MatLegacyProgressSpinnerModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './multi-location-admin-section.component.html',
  styles: [
    `
      .mat-column-username {
        width: 20%;
      }
      .mat-column-name {
        width: 20%;
      }
      .mat-column-repairSites {
        width: 45%;
      }
      .mat-column-status {
        width: 20px;
      }
      .mat-column-kebab {
        max-width: 28px;
        padding: 0;
      }
    `
  ],
  providers: [TitleCasePipe]
})
export class MultiLocationAdminSectionComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;

  expandCurtain = false;
  dataSource: MatTableDataSource<MlAdminUser>;
  columnHeaders: string[] = [];
  columnInfos: ColumnHeaders[] = [
    {
      matColumnDef: 'username',
      columnTitle: 'User Name',
      property: 'username'
    },
    {
      matColumnDef: 'name',
      columnTitle: 'Name',
      property: 'name'
    },
    {
      matColumnDef: 'repairerSites',
      columnTitle: 'Repair sites ',
      property: 'repairerSites'
    },
    {
      matColumnDef: 'status',
      columnTitle: 'Status'
    },
    {
      matColumnDef: 'kebab'
    }
  ];
  kebabButtons: { status: UserStatus | 'DELETED'; label: string; activatedToDisplay: boolean }[] = [
    { status: 'ENABLED', label: 'Enable User', activatedToDisplay: true },
    { status: 'DISABLED', label: 'Disable User', activatedToDisplay: true },
    { status: 'ARCHIVED', label: 'Archive User', activatedToDisplay: true },
    { status: 'DELETED', label: 'Delete User', activatedToDisplay: false }
  ];

  private matDialog = inject(MatDialog);
  private cdRef = inject(ChangeDetectorRef);
  private modalConfigsHelper = inject(ModalConfigsHelper);
  private titleCasePipe = inject(TitleCasePipe);
  private injector = inject(Injector);
  currentUserService = inject(CurrentUserService);
  mlAdminService = inject(MultiLocalAdminService);
  private sysAdminService = inject(SysAdminService);

  ngOnInit() {
    this.columnHeaders = this.columnInfos?.map((col) => col.matColumnDef);
    this.mlAdminService.getAllMlAdmins().subscribe();

    effect(
      () => {
        this.initializeTableData();
      },
      { injector: this.injector }
    );
  }

  private initializeTableData() {
    this.dataSource = new MatTableDataSource(this.mlAdminService.mlAdminList());
    this.dataSource.sortingDataAccessor = sortingDataAccessor;
    this.dataSource.sort = this.sort;
    this.cdRef.detectChanges();
  }

  getRepairerSiteNames(user: MlAdminUser): string {
    return user.repairerSites.map((site) => site.companyName).join(', ');
  }

  openMlAdminModal(user?: MlAdminUser) {
    this.matDialog.open(MladminModalComponent, this.modalConfigsHelper.buildExtraWideModalConfig({ user }, 98));
  }

  onClickKebabButton(status: UserStatus | 'DELETED', username: string) {
    let waitingConfirmation = false;
    let requestObservable = null;

    switch (status) {
      case 'ENABLED':
        requestObservable = this.sysAdminService.enableUser(username);
        break;
      case 'DISABLED':
        requestObservable = this.sysAdminService.disableUser(username);
        break;
      case 'ARCHIVED':
        waitingConfirmation = true;
        requestObservable = this.sysAdminService.archiveSysadmin(username);
        this.openConfirmationModal(requestObservable, 'archive');
        break;
      case 'DELETED':
        waitingConfirmation = true;
        requestObservable = this.sysAdminService.deleteSysadminOrMlAdmin(username);
        this.openConfirmationModal(requestObservable, 'delete');
        break;
    }

    !waitingConfirmation && this.actionOnUser(requestObservable);
  }

  private actionOnUser(request: Observable<object>) {
    if (request) {
      request.subscribe({
        complete: () => {
          this.mlAdminService.getAllMlAdmins().subscribe();
        }
      });
    }
  }

  private openConfirmationModal(request: Observable<object>, action: string) {
    const data = {
      title: `${this.titleCasePipe.transform(action)}${action === 'delete' ? ' Invited' : ''} user`,
      alertMessage: `User will be ${action}d.`,
      confirmButton: action.toUpperCase()
    };
    const dialogRef = this.matDialog.open(
      ConfirmBoxComponent,
      this.modalConfigsHelper.buildMediumModalConfig(data, smallModalConfig)
    );
    dialogRef.afterClosed().subscribe((result: string) => {
      if (result === action.toUpperCase()) {
        this.actionOnUser(request);
      }
    });
  }
}
