<div class="header-container top-container">
  <h3>Company Information</h3>
  <div class="edit-address">
    <button (click)="editCompanyAddress.emit()" mat-raised-button color="accent">EDIT</button>
  </div>
</div>
<mat-divider></mat-divider>

<div class="company-information-box m-20">
  <div class="px-10 pb-20 grid gap-10 items-center" [ngStyle]="{ 'grid-template-columns': dataColumnStyle }">
    <div *ngIf="repairerSite?.companyName" [ngStyle]="{ 'grid-column': businessNameStyle || 'auto' }">
      <div class="body-2">Business Name</div>
      <div class="subtitle-2">
        {{ repairerSite.companyName }}
      </div>
    </div>
    <div *ngIf="repairerSite?.abn">
      <div class="body-2">
        {{ repairerSite.country === 'Australia' ? 'ABN' : 'NZBN' }}
      </div>
      <div class="subtitle-2">
        {{ repairerSite.abn }}
      </div>
    </div>
    <div *ngIf="repairerSite?.licenseNumber">
      <div class="body-2">License Number</div>
      <div class="subtitle-2">
        {{ repairerSite.licenseNumber }}
      </div>
    </div>
    <div *ngIf="repairerSite?.phoneNumber">
      <div class="body-2">Phone Number</div>
      <div class="subtitle-2">
        {{ repairerSite.phoneNumber }}
      </div>
    </div>
    <div *ngIf="repairerSite?.email">
      <div class="body-2">Email</div>
      <div class="subtitle-2 break-words">
        {{ repairerSite.email }}
      </div>
    </div>
    <div *ngIf="repairerSite?.quotingSystemsString">
      <div class="body-2">Quoting System</div>
      <div class="subtitle-2 break-words">
        {{ repairerSite.quotingSystemsString }}
      </div>
    </div>
  </div>
  <div
    class="company-addresses"
    [class.hidden]="!repairerSite?.physicalAddress?.postCode && !repairerSite?.physicalAddress.addressLine1">
    <mat-divider></mat-divider>
    <div class="content">
      <ng-container *ngIf="repairerSite?.physicalAddress">
        <div class="google-map-container">
          <google-map
            *ngIf="(googleMapsService.googleMapsLoaded | async) && markerOptions"
            height="100%"
            width="100%"
            [options]="options">
            <map-marker [options]="markerOptions"></map-marker>
          </google-map>
        </div>
        <div class="physical-address">
          <div class="address-title body-2">Physical Address</div>
          <div class="address">
            <mat-icon>pin_drop_24px</mat-icon>
            <div class="subtitle-2">
              <div>
                {{ repairerSite?.physicalAddress?.addressLine1 && repairerSite?.physicalAddress?.addressLine1
                }}{{ repairerSite?.physicalAddress?.addressLine2 ? ', ' : '' }}
                {{ repairerSite?.physicalAddress?.addressLine2 && repairerSite?.physicalAddress?.addressLine2 }}
              </div>
              <div>
                {{ repairerSite?.physicalAddress?.suburb && repairerSite?.physicalAddress?.suburb + ',' }}
                {{
                  repairerSite?.country === 'Australia'
                    ? repairerSite?.physicalAddress?.state && repairerSite?.physicalAddress?.state
                    : repairerSite?.physicalAddress?.city && repairerSite?.physicalAddress?.city
                }}
              </div>
              <div>
                {{ repairerSite?.physicalAddress?.postCode && repairerSite?.physicalAddress?.postCode + ',' }}
                {{ repairerSite?.country && repairerSite?.country }}
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="billing-address" *ngIf="repairerSite?.billingAddress">
        <div class="address-title body-2">Billing Address</div>
        <div class="address">
          <mat-icon>pin_drop_24px</mat-icon>
          <div class="subtitle-2">
            <div>
              {{ repairerSite?.billingAddress?.addressLine1 && repairerSite?.billingAddress?.addressLine1
              }}{{ repairerSite?.billingAddress?.addressLine2 ? ', ' : '' }}
              {{ repairerSite?.billingAddress?.addressLine2 && repairerSite?.billingAddress?.addressLine2 }}
            </div>
            <div>
              {{ repairerSite?.billingAddress?.suburb && repairerSite?.billingAddress?.suburb + ',' }}
              {{
                repairerSite?.country === 'Australia'
                  ? repairerSite?.billingAddress?.state && repairerSite?.billingAddress?.state
                  : repairerSite?.billingAddress?.city && repairerSite?.billingAddress?.city
              }}
            </div>
            <div>
              {{ repairerSite?.billingAddress?.postCode && repairerSite?.billingAddress?.postCode + ',' }}
              {{ repairerSite?.country && repairerSite?.country }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
