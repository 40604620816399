import { MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { Directive } from '@angular/core';

export const EIO_DATE_FORMATS_SHORT: MatDateFormats = {
  parse: {
    dateInput: 'MM/YYYY'
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@Directive({
  selector: '[appMonthYearDateFormat]',
  providers: [{ provide: MAT_DATE_FORMATS, useValue: EIO_DATE_FORMATS_SHORT }]
})
export class MonthYearDateFormatDirective {}
