import { Component, Input } from '@angular/core';
import { MlAdminUser, UserRepairerSite } from '../../../model/user.model';
import { CommonModule } from '@angular/common';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { TimePipe } from '../../../pipes/time.pipe';
import { DatePipe } from '../../../pipes/date.pipe';

@Component({
  selector: 'app-status-cell-info',
  standalone: true,
  imports: [CommonModule, MatLegacyTooltipModule, DatePipe, TimePipe],
  templateUrl: './status-cell-info.component.html',
  styleUrls: ['./status-cell-info.component.scss'],
  host: { class: 'flex justify-center' }
})
export class StatusCellInfoComponent {
  @Input() currentUserTimezone: string;
  @Input() data: UserRepairerSite & MlAdminUser;
}
