import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ConfirmBoxComponent } from '../../../components/confirm-box/confirm-box.component';
import { UserService } from '../../../services/user.service';
import { ToolbarService } from '../../../services/toolbar.service';
import { MessagingService } from '../../../components/messaging/messaging.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { Moment } from 'moment';
import { EIO_DATE_BE_FORMAT } from '../../../consts/localization.const';
import { Job } from '../../../model/job.model';
import { DateInputHelper } from '../../../helpers/date-input.helper';
import { OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { ModalConfigsHelper } from '../../../helpers/modal-configs.helper';
import { smallModalConfig } from '../../../consts/modal-config.const';

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.scss']
})
export class BookingComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Output() refreshJobViewer: EventEmitter<any> = new EventEmitter();
  @Output() disableSendButton: EventEmitter<any> = new EventEmitter();
  @Input() hasQuote: boolean;
  @Input() preselectedOption: string;
  @Input() onSite: boolean;
  @Input() job: Job;
  @Input() timezone: string;

  bookingForm: UntypedFormGroup;
  availableDates: Moment[] = [];
  minDate: Moment;
  inspectionDateInPast = false;
  private unsubscribe = new Subject<void>();

  constructor(
    private confirmBox: MatDialog,
    private userService: UserService,
    public toolbarService: ToolbarService,
    private message: MessagingService,
    private fb: UntypedFormBuilder,
    private elRef: ElementRef,
    public dateInputHelper: DateInputHelper,
    private modalConfigsHelper: ModalConfigsHelper
  ) {}

  get proposedDate() {
    return this.bookingForm.get('proposedDate');
  }

  get bookingOption() {
    return this.bookingForm.get('bookingOption');
  }

  ngOnInit(): void {
    this.bookingForm = this.fb.group({
      bookingOption: [{ value: this.job.bookingOption ? this.job.bookingOption : '' }],
      proposedDate: [{ value: this.job.propositionDate ? this.job.propositionDate : '' }, Validators.required]
    });

    this.minDate = this.timezone ? moment().tz(this.timezone) : moment();

    if (this.job?.inspectionDate) {
      this.inspectionDateInPast = this.minDate
        .startOf('day')
        .isAfter(moment(this.job.inspectionDate, EIO_DATE_BE_FORMAT));
    }

    if (this.preselectedOption) {
      this.bookingOption.setValue(this.preselectedOption);
      if (
        (!this.onSite && !this.hasQuote) ||
        (this.onSite && (this.hasQuote || this.inspectionDateInPast)) ||
        this.preselectedOption === 'PROPOSE_ON_SITE_INSPECTION'
      ) {
        this.bookingOption.disable();
      }
    }

    if (this.job?.propositionDate) {
      this.proposedDate.setValue(this.job?.propositionDate);
    }

    this.proposedDate.valueChanges
      .pipe(takeUntil(this.unsubscribe), debounceTime(750), distinctUntilChanged())
      .subscribe((value) => {
        if (this.proposedDate.dirty) {
          if (this.proposedDate.valid) {
            this.updateJob('proposedDate');
          } else {
            this.job.propositionDate = value;
            this.disableSendButton.emit();
          }
        }
      });

    this.bookingOption.valueChanges
      .pipe(takeUntil(this.unsubscribe), debounceTime(750), distinctUntilChanged())
      .subscribe(() => {
        if (this.bookingOption.value === 'CANCELLED_DUE_TO_NO_SHOW') {
          this.proposedDate.setValue('');
        }
      });

    if (this.job?.restrictInspectionDates === 'ACMS') {
      this.userService.getNextAvailableInspectionDates(this.job.insurerId).subscribe(
        (availableDates) => availableDates.forEach((date) => this.availableDates.push(moment.unix(date))),
        () => this.message.error('Could not retrieve the next available inspection dates.')
      );
    }
  }

  ngAfterViewInit() {
    this.elRef.nativeElement.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    // In case we re-create the booking after delete, wait until the value is set and the form is ready
    if (changes.preselectedOption && !changes.preselectedOption.firstChange) {
      this.bookingOption.setValue(this.preselectedOption);
      if (
        (!this.onSite && !this.hasQuote) ||
        (this.onSite && (this.hasQuote || this.inspectionDateInPast)) ||
        this.preselectedOption === 'PROPOSE_ON_SITE_INSPECTION'
      ) {
        this.bookingOption.disable();
      }
    } else if (changes.hasQuote && !changes.hasQuote.firstChange) {
      if (this.onSite) {
        if (this.hasQuote) {
          // In case a quote was added after the booking was added to the Send tray, make sure the only available option is the one selected
          this.bookingOption.setValue('PROPOSE_NEW_DATE');
          this.bookingOption.disable();
          this.updateJob('bookingOption');
        } else {
          // If the quote was deleted, put back the available selections
          this.bookingOption.enable();
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  availableDatesFilter = (m: Moment | null): boolean => {
    if (this.job?.restrictInspectionDates === 'ACMS') {
      let found = false;
      for (const date of this.availableDates) {
        found =
          (m || moment()).date() === date.date() &&
          (m || moment()).month() === date.month() &&
          (m || moment()).year() === date.year();
        if (found) {
          break;
        }
      }
      return found;
    } else {
      return true;
    }
  };

  confirmDeleteBooking() {
    const data = { title: 'Delete Booking', alertMessage: 'This booking will be deleted.', confirmButton: 'DELETE' };
    const dialogRef = this.confirmBox.open(
      ConfirmBoxComponent,
      this.modalConfigsHelper.buildMediumModalConfig(data, smallModalConfig)
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'DELETE') {
        this.deleteBooking();
      }
    });
  }

  deleteBooking() {
    this.userService.updateJob('false', this.job.jobId, 'booking').subscribe(
      () => this.toolbarService.setCloudState('RESTING'),
      () => {
        this.message.error('Could not delete booking.');
        this.toolbarService.setCloudState('RESTING');
      },
      () => this.refreshJobViewer.emit()
    );
  }

  updateJob(fieldName: string) {
    this.toolbarService.setCloudState('SAVING');
    let fieldValue = this.bookingForm.get(fieldName).value;
    if (fieldName === 'proposedDate') {
      if (fieldValue !== null) {
        fieldValue = moment(fieldValue).format(EIO_DATE_BE_FORMAT);
      }
      fieldName = 'bookingPropositionDate';
    }
    this.userService.updateJob(fieldValue, this.job.jobId, fieldName).subscribe(
      () => this.toolbarService.setCloudState('RESTING'),
      () => {
        this.message.error('Could not update field.');
        this.toolbarService.setCloudState('RESTING');
      },
      () => {
        this.refreshJobViewer.emit();
      }
    );
  }
}
