import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BodyParserHelper {
  constructor() {}

  checkBodyValue(body: string) {
    try {
      JSON.parse(body);
    } catch (e) {
      return false;
    }
    return true;
  }
}
