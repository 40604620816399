<div class="add-customer-feedback">
  <mat-card class="curtains">
    <div class="customer-feedback-header secondary-icon">
      <h5>Customer Feedback</h5>
      <div class="customer-panel">
        <button mat-icon-button (click)="confirmDeleteCustomerFeedback()">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
    <mat-card-content>
      <form [formGroup]="customerFeedbackForm" class="gicop">
        <div class="three-fields">
          <mat-form-field appearance="outline">
            <mat-label>Date of feedback</mat-label>
            <input
              (keypress)="dateInputHelper.restrictDateInput($event)"
              [matDatepicker]="feedbackDatePicker"
              required
              formControlName="date"
              matInput />
            <mat-datepicker-toggle [for]="feedbackDatePicker" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #feedbackDatePicker></mat-datepicker>
            <mat-hint *ngIf="date.enabled">Date format DD/MM/YYYY</mat-hint>
            <mat-error *ngIf="date.hasError('matDatepickerParse')">
              The date format is <strong>DD/MM/YYYY</strong>
            </mat-error>
            <mat-error *ngIf="date.hasError('required') && !date.hasError('matDatepickerParse')">
              Date of feedback is <strong>required</strong>
            </mat-error>
          </mat-form-field>
          <div class="feedback-type">
            <label id="radio-group-label" class="body-2">Type of feedback</label>
            <mat-radio-group
              aria-labelledby="radio-group-label"
              color="accent"
              formControlName="feedbackType"
              class="radio-group">
              <mat-radio-button class="body-1" value="POSITIVE">Positive</mat-radio-button>
              <mat-radio-button class="body-1" value="NEGATIVE">Negative</mat-radio-button>
            </mat-radio-group>
          </div>
          <mat-form-field class="inputField" appearance="outline" color="accent">
            <mat-label>Raised by</mat-label>
            <input matInput autocomplete="off" formControlName="raisedBy" maxlength="128" required />
            <mat-error *ngIf="raisedBy.invalid">Raised by is <strong>required</strong></mat-error>
          </mat-form-field>
        </div>

        <mat-form-field appearance="outline" color="accent" class="textarea-fields">
          <mat-label>Details</mat-label>
          <textarea
            matInput
            autocomplete="off"
            formControlName="details"
            maxlength="1024"
            cdkTextareaAutosize="true"
            cdkAutosizeMinRows="1"
            required></textarea>
          <mat-error *ngIf="details.invalid">Details is <strong>required</strong></mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" color="accent" class="textarea-fields">
          <mat-label>Action to resolve</mat-label>
          <textarea
            matInput
            autocomplete="off"
            formControlName="actionToResolve"
            maxlength="512"
            cdkTextareaAutosize="true"
            cdkAutosizeMinRows="1"></textarea>
        </mat-form-field>
      </form>
    </mat-card-content>
  </mat-card>
</div>
