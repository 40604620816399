export enum JobStatusEnum {
  REQUEST_FOR_QUOTE = 'Request For Quote',
  HIDDEN = 'Hidden',
  MORE_INFO_REQUIRED = 'More Information Required',
  MORE_INFO_SUPPLEMENT = 'More Information Supplement',
  ASSESSMENT_COMPLETED = 'Assessed Without Prejudice',
  AUTHORIZED = 'Authorised',
  PRE_AUTHORIZED = 'Self-Authorised',
  PENDING_ASSESSMENT = 'Pending Assessment',
  PENDING_INVOICE_APPROVAL = 'Pending Invoice Approval',
  INVOICE_APPROVED = 'Invoice Approved',
  INVOICE_REJECTED = 'Invoice Rejected',
  SUPPLEMENT_ASSESSMENT_COMPLETED = 'Supplement Assessed Without Prejudice',
  PENDING_SUPPLEMENT_ASSESSMENT = 'Pending Supplement Assessment',
  REPAIRS_BOOKED = 'Repairs Booked',
  READY_TO_INVOICE = 'Ready To Invoice'
}
