export const AU_STATES_OPTIONS = [
  { value: 'ACT', label: 'ACT' },
  { value: 'NSW', label: 'NSW' },
  { value: 'NT', label: 'NT' },
  { value: 'QLD', label: 'QLD' },
  { value: 'SA', label: 'SA' },
  { value: 'TAS', label: 'TAS' },
  { value: 'VIC', label: 'VIC' },
  { value: 'WA', label: 'WA' }
];

export const NZ_STATES_OPTIONS = [{ value: 'NZ', label: 'NZ' }];

export const REGO_STATE = [
  { group: '', options: [{ value: '', label: '' }] },
  { group: 'AUS', options: AU_STATES_OPTIONS },
  { group: 'NZ', options: NZ_STATES_OPTIONS }
];
