<mat-dialog-content>
  <mat-icon>screen_share</mat-icon>
  <div class="title-1">Request Screen Sharing</div>
  <div class="request" *ngIf="!sendingInProgress">
    {{ data?.sysAdminName }} has requested a screen sharing session with you. Do you accept?
  </div>
  <div class="progress-bar body-2" *ngIf="sendingInProgress">
    <mat-progress-bar mode="buffer"></mat-progress-bar>
    <span>Sharing screen...</span>
    <span>This should only take a second</span>
  </div>
</mat-dialog-content>
<mat-divider *ngIf="!sendingInProgress"></mat-divider>
<mat-dialog-actions *ngIf="!sendingInProgress">
  <button mat-raised-button mat-dialog-close color="accent" (click)="cancelSharingScreen()" type="button">
    CANCEL
  </button>
  <button class="buttons" color="primary" mat-raised-button (click)="acceptSharingScreen()">CONFIRM</button>
</mat-dialog-actions>
