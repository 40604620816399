<div class="dialog-header" mat-dialog-title>
  <h6>{{ title }}</h6>
  <button aria-label="Close" mat-dialog-close mat-icon-button>
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-divider></mat-divider>
<form (ngSubmit)="saveRepairerDates()" [formGroup]="repairTimelineForm">
  <div mat-dialog-content class="update-repair-timeline-container">
    <div class="external-events">
      <div class="card" mwlDroppable dragOverClass="drag-over">
        <div class="repair-timeline-authorized-card">
          <app-car-placeholder-or-photo
            *ngIf="1281 | breakObserver: 'min' | async"
            [jobInfo]="{ jobId: data?.job?.jobId, repairerSiteId: data?.job?.repairerSiteId, rego: data?.job?.rego }"
            [photoInfo]="{
              photoIdentityId: starredPhotoInfo?.id,
              photoIdentityExtension: starredPhotoInfo?.extension
            }">
          </app-car-placeholder-or-photo>
          <div
            class="authorized-title-container"
            [class.authorized-title-container-left-spaced]="starredPhotoInfo?.id || data?.job?.rego">
            <div class="authorized-title body-2">Authorised</div>
            <div class="body-2">{{ data.job?.authorityDate | momentTz: data?.userTimezone }}</div>
          </div>
        </div>
        <div class="info-guidance-drag-drop">
          <mat-icon>info_outlined</mat-icon>
          <span class="body-2">Drag and drop the events onto the calendar to set the dates</span>
        </div>
        <div
          class="card-body body-2"
          *ngFor="let event of externalEvents"
          mwlDraggable
          [dropData]="{ event: event }"
          [touchStartLongPress]="{ delay: 300, delta: 30 }"
          [matTooltipDisabled]="event.title != repairTimelineStatus.REPAIRS_BOOKED"
          [matTooltip]="'Date the customer was contacted to book the repairs'"
          [matTooltipPosition]="'right'"
          [ngClass]="{
            'placed-in-calendar': isPlacedInCalendar(events, event),
            'placed-in-calendar-in-future': event.start && !isPlacedInCalendar(events, event)
          }">
          <span class="card-title">
            <mat-icon class="drag-icon">drag_indicator</mat-icon>
            {{ event.title }}
            <mat-icon
              (click)="externalDrop(event); $event.stopPropagation()"
              class="remove-event"
              *ngIf="eventWithTitles?.slice(-1)[0]?.title === event?.title"
              >backspace</mat-icon
            >
          </span>
          <span class="card-date">{{
            event?.start ? (event?.start?.toString() | momentTz: data?.userTimezone) : ''
          }}</span>
          <div
            mwlDraggable
            (dragEnd)="title.style.zIndex = '-1'; status.style.color = 'transparent'"
            (dragStart)="title.style.zIndex = '10'; status.style.color = eventColorOndrag"
            [dropData]="{ event: event }"
            [touchStartLongPress]="{ delay: 300, delta: 30 }"
            class="indicator"
            dragActiveClass="drag-active"
            #status>
            <mat-icon>lens</mat-icon>
            <span class="body-3" #title>{{ event.title }}</span>
          </div>
        </div>
      </div>
    </div>
    <mat-divider [vertical]="true"></mat-divider>

    <div class="calendar-container">
      <div class="validation-banner" *ngIf="isCalendarInError || chronologicalOrderMessage">
        <mat-icon>warning</mat-icon>
        <div class="validation-message">
          <div>{{ chronologicalOrderMessage }}</div>
          <div *ngFor="let evt of events">{{ evt?.errorMessages?.join(', ') }}</div>
        </div>
      </div>
      <div class="repair-timeline-header row text-center">
        <div class="btn-group">
          <div
            class="btn"
            mwlCalendarPreviousView
            [view]="view"
            [(viewDate)]="viewDate"
            (viewDateChange)="closeOpenMonthViewDay()">
            <span class="btn-label">Previous</span>
          </div>
          <div class="btn" mwlCalendarToday [(viewDate)]="viewDate">Today</div>
          <div
            class="btn"
            mwlCalendarNextView
            [view]="view"
            [(viewDate)]="viewDate"
            (viewDateChange)="closeOpenMonthViewDay()">
            Next
          </div>
        </div>
        <div class="col-md-4">
          <h3>{{ viewDate | calendarDate: view + 'ViewTitle' : 'en' }}</h3>
        </div>
      </div>
      <mwl-calendar-month-view [viewDate]="viewDate" [events]="events" (eventTimesChanged)="eventDropped($event)">
      </mwl-calendar-month-view>
      <div class="fields-box-part box-explanation">
        <mat-form-field appearance="outline" class="textarea-fields" color="accent">
          <mat-label>Explanation</mat-label>
          <textarea matInput cdkTextareaAutosize="true" formControlName="explanation" [cdkAutosizeMinRows]="3">
          </textarea>
        </mat-form-field>
      </div>
      <div class="info-guidance subtitle-2">
        <mat-icon>info_outlined</mat-icon>
        <span class="body-2"
          >Please provide your revised repairs schedule. The final dates will also be included with your invoice.</span
        >
      </div>
    </div>
  </div>

  <mat-dialog-actions>
    <button mat-raised-button color="accent" mat-dialog-close>CANCEL</button>
    <button
      [disabled]="pending || isSaveBtnDisabled || chronologicalOrderMessage"
      color="primary"
      mat-raised-button
      type="submit">
      SAVE
    </button>
  </mat-dialog-actions>
</form>
