import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { UserService } from '../../../services/user.service';
import { MessagingService } from '../../../components/messaging/messaging.service';
import { OwnerContactUpdate } from '../../../model/user.model';
import { ToolbarService } from '../../../services/toolbar.service';
import { EscapeDialogHelper } from '../../../helpers/escape-dialog.helper';

@Component({
  selector: 'app-edit-contact',
  templateUrl: './edit-contact.component.html',
  styleUrls: ['./edit-contact.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EditContactComponent implements OnInit {
  contactForm: UntypedFormGroup;
  pending = false;

  constructor(
    public dialogRef: MatDialogRef<EditContactComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder,
    private userService: UserService,
    public toolbarService: ToolbarService,
    private message: MessagingService,
    private escapeDialogRef: EscapeDialogHelper
  ) {}

  get email() {
    return this.contactForm.get('email');
  }

  ngOnInit(): void {
    this.contactForm = this.fb.group({
      contactOrLastName: [''],
      email: [
        '',
        [
          Validators.pattern(
            '^([^@\\s\\."\'\\(\\)\\[\\]\\{\\}\\\\/,:;]+\\.)*[^@\\s\\."\'\\(\\)\\[\\]\\{\\}\\\\/,:;]' +
              '+@[^@\\s\\."\'\\(\\)\\[\\]\\{\\}\\\\/,:;]+(\\.[^@\\s\\."\'\\(\\)\\[\\]\\{\\}\\\\/,:;]+)+$'
          )
        ]
      ],
      homePhone: [''],
      mobilePhone: [''],
      workPhone: [''],
      postCode: ['']
    });

    if (!this.data.ownerIsACompany) {
      this.contactForm.controls.contactOrLastName.disable();
    }

    if (this.data.cancelled) {
      this.contactForm.disable();
    }

    this.escapeDialogRef.escapeDialog(this.dialogRef);

    this.email.valueChanges.subscribe(() => {
      if (this.email.invalid) {
        this.email.markAsTouched();
      }
    });
  }

  saveContact() {
    this.pending = true;
    this.toolbarService.setCloudState('SAVING');

    const ownerContactData: OwnerContactUpdate = {};

    if (this.contactForm.controls.email.dirty) {
      ownerContactData.email = this.contactForm.controls.email.value.trim();
    }

    if (this.contactForm.controls.homePhone.dirty) {
      ownerContactData.homePhone = this.contactForm.controls.homePhone.value.trim();
    }

    if (this.contactForm.controls.mobilePhone.dirty) {
      ownerContactData.mobilePhone = this.contactForm.controls.mobilePhone.value.trim();
    }

    if (this.contactForm.controls.workPhone.dirty) {
      ownerContactData.workPhone = this.contactForm.controls.workPhone.value.trim();
    }

    if (this.contactForm.controls.postCode.dirty) {
      ownerContactData.postCode = this.contactForm.controls.postCode.value.trim();
    }

    if (this.data.ownerIsACompany && this.contactForm.controls.contactOrLastName.dirty) {
      ownerContactData.contact = this.contactForm.controls.contactOrLastName.value.trim();
    }

    this.userService.updateOwnerContact(this.data.jobId, ownerContactData).subscribe(
      () => this.dialogRef.close('Success'),
      () => {
        this.message.error('Could not update contact.');
        this.toolbarService.setCloudState('RESTING');
        this.pending = false;
      },
      () => {
        this.toolbarService.setCloudState('RESTING');
        this.pending = false;
      }
    );
  }
}
