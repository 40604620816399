import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { CustomerFeedback } from '../../../../model/gicop-notifications';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import * as moment from 'moment';

@Component({
  selector: 'app-customer-feedback-table',
  templateUrl: './customer-feedback-table.component.html',
  styleUrls: ['./customer-feedback-table.component.scss']
})
export class CustomerFeedbackTableComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() customerFeedbacks: CustomerFeedback[];
  @Input() timezone: string;
  @ViewChild('customerFeedbackSort', { static: true }) customerFeedbackSort: MatSort;
  columnHeader = ['date', 'feedbackType', 'raisedBy', 'details'];
  dataSource: MatTableDataSource<CustomerFeedback>;
  activeColumn = 'date';

  constructor() {}

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<any>(this.customerFeedbacks);
    this.dataSource.sortingDataAccessor = this.customSortingAccessor.bind(this);
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.customerFeedbackSort;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.customerFeedbacks && !changes.customerFeedbacks.firstChange) {
      this.dataSource = new MatTableDataSource<any>(this.customerFeedbacks);
      this.dataSource.sortingDataAccessor = this.customSortingAccessor.bind(this);
      this.dataSource.sort = this.customerFeedbackSort;
    }
  }

  customSortingAccessor(item: any, property: string) {
    if (property === 'details' || property === 'actionToResolve' || property === 'raisedBy') {
      return item[property].toLowerCase();
    } else if (property === 'date') {
      return moment(item[property]).tz(this.timezone).format('DD/MM/yyyy');
    }
    return item[property];
  }
}
