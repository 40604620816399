import { DatePipe } from '@angular/common';
import {
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  FormArray,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatExpansionPanel } from '@angular/material/expansion';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig
} from '@angular/material/legacy-dialog';
import * as moment from 'moment';
import { Moment } from 'moment';
import { combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { REGO_STATE } from 'src/app/consts/rego-state.const';
import { ConfirmBoxComponent } from '../../../components/confirm-box/confirm-box.component';
import { MessagingService } from '../../../components/messaging/messaging.service';
import {
  EIO_DATE_BE_FORMAT,
  EIO_DATE_BE_FORMAT_MMYY,
  EIO_DATE_UI_FORMAT_PIPE
} from '../../../consts/localization.const';
import { extraWideModalConfig, smallModalConfig } from '../../../consts/modal-config.const';
import { DateInputHelper } from '../../../helpers/date-input.helper';
import { ModalConfigsHelper } from '../../../helpers/modal-configs.helper';
import { Job } from '../../../model/job.model';
import { ToolbarService } from '../../../services/toolbar.service';
import { UserService } from '../../../services/user.service';
import { genericVariableValidator } from '../../../validators/genericVariable.validators';
import { VehicleSelectionComponent } from '../vehicle-selection/vehicle-selection.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-claim-curtain',
  templateUrl: './claim-curtain.component.html',
  styleUrls: ['./claim-curtain.component.scss'],
  providers: [DatePipe]
})
export class ClaimCurtainComponent implements OnInit, OnChanges {
  @Input() job: Job;
  @Input() currentUserTimezone: string;
  @Input() claimForm: UntypedFormGroup;
  @Input() savedJobStatus: any;
  @Input() showBooking: boolean;
  @Input() shouldCollapseCurtain: boolean;
  @Output() fetchJob = new EventEmitter<void>();
  @Output() validateSendJob = new EventEmitter<{ isVehicleInfosInvalid: boolean; mandatoryRules: string[] }>();
  @Output() saveJob = new EventEmitter();
  @Output() disableSendButton: EventEmitter<boolean> = new EventEmitter();
  @Output() inspectionBooking = new EventEmitter();
  @ViewChild('panel', { static: false }) panel: MatExpansionPanel;
  claimCurtainOpened = false;
  mandatoryRules: string[] = [];
  vehicleFromGlassMandatory = false;
  regoStateMandatory = false;
  odometerMandatory = false;
  vinMandatory = false;
  dateDisplayFormat = EIO_DATE_UI_FORMAT_PIPE;
  groupStates = REGO_STATE;

  #destroyRef = inject(DestroyRef);

  constructor(
    private fb: UntypedFormBuilder,
    private datePipe: DatePipe,
    public dateInputHelper: DateInputHelper,
    private matDialog: MatDialog,
    private toolbarService: ToolbarService,
    private message: MessagingService,
    private userService: UserService,
    private modalConfigsHelper: ModalConfigsHelper
  ) {}

  get vehicleDescription() {
    return this.claimForm.get('vehicleDescription');
  }
  get rego() {
    return this.claimForm.get('rego');
  }
  get regoState() {
    return this.claimForm.get('regoState');
  }
  get regoExpiryDate() {
    return this.claimForm.get('regoExpiryDate');
  }
  get odometer() {
    return this.claimForm.get('odometer');
  }
  get vin() {
    return this.claimForm.get('vin');
  }
  get bodyColour() {
    return this.claimForm.get('bodyColour');
  }
  get condition() {
    return this.claimForm.get('condition');
  }
  get complianceDate() {
    return this.claimForm.get('complianceDate');
  }
  get vehicleValue() {
    return this.claimForm.get('vehicleValue');
  }
  get accidentDescription() {
    return this.claimForm.get('accidentDescription');
  }
  get incidentDate() {
    return this.claimForm.get('incidentDate');
  }
  get repairerInstructions() {
    return this.claimForm.get('repairerInstructions');
  }
  get inspectionType() {
    return this.claimForm.get('inspectionType');
  }
  get inspectionDate() {
    return this.claimForm.get('inspectionDate');
  }
  get assessmentCategory() {
    return this.claimForm.get('assessmentCategory');
  }
  get assessmentInstruction() {
    return this.claimForm.get('assessmentInstruction');
  }
  get accidentLocation() {
    return this.claimForm.get('accidentLocation');
  }
  get accessories() {
    return this.claimForm.get('accessories') as FormArray;
  }

  ngOnInit() {
    this.claimForm = this.fb.group({
      vehicleDescription: [{ value: '', disabled: true }],
      rego: [{ value: '', disabled: true }],
      regoState: [{ value: '', disabled: true }],
      regoExpiryDate: [{ value: '', disabled: true }],
      odometer: [
        { value: '', disabled: true },
        Validators.pattern('^(?!(?:[^.,\\n]*[.,]){2})\\d+(?:[,.]\\d+)?(?:[^\\S\\n\\r]\\d+(?:[,.]\\d+)?)*$')
      ],
      vin: [{ value: '', disabled: true }],
      bodyColour: [{ value: '', disabled: true }],
      condition: [{ value: '', disabled: true }],
      complianceDate: [{ value: '', disabled: true }],
      vehicleValue: [{ value: '', disabled: true }],
      accidentDescription: [{ value: '', disabled: true }],
      incidentDate: [{ value: '', disabled: true }],
      repairerInstructions: [{ value: '', disabled: true }],
      inspectionType: [{ value: '', disabled: true }],
      inspectionDate: [{ value: '', disabled: true }],
      assessmentCategory: [{ value: '', disabled: true }],
      assessmentInstruction: [{ value: '', disabled: true }],
      accidentLocation: [{ value: '', disabled: true }],
      accessories: new UntypedFormArray([])
    });

    combineLatest([
      this.vehicleDescription.statusChanges,
      this.regoState.statusChanges,
      this.vin.statusChanges,
      this.odometer.statusChanges
    ])
      .pipe(debounceTime(750), distinctUntilChanged(), takeUntilDestroyed(this.#destroyRef))
      .subscribe({
        next: ([vehicleDescriptionState, regoState, vinState, odometerState]) => {
          this.validateSendJob.emit({
            mandatoryRules: this.mandatoryRules,
            isVehicleInfosInvalid:
              vehicleDescriptionState === 'INVALID' ||
              regoState === 'INVALID' ||
              vinState === 'INVALID' ||
              odometerState === 'INVALID' ||
              (this.odometerMandatory && !this.odometer.value?.trim()) ||
              (this.vinMandatory && !this.vin.value?.trim()) ||
              (this.vehicleFromGlassMandatory &&
                ((!this.job?.vehicleSelectedFromGlassGuide && !this.job?.unlistedGlassVehicle) ||
                  !this.vehicleDescription.value?.trim()))
          });
        }
      });

    this.populateClaimValues();
    this.defineMandatoryFields();

    this.vehicleDescription.valueChanges
      .pipe(debounceTime(750), distinctUntilChanged(), takeUntilDestroyed(this.#destroyRef))
      .subscribe({
        next: () => {
          if (this.claimCurtainOpened && !this.job.vehicleSelectedFromGlassGuide) {
            this.saveJob.emit({
              fieldValue: this.vehicleDescription.value,
              fieldName: 'vehicleDescription'
            });
          }
        }
      });

    this.rego.valueChanges
      .pipe(debounceTime(750), distinctUntilChanged(), takeUntilDestroyed(this.#destroyRef))
      .subscribe({
        next: () => {
          if (this.claimCurtainOpened) {
            this.saveJob.emit({ fieldValue: this.rego.value, fieldName: 'rego' });
          }
        }
      });

    this.vin.valueChanges
      .pipe(debounceTime(750), distinctUntilChanged(), takeUntilDestroyed(this.#destroyRef))
      .subscribe({
        next: () => {
          if (this.vin.dirty && this.vin.valid) {
            this.saveJob.emit({ fieldValue: this.vin.value, fieldName: 'vin' });
          } else if (this.vin.invalid) {
            this.vin.markAsTouched();
          }
        }
      });

    this.bodyColour.valueChanges
      .pipe(debounceTime(750), distinctUntilChanged(), takeUntilDestroyed(this.#destroyRef))
      .subscribe({
        next: () => {
          if (this.claimCurtainOpened) {
            this.saveJob.emit({
              fieldValue: this.bodyColour.value,
              fieldName: 'bodyColour'
            });
          }
        }
      });

    this.odometer.valueChanges
      .pipe(debounceTime(750), distinctUntilChanged(), takeUntilDestroyed(this.#destroyRef))
      .subscribe({
        next: () => {
          if (this.odometer.dirty && this.odometer.valid) {
            this.updateJob('odometer');
          } else if (this.odometer.invalid) {
            this.odometer.markAsTouched();
          }
        }
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      !changes?.job?.isFirstChange() &&
      changes?.job?.previousValue &&
      changes?.job?.currentValue[this.savedJobStatus?.fieldName] ===
        changes?.job?.previousValue[this.savedJobStatus?.fieldName]
    ) {
      this.populateClaimValues();
      this.defineMandatoryFields();
    }

    if (this.savedJobStatus?.status === 'error') {
      this.claimForm.controls[this.savedJobStatus?.fieldName].setValue(this.job[this.savedJobStatus?.fieldName], {
        emitEvent: false
      });
    } else if (this.savedJobStatus?.status === 'success') {
      this.claimForm.controls[this.savedJobStatus?.fieldName]?.markAsPristine();
    }

    this.savedJobStatus = { status: null };

    if (changes.shouldCollapseCurtain && !changes.shouldCollapseCurtain.firstChange) {
      if (changes.shouldCollapseCurtain.currentValue) {
        this.panel.close();
      }
    }
  }

  openPdfReport(reportType: string) {
    this.userService
      .getReportRepairer(`/jobs/${this.job.jobId}/reports/${reportType}`)
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe({
        error: () => this.message.error('Could not retrieve the file.')
      });
  }

  confirmClearVMD() {
    const data = {
      title: 'Clear Vehicle Selection',
      alertMessage: 'This will clear the current vehicle selection.',
      confirmButton: 'CLEAR',
      hideCannotBeUndone: true
    };
    const dialogRef = this.matDialog.open(
      ConfirmBoxComponent,
      this.modalConfigsHelper.buildMediumModalConfig(data, smallModalConfig)
    );
    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === 'CLEAR') {
          this.clearVMD();
        }
      }
    });
  }

  clearVMD() {
    this.toolbarService.setCloudState('SAVING');
    this.userService.updateJob('', this.job.jobId, 'nvic').subscribe({
      next: () => this.toolbarService.setCloudState('RESTING'),
      error: () => {
        this.message.error('Could not clear vehicle selection.');
        this.toolbarService.setCloudState('RESTING');
      },
      complete: () => {
        this.message.confirm('Vehicle selection cleared.');
        this.vehicleDescription.markAsTouched();
        this.fetchJob.emit();
      }
    });
  }

  selectVehicle() {
    const { jobId, unlistedGlassVehicle, vehicleSelectedFromGlassGuide, makeId, modelId, vehicleYear, nvic } =
      this.job ?? {};
    const dialogConfig: MatDialogConfig = extraWideModalConfig;
    dialogConfig.data = {
      jobId,
      unlistedGlassVehicle,
      vehicleSelectedFromGlassGuide,
      makeId,
      modelId,
      vehicleYear,
      nvic
    };
    const dialogRef = this.matDialog.open(VehicleSelectionComponent, dialogConfig);
    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === 'Success') {
          this.fetchJob.emit();
        }
      }
    });
  }

  dateChange(fieldName: string) {
    this.claimForm.controls[fieldName].markAsTouched();
    if (this.claimForm.controls[fieldName].valid) {
      this.updateJob(fieldName);
    } else {
      this.disableSendButton.emit(true);
    }
  }

  updateJob(fieldName: string) {
    let fieldValue = this.claimForm.controls[fieldName].value;

    if (fieldName === 'inspectionDate' || fieldName === 'regoExpiryDate') {
      if (fieldValue === null) {
        fieldValue = '';
      } else {
        fieldValue = moment(fieldValue).format(EIO_DATE_BE_FORMAT);
      }
    } else if (fieldName === 'complianceDate') {
      if (fieldValue === null) {
        fieldValue = '';
      } else {
        fieldValue = moment(fieldValue).format(EIO_DATE_BE_FORMAT_MMYY);
      }
    }
    this.saveJob.emit({ fieldValue, fieldName });
  }

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.complianceDate.value
      ? moment(this.complianceDate.value)
      : moment(normalizedYear.year(), 'YYYY');
    ctrlValue.year(normalizedYear.year());
    this.complianceDate.setValue(ctrlValue);
    this.complianceDate.markAsDirty();
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = moment(this.complianceDate.value);
    ctrlValue.month(normalizedMonth.month());
    this.complianceDate.setValue(ctrlValue);
    datepicker.close();
  }

  populateClaimValues() {
    this.vehicleDescription.setValue(this.job.vehicleDescription);
    this.rego.setValue(this.job.rego ? this.job.rego.toUpperCase() : '');
    this.regoState.setValue(this.job.regoState);
    this.regoExpiryDate.setValue(this.job.regoExpiryDate);

    this.odometer.setValue(this.job.odometer || '');
    this.vin.setValue(this.job.vin ? this.job.vin.toUpperCase() : '');
    this.bodyColour.setValue(this.job.bodyColour);
    this.condition.setValue(this.job.condition);
    this.complianceDate.setValue(this.job?.complianceDate || '');
    this.vehicleValue.setValue(this.job?.vehicleValue || '');
    this.accidentDescription.setValue(this.job?.accidentDescription || '');
    this.incidentDate.setValue(
      this.job.incidentDate ? this.datePipe.transform(this.job?.incidentDate, this.dateDisplayFormat) : ''
    );
    this.repairerInstructions.setValue(this.job?.repairerInstructions || '');
    this.inspectionDate.setValue(
      this.job.inspectionDate ? this.datePipe.transform(this.job.inspectionDate, this.dateDisplayFormat) : ''
    );
    this.inspectionType.setValue(this.job?.inspectionType || '');
    this.assessmentCategory.setValue(this.job?.assessmentCategory || '');
    this.assessmentInstruction.setValue(this.job?.assessmentInstruction || '');
    this.accidentLocation.setValue(this.job?.accidentLocation || '');

    this.accessories.clear();
    this.job?.accessories?.forEach((accessory) => {
      const { description, value } = accessory;
      const accessoryControl = this.fb.group({
        description: new UntypedFormControl(description),
        value: new UntypedFormControl(value)
      });
      this.accessories.push(accessoryControl);
    });

    this.job.editable ? this.claimForm.enable() : this.claimForm.disable();

    this.job.cancelled ? this.claimForm.disable() : this.claimForm.enable();

    this.vehicleValue.disable();
    this.condition.disable();
    this.accidentDescription.disable();
    this.incidentDate.disable();
    this.repairerInstructions.disable();
    this.inspectionDate.disable();
    this.inspectionType.disable();
    this.assessmentCategory.disable();
    this.assessmentInstruction.disable();
    this.accidentLocation.disable();
    this.accessories.disable();
  }

  defineMandatoryFields() {
    this.mandatoryRules = [];
    if (this.job?.action && this.job?.action?.actionRules?.length > 0) {
      for (const action of this.job.action.actionRules) {
        this.mandatoryRules.push(action.comparedAttribute);
        if (action.comparedAttribute === 'vinNumber') {
          this.vinMandatory = true;
          this.vin.setValidators(Validators.required);
          this.vin.updateValueAndValidity();
        } else if (action.comparedAttribute === 'regoState') {
          this.regoStateMandatory = true;
          this.regoState.setValidators(Validators.required);
          this.regoState.updateValueAndValidity();
        } else if (action.comparedAttribute === 'odometer') {
          this.odometerMandatory = true;
          this.odometer.addValidators(Validators.required);
          this.odometer.updateValueAndValidity();
        } else if (action.comparedAttribute === 'vehicleFromGlass') {
          this.vehicleFromGlassMandatory = true;
          this.vehicleDescription.setValidators([
            Validators.required,
            genericVariableValidator(
              'mustBeSetFromGlassGuide',
              this.job?.vehicleSelectedFromGlassGuide,
              this.job?.unlistedGlassVehicle
            )
          ]);
          this.vehicleDescription.updateValueAndValidity();
          this.vehicleDescription.markAsTouched();
        }
      }
    }
  }
}
