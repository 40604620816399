import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyCardModule } from '@angular/material/legacy-card';
import { CurrentUserService } from '../../../services/currentUser.service';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule } from '@angular/material/legacy-menu';
import { UserRepairerSite } from '../../../model/user.model';
import { AccessRepairerSiteComponent } from '../../sysadmin-dashboard/access-repairer-site/access-repairer-site.component';
import { smallModalConfig } from '../../../consts/modal-config.const';
import { ModalConfigsHelper } from '../../../helpers/modal-configs.helper';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatLegacyCardModule,
    MatLegacyButtonModule,
    MatExpansionModule,
    MatIconModule,
    MatLegacyMenuModule
  ],
  selector: 'app-mladmin-repairer-sites',
  templateUrl: './mladmin-repairer-sites.component.html',
  styles: [
    `
      section {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        grid-gap: 20px;
        align-items: stretch;
      }
    `
  ]
})
export class MlAdminRepairerSitesComponent {
  currentUserService = inject(CurrentUserService);
  private modalConfigsHelper = inject(ModalConfigsHelper);
  private matDialog = inject(MatDialog);

  accessRepairSite(site: UserRepairerSite) {
    if (site) {
      const data = { repairerSiteId: site.id, repairerSiteName: site.companyName };
      this.matDialog.open(
        AccessRepairerSiteComponent,
        this.modalConfigsHelper.buildMediumModalConfig(data, smallModalConfig)
      );
    }
  }
}
