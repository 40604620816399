<form [formGroup]="form" (ngSubmit)="saveOrExecuteAction()" class="mt-10">
  <mat-form-field appearance="outline" class="primary-icon" color="accent">
    <mat-label>Filter</mat-label>
    <input formControlName="search" matInput (keydown.enter)="$event.preventDefault()" />
    <mat-icon matSuffix class="custom-mat-icon mat-icon-md mt-[8px]">filter_list</mat-icon>
  </mat-form-field>

  <table
    *ngIf="filteredCompanies().length; else noResultMessage"
    mat-table
    [dataSource]="dataSource"
    matSort
    matSortDisableClear
    class="table-style mt-0">
    <ng-container matColumnDef="selected">
      <th class="body-2" *matHeaderCellDef mat-header-cell></th>
      <td class="body-1 w-40" *matCellDef="let element" mat-cell>
        <mat-icon *ngIf="element.id === financeCompany.value?.id" class="text-pacific-blue">check_circle</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="companyName">
      <th *matHeaderCellDef class="body-2" mat-header-cell mat-sort-header>Company Name</th>
      <td *matCellDef="let item" class="body-1" mat-cell>{{ item.companyName }}</td>
    </ng-container>
    <ng-container matColumnDef="fullAddress">
      <th *matHeaderCellDef class="body-2" mat-header-cell mat-sort-header>Address</th>
      <td *matCellDef="let item" class="body-1" mat-cell>{{ item.fullAddress }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      [class.selected-row]="row.id === financeCompany.value?.id"
      (click)="selectFinanceCompany(row)"></tr>
  </table>

  <ng-template #noResultMessage>
    <section class="flex justify-center mb-20">
      <div class="block rounded border border-solid border-primary-500 py-10">
        <infos-guidance [guidanceTemplate]="noResult" warningGuidance />
        <ng-template #noResult>
          <div class="subtitle-2 text-primary-500">No results could be found. Please review your search.</div>
        </ng-template>
      </div>
    </section>
  </ng-template>
</form>
