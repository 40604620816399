import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'initials',
  standalone: true,
  pure: true
})
export class InitialsPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return '';
    }
    const names = value.split(' ');
    return (
      names.length >= 2 ? names[0].charAt(0) + names[names.length - 1].charAt(0) : names[0].charAt(0)
    ).toUpperCase();
  }
}
