import { inject, Injectable, signal, WritableSignal } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class JobService {
  private activeJobIdSubject: BehaviorSubject<number> = new BehaviorSubject<number>(
    +localStorage.getItem('activeJobId')
  );
  activeJobId$: Observable<number> = this.activeJobIdSubject.asObservable();
  displayWarningQuoteTab: WritableSignal<boolean> = signal(false);

  private http = inject(HttpClient);

  set activeJobId(id: number) {
    if (id) {
      localStorage.setItem('activeJobId', id.toString());
    }
    this.activeJobIdSubject.next(id);
  }

  get activeJobId() {
    return this.activeJobIdSubject.value;
  }

  private static handleError(error: any) {
    return Promise.reject(error.error || error.details || error);
  }

  changeUpdateStrategy(jobId: number, strategyName: 'REPLACE' | 'MERGE') {
    return this.http
      .post<null>(`ui/mainService/job/${jobId}/updateStrategy/${strategyName}`, {})
      .pipe(catchError(JobService.handleError));
  }
}
