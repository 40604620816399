import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GoogleMapsService {
  googleMapsLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(http: HttpClient) {
    const url = `https://maps.googleapis.com/maps/api/js?channel=EIOnline.${environment.ENVIRONMENT_NAME}.ui.map&libraries=places&language=en-AU&key=AIzaSyDl2lq2bEZD31eFeyGp4KQYDNpwyH5Id3Q`;
    http.jsonp(url, 'callback').subscribe(() => this.googleMapsLoaded.next(true));
  }
}
