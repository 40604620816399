import { Pipe, PipeTransform } from '@angular/core';
import { UntypedFormArray, UntypedFormControl } from '@angular/forms';

@Pipe({
  name: 'getControl'
})
export class GetControlPipe implements PipeTransform {
  transform(itemList: UntypedFormArray, itemId: number) {
    const control = itemList.controls.find((ctrl) => ctrl.get('id').value === itemId) as UntypedFormControl;
    if (control) {
      return control.get('invoicedAmount') as UntypedFormControl;
    }
  }
}
