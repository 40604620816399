<div class="header-container">
  <h3>Administrators</h3>
  <div class="header-container-actions">
    <button (click)="addSysAdmin()" mat-raised-button color="accent">ADD SYSADMIN</button>
    <mat-divider class="header-container-actions-divider" [vertical]="true"></mat-divider>
    <button mat-icon-button (click)="administratorsExpanded = !administratorsExpanded">
      <mat-icon>{{ administratorsExpanded ? 'expand_less' : 'expand_more' }}</mat-icon>
    </button>
  </div>
</div>
<mat-divider></mat-divider>
<div *ngIf="sysadminList?.length" [class.hidden]="!administratorsExpanded" class="cards-container">
  <div *ngFor="let sysadmin of sysadminList" class="admin-container">
    <mat-card class="sysadmin-card" (click)="addSysAdmin(sysadmin); $event.stopPropagation()">
      <div class="sysadmin-line body-2">
        <div class="sysadmin body-2">SYSADMIN</div>
      </div>
      <div class="sysadmin-line">
        <div>
          <div class="subtitle-1">{{ sysadmin.firstName }} {{ sysadmin.lastName }}</div>
          <div class="body-2">
            {{ sysadmin.emailAddress }}
          </div>
          <div class="body-2 username">
            {{ sysadmin.username }}
          </div>
        </div>
        <div class="secondary-icon">
          <button mat-icon-button (click)="$event.stopPropagation()" [matMenuTriggerFor]="enableDisableMenu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #enableDisableMenu="matMenu" xPosition="before" class="kebab-menu sysadmin-kebab">
            <button
              *ngIf="(sysadmin.status === 'ENABLED' || sysadmin.status === 'SUSPENDED') && sysadmin.isActivated"
              mat-menu-item
              (click)="enableDisableSysAdmin(sysadmin, false); $event.stopPropagation()">
              <span>Disable User</span>
            </button>
            <button
              *ngIf="sysadmin.isActivated && (sysadmin.status === 'DISABLED' || sysadmin.status === 'SUSPENDED')"
              mat-menu-item
              (click)="enableDisableSysAdmin(sysadmin, true); $event.stopPropagation()">
              <span>Enable User</span>
            </button>
            <button
              mat-menu-item
              *ngIf="sysadmin.isActivated || sysadmin.status === 'DISABLED'"
              (click)="confirmArchiveSysAdmin(sysadmin.username); $event.stopPropagation()">
              <span>Archive User</span>
            </button>
            <button
              mat-menu-item
              *ngIf="!sysadmin.isActivated"
              (click)="confirmDeleteInvitedSysAdmin(sysadmin.username); $event.stopPropagation()">
              <span>Delete</span>
            </button>
          </mat-menu>
        </div>
      </div>
      <div
        class="sysadmin"
        [appHighlightStatus]="sysadmin.isActivated ? sysadmin?.status : 'INVITED'"
        [ngClass]="{
          status: sysadmin.isActivated && sysadmin.status === 'ENABLED',
          invited: !(sysadmin.isActivated && sysadmin.status === 'ENABLED')
        }">
        {{
          sysadmin.isActivated ? (sysadmin.status === 'ENABLED' ? 'Active' : (sysadmin.status | titlecase)) : 'Invited'
        }}
      </div>
    </mat-card>
  </div>
</div>
