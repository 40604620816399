<div class="dialog-header" mat-dialog-title>
  <h6>{{ repairerUserName ? 'Sharing ' + repairerUserName + "'s screen" : 'Sharing screen' }}</h6>
  <button mat-raised-button aria-label="Close" mat-dialog-close color="primary" (click)="stoppingScreenShare()">
    <mat-icon>close</mat-icon>
    STOP SHARING SCREEN
  </button>
</div>
<mat-divider></mat-divider>
<div mat-dialog-content>
  <div class="progress-bar" *ngIf="waitingForScreenShare() && !screenSharePaused()">
    <mat-icon>screen_share</mat-icon>
    <h5>Request Screen Sharing</h5>
    <div class="w-[300px] inline-block">
      <mat-progress-bar mode="buffer"/>
      <div class="body-2 mt-10" *ngIf="cobrowsingUsers().length; else noUsers">
        <div *ngIf="!waitingRepUserConfirmation(); else waingUserConfirmation">
          <span class="bold">{{ cobrowsingUsers().length | i18nPlural: cobrowsingUsersPluralMapping }} online,</span>
          please select who you would like to share your screen with.
        </div>
        <ng-template #waingUserConfirmation>
          Waiting for <span class="bold">{{ selectedCobrowsingUser() }}</span> to confirm share screen.
        </ng-template>
      </div>
      <ng-template #noUsers>
        <div class="body-2 mt-10">There are no users online, please contact the repairer.</div>
      </ng-template>
    </div>
    <cobrowsing-user *ngIf="!waitingRepUserConfirmation()"
                     (sendRequestShare)="subscribeToScreenShare($event)"
                     [cobrowsingUsers]="cobrowsingUsers()"/>
  </div>
  <div class="paused-screen" *ngIf="screenSharePaused() && !waitingForScreenShare()">
    <mat-icon svgIcon="sharing_paused"/>
    <h5>Screen sharing paused</h5>
    <mat-progress-bar mode="buffer"/>
    <div class="waiting-message body-2">
      <span>The shared screen is no longer actively displayed</span>
    </div>
  </div>
  <video class="host" [class.hide-host]="waitingForScreenShare() || screenSharePaused()" playsinline autoplay #host>
    Sorry, your browser doesn't support embedded videos.
  </video>
</div>
