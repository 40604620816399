import {
  booleanAttribute,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { DocumentItem, LabourItem, PartItem, Quote, SubletItem } from '../../../model/document.model';
import { EIO_DATE_BE_FORMAT, EIO_DATE_UI_FORMAT_PIPE } from '../../../consts/localization.const';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { ToolbarService } from '../../../services/toolbar.service';
import { UserService } from '../../../services/user.service';
import { MessagingService } from '../../../components/messaging/messaging.service';
import { ConfirmBoxComponent } from '../../../components/confirm-box/confirm-box.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DateInputHelper } from '../../../helpers/date-input.helper';
import { CurrentUserService } from '../../../services/currentUser.service';
import { DecimalPipe } from '@angular/common';
import { LABOUR_TYPE } from '../../../consts/labourType.const';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { PART_SOURCE } from '../../../consts/partSource.const';
import { MatLegacyTable as MatTable } from '@angular/material/legacy-table';
import { MatExpansionPanel } from '@angular/material/expansion';
import { BreakpointObserverHelper } from '../../../helpers/breakpoint-observer.helper';
import {
  quoteCurtainPartColumnHeadersLargeScreen,
  quoteCurtainPartColumnHeadersSmallScreen
} from '../../../consts/columnHeaders.const';
import { smallModalConfig } from '../../../consts/modal-config.const';
import { ModalConfigsHelper } from '../../../helpers/modal-configs.helper';
import { JobService } from '../../../services/job.service';

@Component({
  selector: 'app-quote-curtain',
  templateUrl: './quote-curtain.component.html',
  styleUrls: ['./quote-curtain.component.scss'],
  providers: [DecimalPipe]
})
export class QuoteCurtainComponent implements OnInit, OnChanges, OnDestroy {
  @Input() document: DocumentItem;
  @Input() isSentToInsurerTray = false;
  @Input() shouldCollapseCurtain: boolean;
  @Input() editable: boolean;
  @Input() quotingSystemName: string;
  @Input() previousQuote = false;
  @Input() canBeReplacedOrMerged = false;
  @Input() updateStrategy: 'REPLACE' | 'MERGE' = null;
  @Input({ transform: booleanAttribute }) showTotals = false;
  @Output() refreshJobViewer: EventEmitter<any> = new EventEmitter();
  @Output() disableSendButton: EventEmitter<boolean> = new EventEmitter();
  @Output() validateQuote: EventEmitter<any> = new EventEmitter();
  @ViewChildren('labourDescriptionsRef') labourDescriptionsRef: QueryList<ElementRef>;
  @ViewChildren('subletDescriptionsRef') subletDescriptionsRef: QueryList<ElementRef>;
  @ViewChild('partTable') partTable: MatTable<any>;
  @ViewChild('subletTable') subletTable: MatTable<any>;
  @ViewChild('panel', { static: false }) panel: MatExpansionPanel;

  timezone: string;
  quote: Quote;
  labourColumnHeaders = ['description', 'note', 'estimateHours', 'assessedHours', 'estimateAmount', 'assessedAmount'];
  labourColumnFooters = ['total', 'estimateHours', 'assessedHours', 'estimateAmount', 'assessedAmount'];
  partColumnHeaders = [
    'partDescriptionNumber',
    'partSource',
    'assessorNote',
    'quantity',
    'estimateAmount',
    'estimateMarkup',
    'assessedAmount',
    'assessedMarkup'
  ];
  partColumnFooters = ['total', 'estimateAmount', 'estimateMarkup', 'assessedAmount', 'assessedMarkup'];
  subletsColumnHeaders = ['description', 'note', 'estimateAmount', 'empty', 'assessedAmount'];
  subletsColumnFooters = ['total', 'estimateAmount', 'empty', 'assessedAmount'];

  quoteForm: UntypedFormGroup;
  displayDays: boolean;
  quoteCurtainOpened = false;
  dateDisplayFormat = EIO_DATE_UI_FORMAT_PIPE;
  isLargeScreen = false;
  Math = Math;
  private unsubscribe = new Subject();
  partSourceOptions = PART_SOURCE;

  private confirmBox = inject(MatDialog);
  private fb = inject(UntypedFormBuilder);
  private ref = inject(ChangeDetectorRef);
  jobService = inject(JobService);
  private currentUserService = inject(CurrentUserService);
  private message = inject(MessagingService);
  private toolbarService = inject(ToolbarService);
  private userService = inject(UserService);
  dateInputHelper = inject(DateInputHelper);
  private breakpointObserver = inject(BreakpointObserverHelper);
  private modalConfigsHelper = inject(ModalConfigsHelper);
  private decPipe = inject(DecimalPipe);

  get estimator() {
    return this.quoteForm.get('estimator');
  }
  get quoteDate() {
    return this.quoteForm.get('quoteDate');
  }
  get estimatedStartDate() {
    return this.quoteForm.get('estimatedStartDate');
  }
  get estimatedDuration() {
    return this.quoteForm.get('estimatedDuration');
  }
  get partItems() {
    return this.quoteForm.get('partItems') as UntypedFormArray;
  }
  get subletItems() {
    return this.quoteForm.get('subletItems') as UntypedFormArray;
  }
  get labourSections() {
    return this.quoteForm.get('labourSections') as UntypedFormArray;
  }

  ngOnInit(): void {
    this.timezone = this.currentUserService?.timeZone;
    this.quote = this.document.document as Quote;

    this.displayDays = !!this.quote.estimatedDuration;
    this.breakpointObserver
      .observeMinWidth()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (result) => {
          this.isLargeScreen = result;
          this.partColumnHeaders = this.isLargeScreen
            ? quoteCurtainPartColumnHeadersLargeScreen
            : quoteCurtainPartColumnHeadersSmallScreen;
        }
      });

    this.quoteForm = this.fb.group({
      estimator: [{ value: this.quote.estimator, disabled: true }],
      quoteDate: [{ value: this.quote.quoteDate, disabled: true }],
      estimatedStartDate: [{ value: this.quote.estimatedStartDate, disabled: true }],
      estimatedDuration: [{ value: this.quote.estimatedDuration, disabled: true }, Validators.pattern('^\\d*$')],
      partItems: new UntypedFormArray([]),
      subletItems: new UntypedFormArray([]),
      labourSections: new UntypedFormArray([])
    });

    if (this.editable) {
      this.quoteForm.enable();
    }

    this.populateLabourItems();
    this.populateSubletItems();
    this.populatePartItems();

    this.estimator.valueChanges
      .pipe(debounceTime(1500), distinctUntilChanged(), takeUntil(this.unsubscribe))
      .subscribe(() => {
        if (this.quoteCurtainOpened) {
          if (this.estimator.invalid) {
            this.estimator.markAsTouched();
          } else {
            this.update('estimator');
          }
        }
      });

    this.quoteDate.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      if (this.quoteCurtainOpened) {
        if (this.quoteDate.invalid) {
          this.quoteDate.markAsTouched();
          this.disableSendButton.emit(true);
        } else {
          this.update('quoteDate');
        }
      }
    });

    this.estimatedStartDate.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      if (this.quoteCurtainOpened) {
        if (this.estimatedStartDate.invalid) {
          this.estimatedStartDate.markAsTouched();
          this.disableSendButton.emit(true);
        } else {
          this.update('estimatedStartDate');
        }
      }
    });

    this.estimatedDuration.valueChanges
      .pipe(debounceTime(1500), distinctUntilChanged(), takeUntil(this.unsubscribe))
      .subscribe(() => {
        if (this.quoteCurtainOpened) {
          if (this.estimatedDuration.invalid) {
            this.estimatedDuration.markAsTouched();
            this.disableSendButton.emit(true);
          } else {
            this.update('estimatedDuration');
          }
        }
      });

    if (this.quote.manual && !this.showTotals) {
      !this.labourColumnHeaders.includes('delete') && this.labourColumnHeaders.push('delete');
      !this.partColumnHeaders.includes('delete') && this.partColumnHeaders.push('delete');
      !this.subletsColumnHeaders.includes('delete') && this.subletsColumnHeaders.push('delete');
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.document && !changes.document.firstChange) {
      this.quote = this.document.document as Quote;
      this.populateQuoteValues();
    }
    if (changes.shouldCollapseCurtain && !changes.shouldCollapseCurtain.firstChange) {
      if (changes.shouldCollapseCurtain.currentValue) {
        this.panel.close();
      }
    }
    if (changes.updateStrategy?.currentValue === 'MERGE' && !changes.updateStrategy.firstChange) {
      this.labourSections.clear();
      this.subletItems.clear();
      this.partItems.clear();
      this.populateLabourItems();
      this.populateSubletItems();
      this.populatePartItems();
    }
  }

  private populateQuoteValues() {
    this.estimator.setValue(this.quote.estimator, { emitEvent: false });
    this.quoteDate.setValue(this.quote.quoteDate, { emitEvent: false });
    this.estimatedStartDate.setValue(this.quote.estimatedStartDate, { emitEvent: false });
    this.estimatedDuration.setValue(this.quote.estimatedDuration, { emitEvent: false });
  }

  private populateLabourItems() {
    this.quote.labourSections.forEach((labour) => {
      if (this.quote.manual && !this.quote.submitted) {
        labour.items.push(this.newLineLaboursItem());
      }

      let labourItemsGroup = labour.items;
      if (this.updateStrategy === 'MERGE') {
        labourItemsGroup = this.regroupSameDescriptionItem(labourItemsGroup);
      }

      let labourControls: any = {};
      labourControls = {
        estimateRate: new UntypedFormControl(labour.estimateRate?.toFixed(2), [
          Validators.pattern('\\-?\\d*\\.?\\d{1,2}')
        ]),
        items: this.fb.array(
          labourItemsGroup.map((i) =>
            this.fb.group({
              id: new UntypedFormControl(i.id),
              description: new UntypedFormControl(i.description, Validators.required),
              assessorNote: new UntypedFormControl(i.assessorNote),
              estimateHours: new UntypedFormControl(
                {
                  value: labour.estimateRate === 0 && i.id ? 0 : i.estimateHours?.toFixed(2),
                  disabled: labour.estimateRate === 0
                },
                Validators.pattern('\\-?\\d*\\.?\\d{1,2}')
              ),
              estimateAmount: new UntypedFormControl(i.estimateAmount?.toFixed(2), [
                Validators.required,
                Validators.pattern('\\-?\\d*\\.?\\d{1,2}')
              ]),
              assessedAmount: new UntypedFormControl(i.assessedAmount),
              assessedHours: new UntypedFormControl(i.assessedHours),
              assessedStatus: new UntypedFormControl(i.assessedStatus),
              addedInV6: new UntypedFormControl(i.addedInV6),
              amountToHours: new UntypedFormControl(labour.estimateRate === 0),
              mergedFromReUpload: new UntypedFormControl(i.mergedFromReUpload)
            })
          )
        ),
        labourType: new UntypedFormControl(labour.labourType),
        groupName: new UntypedFormControl(labour.groupName),
        totalEstimateAmount: new UntypedFormControl(+labour.totalEstimateAmount),
        totalEstimateHours: new UntypedFormControl(+labour.totalEstimateHours),
        assessedRate: new UntypedFormControl(+labour.assessedRate),
        totalAssessedHours: new UntypedFormControl(+labour.totalAssessedHours),
        totalAssessedAmount: new UntypedFormControl(+labour.totalAssessedAmount)
      };

      if (!this.quote.manual) {
        labourControls.groupName = labour.groupName;
      }
      const labourControl = this.fb.group(labourControls);
      this.labourSections.push(labourControl);
    });
  }

  private populatePartItems() {
    if (this.quote.manual && !this.quote.submitted) {
      this.quote.parts.push(this.newLineParts());
    }
    let partsGroup = this.quote.parts;
    if (this.updateStrategy === 'MERGE') {
      partsGroup = this.regroupSameDescriptionItem(partsGroup);
    }

    partsGroup.forEach((part) => {
      let partControl: any = {};
      partControl = {
        id: new UntypedFormControl(part.id),
        description: new UntypedFormControl(part.description, [Validators.required]),
        partNumber: new UntypedFormControl(part.partNumber),
        partSource: new UntypedFormControl(part.partSource),
        assessorNote: new UntypedFormControl(part.assessorNote),
        quantity: new UntypedFormControl(part.quantity, Validators.min(1)),
        estimateAmount: new UntypedFormControl(this.decPipe.transform(part.estimateAmount, '1.2-2'), [
          Validators.required,
          Validators.pattern('\\-?\\d*\\.?\\d{1,2}')
        ]),
        estimateMarkup: new UntypedFormControl(part.estimateMarkup),
        addedInV6: new UntypedFormControl(part.addedInV6),
        assessedAmount: new UntypedFormControl(part.assessedAmount),
        assessedStatus: new UntypedFormControl(part.assessedStatus),
        assessedMarkupPercent: new UntypedFormControl(part.assessedMarkupPercent),
        mergedFromReUpload: new UntypedFormControl(part.mergedFromReUpload)
      };
      this.partItems.push(this.fb.group(partControl));
    });
  }

  private populateSubletItems() {
    if (this.quote.manual && !this.quote.submitted) {
      this.quote.sublets.push(this.newLineSublets());
    }

    let subletsGroup = this.quote.sublets;
    if (this.updateStrategy === 'MERGE') {
      subletsGroup = this.regroupSameDescriptionItem(subletsGroup);
    }

    subletsGroup.forEach((sublet) => {
      let subletControl: any = {};
      subletControl = {
        id: new UntypedFormControl(sublet.id),
        description: new UntypedFormControl(sublet.description, [Validators.required]),
        assessorNote: new UntypedFormControl(sublet.assessorNote),
        estimateAmount: new UntypedFormControl(this.decPipe.transform(sublet.estimateAmount, '1.2-2'), [
          Validators.required,
          Validators.pattern('\\-?\\d*\\.?\\d{1,2}')
        ]),
        assessedAmount: new UntypedFormControl(sublet.assessedAmount),
        assessedStatus: new UntypedFormControl(sublet.assessedStatus),
        addedInV6: new UntypedFormControl(sublet.addedInV6),
        mergedFromReUpload: new UntypedFormControl(sublet.mergedFromReUpload)
      };

      this.subletItems.push(this.fb.group(subletControl));
    });
  }

  // Groups items by their 'description' property while preserving the general order.
  private regroupSameDescriptionItem<T extends { description?: string }>(items: T[]): T[] {
    if (!items || items.length === 0) {
      return [];
    }

    const groupedAndFlattenedItems = items.reduce((flattened, item) => {
      const existingGroup = flattened.find((group) => group[0]?.description === item?.description);

      if (existingGroup) {
        existingGroup.push(item);
      } else {
        flattened.push([item]);
      }

      return flattened;
    }, []);

    return groupedAndFlattenedItems.reduce((flattened, group) => flattened.concat(group), []);
  }

  addOrEditLineLaboursItem(sectionIndex: number, itemIndex: number) {
    if (
      this.labourSections.value[sectionIndex].items[itemIndex].description &&
      this.labourSections.value[sectionIndex].items[itemIndex].estimateAmount !== null &&
      this.labourSections.value[sectionIndex].items[itemIndex].estimateAmount !== ''
    ) {
      const labourItem: LabourItem = {
        id: this.labourSections.value[sectionIndex].items[itemIndex].id,
        description: this.labourSections.value[sectionIndex].items[itemIndex].description,
        estimateHours:
          +this.labourSections.value[sectionIndex].estimateRate !== 0
            ? +this.labourSections.value[sectionIndex].items[itemIndex].estimateHours.toString().replace(',', '')
            : 0,
        estimateAmount: +this.labourSections.value[sectionIndex].items[itemIndex].estimateAmount
          .toString()
          .replace(',', ''),
        amountToHours: this.labourSections.value[sectionIndex].items[itemIndex].amountToHours
      };

      this.toolbarService.setCloudState('SAVING');
      this.userService
        .addOrEditLabourItem(this.quote.id, LABOUR_TYPE[this.labourSections.value[sectionIndex].labourType], labourItem)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe({
          next: (data: Quote) => {
            this.quote.labourSections = data.labourSections;
            this.updateTotals(data);
            this.labourItem(sectionIndex)
              .controls[itemIndex].get('id')
              .patchValue(data.labourSections[sectionIndex].items[itemIndex].id);
            this.labourItem(sectionIndex).controls[itemIndex].get('estimateAmount').reset();
            this.labourItem(sectionIndex)
              .controls[itemIndex].get('estimateAmount')
              .patchValue(data.labourSections[sectionIndex].items[itemIndex].estimateAmount?.toFixed(2));
            this.ref.detectChanges();
            if (!this.labourItem(sectionIndex).controls.some((item) => item.value.id === null)) {
              const labourControls = this.labourSections.controls[sectionIndex];
              const newLabourControl = this.fb.group(this.newLineLaboursItem());
              newLabourControl.get('estimateHours').setValidators(Validators.pattern('\\-?\\d*\\.?\\d{1,2}'));
              newLabourControl.get('description').setValidators(Validators.required);
              newLabourControl
                .get('estimateAmount')
                .setValidators([Validators.required, Validators.pattern('\\-?\\d*\\.?\\d{1,2}')]);
              if (data.labourSections[sectionIndex].estimateRate === 0) {
                newLabourControl.get('estimateHours').disable();
              }
              (labourControls.get('items') as UntypedFormArray).push(newLabourControl);
              setTimeout(() => {
                this.labourDescriptionsRef.forEach((description) => {
                  if (
                    description.nativeElement.id ===
                    `description-${sectionIndex}${labourControls.get('items').value.length - 1}`
                  ) {
                    description.nativeElement.focus();
                  }
                });
              }, 10);
            }
            this.validateQuote.emit();
          },
          error: () => {
            this.message.error(
              `Could not ${this.labourSections.value[sectionIndex].items[itemIndex].id ? 'edit' : 'add'} labour item.`
            );
            this.toolbarService.setCloudState('RESTING');
          },
          complete: () => {
            this.toolbarService.setCloudState('RESTING');
          }
        });
    }
  }

  addOrEditLineSublets(sectionIndex: number) {
    this.subletItems.controls[sectionIndex]
      .get('estimateAmount')
      .patchValue(this.decPipe.transform(this.subletItems.value[sectionIndex].estimateAmount, '1.2-2'));
    this.subletItems.controls[sectionIndex].get('estimateAmount').setErrors(null);
    if (
      this.subletItems.value[sectionIndex].description &&
      this.subletItems.value[sectionIndex].estimateAmount !== null &&
      this.subletItems.value[sectionIndex].estimateAmount !== ''
    ) {
      const subletItem: SubletItem = {
        id: this.subletItems.value[sectionIndex].id,
        description: this.subletItems.value[sectionIndex].description,
        estimateAmount: +this.subletItems.value[sectionIndex].estimateAmount.toString().replace(',', ''),
        assessedStatus: this.subletItems.value[sectionIndex].assessedStatus,
        addedInV6: this.subletItems.value[sectionIndex].addedInV6
      };

      this.toolbarService.setCloudState('SAVING');
      this.userService
        .addOrEditPartOrSubletItem(this.quote.id, 'subletItem', subletItem)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe({
          next: (data: Quote) => {
            this.quote.sublets = data.sublets;
            this.updateTotals(data);
            this.subletItems.controls[sectionIndex].get('id').patchValue(data.sublets[sectionIndex].id);
            if (!this.subletItems.controls.some((item) => item.value.id === null)) {
              const newSubletsControl = this.fb.group(this.newLineSublets());
              newSubletsControl.get('description').setValidators(Validators.required);
              newSubletsControl
                .get('estimateAmount')
                .setValidators([Validators.required, Validators.pattern('\\-?\\d*\\.?\\d{1,2}')]);
              this.subletItems.push(newSubletsControl);
              setTimeout(() => {
                this.subletDescriptionsRef.forEach((description) => {
                  if (description.nativeElement.id === `descriptionSublet-${this.subletItems.value.length - 1}`) {
                    description.nativeElement.focus();
                  }
                });
              }, 10);
            }
            this.ref.detectChanges();
            this.validateQuote.emit();
          },
          error: () => {
            this.message.error(`Could not ${this.subletItems.value[sectionIndex].id ? 'edit' : 'add'} sublet item.`);
            this.toolbarService.setCloudState('RESTING');
          },
          complete: () => {
            this.toolbarService.setCloudState('RESTING');
          }
        });
    }
  }

  addOrEditLineParts(sectionIndex: number) {
    this.partItems.controls[sectionIndex]
      .get('estimateAmount')
      .patchValue(this.decPipe.transform(this.partItems.value[sectionIndex].estimateAmount, '1.2-2'));
    this.partItems.controls[sectionIndex].get('estimateAmount').setErrors(null);

    if (this.partItems.value[sectionIndex].quantity !== null) {
      this.partItems.controls[sectionIndex]
        .get('quantity')
        .patchValue(Math.floor(this.partItems.value[sectionIndex].quantity));
    }

    if (
      this.partItems.value[sectionIndex].quantity > 0 &&
      this.partItems.value[sectionIndex].description &&
      this.partItems.value[sectionIndex].estimateAmount !== null &&
      this.partItems.value[sectionIndex].estimateAmount !== ''
    ) {
      const partItem: PartItem = {
        id: this.partItems.value[sectionIndex].id,
        description: this.partItems.value[sectionIndex].description,
        estimateAmount: +this.partItems.value[sectionIndex].estimateAmount.toString().replace(',', ''),
        partNumber: this.partItems.value[sectionIndex].partNumber,
        quantity: this.partItems.value[sectionIndex].quantity,
        partSource: this.partItems.value[sectionIndex].partSource,
        estimateMarkup: this.partItems.value[sectionIndex].estimateMarkup
      };

      this.toolbarService.setCloudState('SAVING');
      this.userService
        .addOrEditPartOrSubletItem(this.quote.id, 'partItem', partItem)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe({
          next: (data: Quote) => {
            this.quote.parts = data.parts;
            this.updateTotals(data);
            this.partItems.controls[sectionIndex].get('id').patchValue(data.parts[sectionIndex].id);
            if (!this.partItems.controls.some((item) => item.value.id === null)) {
              const newPartsControl = this.fb.group(this.newLineParts());
              newPartsControl.get('description').setValidators(Validators.required);
              newPartsControl
                .get('estimateAmount')
                .setValidators([Validators.required, Validators.pattern('\\-?\\d*\\.?\\d{1,2}')]);
              this.partItems.push(newPartsControl);
            }
            this.ref.detectChanges();
            this.validateQuote.emit();
          },
          error: () => {
            this.message.error(`Could not ${this.partItems.value[sectionIndex].id ? 'edit' : 'add'} part item.`);
            this.toolbarService.setCloudState('RESTING');
          },
          complete: () => {
            this.toolbarService.setCloudState('RESTING');
          }
        });
    } else if (this.partItems.value[sectionIndex].quantity <= 0) {
      this.disableSendButton.emit(true);
      this.validateQuote.emit();
    }
  }

  setEstimatedRate(sectionIndex: number, estimatedRate: any) {
    const estimateRateControl = this.labourSections.controls[sectionIndex].get('estimateRate');
    if (estimatedRate !== '' && estimateRateControl.errors?.pattern) {
      estimatedRate = 0;
    }
    estimateRateControl.setValue((+estimatedRate)?.toFixed(2));
    estimateRateControl.setErrors(null);
    this.quoteForm.updateValueAndValidity();
    this.toolbarService.setCloudState('SAVING');
    this.userService
      .setEstimatedRate(this.quote.id, LABOUR_TYPE[this.labourSections.value[sectionIndex].labourType], +estimatedRate)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (data: Quote) => {
          if (+estimatedRate === 0) {
            this.labourItem(sectionIndex).controls.map((i) => i.get('id').value && i.get('estimateHours').setValue(0));
            this.labourItem(sectionIndex).controls.filter((i) => i.get('estimateHours').disable({ emitEvent: false }));
            this.labourItem(sectionIndex).controls.map((i) => i.get('amountToHours').setValue(true));
          } else {
            this.labourItem(sectionIndex).controls.filter((i) => i.get('estimateHours').enable({ emitEvent: false }));
          }

          data.labourSections[sectionIndex].items.forEach((labourItem: LabourItem) => {
            this.labourItem(sectionIndex).controls.forEach((row) => {
              this.quote.labourSections[sectionIndex].estimateRate = data.labourSections[sectionIndex].estimateRate;
              if (row.get('id').value && row.get('id').value === labourItem.id) {
                row.get('estimateAmount').patchValue((+labourItem.estimateAmount)?.toFixed(2), { emitEvent: false });
                row.get('estimateHours').patchValue((+labourItem.estimateHours)?.toFixed(2), { emitEvent: false });
              }
            });
          });

          this.updateTotals(data);
          this.ref.detectChanges();
          this.validateQuote.emit();
        },
        error: () => {
          this.message.error(`Could not edit estimated rate value.`);
          this.toolbarService.setCloudState('RESTING');
        },
        complete: () => {
          this.toolbarService.setCloudState('RESTING');
        }
      });
  }

  labourItem(i: number) {
    return this.labourSections.controls[i].get('items') as UntypedFormArray;
  }

  updateEstimateHours(sectionIndex: number, itemIndex: number, estimateHoursValue: any) {
    const estimateHoursControl = this.labourItem(sectionIndex).controls[itemIndex];
    if (estimateHoursControl.get('estimateHours').errors?.pattern) {
      estimateHoursValue = 0;
    }
    this.labourItem(sectionIndex)
      .controls[itemIndex].get('estimateHours')
      .setValue((+estimateHoursValue)?.toFixed(2));
    this.labourItem(sectionIndex).controls[itemIndex].get('estimateHours').setErrors(null);
    this.labourItem(sectionIndex).controls.map((i) => i.get('amountToHours').setValue(false));
    const rate = +this.labourSections.controls[sectionIndex].get('estimateRate').value.toString().replace(',', '');
    if (rate !== 0) {
      const estimateAmount = +estimateHoursValue * rate;
      this.labourItem(sectionIndex)
        .controls[itemIndex].get('estimateAmount')
        .patchValue(estimateAmount.toFixed(2), { emitEvent: false });
    }
  }

  updateEstimateAmount(sectionIndex: number, itemIndex: number, estimateAmountValue: any) {
    const estimateAmountControl = this.labourItem(sectionIndex).controls[itemIndex];
    if (estimateAmountControl.get('estimateAmount').errors) {
      estimateAmountValue = 0;
    }

    this.labourItem(sectionIndex).controls[itemIndex].get('estimateAmount').setValue((+estimateAmountValue).toFixed(2));
    this.labourItem(sectionIndex).controls[itemIndex].get('estimateAmount').setErrors(null);
    this.labourItem(sectionIndex).controls.map((i) => i.get('amountToHours').setValue(true));
    const rate = +this.labourSections.controls[sectionIndex].get('estimateRate').value.toString().replace(',', '');
    if (rate !== 0) {
      const estimateHours = +estimateAmountValue / rate;
      this.labourItem(sectionIndex)
        .controls[itemIndex].get('estimateHours')
        .patchValue(estimateHours.toFixed(2), { emitEvent: false });
    }
  }

  deleteItem(id: number, index: number, section: string, i?: number) {
    this.toolbarService.setCloudState('SAVING');
    this.userService
      .deleteItem(id, section)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (data) => {
          if (i !== null) {
            this.labourItem(i).removeAt(index);
            this.quote.labourSections = data.labourSections;
          } else {
            if (section === 'partItem') {
              this.partItems.removeAt(index);
              this.quote.parts = data.parts;
              this.partTable.dataSource = this.partItems.value;
              this.partTable.renderRows();
            } else {
              this.subletItems.removeAt(index);
              this.quote.sublets = data.sublets;
              this.subletTable.dataSource = this.subletItems.value;
              this.subletTable.renderRows();
            }
          }
          this.updateTotals(data);
          this.ref.detectChanges();
        },
        error: () => {
          this.message.error(`Could not delete row.`);
          this.toolbarService.setCloudState('RESTING');
        },
        complete: () => {
          this.toolbarService.setCloudState('RESTING');
        }
      });
  }

  private updateTotals(data: any) {
    this.quote.estLabourTotal = data.estLabourTotal;
    this.quote.estPartsTotal = data.estPartsTotal;
    this.quote.estSubletTotal = data.estSubletTotal;
    this.quote.estSubTotal = data.estSubTotal;
    this.quote.estGSTTotal = data.estGSTTotal;
    this.quote.estTotal = data.estTotal;
    if (data.estTotal <= 0 || this.partItems.controls.some((control) => control.get('quantity').value <= 0)) {
      this.disableSendButton.emit(true);
    } else if (this.quoteDate.valid && this.estimatedStartDate.valid && this.estimatedDuration.valid) {
      this.disableSendButton.emit(false);
    }
    this.validateQuote.emit();
  }

  private update(fieldName: string) {
    let fieldValue = this.quoteForm.controls[fieldName].value;

    if (fieldName === 'quoteDate' || fieldName === 'estimatedStartDate') {
      if (!fieldValue) {
        fieldValue = '';
      } else {
        fieldValue = moment(fieldValue).format(EIO_DATE_BE_FORMAT);
      }
    } else if (fieldName === 'estimatedDuration') {
      this.displayDays = fieldValue !== null;
    }

    this.toolbarService.setCloudState('SAVING');
    this.userService
      .updateQuote(fieldValue, this.quote.id, fieldName)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: () => this.toolbarService.setCloudState('RESTING'),
        error: () => {
          this.message.error('Could not update field.');
          this.toolbarService.setCloudState('RESTING');
        },
        complete: () => {
          this.disableSendButton.emit(false);
          this.refreshJobViewer.emit();
        }
      });
  }

  confirmDeleteQuote() {
    const data = { title: 'Delete Quote', alertMessage: 'Your quote will be deleted.', confirmButton: 'DELETE' };
    const dialogRef = this.confirmBox.open(
      ConfirmBoxComponent,
      this.modalConfigsHelper.buildMediumModalConfig(data, smallModalConfig)
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'DELETE') {
        this.deleteQuote();
      }
    });
  }

  private deleteQuote() {
    this.toolbarService.setCloudState('SAVING');
    this.userService
      .deleteQuote(this.quote.id)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: () => this.toolbarService.setCloudState('RESTING'),
        error: () => {
          this.message.error('Quote could not be deleted.');
          this.toolbarService.setCloudState('RESTING');
        },
        complete: () => {
          this.refreshJobViewer.emit(true);
          this.message.confirm('Quote Deleted');
        }
      });
  }

  private newLineLaboursItem() {
    return {
      id: null,
      description: null,
      assessorNote: null,
      estimateHours: null,
      estimateAmount: null,
      assessedAmount: null,
      assessedHours: null,
      assessedStatus: null,
      addedInV6: null,
      amountToHours: true
    };
  }

  private newLineParts() {
    return {
      id: null,
      partNumber: null,
      description: null,
      quantity: 1,
      partSource: null,
      assessorNote: null,
      estimateMarkup: null,
      estimateAmount: null,
      assessedAmount: null,
      assessedMarkupPercent: null,
      assessedStatus: null,
      addedInV6: null
    };
  }

  private newLineSublets() {
    return {
      id: null,
      description: null,
      assessorNote: null,
      estimateAmount: null,
      assessedAmount: null,
      assessedStatus: null,
      addedInV6: null
    };
  }

  trackByFn(index: any, item: any) {
    return item.id;
  }

  trackByForSubletsAndParts(index: any) {
    return index;
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
