<form [formGroup]="form">
  <article class="flex items-center mb-20">
    <p class="title-1 font-normal m-0">Managed Repair Sites</p>
  </article>

  <article class="grid grid-cols-4 gap-15 px-5" *ngIf="selectedRepairerSites().length; else noRepairers">
    <mat-checkbox
      *ngFor="let site of selectedRepairerSites()"
      class="checkbox-body1"
      [checked]="repairerSites.value.includes(site.id)"
      (change)="onCheckRepairerSiteCheckbox($event.checked, site.id)">
      {{ site.companyName }}
    </mat-checkbox>
  </article>

  <ng-template #noRepairers>
    <infos-guidance [guidanceTemplate]="noSiteGuidance" />
  </ng-template>

  <mat-divider class="my-20" />

  <article class="flex items-center mb-20">
    <p class="title-1 font-normal m-0">All Repair Sites</p>

    <mat-checkbox
      color="primary"
      class="ml-15 mr-50 checkbox-body1"
      [disabled]="mladminService.notArchivedRepairerSitesList().length === 0"
      [checked]="selectAll"
      (change)="selectAllVisibleRepairerSites($event.checked)">
      Select All
    </mat-checkbox>

    <div class="flex flex-grow">
      <mat-form-field
        appearance="outline"
        color="accent"
        class="w-[150px] small-field remove-end-border-radius remove-form-wrapper-spacing">
        <mat-select formControlName="searchRepairerGroup" (selectionChange)="getRepairerSites()">
          <mat-option *ngFor="let group of repairerGroupList" [value]="group">{{ group }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field
        appearance="outline"
        color="accent"
        class="input-search subtitle-2 remove-start-border-radius remove-form-wrapper-spacing">
        <input formControlName="search" matInput placeholder="Type to search" />
      </mat-form-field>
    </div>
    <button mat-raised-button color="primary" class="max-h-35 w-[110px]" (click)="getRepairerSites()">SEARCH</button>
  </article>

  <article
    class="w-full flex justify-center items-center p-y-20 mb-15"
    *ngIf="mladminService.loadingNotArchivedRepairerSitesList()">
    <mat-spinner diameter="20" color="primary" class="mr-10" />
    <span class="text-primary-900">Loading...</span>
  </article>

  <article
    class="w-full flex justify-center items-center p-y-20 mb-15"
    *ngIf="
      searchPerformed &&
      !mladminService.loadingNotArchivedRepairerSitesList() &&
      !mladminService.notArchivedRepairerSitesList().length
    ">
    <mat-icon class="custom-mat-icon mat-icon-sm mr-5" color="warn">report_problem</mat-icon>
    <span class="body-1 text-primary-500">No results could be found. Please review your search.</span>
  </article>

  <article
    *ngIf="
      !mladminService.loadingNotArchivedRepairerSitesList() && mladminService.notArchivedRepairerSitesList().length
    ">
    <div
      *ngIf="
        mladminService.notArchivedRepairerSitesTotalNumberOfMatchesFound() >
        mladminService.notArchivedRepairerSitesList()?.length
      "
      class="w-full flex justify-center items-center pb-20">
      <mat-icon class="custom-mat-icon mat-icon-md mr-5">info_outline_24px</mat-icon>
      <span class="body-1">
        Showing {{ mladminService.notArchivedRepairerSitesList()?.length }} results out of
        {{ mladminService.notArchivedRepairerSitesTotalNumberOfMatchesFound() }}. Please refine your search.
      </span>
    </div>

    <div class="max-h-[130px] grid grid-cols-4 gap-15 mb-10 px-5">
      <mat-checkbox
        *ngFor="let site of mladminService.notArchivedRepairerSitesList()"
        class="checkbox-body1"
        [checked]="repairerSites.value.includes(site.id)"
        (change)="onCheckRepairerSiteCheckbox($event.checked, site.id)">
        {{ site.companyName }}
      </mat-checkbox>
    </div>
  </article>
</form>

<ng-template #noSiteGuidance>
  <span class="body-1">
    This administrator does not currently manage any repairer sites. Use the search below to add their managed sites.
  </span>
</ng-template>
