<div *ngIf="preAuthority">
  <mat-expansion-panel
    (closed)="preAuthorityCurtainOpened = false"
    (opened)="preAuthorityCurtainOpened = true"
    class="curtains">
    <mat-expansion-panel-header [collapsedHeight]="'70px'" [expandedHeight]="'70px'">
      <mat-panel-title class="pre-authority-header-grid">
        <div>
          <div *ngIf="preAuthority.preAuthorizedDate" class="received-date">
            {{ preAuthority.preAuthorizedDate | dateepoch: timezone }}
            {{ preAuthority.preAuthorizedDate | time: timezone }}
          </div>
          <div class="authority-number">
            <div class="title-1">Self-Authority</div>
            <div class="authorised-box" appHighlightStatus="ISSUED">ISSUED</div>
          </div>
        </div>
        <div *ngIf="!preAuthorityCurtainOpened">
          <ng-container *ngIf="preAuthority.ownerContribution">
            <div class="labels body-2">Owner Contribution</div>
            <div class="data-values subtitle-2">$ {{ preAuthority.ownerContribution | number: '1.2-2' }}</div>
          </ng-container>
        </div>
        <div class="vehicle-value">
          <ng-container *ngIf="preAuthority.vehicleValue">
            <div class="labels body-2">Vehicle Value</div>
            <div class="data-values subtitle-2">$ {{ preAuthority.vehicleValue | number: '1.2-2' }}</div>
          </ng-container>
        </div>
      </mat-panel-title>
      <mat-panel-description class="kebab-panel">
        <div class="pdf-button secondary-icon">
          <button type="button" mat-icon-button (click)="openPdfReport.emit(); $event.stopPropagation()">
            <mat-icon>picture_as_pdf</mat-icon>
          </button>
        </div>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <mat-divider></mat-divider>
    <div class="info-guidance subtitle-2">
      <mat-icon>info_outlined</mat-icon>
      <span>This job is self-authorised, you can proceed with the work and send the quote separately.</span>
    </div>
    <form [formGroup]="preAuthorityForm">
      <div class="pre-authority-grid">
        <mat-form-field appearance="outline" class="field" color="accent">
          <mat-label>Owner Contribution</mat-label>
          <input (blur)="formatOwnerContribution()" formControlName="ownerContribution" matInput />
          <mat-icon class="ownerContribution-input-icon" matSuffix *ngIf="ownerContribution.value"
            >attach_money_24px</mat-icon
          >
          <mat-error *ngIf="!ownerContribution.valid"> This is not a valid amount </mat-error>
        </mat-form-field>
      </div>
    </form>
  </mat-expansion-panel>
</div>
