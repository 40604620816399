import { Component, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MessagingService } from '../../components/messaging/messaging.service';
import { smallModalConfig } from '../../consts/modal-config.const';
import { REPAIR_PROGRESS_MAPPING } from '../../consts/repairProgressMapping.const';
import { JobStatusEnum } from '../../enums/jobStatusEnum.enum';
import { ModalConfigsHelper } from '../../helpers/modal-configs.helper';
import { JobSearchResult } from '../../model/jobSearchResult.model';
import { JobService } from '../../services/job.service';
import { ToolbarService } from '../../services/toolbar.service';
import { UserService } from '../../services/user.service';
import { WebRTCService } from '../../services/webRTC.service';
import { AccessRepairerSiteComponent } from '../sysadmin-dashboard/access-repairer-site/access-repairer-site.component';

@Component({
  selector: 'app-job-search',
  templateUrl: './job-search.component.html',
  styleUrls: ['./job-search.component.scss']
})
export class JobSearchComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  jobSearch: JobSearchResult[];
  loading = false;
  jobSearchColumns = [
    'lastTransactionDateTime',
    'ownerName',
    'rego',
    'vehicleDescription',
    'insurerName',
    'requestForQuoteDateTime',
    'status',
    'claimNumber',
    'dataFileId',
    'link'
  ];
  dataSource: MatTableDataSource<JobSearchResult>;
  activeColumn = 'lastTransactionDateTime';
  private unsubscribe = new Subject();
  searchValue = '';
  isUserSysAdminOrMlAdminWithoutRepairerSiteAccess = false;

  protected readonly jobStatusEnum = JobStatusEnum;
  protected readonly repairProgressMapping = REPAIR_PROGRESS_MAPPING;

  data = inject(MAT_DIALOG_DATA);
  private userService = inject(UserService);
  private message = inject(MessagingService);
  private toolbarService = inject(ToolbarService);
  private dialogRef = inject(MatDialogRef<JobSearchComponent>);
  private accessRepairerSite = inject(MatDialog);
  private router = inject(Router);
  private webRTC = inject(WebRTCService);
  private modalConfigsHelper = inject(ModalConfigsHelper);
  private jobService = inject(JobService);

  ngOnInit(): void {
    if (this.webRTC.displayMediaStream) {
      this.jobSearchColumns.pop();
    }

    this.webRTC.webRTCConnected.pipe(takeUntil(this.unsubscribe)).subscribe((isConnected: boolean) => {
      if (isConnected && this.jobSearchColumns.includes('link')) {
        this.jobSearchColumns.pop();
      } else {
        this.jobSearchColumns.push('link');
      }
    });

    this.data?.searchValue.pipe(takeUntil(this.unsubscribe)).subscribe((value) => {
      this.getJobSearch(value);
      this.searchValue = value;
    });

    this.isUserSysAdminOrMlAdminWithoutRepairerSiteAccess =
      this.data?.userPrivilege === 'SYSADMIN' ||
      (this.data?.userPrivilege === 'MLADMIN' && !localStorage.getItem('repairerSiteAccessed'));
    if (!this.isUserSysAdminOrMlAdminWithoutRepairerSiteAccess) {
      this.jobSearchColumns.splice(
        this.jobSearchColumns.findIndex((col) => col === 'dataFileId'),
        1
      );
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private getJobSearch(searchValue: string) {
    this.loading = true;
    this.userService
      .searchJobs(searchValue)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (resp) => {
          this.jobSearch = resp.body;
        },
        error: () => {
          this.message.error('Could not retrieve your jobs.');
          this.loading = false;
          this.toolbarService.setCloudState('RESTING');
        },
        complete: () => {
          this.loading = false;
          this.dataSource = new MatTableDataSource(this.jobSearch);
          this.dataSource.sort = this.sort;
          this.toolbarService.setCloudState('RESTING');
        }
      });
  }

  redirectToJob(job: JobSearchResult, sameWindow: boolean, e: any) {
    if (this.isUserSysAdminOrMlAdminWithoutRepairerSiteAccess) {
      this.confirmAccessRepairerSite(job, sameWindow);
    } else {
      this.jobService.activeJobId = job.id;
      if (sameWindow) {
        this.router.navigate([`/jobViewer/${job.id}`]);
      } else {
        window.open(`/jobViewer/${job.id}`, '_blank');
      }
      localStorage.setItem('previousUrl', 'search');
    }

    if (sameWindow && e.isTrusted) {
      this.dialogRef.close();
    }
  }

  private confirmAccessRepairerSite(job: JobSearchResult, sameWindow: boolean) {
    const data = { repairerSiteId: job.repairerId, repairerSiteName: job.companyName, jobId: job.id, sameWindow };
    this.accessRepairerSite.open(
      AccessRepairerSiteComponent,
      this.modalConfigsHelper.buildMediumModalConfig(data, smallModalConfig)
    );
  }
}
