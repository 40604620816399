<div class="send-to-insco-title subtitle-1" #inscoTitle [class.add-width]="inscoTitle?.offsetWidth >= 200">
  Send to
  <h6 class="insco-name">{{ recipient }}</h6>
</div>
<mat-tab-group
  dynamicHeight
  animationDuration="0ms"
  color="primary"
  [(selectedIndex)]="selectedTabIndex"
  [class.extra-margin]="inscoTitle?.offsetWidth >= 200"
  [class.warning-tab]="jobService.displayWarningQuoteTab() && activatedTab === 'quote'"
  (selectedTabChange)="activatedTab = $event?.tab?.textLabel"
  #tabGroup>
  <mat-tab *ngIf="unSubmittedDiary" [label]="'diary'">
    <ng-template mat-tab-label>
      <span class="subtitle-2 span-label-for-tab">
        <mat-icon
          color="primary"
          [matTooltip]="appAddDairy?.diaryForm?.status === 'VALID' ? null : 'Complete to send'"
          *ngIf="appAddDairy?.diaryForm?.status === 'INVALID'"
          >info</mat-icon
        >
        Diary
      </span>
    </ng-template>
    <div class="mt-[21px]">
      <app-add-diary
        #appAddDairy
        (disableSendButton)="validateSendJob.emit()"
        (refreshJobViewer)="refresh.emit()"
        [diary]="unSubmittedDiary">
      </app-add-diary>
    </div>
  </mat-tab>

  <mat-tab *ngIf="unSubmittedPhotos?.length" [label]="'photo'">
    <ng-template mat-tab-label>
      <span class="subtitle-2 span-label-for-tab">
        {{ unSubmittedPhotos?.length > 1 ? 'Photos' : 'Photo' }}
      </span>
    </ng-template>
    <div class="mt-[21px]">
      <app-gallery-curtain
        (refreshJobViewer)="refresh.emit()"
        (updateSelectedPhoto)="updateSelectedPhoto.emit($event)"
        [job]="job"
        [photos]="unSubmittedPhotos"
        [quote]="!unSubmittedQuote">
      </app-gallery-curtain>
    </div>
  </mat-tab>

  <mat-tab *ngIf="unSubmittedDocs?.length" [label]="'document'">
    <ng-template mat-tab-label>
      <span class="subtitle-2 span-label-for-tab">
        {{ unSubmittedDocs?.length > 1 ? 'Documents' : 'Document' }}
      </span>
    </ng-template>
    <div class="mt-[21px]">
      <app-documents-curtain
        (refreshJobViewer)="refresh.emit()"
        [job]="job"
        [docs]="unSubmittedDocs"
        [blockExpansion]="true">
      </app-documents-curtain>
    </div>
  </mat-tab>

  <mat-tab *ngIf="unSubmittedCustomerFeedback" [label]="'customerFeedback'">
    <ng-template mat-tab-label>
      <span class="subtitle-2 span-label-for-tab">
        <mat-icon
          color="primary"
          [matTooltip]="appAddCustomerFeedback?.customerFeedbackForm?.status === 'VALID' ? null : 'Complete to send'"
          *ngIf="appAddCustomerFeedback?.customerFeedbackForm?.status === 'INVALID'"
          >info</mat-icon
        >
        Customer Feedback
      </span>
    </ng-template>
    <div class="mt-[21px]">
      <app-add-customer-feedback
        #appAddCustomerFeedback
        [customerFeedback]="unSubmittedCustomerFeedback"
        (disableSendButton)="validateSendJob.emit(); disableSendButton.emit($event)"
        (refreshJobViewer)="refresh.emit()">
      </app-add-customer-feedback>
    </div>
  </mat-tab>

  <mat-tab *ngIf="unSubmittedCustomerContact" [label]="'customerContacts'">
    <ng-template mat-tab-label>
      <span class="subtitle-2 span-label-for-tab">
        <mat-icon
          color="primary"
          [matTooltip]="appAddCustomerContact?.customerContactedForm?.status === 'VALID' ? null : 'Complete to send'"
          *ngIf="appAddCustomerContact?.customerContactedForm?.status === 'INVALID'"
          >info
        </mat-icon>
        Customer Contact
      </span>
    </ng-template>
    <div class="mt-[21px]">
      <app-add-customer-contact
        #appAddCustomerContact
        [customerContact]="unSubmittedCustomerContact"
        [repairerSiteName]="job?.repairerSiteName"
        [ownerName]="ownerName"
        [timezone]="timezone"
        (disableSendButton)="validateSendJob.emit(); disableSendButton.emit($event)"
        (refreshJobViewer)="refresh.emit()">
      </app-add-customer-contact>
    </div>
  </mat-tab>

  <mat-tab *ngIf="unSubmittedPotentialBreachOfCode" [label]="'codeOfPracticeBreaches'">
    <ng-template mat-tab-label>
      <span class="subtitle-2 span-label-for-tab">
        <mat-icon
          color="primary"
          [matTooltip]="
            appAddPotentialBreachCode?.potentialBreachOfCodeForm?.status === 'VALID' ? null : 'Complete to send'
          "
          *ngIf="appAddPotentialBreachCode?.potentialBreachOfCodeForm?.status === 'INVALID'"
          >info
        </mat-icon>
        Potential Breach of Code
      </span>
    </ng-template>
    <div class="mt-[21px]">
      <app-add-potential-breach-code
        #appAddPotentialBreachCode
        [potentialBreachOfCode]="unSubmittedPotentialBreachOfCode"
        (disableSendButton)="validateSendJob.emit(); disableSendButton.emit($event)"
        (refreshJobViewer)="refresh.emit()">
      </app-add-potential-breach-code>
    </div>
  </mat-tab>

  <mat-tab *ngIf="unSubmittedCustomerVulnerability" [label]="'customerVulnerabilities'">
    <ng-template mat-tab-label>
      <span class="subtitle-2 span-label-for-tab">
        <mat-icon
          color="primary"
          [matTooltip]="
            appAddCustomerVulnerability?.customerVulnerabilityForm?.status === 'VALID' ? null : 'Complete to send'
          "
          *ngIf="appAddCustomerVulnerability?.customerVulnerabilityForm?.status === 'INVALID'"
          >info
        </mat-icon>
        Vulnerable Customer
      </span>
    </ng-template>
    <div class="mt-[21px]">
      <app-add-customer-vulnerability
        #appAddCustomerVulnerability
        [vulnerability]="unSubmittedCustomerVulnerability"
        [currentUser]="currentUser"
        (disableSendButton)="validateSendJob.emit()"
        (refreshJobViewer)="refresh.emit()">
      </app-add-customer-vulnerability>
    </div>
  </mat-tab>

  <mat-tab *ngIf="showSelfAuthorisationCurtain" [label]="'selfAuthorise'">
    <ng-template mat-tab-label>
      <span class="subtitle-2 span-label-for-tab"> Self-Authorise </span>
    </ng-template>
    <div class="mt-[21px]">
      <app-self-authorise
        (refreshJobViewer)="refresh.emit()"
        (openPdfReport)="openPdfReport.emit('pre_authorized')"
        [jobId]="job?.jobId">
      </app-self-authorise>
    </div>
  </mat-tab>

  <mat-tab *ngIf="showBooking" [label]="'inspectionBooking'">
    <ng-template mat-tab-label>
      <span class="subtitle-2 span-label-for-tab">
        <mat-icon
          color="primary"
          [matTooltip]="appBookingCurtain?.bookingForm?.status === 'VALID' ? null : 'Complete to send'"
          *ngIf="appBookingCurtain?.bookingForm?.status === 'INVALID'"
          >info</mat-icon
        >
        Inspection Booking
      </span>
    </ng-template>
    <div class="mt-[21px]">
      <app-booking
        #appBookingCurtain
        (disableSendButton)="validateSendJob.emit(); disableSendButton.emit(true)"
        (refreshJobViewer)="refresh.emit()"
        [hasQuote]="!!unSubmittedQuote"
        [job]="job"
        [timezone]="timezone"
        [onSite]="job?.onSiteInspection"
        [preselectedOption]="job?.bookingOption">
      </app-booking>
    </div>
  </mat-tab>

  <mat-tab *ngIf="unSubmittedInvoice" [label]="'invoice'">
    <ng-template mat-tab-label>
      <span class="subtitle-2 span-label-for-tab">
        <mat-icon
          color="primary"
          [matTooltip]="appInvoiceCurtain?.invoiceForm?.status === 'VALID' ? null : 'Complete to send'"
          *ngIf="appInvoiceCurtain?.invoiceForm?.status === 'INVALID'"
          >info</mat-icon
        >
        Invoice {{ unSubmittedInvoice?.document?.invoiceNumber }}
      </span>
    </ng-template>
    <div class="mt-[21px]">
      <app-invoice-curtain
        #appInvoiceCurtain
        [isSentToInsurerTray]="expandCurtainInTray"
        (disableSendButton)="disableSendButton.emit($event || showValidationGuidance)"
        (refreshInvoice)="refresh.emit($event)"
        (synchronizeOwnerContribution)="synchronize.emit($event)"
        (validateField)="validateSendJob.emit($event)"
        [document]="unSubmittedInvoice"
        [lastExcessChangeTimestamp]="job?.lastExcessChangeTimestamp">
      </app-invoice-curtain>
    </div>
  </mat-tab>
  <mat-tab *ngIf="unSubmittedQuote" [label]="'quote'">
    <ng-template mat-tab-label>
      <span class="subtitle-2 span-label-for-tab">
        <mat-icon
          color="primary"
          [matTooltip]="
            quoteCurtainEnableSendButton &&
            !(unSubmittedQuote?.document?.manual && unSubmittedQuote?.document?.estTotal === 0)
              ? null
              : 'Complete to send'
          "
          *ngIf="
            (!jobService.displayWarningQuoteTab() && !quoteCurtainEnableSendButton) ||
            (unSubmittedQuote?.document?.manual && unSubmittedQuote?.document?.estTotal === 0)
          ">
          info
        </mat-icon>
        <mat-icon
          matTooltip="Quoting Conflict, Merge Or Replace To Send"
          *ngIf="jobService.displayWarningQuoteTab()"
          class="custom-mat-icon mat-icon-sm text-orange-peel">
          warning
        </mat-icon>
        {{ unSubmittedQuote?.document?.original ? '' : 'Supplement ' }} Quote
      </span>
    </ng-template>

    <div class="mt-[21px]">
      <section
        *ngIf="jobService.displayWarningQuoteTab()"
        class="bg-orange-peel text-white subtitle-2 rounded m-10 p-10 flex items-center justify-between gap-10">
        <div class="flex items-center gap-10">
          <mat-icon color="accent" class="custom-mat-icon mat-icon-md"> warning </mat-icon>
          The previous quote has not been authorised yet. Please select to merge quotes or replace the previous quote
          completely (assessment results of the previous quote will be maintained if applicable).
        </div>
        <div class="flex items-center gap-10">
          <button mat-flat-button class="bg-white subtitle-2" (click)="updateStrategyQuote('MERGE')">MERGE</button>
          <button mat-flat-button class="bg-white subtitle-2" (click)="updateStrategyQuote('REPLACE')">REPLACE</button>
        </div>
      </section>

      <app-quote-curtain
        *ngIf="jobService.displayWarningQuoteTab()"
        [document]="submittedQuoteNotAuthorised[0]"
        [editable]="false"
        [quotingSystemName]="job.quotingSystem?.companyName"
        [previousQuote]="true"
        [isSentToInsurerTray]="expandCurtainInTray">
      </app-quote-curtain>

      <app-quote-curtain
        (disableSendButton)="disableSendButton.emit($event); quoteCurtainEnableSendButton = !$event"
        (refreshJobViewer)="refresh.emit($event)"
        (validateQuote)="validateSendJob.emit()"
        [document]="unSubmittedQuote"
        [editable]="job?.editable"
        [canBeReplacedOrMerged]="jobService.displayWarningQuoteTab()"
        [updateStrategy]="job?.updateStrategy"
        [quotingSystemName]="job.quotingSystem?.companyName"
        [isSentToInsurerTray]="expandCurtainInTray">
      </app-quote-curtain>
    </div>
  </mat-tab>

  <mat-tab *ngIf="noQuoteResponse" [label]="'returnToAssessor'">
    <ng-template mat-tab-label>
      <span class="subtitle-2 span-label-for-tab">
        <mat-icon
          color="primary"
          [matTooltip]="appAddReturnNoQuote?.replyForm?.status === 'VALID' ? null : 'Complete to send'"
          *ngIf="appAddReturnNoQuote?.replyForm?.status === 'INVALID'"
          >info</mat-icon
        >
        Return to Assessor
      </span>
    </ng-template>
    <div class="mt-[21px]">
      <app-add-return-no-quote
        #appAddReturnNoQuote
        (refreshJobViewer)="refresh.emit($event)"
        [assessmentAction]="job?.noQuoteResponseAssessmentAction || noQuoteResponseAssessmentAction"
        [assessorAllocated]="job?.assessorAllocated"
        [displayRFA]="submittedQuoteNotAuthorised.length && submittedQuotes.length === 1"
        [displaySupplementRFA]="submittedQuoteNotAuthorised.length && submittedQuotes.length > 1"
        [hasQuotes]="hasQuotes"
        [jobId]="job?.jobId"
        [note]="job?.noQuoteResponseNote">
      </app-add-return-no-quote>
    </div>
  </mat-tab>
</mat-tab-group>
