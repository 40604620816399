<div class="dialog-header" mat-dialog-title>
  <h6>Add Invoice</h6>
  <button aria-label="Close" mat-dialog-close mat-icon-button>
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-divider></mat-divider>
<div mat-dialog-content class="relative">
  <div
    class="absolute right-0 result flex items-center px-15 py-10 mr-20 mt-5 bold subtitle-2 bg-wild-sand"
    @fadeSlideUpDown
       *ngIf="fileName && !fileDeleted && !invoiceFiles?.length">
    <mat-icon class="custom-mat-icon mat-icon-sm mr-5 mt-[2px]">check_circle</mat-icon>
    EstImage found your Invoice
  </div>
  <mat-tab-group
    animationDuration="0ms"
    color="primary"
    class="add-invoice-tab"
    [selectedIndex]="data?.selectedIndex"
    (selectedTabChange)="selectedIndex = $event.index">
    <mat-tab #fromFile>
      <ng-template mat-tab-label>
        <div
          [ngClass]="{ 'text-boulder subtitle-1': !fromFile.isActive, 'text-primary-500 title-1': fromFile.isActive }">
          Import Invoice
        </div>
      </ng-template>
      <div class="pt-10 import-container-file border-0 border-t border-silver border-solid">
        <section class="file-box" @fadeSlideUpDown *ngIf="fileName">
          <div class="file-container">
            <div class="delete-button-container">
              <mat-icon svgIcon="description"></mat-icon>
              <div>
                <div class="received-date text-secondary-500">
                  {{ lastModified / 1000 | dateepoch: timezone }} {{ lastModified / 1000 | time: timezone }}
                </div>
                <div class="file-name subtitle-2">
                  <span class="extra-margin">File</span>
                  <span class="subtitle-1 bold display-ellipsis">{{ fileName }}</span>
                </div>
              </div>
            </div>
            <button
              class="raised-button-with-icon"
              mat-icon-button
              mat-raised-button
              color="accent"
              [disabled]="pendingImages()"
              (click)="deleteFile(invoiceInput)"
              type="button">
              <mat-icon>delete_forever</mat-icon>
            </button>
          </div>
          <mat-divider @fadeInOut *ngIf="imageFiles?.length" class="mt-10"></mat-divider>
          <mat-progress-bar mode="indeterminate" *ngIf="pendingImages()" class="my-20"></mat-progress-bar>
          <mat-checkbox @fadeInOut *ngIf="imageFiles?.length"
            color="primary"
            [checked]="uploadImages"
            (change)="uploadImages = $event.checked">
            EstImage found {{ imageFiles?.length | i18nPlural: imagesPluralMapping }} that can be uploaded along with
            the invoice. Import these as well?
          </mat-checkbox>
        </section>
        <app-import-file-from-disk @fadeSlideUpDown *ngIf="selectedIndex < 1"
                                   (setFile)="setFileFromHandler($event)"
                                   (setImages)="setImages($event)"
                                   [rego]="data?.rego"
                                   [type]="data?.type"
                                   [fileDeleted]="fileDeleted"
                                   [xmlFiles]="importInvoiceFiles"/>
        <div @fadeInOut *ngIf="!fileName" class="button-container">
          <button type="button" color="accent" mat-raised-button (click)="invoiceInput.click()">BROWSE</button>
        </div>
        <input #invoiceInput (change)="importInvoice(invoiceInput)" [hidden]="true" accept="text/xml" type="file" />
      </div>
    </mat-tab>
    <mat-tab #fromQuotes>
      <ng-template mat-tab-label>
        <div
          [ngClass]="{
            'text-boulder subtitle-1': !fromQuotes.isActive,
            'text-primary-500 title-1': fromQuotes.isActive
          }">
          Invoice From Quotes
        </div>
      </ng-template>
      <div class="pt-10 border-0 border-b border-silver border-solid">
        <section @fadeInOut *ngFor="let quote of quotes"
          class="quote-list-grid text-secondary-500 border-0 border-t border-silver border-solid">
          <div>
            <div @fadeInOut *ngIf="quote.date" class="received-date text-secondary-500">
              {{ quote.date | dateepoch: timezone }} {{ quote.date | time: timezone }}
            </div>
            <div class="inline-flex items-center">
              {{ quote.document.original ? 'Quote' : 'Supplement' }}
              <span class="ml-10" *ngIf="quote.document.supplementNumber > 0">{{
                quote.document.supplementNumber
              }}</span>
              <h5 class="ml-10">{{ quote.document.quoteNumber }}</h5>
              <div @fadeInOut *ngIf="quote.assessed" class="ml-10" appHighlightStatus="ASSESSED">ASSESSED</div>
            </div>
          </div>

          <div>
            <div class="body-2">Assessed</div>
            <div class="subtitle-2">$ {{ quote.document.assessedTotal | number: '1.2-2' }}</div>
          </div>
        </section>
      </div>
    </mat-tab>
  </mat-tab-group>
  <form [formGroup]="invoiceForm">
    <div class="inline-flex w-full">
      <mat-form-field class="w-[460px] pt-20 flex-1" appearance="outline" color="accent">
        <mat-label>Invoice Number</mat-label>
        <input formControlName="invoiceNumber" matInput required />
        <mat-error *ngIf="invoiceNumber.hasError('required')"> Invoice Number is <strong>required</strong> </mat-error>
        <mat-error *ngIf="invoiceNumber.hasError('duplicateInvoice')">
          This invoice number is already associated with this job
        </mat-error>
      </mat-form-field>
    </div>
    <section class="invoice-section" @fadeSlideUpDown *ngIf="selectedIndex === 1">
      <div class="bg-charcoal py-[7px] px-20 text-white items-center border-boulder mt-[1px]">
        <span class="body-2">Totals</span>
      </div>
      <div class="bg-mercury py-[7px] px-20 grid grid-cols-[1fr_20px_150px] items-center">
        <span class="body-2">Invoice Subtotal</span>
        <span class="text-center">$</span>
        <span class="text-right subtitle-2">{{assessedSubTotalInvoice | number:'1.2-2'}}</span>
      </div>
      <div class="bg-mercury py-[7px] px-20 grid grid-cols-[1fr_20px_150px] items-center mt-[1px]">
        <span class="body-2">GST</span>
        <span class="text-center">$</span>
        <span class="text-right subtitle-2">{{assessedGSTTotalInvoice | number:'1.2-2'}}</span>
      </div>
      <div class="bg-mercury py-10 px-20 grid grid-cols-[1fr_20px_150px] items-center border-t border-0 border-solid border-boulder"
           *ngIf="!data.lastInvoice || (data.lastInvoice?.isFirstInvoice && data.lastInvoice?.status === 'REJECTED')">
        <span class="body-2 font-bold">Invoice Total</span>
        <span class="text-center font-bold">$</span>
        <span class="text-right font-bold subtitle-2">{{assessedTotalInvoice | number:'1.2-2'}}</span>
      </div>
      <ng-container *ngIf="!data.lastInvoice || (data.lastInvoice?.isFirstInvoice && data.lastInvoice?.status === 'REJECTED')">
        <div class="bg-mercury py-[7px] px-20 grid grid-cols-[1fr_20px_150px] items-center mt-[1px]">
          <span class="body-2">Excess</span>
          <span class="text-center">{{!data.excessType ? '$' : ''}}</span>
          <span class="text-right subtitle-2">{{!data.excessType ? (data.excess | number:'1.2-2') : data.excessType}}</span>
        </div>
        <div class="bg-mercury py-5 pl-20 pr-10 grid grid-cols-[1fr_20px_150px] gap-10 items-center mt-[1px]">
          <span class="body-2">Owner Contribution</span>
          <span class="text-center">$</span>
          <mat-form-field appearance="outline" color="accent" class="no-padding-bottom no-margin xs">
            <input matInput class="text-right subtitle-2"
                   formControlName="ownerContribution"
                   formatInputNumber
                   minDecimals="2"
                   maxDecimals="2">
          </mat-form-field>
        </div>
      </ng-container>
      <div class="bg-wild-sand py-10 px-20 grid grid-cols-[1fr_20px_150px] items-center border-t border-0 border-solid border-boulder">
        <span class="body-2 font-bold">Total Payable</span>
        <span class="text-center font-bold">$</span>
        <span class="text-right font-bold subtitle-2">{{invoiceTotal() | number:'1.2-2'}}</span>
      </div>
    </section>
  </form>
</div>
<mat-dialog-actions>
  <button mat-raised-button mat-dialog-close color="accent" [disabled]="invoiceForm.disabled">CANCEL</button>
  <button
    [disabled]="invoiceForm.disabled || invoiceForm.invalid || invoiceForm.pristine || pending() || pendingImages() || (fromFile.isActive && !fileName)
    "
    (click)="addInvoice(fromQuotes.isActive)"
    color="primary"
    mat-raised-button
    type="submit">
    ADD INVOICE
  </button>
</mat-dialog-actions>
