import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessagingService } from '../components/messaging/messaging.service';
import { AuthService } from '../auth/auth.service';
import { ResetPasswordPayload } from '../model/user.model';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  resetForm: UntypedFormGroup;
  usernameParam: string;
  submitPending = false;
  reset = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private auth: AuthService,
    private messagingService: MessagingService
  ) {}

  get username() {
    return this.resetForm.get('username');
  }

  ngOnInit(): void {
    if (this.route.snapshot.queryParams) {
      this.usernameParam = this.route.snapshot.queryParams.username;
    }

    this.resetForm = this.formBuilder.group({
      username: ['', [Validators.required]]
    });

    this.username.setValue(this.usernameParam ? this.usernameParam : '');
  }

  backToLogin() {
    this.router.navigate(['login']);
  }

  resetPassword() {
    this.username.disable();
    this.submitPending = true;
    const resetPass: ResetPasswordPayload = {
      username: this.username.value
    };
    this.auth.reset(resetPass).subscribe(
      () => (this.reset = true),
      () => {
        this.messagingService.error('There was a problem resetting your password. Please try again');
        this.submitPending = false;
        this.username.enable();
      }
    );
  }
}
