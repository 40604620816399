<div class="dialog-header" mat-dialog-title>
  <div class="title">
    <h6>OAuth2 Interface Configuration</h6>
    <span class="status body-1" [class.green-color]="data?.oAuth2Enabled">{{
      data?.oAuth2Enabled ? 'Enabled' : 'Disabled'
    }}</span>
  </div>
  <button mat-icon-button aria-label="Close" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-divider></mat-divider>
<div mat-dialog-content class="oAuth-interface">
  <form [formGroup]="oAuthForm">
    <div class="fields-container">
      <mat-form-field appearance="outline" class="disable" color="accent">
        <mat-label>client_id</mat-label>
        <input formControlName="clientId" matInput disabled />
      </mat-form-field>

      <mat-form-field appearance="outline" color="accent">
        <mat-label>client_secret</mat-label>
        <input formControlName="clientSecret" matInput required />
        <mat-error *ngIf="clientSecret.hasError('required')"> client_secret is <strong>required</strong> </mat-error>
      </mat-form-field>
    </div>
    <mat-form-field appearance="outline" color="accent">
      <mat-label>redirect_uri</mat-label>
      <input formControlName="redirectUri" matInput required />
      <mat-error *ngIf="redirectUri.hasError('required')">
        <div>redirect_uri is <strong>required</strong></div>
      </mat-error>
      <mat-error *ngIf="!redirectUri.valid && !isProd && !redirectUri.hasError('required')">
        <div>Please enter valid <strong>redirect_uri</strong></div>
      </mat-error>
      <mat-error *ngIf="!redirectUri.valid && isProd && !redirectUri.hasError('required')">
        <div>Protocol should be <strong>https</strong></div>
      </mat-error>
    </mat-form-field>
  </form>
</div>

<mat-dialog-actions>
  <button mat-raised-button mat-dialog-close color="accent">CANCEL</button>
  <button
    mat-button
    class="enable-disable-button"
    [disabled]="(clientSecret.invalid || redirectUri.invalid) && !data?.oAuth2Enabled"
    (click)="confirmChangeStatusOfInterface(data?.oAuth2Enabled)">
    {{ data?.oAuth2Enabled ? 'DISABLE' : 'ENABLE' }}
  </button>
  <button
    [disabled]="oAuthForm.invalid || oAuthForm.pristine || pending"
    (click)="updateQuotingSystemOauth2()"
    mat-raised-button
    color="primary">
    SAVE
  </button>
</mat-dialog-actions>
