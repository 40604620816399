import { Injectable } from '@angular/core';
import * as StompJs from '@stomp/stompjs';
import {ActivationState} from '@stomp/stompjs';
import { Subject } from 'rxjs';
import * as SockJS from 'sockjs-client';
import * as Stomp from 'stompjs';
import { SocketClientState } from '../enums/socketClientState.enum';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
  private stompClient: StompJs.Client;
  private connectState = new Subject<SocketClientState>();
  private _connected = false;

  get connected(): boolean {
    return this._connected;
  }

  get clientStomp(): Stomp.client {
    return this.stompClient;
  }

  get connectionState(): Subject<SocketClientState> {
    return this.connectState;
  }

  establishWebSocketConnection(path: string) {
    this.connectState.next(SocketClientState.INACTIVE);
    const self = this;
    const client = new StompJs.Client({
      reconnectDelay: 5000,
      heartbeatOutgoing: 20000,
      heartbeatIncoming: 20000,
      debug: () => {},
      onChangeState: (state: ActivationState) => {
        if (state === ActivationState.ACTIVE) {
          self.connectState.next(SocketClientState.ACTIVE);
        }
      },
      onConnect: () => {
        this._connected = true;
        this.connectState.next(SocketClientState.CONNECTED);
      },
      onWebSocketError: (error) => {
        self.errorCallBack(error);
      },
      onStompError: (error) => {
        self.errorCallBack(error);
      }
    });
    client.webSocketFactory = () => {
      return new SockJS(path);
    };

    this.stompClient = client;
    client.activate();
  }

  closeWebSocketConnection() {
    if (this.stompClient !== null && this.stompClient.connected) {
      this.stompClient.deactivate().then(() => {
        this.connectState.next(SocketClientState.DISCONNECTED);
        this.connectState.complete();
        this._connected = false;
      });
    }
  }

  errorCallBack(error) {
    if (
      error.headers &&
      (error.headers.message.includes('Forbidden') ||
        error.headers.message.includes('There is not exactly one repuser currently logged in for this repairer site'))
    ) {
      this.connectState.next(SocketClientState.ERROR);
      this.closeWebSocketConnection();
    }
  }

  send(topic: string, message: any) {
    this.stompClient?.publish({
      destination: topic,
      body: JSON.stringify(message),
      skipContentLengthHeader: true
    });
  }
}
