import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { Diary, DocumentReference, Job, PhotoReference } from '../../../model/job.model';
import { CurrentUserService } from '../../../services/currentUser.service';
import { User } from '../../../model/user.model';
import { DocumentItem } from '../../../model/document.model';
import {
  CustomerContact,
  CustomerFeedback,
  CustomerVulnerability,
  PotentialBreachOfCode
} from '../../../model/gicop-notifications';
import { MatLegacyTabGroup as MatTabGroup } from '@angular/material/legacy-tabs';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { JobService } from '../../../services/job.service';
import { MessagingService } from '../../../components/messaging/messaging.service';
import { TitleCasePipe } from '@angular/common';

@Component({
  selector: 'app-send-to-insco-tabs',
  templateUrl: './send-to-insco-tabs.component.html',
  styleUrls: ['./send-to-insco-tabs.component.scss'],
  providers: [TitleCasePipe]
})
export class SendToInscoTabsComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @ViewChild('tabGroup', { static: false }) tabGroup: MatTabGroup;
  @Input() job: Job;
  @Input() recipient: string;
  @Input() ownerName: string;
  @Input() unSubmittedDocs: DocumentReference[];
  @Input() unSubmittedPhotos: PhotoReference[] = [];
  @Input() unSubmittedQuote: DocumentItem;
  @Input() unSubmittedInvoice: DocumentItem;
  @Input() noQuoteResponse: boolean;
  @Input() noQuoteResponseAssessmentAction: string;
  @Input() submittedQuoteNotAuthorised: DocumentItem[] = [];
  @Input() submittedQuotes: DocumentItem[] = [];
  @Input() unSubmittedCustomerFeedback: CustomerFeedback;
  @Input() unSubmittedDiary: Diary;
  @Input() unSubmittedCustomerContact: CustomerContact;
  @Input() unSubmittedPotentialBreachOfCode: PotentialBreachOfCode;
  @Input() unSubmittedCustomerVulnerability: CustomerVulnerability;
  @Input() showSelfAuthorisationCurtain: boolean;
  @Input() showValidationGuidance: boolean;
  @Input() indexTab = '';
  @Input() showBooking: boolean;
  @Input() hasQuotes: boolean;
  @Input() expandCurtainInTray = false;
  @Output() refresh = new EventEmitter();
  @Output() updateSelectedPhoto = new EventEmitter();
  @Output() validateSendJob = new EventEmitter();
  @Output() openPdfReport = new EventEmitter();
  @Output() disableSendButton = new EventEmitter();
  @Output() synchronize = new EventEmitter();
  @ViewChild('inscoTitle', { static: false }) inscoTitle: ElementRef;
  timezone: string;
  currentUser: User;
  activatedTab = '';
  selectedTabIndex = 0;
  quoteCurtainEnableSendButton = true;

  private tabGroupLength = 0;
  private checkNewTab = new Subject<void>();

  jobService = inject(JobService);
  private currentUserService = inject(CurrentUserService);
  private messageService = inject(MessagingService);
  private titleCasePipe = inject(TitleCasePipe);

  ngOnInit() {
    this.currentUser = this.currentUserService.currentUserValue;
    this.timezone = this.currentUserService?.timeZone;
    this.checkIfNewTabWasAdded();
    this.shouldDisplayWarningQuoteTab();
  }

  ngAfterViewInit(): void {
    this.tabGroupLength = this.tabGroup?._allTabs.length;
    this.activatedTab = this.tabGroup?._allTabs.first.textLabel;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.indexTab) {
      this.activatedTab = changes?.indexTab?.currentValue;
    }
    // Will update the view before executing the Observable
    this.checkNewTab.next();

    if (changes?.unSubmittedQuote || changes?.submittedQuoteNotAuthorised) {
      this.shouldDisplayWarningQuoteTab();
    }
  }

  private shouldDisplayWarningQuoteTab(): void {
    this.jobService.displayWarningQuoteTab.set(
      !!this.unSubmittedQuote && this.submittedQuoteNotAuthorised?.length > 0 && this.job.updateStrategyWaiting
    );
  }

  private checkIfNewTabWasAdded(): void {
    this.checkNewTab.pipe(debounceTime(0)).subscribe(() => {
      if (this.tabGroupLength !== this.tabGroup?._tabs.length) {
        this.tabGroupLength = this.tabGroup?._tabs.length;
        this.tabGroup?._tabs.forEach((item, index) => {
          item.isActive = this.activatedTab === item?.textLabel;
          if (item.textLabel === this.activatedTab) {
            // If the selectedTabIndex is the same as before it will not update the view with only this.selectedTabIndex = index;
            this.tabGroup.selectedIndex = index;
            this.tabGroup._elementRef.nativeElement.scrollIntoView({
              behavior: 'smooth'
            });
          }
        });
      }
    });
  }

  updateStrategyQuote(strategyName: 'REPLACE' | 'MERGE'): void {
    this.jobService.changeUpdateStrategy(this.job.jobId, strategyName).subscribe(() => {
      this.jobService.displayWarningQuoteTab.set(false);
      this.messageService.confirm(`Quote ${this.titleCasePipe.transform(strategyName)}d!`);
      this.refresh.emit('QuoteReplacedOrMerged');
    });
  }

  ngOnDestroy(): void {
    this.checkNewTab.next();
    this.checkNewTab.complete();
    this.jobService.displayWarningQuoteTab.set(false);
  }
}
