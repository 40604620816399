<div class="dialog-header" mat-dialog-title>
  <h6>Add Quote</h6>
  <button aria-label="Close" mat-dialog-close mat-icon-button>
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-divider></mat-divider>
<div mat-dialog-content class="relative">
  <div
    class="absolute right-0 result flex items-center px-15 py-10 mr-20 mt-5 bold subtitle-2 bg-wild-sand"
    *ngIf="fileName && !fileDeleted && !quoteFiles?.length">
    <mat-icon class="custom-mat-icon mat-icon-sm mr-5 mt-[2px]">check_circle</mat-icon>
    EstImage found your Quote
  </div>
  <mat-tab-group
    animationDuration="0ms"
    color="primary"
    class="add-quote-tab"
    [selectedIndex]="selectedIndex"
    (selectedTabChange)="selectedIndex = $event.index">
    <mat-tab #fromFile>
      <ng-template mat-tab-label>
        <div
          [ngClass]="{ 'text-boulder subtitle-1': !fromFile.isActive, 'text-primary-500 title-1': fromFile.isActive }">
          Import Quote
        </div>
      </ng-template>
      <div class="mt-20 import-container-file">
        <section *ngIf="fileName" class="file-box">
          <div class="file-container">
            <div class="delete-button-container secondary-icon">
              <mat-icon svgIcon="description"></mat-icon>
              <div>
                <div class="received-date text-secondary-500">
                  {{ lastModified / 1000 | dateepoch: timezone }} {{ lastModified / 1000 | time: timezone }}
                </div>
                <div class="file-name subtitle-2">
                  <span class="extra-margin">File</span>
                  <span class="subtitle-1 bold display-ellipsis">{{ fileName }}</span>
                </div>
              </div>
            </div>
            <button
              type="button"
              class="raised-button-with-icon"
              mat-icon-button
              mat-raised-button
              color="accent"
              [disabled]="pendingImages()"
              (click)="deleteFile(quoteInput)">
              <mat-icon>delete_forever</mat-icon>
            </button>
          </div>
          <mat-divider *ngIf="imageFiles?.length" class="mt-10"></mat-divider>
          <mat-progress-bar mode="indeterminate" *ngIf="pendingImages()" class="my-20"></mat-progress-bar>
          <mat-checkbox
            *ngIf="imageFiles?.length"
            color="primary"
            [checked]="uploadImages"
            (change)="uploadImages = $event.checked">
            EstImage found {{ imageFiles?.length | i18nPlural: imagesPluralMapping }} that can be uploaded along with
            the quote. Import these as well?
          </mat-checkbox>
        </section>
        <app-extracted-vehicle-details
          *ngIf="extractedVehicleData"
          [data]="extractedVehicleData"
          (importData)="importVehicleDetails = $event">
        </app-extracted-vehicle-details>
        <app-import-file-from-disk
          *ngIf="selectedIndex < 1"
          (setFile)="setFileFromHandler($event)"
          (setImages)="setImages($event)"
          [fileDeleted]="fileDeleted"
          [xmlFiles]="importQuoteFiles"
          [rego]="data?.rego"
          [type]="data?.type">
        </app-import-file-from-disk>
        <div *ngIf="!fileName" class="button-container pt-10">
          <button type="button" color="accent" mat-raised-button (click)="quoteInput.click()">BROWSE</button>
        </div>
        <input #quoteInput (change)="importQuote(quoteInput)" [hidden]="true" accept="text/xml" type="file" />
      </div>
    </mat-tab>
    <mat-tab #manualQuotes>
      <ng-template mat-tab-label>
        <div
          [ngClass]="{
            'text-boulder subtitle-1': !manualQuotes.isActive,
            'text-primary-500 title-1': manualQuotes.isActive
          }">
          Manual Quote
        </div>
      </ng-template>
      <div class="mt-20 py-20 border-0 border-t border-silver border-solid">
        <form [formGroup]="quoteForm" class="form-fields">
          <mat-checkbox
            *ngIf="data.notAuthorised"
            color="primary"
            [checked]="importLastQuote.value"
            formControlName="importLastQuote">
            Import the last quote
          </mat-checkbox>
          <mat-form-field class="pt-20" appearance="outline" color="accent">
            <mat-label>Quote Number</mat-label>
            <input formControlName="quoteNumber" matInput required />
            <mat-error *ngIf="quoteNumber.invalid"> Quote Number is <strong>required</strong> </mat-error>
          </mat-form-field>
        </form>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
<mat-dialog-actions>
  <button mat-raised-button mat-dialog-close color="accent">CANCEL</button>
  <button
    [disabled]="
      (quoteForm.invalid && manualQuotes.isActive) || pending() || pendingImages() || (fromFile.isActive && !fileName)
    "
    (click)="addQuote(manualQuotes.isActive)"
    color="primary"
    mat-raised-button
    type="submit">
    ADD QUOTE
  </button>
</mat-dialog-actions>
