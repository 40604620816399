import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConfirmBoxComponent } from '../../../../components/confirm-box/confirm-box.component';
import { CUSTOMER_VULNERABILITY, CustomerVulnerability } from '../../../../model/gicop-notifications';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DateInputHelper } from '../../../../helpers/date-input.helper';
import { UserService } from '../../../../services/user.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ToolbarService } from '../../../../services/toolbar.service';
import { MessagingService } from '../../../../components/messaging/messaging.service';
import { User } from '../../../../model/user.model';
import { EMAIL_PATTERN_STRING } from '../../../../consts/patterns.const';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { smallModalConfig } from '../../../../consts/modal-config.const';
import { ModalConfigsHelper } from '../../../../helpers/modal-configs.helper';

@Component({
  selector: 'app-add-customer-vulnerability',
  templateUrl: './add-customer-vulnerability.component.html',
  styleUrls: ['./add-customer-vulnerability.component.scss']
})
export class AddCustomerVulnerabilityComponent implements OnInit, AfterViewInit {
  @Input() vulnerability: CustomerVulnerability;
  @Output() refreshJobViewer: EventEmitter<any> = new EventEmitter();
  @Output() disableSendButton: EventEmitter<any> = new EventEmitter();
  @Input() currentUser: User;
  customerVulnerabilityForm: UntypedFormGroup;
  customerVulnerabilityList = CUSTOMER_VULNERABILITY;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  languages: string[] = [];
  constructor(
    private fb: UntypedFormBuilder,
    public dateInputHelper: DateInputHelper,
    private userService: UserService,
    private confirmBox: MatDialog,
    public toolbarService: ToolbarService,
    private message: MessagingService,
    private elRef: ElementRef,
    private modalConfigsHelper: ModalConfigsHelper
  ) {}

  get customerVulnerability() {
    return this.customerVulnerabilityForm.get('customerVulnerability');
  }
  get languagesSpoken() {
    return this.customerVulnerabilityForm.get('languagesSpoken');
  }
  get contactPerson() {
    return this.customerVulnerabilityForm.get('contactPerson');
  }
  get phoneNumber() {
    return this.customerVulnerabilityForm.get('phoneNumber');
  }
  get email() {
    return this.customerVulnerabilityForm.get('email');
  }
  get details() {
    return this.customerVulnerabilityForm.get('details');
  }
  get position() {
    return this.customerVulnerabilityForm.get('position');
  }

  ngOnInit(): void {
    this.customerVulnerabilityForm = this.fb.group({
      id: this.vulnerability?.id,
      customerVulnerability: [this.vulnerability?.customerVulnerability || '', [Validators.required]],
      languagesSpoken: [this.vulnerability?.languagesSpoken || []],
      details: [this.vulnerability?.details || ''],
      contactPerson: this.vulnerability?.contactPerson || `${this.currentUser.firstName} ${this.currentUser.lastName}`,
      position: this.vulnerability?.position || '',
      phoneNumber: this.vulnerability?.phoneNumber || this.currentUser.phone || '',
      email: [
        this.vulnerability?.email || this.currentUser.email || '',
        [Validators.required, Validators.pattern(EMAIL_PATTERN_STRING)]
      ]
    });

    this.updateCustomerVulnerabilityForm();
  }

  updateCustomerVulnerabilityForm() {
    this.customerVulnerability.valueChanges.subscribe(() => {
      this.disableSendButton.emit();
      this.updateCustomerVulnerability();
    });

    this.contactPerson.valueChanges.pipe(debounceTime(750), distinctUntilChanged()).subscribe(() => {
      if (this.contactPerson.invalid) {
        this.contactPerson.markAsTouched();
      }
      this.updateCustomerVulnerability();
      this.disableSendButton.emit();
    });

    this.position.valueChanges.pipe(debounceTime(750), distinctUntilChanged()).subscribe(() => {
      if (this.position.invalid) {
        this.position.markAsTouched();
      }
      this.updateCustomerVulnerability();
      this.disableSendButton.emit();
    });

    this.phoneNumber.valueChanges.pipe(debounceTime(750), distinctUntilChanged()).subscribe(() => {
      if (this.phoneNumber.invalid) {
        this.phoneNumber.markAsTouched();
      }
      this.updateCustomerVulnerability();
      this.disableSendButton.emit();
    });

    this.email.valueChanges.pipe(debounceTime(750), distinctUntilChanged()).subscribe(() => {
      this.updateCustomerVulnerability();
      this.disableSendButton.emit();
    });

    this.details.valueChanges.pipe(debounceTime(750), distinctUntilChanged()).subscribe(() => {
      if (this.details.invalid) {
        this.details.markAsTouched();
      }
      this.updateCustomerVulnerability();
      this.disableSendButton.emit();
    });
  }

  confirmDeleteVulnerability() {
    const data = {
      title: 'Delete Customer Vulnerability',
      alertMessage: 'This customer vulnerability will be deleted.',
      confirmButton: 'DELETE'
    };
    const dialogRef = this.confirmBox.open(
      ConfirmBoxComponent,
      this.modalConfigsHelper.buildMediumModalConfig(data, smallModalConfig)
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'DELETE') {
        this.deleteCustomerVulnerability();
      }
    });
  }

  deleteCustomerVulnerability() {
    this.toolbarService.setCloudState('SAVING');
    this.userService
      .deleteNotification(this.vulnerability.jobId, 'customerVulnerabilities', this.vulnerability.id)
      .subscribe(
        () => this.toolbarService.setCloudState('RESTING'),
        () => {
          this.message.error('Customer vulnerability could not be deleted.');
          this.toolbarService.setCloudState('RESTING');
        },
        () => {
          this.message.confirm('Customer vulnerability Deleted.');
          this.refreshJobViewer.emit();
        }
      );
  }

  updateCustomerVulnerability() {
    this.toolbarService.setCloudState('SAVING');
    const customerVulnerability: CustomerVulnerability = this.customerVulnerabilityForm.getRawValue();
    this.userService
      .updateNotification(this.vulnerability.jobId, 'customerVulnerabilities', customerVulnerability)
      .subscribe(
        () => this.toolbarService.setCloudState('RESTING'),
        () => {
          this.message.error('Vulnerability has not been saved');
          this.toolbarService.setCloudState('RESTING');
        },
        () => {
          this.refreshJobViewer.emit();
        }
      );
  }

  ngAfterViewInit() {
    this.elRef.nativeElement.scrollIntoView({
      behavior: 'smooth'
    });
  }

  removeLanguage(language: string) {
    this.languagesSpoken.value.splice(this.languagesSpoken.value.indexOf(language), 1);
    this.validateLanguageSpoken();
  }

  addLanguage(event: MatChipInputEvent | FocusEvent) {
    const input: any = event instanceof FocusEvent ? event.target : event.input;
    const value = ((event instanceof FocusEvent ? (event.target as HTMLInputElement).value : event.value) || '').trim();

    if ((value || '').trim()) {
      this.languagesSpoken.value.push(value.trim());
    }

    if (input) {
      input.value = '';
    }

    this.validateLanguageSpoken();
  }

  validateLanguageSpoken() {
    if (!this.languagesSpoken.value.length) {
      this.languagesSpoken.markAsTouched();
      this.languagesSpoken.setErrors({ required: true });
    } else {
      this.languagesSpoken.setErrors(null);
    }
    this.updateCustomerVulnerability();
    this.disableSendButton.emit();
  }
}
