<div *ngIf="authority" class="authority">
  <mat-expansion-panel class="curtains" #panel>
    <mat-expansion-panel-header [collapsedHeight]="'70px'" [expandedHeight]="'70px'">
      <mat-panel-title class="authority-title">
        <div>
          <div *ngIf="authority.date" class="received-date">
            {{ authority.date | dateepoch: timezone }} {{ authority.date | time: timezone }}
          </div>
          <div class="authority-number">
            <h5>Authority</h5>
            <div class="authorised-box" appHighlightStatus="ISSUED">ISSUED</div>
          </div>
        </div>
        <div>
          <div class="body-2">Authorised Parts</div>
          <div class="data-values subtitle-2">$ {{ authority?.assessedPartsTotal | number: '1.2-2' }}</div>
        </div>
        <div>
          <div class="body-2">Authorised Labour</div>
          <div class="data-values subtitle-2">$ {{ authority?.assessedLabourTotal | number: '1.2-2' }}</div>
        </div>
        <div>
          <div class="body-2">Authorised Sublet</div>
          <div class="data-values subtitle-2">$ {{ authority?.assessedSubletTotal | number: '1.2-2' }}</div>
        </div>
        <div>
          <div class="body-2">Total Authorised</div>
          <div class="data-values subtitle-2">$ {{ authority?.assessedTotal | number: '1.2-2' }}</div>
        </div>
      </mat-panel-title>
      <mat-panel-description class="kebab-panel">
        <div class="secondary-icon">
          <a (click)="showErrorAttachment(); $event.stopPropagation()" rel="noopener">
            <button mat-icon-button>
              <mat-icon>picture_as_pdf_24px</mat-icon>
            </button>
          </a>
        </div>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <mat-divider></mat-divider>
    <mat-panel-description class="authority-curtain-content">
      <div class="authority-content">
        <ng-container *ngIf="authority?.authorityComment">
          <div class="authority-comment">
            <div class="field-label body-2">Authority comment</div>
            <p class="subtitle-2">{{ authority?.authorityComment }}</p>
          </div>
        </ng-container>
        <div class="owner-contribution">
          <form [formGroup]="authorityForm">
            <mat-form-field appearance="outline" class="field" color="accent">
              <mat-label>Owner Contribution</mat-label>
              <input (blur)="populateAuthorityValue()" formControlName="ownerContribution" matInput />
              <mat-icon *ngIf="authorityForm.controls.ownerContribution.value" matSuffix>attach_money_24px</mat-icon>
              <mat-error *ngIf="!authorityForm.controls.ownerContribution.valid">
                This is not a valid amount
              </mat-error>
            </mat-form-field>
          </form>
          <div class="excess">
            <div class="body-2">Total Excess</div>
            <div class="data-values subtitle-2">
              <span *ngIf="authority?.excess">$</span>
              <span *ngIf="!(authority?.excess && authority?.excessType)">{{ excessToDisplay }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="summary-content">
        <h3 class="summary-title">Summary</h3>
        <div class="summary-table">
          <table
            *ngIf="authorityData[0].labourSection.length"
            [dataSource]="authorityData[0].labourSection"
            class="table-style labour"
            mat-table>
            <ng-container matColumnDef="labour">
              <th *matHeaderCellDef mat-header-cell>Labour</th>
              <td
                *matCellDef="let item"
                [ngClass]="{ 'principal-section body-2': !item.subSection, 'sub-section body-1': item.subSection }"
                class="section"
                mat-cell>
                <div>
                  {{ item.section }}
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="estimated">
              <th *matHeaderCellDef mat-header-cell>Estimated</th>
              <td *matCellDef="let item" class="estimated body-1" mat-cell>
                <div *ngIf="item.estimated">
                  <div class="amount-cell">
                    <div class="sign-cell">$</div>
                    <div>{{ item.estimated | number: '1.2-2' }}</div>
                  </div>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="assessed">
              <th *matHeaderCellDef mat-header-cell>Assessed</th>
              <td *matCellDef="let item" class="assessed body-1" mat-cell>
                <div *ngIf="item.estimated">
                  <div class="amount-cell">
                    <div class="sign-cell">$</div>
                    <div>{{ item.assessed | number: '1.2-2' }}</div>
                  </div>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="assessedTotals">
              <th *matHeaderCellDef mat-header-cell></th>
              <td *matCellDef="let item" class="assessedTotals" mat-cell>
                <div>
                  <ng-container
                    *ngIf="item.estimated && item.assessed"
                    [ngTemplateOutletContext]="{ estimatedAmount: item.estimated, assessedAmount: item.assessed }"
                    [ngTemplateOutlet]="assessedTotals">
                  </ng-container>
                </div>
              </td>
            </ng-container>
            <tr *matHeaderRowDef="labourColumnHeaders" mat-header-row></tr>
            <tr *matRowDef="let row; columns: labourColumnHeaders" mat-row></tr>
          </table>

          <table
            *ngIf="authorityData[0].partSection.length"
            [dataSource]="authorityData[0].partSection"
            class="table-style part"
            mat-table>
            <ng-container matColumnDef="parts">
              <th *matHeaderCellDef mat-header-cell>Parts</th>
              <ng-container></ng-container>
              <td
                *matCellDef="let item"
                [ngClass]="{ 'principal-section body-2': !item.subSection, 'sub-section body-1': item.subSection }"
                class="section"
                mat-cell>
                <div>
                  {{ item.section }}
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="estimated">
              <th *matHeaderCellDef mat-header-cell>Estimated</th>
              <td *matCellDef="let item" class="estimated body-1" mat-cell>
                <ng-container *ngIf="item.estimated">
                  <div class="amount-cell">
                    <div class="sign-cell">{{ item.section === 'Markup' ? '%' : '$' }}</div>
                    <div>{{ item.estimated | number: '1.2-2' }}</div>
                  </div>
                </ng-container>
              </td>
            </ng-container>
            <ng-container matColumnDef="assessed">
              <th *matHeaderCellDef mat-header-cell>Assessed</th>
              <td *matCellDef="let item" class="assessed body-1" mat-cell>
                <ng-container *ngIf="item.assessed">
                  <div class="amount-cell">
                    <div class="sign-cell">{{ item.section === 'Markup' ? '%' : '$' }}</div>
                    <div>{{ item.assessed | number: '1.2-2' }}</div>
                  </div>
                </ng-container>
              </td>
            </ng-container>
            <ng-container matColumnDef="assessedTotals">
              <th *matHeaderCellDef mat-header-cell></th>
              <td *matCellDef="let item" class="assessedTotals" mat-cell>
                <div>
                  <ng-container
                    *ngIf="item.estimated && item.assessed"
                    [ngTemplateOutletContext]="{ estimatedAmount: item.estimated, assessedAmount: item.assessed }"
                    [ngTemplateOutlet]="assessedTotals">
                  </ng-container>
                </div>
              </td>
            </ng-container>
            <tr *matHeaderRowDef="partsColumnHeaders" mat-header-row></tr>
            <tr *matRowDef="let row; columns: partsColumnHeaders" mat-row></tr>
          </table>

          <table
            *ngIf="authorityData[0].subletSection.length"
            [dataSource]="authorityData[0].subletSection"
            class="table-style sublet"
            mat-table>
            <ng-container matColumnDef="sublets">
              <th *matHeaderCellDef mat-header-cell>Sublets</th>
              <td
                *matCellDef="let item"
                [ngClass]="{ 'principal-section body-2': !item.subSection, 'sub-section body-1': item.subSection }"
                class="section"
                mat-cell>
                <div>
                  {{ item.section }}
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="estimated">
              <th *matHeaderCellDef mat-header-cell>Estimated</th>
              <td *matCellDef="let item" class="estimated body-1" mat-cell>
                <ng-container *ngIf="item.estimated">
                  <div class="amount-cell">
                    <div class="sign-cell">$</div>
                    <div>{{ item.estimated | number: '1.2-2' }}</div>
                  </div>
                </ng-container>
              </td>
            </ng-container>
            <ng-container matColumnDef="assessed">
              <th *matHeaderCellDef mat-header-cell>Assessed</th>
              <td *matCellDef="let item" class="assessed body-1" mat-cell>
                <ng-container *ngIf="item.assessed">
                  <div class="amount-cell">
                    <div class="sign-cell">$</div>
                    <div>{{ item.assessed | number: '1.2-2' }}</div>
                  </div>
                </ng-container>
              </td>
            </ng-container>
            <ng-container matColumnDef="assessedTotals">
              <th *matHeaderCellDef mat-header-cell></th>
              <td *matCellDef="let item" class="assessedTotals" mat-cell>
                <div>
                  <ng-container
                    *ngIf="item.estimated && item.assessed"
                    [ngTemplateOutletContext]="{ estimatedAmount: item.estimated, assessedAmount: item.assessed }"
                    [ngTemplateOutlet]="assessedTotals">
                  </ng-container>
                </div>
              </td>
            </ng-container>
            <tr *matHeaderRowDef="subletsColumnHeaders" mat-header-row></tr>
            <tr *matRowDef="let row; columns: subletsColumnHeaders" mat-row></tr>
          </table>

          <table
            *ngIf="authorityData[0].totalSection.length"
            [dataSource]="authorityData[0].totalSection"
            class="table-style totals"
            mat-table>
            <ng-container matColumnDef="section">
              <th *matHeaderCellDef mat-header-cell></th>
              <td
                *matCellDef="let item"
                [ngClass]="{ 'principal-section body-2': !item.subSection, 'sub-section body-1': item.subSection }"
                class="section"
                mat-cell>
                <div>
                  {{ item.section }}
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="estimated">
              <th *matHeaderCellDef mat-header-cell></th>
              <td *matCellDef="let item" class="estimated body-1" mat-cell>
                <ng-container *ngIf="item.estimated">
                  <div class="amount-cell">
                    <div class="sign-cell">$</div>
                    <div>{{ item.estimated | number: '1.2-2' }}</div>
                  </div>
                </ng-container>
              </td>
            </ng-container>
            <ng-container matColumnDef="assessed">
              <th *matHeaderCellDef mat-header-cell></th>
              <td *matCellDef="let item" class="assessed body-1" mat-cell>
                <ng-container *ngIf="item.assessed">
                  <div class="amount-cell">
                    <div class="sign-cell">$</div>
                    <div>{{ item.assessed | number: '1.2-2' }}</div>
                  </div>
                </ng-container>
              </td>
            </ng-container>
            <ng-container matColumnDef="assessedTotals">
              <th *matHeaderCellDef mat-header-cell></th>
              <td *matCellDef="let item" class="assessedTotals" mat-cell>
                <div>
                  <ng-container
                    *ngIf="item.estimated && item.assessed"
                    [ngTemplateOutletContext]="{ estimatedAmount: item.estimated, assessedAmount: item.assessed }"
                    [ngTemplateOutlet]="assessedTotals">
                  </ng-container>
                </div>
              </td>
            </ng-container>
            <tr *matHeaderRowDef="totalsColumns" hidden mat-header-row></tr>
            <tr *matRowDef="let row; columns: totalsColumns" mat-row></tr>
          </table>
        </div>
      </div>
    </mat-panel-description>
  </mat-expansion-panel>
</div>

<ng-template #assessedTotals let-assessedAmount="assessedAmount" let-estimatedAmount="estimatedAmount">
  <div *ngIf="authority" class="assess-indicator margin">
    <div
      [ngClass]="{ ASSESSED_UNDER: assessedAmount < estimatedAmount, ASSESSED_OVER: assessedAmount > estimatedAmount }"
      class="indicator small margin-left-10">
      <mat-icon *ngIf="assessedAmount < estimatedAmount">keyboard_arrow_down</mat-icon>
      <mat-icon *ngIf="assessedAmount > estimatedAmount">keyboard_arrow_up</mat-icon>
    </div>
  </div>
  <span *ngIf="assessedAmount != estimatedAmount" class="overview-diff body-2">
    <span *ngIf="assessedAmount < estimatedAmount"> - </span>
    <span *ngIf="assessedAmount > estimatedAmount"> + </span>
    {{ Math.abs(assessedAmount - estimatedAmount) | number: '1.2-2' }}
  </span>
</ng-template>
