import { AfterViewInit, ChangeDetectorRef, Component, forwardRef, inject, OnDestroy, OnInit } from '@angular/core';
import { Address, AU_STATES } from '../../model/address.model';
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators
} from '@angular/forms';
import { merge, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GoogleMapsService } from '../../services/google-map.service';

// Provider for NG_VALUE_ACCESSOR
const CUSTOM_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => AddressBlockComponent),
  multi: true
};

// Provider for NG_VALIDATORS
const CUSTOM_VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => AddressBlockComponent),
  multi: true
};
@Component({
  selector: 'address-block',
  templateUrl: './address-block.component.html',
  styleUrls: ['./address-block.component.scss'],
  providers: [CUSTOM_VALUE_ACCESSOR, CUSTOM_VALIDATOR]
})
export class AddressBlockComponent implements OnInit, OnDestroy, AfterViewInit, ControlValueAccessor, Validator {
  isGeolocalized = false;
  form: FormGroup;
  readonly stateOptions = AU_STATES;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  fb = inject(FormBuilder);
  cd = inject(ChangeDetectorRef);
  googleMapsService = inject(GoogleMapsService);
  get addressLine1() {
    return this.form.controls.addressLine1;
  }
  get country() {
    return this.form.controls.country;
  }
  get postCode() {
    return this.form.controls.postCode;
  }
  get state() {
    return this.form.controls.state;
  }

  ngOnInit() {
    this.form = this.fb.group({
      addressLine1: ['', Validators.required],
      addressLine2: '',
      postCode: ['', Validators.required],
      state: '',
      city: '',
      suburb: '',
      country: '',
      latitude: null,
      longitude: null
    });

    this.form.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => this.onChange(this.form.value));

    merge(
      this.addressLine1.valueChanges,
      this.form.get('state').valueChanges,
      this.form.get('city').valueChanges,
      this.form.get('suburb').valueChanges,
      this.form.get('country').valueChanges,
      this.postCode.valueChanges
    )
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.isGeolocalized = false;
        this.form.get('latitude').setValue(null);
        this.form.get('longitude').setValue(null);
      });
  }

  ngAfterViewInit() {
    if (this.country.value === 'Australia') {
      this.state.setValidators(Validators.required);
      this.state.updateValueAndValidity({ emitEvent: false });
    }
  }

  onChange = (value: Address) => {};
  onTouched: () => {};
  validateFn: (value: any) => void = (value: any) => {};
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }
  registerOnChange(fn: any) {
    this.onChange = fn;
  }
  registerOnValidatorChange(fn: () => void): void {
    this.validateFn = fn;
  }
  setDisabledState(isDisabled: boolean) {
    isDisabled ? this.form?.disable({ emitEvent: false }) : this.form?.enable({ emitEvent: false });
  }
  writeValue(address: Address) {
    this.isGeolocalized = !!address?.latitude && !!address?.longitude;
    this.form.patchValue(address, { emitEvent: false });
  }
  validate(control: FormControl): ValidationErrors | null {
    return this.form.invalid ? { invalid: true } : null;
  }
  chosenAddress(address: Address) {
    this.isGeolocalized = true;
    this.form.patchValue(address, { emitEvent: false });
    this.onChange(this.form.value);
    this.cd.detectChanges();
  }
  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
