import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfirmCredentials, LoginCredentials, ResetPasswordPayload } from '../model/user.model';
import { share } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(private http: HttpClient) {}

  login = (creds: LoginCredentials) => this.http.post('auth/login', creds);

  refresh = () => this.http.post('auth/refresh', null).pipe(share());

  confirm = (creds: ConfirmCredentials) => this.http.post('auth/password', creds);

  logout = () => this.http.post('auth/logout', null);

  reset = (body: ResetPasswordPayload) => this.http.post('auth/resetPassword', body);
}
