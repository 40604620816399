import { CommonModule, DecimalPipe } from '@angular/common';
import { Component, computed, EventEmitter, inject, Input, Output, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { InfosActionCardComponent } from '../../../../components/infos-action-card/infos-action-card.component';
import { InfosContentComponent } from '../../../../components/infos-content/infos-content.component';
import { StatusChipComponent } from '../../../../components/status-chip/status-chip.component';
import { Invoice } from '../../../../model/document.model';
import { InvoiceStore } from '../invoice.store.component';

@Component({
  selector: 'estimage-pay-guidance',
  standalone: true,
  host: { class: 'w-full' },
  templateUrl: './estimage-pay-guidance.component.html',
  imports: [
    CommonModule,
    MatIconModule,
    InfosActionCardComponent,
    StatusChipComponent,
    DecimalPipe,
    MatButtonModule,
    InfosContentComponent
  ]
})
export class EstimagePayGuidanceComponent {
  @Input() submitted: boolean;
  @Input() totalPayable: number;
  @Output() useEstimagePay: EventEmitter<void> = new EventEmitter();

  @Input() set invoice(invoice: Invoice) {
    this._invoice.set(invoice);
  }

  #invoiceStore = inject(InvoiceStore);

  _invoice = signal<Invoice>(null);

  invoiceApprovedOrAccepted = computed(() => ['APPROVED', 'ADJUSTED'].includes(this._invoice().status));

  paymentFailed = computed(() => ['FAILED'].includes(this._invoice().estimagePayPaymentRequestStatus));

  invoiceRejected = computed(() => ['REJECTED'].includes(this._invoice().status));

  removeEstImagePay(): void {
    this.#invoiceStore.removeEstimagePay(this._invoice().id);
  }
}
