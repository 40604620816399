<div class="dialog-header" mat-dialog-title>
  <h6>{{ data ? 'Edit' : 'Add' }} Sysadmin</h6>
  <button aria-label="Close" mat-dialog-close mat-icon-button>
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-divider></mat-divider>
<form (ngSubmit)="saveUpdateSysadmin()" [formGroup]="sysadminForm">
  <div mat-dialog-content>
    <div [class.padding-bottom-22]="!username.valid && !username.hasError('required')" class="name-line">
      <mat-form-field appearance="outline" color="accent">
        <mat-label>Username</mat-label>
        <input formControlName="username" matInput required />
        <mat-error *ngIf="username.hasError('required')"> Username is <strong>required</strong> </mat-error>
        <mat-error *ngIf="!username.valid && !username.hasError('required')">
          This username exists already in the system
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" color="accent">
        <mat-label>First Name</mat-label>
        <input formControlName="firstName" matInput required />
        <mat-error *ngIf="firstName.hasError('required')"> First Name is <strong>required</strong> </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" color="accent">
        <mat-label>Last Name</mat-label>
        <input formControlName="lastName" matInput required />
        <mat-error *ngIf="lastName.hasError('required')"> Last Name is <strong>required</strong> </mat-error>
      </mat-form-field>
    </div>
    <mat-form-field appearance="outline" color="accent">
      <mat-label>Email</mat-label>
      <input formControlName="email" matInput required />
      <mat-error *ngIf="email.hasError('required')"> Email is <strong>required</strong> </mat-error>
      <mat-error *ngIf="!email.valid && !email.hasError('required')"> Please enter a valid email address </mat-error>
    </mat-form-field>
    <div class="email-info" *ngIf="!data?.isActivated">
      <mat-icon>error_outline</mat-icon>
      <span>An email will be sent to this email address to complete account information</span>
    </div>
  </div>
  <mat-dialog-actions>
    <button mat-raised-button mat-dialog-close color="accent" type="button">CANCEL</button>
    <button
      [disabled]="sysadminForm.invalid || pending"
      class="buttons"
      color="primary"
      mat-raised-button
      type="submit">
      {{ !data || (data && !data?.isActivated) ? 'INVITE' : 'SAVE' }}
    </button>
  </mat-dialog-actions>
</form>
