import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MessagingService } from '../components/messaging/messaging.service';
import { UserService } from '../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { RedirectHelper } from '../helpers/redirect.helper';
import { CurrentUserService } from '../services/currentUser.service';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SetPasswordComponent implements OnInit {
  setPasswordForm: UntypedFormGroup;
  minCharacters = 14;
  nonce: string;
  user: string;
  name: string;
  errorMessage = '';
  hidePassword = true;
  hideConfirmPassword = true;
  submitPending = false;
  linkIsExpiredOrInvalid = false;
  pendingCheck = false;

  constructor(
    private auth: AuthService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private messagingService: MessagingService,
    private redirectHelper: RedirectHelper,
    private currentUserService: CurrentUserService
  ) {}

  get username() {
    return this.setPasswordForm.get('username');
  }

  get password() {
    return this.setPasswordForm.get('password');
  }

  get confirmPassword() {
    return this.setPasswordForm.get('confirmPassword');
  }

  static checkPasswords(group: UntypedFormGroup) {
    if (
      group.controls.username.value &&
      group.controls.password.value.trim().toLowerCase().includes(group.controls.username.value.toLowerCase())
    ) {
      group.controls.password.setErrors({ containUsername: true });
    } else if (group.controls.password.errors && group.controls.password.errors.containUsername) {
      group.controls.password.setErrors(null);
    }
    if (group.controls.password.value !== group.controls.confirmPassword.value) {
      group.controls.confirmPassword.setErrors({ passwordMismatch: true });
    } else {
      group.controls.confirmPassword.setErrors(null);
    }
  }

  ngOnInit() {
    this.route.paramMap.subscribe(
      (params) => {
        this.nonce = params.get('nonce');
        this.name = params.get('name').split('+').join(' ');
        this.linkIsExpiredOrInvalid = new Date().getTime() / 1000 > Number(params.get('expiry'));
      },
      () => this.messagingService.error('There is a problem with your link. Please check that it was copied exactly.')
    );

    this.setPasswordForm = this.formBuilder.group(
      {
        username: ['', [Validators.required]],
        password: ['', [Validators.required, this.passwordValidator.bind(this)]],
        confirmPassword: ['', [Validators.required]]
      },
      {
        validator: SetPasswordComponent.checkPasswords
      }
    );
  }

  setPassword() {
    this.username.disable();
    this.password.disable();
    this.confirmPassword.disable();
    this.submitPending = true;
    this.errorMessage = '';

    this.auth
      .confirm({
        username: this.username.value,
        password: this.password.value,
        nonce: this.nonce
      })
      .subscribe(
        () =>
          this.auth.login({ username: this.username.value, password: this.password.value }).subscribe(
            () => {
              this.userService.getCurrentUser().subscribe(
                (user) => {
                  this.currentUserService.userLogin(user);
                },
                () => {
                  this.messagingService.error('There was a problem authenticating you. Please try again.');
                  this.router.navigate(['login']).catch();
                },
                () => {
                  this.redirectHelper.manageRedirection(this.currentUserService.currentUserValue, this.router);
                }
              );
            },
            () => {
              this.messagingService.error('There was a problem authenticating you. Please try again.');
              this.router.navigate(['login']).catch();
            }
          ),
        (error) => {
          if (error.status === 409) {
            switch (error.error.errorCode) {
              case 'TOO_SHORT':
                this.password.setErrors({ minlength: true });
                break;
              case 'TOO_COMMON':
                this.errorMessage =
                  'The password is too common and will not protect your account. Please pick a more unique password.';
                break;
              case 'RECENTLY_USED':
                this.errorMessage = 'Must not be one of your 10 previous passwords.';
                break;
              case 'WRONG_CREDENTIALS':
                this.errorMessage =
                  'The credentials you provided are incorrect, please contact your administrator or contact Stelvio Support at (02) 9455 1055 if you need any assistance.';
                break;
              case 'PASSWORD_CONTAINS_USERNAME':
                this.password.setErrors({ containUsername: true });
                break;
            }
          } else {
            // Generic error
            this.messagingService.error('There was a problem setting your password. Please try again');
          }

          this.submitPending = false;
          this.username.enable();
          this.password.enable();
          this.confirmPassword.enable();
        }
      );
  }

  private passwordValidator(password: UntypedFormControl) {
    if (password && password.value.trim().length < this.minCharacters) {
      return { minlength: true };
    } else {
      return false;
    }
  }
}
