<div class="bucket-header tab-quotes" [class.extra-height]="1280 | breakObserver: 'max' | async">
  <div class="headers">
    <ng-template ngFor let-bucketDetail [ngForOf]="bucketDetails">
      <div
        class="bucket"
        (click)="scrollBucket.emit(bucketDetail?.bucketName)"
        [class.bucket-selected]="bucketSelected === bucketDetail?.bucketName">
        <div class="labels body-2">{{ bucketDetail?.label }}</div>
        <h6>{{ bucketDetail?.bucketLength }}</h6>
      </div>
    </ng-template>
  </div>
  <section class="flex items-center">
    <div class="dates mr-30">
      <div *ngIf="dates?.oldestReceivedOn" class="oldest-received">
        <div class="labels body-2">Oldest received on</div>
        <div class="date subtitle-2">
          {{ dates?.oldestReceivedOn | dateepoch: dates?.timezone }}
          {{ dates?.oldestReceivedOn | time: dates?.timezone }}
        </div>
      </div>
      <div *ngIf="dates?.newestReceivedOn">
        <div class="labels body-2">Newest received on</div>
        <div class="date subtitle-2">
          {{ dates?.newestReceivedOn | dateepoch: dates?.timezone }}
          {{ dates?.newestReceivedOn | time: dates?.timezone }}
        </div>
      </div>
    </div>
    <div [matTooltip]="setUnreadJobsTooltipMessage()">
      <button
        mat-icon-button
        color="accent"
        class="custom-mat-button bg-white rounded border border-solid border-silver"
        [ngClass]="{ 'border-b-4 border-b-primary-500': unreadJobFilter.isUnreadFilterActivated }"
        [disabled]="!unreadJobFilter.hasUnreadJobs && !unreadJobFilter.isUnreadFilterActivated"
        (click)="this.onClickUnreadFilterButton()">
        <mat-icon
          svgIcon="unread_job"
          class="custom-mat-icon mat-icon-md"
          [ngClass]="{
            'contrast-0 opacity-50': !unreadJobFilter.hasUnreadJobs && !unreadJobFilter.isUnreadFilterActivated
          }"></mat-icon>
      </button>
    </div>
  </section>
</div>
