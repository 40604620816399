import { Component } from '@angular/core';
import { CheckForUpdateService } from './services/checkForUptade.service';
import * as moment from 'moment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'EstImage Online';
  constructor(private swCheckUpdate: CheckForUpdateService) {
    this.swCheckUpdate.checkForUpdates();
    if (localStorage.getItem('timeZone')) {
      moment.tz.setDefault(localStorage.getItem('timeZone'));
    }
  }
}
