import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Job, PhotoReference } from '../../../model/job.model';
import { LightboxService } from '../lightbox/lightbox.service';
import { ConfirmBoxComponent } from '../../../components/confirm-box/confirm-box.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ToolbarService } from '../../../services/toolbar.service';
import { UserService } from '../../../services/user.service';
import { MessagingService } from '../../../components/messaging/messaging.service';
import { CurrentUserService } from '../../../services/currentUser.service';
import { User } from '../../../model/user.model';
import { MatExpansionPanel } from '@angular/material/expansion';
import { smallModalConfig } from '../../../consts/modal-config.const';
import { ModalConfigsHelper } from '../../../helpers/modal-configs.helper';

@Component({
  selector: 'app-gallery-curtain',
  templateUrl: './gallery-curtain.component.html',
  styleUrls: ['./gallery-curtain.component.scss'],
  providers: [LightboxService]
})
export class GalleryCurtainComponent implements OnInit, OnChanges {
  @Input() job: Job;
  @Input() photos: PhotoReference[];
  @Input() quote: boolean;
  @Input() shouldCollapseCurtain: boolean;
  @Output() refreshJobViewer: EventEmitter<any> = new EventEmitter();
  @Output() updateSelectedPhoto: EventEmitter<PhotoReference> = new EventEmitter();
  @ViewChild('panel', { static: false }) panel: MatExpansionPanel;

  galleryCurtainOpened = false;
  photoLoaded = [];
  currentUser: User;

  constructor(
    private lightboxService: LightboxService,
    private userService: UserService,
    private confirmBox: MatDialog,
    public toolbarService: ToolbarService,
    private message: MessagingService,
    private currentUserService: CurrentUserService,
    private modalConfigsHelper: ModalConfigsHelper
  ) {}

  ngOnInit(): void {
    this.currentUser = this.currentUserService.currentUserValue;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.shouldCollapseCurtain && !changes.shouldCollapseCurtain.firstChange) {
      if (changes.shouldCollapseCurtain.currentValue) {
        this.panel.close();
      }
    }
  }

  openLightbox(selectedIndex) {
    this.lightboxService.open({
      imageGallerySelector: {
        images: this.photos,
        repairerSiteId: this.job.repairerSiteId,
        jobId: this.job.jobId,
        selectedIndex
      }
    });
  }

  confirmDeleteUnsubmittedphotos() {
    const data = {
      title: 'Delete All Photos',
      alertMessage: this.photos.length + (this.photos.length === 1 ? ' photo' : ' photos') + ' will be deleted.',
      confirmButton: 'DELETE'
    };
    const dialogRef = this.confirmBox.open(
      ConfirmBoxComponent,
      this.modalConfigsHelper.buildMediumModalConfig(data, smallModalConfig)
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'DELETE') {
        this.deleteUnsubmittedphotos();
      }
    });
  }

  deleteUnsubmittedphotos() {
    this.toolbarService.setCloudState('SAVING');
    this.userService.deleteUnsubmittedPhotos(this.job.jobId).subscribe(
      () => this.toolbarService.setCloudState('RESTING'),
      () => {
        this.message.error('Photos could not be deleted.');
        this.toolbarService.setCloudState('RESTING');
      },
      () => {
        this.message.confirm(this.photos.length + (this.photos.length === 1 ? ' Photo' : ' Photos') + ' Deleted.');
        this.refreshJobViewer.emit();
      }
    );
  }

  confirmDeletePhoto(id: number, jobId: number) {
    const data = { title: 'Delete Photo', alertMessage: 'Your photo will be deleted.', confirmButton: 'DELETE' };
    const dialogRef = this.confirmBox.open(
      ConfirmBoxComponent,
      this.modalConfigsHelper.buildMediumModalConfig(data, smallModalConfig)
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'DELETE') {
        this.deletePhoto(id, jobId);
      }
    });
  }

  deletePhoto(id: number, jobId: number) {
    this.toolbarService.setCloudState('SAVING');
    this.userService.deleteJobFile(id, jobId, 'deleteJobPhoto').subscribe(
      () => this.toolbarService.setCloudState('RESTING'),
      () => {
        this.message.error('Photo could not be deleted.');
        this.toolbarService.setCloudState('RESTING');
      },
      () => {
        this.message.confirm('Photo Deleted.');
        this.refreshJobViewer.emit();
      }
    );
  }

  selectPhoto(photo: PhotoReference) {
    this.userService.selectTopImage(this.job.jobId, photo.id).subscribe(
      () => this.updateSelectedPhoto.emit(photo),
      () => this.message.error('Picture could not be selected.'),
      () => this.message.confirm('New top picture selected.')
    );
  }
}
