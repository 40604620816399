<div class="header-container">
  <h3>Function Invocation Stats</h3>
</div>
<mat-divider></mat-divider>
<div>
  <table
    #functionInvocationStatsSort="matSort"
    (matSortChange)="functionInvocationStatsActiveColumn = $event.active"
    [dataSource]="functionInvocationStatsListDataSource"
    class="table-style"
    mat-table
    matSort
    matSortActive="functionName"
    matSortDirection="asc"
    matSortDisableClear>
    <ng-container matColumnDef="functionName">
      <th
        *matHeaderCellDef
        [class.active]="functionInvocationStatsActiveColumn === 'functionName'"
        mat-header-cell
        mat-sort-header>
        Function
      </th>
      <td *matCellDef="let functionInvocationStats" mat-cell>{{ functionInvocationStats.functionName }}</td>
    </ng-container>
    <ng-container matColumnDef="totalExecutionTimeMilli">
      <th
        *matHeaderCellDef
        [class.active]="functionInvocationStatsActiveColumn === 'totalExecutionTimeMilli'"
        mat-header-cell
        mat-sort-header>
        Total Execution Time (ms)
      </th>
      <td *matCellDef="let functionInvocationStats" mat-cell>{{ functionInvocationStats.totalExecutionTimeMilli }}</td>
    </ng-container>
    <ng-container matColumnDef="averageExecutionTimeMilli">
      <th
        *matHeaderCellDef
        [class.active]="functionInvocationStatsActiveColumn === 'averageExecutionTimeMilli'"
        mat-header-cell
        mat-sort-header>
        Average Execution Time (ms)
      </th>
      <td *matCellDef="let functionInvocationStats" mat-cell>
        {{
          functionInvocationStats.totalExecutionTimeMilli /
            (functionInvocationStats.nbSuccess + functionInvocationStats.nbFailure) | number: '1.2'
        }}
      </td>
    </ng-container>
    <ng-container matColumnDef="worstExecutionTimeMilli">
      <th
        *matHeaderCellDef
        [class.active]="functionInvocationStatsActiveColumn === 'worstExecutionTimeMilli'"
        mat-header-cell
        mat-sort-header>
        Worst Execution Time (ms)
      </th>
      <td *matCellDef="let functionInvocationStats" mat-cell>{{ functionInvocationStats.worstExecutionTimeMilli }}</td>
    </ng-container>
    <ng-container matColumnDef="nbSuccess">
      <th
        *matHeaderCellDef
        [class.active]="functionInvocationStatsActiveColumn === 'nbSuccess'"
        mat-header-cell
        mat-sort-header>
        Success
      </th>
      <td *matCellDef="let functionInvocationStats" mat-cell>{{ functionInvocationStats.nbSuccess }}</td>
    </ng-container>
    <ng-container matColumnDef="nbFailure">
      <th
        *matHeaderCellDef
        [class.active]="functionInvocationStatsActiveColumn === 'nbFailure'"
        mat-header-cell
        mat-sort-header>
        Failure
      </th>
      <td *matCellDef="let functionInvocationStats" mat-cell>{{ functionInvocationStats.nbFailure }}</td>
    </ng-container>
    <tr *matHeaderRowDef="functionInvocationStatsListColumnHeaders" mat-header-row></tr>
    <tr *matRowDef="let row; columns: functionInvocationStatsListColumnHeaders" mat-row></tr>
  </table>
  <div *ngIf="!siteFunctionInvocationStats.functionInvocationStatsList?.length">
    <div class="no-results">No function executions since last wipe.</div>
  </div>
</div>

<div class="report-button">
  <ng-container>
    <button
      color="primary"
      (click)="getSiteFunctionInvocationStats()"
      mat-raised-button
      class="generate-report-button"
      type="submit">
      FETCH STATS
    </button>
  </ng-container>
</div>

<div class="report-button">
  <ng-container>
    <button
      color="accent"
      (click)="clearSiteFunctionInvocationStats()"
      mat-raised-button
      class="generate-report-button"
      type="submit">
      CLEAR STATS
    </button>
  </ng-container>
</div>
