import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { CoreQuotingSystem } from '../../../model/quoting-system/core-quoting-system.model';
import { MessagingService } from '../../../components/messaging/messaging.service';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig
} from '@angular/material/legacy-dialog';
import { ToolbarService } from '../../../services/toolbar.service';
import { UserService } from '../../../services/user.service';
import { SysAdminService } from '../../../services/sys-admin.service';
import { MatSort } from '@angular/material/sort';
import { AddQuotingSystemComponent } from '../add-quoting-system/add-quoting-system.component';
import { QuotingSystemUpdate } from '../../../model/quoting-system/quoting-system-create.model';
import { ConfirmBoxComponent } from '../../../components/confirm-box/confirm-box.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ModalConfigsHelper } from '../../../helpers/modal-configs.helper';
import { smallModalConfig, wideModalConfig } from '../../../consts/modal-config.const';

@Component({
  selector: 'app-quoting-system-section',
  templateUrl: './quoting-system-section.component.html',
  styleUrls: ['./quoting-system-section.component.scss']
})
export class QuotingSystemSectionComponent implements OnChanges, OnDestroy {
  @Input() quotingSystemList: CoreQuotingSystem[] = [];
  @Output() updateQuotingSystemList: EventEmitter<any> = new EventEmitter();
  @Output() confirmRevokeAllTokens: EventEmitter<any> = new EventEmitter();
  @Output() configureInterfaces: EventEmitter<any> = new EventEmitter();
  @ViewChild('quotingSystemSort', { static: true }) quotingSystemSort: MatSort;
  quotingSystemListDataSource: MatTableDataSource<CoreQuotingSystem>;
  quotingSystemExpanded = false;
  quotingSystemActiveColumn = 'companyName';
  quotingSystemListColumnHeaders = ['companyName', 'contactPhoneNumber', 'oAuth2Enabled', 'kebab'];
  private unsubscribe = new Subject();

  constructor(
    private message: MessagingService,
    private editSysadmin: MatDialog,
    public toolbarService: ToolbarService,
    private userService: UserService,
    private sysAdminService: SysAdminService,
    private addQSystem: MatDialog,
    private confirmBox: MatDialog,
    private modalConfigsHelper: ModalConfigsHelper
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    this.quotingSystemListDataSource = new MatTableDataSource(changes.quotingSystemList.currentValue);
    this.quotingSystemListDataSource.sortingDataAccessor = this.sortingQuotingSystem;
    this.quotingSystemListDataSource.sort = this.quotingSystemSort;
  }

  addEditQuotingSystem(quotingSystem?: QuotingSystemUpdate) {
    const dialogConfig: MatDialogConfig = wideModalConfig;
    dialogConfig.data = { quotingSystem } || null;
    const dialogRef = this.addQSystem.open(AddQuotingSystemComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'Success') {
        this.message.confirm(`Quoting system ${quotingSystem ? 'Edited' : 'Added'}`);
        this.updateQuotingSystemList.emit();
      }
    });
  }

  deleteQuotingSystem(clientId: string) {
    this.toolbarService.setCloudState('SAVING');
    this.sysAdminService
      .deleteQuotingSystem(clientId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: () => this.toolbarService.setCloudState('RESTING'),
        error: () => {
          this.message.error('Quoting System could not be deleted.');
          this.toolbarService.setCloudState('RESTING');
        },
        complete: () => {
          this.message.confirm('Quoting System Deleted.');
          this.updateQuotingSystemList.emit();
        }
      });
  }

  confirmDeleteQuotingSystem(clientId: string) {
    const data = {
      title: 'Delete Quoting System',
      alertMessage: 'Quoting System will be deleted.',
      confirmButton: 'DELETE'
    };
    const dialogRef = this.confirmBox.open(
      ConfirmBoxComponent,
      this.modalConfigsHelper.buildMediumModalConfig(data, smallModalConfig)
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'DELETE') {
        this.toolbarService.setCloudState('FETCHING');
        this.deleteQuotingSystem(clientId);
      }
    });
  }

  sortingQuotingSystem(item, property) {
    if (property === 'eventNotifications') {
      if (item.notificationsEnabled) {
        if (item.eventNotifications.filter((element) => element.checked).length !== 0) {
          return item[property]
            .filter((element) => element.checked)
            .map((element) => element.eventType)
            .join();
        } else {
          return 'No Events selected';
        }
      } else {
        return 'Disabled';
      }
    }
    return item[property] ? item[property].toString().toLowerCase() : '';
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
