import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogModule,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MultiLocalAdminService } from '../../../services/ml-admin.service';
import { EMAIL_PATTERN_STRING } from '../../../consts/patterns.const';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { EscapeDialogHelper } from '../../../helpers/escape-dialog.helper';
import { MladminModalRepairerSitesComponent } from './mladmin-modal-repairer-sites/mladmin-modal-repairer-sites.component';

@Component({
  selector: 'app-mladmin-modal',
  standalone: true,
  imports: [
    CommonModule,
    MatDividerModule,
    MatIconModule,
    MatLegacyButtonModule,
    MatLegacyDialogModule,
    MatLegacyFormFieldModule,
    MatLegacyInputModule,
    ReactiveFormsModule,
    MladminModalRepairerSitesComponent
  ],
  templateUrl: './mladmin-modal.component.html'
})
export class MladminModalComponent implements OnInit {
  data = inject(MAT_DIALOG_DATA);
  form: UntypedFormGroup;
  repairerSiteSelected: number[] = [];

  private fb = inject(UntypedFormBuilder);
  private dialogRef = inject(MatDialogRef<MladminModalComponent>);
  private escapeDialogRef = inject(EscapeDialogHelper);
  mlAdminService = inject(MultiLocalAdminService);

  get username() {
    return this.form.get('username');
  }
  get firstName() {
    return this.form.get('firstName');
  }
  get lastName() {
    return this.form.get('lastName');
  }
  get email() {
    return this.form.get('email');
  }
  get phone() {
    return this.form.get('phone');
  }

  ngOnInit() {
    this.initializeForm();
    this.escapeDialogRef.escapeDialog(this.dialogRef);
  }

  private initializeForm() {
    const { username, firstName, lastName, emailAddress, phone } = this.data.user ?? {};
    this.form = this.fb.group({
      firstName: [firstName, Validators.required],
      lastName: [lastName, Validators.required],
      username: [
        {
          value: username,
          disabled: username
        },
        Validators.required
      ],
      email: [emailAddress, [Validators.required, Validators.pattern(EMAIL_PATTERN_STRING)]],
      phone
    });
  }

  removeDuplicateError() {
    if (this.username.hasError('usernameDuplicate')) {
      this.username.setErrors(null);
    }
  }

  submitMlAdminForm() {
    const request = !this.data.user
      ? this.mlAdminService.createMlAdmin(this.form.value, this.repairerSiteSelected)
      : this.mlAdminService.updateMlAdmin(this.username.value, this.form.value, this.repairerSiteSelected);
    request.subscribe((result) => {
      switch (result) {
        case 'SUCCESS':
          this.dialogRef.close();
          break;
        case 'DUPLICATE_USERNAME':
          this.username.setErrors({ usernameDuplicate: true });
          break;
      }
    });
  }
}
