import { Location } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  inject,
  OnDestroy,
  OnInit,
  signal,
  ViewChild,
  ViewEncapsulation,
  WritableSignal
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
  MatLegacyDialogRef as MatDialogRef,
  MatLegacyDialogState as MatDialogState
} from '@angular/material/legacy-dialog';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable, of, Subject, Subscription, timer } from 'rxjs';
import { filter, switchMap, takeUntil, takeWhile } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { MessagingService } from '../components/messaging/messaging.service';
import { extraWideModalConfig, mediumModalConfig, wideModalConfig } from '../consts/modal-config.const';
import { SocketClientState } from '../enums/socketClientState.enum';
import { BodyParserHelper } from '../helpers/body-parser.helper';
import { CleanActiveJobHelper } from '../helpers/clean-active-job.helper';
import { ModalConfigsHelper } from '../helpers/modal-configs.helper';
import { Refreshable } from '../model/refreshable.model';
import { User } from '../model/user.model';
import { CurrentUserService } from '../services/currentUser.service';
import { SysAdminService } from '../services/sys-admin.service';
import { ToolbarService } from '../services/toolbar.service';
import { UserService } from '../services/user.service';
import { WebRTCService } from '../services/webRTC.service';
import { WebsocketService } from '../services/websocket.service';
import { Privileges } from '../types/privileges.type';
import { ConfirmShareScreenComponent } from './confirm-share-screen/confirm-share-screen.component';
import { JobSearchComponent } from './job-search/job-search.component';
import { AccessRepairerSiteComponent } from './sysadmin-dashboard/access-repairer-site/access-repairer-site.component';
import { ViewEulaComponent } from './view-eula/view-eula.component';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ShellComponent implements OnInit, OnDestroy {
  @ViewChild('inputSearch', { static: false }) inputSearch: ElementRef<HTMLElement>;
  currentYear = new Date().getFullYear();
  currentUser: User;
  timezone: string;
  pageName: string;
  backLink: string;
  rego: string;
  rtcConfig: any;
  routedComponent: Refreshable;
  subscription: Subscription;
  subscriptionToWebsocket: Subscription;
  subscriptionToWebRTC: Subscription;
  searchJobsForm: UntypedFormGroup;
  userMenuOpened = false;
  supportMenuOpened = false;
  screenShareStarted: WritableSignal<boolean> = signal(false);
  screenShareConfirmationOpened = false;
  searchVal: BehaviorSubject<string>;
  backLinkActive = false;
  backLinkText: string;
  shareScreenDialogRef: MatDialogRef<ConfirmShareScreenComponent>;
  isMigrationCompleted: Observable<boolean> = of(true);
  isRepadmin: boolean;
  isRepairer: boolean;
  isSysadmin: boolean;
  isMlAdmin: boolean;
  private unsubscribe = new Subject();

  currentUserService = inject(CurrentUserService);
  sysAdminService = inject(SysAdminService);
  toolbarService = inject(ToolbarService);
  jobSearchDialog = inject(MatDialog);
  cleanActiveJobHelper = inject(CleanActiveJobHelper);
  #router = inject(Router);
  #cdRef = inject(ChangeDetectorRef);
  #fb = inject(UntypedFormBuilder);
  #location = inject(Location);
  #auth = inject(AuthService);
  #messageService = inject(MessagingService);
  #userService = inject(UserService);
  #webRtcService = inject(WebRTCService);
  #websocketService = inject(WebsocketService);
  #accessRepairerSite = inject(MatDialog);
  #shareScreen = inject(MatDialog);
  #viewEula = inject(MatDialog);
  #bodyParserHelper = inject(BodyParserHelper);
  #modalConfigsHelper = inject(ModalConfigsHelper);

  get searchInput() {
    return this.searchJobsForm.get('searchInput');
  }

  ngOnInit() {
    this.sysAdminService.getRepairerSiteAccessFromLocalStorage();
    this.checkUserPrivileges();
    this.sysAdminService.getFeaturesGated().subscribe();
    this.searchJobsForm = this.#fb.group({ searchInput: ['', []] });
    this.toolbarService.cloudState$.subscribe();
    this.currentUser = this.currentUserService.currentUserValue;
    this.timezone = this.currentUserService?.timeZone;
    this.#websocketService.establishWebSocketConnection('/ws');

    setTimeout(() => {
      if (this.#websocketService.connected && this.currentUserService.currentUserValue.repairerSiteId) {
        this.subscribeToScreenShare(this.currentUser.username);
      }
    }, 2000);

    this.#websocketService.connectionState.pipe(takeUntil(this.unsubscribe)).subscribe({
      next: (data) => {
        if (data === SocketClientState.ERROR) {
          this.#websocketService.establishWebSocketConnection('/ws');
        }
      }
    });

    this.getIceServerConfig();

    this.subscription = this.toolbarService.toolbarDataReceived$.subscribe({
      next: (data) => {
        this.checkUserPrivileges();
        this.pageName = data.pageName;
        this.backLink = data.backLink;
        this.rego = data.rego;
        this.#cdRef.detectChanges();
      }
    });

    this.activateBackLink(localStorage.getItem('previousUrl'));

    this.#router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.unsubscribe)
      )
      .subscribe((event: NavigationEnd) => {
        this.activateBackLink(event.url);
        this.sysAdminService.getRepairerSiteAccessFromLocalStorage();
        if (event.url.includes('sysadmin')) {
          this.isMigrationCompleted = of(true);
        }
      });

    this.#userService.isAlreadyCompleted.pipe(takeUntil(this.unsubscribe)).subscribe({
      next: (value) => {
        if (!value) {
          this.isMigrationCompleted = timer(0, 30000).pipe(
            switchMap(() => this.#userService.migrationCompletionStatus()),
            takeWhile((migrationStillRunning) => !migrationStillRunning, true)
          );
        }
      }
    });

    this.#userService.getRepairerGroup().pipe(takeUntil(this.unsubscribe)).subscribe();
  }

  private checkUserPrivileges() {
    const userPrivileges = this.currentUserService.currentUserValue.privileges || [];

    this.isRepairer = !userPrivileges.some((item) => ['REPADMIN', 'MLADMIN', 'SYSADMIN'].includes(item));
    this.isRepadmin =
      this.hasPrivilege(userPrivileges, 'REPADMIN') &&
      !this.hasPrivilege(userPrivileges, 'SYSADMIN') &&
      !this.hasPrivilege(userPrivileges, 'MLADMIN');
    this.isMlAdmin = this.hasPrivilege(userPrivileges, 'MLADMIN') && !this.hasPrivilege(userPrivileges, 'REPADMIN');
    this.isSysadmin = this.hasPrivilege(userPrivileges, 'SYSADMIN') && !this.hasPrivilege(userPrivileges, 'REPADMIN');
  }

  private hasPrivilege(privileges: string[], privilegeToCheck: string): boolean {
    return privileges.includes(privilegeToCheck);
  }

  navigateToPage(page) {
    this.#router.navigate([page]).then(() => {
      this.cleanActiveJobHelper.clearLocalStorageFromActiveJob();
      this.jobSearchDialog.closeAll();
    });
  }

  activateBackLink(prevURL: string) {
    if (localStorage.getItem('previousUrl')) {
      this.backLinkActive =
        (prevURL.indexOf('jobViewer') > -1 || prevURL.indexOf('claimList') > -1) &&
        this.#router.url.indexOf('jobViewer') > -1 &&
        localStorage.getItem('previousUrl') !== 'search';
      this.backLinkText = localStorage.getItem('previousUrl').indexOf('jobViewer') > -1 ? 'DASHBOARD' : 'CLAIM LIST';
    } else {
      this.backLinkActive = false;
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    if (this.subscriptionToWebRTC) {
      this.subscriptionToWebRTC.unsubscribe();
    }
    if (this.subscriptionToWebsocket) {
      this.subscriptionToWebsocket.unsubscribe();
    }
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.#websocketService.closeWebSocketConnection();
  }

  backWithLocation() {
    this.#location.back();
  }

  subscribeToScreenShare(repairerUserName: string) {
    this.subscriptionToWebsocket = this.#websocketService.clientStomp?.subscribe(
      `/repuser/screenshare/${repairerUserName}`,
      (event) => {
        const message = this.#bodyParserHelper.checkBodyValue(event.body) ? JSON.parse(event.body) : event.body;
        if (message.event === 'sysAdminRequestShare') {
          this.requestScreenSharing(message.data, repairerUserName);
        }
        if (message === 'STOP') {
          if (this.#webRtcService.displayMediaStream) {
            this.#webRtcService.displayMediaStream.getTracks().forEach((track) => {
              track.stop();
            });
          }
          this.#webRtcService.resetShareScreen();
          this.screenShareStarted.set(false);
        }
        if (message.event === 'stoppingShareScreen' || message.event === 'refreshSysAdmin') {
          this.#messageService.error(`The screen share was canceled by ${message.data}`);
          if (this.shareScreenDialogRef && this.shareScreenDialogRef.getState() === MatDialogState.OPEN) {
            this.shareScreenDialogRef.close();
            this.#webRtcService.resetShareScreen();
          } else {
            if (!this.screenShareStarted()) {
              setTimeout(() => location.reload(), 1000);
            }
          }
          this.screenShareStarted.set(false);
        }
      }
    );
  }

  subscribeToWebRTC(repairerUserName: string) {
    this.subscriptionToWebRTC = this.#websocketService.clientStomp.subscribe(
      `/repuser/webRTC/receiveSignal/${repairerUserName}`,
      (message) => {
        const content = JSON.parse(message.body);
        const data = content.data;
        switch (content.event) {
          case 'answer':
            this.#webRtcService.handleAnswer(data).then();
            break;
          case 'candidate':
            this.#webRtcService.handleCandidate(data).then();
            break;
          case 'stoppingShareScreen':
            this.#messageService.error(`The screen share was stopped by ${data}`);
            setTimeout(() => {
              if (this.#webRtcService.displayMediaStream) {
                this.#webRtcService.displayMediaStream.getTracks().forEach((track) => {
                  track.stop();
                });
              }
              this.#webRtcService.resetShareScreen();
            }, 500);
            break;
        }
      }
    );
  }

  getIceServerConfig() {
    if (!localStorage.getItem('RTCConfiguration')) {
      this.#userService
        .getConfig()
        .pipe(takeUntil(this.unsubscribe))
        .subscribe({
          next: (configuration: RTCConfiguration) => {
            this.rtcConfig = {
              iceServers: configuration,
              sdpSemantics: 'unified-plan',
              bundlePolicy: 'max-compat'
            };
            localStorage.setItem('RTCConfiguration', JSON.stringify(this.rtcConfig));
          },
          error: () => this.#messageService.error('Failed to get RTCConfiguration')
        });
    } else {
      this.rtcConfig = JSON.parse(localStorage.getItem('RTCConfiguration'));
    }
  }

  requestScreenSharing(sysAdminName: string, repairerUserName: string) {
    this.screenShareConfirmationOpened = true;
    this.shareScreenDialogRef = this.#shareScreen.open(
      ConfirmShareScreenComponent,
      this.#modalConfigsHelper.buildMediumModalConfig({ sysAdminName }, mediumModalConfig)
    );

    this.shareScreenDialogRef.afterClosed().subscribe((result) => {
      if (result === 'ACCEPT') {
        this.#webRtcService.establishWebRtcConnection(this.rtcConfig, `/repuser/webRTC/signal/${repairerUserName}`);
        this.#websocketService.send(`/repuser/screenshare/${repairerUserName}`, {
          event: 'repUserAcceptShare',
          data: this.currentUser.username
        });
        this.subscribeToWebRTC(repairerUserName);
        const displayMediaOptions = {
          video: {
            cursor: 'motion',
            displaySurface: ['browser']
          },
          audio: false,
          selfBrowserSurface: 'include'
        };
        const cursor = document.querySelector('.cursor-shell');
        cursor.setAttribute('style', 'visibility = "visible"');
        this.#webRtcService
          .getScreenSizeOnopen(
            displayMediaOptions,
            cursor,
            `/repuser/webRTC/signal/${repairerUserName}`,
            `${this.currentUser.firstName} ${this.currentUser.lastName}`
          )
          .then(() => {
            this.screenShareStarted.set(true);
          });
      } else {
        this.#websocketService.send(`/repuser/screenshare/${repairerUserName}`, {
          event: 'cancelScreenShare',
          data: `${this.currentUser.firstName} ${this.currentUser.lastName}`
        });
      }
    });
  }

  setRoutedComponent(component: Refreshable) {
    this.routedComponent = component;
    this.#cdRef.detectChanges(); // prevent 'expression has changed after it was checked' error
    this.#auth.refresh().subscribe();

    if (this.sysAdminService.repairerSiteAccessed()) {
      this.subscribeToScreenShare(this.sysAdminService.repairerSiteAccessed());
    }
  }

  logout = () =>
    this.#auth
      .logout()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: () => {
          this.#router.navigate(['login']).catch(() => window.location.replace('/'));
          this.currentUserService.userLogout();
        },
        error: () => this.#messageService.error('There was a problem logging you out. Please try again.'),
        complete: () => {
          // clears all keys stored
          localStorage.clear();
          if (this.screenShareConfirmationOpened) {
            this.#websocketService.send(`/repuser/screenshare/${this.currentUser.repairerSiteId}`, {
              event: 'userLeftApp',
              data: `${this.currentUser.firstName} ${this.currentUser.lastName}`
            });
            if (this.#webRtcService.displayMediaStream) {
              this.#webRtcService.displayMediaStream.getTracks().forEach((track) => {
                track.stop();
              });
            } else {
              window.location.reload();
            }
          }
          setTimeout(() => this.#websocketService.closeWebSocketConnection(), 3000);
        }
      });

  exitRepairerSite() {
    const data = { repairerSiteName: this.sysAdminService.repairerSiteAccessed() };
    const dialogRef = this.#accessRepairerSite.open(
      AccessRepairerSiteComponent,
      this.#modalConfigsHelper.buildMediumModalConfig(data, mediumModalConfig)
    );
    dialogRef.afterClosed().subscribe({
      next: (response) => {
        if (response?.result === 'CLEAR') {
          localStorage.removeItem('timeZone');
        }
      }
    });
  }

  menuOpened() {
    this.userMenuOpened = true;
  }

  searchJob() {
    if (!this.jobSearchDialog?.openDialogs.length) {
      const dialogConfig: MatDialogConfig = extraWideModalConfig;
      dialogConfig.panelClass = 'full-screen-dialog';
      dialogConfig.backdropClass = 'search-screen';
      dialogConfig.disableClose = false;
      dialogConfig.data = {
        searchValue: new BehaviorSubject(this.searchInput.value),
        userPrivilege: this.fetchTopUserPrivilege()
      };
      this.jobSearchDialog.open(JobSearchComponent, dialogConfig);
    } else {
      this.jobSearchDialog.openDialogs[0].componentInstance.data.searchValue.next(this.searchInput.value);
    }
    this.jobSearchDialog.afterAllClosed.subscribe({
      next: () => {
        this.searchInput.setValue('');
        this.inputSearch.nativeElement.blur();
      }
    });
  }

  private fetchTopUserPrivilege(): 'SYSADMIN' | 'REPADMIN' | 'MLADMIN' | null {
    const privilegeArray: Privileges[] = ['SYSADMIN', 'REPADMIN', 'MLADMIN'];
    const currentUserPrivileges = this.currentUserService.currentUserValue.privileges || [];
    const foundPrivilege = currentUserPrivileges.find((privilege) => privilegeArray.includes(privilege));

    return foundPrivilege || null;
  }

  displayEula() {
    this.#viewEula.open(ViewEulaComponent, wideModalConfig);
  }

  @HostListener('window:beforeunload')
  goToPage() {
    if (this.currentUserService.currentUserValue?.repairerSiteId) {
      this.#websocketService.send(`/repuser/screenshare/${this.currentUser.username}`, {
        event: 'refreshRepUser',
        data: `${this.currentUser.firstName} ${this.currentUser.lastName}`
      });
    }
  }

  @HostListener('window:blur', ['$event'])
  onBlur(event: any): void {
    if (event && this.currentUserService.currentUserValue?.repairerSiteId && this.screenShareStarted()) {
      this.#websocketService.send(`/repuser/screenshare/${this.currentUser.username}`, {
        event: 'pauseScreenShare',
        data: true
      });
    }
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: FocusEvent): void {
    if (event && this.currentUserService.currentUserValue?.repairerSiteId && this.screenShareStarted()) {
      this.#websocketService.send(`/repuser/screenshare/${this.currentUser.username}`, {
        event: 'pauseScreenShare',
        data: false
      });
    }
  }
}
