<h3>User information</h3>

<mat-card class="m-15 p-0 flex justify-between gap-20 primary-icon">
  <article class="flex items-center justify-center py-20 p-15" *ngFor="let info of infoMap">
    <mat-icon class="custom-mat-icon mat-icon-lg mr-20">{{ info.icon }}</mat-icon>
    <div class="grid grid-rows-2 gap-y-10">
      <p class="body-2 m-0">{{ info.label }}</p>
      <p class="subtitle-2 m-0 min-h-[19px]">{{ currentUserService.currentUserValue[info.value] }}</p>
    </div>
  </article>
  <button mat-icon-button (click)="openEditUserModal(); $event.stopPropagation()">
    <mat-icon class="custom-mat-icon mat-icon-sm">edit</mat-icon>
  </button>
</mat-card>
