<div class="set-directory-container">
  <div class="set-directory bold">
    <mat-icon>{{ data?.icon }}</mat-icon>
    <div>{{ data?.type === 'file' ? 'Quote / Invoice' : (data?.type | titlecase) }} Import folder</div>
  </div>
  <mat-divider></mat-divider>
  <div class="directory-name" *ngIf="!!data?.fileDirectory || !!data?.imageDirectory; else directoryName">
    <div class="bold">{{ data?.type === 'image' ? data?.imageDirectory : data?.fileDirectory }}</div>
    <div class="directory-buttons">
      <button mat-icon-button (click)="deleteDirectory.emit(data?.type)">
        <mat-icon>delete</mat-icon>
      </button>
      <button
        class="raised-button-with-icon"
        mat-button
        mat-raised-button
        color="accent"
        (click)="setDirectory.emit(data?.type)"
        type="button">
        <mat-icon>create</mat-icon>
      </button>
    </div>
  </div>
  <ng-template #directoryName>
    <button class="set-button" mat-button mat-raised-button (click)="setDirectory.emit(data?.type)" color="accent">
      Set
    </button>
  </ng-template>
</div>
