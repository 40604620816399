import { animate, style, transition, trigger } from '@angular/animations';

export const fadeInDelayed = trigger('fadeInDelayed', [
  transition(':enter', [style({ opacity: 0 }), animate('200ms 200ms ease-in', style({ opacity: 1 }))])
]);

export const fadeInOut = trigger('fadeInOut', [
  transition(':leave', [style({ opacity: 1 }), animate('200ms ease-in-out', style({ opacity: 0 }))]),
  transition(':enter', [style({ opacity: 0 }), animate('200ms ease-in-out', style({ opacity: 1 }))])
]);
