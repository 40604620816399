import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgTemplateOutlet } from '@angular/common';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';

@Component({
  selector: 'infos-action-card',
  host: { class: 'w-full' },
  standalone: true,
  imports: [NgTemplateOutlet, MatCardModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <mat-card class="bg-white">
      <mat-card-content class="flex gap-x-15 flex-nowrap justify-between">
        <ng-content select="[info-icon]" />

        <div class="flex flex-grow gap-x-60">
          <div class="flex flex-col gap-y-10 w-[36%] min-w-[36%] body-1">
            <span class="subtitle-1 font-bold text-charcoal">{{ label }}</span>
            <ng-content select="[info-guidance]" />
          </div>

          <ng-content select="[info-details]" />
        </div>
        <ng-content select="[delete-icon]" />
      </mat-card-content>
    </mat-card>
  `
})
export class InfosActionCardComponent {
  @Input() label: string;
}
