<div class="flex justify-between px-20 pt-15 pb-5">
  <h3>History of Company Changes</h3>
  <button (click)="locationAdminStore.getCompanyInformationHistory(locationAdminStore.userRepairerSite().id)"
          mat-raised-button color="accent">
    REFRESH
  </button>
</div>
<mat-divider></mat-divider>
<div class="px-20 pt-0 pb-10">
  <table
    [class.hidden]="!locationAdminStore.repairerSiteHistories().length"
    [dataSource]="dataSource"
    class="table-style"
    matSort
    mat-table
    matSortActive="transactionDateTime"
    matSortDirection="asc"
    (matSortChange)="activeColumn = $event.active"
    matSortDisableClear>
    <ng-container matColumnDef="transactionDateTime">
      <th mat-header-cell *matHeaderCellDef [class.active]="activeColumn === 'transactionDateTime'" mat-sort-header>
        Date
      </th>
      <td mat-cell class="text-[14px]" *matCellDef="let history">
        {{history.transactionDateTime | dateepoch: currentUserService.timeZone}} {{history.transactionDateTime | time: currentUserService.timeZone }}
      </td>
    </ng-container>
    <ng-container matColumnDef="event">
      <th mat-header-cell *matHeaderCellDef [class.active]="activeColumn === 'event'" mat-sort-header>
        Event
      </th>
      <td mat-cell class="text-[14px]" *matCellDef="let history">
        {{ history?.event }}
      </td>
    </ng-container>
    <ng-container matColumnDef="details">
      <th mat-header-cell *matHeaderCellDef [class.active]="activeColumn === 'details'" mat-sort-header>
        Details
      </th>
      <td mat-cell class="text-[14px] italic w-2/4" *matCellDef="let history">
        <ng-container
          [ngTemplateOutlet]="details"
          [ngTemplateOutletContext]="{ history : {type: 'Previous', details: history.details.previous} }">
        </ng-container>
        <ng-container
          [ngTemplateOutlet]="details"
          [ngTemplateOutletContext]="{ history: {type: 'New', details: history.details.new} }">
        </ng-container>
      </td>
    </ng-container>
    <tr *matHeaderRowDef="columnHeaders" mat-header-row></tr>
    <tr class="h-80" *matRowDef="let row; columns: columnHeaders" mat-row></tr>
  </table>
</div>

<infos-guidance *ngIf="!locationAdminStore.repairerSiteHistories().length && !locationAdminStore.isLoading()"
                [guidanceTemplate]="guidance"/>
<loading-spinner [loading]="locationAdminStore.isLoading()"/>

<ng-template #guidance>
  <div class="text-dove-gray text-[16px]">There have been no changes made to company information.</div>
</ng-template>

<ng-template #details let-history="history">
  <div class="grid grid-cols-[100px_auto]" [ngClass]="{'text-silver-chalice mb-10': history.type ==='Previous'}">
    <span>{{ history.type }}</span>
    <div>{{ history.details }}</div>
  </div>
</ng-template>


