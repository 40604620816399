<div class="lightbox-container" [@fade]="loading ? 'fadeOut' : 'fadeIn'" (wheel)="wheelScroll($event)">
  <div class="tool-belt-1">
    <mat-icon (click)="close()">close</mat-icon>
  </div>
  <div class="img-container" [cdkDragFreeDragPosition]="initialImagePosition" cdkDrag>
    <img #imageElement *ngIf="imageGallerySelector" (load)="loading = false" [src]="selectedImgSrc" />
  </div>
  <div class="tool-belt-2">
    <div class="tool-belt-2">
      <div class="img-controls">
        <mat-icon (click)="previous()">keyboard_arrow_left</mat-icon>
        <mat-icon (click)="tooltipIsVisible = !tooltipIsVisible" class="info" tabindex="0">info_outline</mat-icon>
        <mat-icon (click)="zoomIn()">zoom_in</mat-icon>
        <mat-icon (click)="zoomOut()">zoom_out</mat-icon>
        <mat-icon (click)="next()">keyboard_arrow_right</mat-icon>
      </div>
      <div class="tooltip-text body-1" *ngIf="tooltipIsVisible">
        <div>Uploaded by {{ selectedImage.takenBy }}</div>
        <div>On {{ selectedImage.captureTime | dateepoch }}</div>
      </div>
    </div>
  </div>
</div>

<mat-spinner *ngIf="loading"></mat-spinner>
