<div class="dialog-header" mat-dialog-title>
  <h6>Edit {{ data.ownerName }}</h6>
  <button aria-label="Close" mat-dialog-close mat-icon-button>
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-divider></mat-divider>
<div mat-dialog-content class="add-user-container">
  <form [formGroup]="contactForm">
    <div class="fields-box margin-bottom-15">
      <mat-form-field appearance="outline" color="accent">
        <mat-label>Email</mat-label>
        <input formControlName="email" matInput [(ngModel)]="data.ownerEmail" />
        <mat-error *ngIf="email.hasError('pattern') || email.invalid" class="mat-error">
          Please enter a valid email address
        </mat-error>
      </mat-form-field>
    </div>

    <div class="fields-box">
      <mat-form-field appearance="outline" class="modal-field" color="accent">
        <mat-label>Home</mat-label>
        <input formControlName="homePhone" matInput [(ngModel)]="data.ownerHomePhone" />
      </mat-form-field>

      <mat-form-field appearance="outline" class="modal-field" color="accent">
        <mat-label>Mobile</mat-label>
        <input formControlName="mobilePhone" matInput [(ngModel)]="data.ownerMobilePhone" />
      </mat-form-field>

      <mat-form-field appearance="outline" class="modal-field" color="accent">
        <mat-label>Work</mat-label>
        <input formControlName="workPhone" matInput [(ngModel)]="data.ownerWorkPhone" />
      </mat-form-field>

      <mat-form-field appearance="outline" class="modal-field" color="accent">
        <mat-label>Postcode</mat-label>
        <input formControlName="postCode" matInput [(ngModel)]="data.ownerPostCode" />
      </mat-form-field>
    </div>
  </form>
</div>

<mat-dialog-actions>
  <button mat-raised-button mat-dialog-close color="accent">CANCEL</button>
  <button
    (click)="saveContact()"
    [disabled]="contactForm.invalid || contactForm.pristine || pending"
    class="buttons"
    color="primary"
    mat-raised-button
    type="submit">
    SAVE
  </button>
</mat-dialog-actions>
