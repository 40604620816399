import { Component, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig
} from '@angular/material/legacy-dialog';
import { MatSort } from '@angular/material/sort';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfirmBoxComponent } from '../../components/confirm-box/confirm-box.component';
import { MessagingService } from '../../components/messaging/messaging.service';
import { smallModalConfig, wideModalConfig } from '../../consts/modal-config.const';
import { ModalConfigsHelper } from '../../helpers/modal-configs.helper';
import { FinanceCompany } from '../../model/financing-company.model';
import { CoreQuotingSystem } from '../../model/quoting-system/core-quoting-system.model';
import { QuotingSystem } from '../../model/quoting-system/quoting-system.model';
import { Refreshable } from '../../model/refreshable.model';
import { RepairerGroup, User, UserRepairerSite } from '../../model/user.model';
import { CurrentUserService } from '../../services/currentUser.service';
import { MultiLocalAdminService } from '../../services/ml-admin.service';
import { SysAdminService } from '../../services/sys-admin.service';
import { ToolbarService } from '../../services/toolbar.service';
import { UserService } from '../../services/user.service';
import { AddRepairerSiteComponent } from './add-repairer-site/add-repairer-site.component';
import { ConfigureOAuthInterfaceComponent } from './configure-o-auth-interface/configure-o-auth-interface.component';

@Component({
  selector: 'app-sysadmin-dashboard',
  templateUrl: './sysadmin-dashboard.component.html',
  styleUrls: ['./sysadmin-dashboard.component.scss']
})
export class SysadminDashboardComponent implements OnInit, Refreshable, OnDestroy {
  @ViewChild('pendingInvitesSort', { static: true }) pendingInvitesSort: MatSort;
  @ViewChild('activeLocationsSort', { static: true }) activeLocationsSort: MatSort;
  @ViewChild('archivedLocationsSort', { static: true }) archivedLocationsSort: MatSort;
  timezone: string;
  repairerSite: UserRepairerSite;
  sysadminList: User[] = [];
  financingCompanies: FinanceCompany[] = [];
  quotingSystemList: CoreQuotingSystem[] = [];
  quotingSystem: QuotingSystem;
  loading = false;
  loadingRepairers = false;
  loadingSysadmins = false;
  loadingFinancingCompanies = false;
  loadingQuotingSystem = false;
  loadingQuotingSystemClientId = false;
  filterValue = '';
  repairersExpanded = true;
  repairersGroup: RepairerGroup[];
  refreshTab = { status: false };
  private unsubscribe = new Subject<void>();

  private addRepairer = inject(MatDialog);
  private confirmBox = inject(MatDialog);
  private configureOAuth2 = inject(MatDialog);
  private modalConfigsHelper = inject(ModalConfigsHelper);
  toolbarService = inject(ToolbarService);
  private currentUserService = inject(CurrentUserService);
  private message = inject(MessagingService);
  private mlAdminService = inject(MultiLocalAdminService);
  private userService = inject(UserService);
  private sysAdminService = inject(SysAdminService);

  ngOnInit() {
    this.toolbarService.toolbarData({ pageName: 'System Administration', backLink: '' });
    this.timezone = this.currentUserService?.timeZone;
    this.userService.repairersGroup.pipe(takeUntil(this.unsubscribe)).subscribe({
      next: (groups) => (this.repairersGroup = groups)
    });

    if (this.sysAdminService.repairerSiteAccessed()) {
      this.sysAdminService.revokeAccessRepairerSite().subscribe(() => this.refresh());
    } else {
      this.getSysadmins();
      this.getFinancingCompanies();
      this.getQSystems();
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  getSysadmins() {
    this.loading = this.loadingSysadmins = true;
    this.toolbarService.setCloudState('FETCHING');
    this.sysAdminService
      .getSysadmins()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (sysadmins) => (this.sysadminList = sysadmins),
        error: () => {
          this.message.error('Could not retrieve the list of administrators.');
          this.loadingSysadmins = false;
          this.endOfLoading();
        },
        complete: () => {
          this.loadingSysadmins = false;
          this.endOfLoading();
        }
      });
  }

  getFinancingCompanies() {
    this.loading = this.loadingFinancingCompanies = true;
    this.toolbarService.setCloudState('FETCHING');
    this.sysAdminService
      .getFinancingCompanies()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (companies) => (this.financingCompanies = companies),
        error: () => {
          this.message.error('Could not retrieve the list of finance companies.');
          this.loadingFinancingCompanies = false;
          this.endOfLoading();
        },
        complete: () => {
          this.loadingFinancingCompanies = false;
          this.endOfLoading();
        }
      });
  }

  getQSystems() {
    this.loading = this.loadingQuotingSystem = true;
    this.toolbarService.setCloudState('FETCHING');
    this.sysAdminService
      .getQuotingSystems()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (quotingSystem) => (this.quotingSystemList = quotingSystem),
        error: () => {
          this.message.error('Could not retrieve the list of quoting system.');
          this.loadingQuotingSystem = false;
          this.endOfLoading();
        },
        complete: () => {
          this.loadingQuotingSystem = false;
          this.endOfLoading();
        }
      });
  }

  addRepairerSite() {
    const dialogConfig: MatDialogConfig = wideModalConfig;
    dialogConfig.data = { repairersGroup: this.repairersGroup };
    const dialogRef = this.addRepairer.open(AddRepairerSiteComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'Success') {
        this.message.confirm('New Repairer Site Created');
        this.refreshTab = { status: true };
      }
    });
  }

  revokingTokensForRepairSite(repairerSiteId: number) {
    this.toolbarService.setCloudState('FETCHING');
    this.sysAdminService
      .revokeTokensOfRepairerSite(repairerSiteId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: () => this.message.confirm('Tokens revoked for Repairer Site.'),
        error: () => {
          this.message.error('Could not revoke tokens for Repairer Site.');
          this.toolbarService.setCloudState('RESTING');
        },
        complete: () => this.toolbarService.setCloudState('RESTING')
      });
  }

  revokingTokensOfQuotingSystem(repairerSiteId: number) {
    this.toolbarService.setCloudState('FETCHING');
    this.sysAdminService
      .revokeTokensOfQuotingSystem(repairerSiteId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: () => this.message.confirm('Tokens revoked for Quoting System.'),
        error: () => {
          this.message.error('Could not revoke tokens for Quoting System.');
          this.toolbarService.setCloudState('RESTING');
        },
        complete: () => this.toolbarService.setCloudState('RESTING')
      });
  }

  confirmRevokeAllTokens(event: any) {
    const data = {
      title: 'Revoke All Tokens',
      alertMessage: 'All users tokens will be revoked. They will need to redo the linking process.',
      confirmButton: 'REVOKE'
    };
    const dialogRef = this.confirmBox.open(
      ConfirmBoxComponent,
      this.modalConfigsHelper.buildMediumModalConfig(data, smallModalConfig)
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'REVOKE') {
        event.isQuotingSystem
          ? this.revokingTokensOfQuotingSystem(event.repairerSiteId)
          : this.revokingTokensForRepairSite(event.repairerSiteId);
      }
    });
  }

  endOfLoading() {
    if (!this.loadingSysadmins && !this.loadingFinancingCompanies && !this.loadingQuotingSystem) {
      this.loading = false;
      this.toolbarService.setCloudState('RESTING');
    }
  }

  configureInterfaces(event: any) {
    this.loading = this.loadingQuotingSystemClientId = true;
    this.toolbarService.setCloudState('FETCHING');
    this.sysAdminService
      .getQuotingSystem(event.clientId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (getQuotingSystem) => (this.quotingSystem = getQuotingSystem),
        error: () => {
          this.message.error('Could not retrieve quoting system.');
          this.loadingQuotingSystemClientId = false;
        },
        complete: () => {
          this.loadingQuotingSystemClientId = false;
          let dialogRef;
          if (event.isOauthInterface) {
            const dialogConfig: MatDialogConfig = wideModalConfig;
            dialogConfig.data = this.quotingSystem;
            dialogRef = this.configureOAuth2.open(ConfigureOAuthInterfaceComponent, dialogConfig);
          }
          dialogRef.afterClosed().subscribe((result) => {
            if (result === 'Success oAuth') {
              this.message.confirm('OAuth2 Interface Configured');
            }
            this.getQSystems();
          });
        }
      });
  }

  refresh() {
    this.getSysadmins();
    this.mlAdminService.getAllMlAdmins().subscribe();
    this.refreshTab = { status: true };
    this.getFinancingCompanies();
    this.getQSystems();
  }
}
