import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateInputHelper {
  restrictDateInput(event: KeyboardEvent) {
    return new RegExp('[0-9]|\/|\-').test(event.key) ? event.key : event.preventDefault();
  }
}
