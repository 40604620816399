import { DecimalPipe, I18nPluralPipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { Component, inject, OnInit, signal, ViewChild, WritableSignal } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource, MatLegacyTableModule } from '@angular/material/legacy-table';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { AbstractDynamicDialogComponent } from '../../../../components/dynamic-dialog/abstract-dynamic-dialog.component';
import { InfosGuidanceComponent } from '../../../../components/infos-guidance/infos-guidance.component';
import { DisbursementAccount, EstImagePayRequest } from '../../../../model/estimage-pay.model';
import { InvoiceStore } from '../invoice.store.component';

@Component({
  selector: 'use-estimage-pay-modal',
  templateUrl: './use-estimage-pay-modal.component.html',
  standalone: true,
  imports: [
    I18nPluralPipe,
    MatLegacyCheckboxModule,
    NgIf,
    MatIconModule,
    MatLegacyTableModule,
    MatSortModule,
    InfosGuidanceComponent,
    NgClass,
    NgForOf,
    DecimalPipe
  ]
})
export class UseEstimagePayModalComponent
  extends AbstractDynamicDialogComponent<{ estImagePayRequest: EstImagePayRequest }>
  implements OnInit
{
  @ViewChild(MatSort) set sort(matSort: MatSort) {
    this.dataSource.sort = matSort;
  }
  columnHeaders = ['selected', 'accountName', 'accountNumber', 'bsb'];
  dataSource: MatTableDataSource<DisbursementAccount> = new MatTableDataSource();
  activeColumn = 'accountName';
  acknowledge: WritableSignal<boolean> = signal(false);
  accountSelected: WritableSignal<DisbursementAccount> = signal(null);
  #invoiceStore = inject(InvoiceStore);
  #dialogRef = inject(MatDialogRef<UseEstimagePayModalComponent>);
  constructor() {
    super();
  }

  ngOnInit(): void {
    this.dynamicDialogService.dialogRefSignal.set(this.#dialogRef);
    this.dataSource.data = this.data.estImagePayRequest.disbursementAccounts;
    this.dataSource.sort = this.sort;
    const selectedDisbursementAccount =
      this.dataSource.data?.find(
        (d) => d?.accountName === this.data.estImagePayRequest?.accountSelected?.accountName
      ) ?? null;
    this.data.estImagePayRequest?.accountSelected && this.acknowledge.set(true);
    this.accountSelected.set(
      this.dataSource.data?.length === 1 ? this.dataSource.data[0] : selectedDisbursementAccount
    );
    this.disabled.set(!(selectedDisbursementAccount && this.acknowledge()));
  }

  saveOrExecuteAction() {
    this.disabled.set(true);
    this.accountSelected() &&
      this.acknowledge() &&
      this.#invoiceStore.useEstimagePay({
        invoiceId: this.data.estImagePayRequest?.invoiceId,
        accountId: this.accountSelected().accountId
      });
  }
}
