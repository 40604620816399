import { Component } from '@angular/core';

@Component({
  selector: 'app-info-message',
  templateUrl: './info-message.component.html',
  styleUrls: ['./info-message.component.scss']
})
export class InfoMessageComponent {
  isChrome = window.navigator.vendor === 'Google Inc.' && window.navigator.userAgent.indexOf('Edg') >= -1;
  constructor() {}
}
