<div class="dialog-header" mat-dialog-title>
  <h6>Pick-up a job</h6>
  <button aria-label="Close" mat-dialog-close mat-icon-button>
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-divider></mat-divider>
<div mat-dialog-content class="pick-up-container">
  <form [formGroup]="pickupForm">
    <div class="fields-box">
      <mat-form-field appearance="outline" color="accent">
        <mat-label>Insurer</mat-label>
        <mat-select
          panelClass="auto-width-select"
          formControlName="insurer"
          required
          (selectionChange)="selectInsurer()">
          <mat-option *ngFor="let insurer of insurers" [value]="insurer.insurerId">
            {{ insurer.insurerName }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="insurer.hasError('required')"> Insurer is <strong>required</strong> </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" color="accent">
        <mat-label>REGO</mat-label>
        <input formControlName="rego" required matInput oninput="this.value = this.value.toUpperCase()" />
        <mat-error *ngIf="rego.hasError('required')"> REGO is <strong>required</strong> </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" color="accent">
        <mat-label>Claim Number</mat-label>
        <input formControlName="claimNumber" matInput />
      </mat-form-field>
    </div>
    <div class="fields-box">
      <mat-form-field appearance="outline" color="accent" *ngIf="showInspectionDate" class="medium-field">
        <mat-label>Inspection Date</mat-label>
        <input
          (keypress)="dateInputHelper.restrictDateInput($event)"
          [matDatepicker]="inspectionDatepicker"
          [min]="minDate"
          [matDatepickerFilter]="availableDatesFilter"
          formControlName="inspectionDate"
          matInput />
        <mat-datepicker-toggle [for]="inspectionDatepicker" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #inspectionDatepicker></mat-datepicker>
        <mat-hint>Date format DD/MM/YYYY</mat-hint>
        <mat-error *ngIf="inspectionDate.hasError('matDatepickerParse')">
          The date format is <strong>DD/MM/YYYY</strong>
        </mat-error>
        <mat-error
          *ngIf="inspectionDate.hasError('matDatepickerFilter') || inspectionDate.hasError('matDatepickerMin')">
          The date is not available
        </mat-error>
      </mat-form-field>
    </div>
  </form>
  <mat-progress-bar mode="indeterminate" color="primary" *ngIf="showProgressBar"></mat-progress-bar>
</div>
<mat-dialog-actions>
  <button mat-raised-button color="accent" mat-dialog-close>CANCEL</button>
  <button
    [disabled]="pickupForm.invalid || pending"
    (click)="pickUpJob()"
    class="buttons"
    color="primary"
    mat-raised-button
    type="submit">
    PICK-UP
  </button>
</mat-dialog-actions>
