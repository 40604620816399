<div class="p-20">
  <form [formGroup]="form" class="search-form" (ngSubmit)="getRepairerSites(true)">
    <mat-form-field
      appearance="outline"
      class="small-field w-[115px] remove-end-border-radius remove-form-wrapper-spacing"
      color="accent">
      <mat-select formControlName="statusFilter" (selectionChange)="getRepairerSites(true)">
        <mat-option *ngFor="let status of statusOptions" [value]="status.value">
          {{ status.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field
      appearance="outline"
      color="accent"
      class="input-search subtitle-2 remove-start-border-radius remove-form-wrapper-spacing">
      <input matInput formControlName="searchFilter" [placeholder]="searchPlaceholder" cdkFocusInitial />
      <mat-icon *ngIf="searchFilter.value" matSuffix (click)="searchFilter.setValue('')">cancel_24px</mat-icon>
    </mat-form-field>
    <button color="primary" class="buttons" mat-raised-button type="submit">SEARCH</button>
  </form>

  <mat-divider />

  <ng-container *ngIf="!loadingRepairers() && !sysAdminService.repairerSitesList()?.length">
    <div *ngIf="!searchInputValue" class="search-information">
      <mat-icon>info_outline_24px</mat-icon>
      <span class="search-information-text">Define a search criterion to generate a list of repairer.</span>
    </div>
    <div *ngIf="searchInputValue" class="search-no-result-found-container">
      <p class="search-no-result-found">
        <mat-icon color="primary">warning</mat-icon>
        <span class="search-no-result-found-text">No results could be found. Please review your search.</span>
      </p>
    </div>
  </ng-container>

  <mat-paginator
    class="body-2 -mb-10"
    *ngIf="sysAdminService.repairerSitesList()?.length && !loadingRepairers()"
    (page)="pageSearch($event.pageIndex)"
    [length]="sysAdminService.repairerSitesTotalNumberOfMatchesFound()"
    [pageSize]="pageSize"
    [showFirstLastButtons]="true"
    [pageSizeOptions]="[]"
    [pageIndex]="page.value" />

  <app-repairer-site-table
    *ngIf="sysAdminService.repairerSitesList()?.length"
    [activeColumn]="'companyName'"
    [loadingRepairers]="loadingRepairers()"
    [currentUserTimezone]="userTimezone"
    [columnInfos]="columnHeadersRepairerSites"
    [repairerSites]="sysAdminService.repairerSitesList()"
    [kebabMenuButtons]="kebabMenuButtons"
    (rowItemClicked)="getRepairerSite($event)"
    (columnHeaderOrderChange)="sortedSearch($event?.sort, $event?.direction)"
    (kebabMenuClicked)="kebabMenuClick($event)">
  </app-repairer-site-table>

  <loading-spinner [spinnerHeight]="20" [loading]="loadingRepairers()" />
</div>
