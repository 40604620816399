import { CdkScrollable } from '@angular/cdk/overlay';
import { CommonModule, NgComponentOutlet } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ComponentRef,
  inject,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogModule as MatDialogModule,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { EscapeDialogDirective } from '../../directives/escape-dialog.directive';
import { DialogData } from '../../model/dialogData.model';
import { AbstractDynamicDialogComponent } from './abstract-dynamic-dialog.component';

@Component({
  selector: 'app-dynamic-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatIconModule,
    NgComponentOutlet,
    EscapeDialogDirective,
    MatLegacyButtonModule,
    CdkScrollable,
    MatDividerModule
  ],
  template: ` <div [appEscapeDialog]="dialogRef">
    <div
      [ngClass]="{ 'pb-20': !data?.displayClose }"
      class="flex justify-between items-center w-full pb-10"
      *ngIf="data?.title"
      mat-dialog-title>
      <h6>{{ data.title }}</h6>
      <button mat-icon-button aria-label="Close" *ngIf="data?.displayClose !== false" mat-dialog-close>
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <mat-divider *ngIf="data?.title" />
    <mat-dialog-content cdkScrollable>
      <ng-container #content />
    </mat-dialog-content>
    <mat-dialog-actions class="flex justify-between">
      <button *ngIf="data?.displayCancel ?? true" mat-raised-button mat-dialog-close color="accent">CANCEL</button>
      <button
        (click)="componentRef?.instance?.extraAction()"
        *ngIf="data.thirdButtonLabel"
        [disabled]="componentRef?.instance?.thirdButtonDisable()"
        color="primary"
        mat-raised-button>
        {{ data?.thirdButtonLabel }}
      </button>
      <button
        [ngClass]="{ 'ml-auto': !data?.displayCancel && !data?.thirdButtonLabel }"
        (click)="componentRef?.instance?.saveOrExecuteAction()"
        [disabled]="componentRef?.instance?.form?.invalid || componentRef?.instance?.disabled()"
        color="primary"
        mat-raised-button>
        {{ data?.action || 'SAVE' }}
      </button>
    </mat-dialog-actions>
  </div>`
})
export class DynamicDialogComponent implements OnInit, OnDestroy {
  @ViewChild('content', { read: ViewContainerRef, static: true }) vcRef: ViewContainerRef;
  @ViewChild(EscapeDialogDirective) escapeDialogDirective: EscapeDialogDirective;
  data: DialogData = inject(MAT_DIALOG_DATA);
  dialogRef = inject(MatDialogRef<DynamicDialogComponent>);
  private cd = inject(ChangeDetectorRef);
  componentRef: ComponentRef<AbstractDynamicDialogComponent>;

  ngOnInit(): void {
    this.vcRef.clear();
    this.componentRef = this.vcRef.createComponent(this.data.component);
    this.cd.detectChanges();
    this.dialogRef.afterClosed().subscribe(() => this.componentRef.instance.disabled.set(false));
  }

  ngOnDestroy() {
    this.componentRef?.destroy();
  }
}
