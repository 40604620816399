import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogModule,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { UserService } from '../../../services/user.service';
import { MessagingService } from '../../../components/messaging/messaging.service';
import { CurrentUserService } from '../../../services/currentUser.service';
import { EscapeDialogHelper } from '../../../helpers/escape-dialog.helper';
import { mergeMap } from 'rxjs/operators';
import { SysAdminService } from '../../../services/sys-admin.service';
import { CommonModule } from '@angular/common';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';

@Component({
  standalone: true,
  imports: [CommonModule, MatLegacyDialogModule, MatLegacyButtonModule],
  selector: 'app-access-repairer-site',
  templateUrl: './access-repairer-site.component.html',
  styleUrls: ['./access-repairer-site.component.scss']
})
export class AccessRepairerSiteComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<AccessRepairerSiteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private currentUserService: CurrentUserService,
    private userService: UserService,
    private router: Router,
    private message: MessagingService,
    private escapeDialogRef: EscapeDialogHelper,
    private sysAdminService: SysAdminService
  ) {}

  ngOnInit(): void {
    this.escapeDialogRef.escapeDialog(this.dialogRef);
  }

  grantAccessRepairerSite() {
    this.sysAdminService.grantAccessRepairerSite(this.data.repairerSiteId).subscribe(
      () => {
        this.userService.getCurrentUser().subscribe(
          (user) => this.currentUserService.userLogin(user),
          () => {},
          () => {
            if (this.data.jobId) {
              window.open(`/jobViewer/${this.data.jobId}`, this.data?.sameWindow ? '_self' : '_blank');
            } else {
              this.router.navigate(['/dashboard']).catch();
            }
          }
        );
      },
      () => {
        this.message.error('Could not grant access the the repairer site.');
      },
      () => {
        localStorage.setItem('repairerSiteAccessed', this.data.repairerSiteName);
        this.dialogRef.close();
      }
    );
  }

  revokeAccessRepairerSite() {
    this.sysAdminService
      .revokeAccessRepairerSite()
      .pipe(mergeMap(() => this.userService.getCurrentUser()))
      .subscribe({
        complete: () => this.dialogRef.close({ result: 'CLEAR' })
      });
  }
}

export interface DialogData {
  repairerSiteId?: number;
  repairerSiteName?: string;
  jobId?: number;
  sameWindow?: boolean;
}
