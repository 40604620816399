<mat-card
  class="job-card m-10 p-0"
  [ngClass]="{ 'unread-job bg-alice-blue': job?.unread, 'active-job': job.jobId === activeJobId }"
  [routerLink]="!nextButtonpending && !previousButtonpending ? ['/jobViewer', job.jobId] : null"
  (click)="saveActiveJob(job.jobId)">
  <div class="mat-card-content flex flex-col justify-between h-fit">
    <section class="flex justify-between items-center gap-5 pl-10">
      <article
        class="body-2 display-ellipsis"
        matTooltip="{{
          (dateInBucket | daysSince: timezone) +
            ((dateInBucket | daysSince: timezone) === 1 ? ' day ' : ' days ') +
            'ago'
        }}"
        [matTooltipDisabled]="bucket === 'REPAIR_BOOKED'">
        {{ relevantDate }}
      </article>

      <article class="flex">
        <div class="flex items-center gap-10">
          <mat-icon color="primary" class="custom-mat-icon mat-icon-xs" *ngIf="job?.unreadDiary" inline="true"
            >comment_24px
          </mat-icon>
          <mat-icon color="primary" class="custom-mat-icon mat-icon-xs" *ngIf="job?.unsubmittedDocument" inline="true"
            >send
          </mat-icon>
          <div
            *ngIf="job.pendingSupplement"
            matTooltip="This indicates that the job is pending a supplement assessment">
            <mat-icon color="primary" class="custom-mat-icon mat-icon-sm mt-[4px]">running_with_errors</mat-icon>
          </div>
        </div>
        <button
          mat-icon-button
          [matMenuTriggerFor]="optionsMenu"
          (click)="$event.stopPropagation()"
          class="custom-mat-button p-0 mx-[-3px]">
          <mat-icon class="custom-mat-icon mat-icon-md">more_vert</mat-icon>
        </button>
        <mat-menu #optionsMenu="matMenu" class="job-progress">
          <button class="p-0" (click)="archiveJob()" mat-menu-item>Archive job</button>
          <button class="p-0" (click)="toggleJobReadStatus(job?.unread, job?.jobId)" mat-menu-item>
            Mark as {{ job?.unread ? 'read' : 'unread' }}
          </button>
        </mat-menu>
      </article>
    </section>

    <section class="min-[1367px]:grid min-[1366px]:grid-cols-4 mt-[-6px] px-10">
      <article class="flex flex-col gap-px min-[1366px]:col-span-3">
        <div
          #claim
          class="text-charcoal whitespace-nowrap subtitle-1"
          [class.scrolling-text]="setTextAnimation(claim, claimText)">
          <span #claimText class="flex items-center" [ngClass]="{ 'font-bold': job?.unread }">
            <mat-icon
              *ngIf="job?.unread"
              class="custom-mat-icon mat-icon-xs material-icons-outlined bg-pacific-blue text-white rounded p-[3px] mr-5">
              mark_email_unread_outline
            </mat-icon>
            {{ job.claimNumber | uppercase }}
          </span>
        </div>

        <div
          #model
          class="body-2 display-ellipsis"
          [class.mt-5]="!job?.claimNumber && !(job?.ownerPersonName || job?.ownerCompanyName)"
          [class.scrolling-text]="setTextAnimation(model, modelText)">
          <span #modelText>{{ job.insco | uppercase }}</span>
        </div>
        <div
          #owner
          *ngIf="job?.ownerPersonName || job?.ownerCompanyName"
          class="body-2 display-ellipsis flex items-center"
          matTooltip="{{
            setTextAnimation(owner, ownerText)
              ? job?.ownerIsACompany
                ? job?.ownerCompanyName
                : job?.ownerPersonName
              : ''
          }}">
          <mat-icon class="custom-mat-icon mat-icon-xs">account_circle</mat-icon>
          <span #ownerText class="leading-snug ml-[2px]">
            {{ (job?.ownerIsACompany ? job?.ownerCompanyName : job?.ownerPersonName) | uppercase }}</span
          >
        </div>
        <div
          #vehicleDescription
          class="body-2 display-ellipsis"
          [class.scrolling-text]="setTextAnimation(vehicleDescription, vehicleDescriptionSpan)">
          <span #vehicleDescriptionSpan class="text-dove-gray">{{ job.vehicleDescription }}</span>
        </div>
        <div class="flex mt-10" [ngClass]="job.totalLoss ? 'justify-between' : 'justify-end'">
          <div
            *ngIf="job.totalLoss"
            class="w-fit body-3 flex items-center gap-[4px] rounded bg-orange-peel py-[4px] px-5">
            <mat-icon svgIcon="total_loss_icon" class="custom-mat-icon mat-icon-xs"></mat-icon>
            <span class="mt-[1px] body-3 font-black">TOTAL LOSS</span>
          </div>
          <div class="min-[1367px]:hidden">
            <ng-container *ngTemplateOutlet="rego"></ng-container>
          </div>
        </div>
      </article>

      <article class="max-[1366px]:hidden flex flex-col justify-center items-center">
        <ng-container *ngIf="job.photoIdentityUI">
          <img
            [hidden]="!photoLoaded[job.jobId]"
            class="h-auto w-[54px] mb-5 rounded"
            appRetrySrc="/jobAttachments/{{ repairerSite?.id }}/{{ job.jobId }}/thumb_{{ job.photoIdentityUI.id }}.{{
              job.photoIdentityUI.extension
            }}"
            (error)="photoLoaded[job.jobId] = false"
            (load)="photoLoaded[job.jobId] = true" />
        </ng-container>
        <img
          *ngIf="!job.photoIdentityUI || !photoLoaded[job.jobId]"
          class="h-auto w-[54px] mb-5 rounded placeholder"
          src="../../../assets/images/car-placeholder.svg" />
        <ng-container *ngTemplateOutlet="rego"></ng-container>
      </article>
    </section>

    <section class="bottom-icon flex justify-between items-center p-10">
      <button
        *ngIf="['REPAIR_STARTED', 'REPAIR_COMPLETED', 'VEHICLE_OUT'].includes(bucket)"
        mat-raised-button
        matTooltip="Use this function to regress the vehicle one step back in the repair progress"
        [disabled]="previousButtonpending || nextButtonpending"
        class="left-button p-0"
        (click)="$event.stopPropagation(); !previousButtonpending && moveToPreviousBucket()">
        <mat-icon *ngIf="!previousButtonpending" class="next-icon" svgIcon="back_icon"></mat-icon>
        <mat-spinner
          *ngIf="previousButtonpending"
          class="my-0 mx-auto"
          color="primary"
          mode="indeterminate"
          [diameter]="20"></mat-spinner>
      </button>
      <button
        *ngIf="bucket !== 'VEHICLE_OUT'"
        mat-raised-button
        matTooltip="Use this function to progress the vehicle through the 4 phases of the repair progress"
        class="right-button p-0"
        [disabled]="previousButtonpending || nextButtonpending"
        (click)="$event.stopPropagation(); !nextButtonpending && moveToNextBucket()">
        <mat-icon *ngIf="!nextButtonpending" class="next-icon" svgIcon="next_icon"></mat-icon>
        <mat-spinner
          *ngIf="nextButtonpending"
          class="my-0 mx-auto"
          color="primary"
          mode="indeterminate"
          [diameter]="20">
        </mat-spinner>
      </button>
    </section>
  </div>
</mat-card>

<ng-template #rego>
  <div *ngIf="job?.rego" class="rego-span body-2 max-w-[80px] min-w-fit display-ellipsis">{{ job.rego }}</div>
</ng-template>
