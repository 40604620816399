import { booleanAttribute, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AllStatus } from '../../types/all-status.type';
import { HumanizePipe } from '../../pipes/humanize.pipe';

@Component({
  selector: 'status-chip',
  standalone: true,
  imports: [CommonModule, HumanizePipe],
  template: `
    <div class="status-chip" [class.small-chip]="smallChip" [ngClass]="getChipClasses()">
      {{ statusInfo | humanize }}
    </div>
  `
})
export class StatusChipComponent {
  statusInfo: AllStatus;
  backgroundColor: string;
  textColor = 'text-white';

  @Input({ transform: booleanAttribute }) smallChip = false;

  @Input('status') set status(status: AllStatus) {
    this.statusInfo = status;
    switch (status) {
      case 'ASSESSED':
      case 'MIGRATED':
        this.backgroundColor = 'bg-secondary-500';
        break;
      case 'ACTIVE':
      case 'APPROVED':
      case 'AUTHORISED':
      case 'ENABLED':
      case 'ISSUED':
      case 'COMPLETED':
      case 'COMPLETE':
        this.backgroundColor = 'bg-salem';
        break;
      case 'ADJUSTED':
      case 'IN_PROGRESS':
      case 'INVITED':
      case 'PENDING':
      case 'PENDING_FOR_REVIEW':
      case 'SUBMITTED':
        this.backgroundColor = 'bg-orange-peel';
        break;
      case 'DISABLED':
        this.backgroundColor = 'bg-gainsboro';
        this.textColor = 'text-silver-chalice';
        break;
      case 'CANCELLED':
      case 'SUSPENDED':
      case 'REJECTED':
      case 'DECLINED':
        this.backgroundColor = 'bg-primary-500';
        break;
    }
  }

  getChipClasses(): { [key: string]: boolean } {
    return {
      [this.backgroundColor]: !!this.backgroundColor,
      [this.textColor]: !!this.textColor
    };
  }
}
