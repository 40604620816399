<div class="job-search">
  <div class="results">
    <ng-container *ngIf="jobSearch?.length === 0">
      <div class="no-results subtitle-2">
        <mat-icon>info_outline_24px</mat-icon>
        <div>No results found. Please review your search criteria</div>
      </div>
    </ng-container>
    <div class="result-message subtitle-1" *ngIf="jobSearch?.length < 50 && jobSearch?.length">
      {{ jobSearch?.length }} {{ jobSearch?.length === 1 ? 'result' : 'results' }}
      found
    </div>
    <div class="result-message subtitle-1" *ngIf="jobSearch?.length >= 50">More than 50 results found</div>
    <button aria-label="Close" mat-dialog-close mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="content">
    <div [class.hidden]="!jobSearch?.length" class="search-table">
      <table
        [dataSource]="dataSource"
        mat-table
        (matSortChange)="activeColumn = $event.active"
        matSort
        matSortDirection="desc"
        matSortActive="lastTransactionDateTime"
        matSortDisableClear>
        <ng-container matColumnDef="lastTransactionDateTime">
          <th
            *matHeaderCellDef
            class="table-header body-2"
            mat-header-cell
            [class.active]="activeColumn === 'lastTransactionDateTime'"
            mat-sort-header>
            Last transaction
          </th>
          <td *matCellDef="let item" mat-cell class="body-1">
            <div class="time-stamp">
              {{ item.lastTransactionDateTime | date: 'dd/MM/yyyy' }} {{ item.lastTransactionDateTime | date: 'HH:mm' }}
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="ownerName">
          <th
            *matHeaderCellDef
            class="table-header body-2"
            mat-header-cell
            [class.active]="activeColumn === 'ownerName'"
            mat-sort-header>
            {{ isUserSysAdminOrMlAdminWithoutRepairerSiteAccess ? 'Repairer' : 'Owner' }}
          </th>
          <td *matCellDef="let item" mat-cell class="body-1">
            <div
              class="owner"
              [innerHTML]="
                (isUserSysAdminOrMlAdminWithoutRepairerSiteAccess ? item.companyName : item.ownerName)
                  | highlight: searchValue
              "></div>
          </td>
        </ng-container>
        <ng-container matColumnDef="rego">
          <th
            *matHeaderCellDef
            class="table-header body-2"
            mat-header-cell
            [class.active]="activeColumn === 'rego'"
            mat-sort-header>
            Rego
          </th>
          <td *matCellDef="let item" mat-cell class="body-1">
            <div class="rego body-2">
              <span [class.rego-span]="item?.rego" [innerHTML]="item.rego | highlight: searchValue"></span>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="vehicleDescription">
          <th
            *matHeaderCellDef
            class="table-header body-2"
            mat-header-cell
            [class.active]="activeColumn === 'vehicleDescription'"
            mat-sort-header>
            Vehicle description
          </th>
          <td *matCellDef="let item" mat-cell class="body-1">
            <div [innerHTML]="item.vehicleDescription | highlight: searchValue"></div>
          </td>
        </ng-container>
        <ng-container matColumnDef="insurerName">
          <th
            *matHeaderCellDef
            class="table-header body-2"
            mat-header-cell
            [class.active]="activeColumn === 'insurerName'"
            mat-sort-header>
            Insurer
          </th>
          <td *matCellDef="let item" mat-cell class="body-1">
            <div [innerHTML]="item.insurerName | highlight: searchValue"></div>
          </td>
        </ng-container>
        <ng-container matColumnDef="requestForQuoteDateTime">
          <th
            *matHeaderCellDef
            class="table-header body-2"
            mat-header-cell
            [class.active]="activeColumn === 'requestForQuoteDateTime'"
            mat-sort-header>
            Request For Quote Date
          </th>
          <td *matCellDef="let item" mat-cell class="body-1">
            {{ item.requestForQuoteDateTime | date: 'dd/MM/yyyy' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th
            *matHeaderCellDef
            class="table-header body-2"
            mat-header-cell
            [class.active]="activeColumn === 'status'"
            mat-sort-header>
            Job Status
          </th>
          <td *matCellDef="let item" mat-cell class="body-1">
            {{ (item?.status && item?.status !== 'HIDDEN') ?
            (item?.archived ? (jobStatusEnum[item.status] + ' [Archived]') :
            (item?.repairProgressColumn ? jobStatusEnum[item.status] + '/' + repairProgressMapping[item?.repairProgressColumn] : jobStatusEnum[item.status]))
            : (item?.status === 'HIDDEN' && !item?.repairProgressColumn ? item?.lastAssessmentAction : (item?.repairProgressColumn ? repairProgressMapping[item?.repairProgressColumn] : jobStatusEnum[item.status])) }}
          </td>
        </ng-container>
        <ng-container matColumnDef="claimNumber">
          <th
            *matHeaderCellDef
            class="table-header body-2"
            mat-header-cell
            [class.active]="activeColumn === 'claimNumber'"
            mat-sort-header>
            Claim number
          </th>
          <td *matCellDef="let item" mat-cell class="body-1">
            <div [innerHTML]="item.claimNumber | highlight: searchValue"></div>
          </td>
        </ng-container>
        <ng-container matColumnDef="dataFileId" *ngIf="isUserSysAdminOrMlAdminWithoutRepairerSiteAccess">
          <th
            *matHeaderCellDef
            class="table-header body-2"
            mat-header-cell
            [class.active]="activeColumn === 'dataFileId'"
            mat-sort-header>
            Datafile ID
          </th>
          <td *matCellDef="let item" mat-cell class="body-1">
            <div [innerHTML]="item.dataFileId | highlight: searchValue"></div>
          </td>
        </ng-container>
        <ng-container matColumnDef="link">
          <th *matHeaderCellDef class="table-header" mat-header-cell></th>
          <td *matCellDef="let item" mat-cell>
            <button mat-icon-button (click)="redirectToJob(item, false, $event); $event.stopPropagation()">
              <mat-icon>launch</mat-icon>
            </button>
          </td>
        </ng-container>
        <tr *matHeaderRowDef="jobSearchColumns" mat-header-row></tr>
        <tr (click)="redirectToJob(job, true, $event)" *matRowDef="let job; columns: jobSearchColumns" mat-row></tr>
      </table>
      <div *ngIf="jobSearch?.length >= 50" class="refine-search subtitle-2">
        <mat-icon>info_outline_24px</mat-icon>
        <div>Your search returned more than 50 results. please refine your search criteria</div>
      </div>
    </div>
  </div>
</div>
