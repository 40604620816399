<h6>{{ data.title }}</h6>
<div mat-dialog-content class="subtitle-2">
  <div>{{ data.alertMessage }}</div>
  <div *ngIf="!data?.hideCannotBeUndone">This cannot be undone.</div>
</div>
<mat-dialog-actions [ngClass]="{ biggerButtons: data.biggerButtons }">
  <button mat-raised-button mat-dialog-close color="accent">CANCEL</button>
  <button mat-button mat-raised-button [mat-dialog-close]="data.confirmButton" cdkFocusInitial color="primary">
    {{ data.confirmButton }}
  </button>
</mat-dialog-actions>
