import { Component, OnInit } from '@angular/core';
import { CurrentUserService } from '../services/currentUser.service';
import { User } from '../model/user.model';
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { RedirectHelper } from '../helpers/redirect.helper';
import { MessagingService } from '../components/messaging/messaging.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { RepairerAdminService } from '../services/repairer-admin.service';

@Component({
  selector: 'app-accept-eula',
  templateUrl: './accept-eula.component.html',
  styleUrls: ['./accept-eula.component.scss']
})
export class AcceptEulaComponent implements OnInit {
  currentUser: User;
  eulaContent: SafeHtml = '';

  constructor(
    private currentUserService: CurrentUserService,
    private userService: UserService,
    private redirectHelper: RedirectHelper,
    private message: MessagingService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private repAdminService: RepairerAdminService
  ) {}

  ngOnInit(): void {
    this.currentUser = this.currentUserService.currentUserValue;
    fetch('assets/eula/EULA.html')
      .then((res) => res.text())
      .then((data) => {
        this.eulaContent = this.sanitizer.bypassSecurityTrustHtml(data);
      });
  }

  decline() {
    this.router.navigate(['login']).catch();
  }

  agree() {
    this.repAdminService.acceptEula(this.currentUser.username).subscribe(
      () => {},
      () => this.message.error('Server Error'),
      () => {
        this.userService.getCurrentUser().subscribe(
          (user) => this.currentUserService.userLogin(user),
          () => {},
          () => {
            this.redirectHelper.manageRedirection(this.currentUserService.currentUserValue, this.router);
          }
        );
      }
    );
  }
}
