<div class="add-diary">
  <mat-card class="curtains">
    <div class="diary-header secondary-icon">
      <h5>Diary</h5>
      <div class="diary-kebab-panel">
        <button mat-icon-button (click)="confirmDeleteUnsubmittedDiaries()">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
    <mat-card-content>
      <form [formGroup]="diaryForm">
        <mat-form-field appearance="outline" class="diary-subject">
          <mat-label>Subject</mat-label>
          <input formControlName="subject" matInput required />
          <mat-error *ngIf="subject.hasError('required')"> Subject is <strong>required</strong> </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="diary-note">
          <mat-label>Message</mat-label>
          <textarea matInput formControlName="body" cdkTextareaAutosize="true" cdkAutosizeMinRows="1"></textarea>
        </mat-form-field>
      </form>
    </mat-card-content>
  </mat-card>
</div>
