import { Component, OnInit, inject, DestroyRef } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
  MatLegacyDialog as MatDialog
} from '@angular/material/legacy-dialog';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ToolbarService } from '../../../services/toolbar.service';
import { MessagingService } from '../../../components/messaging/messaging.service';
import { EscapeDialogHelper } from '../../../helpers/escape-dialog.helper';
import { GuidanceBoxComponent } from '../../../components/guidance-box/guidance-box.component';
import { switchMap } from 'rxjs/operators';
import { RepairerAdminService } from '../../../services/repairer-admin.service';
import { ModalConfigsHelper } from '../../../helpers/modal-configs.helper';
import { smallModalConfig } from '../../../consts/modal-config.const';
import { CurrentUserService } from '../../../services/currentUser.service';
import { Address } from '../../../model/address.model';
import { QUOTING_SYSTEMS } from '../../../consts/quotingSystems.const';
import { EMAIL_PATTERN_STRING } from '../../../consts/patterns.const';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-edit-address',
  templateUrl: './edit-address.component.html'
})
export class EditAddressComponent implements OnInit {
  editAddressForm: FormGroup;
  quotingSystemList = QUOTING_SYSTEMS;
  pending = false;
  isPublished: boolean;

  data = inject(MAT_DIALOG_DATA);
  currentUserService = inject(CurrentUserService);
  private dialogRef = inject(MatDialogRef<EditAddressComponent>);
  private fb = inject(UntypedFormBuilder);
  private toolbarService = inject(ToolbarService);
  private message = inject(MessagingService);
  private escapeDialogRef = inject(EscapeDialogHelper);
  private confirmBox = inject(MatDialog);
  private repAdminService = inject(RepairerAdminService);
  private modalConfigsHelper = inject(ModalConfigsHelper);
  private destroyRef = inject(DestroyRef);

  get companyName() {
    return this.editAddressForm.get('companyName');
  }
  get email() {
    return this.editAddressForm.get('email');
  }
  get phoneNumber() {
    return this.editAddressForm.get('phoneNumber');
  }
  get licenseNumber() {
    return this.editAddressForm.get('licenseNumber');
  }
  get physicalAddress() {
    return this.editAddressForm.get('physicalAddress');
  }
  get billingAddress() {
    return this.editAddressForm.get('billingAddress');
  }
  get sameAsPhysical() {
    return this.editAddressForm.get('sameAsPhysical');
  }
  get repairerGroupId() {
    return this.editAddressForm.get('repairerGroupId');
  }
  get quotingSystem() {
    return this.editAddressForm.get('quotingSystem');
  }

  ngOnInit(): void {
    this.isPublished = this.data?.repairer?.status === 'ACTIVE';
    this.data.repairer.physicalAddress.country = this.data.repairer.country;
    this.data.repairer.billingAddress.country = this.data.repairer.country;
    this.populateEditAddressForm();

    this.escapeDialogRef.escapeDialog(this.dialogRef);
  }

  populateEditAddressForm() {
    this.editAddressForm = this.fb.group({
      companyName: [
        {
          value: this.data.repairer.companyName || '',
          disabled: !this.currentUserService.currentUserValue.privileges.includes('SYSADMIN')
        },
        Validators.required
      ],
      email: [this.data.repairer.email || '', [Validators.required, Validators.pattern(EMAIL_PATTERN_STRING)]],
      phoneNumber: [this.data.repairer.phoneNumber || '', Validators.required],
      licenseNumber: [
        {
          value: this.data.repairer.licenseNumber || '',
          disabled: !this.currentUserService.currentUserValue.privileges.includes('SYSADMIN')
        }
      ],
      physicalAddress: this.data.repairer.physicalAddress,
      billingAddress: [
        {
          value: this.data.repairer.billingAddress,
          disabled: this.data.repairer.sameAsPhysical
        }
      ],
      sameAsPhysical: this.data.repairer.sameAsPhysical,
      repairerGroupId: this.data.repairer.repairerGroupId,
      quotingSystem: [
        {
          value: this.data.repairer.quotingSystem || '',
          disabled: !this.currentUserService.currentUserValue.privileges.includes('SYSADMIN')
        }
      ]
    });

    this.sameAsPhysical.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value) => {
      this.billingAddress.patchValue(this.physicalAddress.value);
      value ? this.billingAddress.disable() : this.billingAddress.enable();
    });

    this.companyName.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      if (this.companyName.invalid) {
        this.companyName.markAllAsTouched();
      }
    });

    this.physicalAddress.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((address: Address) => this.sameAsPhysical.value && this.billingAddress.patchValue(address));
  }

  updateRepairerSites() {
    this.pending = true;
    this.toolbarService.setCloudState('SAVING');
    this.repAdminService.updateRepairerSiteWithAddress(this.data.repairer.id, this.editAddressForm.value).subscribe(
      () => {
        this.dialogRef.close('Success');
        this.toolbarService.setCloudState('RESTING');
        this.pending = false;
      },
      () => {
        this.message.error('Could not update the repairer site.');
        this.toolbarService.setCloudState('RESTING');
        this.pending = false;
      }
    );
  }

  publishRepairerSite() {
    this.pending = true;
    this.toolbarService.setCloudState('SAVING');
    this.repAdminService
      .updateRepairerSiteWithAddress(this.data.repairer.id, this.editAddressForm.value)
      .pipe(
        switchMap(() => this.repAdminService.publishRepairerSite()),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(
        () => {
          this.dialogRef.close('PublishRepairerSucessful');
        },
        () => {
          this.message.error('Publish failed, please contact Support.');
          this.toolbarService.setCloudState('RESTING');
          this.pending = false;
        },
        () => {
          this.toolbarService.setCloudState('RESTING');
          this.pending = false;
          this.confirmPublishRepairerAdminLocation();
        }
      );
  }

  private confirmPublishRepairerAdminLocation() {
    const data = {
      title: 'Location Published',
      alertMessage:
        'Your location has successfully been published and will now be available to all EstImage insurers. This completes your registration.',
      confirmButton: 'OK'
    };
    this.confirmBox.open(GuidanceBoxComponent, this.modalConfigsHelper.buildMediumModalConfig(data, smallModalConfig));
  }
}
