import { Component, ElementRef, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MessagingService } from '../../../components/messaging/messaging.service';
import { ToolbarService } from '../../../services/toolbar.service';
import { GlassGuideService } from '../../glass-guide.service';
import { Make, Model, Variant } from '../../../model/glass-guide.model';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { UserService } from '../../../services/user.service';
import { EscapeDialogHelper } from '../../../helpers/escape-dialog.helper';
import * as moment from 'moment';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-vehicle-selection',
  templateUrl: './vehicle-selection.component.html',
  styleUrls: ['./vehicle-selection.component.scss']
})
export class VehicleSelectionComponent implements OnInit, OnDestroy {
  vehicleSelectionForm: UntypedFormGroup;
  pending = false;

  makes: Make[] = [];
  models: Model[] = [];
  years: number[] = [];
  variants: Variant[] = [];
  lastUpdateDate = '';

  initializeDataFromVehicle = false;

  displayedColumns: string[] = ['checkBox', 'name', 'bodyType', 'doors', 'transmission', 'month', 'engine'];
  variantDataSource: MatTableDataSource<Variant>;
  private unsubscribe = new Subject();

  selectedVehicle = '';

  constructor(
    public dialogRef: MatDialogRef<VehicleSelectionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder,
    private glassGuideService: GlassGuideService,
    private userService: UserService,
    private message: MessagingService,
    public toolbarService: ToolbarService,
    private escapeDialogRef: EscapeDialogHelper,
    private elementRef: ElementRef
  ) {}

  get make() {
    return this.vehicleSelectionForm.get('make');
  }
  get model() {
    return this.vehicleSelectionForm.get('model');
  }
  get year() {
    return this.vehicleSelectionForm.get('year');
  }
  get filter() {
    return this.vehicleSelectionForm.get('filter');
  }
  get unlistedGlassVehicle() {
    return this.vehicleSelectionForm.get('unlistedGlassVehicle');
  }

  ngOnInit(): void {
    const { unlistedGlassVehicle, vehicleSelectedFromGlassGuide, makeId, modelId, vehicleYear } = this.data ?? {};

    this.vehicleSelectionForm = this.fb.group({
      make: makeId,
      model: modelId,
      year: vehicleYear,
      filter: '',
      unlistedGlassVehicle: unlistedGlassVehicle || false
    });

    if (vehicleSelectedFromGlassGuide && makeId && modelId && vehicleYear) {
      this.initializeDataFromVehicle = true;
      this.getVariants();
    }

    this.model.disable();
    this.year.disable();
    this.escapeDialogRef.escapeDialog(this.dialogRef);

    this.glassGuideService
      .getMakes()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (makes) => {
          this.makes = makes;
          if (this.initializeDataFromVehicle) {
            this.model.enable();
            this.year.enable();
            this.getModels();
          }
        },
        () => this.message.error('Could not retrieve the list of makes.')
      );
    this.glassGuideService
      .getLastUpdateDate()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (lastUpdateDate) => (this.lastUpdateDate = lastUpdateDate),
        () => this.message.error('Could not retrieve the glass guide last update.')
      );

    this.unlistedGlassVehicle.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((value) => {
      if (value) {
        this.selectedVehicle = null;
      }
    });
  }

  selectMake() {
    this.model.setValue('');
    this.year.setValue('');
    this.getModels();
    this.getYears();
    this.model.enable();
    this.year.enable();
    this.variants = [];
  }

  getModels() {
    this.glassGuideService
      .getMakeModels(this.make.value)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (models) => {
          this.models = models;
          if (this.initializeDataFromVehicle) {
            this.getModelYears();
          }
        },
        () => this.message.error('Could not retrieve the list of models.')
      );
  }

  getYears() {
    this.glassGuideService
      .getMakeYears(this.make.value)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (years) => (this.years = years),
        () => this.message.error('Could not retrieve the list of years.')
      );
  }

  getModelYears() {
    this.glassGuideService
      .getModelYears(this.make.value, this.model.value)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (years) => {
          this.years = years;
          this.initializeDataFromVehicle = false;
        },
        () => this.message.error('Could not retrieve the list of years.')
      );
  }

  getYearModels() {
    this.glassGuideService
      .getYearModels(this.make.value, this.year.value)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (models) => (this.models = models),
        () => this.message.error('Could not retrieve the list of models.')
      );
  }

  getVariants() {
    if (this.make.value && this.model.value && this.year.value) {
      this.glassGuideService
        .getVariants(this.make.value, this.model.value, this.year.value)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(
          (variants) => {
            this.variants = variants;
            if (this.initializeDataFromVehicle) {
              this.selectedVehicle = this.data.nvic;
              this.scrollToSelectedRow();
            }
          },
          () => this.message.error('Could not retrieve the list of variants.'),
          () => (this.variantDataSource = new MatTableDataSource(this.variants))
        );
    }
  }

  applyFilter() {
    this.variantDataSource.filter = this.filter.value.trim().toLowerCase();
  }

  selectVehicle() {
    this.pending = true;
    this.userService
      .updateJob(
        this.selectedVehicle || this.unlistedGlassVehicle.value,
        this.data.jobId,
        this.selectedVehicle ? 'nvic' : 'unlistedGlassVehicle'
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        () => this.dialogRef.close('Success'),
        () => {
          this.message.error('Could not select vehicle.');
          this.pending = false;
        },
        () => {
          this.message.confirm('Vehicle selected.');
          this.pending = false;
        }
      );
  }

  private scrollToSelectedRow() {
    if (this.selectedVehicle) {
      setTimeout(() => {
        this.elementRef.nativeElement
          .querySelector(`#variant-${this.selectedVehicle}`)
          ?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 500);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  formatMonth = (month: number) =>
    moment()
      .month(month - 1)
      .format('MMM');
}
