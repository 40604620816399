import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import {MessagingService} from '../components/messaging/messaging.service';
import {WebsocketService} from './websocket.service';

@Injectable({
  providedIn: 'root'
})
export class WebRTCService {
  peerConnection: RTCPeerConnection;
  dataChannel: RTCDataChannel;
  displayMediaStream: any;
  webRTCConnected = new Subject<boolean>();

  constructor(
    private websocketService: WebsocketService,
    private message: MessagingService
  ) {}

  establishWebRtcConnection(configuration: any, topic: string) {
    // Create an instance of RTC connection
    this.peerConnection = new RTCPeerConnection(configuration);

    // Create a channel to send data
    this.dataChannel = this.peerConnection.createDataChannel('message', {});

    // Setup ice handling
    this.peerConnection.onicecandidate = this.getIceCandidateCallback(topic);
  }

  getIceCandidateCallback(topic: string) {
    return (event) => {
      if (event.candidate != null) {
        this.websocketService.send(topic, {
          event: 'candidate',
          data: event.candidate
        });
      }
    };
  }

  async getScreenSizeOnopen(displayMediaOptions: any, mouse: any, topic: string, repUserName: string) {
    const mediaDevices = navigator.mediaDevices as any;
    await mediaDevices
      .getDisplayMedia(displayMediaOptions)
      .then((stream) => {
        this.displayMediaStream = stream;
      })
      .catch((er) => {
        if (er.code === 0 && er.name === 'NotAllowedError') {
          this.websocketService.send(topic, {
            event: 'stoppingShareScreen',
            data: repUserName
          });
          // Only for firefox => when we click block in the user interface once we cannot share screen next time
          if (navigator.userAgent.indexOf('Firefox') > 0) {
            this.message.error(`The user surface was blocked, please refresh your page to get access.`);
          }
        }
      });

    this.dataChannel.onopen = () => {
      // get screen size of the video
      if (this.displayMediaStream) {
        this.sendDataMessage('screenSetting', this.displayMediaStream.getTracks()[0].getSettings());
      }
    };

    if (this.displayMediaStream) {
      this.startScreenShare(topic);
    }

    this.peerConnection.addEventListener('datachannel', (event) => {
      event.channel.onmessage = (messageEvent: MessageEvent) => {
        // move the mouse position
        this.moveMousePosition(messageEvent, mouse);
      };
    });

    if (this.displayMediaStream) {
      this.displayMediaStream.getVideoTracks()[0].addEventListener('ended', () => {
        this.webRTCConnected.next(false);
        mouse.style.visibility = 'hidden';
        this.websocketService.send(topic, {
          event: 'stoppingShareScreen',
          data: repUserName
        });
      });
    }
  }

  startScreenShare(topic: string) {
    this.peerConnection.addTrack(this.displayMediaStream.getTracks()[0], this.displayMediaStream);
    this.peerConnection
      .createOffer()
      .then((offer: RTCSessionDescriptionInit) => {
        this.webRTCConnected.next(true);
        this.websocketService.send(topic, {
          event: 'offer',
          data: offer
        });
        return this.peerConnection.setLocalDescription(offer);
      })
      .catch((er) => console.error(er));
  }

  sendDataMessage(eventType, data) {
    this.dataChannel.send(JSON.stringify({ eventType, data }));
  }

  async handleOffer(topic: string, offer: any) {
    if (this.peerConnection) {
      await this.peerConnection.setRemoteDescription(new RTCSessionDescription(offer)).then();
      await this.peerConnection
        .createAnswer()
        .then((answer) => {
          this.websocketService.send(topic, {
            event: 'answer',
            data: answer
          });
          return this.peerConnection.setLocalDescription(answer);
        })
        .catch((er) => console.error(er));
    }
  }

  async handleCandidate(candidate: any) {
    if (candidate && this.peerConnection) {
      await this.peerConnection
        .addIceCandidate(new RTCIceCandidate(candidate))
        .then()
        .catch((er) => console.error(er));
    }
  }

  async handleAnswer(answer: any) {
    if (answer && this.peerConnection) {
      await this.peerConnection.setRemoteDescription(new RTCSessionDescription(answer)).then().catch();
    }
  }

  moveMousePosition(event: any, mouse: any) {
    const message = JSON.parse(event.data);
    // Calculate position of cursor according to the reflected screen
    const x = message.data.x * (window.innerWidth / message.data.width);
    const y = message.data.y * (window.innerHeight / message.data.height);
    if (mouse) {
      mouse.style.left = `${x}px`;
      mouse.style.top = `${y}px`;
      mouse.setAttribute('data-value', message.data.sysadminName);
      if (message.eventType === 'mousedown' && message.data.isOnHost) {
        mouse.style.visibility = 'visible';
        mouse.firstChild.style.visibility = 'visible';
        mouse.firstChild.style.animation = 'spotLight 1.5s cubic-bezier(0.3, 0.1, 1, 0.3)';
        // The highlight disappear after 5 seconds
        setTimeout(() => {
          mouse.firstChild.style.visibility = 'hidden';
          mouse.firstChild.style.animation = 'none';
        }, 5000);
      }

      if (message.eventType === 'mouseup' && message.data.isOnHost) {
        mouse.style.visibility = 'visible';
        mouse.firstChild.style.visibility = 'hidden';
        mouse.firstChild.style.animation = 'none';
      }

      if (message.eventType === 'mouseenter' && message.data.isOnHost) {
        mouse.style.visibility = 'visible';
      }

      if (message.eventType === 'mouseout' && !message.data.isOnHost) {
        mouse.style.visibility = 'hidden';
      }
    }
  }

  addEventsForVideo(host: any, sysAdminName: string) {
    this.sendDataEventsForScreenShare(host, sysAdminName, 'mousemove');
    this.sendDataEventsForScreenShare(host, sysAdminName, 'mousedown', true);
    this.sendDataEventsForScreenShare(host, sysAdminName, 'mouseup', true);
    this.sendDataEventsForScreenShare(host, sysAdminName, 'mouseenter', true);
    this.sendDataEventsForScreenShare(host, sysAdminName, 'mouseout', false);
  }

  sendDataEventsForScreenShare(host: any, sysAdminName: string, eventType: string, isOnHost?: boolean) {
    const hostBorder = 5;
    host.addEventListener(eventType, (event) => {
      const rect = host.getBoundingClientRect();
      const posX = event.clientX - rect.left;
      const posY = event.clientY - rect.top;

      this.sendDataMessage(event.type, {
        isOnHost,
        x: posX,
        y: posY,
        sysadminName: sysAdminName,
        width: rect.width - hostBorder,
        height: rect.height - hostBorder
      });
    });
  }

  // Please keep this method for future use
  click(x, y) {
    const ev = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
      screenX: x,
      screenY: y
    });

    const el = document.elementFromPoint(x, y);

    el.dispatchEvent(ev);
  }

  resetShareScreen() {
    if (this.peerConnection) {
      this.peerConnection.close();
      this.peerConnection = null;
      this.displayMediaStream = null;
      this.webRTCConnected.next(false);
    }
  }

  stopShareScreen(element: any) {
    if (this.displayMediaStream) {
      this.displayMediaStream.getTracks().forEach((track) => {
        track.stop();
      });
      element.srcObject = null;
      this.webRTCConnected.next(false);
    }
  }
}
