import { Injectable } from '@angular/core';
import { MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { extraWideModalConfig, mediumModalConfig, smallModalConfig } from '../consts/modal-config.const';

@Injectable({
  providedIn: 'root'
})
export class ModalConfigsHelper {
  buildExtraWideModalConfig(data: Object, setMaxHeight?: number) {
    const dialogConfig: MatDialogConfig = extraWideModalConfig;
    dialogConfig.panelClass = null;
    dialogConfig.backdropClass = null;
    dialogConfig.data = data;
    if (setMaxHeight) {
      dialogConfig.maxHeight = `${setMaxHeight}vh`;
    }
    return dialogConfig;
  }

  buildMediumModalConfig(data?: Object, configType = mediumModalConfig || smallModalConfig) {
    const dialogConfig: MatDialogConfig = configType;
    dialogConfig.panelClass = 'medium-small-modal';
    dialogConfig.data = data;
    return dialogConfig;
  }
}
