import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { PreAuthority } from '../../../model/preAuthority.model';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DateInputHelper } from '../../../helpers/date-input.helper';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { UserService } from '../../../services/user.service';
import { ToolbarService } from '../../../services/toolbar.service';
import { MessagingService } from '../../../components/messaging/messaging.service';
import { DecimalPipe } from '@angular/common';
import { CurrentUserService } from '../../../services/currentUser.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-pre-authority-curtain',
  templateUrl: './pre-authority-curtain.component.html',
  styleUrls: ['./pre-authority-curtain.component.scss'],
  providers: [DecimalPipe]
})
export class PreAuthorityCurtainComponent implements OnInit, OnChanges, OnDestroy {
  @Input() preAuthority: PreAuthority;
  @Input() jobId: number;
  @Input() editable: boolean;
  @Input() ownerContributionEditable: boolean;
  @Output() disableSendButton: EventEmitter<boolean> = new EventEmitter();
  @Output() refreshJobViewer: EventEmitter<any> = new EventEmitter();
  @Output() updateOwnerContribution: EventEmitter<any> = new EventEmitter();
  @Output() openPdfReport: EventEmitter<null> = new EventEmitter<null>();

  timezone: string;
  preAuthorityCurtainOpened = false;
  saving = false;
  preAuthorityForm: UntypedFormGroup;
  private unsubscribe = new Subject();

  constructor(
    private fb: UntypedFormBuilder,
    private userService: UserService,
    private currentUserService: CurrentUserService,
    private message: MessagingService,
    private decPipe: DecimalPipe,
    public toolbarService: ToolbarService,
    public dateInputHelper: DateInputHelper
  ) {}

  get ownerContribution() {
    return this.preAuthorityForm.get('ownerContribution');
  }

  ngOnInit(): void {
    this.timezone = this.currentUserService?.timeZone;
    this.preAuthorityForm = this.fb.group({
      ownerContribution: [
        this.preAuthority.ownerContribution,
        Validators.pattern('\\-?(\\d+|\\d{1,3}(,\\d{3})*)(\\.\\d{1,2})?')
      ]
    });

    this.formatOwnerContribution();

    this.ownerContribution.valueChanges.pipe(takeUntil(this.unsubscribe), distinctUntilChanged()).subscribe(() => {
      if (this.ownerContribution.valid) {
        this.disableSendButton.emit(false);
        this.updateJob('ownerContribution');
      } else {
        this.ownerContribution.markAsTouched();
        this.disableSendButton.emit(true);
      }
    });

    this.editable ? this.preAuthorityForm.enable() : this.preAuthorityForm.disable();
    this.ownerContributionEditable ? this.ownerContribution.enable() : this.ownerContribution.disable();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.preAuthority.firstChange) {
      this.formatOwnerContribution();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  updateJob(fieldName: string) {
    this.saving = true;
    const fieldValue = this.ownerContribution.value
      ? this.ownerContribution.value.toString().replace(',', '')
      : this.ownerContribution.value;

    if (this.preAuthorityForm.controls[fieldName].valid) {
      this.toolbarService.setCloudState('SAVING');
      this.userService.updateJob(fieldValue, this.jobId, fieldName).subscribe(
        () => this.toolbarService.setCloudState('RESTING'),
        () => {
          this.message.error('Could not update field.');
          this.toolbarService.setCloudState('RESTING');
          this.saving = false;
        },
        () => {
          this.updateOwnerContribution.emit(fieldValue);
          this.preAuthority.ownerContribution = this.ownerContribution.value;
          this.saving = false;
        }
      );
    }
  }

  formatOwnerContribution() {
    if (this.saving) {
      this.ownerContribution.setValue(this.decPipe.transform(this.ownerContribution.value, '1.2-2'), {
        emitEvent: false
      });
    } else {
      this.ownerContribution.setValue(this.decPipe.transform(this.preAuthority.ownerContribution, '1.2-2'), {
        emitEvent: false
      });
    }
  }
}
