import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { CustomerContact } from '../../../../model/gicop-notifications';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import * as moment from 'moment';

@Component({
  selector: 'app-customer-contact-table',
  templateUrl: './customer-contact-table.component.html',
  styleUrls: ['./customer-contact-table.component.scss']
})
export class CustomerContactTableComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() customerContacts: CustomerContact[];
  @Input() timezone: string;
  @ViewChild('customerContactSort', { static: true }) customerContactSort: MatSort;
  columnHeader = ['date', 'isContactSuccessful', 'contactMethodType', 'isIssueDiscussed', 'additionalDetails'];
  dataSource: MatTableDataSource<CustomerContact>;
  activeColumn = 'date';
  constructor() {}

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<any>(this.customerContacts);
    this.dataSource.sortingDataAccessor = this.customSortingAccessor.bind(this);
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.customerContactSort;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.customerContacts && !changes.customerContacts.firstChange) {
      this.dataSource = new MatTableDataSource<any>(this.customerContacts);
      this.dataSource.sortingDataAccessor = this.customSortingAccessor.bind(this);
      this.dataSource.sort = this.customerContactSort;
    }
  }

  private customSortingAccessor(item: any, property: string): any {
    if (property === 'additionalDetails' || property === 'contactMethodType') {
      return item[property].toLowerCase();
    } else if (property === 'date') {
      return this.sortByDate(item[property], item.time);
    } else if (property === 'isIssueDiscussed') {
      return item[property] ? 'yes' + (item.issueResolutionType ? 'resolved' : 'unresolved') : 'no';
    }
    return item[property];
  }

  sortByDate(date: string, time: string): string {
    const hoursAndMinutes = time.split(':');
    return moment(date)
      .tz(this.timezone)
      .set({ hour: +hoursAndMinutes[0], minute: +hoursAndMinutes[1], s: 0 })
      .format();
  }
}
