import { Component, Inject, ViewChild, Renderer2, HostListener, ElementRef, OnInit } from '@angular/core';
import { IMAGE_TOKEN } from './lightbox.tokens';
import { LightboxRef } from './lightbox-ref';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ImageGallerySelector } from './lightbox.service';
import { PhotoReference } from '../../../model/job.model';

@Component({
  selector: 'app-lightbox',
  templateUrl: './lightbox.component.html',
  styleUrls: ['./lightbox.component.scss'],
  animations: [
    trigger('fade', [
      state('fadeOut', style({ opacity: 0 })),
      state('fadeIn', style({ opacity: 1 })),
      transition('* => fadeIn', animate('400ms cubic-bezier(0.25, 0.8, 0.25, 1)'))
    ])
  ]
})
export class LightboxComponent implements OnInit {
  @ViewChild('imageElement', { read: ElementRef, static: false }) imageElement: ElementRef;
  zoomUnit = 1;
  initialImagePosition = null;
  loading = true;
  tooltipIsVisible = false;
  selectedImage: PhotoReference;
  selectedImgSrc;
  selectedIndex;

  constructor(
    private renderer: Renderer2,
    public boxRef: LightboxRef,
    @Inject(IMAGE_TOKEN) public imageGallerySelector: ImageGallerySelector
  ) {}

  ngOnInit(): void {
    this.updateSelectedImage(this.imageGallerySelector.selectedIndex);
  }

  updateSelectedImage(index: number) {
    const { images, repairerSiteId, jobId } = this.imageGallerySelector;
    this.selectedIndex = index;
    this.selectedImage = images[index];
    this.selectedImgSrc = `/jobAttachments/${repairerSiteId}/${jobId}/${this.selectedImage.id}.${this.selectedImage.extension}`;
  }

  previous() {
    this.selectedIndex === 0
      ? this.updateSelectedImage(this.imageGallerySelector.images.length - 1)
      : this.updateSelectedImage(this.selectedIndex - 1);
  }

  next() {
    this.selectedIndex === this.imageGallerySelector.images.length - 1
      ? this.updateSelectedImage(0)
      : this.updateSelectedImage(this.selectedIndex + 1);
  }

  wheelScroll(event) {
    const delta = Math.max(-1, Math.min(1, event.wheelDelta || event.deltaY || -event.detail));
    this.zoomUnit = Math.max(0.3, this.zoomUnit + 0.3 * delta);
    this.resizeImage();
  }

  zoomIn() {
    this.zoomUnit = this.zoomUnit + 0.3;
    this.resizeImage();
  }

  zoomOut() {
    this.zoomUnit = Math.max(0.3, this.zoomUnit - 0.3);
    this.resizeImage();
  }

  resizeImage() {
    this.renderer.setStyle(this.imageElement.nativeElement, 'transform', 'scale(' + this.zoomUnit + ')');
  }

  reset() {
    this.renderer.setStyle(this.imageElement.nativeElement, 'transform', 'scale(1)');
    this.zoomUnit = 1;
    this.initialImagePosition = { x: 0, y: 0 };
  }

  close() {
    this.boxRef.close();
  }

  @HostListener('document:keydown', ['$event'])
  handleKeydown(event: KeyboardEvent) {
    switch (event.key) {
      case 'Escape':
        this.close();
        break;
      case 'ArrowLeft':
        this.previous();
        break;
      case 'ArrowRight':
        this.next();
        break;
    }
  }
}
