<div class="add-customer-vulnerability">
  <mat-card class="curtains">
    <div class="customer-vulnerability-header secondary-icon">
      <h5>Vulnerable Customer</h5>
      <div class="customer-panel">
        <button mat-icon-button (click)="confirmDeleteVulnerability()">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
    <mat-card-content>
      <form [formGroup]="customerVulnerabilityForm" class="gicop">
        <div class="tow-fields">
          <mat-form-field appearance="outline">
            <mat-label>Customer Vulnerability</mat-label>
            <mat-select formControlName="customerVulnerability" required [disableOptionCentering]="true">
              <mat-option *ngFor="let item of customerVulnerabilityList" [value]="item">{{ item }}</mat-option>
            </mat-select>
            <mat-error *ngIf="customerVulnerability.invalid">
              Customer Vulnerability is <strong>required</strong>
            </mat-error>
          </mat-form-field>
          <mat-form-field
            appearance="outline"
            *ngIf="customerVulnerability.value === 'Customer Speaks'"
            class="language-spoken">
            <mat-label>Language Spoken</mat-label>
            <mat-chip-list #languageChipList formControlName="languagesSpoken" required>
              <mat-chip
                *ngFor="let language of languagesSpoken.value"
                [removable]="true"
                (removed)="removeLanguage(language)">
                {{ language | titlecase }}
                <mat-icon matChipRemove>cancel</mat-icon>
              </mat-chip>
              <input
                matInput
                autocomplete="off"
                [matChipInputFor]="languageChipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                [matChipInputAddOnBlur]="true"
                (matChipInputTokenEnd)="addLanguage($event)"
                (blur)="addLanguage($event)" />
            </mat-chip-list>
            <mat-error *ngIf="languagesSpoken.value.length === 0"
              >Language Spoken is <strong>required</strong></mat-error
            >
          </mat-form-field>
          <mat-form-field appearance="outline" *ngIf="customerVulnerability.value === 'Other'">
            <mat-label>Details</mat-label>
            <input matInput autocomplete="off" formControlName="details" required />
            <mat-error *ngIf="details.invalid">Details is <strong>required</strong></mat-error>
          </mat-form-field>
        </div>
        <div class="contact-info-container">
          <div class="title-1">Contact</div>
          <div class="info-message-container subtitle-2">
            <mat-icon class="info-icon">info_outline</mat-icon>
            <span>You may be contacted to provide further information on the vulnerability</span>
          </div>
        </div>
        <div class="three-fields">
          <mat-form-field appearance="outline">
            <mat-label>Contact Person</mat-label>
            <input matInput formControlName="contactPerson" required />
            <mat-error *ngIf="contactPerson.invalid">Contact Person is <strong>required</strong></mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Position</mat-label>
            <input matInput formControlName="position" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Phone Number</mat-label>
            <input matInput formControlName="phoneNumber" required />
            <mat-error *ngIf="phoneNumber.invalid">Phone Number is <strong>required</strong></mat-error>
          </mat-form-field>
        </div>
        <mat-form-field appearance="outline">
          <mat-label>Email</mat-label>
          <input matInput formControlName="email" required />
          <mat-error *ngIf="email.errors?.required">Email is <strong>required</strong></mat-error>
          <mat-error *ngIf="email.errors?.pattern">Invalid email format</mat-error>
        </mat-form-field>
      </form>
    </mat-card-content>
  </mat-card>
</div>
