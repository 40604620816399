<div class="self-authorise">
  <mat-card class="curtains">
    <div class="self-authorise-header">
      <h5>Self-Authorise</h5>
      <div class="buttons-container">
        <div class="secondary-icon">
          <button type="button" mat-icon-button (click)="openPdfReport.emit(); $event.stopPropagation()">
            <mat-icon>picture_as_pdf</mat-icon>
          </button>
        </div>
        <div class="secondary-icon">
          <button mat-icon-button (click)="confirmDeleteSelfAuthorise()"><mat-icon>delete</mat-icon></button>
        </div>
      </div>
    </div>
    <mat-card-content>
      <mat-divider></mat-divider>
      <div class="self-authorise-content">
        <div class="info-guidance subtitle-2">
          <mat-icon>info_outlined</mat-icon>
          <span
            >You can proceed with the repairs to the vehicle immediately if the work meets the guidelines of the
            insurer. Please consult the PDF for detailed guidelines.</span
          >
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
