import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { UserService } from '../../../services/user.service';
import { ToolbarService } from '../../../services/toolbar.service';
import { MessagingService } from '../../../components/messaging/messaging.service';
import { EscapeDialogHelper } from '../../../helpers/escape-dialog.helper';

@Component({
  selector: 'app-remove-job',
  templateUrl: './remove-job.component.html',
  styleUrls: ['./remove-job.component.scss']
})
export class RemoveJobComponent implements OnInit {
  pending = false;

  constructor(
    public dialogRef: MatDialogRef<RemoveJobComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UserService,
    public toolbarService: ToolbarService,
    private message: MessagingService,
    private escapeDialogRef: EscapeDialogHelper
  ) {}

  ngOnInit(): void {
    this.escapeDialogRef.escapeDialog(this.dialogRef);
  }

  hideJob() {
    this.pending = true;
    this.toolbarService.setCloudState('SAVING');
    this.userService.hideFromDashboard(this.data.jobId).subscribe(
      () => this.dialogRef.close('Success'),
      () => {
        this.message.error('Could not remove from dashboard.');
        this.toolbarService.setCloudState('RESTING');
        this.pending = false;
      },
      () => {
        this.message.confirm('Job removed from dashboard.');
        this.toolbarService.setCloudState('RESTING');
        this.pending = false;
      }
    );
  }
}
