import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-car-placeholder-or-photo',
  templateUrl: './car-placeholder-or-photo.component.html',
  styleUrls: ['./car-placeholder-or-photo.component.scss']
})
export class CarPlaceholderOrPhotoComponent {
  @Input() isInRepairerTile = false;
  @Input() jobInfo = { repairerSiteId: null, jobId: null, rego: null };
  @Input() photoInfo = { photoIdentityId: null, photoIdentityExtension: null };
  photoLoaded = {};
  constructor() {}
}
