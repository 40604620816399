import {
  AfterViewInit,
  Component,
  computed,
  ElementRef,
  HostListener,
  inject,
  OnDestroy,
  Signal,
  signal,
  ViewChild,
  ViewEncapsulation,
  WritableSignal
} from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import {Subscription} from 'rxjs';
import {MessagingService} from '../../../components/messaging/messaging.service';
import {SocketClientState} from '../../../enums/socketClientState.enum';
import {BodyParserHelper} from '../../../helpers/body-parser.helper';
import {CobrowsingUser} from '../../../model/user.model';
import {WebRTCService} from '../../../services/webRTC.service';
import {WebsocketService} from '../../../services/websocket.service';

@Component({
  selector: 'app-cobrowsing-screen',
  templateUrl: './cobrowsing-screen.component.html',
  styleUrls: ['./cobrowsing-screen.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CobrowsingScreenComponent implements OnDestroy, AfterViewInit {
  @ViewChild('host', { read: ElementRef, static: false }) host: ElementRef;
  subscriptionToWebsocket: Subscription;
  subscriptionToWebRTC: Subscription;
  repairerUserName = '';
  rtcConfig: any;
  waitingForScreenShare: WritableSignal<boolean> = signal(true);
  screenSharePaused: WritableSignal<boolean> = signal(false);
  cobrowsingUsers: WritableSignal<CobrowsingUser[]> = signal([]);
  cobrowsingUsersPluralMapping = {'=1': '1 user is', other: '# users are'};
  selectedCobrowsingUser: WritableSignal<string> = signal(null);
  waitingRepUserConfirmation: Signal<boolean> = computed(
    () => this.cobrowsingUsers().length && !!this.selectedCobrowsingUser()
  );

  #websocketService = inject(WebsocketService);
  #webRtcService = inject(WebRTCService);
  #bodyParserHelper = inject(BodyParserHelper);
  #messageService = inject(MessagingService);
  #dialogRef = inject(MatDialogRef<CobrowsingScreenComponent>);
  #data = inject(MAT_DIALOG_DATA);

  ngAfterViewInit() {
    this.rtcConfig = JSON.parse(localStorage.getItem('RTCConfiguration'));
    this.subscribeToGetListOfUsers();
    this.#websocketService.connectionState.subscribe((state) => {
      if (state === SocketClientState.ERROR) {
        this.#messageService.error(`You cannot request to share the screen.`);
        this.#dialogRef.close();
      }
    });
  }

  establishConnectionWebRtc(config: any, host: any, username: string) {
    this.#webRtcService.establishWebRtcConnection(config, `/sysadmin/webRTC/signal/${username}`);
    this.#webRtcService.peerConnection.ontrack = this.handleTrackEvent.bind(this);
    this.#webRtcService.addEventsForVideo(host, this.#data.sysadminName);
  }

  private handleTrackEvent(e) {
    const remoteHost = this.host;
    if (remoteHost.nativeElement.srcObject !== e.streams[0]) {
      remoteHost.nativeElement.srcObject = e.streams[0];
      remoteHost.nativeElement.srcObject.muted = true;
    }
  }

  subscribeToWebRTC(username) {
    this.subscriptionToWebRTC = this.#websocketService.clientStomp.subscribe(
      `/sysadmin/webRTC/receiveSignal/${username}`,
      (message) => {
        const content = JSON.parse(message.body);
        const data = content.data;
        switch (content.event) {
          case 'offer':
            this.#webRtcService.handleOffer(`/sysadmin/webRTC/signal/${username}`, data).then();
            break;
          case 'candidate':
            this.#webRtcService.handleCandidate(data).then(() => {
              setTimeout(() => {
                this.waitingForScreenShare.set(false);
                this.host.nativeElement.style.cursor = 'none';
              }, 1000);
            });
            break;
          case 'stoppingShareScreen':
            if (this.host.nativeElement) {
              this.#webRtcService.stopShareScreen(this.host.nativeElement);
            }
            this.#dialogRef.close();
            this.#messageService.error(`The screen share was stopped by ${data}`);
            break;
        }
      }
    );
  }

  subscribeToGetListOfUsers() {
    this.subscriptionToWebsocket = this.#websocketService.clientStomp?.subscribe(
      `/sysadmin/userList/${this.#data.repairerSiteId}`,
      (data) => {
        const message = this.#bodyParserHelper.checkBodyValue(data.body) ? JSON.parse(data.body) : data.body;
        if (message.event === 'sysAdminResponseUserList') {
          this.cobrowsingUsers.set(message.data);
        }
      }
    );
    this.#websocketService.send(`/sysadmin/userList/${this.#data.repairerSiteId}`, '');
  }

  subscribeToScreenShare(username) {
    this.selectedCobrowsingUser.set(username);
    this.subscriptionToWebsocket = this.#websocketService.clientStomp?.subscribe(
      `/sysadmin/screenshare/${username}`,
      (data) => {
        const message = this.#bodyParserHelper.checkBodyValue(data.body) ? JSON.parse(data.body) : data.body;
        if (message.event === 'repUserAcceptShare') {
          this.establishConnectionWebRtc(this.rtcConfig, this.host.nativeElement, username);
          this.subscribeToWebRTC(username);
          this.repairerUserName = message.data;
        }
        if (message === 'STOP' || message.event === 'userLeftApp') {
          if (this.host.nativeElement) {
            this.#webRtcService.stopShareScreen(this.host.nativeElement);
          }
          this.#webRtcService.resetShareScreen();
          this.#dialogRef.close();
          if (message.event === 'userLeftApp') {
            this.#messageService.error(`${message.data} has left the application`);
          }
        }

        if (message.event === 'refreshRepUser' || message.event === 'cancelScreenShare') {
          this.#webRtcService.resetShareScreen();
          this.#dialogRef.close();
          this.#messageService.error(`The screen share was canceled by ${message.data}`);
          this.selectedCobrowsingUser.set(null);
        }

        if (message.event === 'pauseScreenShare') {
          if (!message.data) {
            setTimeout(() => this.screenSharePaused.set(false), 1000);
          } else {
            this.screenSharePaused.set(message.data);
          }
        }
      }
    );
    this.#websocketService.send(`/sysadmin/screenshare/${username}`, {
      event: 'sysAdminRequestShare',
      data: this.#data.sysadminName
    });
  }

  stoppingScreenShare() {
    if (this.host.nativeElement) {
      this.#webRtcService.stopShareScreen(this.host.nativeElement);
    }
    this.#dialogRef.close();
    this.#websocketService.send(
      `/sysadmin/${
        !this.waitingForScreenShare ? `webRTC/signal/${this.selectedCobrowsingUser()}` : 'screenshare'
      }/${this.selectedCobrowsingUser()}`,
      {
        event: 'stoppingShareScreen',
        data: this.#data.sysadminName
      }
    );
  }

  ngOnDestroy() {
    if (this.subscriptionToWebRTC) {
      this.subscriptionToWebRTC.unsubscribe();
    }
    if (this.subscriptionToWebsocket) {
      this.subscriptionToWebsocket.unsubscribe();
    }
    this.selectedCobrowsingUser.set(null);
  }

  @HostListener('window:beforeunload')
  goToPage() {
    this.#websocketService.send(`/sysadmin/screenshare`, {
      event: 'refreshSysAdmin',
      data: this.#data.sysadminName
    });
  }
}
