import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig
} from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { MessagingService } from '../../components/messaging/messaging.service';
import { mediumModalConfig, wideModalConfig } from '../../consts/modal-config.const';
import { EMAIL_PATTERN_STRING } from '../../consts/patterns.const';
import { REPAIR_PROGRESS_MAPPING } from '../../consts/repairProgressMapping.const';
import { TAB_INDEXES } from '../../consts/tabIndexes.const';
import { JobStatusEnum } from '../../enums/jobStatusEnum.enum';
import { ModalConfigsHelper } from '../../helpers/modal-configs.helper';
import { DocumentItem, Invoice, Quote } from '../../model/document.model';
import {
  CustomerContact,
  CustomerFeedback,
  CustomerVulnerability,
  PotentialBreachOfCode
} from '../../model/gicop-notifications';
import { InsurerContactInfo } from '../../model/insurer.model';
import { Diary, DocumentReference, Job, PhotoReference } from '../../model/job.model';
import { CurrentUserService } from '../../services/currentUser.service';
import { JobService } from '../../services/job.service';
import { ToolbarService } from '../../services/toolbar.service';
import { UserService } from '../../services/user.service';
import { UpdateRepairTimelineComponent } from '../repairer-dashboard/update-repair-timeline/update-repair-timeline.component';
import { AddInvoiceComponent } from './add-invoice/add-invoice.component';
import { AddQuoteComponent } from './add-quote/add-quote.component';
import { EditContactComponent } from './edit-contact/edit-contact.component';
import { InvoiceCurtainComponent } from './invoice-curtain/invoice-curtain.component';
import { JobHistoryComponent } from './job-history/job-history.component';
import { RemoveJobComponent } from './remove-job/remove-job.component';
import { SubmitDocumentComponent } from './submit-document/submit-document.component';
import { UploadFilesComponent } from './upload-files/upload-files.component';

@Component({
  selector: 'app-job-viewer',
  templateUrl: './job-viewer.component.html',
  styleUrls: ['./job-viewer.component.scss']
})
export class JobViewerComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() set id(val: number) {
    this.jobId = val;
    this.getJob();
  }

  jobId: number;
  timezone: string;
  loading = false;
  job: Job;
  excessType: string;
  validationDetails = '';
  showValidationGuidance = false;
  unSubmittedDocuments: DocumentItem[] = [];
  unSubmittedQuote: DocumentItem;
  unSubmittedInvoice: DocumentItem;
  submittedInvoices: DocumentItem[] = [];
  partialInvoices: DocumentItem[] = [];
  quotesAuthorizedNotInvoiced: DocumentItem[] = [];
  submittedQuotes: DocumentItem[] = [];
  submittedQuoteNotAuthorised: DocumentItem[] = [];
  unSubmittedPhotos: PhotoReference[] = [];
  submittedPhotos: PhotoReference[];
  submittedDocs: DocumentReference[] = [];
  unSubmittedDocs: DocumentReference[];
  unSubmittedDiary: Diary;
  submittedDiaries: Diary[];
  noQuoteResponse = false;
  noQuoteResponseAssessmentAction: string;
  displayAddInvoiceButton = false;
  invoiceCreated = false;
  invoicePendingReview = false;
  hasQuotes: boolean;
  showBooking = false;
  disableSendButton = false;
  vehicleDateInMandatory = false;
  vehicleDateOutMandatory = false;
  showSelfAuthorisationCurtain = false;
  countItemsToSend = 0;
  supplementQuote = false;
  insurer: InsurerContactInfo;
  containNameLine2 = false;
  indexTab = '';
  unSubmittedCustomerFeedback: CustomerFeedback;
  unSubmittedCustomerContact: CustomerContact;
  unSubmittedPotentialBreachOfCode: PotentialBreachOfCode;
  unSubmittedCustomerVulnerability: CustomerVulnerability;
  emailPattern = new RegExp(EMAIL_PATTERN_STRING);
  documentFeatureEnabled$: Observable<boolean>;
  collapseOpenedCurtain = false;
  expandCurtainInTray = false;
  isVehicleInfosInvalid = false;
  mandatoryRules: string[] = [];
  jobStatusEnum = JobStatusEnum;
  readonly repairProgressMapping = REPAIR_PROGRESS_MAPPING;
  saveJobResponseStatus = null;
  @ViewChild('accordion', { static: true }) accordion: MatAccordion;
  @ViewChildren('unSubmittedInvoiceCurtainComponent')
  unSubmittedInvoiceCurtainComponent: QueryList<InvoiceCurtainComponent>;
  inputFileValue = '';

  submittedItems = [];
  ownerName: string;
  private unsubscribe = new Subject();
  jobStates: any = {};
  invoiceButtonMsg: string;
  isFirstJobDataInitialization = true;

  private router = inject(Router);
  private editContactDialog = inject(MatDialog);
  private uploadFiles = inject(MatDialog);
  private addInvoiceDialog = inject(MatDialog);
  private addQuoteDialog = inject(MatDialog);
  private jobHistoryDialog = inject(MatDialog);
  private repairTimelineDialog = inject(MatDialog);
  jobService = inject(JobService);
  private currentUserService = inject(CurrentUserService);
  private message = inject(MessagingService);
  private toolbarService = inject(ToolbarService);
  private userService = inject(UserService);
  private changeDetectorRef = inject(ChangeDetectorRef);
  private modalConfigsHelper = inject(ModalConfigsHelper);

  get isTotalLossCase() {
    return [this.job.noQuoteResponseAssessmentAction, this.noQuoteResponseAssessmentAction].includes('Total Loss');
  }

  ngOnInit() {
    this.timezone = this.currentUserService?.timeZone;
    this.documentFeatureEnabled$ = this.userService.getdocumentFeatureStatus();
  }

  ngAfterViewInit() {
    this.unSubmittedInvoiceCurtainComponent.changes.subscribe(() => {
      this.validateSendJob();
    });

    // deselect all elements that happen to be selected after double click on a job
    setTimeout(() => {
      if (window.getSelection) {
        window.getSelection().removeAllRanges();
      }
    }, 10);
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  getJob(event?: string, shouldScroll?: boolean) {
    this.loading = true;
    this.toolbarService.setCloudState('FETCHING');
    this.userService.getJob(this.jobId).subscribe({
      next: (job) => (this.job = job),
      error: () => {
        this.router.navigate(['/dashboard']).catch();
        this.message.error(`Could not retrieve the job.`);
        this.loading = false;
        this.toolbarService.setCloudState('RESTING');
      },
      complete: () => {
        this.initializeToolbarData();
        this.loading = false;
        if (event === 'VEHICLE_OUT') {
          this.reviewTimeline();
        }
        this.initializeData();
        this.validateSendJob({ isTotalLossCase: this.isTotalLossCase });
        this.toolbarService.setCloudState('RESTING');
        if (shouldScroll) {
          this.scrollToSendToInsurerTray();
        }
      }
    });
  }

  initializeToolbarData() {
    this.toolbarService.toolbarData({
      pageName: this.job?.ownerIsACompany ? this.job.ownerCompanyName : this.job.ownerPersonName,
      rego: this.job.rego,
      backLink: 'dashboard'
    });
  }

  initializeData() {
    this.countItemsToSend = 0;
    this.submittedItems = [];
    this.unSubmittedDocuments = this.job.documents.filter((document) => !document.submitted);
    this.unSubmittedQuote =
      this.unSubmittedDocuments.length && this.unSubmittedDocuments.filter((document) => document.type === 'QUOTE')[0];
    this.unSubmittedInvoice =
      this.unSubmittedDocuments.length &&
      this.unSubmittedDocuments.filter((document) => document.type === 'INVOICE')[0];
    this.submittedInvoices = this.job.documents.filter(
      (document) => document.submitted && document.type === 'INVOICE' && !(document.document as Invoice).isPartial
    );
    this.partialInvoices = this.job.documents.filter(
      (document) => document.type === 'INVOICE' && (document.document as Invoice).isPartial
    );
    this.submittedQuotes = this.job.documents.filter((document) => document.submitted && document.type === 'QUOTE');
    this.hasQuotes = this.job.documents.filter((document) => document.type === 'QUOTE').length > 0;
    this.submittedPhotos = this.job.photoReferences.filter((photo) => photo.submitted);
    this.unSubmittedPhotos = this.job.photoReferences.filter((photo) => !photo.submitted);
    this.submittedDocs = this.job.documentReferences.filter((doc) => doc.submitted);
    this.unSubmittedDocs = this.job.documentReferences.filter((doc) => !doc.submitted);
    this.submittedDiaries = this.job.diaries.filter((diary) => diary.status !== 'READY_TO_SUBMIT');
    this.unSubmittedDiary = this.job.diaries.filter((diary) => diary.status === 'READY_TO_SUBMIT')[0];
    this.unSubmittedCustomerFeedback =
      (this.job.gicopUI?.unsubmittedGicopNotification as CustomerFeedback)?.gicopNotificationType ===
      'CUSTOMER_FEEDBACK'
        ? (this.job.gicopUI.unsubmittedGicopNotification as CustomerFeedback)
        : null;
    this.unSubmittedCustomerContact =
      (this.job.gicopUI?.unsubmittedGicopNotification as CustomerContact)?.gicopNotificationType === 'CUSTOMER_CONTACT'
        ? (this.job.gicopUI.unsubmittedGicopNotification as CustomerContact)
        : null;
    this.unSubmittedPotentialBreachOfCode =
      (this.job.gicopUI?.unsubmittedGicopNotification as PotentialBreachOfCode)?.gicopNotificationType ===
      'CODE_OF_PRACTICE_BREACH'
        ? (this.job.gicopUI.unsubmittedGicopNotification as PotentialBreachOfCode)
        : null;
    this.unSubmittedCustomerVulnerability =
      (this.job.gicopUI?.unsubmittedGicopNotification as CustomerVulnerability)?.gicopNotificationType ===
      'CUSTOMER_VULNERABILITY'
        ? (this.job.gicopUI.unsubmittedGicopNotification as CustomerVulnerability)
        : null;
    this.noQuoteResponse = this.job.noQuoteResponse;
    this.showBooking = this.job.booking;
    this.showSelfAuthorisationCurtain = this.job.enableSelfAuthorityCurtain;
    this.quotesAuthorizedNotInvoiced = this.submittedQuotes.filter(
      (quote) => (quote.document as Quote).authorized && !(quote.document as Quote).invoiced
    );
    this.submittedQuoteNotAuthorised = this.submittedQuotes.filter((quote) => !(quote.document as Quote).authorized);
    this.invoiceCreated =
      this.job.documents.filter(
        (document) => document.type === 'INVOICE' && (document.document as Invoice).status === 'CREATED'
      ).length > 0;
    this.invoicePendingReview =
      this.job.documents.filter(
        (document) => document.type === 'INVOICE' && (document.document as Invoice).status === 'PENDING_FOR_REVIEW'
      ).length > 0;

    this.displayAddInvoiceButton =
      this.job.jobCanBeInvoiced && this.job.canRepairerUploadEstimatesAndInvoices && !this.invoiceCreated;
    this.checkInvoiceButtonStatus();

    this.submittedItems.push(...this.submittedQuotes, ...this.submittedInvoices, ...this.partialInvoices);
    this.submittedItems.sort((itemA, itemB) => itemA.date - itemB.date);

    if (this.job.authority) {
      let indexLastItemAuthorized = null;
      for (let i = 0; i < this.submittedItems.length; i++) {
        if (this.submittedItems[i].document.authorized) {
          indexLastItemAuthorized = i;
        }
      }
      if (indexLastItemAuthorized !== null) {
        this.submittedItems.splice(indexLastItemAuthorized + 1, 0, this.job.authority);
      }
    }

    const unSubmittedNotification =
      this.unSubmittedCustomerFeedback ||
      this.unSubmittedCustomerContact ||
      this.unSubmittedPotentialBreachOfCode ||
      this.unSubmittedCustomerVulnerability;
    this.countItemsToSend =
      (this.unSubmittedDocs.length ? 1 : 0) +
      (this.unSubmittedDocuments.length ? 1 : 0) +
      (this.unSubmittedPhotos.length ? 1 : 0) +
      (this.unSubmittedDiary ? 1 : 0) +
      (this.showBooking ? 1 : 0) +
      (this.noQuoteResponse ? 1 : 0) +
      (this.showSelfAuthorisationCurtain ? 1 : 0) +
      (unSubmittedNotification ? 1 : 0);

    this.supplementQuote = this.job.documents.some(
      (quote) => (quote.document as Quote).authorized && (quote.document as Quote).original
    );

    if (this.job.externalCostControl) {
      this.insurer = {
        insurerName: this.job.insurerName,
        insurerAddress: this.job.insurerAddress,
        insurerSuburbOrCity: this.job.insurerSuburb || this.job.insurerCity,
        insurerState: this.job.insurerState,
        insurerPostCode: this.job.insurerPostCode,
        insurerCountry: this.job.insurerCountry,
        insurerEmail: this.job.insurerEmail
      };
    }

    this.containNameLine2 = this.job?.cards.filter((card) => card.nameLine2).length > 0;
    this.ownerName = this.job?.cards.find((o) => o.role === 'OWNER').nameLine1;

    if (
      this.isFirstJobDataInitialization &&
      !this.currentUserService.currentUserValue?.privileges?.includes('SYSADMIN')
    ) {
      this.isFirstJobDataInitialization = false;

      if (this.job?.unread) {
        this.toggleJobReadStatus(true, false);
      }
    }

    this.changeDetectorRef.detectChanges();
  }

  receiveClaimCurtainData(data: { isVehicleInfosInvalid: boolean; mandatoryRules: string[] }): void {
    this.mandatoryRules = data.mandatoryRules;
    this.isVehicleInfosInvalid = data.isVehicleInfosInvalid;
    if (!!this.unSubmittedQuote || this.isTotalLossCase) {
      this.validateSendJob({ isTotalLossCase: this.isTotalLossCase });
    }
  }

  checkInvoiceButtonStatus() {
    this.jobStates.isJobWithAtLeastOneAuthorisedQuote = this.job?.documents.some(
      (document) =>
        document.type === 'QUOTE' &&
        (this.job.selfAuthorized
          ? this.job.authority && (document?.document as Quote)?.authorized
          : (document?.document as Quote)?.authorized)
    );
    this.jobStates.hasOrginalQuoteNotSubmittedAndAuthorised = !!this.job?.documents.find(
      (doc) => doc.type === 'QUOTE' && (doc.document as Quote)?.original && !(doc.document as Quote)?.authorized
    );
    this.jobStates.isJobPreAuthWithDateAndNoAuthority =
      !!this.job?.preAuthority?.preAuthorizedDate && !this.job?.authority;
    this.jobStates.isJobWithSelfAuthDateAndNoSelfAuthSent =
      !!this.job?.preAuthority?.preAuthorizedDate && !this.job?.selfAuthorized;
    const latestQuoteDate = Math.max(
      ...this.job?.documents.filter((doc) => doc.type === 'QUOTE').map((doc) => doc.date)
    );
    this.jobStates.isLatestQuoteAuthorised = !!(
      this.job?.documents.find((doc) => doc.type === 'QUOTE' && doc.date === latestQuoteDate)?.document as Quote
    )?.authorized;
    this.jobStates.isLatestQuoteNotInvoiced = !(
      this.job?.documents.find((doc) => doc.type === 'QUOTE' && doc.submitted && doc.date === latestQuoteDate)
        ?.document as Quote
    )?.invoiced;
    this.jobStates.isQuoteInSendToTrayEmpty = this.unSubmittedDocuments.every(
      (doc) => doc.type !== 'QUOTE' && !doc.submitted
    );
    this.jobStates.isInvoiceInSendToTrayEmpty = !this.job.documents.some(
      (doc) => doc.type === 'INVOICE' && !doc.submitted
    );
    this.invoiceButtonMsg = this.getInvoiceMessageValue();
  }

  getInvoiceMessageValue() {
    if (
      !this.jobStates.isLatestQuoteAuthorised &&
      this.jobStates.isQuoteInSendToTrayEmpty &&
      this.job.canRepairerUploadEstimatesAndInvoices
    ) {
      return 'Your latest quote has not been authorised yet';
    }

    if (
      !this.jobStates.isLatestQuoteNotInvoiced &&
      this.jobStates.isQuoteInSendToTrayEmpty &&
      this.jobStates.isInvoiceInSendToTrayEmpty &&
      this.job.canRepairerUploadEstimatesAndInvoices
    ) {
      return 'Your latest quote has already been invoiced';
    }

    if (!this.jobStates.isInvoiceInSendToTrayEmpty && this.jobStates.isQuoteInSendToTrayEmpty) {
      return 'You may only send one invoice at a time';
    }

    if (!this.jobStates.isQuoteInSendToTrayEmpty) {
      return 'You must send your quote for authorisation before adding an invoice';
    }

    if (!this.job.canRepairerUploadEstimatesAndInvoices) {
      return 'This job is currently being handled by the assessor';
    }
    return null;
  }

  saveJob(fieldValue, fieldName: string, shouldScroll?: boolean) {
    this.toolbarService.setCloudState('SAVING');
    this.userService.updateJob(fieldValue, this.jobId, fieldName).subscribe({
      next: () => this.toolbarService.setCloudState('RESTING'),
      error: () => {
        this.message.error('Could not update field.');
        this.toolbarService.setCloudState('RESTING');
        this.saveJobResponseStatus = { status: 'error', fieldName };
        if (
          fieldName === 'regoState' ||
          fieldName === 'odometer' ||
          fieldName === 'vin' ||
          fieldName === 'regoExpiryDate'
        ) {
          this.validateSendJob({ isVehicleInfosInvalid: !!this.unSubmittedQuote });
        }
      },
      complete: () => {
        this.saveJobResponseStatus = { status: 'success', fieldName };
        this.job[fieldName] = fieldValue;
        if (['regoState', 'odometer', 'vin', 'regoExpiryDate', 'complianceDate'].includes(fieldName)) {
          this.validateSendJob();
        } else if (fieldName === 'rego') {
          this.initializeToolbarData();
        }
        if (shouldScroll) {
          this.scrollToSendToInsurerTray();
        }
      }
    });
  }

  editContact() {
    const owner = this.job.cards.filter((card) => card.role === 'OWNER')[0];
    const dialogConfig: MatDialogConfig = wideModalConfig;
    dialogConfig.data = {
      jobId: this.job.jobId,
      ownerName: owner.nameLine1,
      ownerEmail: owner.email,
      ownerMobilePhone: owner.mobilePhone,
      ownerWorkPhone: owner.workPhone,
      ownerHomePhone: owner.homePhone,
      ownerPostCode: owner.postCode,
      cancelled: this.job.cancelled
    };
    const dialogRef = this.editContactDialog.open(EditContactComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'Success') {
        this.message.confirm('Contact Updated');
        this.getJob();
      }
    });
  }

  validateSendJob(event?: any) {
    this.showValidationGuidance = false;
    this.validationDetails = '';
    const validationMessages: string[] = [];

    if (this.unSubmittedInvoice && this.unSubmittedInvoiceCurtainComponent) {
      const invoice = this.unSubmittedInvoice.document as Invoice;

      if (
        this.unSubmittedInvoiceCurtainComponent.first &&
        this.unSubmittedInvoiceCurtainComponent.first.usingFinancingCompany &&
        !invoice.factoryCompanyEnable
      ) {
        this.showValidationGuidance = true;
        validationMessages.push('a Finance Company must be selected');
      } else if (!invoice.paymentByCheque && !invoice.bsb && !invoice.factoryCompanyEnable) {
        this.showValidationGuidance = true;
        validationMessages.push('the banking details on the administration page');
      }
      if (
        (invoice && !invoice.invoiceNumber) ||
        (event?.hasError && (event?.hasError('duplicateInvoice') || event?.hasError('required')))
      ) {
        this.showValidationGuidance = true;
        validationMessages.push('the Invoice Number');
      }

      if (
        invoice &&
        ((this.vehicleDateInMandatory && !invoice.vehicleDateIn) ||
          (this.vehicleDateOutMandatory && !invoice.vehicleDateOut))
      ) {
        this.showValidationGuidance = true;
        if (
          !validationMessages.includes("the Vehicle In<span class='no-font-weight'> and </span>the Vehicle Out dates")
        ) {
          validationMessages.push("the Vehicle In<span class='no-font-weight'> and </span>the Vehicle Out dates");
        }
      }
    }

    if (this.unSubmittedDiary && !this.unSubmittedDiary.subject) {
      this.showValidationGuidance = true;
      validationMessages.push('the diary');
    }

    if (
      this.unSubmittedCustomerFeedback &&
      (!this.unSubmittedCustomerFeedback.date ||
        !this.unSubmittedCustomerFeedback.raisedBy ||
        !this.unSubmittedCustomerFeedback.details)
    ) {
      this.showValidationGuidance = true;
      validationMessages.push('the customer feedback');
    }

    if (
      this.unSubmittedCustomerContact &&
      (!this.unSubmittedCustomerContact.contactFrom ||
        !this.unSubmittedCustomerContact.contactTo ||
        !this.unSubmittedCustomerContact.contactMethodType ||
        !this.unSubmittedCustomerContact.date ||
        !this.unSubmittedCustomerContact.time ||
        (this.unSubmittedCustomerContact.isIssueDiscussed &&
          // tslint:disable-next-line:max-line-length
          (!this.unSubmittedCustomerContact.issueResolutionType ||
            !this.unSubmittedCustomerContact.issueDiscussionDetails)) ||
        !this.unSubmittedCustomerContact.additionalDetails)
    ) {
      this.showValidationGuidance = true;
      validationMessages.push('the customer contact');
    }

    if (
      this.unSubmittedPotentialBreachOfCode &&
      (!this.unSubmittedPotentialBreachOfCode.dateOfIncident ||
        !this.unSubmittedPotentialBreachOfCode.dateDiscovered ||
        !this.unSubmittedPotentialBreachOfCode.raisedBy ||
        !this.unSubmittedPotentialBreachOfCode.partOfCode ||
        !this.unSubmittedPotentialBreachOfCode.codeOfPracticeParagraph ||
        !this.unSubmittedPotentialBreachOfCode.description)
    ) {
      this.showValidationGuidance = true;
      validationMessages.push('the potential breach of code');
    }

    if (
      this.unSubmittedCustomerVulnerability &&
      (!this.unSubmittedCustomerVulnerability.customerVulnerability ||
        (this.unSubmittedCustomerVulnerability.customerVulnerability === 'CUSTOMER_SPEAKS' &&
          !this.unSubmittedCustomerVulnerability.languagesSpoken.length) ||
        (this.unSubmittedCustomerVulnerability.customerVulnerability === 'OTHER' &&
          !this.unSubmittedCustomerVulnerability.details) ||
        !this.unSubmittedCustomerVulnerability.contactPerson ||
        !this.unSubmittedCustomerVulnerability.email ||
        (this.unSubmittedCustomerVulnerability.email &&
          !this.emailPattern.test(this.unSubmittedCustomerVulnerability.email)) ||
        !this.unSubmittedCustomerVulnerability.phoneNumber)
    ) {
      this.showValidationGuidance = true;
      validationMessages.push('the customer vulnerability');
    }

    if (this.noQuoteResponse && !this.job.noQuoteResponseAssessmentAction && !this.noQuoteResponseAssessmentAction) {
      this.showValidationGuidance = true;
      validationMessages.push('the return to assessor');
    }

    if (this.showBooking && !this.job.propositionDate && this.job.bookingOption !== 'CANCELLED_DUE_TO_NO_SHOW') {
      this.showValidationGuidance = true;
      validationMessages.push('the inspection booking');
    }
    if (
      this.isVehicleInfosInvalid &&
      (!!this.unSubmittedQuote || event?.isTotalLossCase || this.mandatoryRules.length > 0)
    ) {
      this.showValidationGuidance = true;
      validationMessages.push('the vehicle information in the Claim Details');
    }

    const isRepairCompletedDateInThePast =
      !!this.job?.repairCompletedDate &&
      moment.tz(this.job?.repairCompletedDate, this.timezone).isBefore(moment().tz(this.timezone));
    const isvehicleDateOutInThePast =
      !!this.job?.vehicleDateOut &&
      moment.tz(this.job?.vehicleDateOut, this.timezone).isBefore(moment().tz(this.timezone));
    if (
      this.job.documents.filter((document) => document.type === 'INVOICE' && !document.submitted)?.length === 1 &&
      !(isRepairCompletedDateInThePast && !!this.job.vehicleDateOut) &&
      !this.job?.repairsCompletedFlag
    ) {
      this.showValidationGuidance = true;
      validationMessages.push('the repair timeline');
    }

    if (
      this.job.selfAuthorized &&
      this.job.documents.filter((document) => document.type === 'QUOTE')?.length === 1 &&
      !(isRepairCompletedDateInThePast || isvehicleDateOutInThePast)
    ) {
      this.showValidationGuidance = true;
      validationMessages.push('the repair timeline');
    }

    if (
      (this.unSubmittedQuote?.document as Quote)?.manual &&
      (this.unSubmittedQuote.document as Quote)?.estTotal === 0
    ) {
      validationMessages.push('the quote');
      this.showValidationGuidance = true;
    }

    this.validationDetails = validationMessages
      .join(', ')
      .replace(/,(?=[^,]*$)/, '<span class="no-font-weight"> and</span>');
    this.disableSendButton = this.showValidationGuidance;
  }

  submitJob() {
    const data = {
      jobId: this.job.jobId,
      job: this.job,
      unSubmittedPhotos: this.unSubmittedPhotos,
      unSubmittedQuote: this.unSubmittedQuote,
      unSubmittedInvoice: this.unSubmittedInvoice,
      unSubmittedDiary: this.unSubmittedDiary,
      assessor: `${this.job?.cards?.find((card) => card.role === 'ASSESSOR')?.nameLine1} ${
        this.job?.cards?.find((card) => card.role === 'ASSESSOR')?.nameLine2 || ''
      }`
    };
    const dialogRef = this.uploadFiles.open(
      SubmitDocumentComponent,
      this.modalConfigsHelper.buildMediumModalConfig(data, mediumModalConfig)
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'Success') {
        this.message.confirm('Submitted successfully');
        this.noQuoteResponseAssessmentAction = null;
        this.getJob();
      }
    });
  }

  addFiles(fileInput: any, isPhotos: boolean) {
    this.indexTab = isPhotos ? 'photo' : 'document';
    const data = { jobId: this.job.jobId, files: fileInput.files, isPhotos };
    const dialogRef = this.uploadFiles.open(
      UploadFilesComponent,
      this.modalConfigsHelper.buildMediumModalConfig(data, mediumModalConfig)
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'Success') {
        this.message.confirm(`${isPhotos ? 'Photos' : 'Documents'} Uploaded`);
        this.getJob(null, true);
      }
      this.inputFileValue = '';
    });
  }

  addQuote(selectedIndex: number) {
    this.indexTab = 'quote';
    const dialogConfig: MatDialogConfig = wideModalConfig;
    dialogConfig.data = {
      jobId: this.job.jobId,
      noQuote: this.submittedQuotes.length === 0,
      notAuthorised:
        this.submittedQuotes.length > 0 &&
        !(this.submittedQuotes[this.submittedQuotes.length - 1]?.document as Quote)?.authorized,
      selectedIndex,
      rego: this.job.rego.trim().replace(/\s/g, ''),
      type: 'quote'
    };
    const dialogRef = this.addQuoteDialog.open(AddQuoteComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'Success') {
        this.getJob(null, true);
      }
    });
  }

  appendInvoice(selectedIndex: number) {
    this.indexTab = 'invoice';
    this.job.externalCostControl ? this.removeJobFromDashboard() : this.addInvoice(selectedIndex);
  }

  addInvoice(selectedIndex: number) {
    const dialogConfig: MatDialogConfig = wideModalConfig;
    dialogConfig.data = {
      jobId: this.job.jobId,
      authorizedQuotes: this.quotesAuthorizedNotInvoiced,
      selectedIndex,
      rego: this.job.rego.trim().replace(/\s/g, ''),
      type: 'invoice',
      excess: !this.job.excessType ? this.job.excess : 0,
      excessType: this.job.excessType,
      ownerContribution: this.job.ownerContribution,
      lastInvoice: this.job.documents.filter(({ type }) => type === 'INVOICE').slice(-1)?.[0]?.document
    };
    const dialogRef = this.addInvoiceDialog.open(AddInvoiceComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'Success') {
        this.getJob(null, true);
      }
    });
  }

  removeJobFromDashboard() {
    const dialogConfig: MatDialogConfig = wideModalConfig;
    dialogConfig.data = { jobId: this.job.jobId, insurerName: this.job.insurerName };
    const dialogRef = this.addInvoiceDialog.open(RemoveJobComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'Success') {
        this.getJob();
      }
    });
  }

  private toggleJobReadStatus(isUnread: boolean, reloadJob = true): void {
    this.userService.toggleJobReadStatus(isUnread, this.job?.jobId).subscribe({
      complete: () => (reloadJob ? this.getJob() : (this.job.unread = !isUnread))
    });
  }

  addDiaryCard() {
    this.indexTab = 'diary';
    this.toolbarService.setCloudState('SAVING');
    const diary: Diary = {
      jobId: this.job.jobId,
      subject: '',
      body: ''
    };
    this.userService.addDiary(diary).subscribe(
      () => this.toolbarService.setCloudState('RESTING'),
      () => {
        this.message.error('Could not add diary.');
        this.toolbarService.setCloudState('RESTING');
      },
      () => {
        this.getJob(null, true);
        this.validateSendJob();
      }
    );
  }

  addNotification(notificationType: string) {
    this.indexTab = notificationType;
    let notificationObject = {};
    let errorMessage = '';
    switch (notificationType) {
      case 'customerFeedback':
        notificationObject = {
          jobId: this.job.jobId,
          feedbackType: 'POSITIVE'
        } as CustomerFeedback;
        errorMessage = 'customer feedback';
        break;
      case 'customerContacts':
        notificationObject = {
          jobId: this.job.jobId,
          isContactSuccessful: false
        } as CustomerContact;
        errorMessage = 'customer contact';
        break;
      case 'codeOfPracticeBreaches':
        notificationObject = {
          jobId: this.job.jobId
        } as PotentialBreachOfCode;
        errorMessage = 'potential breach of code';
        break;
      case 'customerVulnerabilities':
        notificationObject = {
          jobId: this.job.jobId
        } as CustomerVulnerability;
        errorMessage = 'customer vulnerability';
        break;
      default:
        return;
    }

    this.userService.addNotification(notificationType, notificationObject).subscribe(
      () => this.toolbarService.setCloudState('RESTING'),
      () => {
        this.message.error(`Could not add ${errorMessage}.`);
        this.toolbarService.setCloudState('RESTING');
      },
      () => {
        this.getJob(null, true);
        this.validateSendJob();
      }
    );
  }

  addNoQuoteResponse(assessmentAction?: string) {
    this.indexTab = 'returnToAssessor';
    this.noQuoteResponse = true;
    this.saveJob(this.noQuoteResponse, 'noQuoteResponse', true);
    this.job.noQuoteResponseAssessmentAction = assessmentAction;
    this.noQuoteResponseAssessmentAction = assessmentAction;
    this.validateSendJob({ isTotalLossCase: assessmentAction === 'Total Loss' });
    this.countItemsToSend += 1;
  }

  addBooking() {
    this.indexTab = 'inspectionBooking';
    this.showBooking = true;
    this.saveJob(this.showBooking, 'booking', true);
    this.validateSendJob();
    this.job.bookingOption = this.job.onSiteInspection ? 'PROPOSE_NEW_DATE' : 'PROPOSE_ON_SITE_INSPECTION';
    this.countItemsToSend += 1;
  }

  addSelfAuthoriseAction() {
    this.indexTab = 'selfAuthorise';
    this.showSelfAuthorisationCurtain = true;
    this.saveJob(this.showSelfAuthorisationCurtain, 'selfAuthorize', true);
    this.job.enableSelfAuthorityCurtain = true;
    this.countItemsToSend += 1;
  }

  viewJobHistory() {
    const dialogConfig: MatDialogConfig = wideModalConfig;
    dialogConfig.data = { jobId: this.job.jobId };
    this.jobHistoryDialog.open(JobHistoryComponent, dialogConfig);
  }

  synchronizeInvoiceOwnerContribution(value) {
    // The code here is needed to force the change detection on arrays and objects
    if (this.unSubmittedInvoice) {
      (this.unSubmittedInvoice.document as Invoice).ownerContribution = value;
      this.unSubmittedInvoice = Object.assign({}, this.unSubmittedInvoice);
    }
  }

  synchronizeAuthorityOwnerContribution(value) {
    // The code here is needed to force the change detection on arrays and objects
    if (this.job.authority) {
      this.job.authority.ownerContribution = value;
      let authority = this.submittedItems.splice(
        this.submittedItems.findIndex((item) => item.documentType === 'AUTHORITY'),
        1
      )[0];
      authority.ownerContribution = value;
      authority = Object.assign({}, authority);
      this.submittedItems.push(authority);
    }
  }

  synchronizePreAuthority(value) {
    if (this.job.preAuthority) {
      this.job.preAuthority.ownerContribution = value;
      this.job.preAuthority = Object.assign({}, this.job.preAuthority);
    }
  }

  reviewTimeline(event?: boolean) {
    const data = {
      job: this.job,
      userTimezone: this.timezone,
      repairTimelineDates: {
        authorityDate: this.job?.authorityDate,
        repairBookedDate: this.job?.repairBookedDate,
        vehicleDateIn: this.job?.vehicleDateIn,
        repairStartedDate: this.job?.repairStartedDate,
        repairCompletedDate: this.job?.repairCompletedDate,
        vehicleDateOut: this.job?.vehicleDateOut
      },
      isEditRepairTimelineAllowedAfterSubmission: event
    };
    const dialogRef = this.repairTimelineDialog.open(
      UpdateRepairTimelineComponent,
      this.modalConfigsHelper.buildExtraWideModalConfig(data, 98)
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'Success') {
        this.getJob();
      }
    });
  }

  refresh(resetExpandCurtainInTrayOrCustomEvent?: any) {
    this.collapseOpenedCurtain = false;
    this.getJob();
    if (
      !!resetExpandCurtainInTrayOrCustomEvent &&
      resetExpandCurtainInTrayOrCustomEvent !== 'DeleteReturnToAssessor' &&
      resetExpandCurtainInTrayOrCustomEvent !== 'QuoteReplacedOrMerged'
    ) {
      setTimeout(() => {
        this.expandCurtainInTray = false;
      }, 750);
    }
    if (['DeleteReturnToAssessor', 'isNotTotalLossCase'].includes(resetExpandCurtainInTrayOrCustomEvent)) {
      this.noQuoteResponseAssessmentAction = null;
    }
  }

  openPdfReport(reportType: string) {
    this.userService.getReportRepairer(`/jobs/${this.jobId}/reports/${reportType}`).subscribe(
      () => {},
      () => this.message.error('Could not retrieve the file.')
    );
  }

  updateSelectedPhoto(photo: PhotoReference) {
    const oldTopImageIndex = this.job.photoReferences?.findIndex((localPhoto) => localPhoto.topImage);
    const newTopImageIndex = this.job.photoReferences?.findIndex((localPhoto) => localPhoto.id === photo.id);
    this.job.photoReferences[oldTopImageIndex].topImage = false;
    this.job.photoReferences[newTopImageIndex].topImage = true;
    this.job = { ...this.job };
  }

  scrollToSendToInsurerTray() {
    this.collapseOpenedCurtain = true;
    window.scrollTo(0, document.body.scrollHeight);
    this.expandCurtainInTray = true;
  }

  navigateToBucket(jobTab: string) {
    this.jobService.activeJobId = this.job.jobId;
    this.router.navigateByUrl('/dashboard?tab=' + TAB_INDEXES[jobTab]).catch();
  }

  performingTask(task: any) {
    switch (task?.action) {
      case 'addSelfAuthoriseAction':
        this.addSelfAuthoriseAction();
        break;
      case 'addNoQuoteResponse':
        this.addNoQuoteResponse(task?.param);
        break;
      case 'addDiaryCard':
        this.addDiaryCard();
        break;
      case 'addBooking':
        this.addBooking();
        break;
      case 'addNotification':
        this.addNotification(task?.param);
        break;
      case 'addFiles':
        this.addFiles(task?.param?.imageInput || task?.param?.documentInput, task?.param?.isPhotos);
        break;
      case 'addQuote':
        this.addQuote(task?.param);
        break;
      case 'appendInvoice':
        this.appendInvoice(task?.param);
        break;
      case 'markAsUnread':
        this.toggleJobReadStatus(false);
        break;
    }
  }
}
