import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlight'
})
export class Highlight implements PipeTransform {
  transform(value: any, args: any, classToApply: string = 'highlight-color'): any {
    if (!args) {
      return value;
    }
    const re = new RegExp(args, 'gi');
    if (value) {
      return value.replace(re, `<span class="${classToApply}">$&</span>`);
    }
  }
}
