import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DateInputHelper } from '../../../../helpers/date-input.helper';
import { UserService } from '../../../../services/user.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ToolbarService } from '../../../../services/toolbar.service';
import { MessagingService } from '../../../../components/messaging/messaging.service';
import { ConfirmBoxComponent } from '../../../../components/confirm-box/confirm-box.component';
import {
  CustomerContact,
  CustomerContactMethodEnum,
  GicopRecipient,
  IssueResolutionEnum
} from '../../../../model/gicop-notifications';
import * as moment from 'moment';
import { EIO_DATE_BE_FORMAT, EIO_TIME_FORMAT } from '../../../../consts/localization.const';
import { Moment } from 'moment';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ModalConfigsHelper } from '../../../../helpers/modal-configs.helper';
import { smallModalConfig } from '../../../../consts/modal-config.const';

@Component({
  selector: 'app-add-customer-contact',
  templateUrl: './add-customer-contact.component.html',
  styleUrls: ['./add-customer-contact.component.scss']
})
export class AddCustomerContactComponent implements OnInit, AfterViewInit {
  @Input() customerContact: CustomerContact;
  @Input() repairerSiteName: string;
  @Input() ownerName: string;
  @Input() timezone: string;
  @Output() refreshJobViewer: EventEmitter<any> = new EventEmitter();
  @Output() disableSendButton: EventEmitter<any> = new EventEmitter();
  customerContactedForm: UntypedFormGroup;
  contactsFrom: GicopRecipient[] = [];
  contactsTo: GicopRecipient[] = [];
  readonly issueResolutionEnum = IssueResolutionEnum;
  readonly customerContactMethodEnum = CustomerContactMethodEnum;
  timePattern24h = /^([01][0-9]|2[0-3]):([0-5][0-9])$/;
  currentDate: Moment;
  constructor(
    private fb: UntypedFormBuilder,
    public dateInputHelper: DateInputHelper,
    private userService: UserService,
    private confirmBox: MatDialog,
    public toolbarService: ToolbarService,
    private message: MessagingService,
    private elRef: ElementRef,
    private modalConfigsHelper: ModalConfigsHelper
  ) {}

  get contactTo() {
    return this.customerContactedForm.get('contactTo');
  }
  get contactFrom() {
    return this.customerContactedForm.get('contactFrom');
  }
  get contactMethodType() {
    return this.customerContactedForm.get('contactMethodType');
  }
  get date() {
    return this.customerContactedForm.get('date');
  }
  get time() {
    return this.customerContactedForm.get('time');
  }
  get isContactSuccessful() {
    return this.customerContactedForm.get('isContactSuccessful');
  }
  get isIssueDiscussed() {
    return this.customerContactedForm.get('isIssueDiscussed');
  }
  get issueResolutionType() {
    return this.customerContactedForm.get('issueResolutionType');
  }
  get issueDiscussionDetails() {
    return this.customerContactedForm.get('issueDiscussionDetails');
  }
  get additionalDetails() {
    return this.customerContactedForm.get('additionalDetails');
  }

  ngOnInit(): void {
    this.currentDate = moment().tz(this.timezone);
    this.customerContactedForm = this.fb.group({
      id: this.customerContact?.id,
      contactFrom: [this.customerContact?.contactFrom?.type || '', Validators.required],
      contactTo: [this.customerContact?.contactTo?.type || ''],
      contactMethodType: [this.customerContact.contactMethodType || ''],
      date: [
        this.customerContact?.date ? this.formatDate(this.customerContact?.date) : this.currentDate,
        Validators.required
      ],
      time: [
        this.customerContact?.time
          ? this.formatTime(this.customerContact?.time)
          : this.currentDate.format(EIO_TIME_FORMAT),
        Validators.required
      ],
      isContactSuccessful: [this.customerContact?.isContactSuccessful || false],
      isIssueDiscussed: [this.customerContact?.isIssueDiscussed || false],
      issueResolutionType: [this.customerContact?.issueResolutionType || ''],
      issueDiscussionDetails: [this.customerContact.issueDiscussionDetails || ''],
      additionalDetails: [this.customerContact.additionalDetails || '']
    });

    this.contactsFrom.push({ type: 'REPAIRER', displayName: this.repairerSiteName });
    if (this.ownerName) {
      this.contactsFrom.push({ type: 'OWNER', displayName: this.ownerName });
    }
    this.contactsTo = this.contactsFrom;

    if (!this.customerContact?.contactFrom?.type) {
      this.contactTo.disable();
    } else {
      this.contactsTo = this.contactsFrom.filter((item) => item.type !== this.customerContact?.contactFrom?.type);
    }

    this.updateCustomerContactForm();
  }

  formatDate = (timestamp) => moment(timestamp).tz(this.timezone).format(EIO_DATE_BE_FORMAT);
  formatTime = (timestamp) => moment(timestamp, EIO_TIME_FORMAT).tz(this.timezone).format(EIO_TIME_FORMAT);

  confirmDeleteCustomerContact() {
    const data = {
      title: 'Delete Customer Contact',
      alertMessage: 'This customer contact will be deleted.',
      confirmButton: 'DELETE'
    };
    const dialogRef = this.confirmBox.open(
      ConfirmBoxComponent,
      this.modalConfigsHelper.buildMediumModalConfig(data, smallModalConfig)
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'DELETE') {
        this.deleteCustomerContact();
      }
    });
  }

  deleteCustomerContact() {
    this.toolbarService.setCloudState('SAVING');
    this.userService
      .deleteNotification(this.customerContact.jobId, 'customerContacts', this.customerContact.id)
      .subscribe(
        () => this.toolbarService.setCloudState('RESTING'),
        () => {
          this.message.error('Customer contact could not be deleted.');
          this.toolbarService.setCloudState('RESTING');
        },
        () => {
          this.message.confirm('Customer contact Deleted.');
          this.refreshJobViewer.emit();
        }
      );
  }

  updateCustomerContactForm() {
    this.contactFrom.valueChanges.subscribe(() => {
      this.contactsTo = this.contactsFrom;
      this.contactTo.enable();
      this.contactsTo = this.contactsTo.filter((item) => item.type !== this.contactFrom.value);
      this.disableSendButton.emit();
      this.updateCustomerContact();
    });

    this.contactTo.valueChanges.subscribe(() => {
      this.disableSendButton.emit();
      this.updateCustomerContact();
    });

    this.contactMethodType.valueChanges.subscribe(() => {
      this.disableSendButton.emit();
      this.updateCustomerContact();
    });

    this.isContactSuccessful.valueChanges.subscribe((value) => {
      if (!value) {
        this.isIssueDiscussed.setValue(false, { emitEvent: false });
        this.issueResolutionType.setValue('', { emitEvent: false });
        this.issueDiscussionDetails.setValue('', { emitEvent: false });
      }
      this.disableSendButton.emit();
      this.updateCustomerContact();
    });

    this.isIssueDiscussed.valueChanges.subscribe((value) => {
      if (!value) {
        this.issueResolutionType.setValue('', { emitEvent: false });
        this.issueDiscussionDetails.setValue('', { emitEvent: false });
      }
      this.disableSendButton.emit();
      this.updateCustomerContact();
    });

    this.issueResolutionType.valueChanges.subscribe(() => {
      this.disableSendButton.emit();
      this.updateCustomerContact();
    });

    this.issueDiscussionDetails.valueChanges.pipe(debounceTime(750), distinctUntilChanged()).subscribe(() => {
      this.disableSendButton.emit();
      this.updateCustomerContact();
    });

    this.additionalDetails.valueChanges.pipe(debounceTime(750), distinctUntilChanged()).subscribe({
      next: () => this.updateCustomerContact()
    });

    this.date.valueChanges.pipe(debounceTime(750), distinctUntilChanged()).subscribe({
      next: () => {
        if (this.date.invalid) {
          this.date.markAsTouched();
          this.customerContact.date = null;
        }
        this.updateCustomerContact();
      }
    });

    this.time.valueChanges.pipe(debounceTime(750), distinctUntilChanged()).subscribe({
      next: () => {
        if (this.time.invalid) {
          this.time.markAsTouched();
        }
        this.updateCustomerContact();
      }
    });
  }

  validateTime() {
    const timeEntered = moment.tz(`${this.formatDate(this.date.value)} ${this.time.value}`, this.timezone);
    !this.date.hasError('required') && this.date.setErrors(null);
    !this.time.hasError('required') && this.time.setErrors(null);

    const isInvalidTime = !this.timePattern24h.test(this.time.value);
    const isTimeInFuture = timeEntered.isAfter(moment());

    if (isInvalidTime || isTimeInFuture) {
      this.customerContact.time = null;
      this.customerContact.date = null;
    }

    if (isInvalidTime) {
      this.time.setErrors({ invalid: true });
    } else if (isTimeInFuture) {
      this.time.setErrors({ inFuture: true });
      this.date.setErrors({ inFuture: true });
    }
  }

  updateCustomerContact() {
    this.toolbarService.setCloudState('SAVING');
    const customerContact: CustomerContact = this.customerContactedForm.getRawValue();
    customerContact.contactFrom = {
      type: this.contactFrom.value,
      displayName: this.contactFrom.value === 'OWNER' ? this.ownerName : this.repairerSiteName
    };
    customerContact.contactTo = {
      type: this.contactTo.value,
      displayName: this.contactTo.value === 'OWNER' ? this.ownerName : this.repairerSiteName
    };
    this.userService.updateNotification(this.customerContact.jobId, 'customerContacts', customerContact).subscribe({
      next: () => this.toolbarService.setCloudState('RESTING'),
      error: () => {
        this.message.error('Contact has not been saved');
        this.toolbarService.setCloudState('RESTING');
      },
      complete: () => {
        if (this.date.valid && this.time.valid) {
          this.refreshJobViewer.emit();
        }
        this.disableSendButton.emit(this.customerContactedForm.invalid);
      }
    });
  }

  ngAfterViewInit() {
    this.elRef.nativeElement.scrollIntoView({
      behavior: 'smooth'
    });
  }
}
