<div *ngIf="photos" [ngClass]="{ 'gallery-bottom': photos && quote }">
  <mat-expansion-panel
    (closed)="galleryCurtainOpened = false"
    (opened)="galleryCurtainOpened = true"
    class="curtains"
    #panel>
    <mat-expansion-panel-header [collapsedHeight]="'100px'" [expandedHeight]="'100px'">
      <mat-panel-title [ngClass]="{ 'opened-gallery': galleryCurtainOpened }" class="gallery-title">
        <h6>{{ photos.length }} {{ photos.length === 1 ? 'Photo' : 'Photos' }}</h6>
      </mat-panel-title>
      <mat-panel-description [ngClass]="{ 'closed-gallery': !galleryCurtainOpened }">
        <div class="gallery-container">
          <ng-container *ngIf="!galleryCurtainOpened">
            <!--this ngForOF syntax used by the ng-template directive-->
            <div class="gallery-row">
              <ng-template [ngForOf]="photos" let-photo let-i="index" ngFor>
                <div class="image-box">
                  <img
                    [hidden]="!photoLoaded[i]"
                    (click)="$event.stopPropagation(); openLightbox(i)"
                    appRetrySrc="/jobAttachments/{{ job.repairerSiteId }}/{{ job.jobId }}/thumb_{{ photo.id }}.{{
                      photo.extension
                    }}"
                    (error)="photoLoaded[i] = false"
                    (load)="photoLoaded[i] = true" />
                  <mat-icon
                    class="delete"
                    *ngIf="photo.submitted && currentUser.privileges.includes('SYSADMIN') && !photo.addedInV6"
                    (click)="confirmDeletePhoto(photo.id, job.jobId); $event.stopPropagation()"
                    >delete_forever</mat-icon
                  >
                  <mat-icon
                    class="star"
                    [class.unselectedPhoto]="!photo.topImage"
                    (click)="!photo.topImage && selectPhoto(photo); $event.stopPropagation()"
                    >{{ photo.topImage ? 'star' : 'star_border' }}</mat-icon
                  >
                  <div class="spinner-box" [class.spinner]="!photoLoaded[i]">
                    <mat-spinner *ngIf="!photoLoaded[i]" color="primary" [diameter]="44"></mat-spinner>
                  </div>
                </div>
              </ng-template>
            </div>
          </ng-container>
        </div>
      </mat-panel-description>
      <mat-panel-description class="kebab-panel">
        <div *ngIf="photos.length && !photos[0].submitted" class="secondary-icon">
          <button mat-icon-button (click)="$event.stopPropagation(); confirmDeleteUnsubmittedphotos()">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <mat-divider></mat-divider>
    <div class="gallery-container">
      <ng-container *ngIf="galleryCurtainOpened">
        <ng-template [ngForOf]="photos" let-photo let-i="index" ngFor>
          <div class="image-box" [class.spinner]="!photoLoaded[i]">
            <img
              [hidden]="!photoLoaded[i]"
              (click)="$event.stopPropagation(); openLightbox(i)"
              appRetrySrc="/jobAttachments/{{ job.repairerSiteId }}/{{ job.jobId }}/thumb_{{ photo.id }}.{{
                photo.extension
              }}"
              (error)="photoLoaded[i] = false"
              (load)="photoLoaded[i] = true" />
            <mat-spinner *ngIf="!photoLoaded[i]" color="primary" [diameter]="44"></mat-spinner>
            <mat-icon
              class="delete"
              *ngIf="
                !photo.submitted || (photo.submitted && currentUser.privileges.includes('SYSADMIN') && !photo.addedInV6)
              "
              (click)="confirmDeletePhoto(photo.id, job.jobId)"
              >delete_forever</mat-icon
            >
            <mat-icon
              class="star"
              [class.unselectedPhoto]="!photo.topImage"
              (click)="!photo.topImage && selectPhoto(photo)"
              >{{ photo.topImage ? 'star' : 'star_border' }}</mat-icon
            >
          </div>
        </ng-template>
      </ng-container>
    </div>
  </mat-expansion-panel>
</div>
