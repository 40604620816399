import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule } from '@angular/material/legacy-card';
import { wideModalConfig } from '../../../consts/modal-config.const';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig
} from '@angular/material/legacy-dialog';
import { AddUserComponent } from '../../location-admin/add-user/add-user.component';
import { CurrentUserService } from '../../../services/currentUser.service';

@Component({
  selector: 'app-mladmin-info',
  standalone: true,
  imports: [CommonModule, MatDividerModule, MatIconModule, MatLegacyButtonModule, MatLegacyCardModule],
  templateUrl: './mladmin-info.component.html'
})
export class MlAdminInfoComponent {
  infoMap: { icon: string; label: string; value: string }[] = [
    {
      icon: 'domain',
      label: 'Username',
      value: 'username'
    },
    {
      icon: 'badge',
      label: 'First name',
      value: 'firstName'
    },
    {
      icon: 'badge',
      label: 'Last name',
      value: 'lastName'
    },
    {
      icon: 'call',
      label: 'Phone Number',
      value: 'phone'
    },
    {
      icon: 'email',
      label: 'Email',
      value: 'emailAddress'
    }
  ];

  dialog = inject(MatDialog);
  currentUserService = inject(CurrentUserService);

  openEditUserModal() {
    const dialogConfig: MatDialogConfig = {
      ...wideModalConfig,
      data: { user: this.currentUserService.currentUserValue }
    };
    this.dialog.open(AddUserComponent, dialogConfig);
  }
}
