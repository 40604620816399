import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfirmBoxComponent } from '../../../components/confirm-box/confirm-box.component';
import { DynamicDialogService } from '../../../components/dynamic-dialog/dynamic-dialog-service';
import { MessagingService } from '../../../components/messaging/messaging.service';
import { mediumModalConfig, wideModalConfig } from '../../../consts/modal-config.const';
import { ResponseEnumEnum } from '../../../enums/responseEnum.enum';
import { ModalConfigsHelper } from '../../../helpers/modal-configs.helper';
import { FinanceCompany } from '../../../model/financing-company.model';
import { SysAdminService } from '../../../services/sys-admin.service';
import { ToolbarService } from '../../../services/toolbar.service';
import { AddFinancingCompanyComponent } from '../add-financing-company/add-financing-company.component';

@Component({
  selector: 'app-financing-companies-section',
  templateUrl: './financing-companies-section.component.html',
  styleUrls: ['./financing-companies-section.component.scss']
})
export class FinancingCompaniesSectionComponent implements OnChanges, OnDestroy {
  @Input() financingCompaniesList: FinanceCompany[] = [];
  @Output() updateFinancingCompanies: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('financingCompaniesSort', { static: true }) financingCompaniesSort: MatSort;
  financingCompaniesDataSource: MatTableDataSource<FinanceCompany>;
  activeFinancingCompaniesColumn = 'companyName';
  financingCompaniesColumnHeaders = ['companyName', 'abn', 'email', 'phone', 'addressLine1', 'deleteCompany'];
  financingCompaniesExpanded = false;
  private unsubscribe = new Subject();

  #dynamicDialogService = inject(DynamicDialogService);
  constructor(
    private addFinancing: MatDialog,
    private message: MessagingService,
    public toolbarService: ToolbarService,
    private sysAdminService: SysAdminService,
    private confirmBox: MatDialog,
    private modalConfigsHelper: ModalConfigsHelper
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    this.financingCompaniesDataSource = new MatTableDataSource(changes.financingCompaniesList.currentValue);
    this.financingCompaniesDataSource.sortingDataAccessor = this.sortingDataAccessor;
    this.financingCompaniesDataSource.sort = this.financingCompaniesSort;
  }

  addEditFinancingCompany(financeCompany?: FinanceCompany) {
    const dialogConfig = {
      ...wideModalConfig,
      data: {
        title: 'Finance Company Information',
        action: 'SAVE',
        displayClose: true,
        component: AddFinancingCompanyComponent,
        financingCompany: financeCompany ?? null
      }
    };
    const dialogRef = this.#dynamicDialogService.openDialog(dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result === ResponseEnumEnum.SUCCESS) {
        this.message.confirm(`Finance Company ${financeCompany ? 'Edited' : 'Added'}`);
        this.updateFinancingCompanies.emit();
      }
    });
  }

  confirmDeleteFinancingCompany(id: number) {
    const data = {
      title: 'Delete Finance Company',
      alertMessage: 'Finance Company will be deleted.',
      confirmButton: 'DELETE'
    };
    const dialogRef = this.confirmBox.open(
      ConfirmBoxComponent,
      this.modalConfigsHelper.buildMediumModalConfig(data, mediumModalConfig)
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'DELETE') {
        this.deleteFinancingCompany(id);
      }
    });
  }

  deleteFinancingCompany(id: number) {
    this.toolbarService.setCloudState('SAVING');
    this.sysAdminService
      .deleteFinancingCompany(id)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: () => this.toolbarService.setCloudState('RESTING'),
        error: () => {
          this.message.error('Finance Company could not be deleted.');
          this.toolbarService.setCloudState('RESTING');
        },
        complete: () => {
          this.message.confirm('Finance Company Deleted');
          this.updateFinancingCompanies.emit();
        }
      });
  }

  sortingDataAccessor(item, property) {
    if (property.includes('.')) {
      if (property === 'mainContact.firstName') {
        return (item.mainContact.firstName + item.mainContact.lastName).toLowerCase();
      }
      return property
        .split('.')
        .reduce((object, key) => object[key], item)
        .toLowerCase();
    }
    return item[property] ? item[property].toString().toLowerCase() : '';
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
