import { CommonModule } from '@angular/common';
import { Component, computed, effect, inject, OnInit, Signal, ViewChild } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyTableDataSource as MatTableDataSource, MatLegacyTableModule } from '@angular/material/legacy-table';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { AbstractDynamicDialogComponent } from '../../../../components/dynamic-dialog/abstract-dynamic-dialog.component';
import { InfosGuidanceComponent } from '../../../../components/infos-guidance/infos-guidance.component';
import { FinanceCompany } from '../../../../model/financing-company.model';
import { InvoiceStore } from '../invoice.store.component';

@Component({
  selector: 'use-finance-company-modal',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatLegacyTableModule,
    MatSortModule,
    InfosGuidanceComponent
  ],
  templateUrl: './use-finance-company-modal.component.html',
  styles: [
    `
      .mat-column-companyName {
        width: 40%;
      }
    `
  ]
})
export class UseFinanceCompanyModalComponent
  extends AbstractDynamicDialogComponent<{ selectFinanceCompanyId: number }>
  implements OnInit
{
  @ViewChild(MatSort) set sort(matSort: MatSort) {
    this.dataSource.sort = matSort;
  }
  dataSource: MatTableDataSource<FinanceCompany> = new MatTableDataSource();
  displayedColumns: string[] = ['selected', 'companyName', 'fullAddress'];

  #dialogRef = inject(MatDialogRef<UseFinanceCompanyModalComponent>);
  form = new FormGroup({
    financeCompany: new FormControl(null, Validators.required),
    search: new FormControl('')
  });

  financeCompaniesList: (FinanceCompany & { fullAddress: string })[] = [];
  #invoiceStore = inject(InvoiceStore);

  get financeCompany() {
    return this.form.controls.financeCompany;
  }
  get search() {
    return this.form.controls.search;
  }

  searchSignal: Signal<string> = toSignal(this.search.valueChanges);
  filteredCompanies = computed(() =>
    this.financeCompaniesList.filter((company) => {
      const trimSearch = this.searchSignal()?.trim().toLowerCase() ?? '';
      return company.companyName.toLowerCase().includes(trimSearch);
    })
  );

  constructor() {
    super();

    effect(() => {
      this.financeCompaniesList = this.#invoiceStore
        .financeCompanies()
        .map((company) => ({ ...company, fullAddress: this.#getCompanyAddressAsString(company) }));
    });
    effect(() => {
      this.dataSource.data = this.filteredCompanies();
    });
  }

  ngOnInit() {
    this.dynamicDialogService.dialogRefSignal.set(this.#dialogRef);
    this.dataSource.sort = this.sort;

    const selectId = this.data.selectFinanceCompanyId;
    const selectedFinanceCompany = selectId
      ? this.financeCompaniesList.find((company) => company.id === selectId)
      : null;
    this.financeCompany.setValue(selectedFinanceCompany);
  }

  saveOrExecuteAction() {
    if (this.form.valid) {
      this.#dialogRef.close({ selectedFinanceCompany: this.financeCompany.value });
    }
  }

  selectFinanceCompany(financeCompany: FinanceCompany) {
    this.financeCompany.setValue(this.financeCompany.value !== financeCompany ? financeCompany : null);
  }

  #getCompanyAddressAsString(company: FinanceCompany): string {
    const addressComponents = [
      company.addressLine1,
      company.addressLine2,
      company.suburb,
      company.state,
      company.city,
      company.postCode,
      company.country
    ];
    const filteredComponents = addressComponents.filter((component) => component && component.trim().length > 0);
    return filteredComponents.join(' ');
  }
}
