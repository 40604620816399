import { Injectable } from '@angular/core';
import GeocoderAddressComponent = google.maps.GeocoderAddressComponent;
import { Address } from '../model/address.model';

@Injectable({
  providedIn: 'root'
})
export class AddressHelper {
  getAddressFromGooglePlaceResult(googleAddressComponents: GeocoderAddressComponent[], address: Address): Address {
    address.addressLine1 = '';
    googleAddressComponents.forEach((item) => {
      switch (item.types[0]) {
        case 'subpremise':
          address.addressLine1 = item.long_name + '/';
          break;
        case 'street_number':
          address.addressLine1 += item.long_name;
          break;
        case 'route':
          address.addressLine1 = address.addressLine1
            ? [address.addressLine1, item.long_name].join(' ')
            : item.long_name;
          break;
        case 'postal_code':
          address.postCode = item.short_name;
          break;
        case 'administrative_area_level_1':
        case 'locality':
          if (address.country === 'Australia' && item.types[0] === 'locality') {
            address.suburb = item.long_name;
          }
          if (address.country === 'Australia') {
            address.state = item.short_name;
          } else if (address.country === 'New Zealand') {
            address.city = item.long_name;
          }
          break;
        case 'sublocality_level_1':
          address.suburb = item.long_name;
          break;
      }
    });

    return address;
  }
}
