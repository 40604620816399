import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbstractDynamicDialogComponent } from '../../../../components/dynamic-dialog/abstract-dynamic-dialog.component';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { LocationAdminStore } from '../../location.admin.store.component';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { EMAIL_PATTERN_STRING } from '../../../../consts/patterns.const';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { getErrorMessage } from '../../../../consts/form-field-errors';

@Component({
  selector: 'apply-to-estimage-pay-modal',
  standalone: true,
  imports: [CommonModule, FormsModule, MatLegacyFormFieldModule, MatLegacyInputModule, ReactiveFormsModule],
  template: `
    <form [formGroup]="form" (ngSubmit)="saveOrExecuteAction()" class="mt-15 flex flex-col gap-20">
      <span>
        Please provide your e-mail address. A verification code will be sent to that e-mail to allow you to access your
        onboarding form.
      </span>

      <mat-form-field appearance="outline" color="accent">
        <mat-label>Email</mat-label>
        <input formControlName="email" matInput />
        <mat-error *ngIf="email.hasError('required') || email.hasError('pattern')">
          <span *ngIf="email.hasError('required')">Email is </span>
          <strong>{{ getErrorMessage(email.errors) }}</strong>
        </mat-error>
      </mat-form-field>
    </form>
  `
})
export class ApplyToEstImagePayModalComponent extends AbstractDynamicDialogComponent<void> implements OnInit {
  #dialogRef = inject(MatDialogRef<ApplyToEstImagePayModalComponent>);
  #fb = inject(FormBuilder);
  #locationAdminStore = inject(LocationAdminStore);

  protected readonly getErrorMessage = getErrorMessage;

  get email() {
    return this.form.controls.email;
  }

  constructor() {
    super();
    this.form = this.#fb.group({
      email: ['', [Validators.required, Validators.pattern(EMAIL_PATTERN_STRING)]]
    });
  }

  ngOnInit() {
    this.dynamicDialogService.dialogRefSignal.set(this.#dialogRef);
  }

  saveOrExecuteAction() {
    if (this.form.valid) {
      this.#locationAdminStore.applyToEstImage(this.email.value);
    }
  }
}
