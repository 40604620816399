import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { DocumentItem, Invoice } from '../../../model/document.model';
import { CurrentUserService } from '../../../services/currentUser.service';
import { CheckExistenceOfFileHelper } from '../../../helpers/check-existence-of-file.helper';
import { MessagingService } from '../../../components/messaging/messaging.service';

@Component({
  selector: 'app-partial-invoice',
  templateUrl: './partial-invoice.component.html',
  styleUrls: ['./partial-invoice.component.scss']
})
export class PartialInvoiceComponent implements OnInit, OnChanges {
  @Input() document: DocumentItem;
  @Input() repairerSiteId: number;
  @Input() shouldCollapseCurtain: boolean;
  @ViewChild('panel', { static: false }) panel: ElementRef;

  constructor(
    private currentUserService: CurrentUserService,
    private checkExistenceOfFileHelper: CheckExistenceOfFileHelper,
    private message: MessagingService
  ) {}

  invoice: Invoice;
  timezone: string;

  ngOnInit(): void {
    this.timezone = this.currentUserService?.timeZone;
    this.invoice = this.document.document as Invoice;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.shouldCollapseCurtain && !changes.shouldCollapseCurtain.firstChange) {
      if (changes.shouldCollapseCurtain.currentValue) {
        this.panel.nativeElement.close();
      }
    }
  }

  showErrorAttachment() {
    const url = `/jobAttachments/${this.repairerSiteId}/${this.invoice.jobId}/${this.invoice.id}_invoice.pdf`;
    if (this.checkExistenceOfFileHelper.urlExists(url)) {
      window.open(url, '_blank');
    } else {
      this.message.error('There was an error loading the invoice PDF');
    }
  }
}
