import { UntypedFormControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function urlValidator(isProd: boolean): ValidatorFn {
  return (fieldUrl: UntypedFormControl): ValidationErrors | null => {
    if (fieldUrl.value) {
      try {
        const url = new URL(fieldUrl.value);
        if (!isProd && url.protocol.toString() !== 'http:' && url.protocol.toString() !== 'https:') {
          return { fieldUrl };
        } else if (isProd && url.protocol.toString() !== 'https:') {
          return { fieldUrl };
        }
      } catch (_) {
        return { fieldUrl };
      }
    }
    return null;
  };
}
