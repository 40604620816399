<mat-toolbar class="shell-toolbar">
  <mat-toolbar-row
    [class.top-admin]="isSysadmin || isMlAdmin"
    class="toolbar-grid"
    [class.small-screen-grid]="!(1281 | breakObserver: 'min' | async)">
    <div>
      <a *ngIf="!isSysadmin && !isMlAdmin" (click)="navigateToPage('/')">
        <img src="../../assets/images/logo.svg"/>
      </a>
      <img *ngIf="isSysadmin || isMlAdmin" class="top-admin-page" src="../../assets/images/logo.svg"/>
    </div>

    <div class="back-to-dashboard">
      <div
        class="body-2"
        *ngIf="backLinkActive"
        (click)="backWithLocation(); jobSearchDialog && jobSearchDialog.closeAll()">
        <mat-icon>chevron_left_24px</mat-icon>
        <span>BACK TO {{ backLinkText }}</span>
      </div>
      <h4 class="display-ellipsis">{{ pageName }}</h4>
    </div>
    <div class="dashboard-info" (click)="jobSearchDialog && jobSearchDialog.closeAll()">
      <span *ngIf="rego" class="rego-box subtitle-1">{{ rego }}</span>
      <app-refresh
        (refresh)="routedComponent?.refresh()"
        [cloudState]="toolbarService.cloudState$ | async"
        [loading]="routedComponent?.loading"
        [offline]="!toolbarService.isOnline"></app-refresh>
      <div *ngIf="!(isMigrationCompleted | async)" class="migration body-1">
        <mat-icon svgIcon="migration_progress_icon"></mat-icon>
        <span class="migration-text">Migration in progress</span>
      </div>
    </div>
    <form class="global-search" *ngIf="searchJobsForm" [formGroup]="searchJobsForm" (ngSubmit)="searchJob()">
      <mat-form-field
        appearance="outline"
        class="search-field body-1"
        [class.small-screen-search]="!(1281 | breakObserver: 'min' | async)">
        <mat-icon matPrefix>search</mat-icon>
        <input
          class="search-input"
          formControlName="searchInput"
          placeholder="Search by claim number, insurer, REGO,{{
            (isSysadmin || isMlAdmin) && !sysAdminService.repairerSiteAccessed() ? ' datafile id, ' : ' '
          }}vehicle or {{
            (isSysadmin || isMlAdmin) && !sysAdminService.repairerSiteAccessed() ? 'repairer' : 'owner'
          }}"
          matInput
          #inputSearch/>
        <mat-icon *ngIf="searchInput.value" matSuffix (click)="searchInput.setValue('')">cancel_24px</mat-icon>
      </mat-form-field>
    </form>
    <div *ngIf="currentUser" class="icons" [class.large-screen]="1281 | breakObserver: 'min' | async">
      <div *ngIf="1281 | breakObserver: 'min' | async" class="icon-grid">
        <div>
          <div
            (click)="navigateToPage(backLink)"
            *ngIf="isRepadmin || isRepairer || sysAdminService.repairerSiteAccessed()"
            [class.home-selected]="!backLink"
            class="home-icon"></div>
        </div>
        <div>
          <div
            (click)="navigateToPage('/claimList')"
            *ngIf="isRepadmin || isRepairer || sysAdminService.repairerSiteAccessed()"
            [class.report-selected]="pageName === 'Claim List'"
            class="report-icon"></div>
        </div>
        <div class="help-icon" [class.button-active]="supportMenuOpened">
          <button
            *ngIf="1281 | breakObserver: 'min' | async"
            (menuClosed)="supportMenuOpened = false"
            (menuOpened)="supportMenuOpened = true"
            (click)="
              cleanActiveJobHelper.clearLocalStorageFromActiveJob(); jobSearchDialog && jobSearchDialog.closeAll()
            "
            [matMenuTriggerFor]="supportMenu"
            mat-icon-button></button>
        </div>
        <div class="initials-icon">
          <button
            *ngIf="1281 | breakObserver: 'min' | async"
            (menuClosed)="userMenuOpened = false"
            (menuOpened)="menuOpened()"
            (click)="
              cleanActiveJobHelper.clearLocalStorageFromActiveJob(); jobSearchDialog && jobSearchDialog.closeAll()
            "
            [class.button-active]="userMenuOpened"
            [matMenuTriggerFor]="userMenu"
            mat-icon-button>
            <h6>{{ currentUser?.firstName?.charAt(0) }}{{ currentUser?.lastName?.charAt(0) }}</h6>
          </button>
        </div>
      </div>
      <button
        *ngIf="!(1281 | breakObserver: 'min' | async)"
        [matMenuTriggerFor]="smallMenu"
        mat-icon-button
        class="icon-grid small-screen-grid menu-icon">
        <mat-icon>menu</mat-icon>
      </button>
      <mat-menu #smallMenu="matMenu" class="menu-dropdown">
        <ng-container *ngIf="isRepadmin || isRepairer || sysAdminService.repairerSiteAccessed()">
          <button mat-menu-item (click)="navigateToPage(backLink)">
            <span>Dashboard</span>
          </button>
          <button mat-menu-item (click)="navigateToPage('/claimList')">
            <span>Claim List</span>
          </button>
        </ng-container>
        <button
          *ngIf="!(1281 | breakObserver: 'min' | async)"
          [matMenuTriggerFor]="supportMenu"
          (click)="$event.stopPropagation()"
          mat-menu-item>
          <span>Support</span>
        </button>
        <button
          *ngIf="!(1281 | breakObserver: 'min' | async)"
          [matMenuTriggerFor]="userMenu"
          (click)="$event.stopPropagation()"
          mat-menu-item>
          <span>User</span>
        </button>
      </mat-menu>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
<div class="cursor-shell" [class.mouse]="!screenShareStarted()">
  <div class="spot"></div>
  <img src="../../assets/images/arrow.svg" alt="Sys-Admin mouse"/>
</div>
<router-outlet (activate)="setRoutedComponent($event)"></router-outlet>

<mat-menu #supportMenu="matMenu">
  <div class="name">Support</div>
  <div class="help-phone subtitle-2">Stelvio is here to help</div>
  <mat-divider></mat-divider>
  <div class="phone body-2">Phone</div>
  <div class="help-phone subtitle-2">(02) 9455 1055</div>
  <div class="days body-2">9am to 5pm, Monday to Friday</div>
  <ng-container *ngIf="isRepadmin || isRepairer">
    <mat-divider></mat-divider>
    <div (click)="displayEula()" class="help-eula subtitle-2">End-user license agreement</div>
    <div class="days body-2">
      Accepted on {{ currentUser?.eulaApprovedDateTime | dateepoch: timezone }} by {{ currentUser?.eulaApprovedBy }}
    </div>
  </ng-container>
</mat-menu>
<mat-menu #userMenu="matMenu">
  <h4 *ngIf="currentUser?.repairerSiteCompanyName || sysAdminService.repairerSiteAccessed()">
    {{ currentUser?.repairerSiteCompanyName || sysAdminService.repairerSiteAccessed() }}
  </h4>
  <div class="title body-2">
    {{
    isSysadmin
      ? 'SYSTEM ADMINISTRATOR'
      : isRepadmin
        ? 'ADMINISTRATOR'
        : isMlAdmin
          ? 'REPAIRER ADMINISTRATOR'
          : 'REPAIRER'
    }}
  </div>
  <div class="name">
    {{ currentUserService.currentUserValue?.firstName }} {{ currentUserService.currentUserValue?.lastName }}
  </div>
  <div class="user-email body-2">
    {{ currentUserService.currentUserValue?.email }}
  </div>
  <mat-divider></mat-divider>
  <button
    class="subtitle-1"
    (click)="navigateToPage('/locationAdmin')"
    *ngIf="isRepadmin || sysAdminService.repairerSiteAccessed()"
    mat-menu-item>
    Administration
  </button>
  <button
    class="subtitle-1"
    (click)="exitRepairerSite()"
    *ngIf="
      (currentUser?.privileges?.includes('SYSADMIN') || currentUser?.privileges?.includes('MLADMIN')) &&
      currentUserService.currentUserValue?.privileges?.includes('REPADMIN') &&
      sysAdminService.repairerSiteAccessed()
    "
    mat-menu-item>
    Exit {{ sysAdminService.repairerSiteAccessed() }}
  </button>
  <button class="subtitle-1" (click)="logout()" mat-menu-item>
    <span>Log out</span>
  </button>
  <mat-divider></mat-divider>
  <div class="copyright-info">
    <div class="footer-text">All contents &copy; 1990-{{ currentYear }} Stelvio inc. All rights reserved</div>
    <div class="logo">
      <a href="http://www.stelvio.com" rel="noopener" target="_blank"><img src="../assets/images/stelvio.svg"/> </a>
    </div>
  </div>
</mat-menu>
