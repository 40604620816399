import { Directive, HostBinding, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[appHighlightStatus]'
})
export class HighlightStatusDirective implements OnInit {
  // Cannot be standalone because it is declared in app.module
  // Please use status-chip.component.ts instead
  @Input('appHighlightStatus') status: string;
  @HostBinding('style.backgroundColor') @Input() backgroundColor: string;
  @HostBinding('style.color') @Input() textColor: string;
  @HostBinding('attr.appHighlightStatus') appHighlightStatusDirective = ''; // force angular to inject appHighlightStatus on the HtmlElement
  constructor() {}

  ngOnInit(): void {
    switch (this.status) {
      case 'ASSESSED':
      case 'MIGRATED':
        this.backgroundColor = 'var(--secondary-500)';
        break;
      case 'AUTHORISED':
      case 'ISSUED':
      case 'APPROVED':
      case 'ACTIVE':
      case 'ENABLED':
        this.backgroundColor = 'var(--salem)';
        break;
      case 'PENDING':
      case 'PENDING_FOR_REVIEW':
      case 'ADJUSTED':
      case 'INVITED':
        this.backgroundColor = 'var(--orange-peel)';
        break;
      case 'DISABLED':
        this.backgroundColor = 'var(--gainsboro)';
        this.textColor = 'var(--silver-chalice)';
        break;
      case 'REJECTED':
      case 'SUSPENDED':
        this.backgroundColor = 'var(--primary-500)';
        break;
    }
  }
}
