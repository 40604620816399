<mat-expansion-panel (closed)="claimCurtainOpened = false" (opened)="claimCurtainOpened = true" class="curtains" #panel>
  <mat-expansion-panel-header [collapsedHeight]="'70px'" [expandedHeight]="'70px'">
    <mat-panel-title>
      <div>
        <div *ngIf="job?.requestForQuoteTimeStamp" class="received-date">
          {{ job.requestForQuoteTimeStamp | dateepoch: currentUserTimezone }}
          {{ job.requestForQuoteTimeStamp | time: currentUserTimezone }}
        </div>
        <div class="claim-number">
          <h5><span class="subtitle-1">Claim</span> {{ job?.claimNumber }}</h5>
          <div *ngIf="job?.authority" class="authorised-box" appHighlightStatus="AUTHORISED">AUTHORISED</div>
          <div
            *ngIf="(job?.enableToSelfAuthorized || job?.preAuthority) && !job?.authority"
            class="authorised-box pre-authorised-box"
            appHighlightStatus="AUTHORISED">
            PRE-AUTHORISED
          </div>
        </div>
      </div>
    </mat-panel-title>
    <mat-panel-description>
      <div class="closed-panel-description">
        <div *ngIf="job?.vehicleDescription" class="closed-curtain-data">
          <div class="labels body-2">Vehicle</div>
          <div class="data-values subtitle-2 display-ellipsis">{{ job.vehicleDescription }}</div>
        </div>
        <div class="inspection-block" [ngClass]="{ 'self-center': !job?.inspectionDate }">
          <div *ngIf="job?.inspectionDate" class="closed-curtain-data">
            <div class="labels body-2">Inspection</div>
            <div class="data-values subtitle-2">{{ job.inspectionDate | date: dateDisplayFormat }}</div>
          </div>
          <div class="closed-curtain-data">
            <span *ngIf="job?.onSiteInspection" class="on-site body-2 ml-10">On site</span>
          </div>
        </div>
        <div *ngIf="job?.excess || job?.excessType" class="total-excess">
          <div class="labels body-2">Total Excess</div>
          <div class="data-values subtitle-2">
            <span *ngIf="job?.excess">$</span>
            <span>{{
              job?.excessType ? job?.excessType : job?.excess ? (job?.excess | number: '1.2-2') : 'Not Specified'
            }}</span>
          </div>
        </div>
      </div>
      <div class="right-section">
        <div
          #trigger="matMenuTrigger"
          (mouseenter)="trigger.openMenu()"
          (mouseleave)="trigger.closeMenu()"
          [matMenuTriggerFor]="inscoTooltip"
          class="insurer-block">
          <img src="{{ job?.logoUrl }}" />
          <div *ngIf="!job?.logoUrl" class="insco-name">
            {{ job?.insco }}
          </div>
          <mat-icon>info_outline</mat-icon>
        </div>
        <div class="pdf-button secondary-icon">
          <button type="button" mat-icon-button (click)="openPdfReport('repairer'); $event.stopPropagation()">
            <mat-icon>picture_as_pdf</mat-icon>
          </button>
        </div>
      </div>
    </mat-panel-description>
  </mat-expansion-panel-header>
  <mat-divider></mat-divider>
  <form [formGroup]="claimForm">
    <h4>Repairer Instructions</h4>
    <div class="repairers-instructions flex gap-x-20">
      <mat-form-field appearance="outline" class="textarea-fields mt-0 no-validation" color="accent">
        <mat-label>Repairer Instructions</mat-label>
        <textarea
          matInput
          formControlName="repairerInstructions"
          maxlength="65535"
          cdkTextareaAutosize="true"></textarea>
      </mat-form-field>
      <mat-form-field appearance="outline" color="accent" class="w-[272px] min-w-[272px] no-validation">
        <mat-label>Assessment Instructions</mat-label>
        <input formControlName="assessmentInstruction" matInput />
      </mat-form-field>
      <mat-form-field appearance="outline" color="accent" class="medium-field no-validation">
        <mat-label>Assessment Category</mat-label>
        <input formControlName="assessmentCategory" matInput />
      </mat-form-field>
    </div>

    <h4>Vehicle</h4>
    <div class="field-group">
      <mat-form-field
        [class.read-only]="job?.cancelled"
        appearance="outline"
        class="large-field primary-icon"
        color="accent">
        <mat-label>Vehicle Description</mat-label>
        <input
          class="display-ellipsis vehicle-description"
          [class.editable]="!job?.vehicleSelectedFromGlassGuide"
          [required]="vehicleFromGlassMandatory"
          [readonly]="job?.vehicleSelectedFromGlassGuide"
          formControlName="vehicleDescription"
          matInput />
        <button
          (click)="confirmClearVMD()"
          *ngIf="job?.vehicleSelectedFromGlassGuide"
          [disabled]="job?.cancelled"
          mat-icon-button
          matSuffix
          type="button">
          <mat-icon class="back-space">backspace</mat-icon>
        </button>
        <button (click)="selectVehicle()" [disabled]="job?.cancelled" mat-icon-button matSuffix type="button">
          <mat-icon class="car">directions_car</mat-icon>
        </button>
        <mat-hint *ngIf="job?.vehicleSelectedFromGlassGuide">Vehicle selected from Glass Guide</mat-hint>
        <mat-hint *ngIf="job?.unlistedGlassVehicle">The vehicle is not listed in Glass Guide</mat-hint>
        <mat-error *ngIf="vehicleDescription.hasError('required')">
          Vehicle Description is <strong>required</strong>
        </mat-error>
        <mat-error
          *ngIf="!vehicleDescription.hasError('required') && vehicleDescription.hasError('mustBeSetFromGlassGuide')">
          Vehicle <strong>must be set</strong> using the vehicle selector
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="small-field" color="accent">
        <mat-label>REGO</mat-label>
        <input formControlName="rego" matInput oninput="this.value = this.value.toUpperCase()" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="small-field" color="accent">
        <mat-label>State</mat-label>
        <mat-select
          disableOptionCentering="true"
          [panelClass]="'rego-state-options'"
          (selectionChange)="saveJob.emit({ fieldValue: regoState.value, fieldName: 'regoState' })"
          [required]="regoStateMandatory"
          formControlName="regoState">
          <mat-optgroup
            class="rego-state-group"
            [class.empty-label]=""
            *ngFor="let groupState of groupStates"
            [label]="groupState.group">
            <mat-option
              *ngFor="let state of groupState.options"
              [value]="state.value"
              [class.empty-option]="state.label === ''">
              {{ state.label }}
            </mat-option>
          </mat-optgroup>
        </mat-select>
        <mat-error> State is <strong>required</strong> </mat-error>
      </mat-form-field>
      <mat-form-field
        [class.read-only]="regoExpiryDatepicker.disabled"
        appearance="outline"
        class="medium-field primary-icon"
        color="accent">
        <mat-label>REGO Expiry</mat-label>
        <input
          (dateChange)="dateChange('regoExpiryDate')"
          (keypress)="dateInputHelper.restrictDateInput($event)"
          [matDatepicker]="regoExpiryDatepicker"
          formControlName="regoExpiryDate"
          matInput />
        <mat-datepicker-toggle [for]="regoExpiryDatepicker" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #regoExpiryDatepicker></mat-datepicker>
        <mat-hint *ngIf="regoExpiryDate.enabled">Date format DD/MM/YYYY</mat-hint>
        <mat-error *ngIf="!regoExpiryDate.valid"> The date format is <strong>DD/MM/YYYY</strong> </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="small-field" color="accent">
        <mat-label>Odometer</mat-label>
        <input [required]="odometerMandatory" formControlName="odometer" matInput formatInputNumber maxlength="9" />
        <span *ngIf="odometer.value" class="km" matSuffix>Km</span>
        <mat-error *ngIf="odometer.hasError('pattern')"> Only numbers are accepted </mat-error>
        <mat-error *ngIf="odometer.hasError('required')"> Odometer is <strong>required</strong> </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="medium-field" color="accent">
        <mat-label>VIN</mat-label>
        <input
          [required]="vinMandatory"
          formControlName="vin"
          matInput
          oninput="this.value = this.value.toUpperCase()" />
        <mat-error> VIN is <strong>required</strong> </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="medium-field" color="accent">
        <mat-label>Body Colour</mat-label>
        <input formControlName="bodyColour" matInput />
      </mat-form-field>
      <mat-form-field appearance="outline" class="medium-field no-validation" color="accent">
        <mat-label>Condition</mat-label>
        <input formControlName="condition" matInput />
      </mat-form-field>
      <mat-form-field appearance="outline" class="medium-field no-validation" color="accent">
        <mat-label>Vehicle Value</mat-label>
        <input formControlName="vehicleValue" matInput />
        <div class="prefix subtitle-2" *ngIf="vehicleValue.value" matPrefix>$</div>
      </mat-form-field>
      <mat-form-field
        [class.read-only]="complianceDatepicker.disabled"
        appMonthYearDateFormat
        appearance="outline"
        class="medium-field primary-icon"
        color="accent">
        <mat-label>Compliance Date</mat-label>
        <input
          (dateChange)="dateChange('complianceDate')"
          (keypress)="dateInputHelper.restrictDateInput($event)"
          [matDatepicker]="complianceDatepicker"
          formControlName="complianceDate"
          matInput />
        <mat-datepicker-toggle [for]="complianceDatepicker" matSuffix></mat-datepicker-toggle>
        <mat-datepicker
          #complianceDatepicker
          (closed)="complianceDate.dirty && dateChange('complianceDate')"
          (monthSelected)="chosenMonthHandler($event, complianceDatepicker)"
          (yearSelected)="chosenYearHandler($event)"
          startView="multi-year">
        </mat-datepicker>
        <mat-hint *ngIf="complianceDate.enabled">Date format MM/YYYY</mat-hint>
        <mat-error *ngIf="complianceDate.invalid"> The date format is <strong>MM/YYYY</strong> </mat-error>
      </mat-form-field>
    </div>
    <div *ngIf="job?.accessories?.length" class="accessories grid grid-cols-2 gap-20 mt-10">
      <div formArrayName="accessories" *ngFor="let accessory of accessories.controls; let i = index">
        <div class="flex gap-x-20" [formGroupName]="i">
          <mat-form-field appearance="outline" color="accent" class="no-validation">
            <mat-label>Vehicle Accessory Description</mat-label>
            <input formControlName="description" matInput />
          </mat-form-field>
          <mat-form-field appearance="outline" class="medium-field no-validation" color="accent">
            <mat-label>Value</mat-label>
            <input formControlName="value" matInput />
            <div class="prefix subtitle-2" matPrefix>$</div>
          </mat-form-field>
        </div>
      </div>
    </div>

    <h4>Incident</h4>
    <div class="flex mb-20 gap-x-20">
      <mat-form-field class="textarea-fields no-validation mt-0" appearance="outline" color="accent">
        <mat-label>Accident Description</mat-label>
        <textarea
          matInput
          formControlName="accidentDescription"
          maxlength="65535"
          cdkTextareaAutosize="true"></textarea>
      </mat-form-field>
      <mat-form-field appearance="outline" color="accent" class="medium-field no-validation">
        <mat-label>Incident Date</mat-label>
        <input formControlName="incidentDate" matInput />
      </mat-form-field>
    </div>
    <mat-form-field
      appearance="outline"
      color="accent"
      class="medium-field max-w-[50%] w-full min-w-[450px] no-validation">
      <mat-label>Accident Location</mat-label>
      <input formControlName="accidentLocation" matInput />
    </mat-form-field>

    <div class="flex items-center">
      <h4 class="mr-10">Inspection</h4>
      <button
        class="custom-mat-button"
        *ngIf="job?.enableBooking"
        [matTooltipDisabled]="!showBooking"
        [matTooltip]="'You may only send one inspection booking at a time'"
        [matTooltipPosition]="'right'"
        (click)="inspectionBooking.emit({ action: 'addBooking' })"
        [disabled]="showBooking"
        mat-raised-button
        color="accent">
        <mat-icon class="custom-mat-icon mat-icon-sm mb-5">create</mat-icon>
        BOOKING
      </button>
    </div>
    <div class="field-group">
      <div *ngIf="job?.onSiteInspection" class="pt-10 mr-20">
        <span class="on-site body-2">On site</span>
      </div>
      <mat-form-field appearance="outline" color="accent" class="medium-field no-validation">
        <mat-label>Inspection Date</mat-label>
        <input formControlName="inspectionDate" matInput />
      </mat-form-field>
      <mat-form-field appearance="outline" color="accent" class="medium-field no-validation">
        <mat-label>Inspection Type</mat-label>
        <input formControlName="inspectionType" matInput />
      </mat-form-field>
    </div>
  </form>
</mat-expansion-panel>
<mat-menu #inscoTooltip [hasBackdrop]="false" [xPosition]="'before'">
  <section class="insco-tooltip">
    <header class="subtitle-1">{{ job?.insco }}</header>
    <div class="contact subtitle-2">
      <mat-icon>pin_drop</mat-icon>
      <address>
        {{ job?.insurerAddress }}
        {{ job?.insurerSuburb }}
        {{ job?.insurerCountry === 'Australia' ? job?.insurerState : job?.insurerCity }}
        {{ job?.insurerCountry }}
      </address>
    </div>
  </section>
</mat-menu>
