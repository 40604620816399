import { Component, effect, EventEmitter, inject, Injector, Input, OnInit, Output, signal } from '@angular/core';
import { MatLegacySelectChange } from '@angular/material/legacy-select';
import { DashboardSortOrderOption } from '../../../model/dashboard-sort-order.model';
import { Bucket } from '../../../model/dashoard.model';
import { Job } from '../../../model/job.model';
import { User, UserRepairerSite } from '../../../model/user.model';
import { UserService } from '../../../services/user.service';
import { BucketSortName } from '../../../types/dashboardSortOrder.type';

@Component({
  selector: 'app-repairer-buckets',
  templateUrl: './repairer-buckets.component.html',
  styleUrls: ['./repairer-buckets.component.scss']
})
export class RepairerBucketsComponent implements OnInit {
  @Input() bucket: Bucket;
  @Input() currentUser: User;
  @Input() repairerSite: UserRepairerSite;
  @Output() getJobs: EventEmitter<string> = new EventEmitter<string>();

  filteredJobs: Job[] = [];
  filterValue = signal('');
  allowFilter = false;
  allowSort = false;
  sortingBucketName: BucketSortName;
  hasNoMatchingJobs = false;
  filterPlaceholder = 'Filter by insurance or assessing company';
  noResultFilterMessage = 'No results matching your filter. You can clear your filter by pressing on the X icon.';

  sortOptionsList: DashboardSortOrderOption[] = [
    {
      name: 'LAST_RECEIVED_DATETIME_ASC',
      value: 'lastReceivedDateTime',
      direction: 'ASC',
      label: 'Date received (oldest to newest)'
    },
    {
      name: 'LAST_RECEIVED_DATETIME_DESC',
      value: 'lastReceivedDateTime',
      direction: 'DESC',
      label: 'Date received (newest to oldest)'
    },
    {
      name: 'INSPECTION_DATE_ASC',
      value: 'inspectionDate',
      direction: 'ASC',
      label: 'Inspection date (earliest to latest)'
    }
  ];
  sortOption: DashboardSortOrderOption = this.sortOptionsList[0];

  private userService = inject(UserService);
  private injector = inject(Injector);

  ngOnInit() {
    this.filteredJobs = this.bucket?.jobBuckets;
    this.getFilterAndSortOption();
  }

  private getFilterAndSortOption(): void {
    switch (this.bucket?.id) {
      case 'requestForQuote':
        this.allowFilter = true;
        this.allowSort = true;
        this.sortingBucketName = 'requestForQuoteSortOrder';
        this.sortOption = this.sortOptionsList.find(
          (option) => option.name === this.userService.dashboardSortOrder().requestForQuoteSortOrder
        );
        this.addEffectOnFilterValueSignal();
        break;
      case 'pendingAssessment':
        this.allowSort = true;
        this.sortingBucketName = 'pendingAssessmentSortOrder';
        this.sortOption = this.sortOptionsList.find(
          (option) => option.name === this.userService.dashboardSortOrder().pendingAssessmentSortOrder
        );
        break;
    }
  }

  optionComparisonFunction(option, value): boolean {
    return option.name === value.name;
  }

  onChangeDashboardSortOrder(event: MatLegacySelectChange): void {
    if (event?.value && this.sortingBucketName) {
      this.userService.updateDashboardOrder(this.sortingBucketName, event.value.name).subscribe();
    }
  }

  private addEffectOnFilterValueSignal(): void {
    effect(
      () => {
        this.filterJobs(this.filterValue());
      },
      { injector: this.injector }
    );
  }

  private filterJobs(value: string): void {
    const jobsFilteredByValue = this.bucket.jobBuckets?.length
      ? this.bucket.jobBuckets.filter((job) => {
          const searchValue = value.toLowerCase();
          const insuranceCompany = job.insco?.toLowerCase();
          const assessingCompany = job.assessingCompany?.toLowerCase();
          return insuranceCompany.includes(searchValue) || assessingCompany.includes(searchValue);
        })
      : [];
    this.hasNoMatchingJobs = value && jobsFilteredByValue.length === 0;
    this.filteredJobs = jobsFilteredByValue.length ? jobsFilteredByValue : this.bucket.jobBuckets;
  }
}
