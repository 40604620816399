export const TAB_INDEXES = {
  REQUEST_FOR_QUOTE: 0,
  MORE_INFO_REQUIRED: 0,
  PENDING_ASSESSMENT: 0,
  ASSESSMENT_COMPLETED: 0,
  AUTHORIZED: 1,
  PRE_AUTHORIZED: 1,
  REPAIRS_BOOKED: 1,
  PENDING_SUPPLEMENT_ASSESSMENT: 1,
  MORE_INFO_SUPPLEMENT: 1,
  SUPPLEMENT_ASSESSMENT_COMPLETED: 1,
  READY_TO_INVOICE: 2,
  PENDING_INVOICE_APPROVAL: 2,
  INVOICE_APPROVED: 2,
  INVOICE_REJECTED: 2,
  REPAIR_PROGRESS: 3
};
